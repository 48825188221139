import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { RecoilRoot } from 'recoil';
import { useState } from 'react';
import ResetRecoilContext from 'ResetRecoilContext';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { AxiosErrorResponse } from 'models';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: (error: unknown) => {
        const axiosError = error as AxiosError<AxiosErrorResponse>;
        const message = axiosError.response?.data.message;
        if (message) toast.error(message);
      },
    },
  },
  queryCache: new QueryCache({
    onSuccess: (data, query) => {
      if (query.meta?.success)
        toast.success(query.meta?.success as string);
    },
    onError: (error: unknown) => {
      const axiosError = error as AxiosError<AxiosErrorResponse>;
      const message = axiosError.response?.data.message;
      if (message) toast.error(message);
    },
  }),
});

const AppWrapper = (): JSX.Element => {
  const [resetKey, setResetKey] = useState<number>(0);
  const resetRecoil = () => {
    setResetKey(prev => prev + 1);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ResetRecoilContext.Provider value={{ resetRecoil }}>
        <RecoilRoot key={resetKey}>
          <App />
        </RecoilRoot>
      </ResetRecoilContext.Provider>
      <ReactQueryDevtools initialIsOpen={true} />
    </QueryClientProvider>
  );
};

root.render(<AppWrapper />);
