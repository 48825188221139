import { defaultCurrentData, defaultSearchDocument } from 'models';
import { atom } from 'recoil';

const searchDocument = atom({
  key: 'searchDocument',
  default: defaultSearchDocument,
});

const currentDocument = atom({
  key: 'currentDocument',
  default: defaultCurrentData,
});

export const pdkState = {
  searchDocument,
  currentDocument,
};
