import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { ICommonCode } from 'models';
import { client } from 'utils';

function processCodeMutation(
  options: UseMutationOptions<unknown, Error, ICommonCode[], unknown>,
) {
  return useMutation({
    mutationFn: async processCode => {
      const response = await client.post(
        '/api/co/cm/01/code',
        processCode,
      );
      return response;
    },
    ...options,
  });
}

export default function useProcessCodeMutation() {
  const queryClient = useQueryClient();

  return processCodeMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['code', 'process_code'],
      });
    },
  });
}
