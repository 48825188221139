import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { IUserInfo } from 'models';
import { useSetRecoilState } from 'recoil';
import { loginState } from 'store';
import { toast } from 'react-toastify';

interface LoginResponse {
  accessToken: string;
  userInfo: IUserInfo;
  expTime: number;
}

interface ErrorResponse {
  message: string | null | undefined;
}

export function loginMutation(
  options: UseMutationOptions<
    LoginResponse,
    AxiosError<ErrorResponse>,
    unknown,
    unknown
  >,
) {
  return useMutation({
    mutationFn: async user => {
      const response = await axios.post('/api/login', user);
      return response.data;
    },
    ...options,
  });
}

export default function useLoginMutation() {
  const setAccessToken = useSetRecoilState(loginState.accessToken);
  const setUserInfo = useSetRecoilState(loginState.userInfo);
  const setExpTime = useSetRecoilState(loginState.expTime);

  return loginMutation({
    onSuccess: (data: LoginResponse) => {
      const accessToken = data.accessToken;
      const userInfo = data.userInfo;
      const expTime = Math.round(data.expTime / 60);

      setAccessToken(accessToken);
      setUserInfo(userInfo);
      setExpTime(expTime);
      sessionStorage.setItem('accessToken', accessToken);

      window.location.href = '/';
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      const message = error.response?.data.message;
      if (message) toast.error(message);
    },
  });
}
