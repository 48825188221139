import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchProgram } from 'models';
import { client } from 'utils';

function programList({
  searchData,
  options,
}: IQueryParams<ISearchProgram>) {
  return useQuery({
    queryKey: ['system_setting', 'program_list', searchData],
    queryFn: async () => {
      const response = await client.get('/api/co/cm/140m01/program', {
        params: searchData,
      });
      return response.data.programList || [];
    },
    ...options,
  });
}

export default function useProgramList(searchData: ISearchProgram) {
  return programList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
