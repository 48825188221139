import {
  IMainProcess,
  ISearchMainProcess,
  ISearchSubProcess,
  ISubProcess,
} from 'models/interfaces/processInterface';
import { RowType } from 'models/types/commonTypes';

export const defaultMainProcess: IMainProcess = {
  PRS_CD: '',
  RMK_CONT: '',
  SRT_SQN: 0,
  PRS_HRK_CD: null,
  PRS_CD_NM: '',
  PRS_DTT_CD: '01',
  ROWTYPE: RowType.ADD,
};

export const defaultSubProcess: ISubProcess = {
  PRS_CD: '',
  RMK_CONT: '',
  SRT_SQN: 0,
  PRS_HRK_CD: '',
  PRS_CD_NM: '',
  PRS_DTT_CD: '02',
  ROWTYPE: RowType.ADD,
};

export const defaultSearchMainProcess: ISearchMainProcess = {
  PRS_CD: '',
};

export const defaultSearchSubProcess: ISearchSubProcess = {
  PRS_HRK_CD: '',
};
