import { useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  ReadOnlyTextCell,
  SelectCell,
  StatusCell,
  StatusIcon,
  Table,
  TextCell,
} from 'components';
import {
  TableContext,
  addRowData,
  addRowNumber,
  exportJsonToSheet,
  mapDataWithOptions,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';
import { useRecoilValue } from 'recoil';
import { useTableData } from 'hooks';
import { DataTableContainer } from 'styles';
import { standardInfomationState } from 'store';
import {
  Columns,
  CommonArray,
  CommonObject,
  CurrentData,
  ITablePropsWithOptions,
  defaultCurrentData,
  defaultSystemMessage,
} from 'models';
import { useMessageList, useMessageMutation } from 'apis';

const columnHelper = createColumnHelper();
const newRow = defaultSystemMessage;

const COCM130M01Table = ({
  tableHeight,
  options,
}: ITablePropsWithOptions) => {
  const searchMessage = useRecoilValue(
    standardInfomationState.searchMessage,
  );
  const [backupData, setBackupData] = useState<CommonArray>([]);
  const { data: messageData } = useMessageList(
    searchMessage,
  ) as CommonObject;
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: messageData,
    atom: standardInfomationState.messageList,
    effect: () => {
      const newData = addRowNumber(messageData);
      setInitialData(newData);
      setBackupData(newData);
      setCurrentData({ index: 0, data: newData[0] });
    },
  });
  const [currentData, setCurrentData] =
    useState<CurrentData>(defaultCurrentData);
  const useOptions = [
    { label: '사용', value: 'Y' },
    { label: '사용안함', value: 'N' },
  ];
  const messageMutation = useMessageMutation();

  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 20,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('no', {
      header: 'No',
      cell: ReadOnlyTextCell,
      size: 30,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('MSG_DTT_CD', {
      header: '구분',
      cell: SelectCell,
      size: 50,
      filterFn: 'includesString',
      meta: {
        options: options,
      },
    }),
    columnHelper.accessor('MSG_CD', {
      header: '메시지 코드',
      cell: TextCell,
      size: 100,
      filterFn: 'includesString',
      meta: {
        unmodifiable: true,
        required: true,
        justify: 'center',
      },
    }),
    columnHelper.accessor('MSG_NM', {
      header: '메시지 명',
      cell: TextCell,
      size: 400,
      filterFn: 'includesString',
      meta: {
        required: true,
      },
    }),
    columnHelper.accessor('MSG_CONT', {
      header: '메시지 설명',
      cell: TextCell,
      size: 200,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('USE_YN', {
      header: '사용여부',
      cell: SelectCell,
      size: 80,
      filterFn: 'includesString',
      meta: {
        options: useOptions,
      },
    }),
  ];
  const columns = useMemo(() => defaultColumns, [options]) as Columns;

  const handleAddData = () => {
    const modifiedRow = {
      ...newRow,
      MSG_DTT_CD: options?.[0]?.value ?? '',
    };
    addRowData(
      setInitialData,
      setCurrentData,
      modifiedRow,
      initialData.length,
    );
  };

  const handleRemoveData = (selectData: CurrentData) => {
    const removeData = removeRowData(selectData, initialData);

    setInitialData(removeData);
    selectNextDataByRemove(
      selectData,
      initialData.length - 1,
      removeData,
      setCurrentData,
    );
  };

  const handleSaveData = () => {
    const checkKeys = ['MSG_DTT_CD', 'MSG_CD', 'MSG_NM', 'USE_YN'];
    const targetData = saveTableData(initialData, checkKeys);

    if (targetData.length > 0) {
      const modifiedData = targetData.map(message => {
        const selectedOption = options.find(
          option => option.value === message.MSG_DTT_CD,
        );

        const msgTlt = selectedOption.label;
        return {
          ...message,
          MSG_TLT: msgTlt,
        };
      });
      messageMutation.mutate(modifiedData as CommonArray);
    } else {
      setInitialData(initialData);
    }
  };

  const getExcelRowData = () => {
    const optionsMap = {
      USE_YN: useOptions,
    };
    return mapDataWithOptions(initialData, optionsMap);
  };

  const contextValue = {
    handleExport: () =>
      exportJsonToSheet(columns, getExcelRowData(), '시스템메시지'),
    currentData,
    setCurrentData,
    handleAddData,
    handleRemoveData,
    handleSaveData,
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        <Table
          title='시스템메시지'
          initialData={initialData}
          backupData={backupData}
          setData={setInitialData}
          columns={columns}
          editable={true}
          tableHeight={tableHeight}
        />
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default COCM130M01Table;
