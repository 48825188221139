import { useRecoilState, useRecoilValue } from 'recoil';
import { menuState, selectState } from 'store';
import { HeaderMenuList } from 'styles';

const HeaderMenu = (): JSX.Element => {
  const headerMenu = useRecoilValue(menuState.headerMenu);
  const [selectHeaderMenu, setSelectHeaderMenu] = useRecoilState(
    selectState.selectHeaderMenu,
  );
  const handleSelectHeaderMenu = (headerMenuId: string) => {
    setSelectHeaderMenu(headerMenuId);
  };
  return (
    <div className='flex-grow'>
      <HeaderMenuList>
        {headerMenu &&
          headerMenu.map((menu, index) => {
            return (
              <li key={menu.MNU_NM}>
                <a
                  href='#'
                  className={
                    selectHeaderMenu === menu.MNU_ID ? 'active' : undefined
                  }
                  onClick={() => handleSelectHeaderMenu(menu.MNU_ID)}
                >
                  {menu.MNU_NM}
                </a>
                {index !== headerMenu.length - 1 ? <span>|</span> : null}
              </li>
            );
          })}
      </HeaderMenuList>
    </div>
  );
};

export default HeaderMenu;
