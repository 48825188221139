import { useQuery } from '@tanstack/react-query';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { TextCell } from 'components';
import { CommonArray, IQueryParams, ISearchModule } from 'models';
import { client } from 'utils';

const columnHelper = createColumnHelper();

function addVopColumns(vopData: CommonArray) {
  if (vopData.length === 0) {
    return undefined;
  }
  const vopEntries = Object.entries(vopData[0]).sort();
  const vopColumns = vopEntries
    .map(([key, value]) => {
      if (!value) {
        return null;
      }
      const column = key.replace('NM', 'CONT');
      return columnHelper.accessor(column, {
        header: value,
        cell: TextCell,
        size: 40,
        enableColumnFilter: false,
        meta: {
          justify: 'center',
        },
      }) as ColumnDef<unknown, any>;
    })
    .filter(
      (column): column is ColumnDef<unknown, any> =>
        column !== undefined && column !== null,
    );
  const vopGroup = columnHelper.group({
    header: 'STD',
    columns: vopColumns,
  });
  return vopGroup;
}

function deviceDescriptionList({
  searchData,
  options,
}: IQueryParams<ISearchModule>) {
  return useQuery({
    queryKey: ['module', 'device', 'description', searchData],
    queryFn: async () => {
      const response = await client.get('/api/teg/04/modal/description', {
        params: searchData,
      });
      const { descriptionList, descriptionCodes, vopCodes } =
        response.data;
      const vopGroup = addVopColumns(vopCodes);
      return {
        descriptionList,
        descriptionCodes,
        vopGroup,
      };
    },
    ...options,
  });
}

export default function useDeviceDescriptionList(
  searchData: ISearchModule,
) {
  return deviceDescriptionList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: searchData.DVC_NO !== '',
      gcTime: 0,
    },
  });
}
