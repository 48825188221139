import { memo } from 'react';
import { ITableCellProps } from 'models';
import { DataTableCheckbox } from 'styles';

const isEqual = (
  prevProps: Readonly<ITableCellProps>,
  nextProps: Readonly<ITableCellProps>,
) => {
  return prevProps.row.original === nextProps.row.original;
};

const ReadOnlyCheckCell = ({
  row,
  column,
}: ITableCellProps): JSX.Element => {
  const value = String(row.original[column.id]);

  return (
    <DataTableCheckbox
      type='checkbox'
      name={column.id}
      checked={value === 'Y' || value === '1'}
      readOnly
    />
  );
};

export default memo(ReadOnlyCheckCell, isEqual);
