import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IChapterKey } from 'models';
import { client } from 'utils';

function chapterKeyMutation(
  options: UseMutationOptions<unknown, Error, IChapterKey[], unknown>,
) {
  return useMutation({
    mutationFn: async (chapterKeyList: IChapterKey[]) => {
      console.log(chapterKeyList);
      const response = await client.post(
        '/api/teg/09/doc/key',
        chapterKeyList,
      );
      return response;
    },
    ...options,
  });
}

export default function useChapterKeyMutation() {
  const queryClient = useQueryClient();

  return chapterKeyMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['document', 'chapter_key'],
      });
    },
  });
}
