import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchParentMenu } from 'models';
import { client } from 'utils';

function parentMenuList({
  searchData,
  options,
}: IQueryParams<ISearchParentMenu>) {
  return useQuery({
    queryKey: ['system_setting', 'parent_menu', searchData],
    queryFn: async () => {
      const response = await client.get('/api/co/cm/150m01/menu/parent', {
        params: searchData,
      });

      return response.data.parentMenuList || [];
    },
    ...options,
  });
}

export default function useParentMenuList(searchData: ISearchParentMenu) {
  return parentMenuList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: searchData.DMN_CD !== '',
    },
  });
}
