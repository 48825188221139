import { useEffect, useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { TableContext, addRowNumber, exportJsonToSheet } from 'utils';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTableData } from 'hooks';
import { ReadOnlyTextCell, Table } from 'components';
import { DataTableContainer } from 'styles';
import {
  Columns,
  CommonObject,
  ISearchCommonCodeTable,
  ITableContext,
  defaultCurrentData,
  defaultSearchCommonCodeTable,
} from 'models';
import { useCodeTypeList } from 'apis';
import { codeManagementState } from 'store';

const columnHelper = createColumnHelper();

const COCM120M01TypeTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchType = useRecoilValue(
    codeManagementState.searchCodeType('COCM120M01'),
  );
  const { data: typeData } = useCodeTypeList(searchType) as CommonObject;
  const [currentData, setCurrentData] = useState(defaultCurrentData);
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: typeData,
    effect: () => {
      const newData = addRowNumber(typeData);
      setInitialData(newData);
      setCurrentData({ index: 0, data: newData[0] });
    },
  });

  const setSearchCommonCode = useSetRecoilState(
    codeManagementState.searchCommonCodeTable,
  );

  useEffect(() => {
    if (currentData.data) {
      const newSearchCommonCode =
        currentData.data as ISearchCommonCodeTable;
      setSearchCommonCode(newSearchCommonCode);
    } else {
      setSearchCommonCode(defaultSearchCommonCodeTable);
    }
  }, [currentData]);

  const defaultColumns = [
    columnHelper.accessor('no', {
      header: 'No',
      cell: ReadOnlyTextCell,
      size: 30,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('TP_CD', {
      header: '코드유형',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('TP_CD_NM', {
      header: '코드유형명',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
    }),
  ];

  const columns = useMemo(() => defaultColumns, []) as Columns;

  const contextValue: ITableContext = {
    handleExport: () =>
      exportJsonToSheet(columns, initialData, '공통코드 유형'),
    currentData,
    setCurrentData,
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        {initialData && (
          <Table
            title='코드유형'
            initialData={initialData}
            backupData={typeData}
            setData={setInitialData}
            columns={columns}
            tableHeight={tableHeight}
          />
        )}
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default COCM120M01TypeTable;
