import {
  ICodeType,
  ICommonCode,
  ISearchCodeType,
  ISearchCommonCode,
  ISearchCommonCodeTable,
} from 'models/interfaces/codeInterface';
import { RowType } from 'models/types/commonTypes';

export const defaultCodeType: ICodeType = {
  CMN_CD_LEN_VAL: 0,
  OD_TP_CD: '',
  REGR_ID: '',
  REG_DATE: '',
  ROWTYPE: RowType.ADD,
  SYS_DTT_CD: '',
  TP_CD: '',
  TP_CD_CONT: '',
  TP_CD_NM: '',
  TSK_DTT_CD: '',
  USE_CLSF_CD: '',
  USE_YN: 'Y',
};

export const defaultCommonCode: ICommonCode = {
  TP_CD: '',
  CMN_CD: '',
  CMN_CD_NM: '',
  CMN_CD_ABV_NM: '',
  CMN_CD_CONT: '',
  SRT_SQN: '',
  USE_YN: 'Y',
  OD_CD: '',
  ROWTYPE: RowType.ADD,
};

export const defaultSearchCodeType: ISearchCodeType = {
  SYS_DTT_CD: '',
  TP_CD: '',
  TP_CD_NM: '',
};

export const defaultSearchCommonCodeTable: ISearchCommonCodeTable = {
  TP_CD: '',
  USR_FLD_1_NM: '',
  USR_FLD_1_INP_FRM_CD: '',
  USR_FLD_2_NM: '',
  USR_FLD_2_INP_FRM_CD: '',
  USR_FLD_3_NM: '',
  USR_FLD_3_INP_FRM_CD: '',
  USR_FLD_4_NM: '',
  USR_FLD_4_INP_FRM_CD: '',
};

export const defaultSearchCommonCode: ISearchCommonCode = {
  TP_CD: '',
};
