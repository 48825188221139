import { useHeight, useSearchComboList } from 'hooks';
import { useState } from 'react';
import {
  CommonButton,
  FrameInfo,
  FrameInfoInput,
  FrameInfoSelect,
} from 'components';
import { useRecoilState } from 'recoil';
import { editValues } from 'utils';
import COCM140M01Table from './COCM140M01Table';
import { FrameContent } from 'styles';
import { menuManagementState } from 'store';
import { CustomChangeEvent } from 'models';

const COCM140M01 = (): JSX.Element => {
  const getHeight = useHeight();

  const [searchProgram, setSearchProgram] = useRecoilState(
    menuManagementState.searchProgram,
  );
  const [values, setValues] = useState(searchProgram);
  const systemOptions = useSearchComboList({ CMB_CD: 'CMB0015' });
  const combinedSystemOptions = [
    { label: '전체', value: '' },
    ...systemOptions,
  ];

  const editValue = (e: CustomChangeEvent) => editValues(e, setValues);

  const handleSearchProgram = () => {
    setSearchProgram(values);
  };

  return (
    <>
      <FrameInfo>
        <div className='search-form'>
          <div className='input-block'>
            <FrameInfoInput
              title='프로그램ID'
              type='text'
              name='PGM_ID'
              value={values?.PGM_ID || ''}
              onChange={editValue}
            />
            <FrameInfoInput
              title='프로그램명'
              type='text'
              name='PGM_NM'
              value={values?.PGM_NM || ''}
              onChange={editValue}
            />
            <FrameInfoSelect
              title='시스템구분'
              options={combinedSystemOptions}
              name='SYS_DTT_CD'
              onChange={editValue}
            />
          </div>
        </div>
        <div>
          <CommonButton
            title='조회'
            onClick={handleSearchProgram}
          />
        </div>
      </FrameInfo>
      <FrameContent>
        <div className='w-full'>
          {systemOptions.length > 0 && (
            <COCM140M01Table
              tableHeight={getHeight(650)}
              options={systemOptions}
            />
          )}
        </div>
      </FrameContent>
    </>
  );
};

export default COCM140M01;
