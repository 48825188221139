import { CommonArray, defaultCurrentData } from 'models';
import { defaultSearchDeviceType } from 'models/defaults/dscCodeDefaults';
import { atom, atomFamily } from 'recoil';

const searchDeviceType = atomFamily({
  key: 'searchDeviceType',
  default: defaultSearchDeviceType,
});

const dscCodeList = atom<CommonArray>({
  key: 'dscCode',
  default: [],
});

const currentDscCode = atom({
  key: 'currentDscCode',
  default: defaultCurrentData,
});

export const dscCodeState = {
  searchDeviceType,
  dscCodeList,
  currentDscCode,
};
