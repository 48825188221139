import {
  ISearchBbs,
  IBbs,
  IBbsDetail,
  ISearchBbsDetail,
  IBbsFileDownload,
  IBbsFileUpload,
} from 'models/interfaces/bbsInterface';

const enum Flag {
  YES = 'Y',
  NO = 'N',
}
export const defaultBbs: IBbs = {
  BBS_ID: 0,
  BBS_NM: '',
  FILE_FLAG: Flag.NO,
  ANS_FLAG: Flag.NO,
  DWUR_ID: '',
  DWUR_DATE: '',
};

export const defaultBbsDetail: IBbsDetail = {
  BBS_ID: 0,
  BBS_NM: '',
  BBS_CONT: '',
  ANS_CONT: '',
  FILE_FLAG: Flag.NO,
  DWUR_ID: '',
  DWUR_DATE: '',
};

export const defaultSearchBbs: ISearchBbs = {
  BBS_GUBUN: '',
  BBS_SEARCH: '',
};

export const defaultSearchBbsDetail: ISearchBbsDetail = {
  BBS_ID: 0,
};

export const defaultFileDownload: IBbsFileDownload = {
  BBS_ID: 0,
  FILE_NM: '',
};

export const defaultFileUpload: IBbsFileUpload = {
  FILES: new FormData(),
  FILE_INFO: defaultSearchBbsDetail,
};
