import { memo } from 'react';
import { CustomMouseEvent, IColumnMeta, ITableCellProps } from 'models';
import { isEqual } from 'utils';
import { DataTableButton } from 'styles';

function DisplayButton({ row, column }: ITableCellProps): JSX.Element {
  const columnMeta: IColumnMeta | undefined = column.columnDef.meta;
  const convertText =
    typeof columnMeta?.text === 'function'
      ? columnMeta.text(row.original)
      : columnMeta?.text;
  const convertDisabled =
    typeof columnMeta?.disabled === 'function'
      ? columnMeta.disabled(row.original)
      : columnMeta?.disabled;

  const handleOnClick = (e: CustomMouseEvent) => {
    e.stopPropagation();
    columnMeta?.onClick?.(row.original);
  };

  return (
    <DataTableButton
      className='w-full'
      onClick={handleOnClick}
      disabled={convertDisabled}
    >
      {convertText}
    </DataTableButton>
  );
}

export default memo(DisplayButton, isEqual);
