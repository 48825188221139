import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IDocumentType } from 'models';
import { client } from 'utils';

function documentTypeMutation(
  options: UseMutationOptions<unknown, Error, IDocumentType[], unknown>,
) {
  return useMutation({
    mutationFn: async (typeData: IDocumentType[]) => {
      const response = await client.post('/api/teg/09/pdk/type', typeData);
      return response;
    },
    ...options,
  });
}

export default function useDocumentTypeMutation() {
  const queryClient = useQueryClient();

  return documentTypeMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['doc', 'type'] });
    },
  });
}
