import { useLoginMutation } from 'apis';
import { LoginBg } from 'assets';
import { LoginForm, LoginLogoBox } from 'features';
import { CommonObject, IUser } from 'models';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { loginState } from 'store';
import { LoginContainer, LoginWrapper } from 'styles';

export const loginValidate = (user: CommonObject) => {
  const errors: CommonObject = {
    USR_ID: '',
    PWD: '',
  };

  if (!user.USR_ID) {
    errors.USR_ID = '아이디를 입력해주세요.';
  }
  if (!user.PWD) {
    errors.PWD = '비밀번호를 입력해주세요.';
  }
  return errors;
};

const defaultUser: IUser = { USR_ID: '', PWD: '' };

const Login = () => {
  const nav = useNavigate();
  const login = useLoginMutation();
  const accessToken = useRecoilValue(loginState.accessToken);
  const handleLogin = (user: CommonObject) => {
    login.mutate(user);
  };

  useEffect(() => {
    if (accessToken) nav('/');
  }, []);

  return (
    <LoginContainer $background={LoginBg}>
      <LoginWrapper>
        <LoginLogoBox />
        <LoginForm
          initialValues={defaultUser}
          validate={loginValidate}
          onSubmit={handleLogin}
        />
      </LoginWrapper>
    </LoginContainer>
  );
};

export default Login;
