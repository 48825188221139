import { ModalButton } from 'components';
import { memo, useEffect } from 'react';
import { ITableCellProps, ITableMeta, RowType } from 'models';
import { DataTableInput } from 'styles';
import { useTableContext } from 'hooks';

const ModalCell = ({
  getValue,
  table,
  row,
  column,
  rowRef,
}: ITableCellProps) => {
  const tableContext = useTableContext();
  const initialValue = getValue();
  const tableMeta: ITableMeta | undefined = table.options.meta;

  useEffect(() => {
    if (row.original.ROWTYPE !== RowType.NORMAL) {
      tableMeta?.editValue?.(row.index, column.id, initialValue, rowRef);
    }
  }, [initialValue]);

  const handleOnPaste = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <div className='flex justify-between w-full items-center'>
      <DataTableInput
        type='text'
        name={column.id}
        value={initialValue || ''}
        readOnly
        onPaste={handleOnPaste}
      />
      <ModalButton openModal={tableContext?.openModal} />
    </div>
  );
};

export default memo(ModalCell);
