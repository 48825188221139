import { useEffect, useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Table,
  StatusCell,
  StatusIcon,
  DisplayButton,
  TextCell,
  SelectCell,
  ModalCell,
  CheckInputCell,
} from 'components';
import {
  TableContext,
  addRowData,
  exportJsonToSheet,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useSearchComboList, useTableData } from 'hooks';
import { systemManagementState } from 'store';
import {
  useDepartmentCodeList,
  usePasswordMutation,
  useUserList,
  useUserMutation,
} from 'apis';
import {
  Columns,
  CommonArray,
  CommonObject,
  CurrentData,
  ITablePropsWithModal,
  IUserCode,
  defaultSearchDepartmentCode,
  defaultUser,
} from 'models';

const columnHelper = createColumnHelper();
const newRow = defaultUser;

const COCM100M01Table = ({
  tableHeight,
  openModal,
}: ITablePropsWithModal): JSX.Element => {
  const searchUser = useRecoilValue(
    systemManagementState.searchUser('COCM100M01'),
  );
  const { data: tableCodeData } = useUserList(searchUser) as CommonObject;
  const [backupData, setBackupData] = useState<CommonArray>([]);
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: tableCodeData,
    atom: systemManagementState.userList('COCM100M01'),
    effect: () => {
      setBackupData(tableCodeData);
      setCurrentData({ index: 0, data: tableCodeData[0] });
    },
  });
  const [currentData, setCurrentData] = useRecoilState(
    systemManagementState.currentUser('COCM100M01'),
  );
  const { data: dptOptions } = useDepartmentCodeList(
    defaultSearchDepartmentCode,
  ) as CommonObject;
  const hlfcDttCdList = useSearchComboList({ CMB_CD: 'CMB0023' });
  const psclList = useSearchComboList({ CMB_CD: 'CMB0024' }, true);
  const rspofcList = useSearchComboList({ CMB_CD: 'CMB0025' }, true);

  const handleReset = (e: IUserCode) => {
    if (!confirm('비밀번호를 초기화 하시겠습니까?')) {
      return;
    }
    pwdMutation.mutate(e);
  };
  const userMutation = useUserMutation();
  const pwdMutation = usePasswordMutation();
  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 20,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('USR_ID', {
      header: '사용자ID',
      cell: TextCell,
      size: 120,
      filterFn: 'includesString',
      meta: {
        unmodifiable: true,
        justify: 'center',
      },
    }),
    columnHelper.accessor('EMP_NO', {
      header: '사원번호',
      size: 120,
      cell: TextCell,
      filterFn: 'includesString',
      meta: {
        unmodifiable: true,
        justify: 'center',
      },
    }),
    columnHelper.accessor('USR_NM', {
      header: '사용자명',
      cell: TextCell,
      size: 100,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('DPT_NM', {
      header: '부서',
      cell: ModalCell,
      size: 150,
    }),
    columnHelper.accessor('PSCL_CD', {
      header: '직급',
      cell: SelectCell,
      size: 100,
      filterFn: 'includesString',
      meta: {
        renderKey: 'PSCL_NM',
        options: psclList,
      },
    }),
    columnHelper.accessor('RSPOFC_CD', {
      header: '직책',
      cell: SelectCell,
      size: 100,
      filterFn: 'includesString',
      meta: {
        renderKey: 'RSPOFC_NM',
        options: rspofcList,
      },
    }),
    columnHelper.accessor('EML_ADDR', {
      header: '이메일 주소',
      cell: TextCell,
      size: 200,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('HLFC_DTT_CD', {
      header: '재직구분',
      cell: SelectCell,
      size: 100,
      enableColumnFilter: false,
      meta: {
        renderKey: 'HLFC_DTT_NM',
        options: hlfcDttCdList,
      },
    }),
    columnHelper.accessor('BLC_YN', {
      header: '사용잠김',
      size: 50,
      cell: CheckInputCell,
      enableColumnFilter: false,
    }),
    columnHelper.accessor('RESET', {
      header: '비밀번호 초기화',
      size: 100,
      cell: DisplayButton,
      enableColumnFilter: false,
      meta: {
        text: '초기화',
        onClick: handleReset,
      },
    }),
  ];

  const columns = useMemo(
    () => defaultColumns,
    [psclList, rspofcList, hlfcDttCdList],
  ) as Columns;

  const handleAddData = () => {
    const addRow = {
      ...newRow,
      USR_TP_CD: searchUser.USR_TP_CD,
      HLFC_DTT_CD: hlfcDttCdList[0].value,
      PSCL_CD: psclList[0].value,
      RSPOFC_CD: rspofcList[0].value,
    };
    addRowData(setInitialData, setCurrentData, addRow, initialData.length);
  };

  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);

    setInitialData(removedData);
    selectNextDataByRemove(
      selectData,
      initialData.length - 1,
      removedData,
      setCurrentData,
    );
  };

  const handleSaveData = () => {
    const checkKeys = ['USR_ID', 'EMP_NO', 'USR_NM', 'DPT_CD'];
    const targetData = saveTableData(initialData, checkKeys);

    if (targetData !== undefined && targetData.length > 0) {
      userMutation.mutate(targetData as IUserCode[]);
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue = {
    handleExport: () =>
      exportJsonToSheet(columns, initialData, 'user-code'),
    handleAddData,
    handleRemoveData,
    handleSaveData,
    currentData,
    setCurrentData,
    openModal,
  };

  const replaceDptCd = (cd: string, cdList: CommonArray) => {
    const matchingDepartment = cdList.find(
      department => department.DPT_CD === cd,
    );
    return matchingDepartment ? matchingDepartment.DPT_NM : null;
  };

  // const replaceCdToNm = (cd: string, cdList: CommonArray) => {
  //   const matchingCD = cdList.find(code => code.CMN_CD === cd);
  //   return matchingCD ? matchingCD.CMN_CD_NM : '';
  // };

  useEffect(() => {
    if (
      tableCodeData &&
      dptOptions &&
      psclList &&
      rspofcList &&
      hlfcDttCdList
    ) {
      const updatedData = tableCodeData.map((data: CommonObject) => ({
        ...data,
        DPT_NM: replaceDptCd(data.DPT_CD, dptOptions),
        // PSCL_NM: replaceCdToNm(data.PSCL_CD, psclList),
        // RSPOFC_NM: replaceCdToNm(data.RSPOFC_CD, rspofcList),
        // HLFC_DTT_NM: replaceCdToNm(data.HLFC_DTT_CD, hlfcDttCdList),
      }));

      setInitialData(updatedData);
    }
  }, [
    searchUser,
    tableCodeData,
    dptOptions,
    psclList,
    rspofcList,
    dptOptions,
  ]);

  return (
    <TableContext.Provider value={contextValue}>
      {initialData && (
        <Table
          title='사용자'
          initialData={initialData}
          backupData={backupData}
          setData={setInitialData}
          columns={columns}
          editable={true}
          tableHeight={tableHeight}
        />
      )}
    </TableContext.Provider>
  );
};

export default COCM100M01Table;
