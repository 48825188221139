import { useQuery } from '@tanstack/react-query';
import { UseQueryOptionsWithoutQueryKey } from 'models';
import { client } from 'utils';

interface IProjectListParam {
  options: UseQueryOptionsWithoutQueryKey;
}

function project({ options }: IProjectListParam) {
  return useQuery({
    queryKey: ['projectListInDeviceModal'],
    queryFn: async () => {
      const response = await client.get('/api/teg/03/project');
      return response.data.projectList;
    },
    ...options,
  });
}

export default function useProjectListInDeviceModal() {
  return project({
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
