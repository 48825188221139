import CommonButton from 'components/common/CommonButton';
import { IFrameEditBoxProps } from 'models';
import { EditBox } from 'styles/frameStyles';

const FrameEditBox = ({
  children,
  title,
  current,
  onClick,
  onSelect,
  height,
}: IFrameEditBoxProps): JSX.Element => {
  const multiClass = (target: string) => {
    return current === target ? 'multi-title' : 'multi-title active';
  };
  return (
    <EditBox className='w-full'>
      <div className='title-box'>
        {Array.isArray(title) ? (
          <div className='flex gap-x-[1px]'>
            {title.map(t => (
              <span
                key={t}
                className={multiClass(t)}
                onClick={() => onSelect?.(t)}
              >
                {t}
              </span>
            ))}
          </div>
        ) : (
          <span>{title}</span>
        )}
        {onClick ? (
          <CommonButton
            title='저장'
            onClick={onClick}
          />
        ) : null}
      </div>
      <div
        className='flex flex-col'
        style={{ height: height }}
      >
        {children}
      </div>
    </EditBox>
  );
};

export default FrameEditBox;
