import { layoutState } from 'store';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { TARGET_HEIGHT } from 'utils';

// const taskbarHeight = 40; (아마 58)
const taskbarHeight = 58;
// const fhdInnterHeight = 945;
const resolutionHeight = {
  fhd: 1080,
  qhd: 1440,
  uhd: 2160,
};

export default function useHeight() {
  const [topOpen] = useRecoilState(layoutState.topOpen);

  const getHeight = useCallback(
    (height: number, count = 1) => {
      let differenceHeight = 0;
      // const innerHeight = window.innerHeight;
      // if (innerHeight > fhdInnterHeight)
      //   differenceHeight = innerHeight - fhdInnterHeight;
      if (window.outerHeight >= resolutionHeight.uhd - taskbarHeight) {
        differenceHeight = resolutionHeight.uhd - resolutionHeight.fhd;
      } else if (
        window.outerHeight >=
        resolutionHeight.qhd - taskbarHeight
      ) {
        differenceHeight = resolutionHeight.qhd - resolutionHeight.fhd;
      }

      const contentHeight = height + differenceHeight;
      // console.log('contentHeight', contentHeight);

      return topOpen
        ? contentHeight
        : contentHeight + TARGET_HEIGHT / count;
    },
    [topOpen],
  );

  return getHeight;
}
