import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { CommonArray } from 'models';
import { toast } from 'react-toastify';
import { client } from 'utils';

function systemMessageMutation(
  options: UseMutationOptions<unknown, Error, CommonArray, unknown>,
) {
  return useMutation({
    mutationFn: async systemMessage => {
      const response = await client.post(
        '/api/co/cm/130m01/message',
        systemMessage,
      );
      return response.data.message;
    },
    ...options,
  });
}

export default function useMessageMutation() {
  const queryClient = useQueryClient();

  return systemMessageMutation({
    onSuccess: message => {
      queryClient.invalidateQueries({
        queryKey: ['system_reference', 'system_message'],
      });
      toast.success(message as string);
    },
  });
}
