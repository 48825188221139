import { useQuery } from '@tanstack/react-query';
import { client } from 'utils';
import { IQueryParams } from 'models';
import { ISearchDeviceName } from 'models/interfaces/deviceInterface';

function deviceNameList({
  searchData,
  options,
}: IQueryParams<ISearchDeviceName>) {
  return useQuery({
    queryKey: ['device', 'name', searchData],
    queryFn: async () => {
      const response = await client.get('/api/teg/05/device', {
        params: searchData,
      });
      const { deviceList } = response.data;
      return {
        deviceList,
      };
    },
    ...options,
  });
}

export default function useDeviceNameList(searchData: ISearchDeviceName) {
  return deviceNameList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: searchData.DVC_NO !== '',
    },
  });
}
