import { useQuery } from '@tanstack/react-query';
import { CommonObject, UseQueryOptionsWithoutQueryKey } from 'models';
import { client } from 'utils';

interface IDeviceListParam {
  searchData: CommonObject;
  options: UseQueryOptionsWithoutQueryKey;
}

function device({ searchData, options }: IDeviceListParam) {
  return useQuery({
    queryKey: ['device', searchData],
    queryFn: async () => {
      const response = await client.get('/api/teg/03/device', {
        params: searchData,
      });
      const { deviceList, maskDeviceList } = response.data;
      return {
        deviceList,
        maskDeviceList,
      };
    },
    ...options,
  });
}

export default function useDeviceList(searchData: CommonObject) {
  return device({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: searchData.PRJ_NO !== 0 && searchData.PRC_NO !== 0,
    },
  });
}
