import { CommonButton, FrameInfo, FrameInfoInput, Tree } from 'components';
import { useHeight } from 'hooks';
import { useEffect, useState } from 'react';
import { convertDataToTree, editValues, preConvertData } from 'utils';
import AIBI010M01Table from './AIBI010M01Table';
import AIBI010M01Form from './AIBI010M01Form';
import { useRecoilState } from 'recoil';
import { FrameContent } from 'styles';
import { systemManagementState } from 'store';
import { CommonObject, CustomChangeEvent, IDepartmentCode } from 'models';
import { useDepartmentCodeList } from 'apis';

const targetKey = 'DPT_CD';
const parentKey = 'HRK_DPT_CD';
const renderKey = 'DPT_NM';

const AIBI010M01 = () => {
  const getHeight = useHeight();
  const [searchDepartmentCode, setSearchDepartmentCode] = useRecoilState(
    systemManagementState.searchDepartmentCode,
  );
  const [values, setValues] = useState(searchDepartmentCode);
  const { data: treeCodeData, isLoading } = useDepartmentCodeList(
    searchDepartmentCode,
  ) as CommonObject;
  const [treeCodeList, setTreeCodeList] = useState<IDepartmentCode[]>([]);
  const [currentData, setCurrentData] = useRecoilState(
    systemManagementState.currentDepartmentCode('AIBI010M01'),
  );
  const editValue = (e: CustomChangeEvent) => editValues(e, setValues);

  const handleSearch = () => {
    setSearchDepartmentCode(values);
  };

  useEffect(() => {
    if (treeCodeData) {
      const treeList = preConvertData(treeCodeData, parentKey);
      setTreeCodeList(convertDataToTree(treeList, parentKey, targetKey));
    }
  }, [treeCodeData]);
  useEffect(() => {
    setValues(searchDepartmentCode);
  }, [searchDepartmentCode]);

  return (
    <>
      <FrameInfo>
        <div className='search-form'>
          <div className='input-block'>
            <FrameInfoInput
              title='부서명'
              type='text'
              value={values?.DPT_NM || ''}
              onChange={editValue}
              name='DPT_NM'
            />
            <FrameInfoInput
              title='사용여부'
              type='checkbox'
              value={values?.USE_YN}
              checked={values?.USE_YN === 'Y'}
              onChange={editValue}
              name='USE_YN'
            />
          </div>
          <div>
            <CommonButton
              title='조회'
              onClick={handleSearch}
            />
          </div>
        </div>
      </FrameInfo>
      <FrameContent>
        <Tree
          title='구성'
          isLoading={isLoading}
          data={treeCodeList}
          currentData={currentData}
          setCurrentData={setCurrentData}
          nodeKey={targetKey}
          parentKey={parentKey}
          renderKey={renderKey}
          height={getHeight(670)}
        />
        <div className='grow-col-between'>
          <AIBI010M01Table tableHeight={getHeight(530)} />
          <AIBI010M01Form />
        </div>
      </FrameContent>
    </>
  );
};

export default AIBI010M01;
