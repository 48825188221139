import { createColumnHelper } from '@tanstack/react-table';
import { useChapterKeyValueMutation, useDesignRuleDevice } from 'apis';
import { SelectCell, StatusCell, StatusIcon, Table } from 'components';
import { useTableData } from 'hooks';
import {
  Columns,
  CommonObject,
  IChapterKeyValue,
  ITableContext,
} from 'models';
import { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { documentState } from 'store';
import { DataTableContainer } from 'styles';
import { TableContext, saveTableData } from 'utils';

const columnHelper = createColumnHelper();

const TEG10DeviceTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const currentChapter = useRecoilValue(
    documentState.currentDocumentContent,
  );
  const currentKey = useRecoilValue(documentState.currentChapterKey);
  const deviceMutation = useChapterKeyValueMutation('device');
  const { data: valueData } = useDesignRuleDevice({
    DOC_NO: currentChapter.data.DOC_NO,
    CH_NO: currentChapter.data.CH_NO,
    CHR_SNO: currentKey.data.CHR_SNO,
  }) as CommonObject;

  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: valueData?.RGdevice,
    effect: () => {
      setInitialData([valueData?.RGdevice]);
      setCurrentData({ index: 0, data: valueData?.RGdevice });
    },
  });
  const deviceOptions = valueData?.deviceList.map(
    (device: CommonObject) => ({
      label: device.DVC_NM,
      value: device.DVC_NO,
    }),
  );

  const [currentData, setCurrentData] = useRecoilState(
    documentState.RGDevice,
  );
  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      header: () => <StatusIcon />,
      size: 25,
      cell: StatusCell,
    }),
    columnHelper.accessor('VALUE', {
      header: '디바이스',
      size: 150,
      cell: SelectCell,
      meta: {
        options: deviceOptions && [
          { label: '선택', value: '' },
          ...deviceOptions,
        ],
      },
    }),
  ];
  const columns = useMemo(
    () => defaultColumns,
    [deviceOptions, valueData],
  ) as Columns;

  const handleSaveData = () => {
    const targetData = saveTableData(initialData, [], false);

    if (targetData.length > 0) {
      deviceMutation.mutate(targetData as IChapterKeyValue[]);
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue: ITableContext = {
    handleSaveData,
    currentData,
    setCurrentData,
  };
  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        <Table
          title='Device'
          initialData={initialData}
          setData={setInitialData}
          backupData={[valueData?.RGdevice]}
          columns={columns}
          showToggle={false}
          tableHeight={tableHeight}
        />
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default TEG10DeviceTable;
