import { CommonArray, defaultCurrentData } from 'models';
import {
  defaultSearchModuleDut,
  defaultSearchModule,
} from 'models/defaults/moduleDefaults';
import { atom, atomFamily } from 'recoil';

const searchModule = atomFamily({
  key: 'searchModule',
  default: defaultSearchModule,
});

const moduleList = atomFamily<CommonArray, string>({
  key: 'moduleList',
  default: [],
});

const checkedModuleList = atomFamily<CommonArray, string>({
  key: 'checkedModuleList',
  default: [],
});

const checkedModuleRef = atomFamily<CommonArray, string>({
  key: 'checkedModuleRef',
  default: [],
});

const designList = atomFamily<CommonArray, string>({
  key: 'designList',
  default: [],
});

const dutList = atomFamily<CommonArray, string>({
  key: 'dutList',
  default: [],
});

const searchModuleDut = atomFamily({
  key: 'searchModuleDut',
  default: defaultSearchModuleDut,
});

const currentModule = atom({
  key: 'currentModule',
  default: defaultCurrentData,
});

const currentDesign = atom({
  key: 'currentDesign',
  default: defaultCurrentData,
});

const currentDut = atom({
  key: 'currentDut',
  default: defaultCurrentData,
});

const currentDescription = atom({
  key: 'currentDescription',
  default: defaultCurrentData,
});

const currentDeviceDraw = atom({
  key: 'currentDeviceDraw',
  default: defaultCurrentData,
});

const deviceDrawList = atom<CommonArray>({
  key: 'deviceDrawList',
  default: [],
});

const drawTypeOption = atom({
  key: 'drawTypeOption',
  default: [],
});

const deviceDrawFile = atom<File | null>({
  key: 'deviceDrawFile',
  default: null,
});

export const moduleState = {
  searchModule,
  searchModuleDut,
  moduleList,
  checkedModuleList,
  checkedModuleRef,
  designList,
  dutList,
  deviceDrawList,
  drawTypeOption,
  deviceDrawFile,
  currentModule,
  currentDesign,
  currentDut,
  currentDeviceDraw,
  currentDescription,
};
