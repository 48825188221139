import { createColumnHelper } from '@tanstack/react-table';
import { useCodeTypeList, useCodeTypeMutation } from 'apis';
import {
  ReadOnlyTextCell,
  StatusCell,
  StatusIcon,
  Table,
} from 'components';
import { useTableData } from 'hooks';
import {
  Columns,
  CommonObject,
  CurrentData,
  ICodeType,
  ITableContext,
  RowType,
  defaultCodeType,
} from 'models';
import { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { codeManagementState } from 'store';
import { DataTableContainer } from 'styles';
import {
  TableContext,
  addRowData,
  exportJsonToSheet,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';

const columnHelper = createColumnHelper();
const newRow = defaultCodeType;

const COCM110M01Table = ({ tableHeight }: { tableHeight: number }) => {
  const searchCodeType = useRecoilValue(
    codeManagementState.searchCodeType('COCM110M01'),
  );

  const { data: codeTypeData } = useCodeTypeList(
    searchCodeType,
  ) as CommonObject;
  const [currentData, setCurrentData] = useRecoilState(
    codeManagementState.currentCodeType,
  );
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: codeTypeData,
    atom: codeManagementState.codeTypeList,
    effect: () => {
      setInitialData(codeTypeData);
      setCurrentData({ index: 0, data: codeTypeData[0] });
    },
  });
  const saveCodeTypeList = useCodeTypeMutation();

  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 20,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('TP_CD', {
      header: '코드유형',
      size: 200,
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('TP_CD_NM', {
      header: '코드유형명',
      size: 200,
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const handleAddData = () => {
    const setTpCdRow = {
      ...newRow,
      SYS_DTT_CD: searchCodeType.SYS_DTT_CD,
    };
    addRowData(
      setInitialData,
      setCurrentData,
      setTpCdRow,
      initialData.length,
    );
    setCurrentData({ index: 0, data: setTpCdRow });
  };

  const handleRemoveData = (selectData: CurrentData) => {
    if (selectData.data.ROWTYPE === RowType.ADD) {
      if (initialData.length > 1)
        setCurrentData({ index: 0, data: initialData[1] });
    }
    const removedData = removeRowData(selectData, initialData);

    if (removedData.length > 0) {
      setInitialData(removedData);
      selectNextDataByRemove(
        selectData,
        initialData.length - 1,
        removedData,
        setCurrentData,
      );
    }
  };

  const handleSaveData = () => {
    const checkKeys = ['TP_CD', 'TP_CD_NM', 'SYS_DTT_CD'];
    const targetData = saveTableData(initialData, checkKeys);

    if (targetData.length > 0) {
      saveCodeTypeList.mutate(targetData as ICodeType[]);
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue: ITableContext = {
    handleExport: () =>
      exportJsonToSheet(columns, initialData, '코드유형목록'),
    handleAddData,
    handleRemoveData,
    handleSaveData,
    currentData,
    setCurrentData,
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        {initialData && (
          <Table
            title='코드유형 목록'
            initialData={initialData}
            backupData={codeTypeData}
            setData={setInitialData}
            columns={columns}
            editable={true}
            tableHeight={tableHeight}
          />
        )}
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default COCM110M01Table;
