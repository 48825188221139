import { IFrameInfoInputProps } from 'models';

const FrameInfoInput = ({
  title,
  children,
  width,
  ...restProps
}: IFrameInfoInputProps): JSX.Element => {
  return (
    <div style={{ width }}>
      <span className='block-title'>{title}</span>
      <input {...restProps} />
      {children}
    </div>
  );
};

export default FrameInfoInput;
