import {
  CommonArray,
  ISearchCombo,
  defaultCurrentData,
  defaultSearchPopup,
} from 'models';
import { atom } from 'recoil';

const searchPopup = atom({
  key: 'searchPopup',
  default: defaultSearchPopup,
});

const currentPopup = atom({
  key: 'currentPopup',
  default: defaultCurrentData,
});

const searchPopupList = atom<CommonArray>({
  key: 'searchPopupList',
  default: [],
});

const searchCombo = atom({
  key: 'searchCombo',
  default: { CMB_CD: '' } as ISearchCombo,
});

const currentSearchCombo = atom({
  key: 'currentSearchCombo',
  default: defaultCurrentData,
});

const searchComboList = atom<CommonArray>({
  key: 'searchComboList',
  default: [],
});

export const formManagementState = {
  searchPopup,
  currentPopup,
  searchPopupList,
  searchCombo,
  currentSearchCombo,
  searchComboList,
};
