import { useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { ReadOnlyTextCell, Table } from 'components';
import { TableContext, exportJsonToSheet } from 'utils';
import { useRecoilValue } from 'recoil';
import { useInfiniteScroll, useTableData } from 'hooks';
import { DataTableContainer } from 'styles';
import {
  Columns,
  CommonObject,
  CurrentData,
  ISearchLoginHistory,
  ITableContext,
  defaultCurrentData,
} from 'models';
import { historyManagementState } from 'store';
import { useLoginHistoryList } from 'apis';

const columnHelper = createColumnHelper();

const COCM180M01Table = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchLoginHistory = useRecoilValue(
    historyManagementState.searchLoginHistory,
  );
  const [loginHistoryData, fetchLoginHistoryData] =
    useInfiniteScroll<ISearchLoginHistory>(
      useLoginHistoryList,
      searchLoginHistory,
      300,
    );

  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: loginHistoryData,
    effect: () => {
      const newData = loginHistoryData.map(
        (history: CommonObject, index: number) => ({
          ...history,
          no: index + 1,
          LGN_SUCS_YN: history.LGN_SUCS_YN === '1' ? '성공' : '실패',
        }),
      );
      setInitialData(newData);
    },
  });
  const [currentData, setCurrentData] =
    useState<CurrentData>(defaultCurrentData);

  const defaultColumns = [
    columnHelper.accessor('no', {
      header: 'No',
      cell: ReadOnlyTextCell,
      size: 30,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('LGN_DATE', {
      header: '로그인일시',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('USR_ID', {
      header: '사용자ID',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('USR_NM', {
      header: '사용자명',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('LGN_SUCS_YN', {
      header: '로그인성공여부',
      size: 100,
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('IP_ADDR', {
      header: 'IP 주소',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const contextValue: ITableContext = {
    handleExport: () =>
      exportJsonToSheet(columns, initialData, '로그인이력'),
    currentData,
    setCurrentData,
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        <Table
          title='로그인이력'
          initialData={initialData}
          setData={setInitialData}
          fetchScroll={fetchLoginHistoryData}
          columns={columns}
          tableHeight={tableHeight}
        />
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default COCM180M01Table;
