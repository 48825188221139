import { CustomMouseEvent } from 'models';
import { ModalInner, ModalOverlay, ModalWrapper } from 'styles';
import { useHeight } from 'hooks';
import TEG04DrawTable from './TEG04DrawTable';
import TEG04DrawForm from './TEG04DrawForm';

const TEG04DrawModal = ({
  onClose,
}: {
  onClose: () => void;
}): JSX.Element => {
  const getHeight = useHeight();
  const close = (e: CustomMouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      <ModalOverlay />
      <ModalWrapper
        onClick={close}
        tabIndex={-1}
      >
        <ModalInner
          tabIndex={0}
          className='w-[800px] h-[800px] flex justify-center'
        >
          <div className='w-[600px] space-y-2'>
            <TEG04DrawTable tableHeight={getHeight(300)} />
            <TEG04DrawForm height={400} />
          </div>
        </ModalInner>
      </ModalWrapper>
    </>
  );
};

export default TEG04DrawModal;
