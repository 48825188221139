import { CommonButton, FrameInfo, FrameInfoInput } from 'components';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { editValues } from 'utils';
import COCM430M01Table from './COCM430M01Table';
import COCM430M01Form from './COCM430M01Form';
import { formManagementState } from 'store';
import { CustomChangeEvent } from 'models';
import { FrameContent } from 'styles';

const COCM430M01 = (): JSX.Element => {
  const [searchCombo, setSearchCombo] = useRecoilState(
    formManagementState.searchCombo,
  );
  const [values, setValues] = useState(searchCombo);

  const editValue = (e: CustomChangeEvent) => editValues(e, setValues);
  const handleSearch = () => {
    setSearchCombo(values);
  };
  return (
    <>
      <FrameInfo>
        <div className='search-form'>
          <div className='input-block'>
            <FrameInfoInput
              title='콤보코드/명'
              type='text'
              name='CMB_NM'
              value={values?.CMB_NM || ''}
              onChange={editValue}
            />
          </div>
          <div>
            <CommonButton
              title='조회'
              onClick={handleSearch}
            />
          </div>
        </div>
      </FrameInfo>
      <FrameContent>
        <div className='flex-between-2 w-full'>
          <div className='w-[600px]'>
            <COCM430M01Table tableHeight={670} />
          </div>
          <div className='grow-col-between'>
            <COCM430M01Form height={670} />
          </div>
        </div>
      </FrameContent>
    </>
  );
};

export default COCM430M01;
