import {
  ColumnFiltersState,
  OnChangeFn,
  SortingState,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  ClipboardEvent,
  UIEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Virtualizer, useVirtualizer } from '@tanstack/react-virtual';
import { DataTable, DataTableContainer, DataTableScrollBox } from 'styles';
import {
  ROWHEIGHT,
  copyClipboardData,
  editCellValue,
  getColumnsAccessorKey,
  getFilterCheckList,
  getMergeHeaderGroups,
  handleSorting,
  moveCurrentData,
  pasteClipboardData,
} from 'utils';
import EmptyBody from '../utils/EmptyBody';
import TableTop from './TableTop';
import TableHead from './TableHead';
import TableBody from './TableBody';
import { useTableContext } from 'hooks';
import {
  CommonObject,
  CustomMouseEvent,
  ITableColumnFilter,
  ITableProps,
  ITableSorting,
  RowType,
  StartIndex,
} from 'models';
import { customEqual } from 'utils';
import { toast } from 'react-toastify';

const Table = (props: ITableProps<CommonObject>): JSX.Element => {
  const { currentData, setCurrentData, setSelectedDataList } =
    useTableContext();
  const {
    title,
    initialData,
    setData,
    backupData = [],
    columns,
    fetchScroll = null,
    draggable = false,
    editable = false,
    headless = false,
    showToggle = true,
    customHeader,
    tableHeight,
  } = props;
  const [sorting, setSorting] = useState<ITableSorting[]>([]);
  const [columnFilters, setColumnFilters] = useState<ITableColumnFilter[]>(
    [],
  );
  const [filterFlag, setFilterFlag] = useState<boolean>(false);
  const [startIndex, setStartIndex] = useState<StartIndex>(
    {} as StartIndex,
  ); //변경
  const [endIndex, setEndIndex] = useState<StartIndex>({} as StartIndex); // 추가
  const [drag, setDrag] = useState(false); // 추가
  const columnKeys = getColumnsAccessorKey(columns);

  const table = useReactTable({
    data: initialData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    meta: {
      editValue: (
        rowIndex: number,
        columnId: string,
        value: any,
        rowRef: any,
      ) => editCellValue(setData, rowIndex, columnId, value, rowRef),
      getFilterCheckList: (columnId: string) =>
        getFilterCheckList(backupData, columnId),
      getBackupData: () => backupData,
      handleSorting: (type: boolean, columnId: string) =>
        handleSorting(sorting, setSorting, type, columnId),
      resetSorting: () => setSorting([]),
      handleFilters: (newFilters: ITableColumnFilter[]) => {
        setColumnFilters(newFilters);
      },
      moveCurrentData: (rowIndex: number) =>
        moveCurrentData(setCurrentData, initialData, rowIndex),
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting as OnChangeFn<SortingState>,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange:
      setColumnFilters as OnChangeFn<ColumnFiltersState>,
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    enableColumnFilters: filterFlag,
  });
  const tableMeta = table.options.meta;

  const mergeHeaderGropus = getMergeHeaderGroups(table.getHeaderGroups());
  const memoizedRows = useMemo(
    () => table.getRowModel().rows,
    [initialData, currentData, sorting, columnFilters, backupData],
  );

  const handleFilterFlag = useCallback(() => {
    setFilterFlag(old => !old);
  }, []);

  const refreshTable = () => {
    if (!confirm('변경사항을 초기화하시겠습니까?')) return;

    setFilterFlag(false);
    setColumnFilters([]);
    setSorting([]);
    setData(backupData);
    setCurrentData({ index: 0, data: initialData[0] });
    setSelectedDataList?.([]);
  };

  const reorderRow = useCallback(
    (draggedRowIndex: number, targetRowIndex: number) => {
      const newData = [...initialData] as never[];
      newData.splice(
        targetRowIndex,
        0,
        newData.splice(draggedRowIndex, 1)[0],
      );
      const [minIndex, maxIndex] = [draggedRowIndex, targetRowIndex].sort(
        (a, b) => a - b,
      );
      const rowTypeUpdateData = newData.map(
        (data: CommonObject, index: number) => {
          if (
            index >= minIndex &&
            index <= maxIndex &&
            data.ROWTYPE !== RowType.ADD &&
            data.ROWTYPE !== RowType.DELETE
          ) {
            const newRow = {
              ...data,
              ROWTYPE: RowType.NORMAL,
            };
            if (customEqual(newRow, backupData[index])) {
              return newRow;
            }
            return { ...data, ROWTYPE: RowType.UPDATE };
          }
          return data;
        },
      );
      setData([...rowTypeUpdateData]);
    },
    [initialData],
  );

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => tableContainerRef.current,
    count: memoizedRows.length,
    estimateSize: () => ROWHEIGHT,
    overscan: 10,
  });
  const getVirtualProperty = (
    virtualizer: Virtualizer<HTMLDivElement, Element>,
  ) => {
    const { getVirtualItems: virtualRows, getTotalSize: totalSize } =
      virtualizer;

    const paddingTop =
      virtualRows().length > 0 ? virtualRows()[0].start || 0 : 0;
    const paddingBottom =
      virtualRows().length > 0
        ? totalSize() - virtualRows()[virtualRows().length - 1].end || 0
        : 0;
    const padding = {
      top: paddingTop,
      bottom: paddingBottom,
    };

    return { virtualRows, padding };
  };
  const { virtualRows, padding } = getVirtualProperty(rowVirtualizer);
  const handleScroll: UIEventHandler<HTMLDivElement> = e => {
    if (fetchScroll) {
      fetchScroll(e.target);
    }
  };

  const handleStartIndex = (e: CustomMouseEvent, index: StartIndex) => {
    // e.preventDefault();

    setDrag(true);
    setStartIndex(index);
    setEndIndex(index);
  };

  const handleEndIndex = (e: CustomMouseEvent) => {
    e.preventDefault();

    setDrag(false);
  };

  const handleMouseMove = (index: StartIndex) => {
    if (drag) {
      setEndIndex(index);
    }
  };

  const isDragedCell = (index: StartIndex) => {
    const { row, column } = index;
    const isFirst = startIndex === endIndex;
    return (
      !isFirst &&
      row >= startIndex.row &&
      row <= endIndex.row &&
      column >= startIndex.column &&
      column <= endIndex.column
    );
  };

  const handlePaste = (e: ClipboardEvent<HTMLTableElement>) => {
    const pasteData = pasteClipboardData(
      e,
      initialData,
      startIndex,
      columnKeys,
    );
    setData(pasteData);
  };

  const handleCopy = async () => {
    const excelData = copyClipboardData(
      initialData,
      columnKeys,
      startIndex,
      endIndex,
    );

    navigator.clipboard.writeText(excelData);
    toast.info('데이터가 복사되었습니다');
  };

  useEffect(() => {
    if (fetchScroll) {
      fetchScroll(tableContainerRef);
    }
  }, [fetchScroll]);

  useEffect(() => {
    if (initialData.length > 0 && initialData[0].ROWTYPE === RowType.ADD) {
      tableContainerRef.current?.scrollTo({ behavior: 'smooth', top: 0 });
    } else if (
      initialData.length > 0 &&
      initialData[initialData.length - 1].ROWTYPE === RowType.ADD
    ) {
      tableContainerRef.current?.scrollTo({
        behavior: 'smooth',
        top: Number.MAX_SAFE_INTEGER,
      });
    }
  }, [initialData]);

  return (
    <DataTableContainer>
      {headless ? null : (
        <TableTop
          title={title}
          count={initialData.length}
          filterFlag={filterFlag}
          handleFilterFlag={handleFilterFlag}
          refreshTable={refreshTable}
          editable={editable}
          showToggle={showToggle}
        />
      )}

      <DndProvider backend={HTML5Backend}>
        <DataTableScrollBox
          ref={tableContainerRef}
          style={{ height: tableHeight }}
          onScroll={fetchScroll ? handleScroll : undefined}
        >
          <DataTable
            onPaste={handlePaste}
            onCopy={handleCopy} // 추가
          >
            <TableHead
              headerGroups={mergeHeaderGropus}
              tableMeta={tableMeta}
            />
            {customHeader?.(mergeHeaderGropus[0])}
            {memoizedRows.length === 0 ? (
              <EmptyBody
                colSpan={columns.length}
                tableHeight={tableHeight}
              />
            ) : (
              <TableBody
                padding={padding}
                virtualRows={virtualRows}
                memoizedRows={memoizedRows}
                draggable={draggable}
                reorderRow={reorderRow}
                handleStartIndex={handleStartIndex}
                handleEndIndex={handleEndIndex} // 추가
                handleMouseMove={handleMouseMove} // 추가
                isDragedCell={isDragedCell} // 추가
                backupData={backupData}
              />
            )}
          </DataTable>
        </DataTableScrollBox>
      </DndProvider>
    </DataTableContainer>
  );
};

export default Table;
