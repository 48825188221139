import { IHeaderUserInfoMenuProps } from 'models';
import { BiKey, BiLogOut } from 'react-icons/bi';

const HeaderUserInfoMenu = ({
  handleEditPassword,
  handleLogout,
}: IHeaderUserInfoMenuProps): JSX.Element => {
  return (
    <div>
      <button
        className='info-menu password'
        onClick={handleEditPassword}
      >
        <BiKey />
        비밀번호 변경
      </button>
      <button
        className='info-menu logout'
        onClick={handleLogout}
      >
        <BiLogOut className='rotate-180' />
        로그아웃
      </button>
    </div>
  );
};

export default HeaderUserInfoMenu;
