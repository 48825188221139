import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IPlotSaveDataWithValueList, IPlotValue } from 'models';
import { client } from 'utils';

function getIframePlotValue(plotValue: IPlotValue, iframeId: string) {
  const iframe = document.getElementById(iframeId) as HTMLIFrameElement;
  if (!iframe.contentDocument) {
    return plotValue;
  }
  const inputTags = iframe.contentDocument.querySelectorAll(
    "input[type='hidden']",
  );
  console.log(inputTags);
  const inputList = Array.from(inputTags) as HTMLInputElement[];
  const inputValue = inputList.reduce((prev, current) => {
    if (current.value !== 'no') {
      const plotId =
        iframeId === 'totalPlot'
          ? current.id.replace('_TOTAL', '')
          : current.id.replace('_TREND', '');
      prev = {
        ...prev,
        [plotId]: current.value,
      };
      return prev;
    }
    return prev;
  }, plotValue);

  return inputValue;
}

function plotSpecMutation(
  options: UseMutationOptions<
    unknown,
    Error,
    IPlotSaveDataWithValueList,
    unknown
  >,
) {
  return useMutation({
    mutationFn: async (plotData: IPlotSaveDataWithValueList) => {
      const convertPlotData: IPlotSaveDataWithValueList = {
        ...plotData,
        valueList: [
          getIframePlotValue(plotData.valueList[0], 'totalPlot'),
          getIframePlotValue(plotData.valueList[1], 'trendPlot'),
        ],
      };
      console.log(convertPlotData);
      const response = await client.post(
        '/api/teg/08/spec',
        convertPlotData,
      );
      return response;
    },
    ...options,
  });
}

export default function usePlotSpecMutation() {
  const queryClient = useQueryClient();

  return plotSpecMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['plot', 'spec'] });
    },
  });
}
