import tw from 'twin.macro';
import styled from 'styled-components';

export const SideContainer = styled.div`
  ${tw`
    relative
    border-r
    border-slate-300
    transform
    duration-300
    flex-shrink-0
  `}
`;

export const SideSearchBox = styled.div`
  ${tw`
    w-[95%]
    flex
    items-center
    justify-between
    rounded-lg
    m-auto
    mt-1
    py-2
    bg-slate-200
  `}

  svg {
    ${tw`
      text-blue-950
      w-[20px]
      h-[20px]
      mr-1
      cursor-pointer
    `}
  }

  input {
    ${tw`
      w-[80%]
      px-2
      text-sm
      bg-slate-200
      outline-none
    `}
  }
`;

export const SearchList = styled.div`
  ${tw`
      absolute
      bg-slate-200
      w-[95%]
      top-[2rem]
      z-10
      rounded-b-lg
      px-1
      py-1
    `}

  .search-item {
    ${tw`
      rounded-lg
      p-1
      cursor-pointer
      transform
      duration-100
      text-sm

      hover:bg-blue-950
      hover:text-white
    `}
  }
`;

export const SideMenuList = styled.ul`
  ${tw`
    w-full
    h-[50%]
    mt-1
  `}

  li {
    ${tw`
      px-2
    `}
  }
`;

export const SideMenuItem = styled.div`
  ${tw`
    w-full
    flex
    justify-between
    items-center
    p-1.5
    text-sm
    rounded-lg

    hover:bg-slate-200
  `}

  &.active {
    ${tw`
      bg-blue-950
      text-white
    `}
  }

  svg {
    ${tw`
      w-[20px]
      h-[20px]
      transform
      duration-150
    `}
  }
`;

export const TimeBox = styled.div`
  ${tw`
      absolute
      bg-slate-200
      w-[100%]
      bottom-0
      z-10
      px-2
      py-2
      flex
    `}

  .form-span {
    ${tw`
      rounded-lg
      p-1
      transform
      duration-100
      text-sm
      mr-auto
    `}
  }

  .time-span {
    ${tw`
      rounded-lg
      p-1
      transform
      duration-100
      text-sm
      bg-white
    `}
  }
`;

export const RenewDiv = styled.div<{ $openInfo: boolean }>`
  ${tw`
    absolute
    w-[200px]
    bottom-[3rem]
    left-[50%]
    translate-x-[-50%]
    rounded-lg
    bg-slate-100
    border
    border-slate-300
    p-1
    text-blue-950
    text-sm
    transform
    duration-200
    z-10
  `}
  ${({ $openInfo }) =>
    $openInfo ? tw`opacity-100` : tw`opacity-0 scale-0`}

  .renew {
    ${tw`
      flex
      flex-col
      items-center
      justify-center
      p-1
      z-20
    `}
    .renew-etc {
      ${tw`
        text-xs
      `}
    }
    .renew-id {
      ${tw`
        text-base
        font-bold
      `}
    }
  }
  .info-menu {
    ${tw`
      flex
      items-center
      w-full
      gap-x-1
      p-1
      rounded-lg
      cursor-pointer

      hover:text-white
    `}
    svg {
      ${tw`
        w-[20px]
        h-[20px]
      `}
    }
  }
  .password {
    ${tw`
      hover:bg-blue-950
    `}
  }
  .logout {
    ${tw`
      mt-1
      text-red-600

      hover:bg-red-600
    `}
  }
  .info-form {
    ${tw`
      flex
      flex-col
      items-center
      justify-center
    `}
  }
`;
