import { useQuery } from '@tanstack/react-query';
import { UseQueryOptionsWithoutQueryKey } from 'models';
import { ISearchDevice } from 'models/interfaces/deviceInterface';
import { client } from 'utils';

interface IProcessListParam {
  searchData: ISearchDevice;
  options: UseQueryOptionsWithoutQueryKey;
}

function process({ searchData, options }: IProcessListParam) {
  return useQuery({
    queryKey: ['processInDeviceModal', searchData],
    queryFn: async () => {
      const response = await client.get('/api/teg/03/process', {
        params: searchData,
      });
      return response.data.processList;
    },
    ...options,
  });
}

export default function useProcessListInDeviceModal(
  searchData: ISearchDevice,
) {
  return process({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
