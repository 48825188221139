import { IModalButtonProps } from 'models';
import { BiSearch } from 'react-icons/bi';
import { ModalIcon } from 'styles';

const ModalButton = ({
  openModal,
  disabled = false,
}: IModalButtonProps): JSX.Element => {
  return (
    <ModalIcon
      onClick={openModal ? () => openModal(true) : undefined}
      disabled={disabled}
    >
      <BiSearch />
    </ModalIcon>
  );
};

export default ModalButton;
