import { ClipboardEvent, Dispatch, SetStateAction } from 'react';
// import { getAlertMessage } from './dataHelper';
import {
  CommonArray,
  CommonObject,
  CurrentData,
  ITableCellProps,
  ITableSorting,
  RowType,
  StartIndex,
  VirtualProperty,
} from 'models';
import { Virtualizer } from '@tanstack/react-virtual';
import { SetterOrUpdater } from 'recoil';
import { toast } from 'react-toastify';
import { customEqual } from 'utils';
import { ColumnDef } from '@tanstack/react-table';

/**
 * 새로운 행 추가 함수
 * @param {Dispatch<SetStateAction<CommonArray>>} setData 테이블 initialData setter
 * @param {CommonObject} newData 테이블 row에 해당하는 빈 객체
 */
export function addRowData(
  setData: Dispatch<SetStateAction<CommonArray>>,
  setCurrent: Dispatch<SetStateAction<CurrentData>>,
  newData: CommonObject,
  lastIndex: number,
) {
  setData((old: CommonArray) => [...old, newData]);
  setCurrent?.({ index: lastIndex, data: newData });
}

/**
 * 선택된 행의 데이터를 복사하여 새로운 행을 추가하는 함수
 * @param {Dispatch<SetStateAction<CommonArray>>} setData 테이블 initialData setter
 * @param {CommonObject} newData 선택한 row
 */
export function duplicateData(
  setData: Dispatch<SetStateAction<CommonArray>>,
  newData: CommonObject,
) {
  const duplicateData = { ...newData, ROWTYPE: RowType.ADD };
  setData((old: CommonArray) => [...old, duplicateData]);
}

/**
 * 단일 행 삭제 함수
 * @param {number} selectData 선택된 행
 * @param {CommonArray} initialData 테이블의 전체 데이터 배열
 * @returns {CommonArray} 선택된 행을 제외한 데이터 배열
 */
export function removeRowData(
  selectData: CurrentData | CurrentData[],
  initialData: CommonArray,
): CommonArray {
  const selectDataList = Array.isArray(selectData)
    ? selectData
    : [selectData];
  const removedData = selectDataList.reduce((acc, current) => {
    const currentIndex = current.index;
    const currentData = current.data;
    if (!currentData) {
      toast.warning('삭제할 대상이 없습니다.');
      return acc;
    }

    // 추가 행 삭제
    if (initialData[currentIndex]?.ROWTYPE === RowType.ADD) {
      return initialData.filter((_, index) => index !== currentIndex);
    }

    // 삭제대상 복구
    if (initialData[currentIndex]?.ROWTYPE === RowType.DELETE) {
      return acc.map((data, index) => {
        if (index === currentIndex) {
          return { ...data, ROWTYPE: RowType.NORMAL };
        }
        return data;
      });
    }

    const filteredData = acc.map((row, index) => {
      if (currentIndex === index) {
        return { ...row, ROWTYPE: RowType.DELETE };
      }
      return row;
    });

    return filteredData;
  }, initialData);

  return removedData;
}

/**
 * 정보가 변경된 테이블 데이터 배열을 반환하는 함수
 * @param {CommonArray} initialData 테이블의 전체 데이터 배열
 * @param {string[]} checkKeys 유효성 검사가 필요한 Key 값 배열
 * @param {boolean} notNormal RowType.NORMAL까지 포함할지 여부
 * @returns {CommonArray} API를 통해 서버에 전달하여 저장할 데이터 배열
 */
export function saveTableData(
  initialData: CommonArray,
  checkKeys: string[] | null = null,
  notNormal = true,
): CommonArray {
  // normal이 아닌 객체가 없다면 save를 수행하지 않아야함
  const isEmpty = initialData.find(row => row.ROWTYPE !== RowType.NORMAL);
  let targetData = [] as CommonArray;

  if (!isEmpty) {
    toast.warning('저장할 내용이 없습니다.');
    return [];
  }

  if (notNormal) {
    targetData = initialData.filter(row => row.ROWTYPE !== RowType.NORMAL);
  } else {
    targetData = initialData;
  }

  if (checkKeys) {
    for (const target of targetData) {
      const keys = Object.keys(target);

      for (const key of keys) {
        if (
          checkKeys.includes(key) &&
          !target[key] &&
          target.ROWTYPE !== RowType.DELETE
        ) {
          toast.warning('입력되지 않은 필드가 있습니다.');
          return [];
        }
      }
    }
  }

  return targetData;
}

/**
 * 선택된 행 수정 함수
 * @param {CurrentData} target 선택된 행
 * @param {CurrentData} current 현재 행
 * @param {Dispatch<SetStateAction<CurrentData>>} setCurrent 현재 행의 정보를 수정하는 setter
 */
export function changeCurrent(
  target: CurrentData,
  current: CurrentData,
  setCurrent: Dispatch<SetStateAction<CurrentData>>,
) {
  if (current !== target) {
    setCurrent(target);
  } else {
    const emptyCurrent: CurrentData = { index: 0, data: {} };
    setCurrent(emptyCurrent);
  }
}

/**
 * 테이블 셀에서 직접 변경된 데이터를 반영하는 함수
 * @param {Dispatch<SetStateAction<CommonArray>>} setData 테이블 전체 데이터 setter
 * @param {number} rowIndex 변경된 데이터 행의 인덱스
 * @param {string} columnId 해당 셀의 컬럼 header명으로 객체의 key값으로 사용
 * @param {any} value 변경된 데이터
 */
export function editCellValue(
  setData: Dispatch<SetStateAction<CommonArray>>,
  rowIndex: number,
  columnId: string,
  value: any,
  rowRef: CommonObject,
) {
  setData(old =>
    old.map((oldRow, index) => {
      if (index === rowIndex) {
        //초기값과 같을 때,
        const newRow = {
          ...oldRow,
          [columnId]: value,
          ROWTYPE:
            oldRow.ROWTYPE === RowType.DELETE
              ? oldRow.ROWTYPE
              : RowType.NORMAL,
        };
        if (customEqual(newRow, rowRef)) return newRow;
        //초기값과 다를 때
        return {
          ...oldRow,
          [columnId]: value,
          ROWTYPE:
            oldRow.ROWTYPE === RowType.ADD ||
            oldRow.ROWTYPE === RowType.DELETE
              ? oldRow.ROWTYPE
              : RowType.UPDATE,
        };
      }
      return oldRow;
    }),
  );
}

/**
 * 중복된 데이터를 제외한 필터 목록 반환하는 함수
 * @param {CommonArray} dataList 테이블의 원본 데이터
 * @param {string} columnId 필터 기능을 적용하고자 하는 컬럼 아이디
 * @returns {(string | undefined)[]} 중복된 데이터를 제외한 배열
 */
export function getFilterCheckList(
  dataList: CommonArray,
  columnId: string,
): (string | undefined)[] {
  const filterCheckList = Array.from(
    new Set(
      dataList &&
        dataList.map(data => {
          if (data[columnId] !== null) {
            return String(data[columnId]);
          }
        }),
    ),
  );
  return filterCheckList;
}

/**
 * 컬럼의 데이터를 오름차순 또는 내림차순으로 정렬하는 함수
 * @param {ITableSorting[]} sorting 정렬 대상 컬럼들의 아이디가 저장된 배열
 * @param {Dispatch<SetStateAction<ITableSorting[]>>} setSorting 정렬 대상 데이터 setter
 * @param {boolean} type 정렬 타입에 대한 데이터(desc or asc)
 * @param {string} columnId 현재 컬럼 아이디
 */
export function handleSorting(
  sorting: ITableSorting[],
  setSorting: Dispatch<SetStateAction<ITableSorting[]>>,
  type: boolean,
  columnId: string,
) {
  const sortedColumn = sorting.map(col => col.id);
  if (sortedColumn.length === 0 || !sortedColumn.includes(columnId)) {
    setSorting(old => [...old, { id: columnId, desc: type }]);
    return;
  }

  if (sortedColumn.includes(columnId)) {
    setSorting(old =>
      old.map(col =>
        col.id === columnId ? { id: columnId, desc: type } : col,
      ),
    );
  }
}

/**
 * 테이블의 가상 행을 구현하기 위한 속성을 반환하는 함수
 * @param {Virtualizer<HTMLDivElement, Element>} virtualizer useVirtualizer의 반환 객체
 * @link https://tanstack.com/virtual/v3/docs/examples/react/table
 * @returns {VirtualProperty} 가상 행에 대한 데이터 배열과 상, 하단 padding 데이터
 */
export function getVirtualProperty(
  virtualizer: Virtualizer<HTMLDivElement, Element>,
): VirtualProperty {
  const { getVirtualItems: virtualRows, getTotalSize: totalSize } =
    virtualizer;

  const paddingTop =
    virtualRows().length > 0 ? virtualRows()[0].start || 0 : 0;
  const paddingBottom =
    virtualRows().length > 0
      ? totalSize() - virtualRows()[virtualRows().length - 1].end || 0
      : 0;
  const padding = {
    top: paddingTop,
    bottom: paddingBottom,
  };

  return { virtualRows, padding };
}

/**
 * FrameEditBox에서 변경된 데이터를 해당 테이블 데이터에 반영하는 함수
 * @param {CommonObject} formData 현재 선택된 데이터 객체
 * @param {number} dataIndex 현재 선택된 데이터의 index
 * @param {Dispatch<SetStateAction<(tableData: CommonArray) => void>>} setTableData Table initialData의 setter 함수 전달
 * @param {string} compareKey 현재 선택된 데이터와 원본 데이터를 비교할 Key 값
 * @param {string | null} upperKey 상위 코드 선택 기능 존재 시 부여(default: null)
 * @param {((rowType: number) => void) | null} removeUpper upperKey 사용 시 상위코드 변경 사용자 정의 함수
 */
export function saveFormData({
  formData,
  dataIndex,
  setTableData,
  compareKey,
  upperKey,
  removeUpper,
}: {
  formData: CommonObject;
  dataIndex: number;
  setTableData:
    | Dispatch<SetStateAction<(tableData: CommonArray) => void>>
    | SetterOrUpdater<CommonArray>;
  compareKey: string;
  upperKey?: string;
  removeUpper?: (rowType: number) => CommonObject;
}) {
  const isEdit = (oldData: CommonObject) =>
    oldData[compareKey] === formData[compareKey];
  const isAdd = (oldData: CommonObject) =>
    oldData.ROWTYPE === RowType.ADD &&
    oldData.ROWTYPE === formData.ROWTYPE;
  const emptyUpper = upperKey ? formData[upperKey] === '' : false;

  // 내부 로직이 일부 변경될 수 있음
  setTableData((oldData: CommonArray) =>
    oldData.map((data, index) => {
      if (dataIndex !== index) return data;

      if (isAdd(data)) {
        if (emptyUpper && removeUpper) {
          return removeUpper?.(RowType.ADD);
        } else {
          return {
            ...formData,
            ROWTYPE: RowType.ADD,
          };
        }
      }
      if (isEdit(data)) {
        if (emptyUpper && removeUpper) {
          return removeUpper?.(RowType.UPDATE);
        } else {
          return {
            ...formData,
            ROWTYPE: RowType.UPDATE,
          };
        }
      }

      return data;
    }),
  );
}

/**
 * useMemo or memo 사용 시 이전 props와 현재 props를 비교하는 함수
 * @param {Readonly<ITableCellProps>} prevProps 이전 props
 * @param {Readonly<ITableCellProps>} nextProps 현재 porps
 * @returns {boolean} 일치여부
 */
export const isEqual = (
  prevProps: Readonly<ITableCellProps>,
  nextProps: Readonly<ITableCellProps>,
): boolean => {
  return prevProps.row.original === nextProps.row.original;
};

/**
 * No 컬럼 필요 시 테이블 데이터를 매개변수로 전달받아 순번을 부여하는 함수
 * @param {CommonArray} dataList 테이블 원본 데이터
 * @param {boolean} isReverse 역순 여부
 * @returns {CommonArray} 'no' key 값이 추가된 데이터 배열
 */
export const addRowNumber = (
  dataList: CommonArray,
  isReverse = false,
): CommonArray => {
  if (isReverse) {
    return dataList.map((item, index) => ({
      ...item,
      no: dataList.length - index,
    }));
  }
  return dataList.map((item, index) => ({
    ...item,
    no: index + 1,
  }));
};

/**
 * 테이블 데이터 내에서 하위 요소를 가지고 있는 여부를 반환하는 함수.
 * 주로 트리 구조에서 사용되는 함수이다.
 * @param {CommonArray} tableData 테이블의 원본 데이터
 * @param {CommonObject} targetRow 비교할 행
 * @returns {boolean} 하위 요소 여부
 */
export const hasChildren = (
  tableData: CommonArray,
  targetRow: CommonObject,
): boolean => {
  const childrenList = tableData.filter(
    data => data.HRK_MNU_ID === targetRow.MNU_ID,
  );
  return childrenList.length > 0;
};

/**
 * select 태그에 사용할 데이터를 value, label 조합으로 필터링하는 함수
 * compareKey로 지정한 실제 데이터와 PARENTCODE를 비교하여 value, label로 반환
 * 차후 추가적으로 사용할 부분이 생기면 변경 필요
 * @param {any[]} options 원본 옵션 데이터
 * @param {string} valueKey value에 대입할 key
 * @param {string} labelKey label에 대입할 key
 * @param {string} compareKey 비교할 실제 키의 값(데이터를 전달)
 * @returns {CommonArray}
 */
export const filteredOptions = (
  options: any[],
  valueKey: string,
  labelKey: string,
  compareKey = '',
) => {
  if (!options) return [];

  const convertOptions = options.map((option: CommonObject) => ({
    ...option,
    value: option[valueKey],
    label: option[labelKey],
  }));

  if (compareKey === '')
    return [{ value: '', label: '선택' }, ...convertOptions];

  const filteredOptions = convertOptions.filter(
    (option: CommonObject) =>
      String(option.PARENTCODE) === String(compareKey),
  );
  return [{ value: '', label: '선택' }, ...filteredOptions];
};

/**
 * 현재 행의 이전, 다음 행으로 이동하는 함수
 * @param {function} setCurrentData 현재 행 수정 함수
 * @param {array} initialData 테이블 데이터
 * @param {number} rowIndex 현재 로우 인덱스 기준 증감 인덱스
 */
export const moveCurrentData = (
  setCurrentData: Dispatch<SetStateAction<CurrentData>>,
  initialData: CommonArray,
  rowIndex: number,
) => {
  const data = initialData[rowIndex];

  setCurrentData({ index: rowIndex, data });
};

/**
 * 테이블 셀의 다음 행에 동일한 컬럼 Input으로 포커싱을 변경하는 함수
 * @param {number} rowIndex 현재 행 기준 증감된 index
 * @param {string} columnId 현재 컬럼 아이디
 * @param {string} type input id의 접두어
 */
export const changeFocus = (rowIndex: number, columnId: string) => {
  const targetId = `${rowIndex}-${columnId}`;
  const targetInput = document.getElementById(targetId);
  if (targetInput) {
    targetInput?.focus();
    return true;
  } else {
    return false;
  }
};

/**
 * 선택된 행 삭제 시 다음 행으로 이동하며, 마지막 행일 경우 첫번째 행으로 이동
 * @param selectData 현재 선택된 행
 * @param lastIndex 데이터의 마지막 인덱스
 * @param removeData RowType.REMOVE를 제외한 데이터
 * @param setCurrentData 현재 데이터 setter 함수
 */
export const selectNextDataByRemove = (
  selectData: CurrentData,
  lastIndex: number,
  removeData: CommonArray,
  setCurrentData:
    | (
        | Dispatch<SetStateAction<CurrentData>>
        | SetterOrUpdater<CurrentData>
      )
    | (
        | Dispatch<SetStateAction<CurrentData[]>>
        | SetterOrUpdater<CurrentData[]>
      ),
  isArray?: boolean,
) => {
  const lastRow = selectData.index === lastIndex;
  const nextIndex =
    selectData.data?.ROWTYPE === RowType.ADD
      ? selectData.index
      : selectData.index + 1;
  const nextData = { index: nextIndex, data: removeData[nextIndex] };

  if (isArray) {
    (
      setCurrentData as
        | Dispatch<SetStateAction<CurrentData[]>>
        | SetterOrUpdater<CurrentData[]>
    )(
      lastRow
        ? [{ index: nextIndex - 1, data: removeData[nextIndex - 1] }]
        : [nextData],
    );
  } else {
    (
      setCurrentData as
        | Dispatch<SetStateAction<CurrentData>>
        | SetterOrUpdater<CurrentData>
    )(
      lastRow
        ? { index: nextIndex - 1, data: removeData[nextIndex - 1] }
        : nextData,
    );
  }
};

/**
 * Columns를 기반으로 각 컬럼의 accessorKey를 수집하여 배열로 반환하는 함수
 * @param columns 테이블 컬럼 객체 배열
 * @return string[] 수집한 accessorKey 배열
 */
export function getColumnsAccessorKey(columns: ColumnDef<unknown, any>[]) {
  const columnKeys = columns.reduce(
    (acc: string[], column: CommonObject) => {
      const isAccessorColumn = Object.hasOwn(column, 'accessorKey');
      const isGroupColumn =
        !isAccessorColumn && Object.hasOwn(column, 'columns');

      if (isGroupColumn) {
        const childKeys = column.columns.map(
          (col: CommonObject) => col.accessorKey,
        );
        return [...acc, ...childKeys];
      }

      return [...acc, column.accessorKey];
    },
    [],
  );

  return columnKeys;
}

/**
 * 클립보드 데이터를 객체 배열로 변환하여 반환하는 함수
 * @param {ClipboardEvent} e 클립보드 이벤트 객체
 * @param {T[]} tableData 테이블 원본 데이터
 * @param {StateSetter<T[]>} setTableData 테이블 데이터 setter
 * @param {StartIndex} startIndex 로우 및 컬럼 시작 인덱스
 * @param {string[]} columnKeys 수집한 컬럼 키 배열
 * @return T[] 변환된 테이블 데이터
 */
export function pasteClipboardData<T extends CommonObject>(
  e: ClipboardEvent<HTMLTableElement>,
  tableData: T[],
  startIndex: StartIndex,
  columnKeys: string[],
) {
  const parser = new DOMParser();
  const copiedHtml = e.clipboardData.getData('text/html');
  const copiedDocument = parser.parseFromString(copiedHtml, 'text/html');

  const rows = Array.from(copiedDocument.querySelectorAll('tr'));

  // 내부 데이터 가공
  const copiedData = rows.map(row => {
    const cells = Array.from(row.querySelectorAll('td'));
    let currentIndex = startIndex.column;

    const tempData = cells.reduce((acc, cell) => {
      if (currentIndex === columnKeys.length) return acc;

      const cellData = {
        ...acc,
        [columnKeys[currentIndex]]: cell.textContent,
        ROWTYPE: RowType.UPDATE,
      };
      currentIndex++;

      return cellData;
    }, {});

    return tempData;
  });

  const lastIndex = startIndex.row + copiedData.length - 1;
  let rowIndex = 0;
  const updatedData = tableData.map((old, index) => {
    const newData = copiedData[rowIndex];

    if (index >= startIndex.row && index <= lastIndex) {
      rowIndex++;
      return { ...old, ...newData };
    }
    return old;
  });

  return updatedData;
}

/**
 * 테이블의 드래그 범위 내 데이터를 클립보드에 복사하여 액셀데이터로 변환하는 함수
 * @param {T[]} tableData 테이블 원본 데이터
 * @param {string[]} columnKeys 수집한 컬럼 키 배열
 * @param {StartIndex} startIndex 로우 및 컬럼 시작 인덱스
 * @param {StartIndex} endIndex 로우 및 컬럼 끝 인덱스
 * @return T[] 변환된 액셀 데이터
 */
export function copyClipboardData<T extends CommonObject>(
  tableData: T[],
  columnKeys: string[],
  startIndex: StartIndex,
  endIndex: StartIndex,
) {
  const copyData = tableData.reduce(
    (acc: string[][], row, index: number) => {
      const isStartRow = index >= startIndex.row;
      const isEndRow = index <= endIndex.row;

      if (isStartRow && isEndRow) {
        const cells = columnKeys.reduce(
          (prev: string[], col, i: number) => {
            const isStartColumn = i >= startIndex.column;
            const isEndColumn = i <= endIndex.column;

            if (isStartColumn && isEndColumn) {
              const currentCell = row[col];
              return [...prev, currentCell];
            }
            return prev;
          },
          [],
        );
        acc.push(cells);
      }
      return acc;
    },
    [],
  );

  return copyData.map(lines => lines.join('\t')).join('\n');
}
