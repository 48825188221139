import { useMyMenu } from 'apis';
import { ICommonMenu, sideMenuCategory } from 'models';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { layoutState, menuState, selectState } from 'store';
import { MenuBox, MenuItem } from 'styles';

const TabMenu = (): JSX.Element => {
  const [sideStyle, sideOpen] = useRecoilValue(layoutState.sideOpenStyle);
  const [selectMenu, setSelectMenu] = useRecoilState(
    selectState.selectMenu,
  );

  const setMyMenu = useSetRecoilState(menuState.myMenu);
  const { data: myMenu } = useMyMenu();

  const handleSelectCategory = (menuId: string) => {
    setSelectMenu(menuId);
  };
  useEffect(() => {
    if (myMenu) {
      const myMenuList = myMenu as ICommonMenu[];
      setMyMenu(myMenuList.filter(menu => menu.USE_YN === '1'));
    }
  }, [myMenu]);

  return (
    <MenuBox style={sideStyle}>
      {sideMenuCategory.map(category => (
        <MenuItem
          key={category.id}
          className={`
            ${category.class} 
            ${selectMenu === category.id ? category.active : undefined} 
            ${sideOpen ? 'scale-100' : 'scale-0'}
          `}
          onClick={() => handleSelectCategory(category.id)}
        >
          {category.name}
        </MenuItem>
      ))}
    </MenuBox>
  );
};

export default TabMenu;
