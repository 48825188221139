import { createColumnHelper } from '@tanstack/react-table';
import { useChapterKeyList, useChapterKeyMutation } from 'apis';
import {
  ReadOnlyCheckCell,
  StatusCell,
  StatusIcon,
  Table,
  TextCell,
} from 'components';
import { useTableData } from 'hooks';
import {
  Columns,
  CommonObject,
  CurrentData,
  IChapterKey,
  ITableContext,
  defaultChapterKey,
} from 'models';
import { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { documentState } from 'store';
import { DataTableContainer } from 'styles';
import {
  TableContext,
  addRowData,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';

const columnHelper = createColumnHelper();
const newChpaterKey = defaultChapterKey;

const TEG10KeyTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchChapterKey = useRecoilValue(documentState.searchChapterKey);
  const currentChapter = useRecoilValue(
    documentState.currentDocumentContent,
  );
  const setSearchChapterKeyValue = useSetRecoilState(
    documentState.searchChapterKeyValue,
  );
  const chapterKeyMutation = useChapterKeyMutation();
  const { data: chapterKeyData } = useChapterKeyList({
    CH_NO: currentChapter?.data?.CH_NO,
    CHR_SNO: currentChapter?.data?.CHR_SNO,
  }) as CommonObject;
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: chapterKeyData?.keyList,
    effect: () => {
      const newChapterKeyList = chapterKeyData.keyList.map(
        (key: CommonObject) => ({
          ...key,
          KEY_TYPE: !key.KEY_TYPE ? 'text' : key.KEY_TYPE,
        }),
      );
      setInitialData(newChapterKeyList);
      setCurrentData({ index: 0, data: newChapterKeyList[0] });
    },
  });
  const [currentData, setCurrentData] = useRecoilState(
    documentState.currentChapterKey,
  );

  const isTitle = currentChapter?.data?.LEVEL === 0;
  const fixedKey = currentData?.data?.ESS_USE === 'Y';
  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      header: () => <StatusIcon />,
      size: 30,
      cell: StatusCell,
    }),
    columnHelper.accessor('FLAG', {
      header: '작성여부',
      cell: ReadOnlyCheckCell,
      size: 60,
    }),
    columnHelper.accessor('KEY', {
      header: '키값',
      size: 150,
      cell: TextCell,
      meta: {
        unmodifiable: true,
      },
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const handleAddData = () => {
    const updatedChapterKey = {
      ...newChpaterKey,
      CHR_SNO: currentChapter?.data?.CHR_SNO,
      KEY_TYPE: 'text',
    };
    addRowData(
      setInitialData,
      setCurrentData,
      updatedChapterKey,
      initialData.length,
    );
  };

  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);

    setInitialData(removedData);
    selectNextDataByRemove(
      selectData,
      initialData.length - 1,
      removedData,
      setCurrentData,
    );
  };

  const handleSaveData = () => {
    const checkKeys = ['KEY'];
    const targetData = saveTableData(initialData, checkKeys);

    if (targetData.length > 0) {
      chapterKeyMutation.mutate(targetData as IChapterKey[]);
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue: ITableContext = {
    handleAddData,
    handleRemoveData,
    handleSaveData,
    currentData,
    setCurrentData,
    customDisabled: {
      add: isTitle,
      remove: isTitle || fixedKey,
      save: isTitle,
    },
  };

  useEffect(() => {
    if (currentData.data) {
      const newSearchChapterKeyValue = {
        ...searchChapterKey,
        KEY: currentData.data.KEY,
      };
      setSearchChapterKeyValue(newSearchChapterKeyValue);
    }
  }, [currentData]);

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        <Table
          title='Key'
          initialData={initialData}
          setData={setInitialData}
          columns={columns}
          showToggle={false}
          tableHeight={tableHeight}
        />
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default TEG10KeyTable;
