import { useQuery } from '@tanstack/react-query';
import { UseQueryOptionsWithoutQueryKey } from 'models';
import { ISearchProcessInModal } from 'models/interfaces/projectInterface';
import { client } from 'utils';

interface IProcessListParam {
  searchData: ISearchProcessInModal;
  options: UseQueryOptionsWithoutQueryKey;
}

function process({ searchData, options }: IProcessListParam) {
  return useQuery({
    queryKey: ['processInModal', searchData],
    queryFn: async () => {
      const response = await client.get('/api/teg/01/modal/process/mid', {
        params: searchData,
      });
      return response.data.midProcessList;
    },
    ...options,
  });
}

export default function useProcessListInModal(
  searchData: ISearchProcessInModal,
) {
  return process({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
