import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchDocument } from 'models';
import { client } from 'utils';

function documentHistoryList({
  searchData,
  options,
}: IQueryParams<ISearchDocument>) {
  return useQuery({
    queryKey: ['doc', 'history', searchData],
    queryFn: async () => {
      const response = await client.get('/api/teg/09/pdk/history', {
        params: searchData,
      });
      return response.data.docHistoryList;
    },
    ...options,
  });
}

export default function useDocumentHistoryList(
  searchData: ISearchDocument,
) {
  return documentHistoryList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
