import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Table, ReadOnlyTextCell } from 'components';
import { TableContext } from 'utils';
import { Columns, CommonObject } from 'models';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTableData } from 'hooks';
import { ITableContext } from 'models';
import { deviceState } from 'store';
import useDeviceList from 'apis/queries/useDeviceList';

const columnHelper = createColumnHelper();

const TEG03ModalDeviceTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchData = useRecoilValue(deviceState.searchDeviceInDeviceModal);
  const deviceResponse = useDeviceList(searchData) as CommonObject;
  const device = deviceResponse?.data?.deviceList;
  const [currentData, setCurrentData] = useRecoilState(
    deviceState.currentModalDeviceData,
  );
  const currentDevice = useRecoilValue(deviceState.currentDeviceData);
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: device,
    atom: deviceState.deviceListInModal,
    effect: () => {
      setInitialData(device);
      if (currentDevice.data.PRC_NO == searchData.PRC_NO) {
        setCurrentData(currentDevice);
      } else {
        setCurrentData({ index: 0, data: device[0] });
      }
    },
  });
  const defaultColumns = [
    columnHelper.accessor('DVC_NM', {
      header: '디바이스',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const contextValue: ITableContext = {
    currentData,
    setCurrentData,
  };

  return (
    <TableContext.Provider value={contextValue}>
      {initialData && (
        <Table
          title='디바이스'
          initialData={initialData}
          setData={setInitialData}
          columns={columns}
          tableHeight={tableHeight}
        />
      )}
    </TableContext.Provider>
  );
};

export default TEG03ModalDeviceTable;
