import { Navigate, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Login from './Login';
import TEG08SubPlot from 'features/frame/TEG08/TEG08SubPlot';
import TEG10Guide from 'features/frame/TEG10/TEG10Guide';

function withAuthCheck(WrappedComponent: React.ComponentType) {
  return () => {
    const accessToken = sessionStorage.getItem('accessToken');
    if (!accessToken) {
      // 로그인 상태가 아니면 로그인 페이지로 리다이렉트
      return (
        <Navigate
          replace
          to='/login'
        />
      );
    }
    // 로그인 상태면 컴포넌트를 그대로 렌더링
    return (
      <>
        <WrappedComponent />
      </>
    );
  };
}

const HomeWithAuth = withAuthCheck(Home);
const TEG08SubPlotWithAuth = withAuthCheck(TEG08SubPlot);
const TEG10GuideWithAuth = withAuthCheck(TEG10Guide);

const AuthorizationRouter = () => {
  return (
    <>
      <Routes>
        <Route
          path='/'
          element={<HomeWithAuth />}
        />
        <Route
          path='/subPlot'
          element={<TEG08SubPlotWithAuth />}
        />
        <Route
          path='/guide'
          element={<TEG10GuideWithAuth />}
        />
        <Route
          path='/login'
          element={<Login />}
        />
      </Routes>
    </>
  );
};

export default AuthorizationRouter;
