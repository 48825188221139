import { useQuery } from '@tanstack/react-query';
import { UseQueryOptionsWithoutQueryKey } from 'models';
import { client } from 'utils';
import { ISearchMask } from 'models/interfaces/maskInterface';

interface IMaskListParam {
  searchData: ISearchMask;
  options: UseQueryOptionsWithoutQueryKey;
}

function mask({ searchData, options }: IMaskListParam) {
  return useQuery({
    queryKey: ['mask', 'maskList', searchData],
    queryFn: async () => {
      const response = await client.get('/api/teg/02/mask', {
        params: searchData,
      });
      return response.data.maskList;
    },
    ...options,
  });
}

export default function useMaskList(searchData: ISearchMask) {
  return mask({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
