import { createColumnHelper } from '@tanstack/react-table';
import { useSearchCombo, useSearchComboMutation } from 'apis';
import {
  DisplayButton,
  ReadOnlyTextCell,
  StatusCell,
  StatusIcon,
  Table,
} from 'components';
import { useTableData } from 'hooks';
import {
  Columns,
  CommonObject,
  CurrentData,
  ISearchComboData,
  RowType,
  defaultSearchComboData,
} from 'models';
import { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { formManagementState } from 'store';
import { DataTableContainer } from 'styles';
import {
  TableContext,
  addRowData,
  exportJsonToSheet,
  removeRowData,
  saveTableData,
  duplicateData,
  selectNextDataByRemove,
} from 'utils';

const columnHelper = createColumnHelper();
const newRow = defaultSearchComboData;

const COCM430M01Table = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchCombo = useRecoilValue(formManagementState.searchCombo);
  const saveComboList = useSearchComboMutation();
  const { data: searchComboData } = useSearchCombo(
    searchCombo,
  ) as CommonObject;
  const [currentData, setCurrentData] = useRecoilState(
    formManagementState.currentSearchCombo,
  );
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: searchComboData,
    atom: formManagementState.searchComboList,
    effect: () => {
      setInitialData(searchComboData);
      setCurrentData({ index: 0, data: searchComboData[0] });
    },
  });

  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 25,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('CMB_CD', {
      header: '콤보코드',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('CMB_NM', {
      header: '콤보명',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.display({
      header: '복사',
      size: 60,
      cell: DisplayButton,
      meta: {
        text: '복사',
        onClick: (row: CommonObject) => duplicateData(setInitialData, row),
        disabled: (row: CommonObject) => row.ROWTYPE === RowType.ADD,
      },
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);

    setInitialData(removedData);
    selectNextDataByRemove(
      selectData,
      initialData.length - 1,
      removedData,
      setCurrentData,
    );
  };

  const handleSaveData = () => {
    const checkKeys = [
      'CMB_CD',
      'CMB_NM',
      'INQ_TGT_SNTX_CONT',
      'INQ_CND_SNTX_CONT',
    ];
    const targetData = saveTableData(initialData, checkKeys);

    if (targetData.length > 0) {
      saveComboList.mutate(targetData as ISearchComboData[]);
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue = {
    handleExport: () =>
      exportJsonToSheet(columns, initialData, '검색콤보'),
    handleAddData: () =>
      addRowData(
        setInitialData,
        setCurrentData,
        newRow,
        initialData.length,
      ),
    handleRemoveData,
    handleSaveData,
    currentData,
    setCurrentData,
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        {initialData && (
          <Table
            title='검색콤보'
            initialData={initialData}
            setData={setInitialData}
            backupData={searchComboData}
            columns={columns}
            editable={true}
            tableHeight={tableHeight}
          />
        )}
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default COCM430M01Table;
