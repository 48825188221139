import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchDepartmentCode } from 'models';
import { client } from 'utils';

function departmentCodeList({
  searchData,
  options,
}: IQueryParams<ISearchDepartmentCode>) {
  return useQuery({
    queryKey: ['department', searchData],
    queryFn: async () => {
      const response = await client.get('/api/ai/bi/010m01/department', {
        params: searchData,
      });
      return response.data.departmentList;
    },
    ...options,
  });
}

export default function useDepartmentCodeList(
  searchData: ISearchDepartmentCode,
) {
  return departmentCodeList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
