import { useQuery } from '@tanstack/react-query';
import {
  ISearchDesignDescription,
  UseQueryOptionsWithoutQueryKey,
} from 'models';
import { client } from 'utils';

interface IDesignListParam {
  searchData: ISearchDesignDescription;
  options: UseQueryOptionsWithoutQueryKey;
}

function design({ searchData, options }: IDesignListParam) {
  return useQuery({
    queryKey: ['desingDescription', searchData],
    queryFn: async () => {
      const response = await client.get('/api/teg/06/dut', {
        params: searchData,
      });
      return response.data;
    },
    ...options,
  });
}

export default function useDesignDescriptionList(
  searchData: ISearchDesignDescription,
) {
  return design({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: searchData.DVC_NO !== '' && searchData.PRJ_NO !== '',
    },
  });
}
