import { useEffect, useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Table, ReadOnlyTextCell } from 'components';
import { TableContext } from 'utils';
import { Columns, CommonObject, defaultCurrentData } from 'models';
import { useRecoilState } from 'recoil';
import { useTableData } from 'hooks';
import { ITableContext } from 'models';
import { descriptionState } from 'store';
import useModuleNameList from 'apis/queries/useModuleNameList';

const columnHelper = createColumnHelper();

const TEG05ModuleTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const [dutMove, setDutMove] = useRecoilState(
    descriptionState.moduleMove,
  );

  const { data: moduleData } = useModuleNameList({
    DVC_NO: dutMove.MV_DVC_NO?.toString(),
  }) as CommonObject;
  const [currentData, setCurrentData] = useState(defaultCurrentData);
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: moduleData,
    effect: () => {
      const moduleList = moduleData.moduleList;
      setInitialData(moduleList);
      setCurrentData({ index: 0, data: moduleList[0] });
    },
  });

  const defaultColumns = [
    columnHelper.accessor('MDL_NM', {
      header: '모듈',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  useEffect(() => {
    setDutMove(prev => ({
      ...prev,
      MV_MDL_ID: currentData?.data?.MDL_ID,
    }));
  }, [currentData]);

  const contextValue: ITableContext = {
    currentData,
    setCurrentData,
  };

  return (
    <TableContext.Provider value={contextValue}>
      {initialData && (
        <Table
          title='모듈'
          initialData={initialData}
          // backupData={backupData}
          setData={setInitialData}
          columns={columns}
          tableHeight={tableHeight}
        />
      )}
    </TableContext.Provider>
  );
};

export default TEG05ModuleTable;
