import { useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  TableContext,
  addRowData,
  exportJsonToSheet,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTableData } from 'hooks';
import {
  SelectCell,
  StatusCell,
  StatusIcon,
  Table,
  TextCell,
} from 'components';
import { DataTableContainer } from 'styles';
import {
  Columns,
  CommonArray,
  CommonObject,
  CurrentData,
  ICommonCode,
  ITableContext,
  defaultCommonCode,
} from 'models';
import { codeManagementState } from 'store';
import { useCommonCodeList, useCommonCodeMutation } from 'apis';

const columnHelper = createColumnHelper();
const newRow: ICommonCode = defaultCommonCode;
const useOptions = [
  { label: '사용', value: 'Y' },
  { label: '사용안함', value: 'N' },
];

const COCM120M01CodeTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchCommonCodeTable = useRecoilValue(
    codeManagementState.searchCommonCodeTable,
  );
  const { data: codeData } = useCommonCodeList(
    searchCommonCodeTable,
  ) as CommonObject;
  const [backupData, setBackupData] = useState<CommonArray>([]);
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: codeData?.commonCodeList,
    atom: codeManagementState.commonCodeList,
    effect: () => {
      const commonCodeList = codeData.commonCodeList;
      setInitialData(commonCodeList);
      setBackupData(commonCodeList);
      setCurrentData({ index: 0, data: commonCodeList[0] });
    },
  });
  const [currentData, setCurrentData] = useRecoilState(
    codeManagementState.currentCommonCode,
  );
  const commonCodeMutation = useCommonCodeMutation();
  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 20,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('CMN_CD', {
      header: '공통코드',
      cell: TextCell,
      filterFn: 'includesString',
      meta: {
        unmodifiable: true,
        required: true,
      },
    }),
    columnHelper.accessor('CMN_CD_NM', {
      header: '공통코드값',
      cell: TextCell,
      filterFn: 'includesString',
      meta: {
        required: true,
      },
    }),
    columnHelper.accessor('CMN_CD_ABV_NM', {
      header: '코드약어',
      cell: TextCell,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('CMN_CD_CONT', {
      header: '공통코드 설명',
      cell: TextCell,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('SRT_SQN', {
      header: '정렬순서',
      size: 100,
      cell: TextCell,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('USE_YN', {
      header: '사용여부',
      size: 100,
      cell: SelectCell,
      enableColumnFilter: false,
      meta: {
        options: useOptions,
      },
    }),
    ...(codeData?.columns || []),
    columnHelper.accessor('OD_CD', {
      header: '구 코드',
      cell: TextCell,
      enableColumnFilter: false,
    }),
  ];
  const columns = useMemo(
    () => defaultColumns,
    [codeData?.columns],
  ) as Columns;

  const handleAddData = () => {
    const modifiedNewRow = {
      ...newRow,
      TP_CD: searchCommonCodeTable.TP_CD,
    };
    addRowData(
      setInitialData,
      setCurrentData,
      modifiedNewRow,
      initialData.length,
    );
  };

  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);

    if (removedData.length > 0) {
      setInitialData(removedData);
      selectNextDataByRemove(
        selectData,
        initialData.length - 1,
        removedData,
        setCurrentData,
      );
    }
  };

  const handleSaveData = () => {
    const checkKeys = ['CMN_CD', 'CMN_CD_NM', 'SRT_SQN', 'USE_YN'];
    const targetData = saveTableData(initialData, checkKeys);

    if (targetData.length > 0) {
      commonCodeMutation.mutate(targetData as ICommonCode[]);
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue: ITableContext = {
    handleExport: () =>
      exportJsonToSheet(columns, initialData, '공통코드'),
    handleAddData,
    handleRemoveData,
    handleSaveData,
    currentData,
    setCurrentData,
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        {initialData && (
          <Table
            title='공통코드'
            initialData={initialData}
            backupData={backupData}
            setData={setInitialData}
            columns={columns}
            editable={true}
            tableHeight={tableHeight}
          />
        )}
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default COCM120M01CodeTable;
