import { useQuery } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import { NumberCell, ReadOnlyNumberCell } from 'components';
import {
  CommonArray,
  CommonObject,
  IQueryParams,
  ISearchPlot,
} from 'models';
import { client } from 'utils';

const columnHelper = createColumnHelper();

function addDescriptionColumns(descriptionList: CommonObject) {
  delete descriptionList.ROWTYPE;
  const descriptionColumns = Object.keys(descriptionList)
    .filter((key: string) => descriptionList[key] !== null)
    .map((key: string) => {
      const columnHeader = descriptionList[key];
      return columnHelper.accessor(key.replace('NM', 'CONT'), {
        header: columnHeader,
        size: 60,
        cell: ReadOnlyNumberCell,
        enableColumnFilter: false,
      });
    });

  return descriptionColumns;
}

function addSpeccodeColumns(specData: CommonArray) {
  const specCodeColumns = specData.map((specCode: CommonObject) => {
    const specId = specCode.CMN_CD_NM;
    const columnId = specId.includes(' ')
      ? specId.replace(' ', '_')
      : specId.includes('/')
        ? specId.replace('/', '_').toUpperCase()
        : specId.toUpperCase();
    return columnHelper.accessor(columnId, {
      header: specCode.VALUE,
      cell: NumberCell,
      size: 60,
      enableColumnFilter: false,
      meta: {
        isFloat: true,
      },
    });
  });

  return specCodeColumns;
}

function reprocucibilitySpecList({
  searchData,
  options,
}: IQueryParams<ISearchPlot>) {
  return useQuery({
    queryKey: ['plot', 'reproducibility_spec', searchData],
    queryFn: async () => {
      const response = await client.get(
        '/api/teg/08/reproducibility/spec',
        {
          params: searchData,
        },
      );

      const { descList, specCodes, specList, plotValList } = response.data;
      const axis = {
        YL: specList[0].SPC_TRN_AXIS_YL,
        YR: specList[0].SPC_TRN_AXIS_YR,
      };
      const descColumns = addDescriptionColumns(descList[0]);
      const specColumns = addSpeccodeColumns(specCodes);

      return {
        specColumns: [...descColumns, ...specColumns],
        specList,
        specCodes,
        axis,
        plotValList,
      };
    },
    ...options,
  });
}

export default function useReprocucibilitySpecList(
  searchData: ISearchPlot,
) {
  return reprocucibilitySpecList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled:
        searchData.DSG_ID !== '' &&
        searchData.MSR_TYPE !== '' &&
        searchData.DUT_ID !== '',
    },
  });
}
