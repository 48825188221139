import FrameEditField from './FrameEditField';
import { Modal, ModalButton } from 'components';
import { useState } from 'react';
import { editValues } from 'utils';
import {
  CODE_TYPE_PARAM,
  CustomChangeEvent,
  ICodeType,
  IFrameUserFieldProps,
} from 'models';
import { EditFieldRow, UserFieldTitle } from 'styles/frameStyles';
import FrameEditInput from './FrameEditInput';
import FrameEditSelect from './FrameEditSelect';

const FrameUserField = ({
  values,
  setValues,
  count,
  typeOptions,
}: IFrameUserFieldProps): JSX.Element => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const editValue = (e: CustomChangeEvent) => editValues(e, setValues);

  const selectByModal = (selectValue: ICodeType) => {
    setValues(old => ({
      ...old,
      [`USR_FLD_${count}_TP_NM`]: selectValue.TP_CD_NM,
      [`USR_FLD_${count}_TP_CD`]: selectValue.TP_CD,
    }));
    setOpenModal(false);
  };

  return (
    <EditFieldRow>
      <UserFieldTitle>사용자 필드{count}</UserFieldTitle>
      <div className='flex-grow'>
        <div className='border-b'>
          <FrameEditField title='필드명'>
            <FrameEditInput
              type='text'
              name={`USR_FLD_${count}_NM`}
              value={values?.[`USR_FLD_${count}_NM`] || ''}
              onChange={editValue}
            />
          </FrameEditField>
        </div>
        <div className='flex'>
          <FrameEditField title='입력형태'>
            <FrameEditSelect
              name={`USR_FLD_${count}_INP_FRM_CD`}
              value={values?.[`USR_FLD_${count}_INP_FRM_CD`] || ''}
              onChange={editValue}
              options={typeOptions}
            />
          </FrameEditField>
          <FrameEditField title='공통코드유형'>
            <FrameEditInput
              type='text'
              value={values?.[`USR_FLD_${count}_TP_NM`] || ''}
              readOnly
            />
            <ModalButton openModal={setOpenModal} />
          </FrameEditField>
        </div>
      </div>
      {openModal && (
        <Modal
          onClose={() => setOpenModal(false)}
          param={CODE_TYPE_PARAM}
          handleSelect={selectByModal}
        />
      )}
    </EditFieldRow>
  );
};

export default FrameUserField;
