export interface ISearchDocumentContent {
  DOC_NO: number;
  DOC_TYPE: string;
  CHR_SNO: number;
  CH_NO: string;
  RUL_NO: string;
  KEY: string;
}

export interface ISearchAppendix {
  DOC_NO: number;
  DOC_TYPE: string;
  CHR_SNO: number;
  CH_NO: string;
}

export interface ISearchDocumentKey {
  CHR_SNO: number;
  CH_NO: string;
}

export interface ISearchDocumentValue {
  DOC_NO: number;
  CHR_SNO: number;
  CH_NO: string;
  KEY: string;
}

export interface ISearchDocumentLayer {
  CHR_SNO: number;
  RUL_NO: string;
}

export interface ISearchRuleDevice {
  DOC_NO: number;
  CHR_SNO: number;
  CH_NO: string;
}

export interface IChapter {
  CHR_SNO: number;
  CH_NM: string;
  CH_NO: string;
  DOC_NO: number;
  DOC_TYPE: string;
  RMK_CONT?: string;
  HRK_CH_NO?: string;
  HRK_SNO?: number;
  LEVEL: number;
  ROWTYPE: number;
}

export enum FIXED_CHAPTER {
  CHAPTER0 = '000000',
  CHAPTER1 = '100000',
}

export enum DESCRIPTION_GROUP {
  DEVICE = 'Device',
  DIODE = 'Diode',
  BJT = 'BJT',
}

export enum DESCRIPTION_ID {
  DEVICE = '311001',
  DIODE = '411001',
  BJT = '511001',
}

export interface IChapterBaseKey {
  CHR_SNO: number;
  DATASET: string;
  KEY: string;
  KEY_TYPE: string;
  ROWTYPE: number;
}

export interface IChapterKey extends IChapterBaseKey {
  ESS_USE: string;
  FLAG: number;
  OLD_KEY: string;
}

export interface IChapterKeyValue extends IChapterBaseKey {
  VALUE: string;
}

export interface IRGDevice {
  CHR_ID: string;
  CHR_SNO: number;
  DOC_NO: number;
  DOC_TYPE: string;
  DVC_CD: string;
  DVC_NM: string;
  HRK_SNO: number;
  ISO_FLAG: string;
  KEY: string;
  NBL_FLAG: string;
  NP_FLAG: string;
  PARAM_KEY: string;
  ROWTYPE: number;
  VALUE: string;
  VOP: string;
  VOP_CD: string;
  VOP_CNT: number;
}

export interface IRuleDescription {
  CHR_SNO: number;
  DCT_FLAG: string;
  DCT_NM: string;
  LAYER: string;
  LYT_RUL?: string;
  OLD_RUL_NO: string;
  RUL_NO: string;
  SYM: string;
  ROWTYPE: number;
}

export interface IRuleLayer {
  LYR_CD: string;
  ROWTYPE: number;
}

export interface IRuleLayerParam {
  rule: IRuleDescription;
  layerList: IRuleLayer[];
}

export interface IChapterImageInfo {
  DOC_NO: number;
  DOC_TYPE: string;
  CHR_SNO: number;
  KEY: string;
  EXT: string;
  IMG_SNO: number;
}

export interface IChapterFileUploadParam {
  image: File;
  imageInfo: IChapterImageInfo;
}

export interface IAppendix {
  DSG_ID: string;
  DVC_ID: string;
  FIG: string;
  SP: string;
  VOP_CD: string;
  ROWTYPE: number;
}

export interface IAppendixParam {
  CHR_SNO: number;
  appendixList: IAppendix[];
}

export interface IWordDocumentParam {
  DOC_NO: number;
  DOC_TYPE: string;
  CHR_NO: string;
}
