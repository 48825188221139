import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { client } from 'utils';

function bbsUploadMutation(
  options: UseMutationOptions<unknown, Error, unknown>,
) {
  return useMutation({
    mutationFn: async (uploadFile: any) => {
      const file = uploadFile.FILES;
      const bbsDetailInfo = uploadFile.FILE_INFO;
      file.append(
        'FILEINFO',
        new Blob([JSON.stringify(bbsDetailInfo)], {
          type: 'application/json',
        }),
      );
      const response = await client.post(
        '/api/co/cm/800m01/file/upload',
        file,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );
      return response;
    },
    ...options,
  });
}

export default function useBbsUploadMutation() {
  const queryClient = useQueryClient();

  return bbsUploadMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['bbs_id', 'file_list'],
      });
    },
  });
}
