import { useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Table,
  StatusCell,
  StatusIcon,
  SelectCell,
  ReadOnlyTextCell,
  CheckInputCell,
} from 'components';
import {
  TableContext,
  addRowData,
  addRowNumber,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';
import {
  Columns,
  CommonArray,
  CommonObject,
  CurrentData,
  ITableContext,
} from 'models';
import { deviceState } from 'store';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTableData } from 'hooks';
import useDeviceVopList from 'apis/queries/useDeviceVopList';
import { defaultDeviceVop } from 'models/defaults/deviceDefaults';
import useDeviceVopMutation from 'apis/mutations/useDeviceVopMutation';
import { IDeviceVop } from 'models/interfaces/deviceInterface';

const columnHelper = createColumnHelper();
const newRow = defaultDeviceVop;

const TEG03DeviceVopTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchData = useRecoilValue(deviceState.searchVop);
  const vopResponse = useDeviceVopList(searchData) as CommonObject;
  const vop = vopResponse?.data?.vopList;
  const vopCodes = vopResponse?.data?.vopCodes;
  const vopCodeOptions = vopCodes?.map(
    (data: { CMN_CD_NM: string; CMN_CD: string }) => ({
      label: data.CMN_CD_NM,
      value: data.CMN_CD,
    }),
  );
  const [currentData, setCurrentData] = useRecoilState(
    deviceState.currentDeviceVopData,
  );
  const [backupData, setBackupData] = useState<CommonArray>([]);
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: vop,
    atom: deviceState.deviceVopList,
    effect: () => {
      const newData = addRowNumber(vop);
      setInitialData(newData);
      setBackupData(newData);
      setCurrentData({ index: 0, data: newData[0] });
    },
  });

  const saveDeivceList = useDeviceVopMutation();

  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 10,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('no', {
      header: 'NO',
      cell: ReadOnlyTextCell,
      size: 10,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('VOP_CD', {
      header: '동작전압',
      cell: SelectCell,
      filterFn: 'includesString',
      size: 100,
      meta: {
        options: vopCodeOptions,
      },
    }),
    columnHelper.accessor('RLS_FLAG', {
      header: '릴리즈',
      cell: CheckInputCell,
      size: 40,
    }),
  ];
  const columns = useMemo(
    () => defaultColumns,
    [vopCodeOptions],
  ) as Columns;

  const handleAddData = () => {
    const modifiedNewRow = {
      ...newRow,
      DVC_NO: searchData.DVC_NO || '',
      VOP_CD: vopCodeOptions?.[0]?.value || '',
    };
    addRowData(
      setInitialData,
      setCurrentData,
      modifiedNewRow,
      initialData.length,
    );
  };

  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);

    if (removedData.length > 0) {
      setInitialData(removedData);
      selectNextDataByRemove(
        selectData,
        initialData.length - 1,
        removedData,
        setCurrentData,
      );
    }
  };

  const handleSaveData = () => {
    const checkKeys = ['VOP_CD'];
    const deviceVopList = saveTableData(initialData, checkKeys, false);
    const saveData = {
      PRJ_NO: searchData.PRJ_NO as number,
      deviceVopList: deviceVopList as IDeviceVop[],
    };
    if (deviceVopList.length > 0) {
      saveDeivceList.mutate(saveData);
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue: ITableContext = {
    handleAddData,
    handleRemoveData,
    handleSaveData,
    currentData,
    setCurrentData,
    customDisabled: {
      fetched: searchData.DVC_NO === undefined || searchData.DVC_NO === 0,
    },
  };

  return (
    <TableContext.Provider value={contextValue}>
      {initialData && (
        <Table
          title='Vop'
          initialData={initialData}
          backupData={backupData}
          setData={setInitialData}
          columns={columns}
          editable={true}
          tableHeight={tableHeight}
        />
      )}
    </TableContext.Provider>
  );
};

export default TEG03DeviceVopTable;
