import { useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  CheckInputCell,
  ReadOnlyTextCell,
  StatusCell,
  StatusIcon,
  Table,
} from 'components';
import { TableContext, exportJsonToSheet, saveTableData } from 'utils';
import { useRecoilValue } from 'recoil';
import { useTableData } from 'hooks';
import { DataTableContainer } from 'styles';
import { authorityManagementState, systemManagementState } from 'store';
import {
  Columns,
  CommonArray,
  CommonObject,
  CurrentData,
  IAuthority,
  ITableContext,
  IUserAuthority,
  IUserAuthorityMenuWithFlag,
  RowType,
  defaultCurrentData,
} from 'models';
import {
  useAllAuthorityList,
  useUserAuthorityList,
  useUserAuthorityMutation,
} from 'apis';

const columnHelper = createColumnHelper();
const exceptKeys = [
  'no',
  'PSCL_CD',
  'ROWTYPE',
  'RSPOFC_CD',
  'MBL_TEL_NO',
  'USR_NM',
  'USR_ID',
  'DPT_NM',
  'EMP_NO',
  'DPT_CD',
];

const COCM170M01Table = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchUser = useRecoilValue(
    systemManagementState.searchUser('COCM170M01'),
  );
  const { data: authorityColumns } = useAllAuthorityList() as CommonObject;
  const { data: userAuthorityData } = useUserAuthorityList(
    searchUser,
  ) as CommonObject;

  const newColumns = authorityColumns
    ? authorityColumns.map((authority: IAuthority) => {
        return columnHelper.accessor(authority.ATH_ID, {
          header: authority.ATH_NM,
          cell: CheckInputCell,
          enableColumnFilter: false,
        });
      })
    : [];

  const [backupData, setBackupData] = useState<CommonArray>([]);
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: userAuthorityData,
    atom: authorityManagementState.authorityData('COCM170M01'),
    effect: () => {
      const newData = userAuthorityData.map(
        (userAuthority: IUserAuthority, index: number) => ({
          ...userAuthority,
          no: index + 1,
          ATCO010: userAuthority.ATCO010.toString(),
          ATCO020: userAuthority.ATCO020.toString(),
          ATCO030: userAuthority.ATCO030.toString(),
          ATCO040: userAuthority.ATCO040.toString(),
          ATCO090: userAuthority.ATCO090.toString(),
        }),
      );
      setInitialData(newData);
      setBackupData(newData);
      setCurrentData({ index: 0, data: newData[0] });
    },
  });
  const [currentData, setCurrentData] =
    useState<CurrentData>(defaultCurrentData);
  const userAuthorityMutation = useUserAuthorityMutation();

  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 20,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('no', {
      header: 'No',
      cell: ReadOnlyTextCell,
      size: 30,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('DPT_NM', {
      header: '부서',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('EMP_NO', {
      header: '사번',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('USR_NM', {
      header: '사용자명',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('USR_ID', {
      header: 'ID',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
  ];
  const columns = useMemo(
    () => [...defaultColumns, ...newColumns],
    [defaultColumns],
  ) as Columns;

  const handleSaveData = () => {
    const checkKeys = ['PGM_ID', 'PGM_NM', 'USE_YN'];
    const targetData = saveTableData(initialData, checkKeys);

    if (targetData.length > 0) {
      const newDataList = targetData.flatMap(target => {
        const newData = Object.entries(target).reduce(
          (acc, [key, value]) => {
            if (!exceptKeys.includes(key)) {
              const userAuthority = {
                USR_ID: target.USR_ID,
                ROWTYPE: value === '1' ? RowType.ADD : RowType.DELETE,
                ATH_ID: key,
              };
              acc.push(userAuthority);
            }
            return acc;
          },
          [] as CommonArray,
        );
        return newData;
      });

      userAuthorityMutation.mutate(
        newDataList as IUserAuthorityMenuWithFlag[],
      );
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue: ITableContext = {
    handleExport: () =>
      exportJsonToSheet(columns, initialData, '사용자 권한'),
    currentData,
    setCurrentData,
    handleSaveData,
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        <Table
          title='사용자권한'
          initialData={initialData}
          backupData={backupData}
          setData={setInitialData}
          columns={columns}
          tableHeight={tableHeight}
        />
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default COCM170M01Table;
