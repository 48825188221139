import { createColumnHelper } from '@tanstack/react-table';
import useMeasurementFileMutation from 'apis/mutations/useMeasurementFileMutation';
import { CheckInputCell, ReadOnlyTextCell, Table } from 'components';
import { useTableData } from 'hooks';
import {
  Columns,
  CommonArray,
  CommonObject,
  CustomChangeEvent,
  ITableContext,
} from 'models';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { measurementFileState } from 'store';
import {
  TableContext,
  convertStringToHtml,
  customEqual,
  getAlertMessage,
  saveTableData,
} from 'utils';

const columnHelper = createColumnHelper();

const TEG07FileUploadTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchData = useRecoilValue(
    measurementFileState.searchMeasureFile,
  );
  const searchDataRef = useRef(searchData);
  const [currentData, setCurrentData] = useRecoilState(
    measurementFileState.currentMeasureFileData,
  );
  const measureFileList = useRecoilValue(
    measurementFileState.measureFileList,
  );
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: measureFileList,
    atom: measurementFileState.measureFileList,
    effect: () => {
      setInitialData(measureFileList);
      setCurrentData({ index: 0, data: measureFileList[0] });
    },
  });

  const CheckHeader = () => {
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      const checkValue = e.target.checked;
      setInitialData(prevData => {
        const newData = prevData.map(data => ({
          ...data,
          CHECK: checkValue ? 'Y' : 'N',
        }));
        return newData;
      });
    };

    return (
      <>
        <input
          type='checkbox'
          onChange={onChange}
          name='CHECK'
        />
      </>
    );
  };

  const defaultColumns = [
    columnHelper.accessor('CHECK', {
      header: () => <CheckHeader />,
      cell: CheckInputCell,
      size: 20,
    }),
    columnHelper.accessor('FILE_NAME', {
      header: '파일명',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 250,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('FILE_FLAG', {
      header: '파일종류',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 50,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('MDL', {
      header: '모듈',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 50,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('DUT', {
      header: 'DUT',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 50,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('LOT_NO', {
      header: 'LOT 번호',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 80,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('WFR_NO', {
      header: '웨이퍼 번호',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 50,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('MSR_NO', {
      header: '측정순번',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 50,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('STATUS', {
      header: '상태',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 50,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('RMN_CNT', {
      header: '비고',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 250,
      meta: {
        justify: 'center',
      },
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;
  const [measurementFile, setMeasurementFile] = useRecoilState(
    measurementFileState.measurementFile,
  );
  const saveFile = useMeasurementFileMutation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const onchange = (e: CustomChangeEvent) => {
    const inputElement = e.target as HTMLInputElement;
    const selectedFileList = inputElement?.files;
    const uploadFileList = [] as File[];
    // 파일 형식 검사
    if (selectedFileList?.length) {
      for (let i = 0; i < selectedFileList?.length; i++) {
        //
        const file_nm = selectedFileList[i].name;
        let msr_type = '';
        let mdl_id = '';
        let dut_id = '';
        const name = file_nm.split('.');
        // 1. 확장자 확인
        const filext = name[1];
        if (
          filext != 'tlp' &&
          filext != 'dat' &&
          filext != 'tl2' &&
          filext != 'da2'
        ) {
          toast.warning(
            convertStringToHtml(getAlertMessage('ERRO_00300', [file_nm])),
          );
          continue;
        }
        const file_nm_arr = name[0].split('_');
        let msrIndex = -1;
        let sqIndex = 1;
        for (let j = 0; j < file_nm_arr.length; j++) {
          const part = file_nm_arr[j];
          if (part === 'DC' || part === 'TF' || part === 'TR') {
            msrIndex = j;
            break;
          }
        }
        // 2. 측정타입 확인
        if (msrIndex == -1) {
          toast.warning(
            convertStringToHtml(getAlertMessage('ERRO_00310', [file_nm])),
          );
          continue;
        } else if (msrIndex != 0 && msrIndex != 1) {
          toast.warning(
            convertStringToHtml(getAlertMessage('ERRO_00320', [file_nm])),
          );
          continue;
        }
        // 3. 측정순번 확인
        if (msrIndex == 0 && file_nm_arr.length == 6) {
          sqIndex = Number(file_nm_arr[5]);
        } else if (msrIndex == 1 && file_nm_arr.length == 7) {
          sqIndex = Number(file_nm_arr[6]);
        }
        sqIndex = parseInt(sqIndex.toString(), 10);
        // 4. 변수할당
        msr_type = file_nm_arr[msrIndex];
        mdl_id = file_nm_arr[msrIndex + 1];
        dut_id = file_nm_arr[msrIndex + 2];
        // 5. 중복 업로드 확인

        const fir = initialData.find(row => row.FILE_NAME === file_nm);
        if (fir) {
          toast.warning(
            convertStringToHtml(getAlertMessage('ERRO_00330', [file_nm])),
          );
          continue;
        }
        // 6. Type, Module, DUT, MSR_NO 중복 확인
        const arrRows = initialData.find(
          row =>
            row.FILE_FLAG === msr_type &&
            row.MDL === mdl_id &&
            row.DUT === dut_id &&
            row.MSR_NO === sqIndex,
        );
        if (arrRows) {
          toast.warning(
            convertStringToHtml(getAlertMessage('ERRO_00340', [file_nm])),
          );
          continue;
        }
        uploadFileList.push(selectedFileList[i]);
      }
    }

    // 파일 추가
    if (uploadFileList?.length) {
      for (let i = 0; i < uploadFileList?.length; i++) {
        setMeasurementFile((old: any[]) => [...old, uploadFileList[i]]);
        const file_nm = uploadFileList[i].name;
        const name = file_nm.split('.');
        const ext = name[1];
        const file_nm_arr = name[0].split('_');
        let dvc_flag = 0;
        const dvc_msr = file_nm_arr[0];
        if (dvc_msr === 'TF' || dvc_msr === 'TR' || dvc_msr === 'DC') {
          dvc_flag = 0;
        } else {
          dvc_flag = 1;
        }
        const msr_type = file_nm_arr[dvc_flag];
        const mdl = file_nm_arr[dvc_flag + 1];
        const dut = file_nm_arr[dvc_flag + 2];
        let lot_no = '';
        let wfr_no = '';
        let msr_no = '';
        if (ext === 'da2') {
          lot_no = '-';
          wfr_no = '-';
          msr_no = '-';
        } else {
          const noext = file_nm.split('.')[0].split('_');
          lot_no = noext[dvc_flag + 3];
          wfr_no = noext[dvc_flag + 4];
          let sq_no = 1;
          if (noext.length === dvc_flag + 6) {
            sq_no = Number(noext[dvc_flag + 5]);
          }
          msr_no = parseInt(sq_no.toString(), 10).toString();
        }
        const newRow = {
          CHECK: 'Y',
          FILE_NAME: file_nm,
          FILE_FLAG: msr_type,
          MDL: mdl,
          DUT: dut,
          LOT_NO: lot_no,
          WFR_NO: wfr_no,
          MSR_NO: msr_no,
          STATUS: '',
        };
        setInitialData((old: CommonArray) => [...old, newRow]);
      }
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  const handleResetData = () => {
    // 전부 삭제
    saveFile.reset();
    setInitialData([]);
    setMeasurementFile([]);
    setUploadList([]);
    setUploadProgress(0);
  };
  const handleAddData = () => {
    if (searchData.DVC_NO === '') {
      toast.warning(getAlertMessage('ERRO_00350'));
      return;
    }
    fileInputRef?.current?.click();
  };
  const handleRemoveData = () => {
    const deletedFileName: string[] = [];
    setInitialData(prevData => {
      const newData = prevData.filter(data => {
        if (data.CHECK === 'Y') {
          deletedFileName.push(data.FILE_NAME);
          return false;
        }
        return true;
      });
      return newData;
    });
    setMeasurementFile((prevFiles: any[]) => {
      const newFiles = prevFiles.filter(
        data => !deletedFileName.includes(data.name),
      );
      return newFiles;
    });
  };

  const setUploadProgress = useSetRecoilState(
    measurementFileState.measurementFileProgress('progress'),
  );

  const handleSaveData = () => {
    saveTableData(initialData, []);
    const fileWrapper = {
      fileList: measurementFile,
      DVC_NO: searchData.DVC_NO,
    };
    setUploadProgress(0);
    if (fileWrapper.fileList.length > 0) {
      saveFile.mutate(fileWrapper);
    }
  };
  const [uploadList, setUploadList] = useState<CommonArray>([]);
  useEffect(() => {
    if (saveFile?.status === 'success') {
      const newUploadList = (saveFile?.data as CommonObject)?.uploadList;
      setUploadList(newUploadList);
      setUploadProgress(100);
    }
  }, [saveFile]);
  useEffect(() => {
    if (customEqual(searchDataRef.current, searchData)) {
      return;
    }
    searchDataRef.current = searchData;
    handleResetData();
  }, [searchData]);
  useEffect(() => {
    if (uploadList.length > 0) {
      let sucResult = 0;
      let failResult = 0;
      const newData = initialData.map(item => {
        const uploadItem = uploadList.find(
          upload => upload.fileName === item.FILE_NAME,
        );
        if (uploadItem) {
          if (uploadItem.saveStatus === 'true') {
            sucResult++; // 성공한 경우 sucResult 증가
          } else {
            failResult++; // 실패한 경우 failResult 증가
          }
          return {
            ...item,
            STATUS: uploadItem.saveStatus === 'true' ? 'SUCCESS' : 'FAIL',
            RMN_CNT:
              uploadItem.saveStatus === 'true'
                ? item.RMN_CNT
                : uploadItem.failReason,
          };
        }
        return item;
      });
      setInitialData(newData);

      toast.success(
        convertStringToHtml(
          getAlertMessage('INFO_04010', [sucResult, failResult]),
        ),
      );

      setMeasurementFile([]);
      setUploadList([]);
    }
  }, [uploadList]);
  const contextValue: ITableContext = {
    handleResetData,
    handleAddData,
    handleRemoveData,
    handleSaveData,
    currentData,
    setCurrentData,
    customDisabled: {
      fetched:
        searchData.PRJ_NO === '' ||
        searchData.PRC_NO === '' ||
        searchData.DVC_NO === '',
    },
  };
  return (
    <TableContext.Provider value={contextValue}>
      {initialData && (
        <>
          <input
            type='file'
            multiple
            ref={fileInputRef}
            className='hidden'
            onChange={e => onchange(e)}
          />
          <Table
            title='업로드 파일'
            initialData={initialData}
            setData={setInitialData}
            columns={columns}
            tableHeight={tableHeight}
          />
        </>
      )}
    </TableContext.Provider>
  );
};

export default TEG07FileUploadTable;
