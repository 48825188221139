import { useEffect, useMemo, useRef, useState } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import {
  TableContext,
  addRowData,
  addRowNumber,
  customEqual,
  nextSymbol,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTableData } from 'hooks';
import {
  DoubleCell,
  ReadOnlyTextCell,
  StatusCell,
  StatusIcon,
  Table,
  TextCell,
} from 'components';
import { DataTableContainer } from 'styles';
import {
  Columns,
  ITableContext,
  CommonObject,
  CurrentData,
  RowType,
  IDeviceDescription,
  defaultDeviceDescription,
  IDeviceDescriptionWrapper,
  IDescriptionCode,
  CommonArray,
} from 'models';
import { moduleState } from 'store';
import {
  useDeviceDescriptionList,
  useDeviceDescriptionMutation,
} from 'apis';

const columnHelper = createColumnHelper();
const newRow: IDeviceDescription = defaultDeviceDescription;

const TEG04DescriptionTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchModule = useRecoilValue(moduleState.searchModule('teg04'));
  const descriptionResponse = useDeviceDescriptionList(
    searchModule,
  ) as CommonObject;
  const descriptionData = descriptionResponse?.data?.descriptionList;
  const descriptionCodes =
    descriptionResponse?.data?.descriptionCodes?.map(
      (code: { DSC_NM: string; DSC_CD: string }) => ({
        label: code.DSC_NM,
        value: code.DSC_CD,
      }),
    );
  const valueArray = descriptionCodes?.map(
    (code: { value: any }) => code.value,
  );
  const [selectedCodes, setSelectedCodes] = useState<string[]>([]);
  const filteredOptions = useMemo(() => {
    return descriptionCodes?.filter(
      (code: { value: string }) => !selectedCodes.includes(code.value),
    );
  }, [descriptionCodes, selectedCodes]);

  const descriptionCodesMap: { [key: string]: string } = {};
  descriptionResponse?.data?.descriptionCodes?.forEach(
    (code: IDescriptionCode) => {
      descriptionCodesMap[code.DSC_CD] = code.DSC_NM;
    },
  );
  const vopGroup = descriptionResponse?.data?.vopGroup;

  const [backupData, setBackupData] = useState<CommonArray>([]);
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: descriptionData,
    effect: () => {
      const newData = addRowNumber(descriptionData);
      setInitialData(newData);
      setBackupData(newData);
      setCurrentData({ index: 0, data: newData[0] });
    },
  });
  const [currentData, setCurrentData] = useRecoilState(
    moduleState.currentDescription,
  );
  const deviceDescriptionMutation = useDeviceDescriptionMutation();

  const generateSplitInfoColumns = (count: number) => {
    const columns = [];
    for (let i = 1; i <= count; i++) {
      const paddedIndex = i.toString().padStart(2, '0');
      columns.push(
        columnHelper.accessor(`S${paddedIndex}`, {
          header: `S${paddedIndex}`,
          cell: TextCell,
          enableColumnFilter: false,
          size: 30,
          meta: {
            justify: 'end',
          },
        }) as ColumnDef<unknown, any>,
      );
    }
    return columns;
  };

  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 30,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('no', {
      header: 'No',
      size: 30,
      cell: ReadOnlyTextCell,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.group({
      header: 'GDR',
      columns: [
        columnHelper.accessor('GDR_MIN', {
          header: '최소값',
          cell: TextCell,
          enableColumnFilter: false,
          size: 40,
          meta: {
            justify: 'end',
          },
        }) as ColumnDef<unknown, string>,
        columnHelper.accessor('GDR_DFT', {
          header: '기본값',
          cell: TextCell,
          enableColumnFilter: false,
          size: 40,
          meta: {
            justify: 'end',
          },
        }) as ColumnDef<unknown, string>,
      ],
    }),
    ...(vopGroup ? [vopGroup] : []),
    columnHelper.accessor('DSC_CD', {
      header: '속성 명',
      size: 200,
      cell: DoubleCell,
      meta: {
        renderKey: 'DSC_NM',
        options: filteredOptions,
        optionFilter: true,
      },
      enableColumnFilter: false,
    }),
    columnHelper.accessor('SYM', {
      header: '심벌',
      size: 30,
      cell: ReadOnlyTextCell,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.group({
      header: '분할 정보',
      columns: generateSplitInfoColumns(12),
    }),
    columnHelper.accessor('RMK_CNT', {
      header: '비고',
      cell: TextCell,
      enableColumnFilter: false,
      size: 60,
    }),
  ];
  const columns = useMemo(
    () => defaultColumns,
    [vopGroup, filteredOptions],
  ) as Columns;

  const handleAddData = () => {
    const newDscId =
      initialData.reduce((max, item) => {
        return item.DSC_ID > max ? item.DSC_ID : max;
      }, 0) + 1;
    const maxSYM = initialData.reduce((max, item) => {
      if (item.SYM.length > max.length) return item.SYM;
      else if (item.SYM.length === max.length && item.SYM > max)
        return item.SYM;
      return max;
    }, 'A');
    const newSYM = initialData.length > 0 ? nextSymbol(maxSYM) : 'A';
    const modifiedNewRow = {
      ...newRow,
      DSC_CD: filteredOptions?.[0]?.value || '',
      DSC_ID: newDscId,
      DVC_NO: searchModule.DVC_NO,
      ROWTYPE: RowType.ADD,
      SYM: newSYM,
    };
    addRowData(
      setInitialData,
      setCurrentData,
      modifiedNewRow,
      initialData.length,
    );
  };

  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);

    if (removedData.length > 0) {
      setInitialData(removedData);
      selectNextDataByRemove(
        selectData,
        initialData.length - 1,
        removedData,
        setCurrentData,
      );
    }
  };

  const refData = useRef<CommonObject[]>(initialData);
  const useNewSymbols = (data: CommonObject[]) => {
    return useMemo(() => {
      return data.reduce(
        (acc, row, index) => {
          const newSYM = nextSymbol(acc.maxSYM);
          const newRow = {
            ...row,
            SYM: newSYM,
            ROWTYPE:
              row.ROWTYPE === RowType.DELETE
                ? row.ROWTYPE
                : RowType.NORMAL,
          };
          if (customEqual(newRow, backupData[index])) {
            acc.data.push(newRow);
          } else {
            acc.data.push({ ...row, SYM: newSYM });
          }
          acc.maxSYM = newSYM;
          return acc;
        },
        { maxSYM: '', data: [] },
      ).data;
    }, [data]);
  };

  const newSymbolData = useNewSymbols(initialData);

  useEffect(() => {
    if (JSON.stringify(initialData) !== JSON.stringify(newSymbolData)) {
      setInitialData(newSymbolData);
    }
    refData.current = initialData;
  }, [newSymbolData]);

  const handleSaveData = () => {
    const checkKeys = ['DSC_CD', 'DSC_NM'];
    const sortedData: CommonObject[] = initialData.reduce(
      (acc, row) => {
        if (row.ROWTYPE !== RowType.DELETE) {
          const newSYM = nextSymbol(acc.maxSYM);
          const newRow = { ...row, SYM: newSYM };
          acc.maxSYM = newSYM;
          acc.data.push(newRow);
        } else {
          acc.data.push(row);
        }
        return acc;
      },
      { maxSYM: '', data: [] },
    ).data;

    const modifiedData = sortedData.map((item): CommonObject => {
      const updatedItem = Object.entries(item).reduce(
        (acc, [key, value]) => {
          acc[key] = value === '' ? null : value;
          return acc;
        },
        {} as CommonObject,
      );
      updatedItem.DSC_NM =
        item.DSC_CD === 'X99'
          ? item.DSC_NM
          : descriptionCodesMap[item.DSC_CD];

      return updatedItem;
    });

    console.log('속성 저장 목록', modifiedData);
    const targetData = saveTableData(modifiedData, checkKeys, false);
    const saveData = {
      PRJ_NO: searchModule.PRJ_NO,
      descriptionList: targetData,
    };
    if (targetData.length > 0) {
      deviceDescriptionMutation.mutate(
        saveData as IDeviceDescriptionWrapper,
      );
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue: ITableContext = {
    handleAddData,
    handleRemoveData,
    handleSaveData,
    currentData,
    setCurrentData,
  };

  useEffect(() => {
    if (valueArray === undefined || valueArray === null) return;
    const filteredCodes = valueArray.filter((value: string) =>
      initialData.some(row => row.DSC_CD === value && value !== 'X99'),
    );
    setSelectedCodes(filteredCodes);
  }, [initialData?.[currentData.index]?.DSC_CD]);

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        {initialData && (
          <Table
            title='속성'
            initialData={initialData}
            backupData={backupData}
            setData={setInitialData}
            columns={columns}
            editable={true}
            tableHeight={tableHeight}
            draggable={true}
          />
        )}
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default TEG04DescriptionTable;
