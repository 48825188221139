import { useQuery } from '@tanstack/react-query';
import { UseQueryOptionsWithoutQueryKey } from 'models';
import { client } from 'utils';

function menuList(options: UseQueryOptionsWithoutQueryKey) {
  return useQuery({
    queryKey: ['menu', 'appMenu'],
    queryFn: async () => {
      const response = await client.get('/api/main');
      return response.data.menuList;
    },
    ...options,
  });
}

export default function useMenuList() {
  return menuList({
    retry: false,
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
}
