import { CommonObject, IUserInfo } from 'models';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
  key: 'recoil-persist',
  storage: sessionStorage,
});

const accessToken = atom({
  key: 'accessToken',
  default: null,
  effects_UNSTABLE: [persistAtom],
});

const userInfo = atom<IUserInfo | CommonObject>({
  key: 'userInfo',
  default: {},
  effects_UNSTABLE: [persistAtom],
});

const expTime = atom({
  key: 'expTime',
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const loginState = {
  accessToken,
  userInfo,
  expTime,
};
