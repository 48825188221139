import { useState } from 'react';

import { CommonButton, FrameInfo, FrameInfoInput } from 'components';
import COCM01MainTable from './COCM01MainTable';
import COCM01SubTable from './COCM01SubTable';
import { CustomChangeEvent } from 'models';
import { useRecoilState } from 'recoil';
import { editValues } from 'utils';
import { processState } from 'store/processAtoms';
import { useHeight } from 'hooks';
import { FrameContent } from 'styles';

const COCM01 = (): JSX.Element => {
  const getHeight = useHeight();
  const [searchMainProcess, setSearchMainProcess] = useRecoilState(
    processState.searchMainProcess('COCM01'),
  );
  const [values, setValues] = useState(searchMainProcess);
  const editValue = (e: CustomChangeEvent) => editValues(e, setValues);
  const handleSearchType = () => {
    setSearchMainProcess(values);
  };
  return (
    <>
      <FrameInfo>
        <div className='search-form'>
          <div className='input-block'>
            <FrameInfoInput
              title='대공정 코드'
              type='text'
              name='PRS_CD'
              value={values?.PRS_CD || ''}
              onChange={editValue}
            />
          </div>
        </div>
        <div>
          <CommonButton
            title='조회'
            onClick={handleSearchType}
          />
        </div>
      </FrameInfo>
      <FrameContent>
        <div className='w-[500px]'>
          <COCM01MainTable tableHeight={getHeight(650)} />
        </div>
        <div className='grow-col-between'>
          <COCM01SubTable tableHeight={getHeight(650)} />
        </div>
      </FrameContent>
    </>
  );
};

export default COCM01;
