import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { TableContext } from 'utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTableData } from 'hooks';
import { ReadOnlyTextCell, Table } from 'components';
import { DataTableContainer } from 'styles';
import { Columns, ITableContext, CommonObject } from 'models';
import { descriptionState, moduleState } from 'store';
import { useDescriptionDrawList } from 'apis';
import ImageCell from 'components/datatable/cells/ImageCell';

const columnHelper = createColumnHelper();

const TEG05DrawTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchDrawImages = useRecoilValue(
    descriptionState.searchDrawImages,
  );

  const drawResponse = useDescriptionDrawList(
    searchDrawImages,
  ) as CommonObject;

  const drawData = drawResponse?.data;
  const [currentData, setCurrentData] = useRecoilState(
    moduleState.currentDeviceDraw,
  );
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: drawData,
    atom: moduleState.deviceDrawList,
    effect: () => {
      setInitialData(drawData);
      setCurrentData({ index: 0, data: drawData[0] });
    },
  });

  const defaultColumns = [
    columnHelper.accessor('DRW_MN', {
      header: '도면명',
      cell: ReadOnlyTextCell,
      enableColumnFilter: false,
      size: 200,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('FILE_URL', {
      header: '도면 이미지',
      cell: ImageCell,
      enableColumnFilter: false,
      size: 800,
      meta: {
        justify: 'center',
      },
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const contextValue: ITableContext = {
    currentData,
    setCurrentData,
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        {initialData && (
          <Table
            title='도면'
            initialData={initialData}
            setData={setInitialData}
            columns={columns}
            editable={false}
            tableHeight={tableHeight}
            showToggle={false}
          />
        )}
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default TEG05DrawTable;
