import { createColumnHelper } from '@tanstack/react-table';
import useBbsList from 'apis/queries/useBbsList';
import { ReadOnlyTextCell, Table } from 'components';
import { useTableData } from 'hooks';
import { Columns, CommonArray, CommonObject, ITableContext } from 'models';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DataTableContainer } from 'styles';
import {
  TableContext,
  addRowNumber,
  getCurrentDateFormatted,
} from 'utils';
import COCM800M01Modal from './COCM800M01Modal';
import { bbsState } from 'store/bbsAtoms';
import IconCell from 'components/datatable/cells/IconCell';

const columnHelper = createColumnHelper();

const COCM800M01BbsTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const [openModal, setOpenModal] = useState(false);
  const searchBbs = useRecoilValue(bbsState.searchBbs('COCM800M01'));
  const [searchBbsDetail, setSearchBbsDetail] = useRecoilState(
    bbsState.searchBbsDetail('COCM800M01'),
  );
  const { data: bbsData } = useBbsList(searchBbs) as CommonObject;
  const dateTrim = (dataList: CommonArray): CommonArray => {
    return dataList.map(item => ({
      ...item,
      DWUR_DATE: getCurrentDateFormatted(item.DWUR_DATE),
    }));
  };

  const [currentData, setCurrentData] = useRecoilState(
    bbsState.currentBbs('COCM800M01Table'),
  );
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: bbsData,
    atom: bbsState.bbsList,
    effect: () => {
      const newData = addRowNumber(bbsData, true);
      const sOutData = dateTrim(newData);
      setInitialData(sOutData);
      setCurrentData({ index: 0, data: sOutData[0] });
    },
  });
  useEffect(() => {
    setSearchBbsDetail({
      BBS_ID: currentData?.data.BBS_ID,
    });
  }, [currentData]);

  const defaultColumns = [
    columnHelper.accessor('no', {
      header: 'No',
      cell: ReadOnlyTextCell,
      size: 30,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('BBS_NM', {
      header: '제목',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('DWUR_ID', {
      header: '작성자',
      cell: ReadOnlyTextCell,
      size: 50,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('DWUR_DATE', {
      header: '작성일',
      cell: ReadOnlyTextCell,
      size: 50,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('FILE_FLAG', {
      header: '첨부파일',
      cell: IconCell,
      size: 30,
      filterFn: 'includesString',
      meta: {
        type: 'FILE_FLAG',
      },
    }),
    columnHelper.accessor('ANS_FLAG', {
      header: '답변여부',
      cell: IconCell,
      size: 30,
      filterFn: 'includesString',
      meta: {
        type: 'ANS_FLAG',
      },
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const handleDoubleClick = () => {
    setOpenModal(true);
  };

  const handleAddData = () => {
    setCurrentData(old => ({
      ...old,
      data: {
        BBS_ID: 0,
      },
    }));
    setOpenModal(true);
  };
  const contextValue: ITableContext = {
    handleAddData,
    handleDoubleClick,
    currentData,
    setCurrentData,
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        {initialData && (
          <Table
            title='질의응답'
            initialData={initialData}
            backupData={bbsData}
            setData={setInitialData}
            columns={columns}
            editable={true}
            tableHeight={tableHeight}
          />
        )}
      </TableContext.Provider>
      {openModal && (
        <COCM800M01Modal
          onClose={() => setOpenModal(false)}
          currentId={searchBbsDetail.BBS_ID}
        />
      )}
    </DataTableContainer>
  );
};

export default COCM800M01BbsTable;
