import { IFrameEditInputProps } from 'models';
import { UserFieldBox } from 'styles';

const SidePasswordField = ({
  ...props
}: IFrameEditInputProps): JSX.Element => {
  return (
    <div>
      <UserFieldBox>
        <input
          type='password'
          {...props}
        />
      </UserFieldBox>
    </div>
  );
};

export default SidePasswordField;
