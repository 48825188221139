import { IFrameInfoSelectProps } from 'models';

const FrameInfoSelect = ({
  title,
  options,
  width,
  ...props
}: IFrameInfoSelectProps): JSX.Element => {
  return (
    <div style={{ width }}>
      <div className='block-title'>{title}</div>
      <select {...props}>
        {options &&
          options.map((option, index) => (
            <option
              key={option.label + index}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
      </select>
    </div>
  );
};

export default FrameInfoSelect;
