import {
  defaultSearchErrorLog,
  defaultSearchLoginHistory,
  defaultSearchSystemLog,
} from 'models';
import { atom } from 'recoil';

const searchLoginHistory = atom({
  key: 'searchLoginHistory',
  default: defaultSearchLoginHistory,
});

const searchSystemLog = atom({
  key: 'searchSystemLog',
  default: defaultSearchSystemLog,
});

const searchErrorLog = atom({
  key: 'searchErrorLog',
  default: defaultSearchErrorLog,
});

export const historyManagementState = {
  searchLoginHistory,
  searchSystemLog,
  searchErrorLog,
};
