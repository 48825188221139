import { atom, selector } from 'recoil';
import { defaultCurrentData, CommonArray } from 'models';
import { defaultSearchDevice } from 'models/defaults/deviceDefaults';
import { ISearchDevice } from 'models/interfaces/deviceInterface';

const currentDeviceData = atom({
  key: 'currentDeviceData',
  default: defaultCurrentData,
});

const currentDeviceVopData = atom({
  key: 'currentDeviceVopData',
  default: defaultCurrentData,
});

const currentModalProjectData = atom({
  key: 'currentModalProjectData',
  default: defaultCurrentData,
});

const currentModalProcessData = atom({
  key: 'currentModalProcessData',
  default: defaultCurrentData,
});

const currentModalDeviceData = atom({
  key: 'currentModalDeviceData',
  default: defaultCurrentData,
});

const currentDescriptionData = atom({
  key: 'currentDescriptionData',
  default: defaultCurrentData,
});

const currentAnalysisData = atom({
  key: 'currentAnalysisData',
  default: defaultCurrentData,
});

const searchDevice = atom({
  key: 'searchDevice',
  default: defaultSearchDevice,
});

const currentDeviceFileUrl = atom({
  key: 'currentDeviceFileUrl',
  default: { URL: '', FLAG: '0' },
});

const deviceList = atom<CommonArray>({
  key: 'deviceList',
  default: [],
});

const projectListInModal = atom<CommonArray>({
  key: 'projectListInDeviceModal',
  default: [],
});

const processListInModal = atom<CommonArray>({
  key: 'processListInDeviceModal',
  default: [],
});

const deviceListInModal = atom<CommonArray>({
  key: 'deviceListInDeviceModal',
  default: [],
});

const descriptionListInDeviceModal = atom<CommonArray>({
  key: 'descriptionListInDeviceModal',
  default: [],
});

const analysisListInDeviceModal = atom<CommonArray>({
  key: 'analysisListInDeviceModal',
  default: [],
});

const searchProcessInDeviceModal = selector<ISearchDevice>({
  key: 'searchProcessInDeviceModal',
  get: ({ get }): ISearchDevice => {
    const project = get(currentModalProjectData);
    if (project && project.data !== undefined) {
      return {
        PRJ_NO: project.data.PRJ_NO,
        PRC_NO: 0,
        PRS_CD2: '',
        PRJ_NM: '',
      };
    }
    return {
      PRJ_NO: 0,
      PRC_NO: 0,
      PRS_CD2: '',
      PRJ_NM: '',
    };
  },
});

const searchDeviceInDeviceModal = selector<ISearchDevice>({
  key: 'searchDeviceInDeviceModal',
  get: ({ get }): ISearchDevice => {
    const project = get(currentModalProjectData);
    const process = get(currentModalProcessData);
    if (
      project &&
      project.data !== undefined &&
      process &&
      process.data !== undefined
    ) {
      return {
        PRJ_NO: project.data.PRJ_NO,
        PRC_NO: process.data.PRC_NO,
        PRS_CD2: '',
        PRJ_NM: '',
      };
    }
    return {
      PRJ_NO: 0,
      PRC_NO: 0,
      PRS_CD2: '',
      PRJ_NM: '',
    };
  },
});

const searchVop = selector<ISearchDevice>({
  key: 'searchVop',
  get: ({ get }): ISearchDevice => {
    const device = get(currentDeviceData);
    const prjNo = get(searchDevice).PRJ_NO;
    const prsCd2 = get(searchDevice).PRS_CD2;
    if (device && device.data !== undefined) {
      return {
        PRJ_NO: prjNo,
        PRC_NO: device.data.PRC_NO,
        DVC_NO: device.data.DVC_NO,
        PRS_CD2: prsCd2,
        PRJ_NM: '',
      };
    }
    return {
      PRJ_NO: 0,
      PRC_NO: 0,
      DVC_NO: 0,
      PRS_CD2: '',
      PRJ_NM: '',
    };
  },
});

const deviceVopList = atom<CommonArray>({
  key: 'deviceVopList',
  default: [],
});

const searchDeviceDescriptionList = atom<CommonArray>({
  key: 'searchDeviceDescriptionList',
  default: [],
});

const searchDeviceAnalysisList = atom<CommonArray>({
  key: 'searchDeviceAnalysisList',
  default: [],
});

export const deviceState = {
  searchDevice,
  searchVop,
  searchProcessInDeviceModal,
  searchDeviceInDeviceModal,
  deviceList,
  deviceVopList,
  projectListInModal,
  processListInModal,
  deviceListInModal,
  descriptionListInDeviceModal,
  analysisListInDeviceModal,
  searchDeviceDescriptionList,
  searchDeviceAnalysisList,
  currentDeviceData,
  currentDeviceVopData,
  currentModalProjectData,
  currentModalProcessData,
  currentModalDeviceData,
  currentDescriptionData,
  currentAnalysisData,
  currentDeviceFileUrl,
};
