import { useEffect, useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Table, ReadOnlyTextCell } from 'components';
import { TableContext } from 'utils';
import { Columns, CommonObject, defaultCurrentData } from 'models';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTableData } from 'hooks';
import { ITableContext } from 'models';
import { descriptionState } from 'store';
import { useDeviceNameList } from 'apis';

const columnHelper = createColumnHelper();

const TEG05DeviceTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchDevice = useRecoilValue(
    descriptionState.deviceSelector('teg05'),
  );
  const setDutMove = useSetRecoilState(descriptionState.moduleMove);

  const { data: deviceData } = useDeviceNameList(
    searchDevice,
  ) as CommonObject;
  const [currentData, setCurrentData] = useState(defaultCurrentData);
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: deviceData,
    effect: () => {
      const deviceList = deviceData.deviceList;
      setInitialData(deviceList);
      setCurrentData({ index: 0, data: deviceList[0] });
    },
  });

  useEffect(() => {
    setDutMove(prev => ({
      MV_DVC_NO: currentData?.data?.DVC_NO,
      MV_MDL_ID: prev.MV_MDL_ID,
    }));
  }, [currentData]);

  const defaultColumns = [
    columnHelper.accessor('DVC_NM', {
      header: '디바이스',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const contextValue: ITableContext = {
    currentData,
    setCurrentData,
  };

  return (
    <TableContext.Provider value={contextValue}>
      {initialData && (
        <Table
          title='디바이스'
          initialData={initialData}
          setData={setInitialData}
          columns={columns}
          tableHeight={tableHeight}
        />
      )}
    </TableContext.Provider>
  );
};

export default TEG05DeviceTable;
