import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Table, ReadOnlyTextCell } from 'components';
import { TableContext } from 'utils';
import { Columns, CommonObject } from 'models';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
} from 'recoil';
import { useTableData } from 'hooks';
import { ITableContext } from 'models';
import { deviceState } from 'store';
import useProcessListInDeviceModal from 'apis/queries/useProcessListInDeviceModal';

const columnHelper = createColumnHelper();

const TEG03ModalProcessTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchData = useRecoilValue(
    deviceState.searchProcessInDeviceModal,
  );
  const { data: process } = useProcessListInDeviceModal(
    searchData,
  ) as CommonObject;
  const [currentData, setCurrentData] = useRecoilState(
    deviceState.currentModalProcessData,
  );
  const resetDevice = useResetRecoilState(
    deviceState.currentModalDeviceData,
  );
  const prcNo = useRecoilValue(deviceState.searchDevice).PRC_NO;
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: process,
    atom: deviceState.processListInModal,
    effect: () => {
      setInitialData(process);
      let matchingIndex = -1;
      for (let i = 0; i < process.length; i++) {
        if (process[i].PRC_NO == prcNo) {
          matchingIndex = i;
          break;
        }
      }
      if (matchingIndex !== -1) {
        setCurrentData({
          index: matchingIndex,
          data: process[matchingIndex],
        });
      } else {
        setCurrentData({ index: 0, data: process[0] });
      }
      if (process.length === 0) {
        resetDevice();
      }
    },
  });

  const defaultColumns = [
    columnHelper.accessor('PRS_FULL_NM', {
      header: '공정',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const contextValue: ITableContext = {
    currentData,
    setCurrentData,
  };

  return (
    <TableContext.Provider value={contextValue}>
      {initialData && (
        <Table
          title='공정'
          initialData={initialData}
          setData={setInitialData}
          columns={columns}
          tableHeight={tableHeight}
        />
      )}
    </TableContext.Provider>
  );
};

export default TEG03ModalProcessTable;
