import {
  IDesign,
  IDesignDescription,
  IDesignDutFileData,
  ISearchDesignDescription,
} from 'models/interfaces/designInterface';
import { RowType } from 'models/types/commonTypes';

export const defaultDesign: IDesign = {
  DVC_NO: 0,
  DSG_ID: 0,
  DSG_NM: '',
  RLS_FLAG: 'N',
  RPT_PLT_FLAG1: '',
  RPT_PLT_FLAG2: '',
  RPT_PLT_FLAG3: '',
  RPT_PLT_FLAG4: '',
  RPT_PLT_FLAG5: '',
  RPT_PLT_FLAG6: '',
  KEY_DSC_ID: 1,
  SRT_SQN: 0,
  ROWTYPE: RowType.ADD,
};

export const defaultDesignDescription: IDesignDescription = {
  DVC_NO: 0,
  DSG_ID: 0,
  DSC_SNO: 0,
  KEY_DSC_FLAG: 'N',
  DSC_ID: 0,
  DSC_ALIAS: '',
  ROWTYPE: RowType.ADD,
};

export const defaultSearchDesignDescription: ISearchDesignDescription = {
  PRJ_NO: '',
  PRC_NO: '',
  DVC_NO: '',
  DSG_ID: '',
};

export const defaultDesignDutFileData: IDesignDutFileData = {
  DVC_NO: 0,
  MSR_TYPE: '',
  DUT_ID: '',
};
