import { useEffect, useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  TableContext,
  addRowData,
  addRowNumber,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTableData } from 'hooks';
import {
  ReadOnlyTextCell,
  StatusCell,
  StatusIcon,
  Table,
} from 'components';
import { DataTableContainer } from 'styles';
import {
  Columns,
  ITableContext,
  CommonObject,
  CurrentData,
  RowType,
  IDeviceDraw,
  defaultDeviceDraw,
  IDeviceDrawSaveWrapper,
  CommonArray,
} from 'models';
import { moduleState } from 'store';
import { useDeviceDrawList, useDeviceDrawMutation } from 'apis';

const columnHelper = createColumnHelper();
const newRow: IDeviceDraw = defaultDeviceDraw;

const TEG04DrawTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchModule = useRecoilValue(moduleState.searchModule('teg04'));
  const drawResponse = useDeviceDrawList(searchModule) as CommonObject;
  const currentDrawFile = useRecoilValue(moduleState.deviceDrawFile);
  const drawData = drawResponse?.data?.drawList;
  const drawTypeOption = drawResponse?.data?.drawTypeOption;
  const setDrawTypeOption = useSetRecoilState(moduleState.drawTypeOption);
  const drawTypeCodesMap: { [key: string]: string } = {};
  drawTypeOption?.forEach((code: any) => {
    drawTypeCodesMap[code.value] = code.label;
  });
  const [currentData, setCurrentData] = useRecoilState(
    moduleState.currentDeviceDraw,
  );
  const [backupData, setBackupData] = useState<CommonArray>([]);
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: drawData,
    atom: moduleState.deviceDrawList,
    effect: () => {
      const newData = addRowNumber(drawData);
      const modifiedData = newData.map(item => ({
        ...item,
        DRW_TYPE_NM: drawTypeCodesMap[item.DRW_TYPE] || '',
      }));
      setInitialData(modifiedData);
      setBackupData(modifiedData);
      setCurrentData({ index: 0, data: modifiedData[0] });
    },
  });

  const deviceDrawMutation = useDeviceDrawMutation();
  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 30,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('no', {
      header: 'No',
      size: 30,
      cell: ReadOnlyTextCell,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('DRW_TYPE_NM', {
      header: '도면종류',
      size: 80,
      cell: ReadOnlyTextCell,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('DRW_MN', {
      header: '도면명',
      cell: ReadOnlyTextCell,
      enableColumnFilter: false,
      size: 120,
    }),
    columnHelper.accessor('FILE_NM', {
      header: '파일명',
      cell: ReadOnlyTextCell,
      enableColumnFilter: false,
      size: 180,
    }),
  ];
  const columns = useMemo(
    () => defaultColumns,
    [drawTypeOption],
  ) as Columns;

  const handleAddData = () => {
    const newDrwSno =
      initialData.reduce((max, item) => {
        return item.DRW_SNO > max ? item.DRW_SNO : max;
      }, 0) + 1;
    const modifiedNewRow = {
      ...newRow,
      DRW_SNO: newDrwSno,
      DRW_TYPE: drawTypeOption?.[0].value || '',
      DVC_NO: searchModule.DVC_NO,
      ROWTYPE: RowType.ADD,
    };
    addRowData(
      setInitialData,
      setCurrentData,
      modifiedNewRow,
      initialData.length,
    );
  };

  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);

    if (removedData.length > 0) {
      setInitialData(removedData);
      selectNextDataByRemove(
        selectData,
        initialData.length - 1,
        removedData,
        setCurrentData,
      );
    }
  };

  const handleSaveData = () => {
    const checkKeys = ['DRW_MN', 'DVC_NO', 'DRW_TYPE', 'DRW_SNO'];
    const fileInfo = {
      PRJ_NO: searchModule.PRJ_NO,
      DVC_NO: searchModule.DVC_NO,
      ROW: currentData?.data?.DRW_SNO?.toString().padStart(2, '0'),
      DRW_TYPE: initialData[currentData?.index]?.DRW_TYPE,
      FILE_TYPE: currentDrawFile?.name.split('.').pop(),
    };
    const targetData = saveTableData(initialData, checkKeys);
    const saveData = {
      PRJ_NO: searchModule.PRJ_NO,
      drawList: targetData,
    };
    const fileData = {
      file: currentDrawFile,
      drawInfo: fileInfo,
    };
    if (targetData.length > 0) {
      const mutationData = {
        dataObject: saveData,
        fileObject: fileData,
      };
      deviceDrawMutation.mutate(mutationData as IDeviceDrawSaveWrapper);
    } else {
      setInitialData(initialData);
    }
  };

  useEffect(() => {
    setDrawTypeOption(drawTypeOption);
  }, [drawTypeOption]);

  const contextValue: ITableContext = {
    handleAddData,
    handleRemoveData,
    handleSaveData,
    currentData,
    setCurrentData,
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        {initialData && (
          <Table
            title='도면'
            initialData={initialData}
            backupData={backupData}
            setData={setInitialData}
            columns={columns}
            editable={true}
            tableHeight={tableHeight}
          />
        )}
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default TEG04DrawTable;
