import { useQuery } from '@tanstack/react-query';
import { IQueryParams } from 'models';
import { useSetRecoilState } from 'recoil';
import { loginState } from 'store';
import { client } from 'utils';

function refresh({ searchData, options }: IQueryParams<any>) {
  const setExpTime = useSetRecoilState(loginState.expTime);
  return useQuery({
    queryKey: [searchData],
    queryFn: async () => {
      const response = await client.get('/api/refresh');
      const accessToken = response.data.accessToken;
      const expTime = Math.round(response.data.expTime / 60);
      sessionStorage.setItem('accessToken', accessToken);
      setExpTime(expTime);

      return response.data;
    },
    ...options,
  });
}

export default function useRefresh(count: number | undefined) {
  return refresh({
    searchData: count,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: count !== 0,
    },
  });
}
