import { DefaultValue, atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { menuState } from './menuAtoms';
import { TITLE_LEVEL } from 'utils';
import { CommonObject, ISideMenu } from 'models';

const { persistAtom } = recoilPersist({
  key: 'recoil-persist',
  storage: sessionStorage,
});

const selectMenu = atom({
  key: 'selectMenu',
  default: 'system',
  effects_UNSTABLE: [persistAtom],
});

const selectSideMenu = atom<ISideMenu | CommonObject>({
  key: 'selectSideMenu',
  default: {},
  effects_UNSTABLE: [persistAtom],
});

const selectHeaderMenu = atom({
  key: 'selectHeaderMenu',
  default: '100000',
  effects_UNSTABLE: [persistAtom],
});

const selectToggleMenu = atom<string[]>({
  key: 'selectToggleMenu',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

const selectTargetMenu = selector({
  key: 'selectTargetMenu',
  get: ({ get }) => {
    return get(selectSideMenu);
  },
  set: ({ set, get }, newValue: CommonObject) => {
    if (newValue instanceof DefaultValue) return;
    if (Object.keys(newValue).length === 0) {
      set(selectSideMenu, {});
      return;
    }

    if (!newValue) {
      set(selectSideMenu, {});
      set(selectToggleMenu, []);
      return;
    }

    const targetMenu = newValue.value;
    const type = newValue.type;
    const appMenu = get(menuState.appMenu);
    const sideMenu = get(menuState.sideMenu);
    const tabMenu = get(menuState.tabMenu);
    const parentId = targetMenu.HRK_MNU_ID;
    const newToggle = sideMenu
      .filter(menu => menu.PATH.includes(parentId))
      .map(menu => menu.HRK_MNU_ID);
    const currentHeaderMenu = appMenu.find(
      menu => menu.MNU_ID === parentId,
    );

    if (type === 'search') {
      set(menuState.tabMenu, old => [...old, targetMenu]);
    }

    if (type === 'button') {
      const isExist = tabMenu.find(
        menu => menu.MNU_ID === targetMenu.MNU_ID,
      );
      isExist
        ? set(selectSideMenu, targetMenu)
        : set(menuState.tabMenu, old => [...old, targetMenu]);
    }

    set(selectSideMenu, targetMenu);
    if (newValue.value.LEVEL !== TITLE_LEVEL) {
      set(selectToggleMenu, newToggle);
    }
    set(selectHeaderMenu, currentHeaderMenu?.PATH[2]);
  },
});

export const selectState = {
  selectMenu,
  selectSideMenu,
  selectHeaderMenu,
  selectToggleMenu,
  selectTargetMenu,
};
