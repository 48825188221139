import { memo } from 'react';
import { BiRefresh } from 'react-icons/bi';
import { RefreshWrapper } from 'styles';

const RefreshIcon = memo(
  ({ onMouseDown }: { onMouseDown: () => void }): JSX.Element => {
    return (
      <RefreshWrapper>
        <BiRefresh
          className='refresh-icon'
          onMouseDown={onMouseDown}
        />
      </RefreshWrapper>
    );
  },
);

export default RefreshIcon;
