import { CommonArray, defaultCurrentData } from 'models';
import { defaultSearchMeasureFile } from 'models/defaults/mearsurementFileDefaults';
import { atom, atomFamily } from 'recoil';

const searchMeasureFile = atom({
  key: 'searchMeasureFile',
  default: defaultSearchMeasureFile,
});

const currentMeasureFileData = atom({
  key: 'currentMeasureFileData',
  default: defaultCurrentData,
});

const measureFileList = atom<CommonArray>({
  key: 'measureFileList',
  default: [],
});

const measurementFile = atom<any>({
  key: 'measurementFile',
  default: [] as File[],
});

const measurementFileProgress = atomFamily({
  key: 'measurementFileProgress',
  default: 0,
});

export const measurementFileState = {
  searchMeasureFile,
  currentMeasureFileData,
  measureFileList,
  measurementFile,
  measurementFileProgress,
};
