import { ISideMenu } from 'models';
import { useRecoilState, useRecoilValue } from 'recoil';
import { menuState, selectState } from 'store';
import { SideMenuItem } from 'styles';
import { SIDEMENU_PADDING, TITLE_LEVEL } from 'utils';
import { toast } from 'react-toastify';

const SideChildItem = ({ menu }: { menu: ISideMenu }): JSX.Element => {
  const toggleMenu = useRecoilValue(selectState.selectToggleMenu);
  const [selectSideMenu, setSelectSideMenu] = useRecoilState(
    selectState.selectSideMenu,
  );
  const selectMenu = useRecoilValue(selectState.selectMenu);
  const [tabMenu, setTabMenu] = useRecoilState(menuState.tabMenu);
  const isOpenToggle = !toggleMenu.includes(menu.HRK_MNU_ID);
  const isTitleLevel = menu.LEVEL === TITLE_LEVEL;
  const maxLength = 10;

  const handleSelect = () => {
    const isIncludes = tabMenu.find(tab => tab.MNU_ID === menu.MNU_ID);
    if (tabMenu.length === maxLength && !isIncludes) return;
    setSelectSideMenu(menu);
  };

  const addTabMenu = () => {
    const isIncludes = tabMenu.find(tab => tab.MNU_ID === menu.MNU_ID);
    if (tabMenu.length === maxLength && !isIncludes) {
      toast.warning('메뉴는 최대 10개까지만 선택할 수 있습니다.');
      return;
    }
    if (isIncludes) return;

    setTabMenu(old => [...old, menu]);
  };

  const handleClick = () => {
    handleSelect();
    addTabMenu();
  };

  const isSelected = (targetId: string) => {
    return selectSideMenu.MNU_ID === targetId;
  };

  return (
    <SideMenuItem
      className={isSelected(menu.MNU_ID) ? 'active' : undefined}
      style={
        selectMenu === 'system'
          ? {
              paddingLeft: isTitleLevel
                ? undefined
                : SIDEMENU_PADDING * menu.LEVEL,
              position:
                !isTitleLevel && isOpenToggle ? 'absolute' : undefined,
              display: !isTitleLevel && isOpenToggle ? 'none' : undefined,
            }
          : undefined
      }
      onClick={handleClick}
    >
      <span>{menu.MNU_NM}</span>
    </SideMenuItem>
  );
};

export default SideChildItem;
