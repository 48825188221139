import { CustomMouseEvent, IFileUploadModalProps } from 'models';
import { ModalInner, ModalOverlay, ModalWrapper } from 'styles';
import CommonButton from './CommonButton';

const FileUploadModal = ({
  onClose,
  maskCloseable,
  children,
}: IFileUploadModalProps): JSX.Element => {
  const onMaskClick = (e: CustomMouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const close = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <ModalOverlay />
      <ModalWrapper
        onClick={maskCloseable ? onMaskClick : undefined}
        tabIndex={-1}
      >
        <ModalInner
          tabIndex={0}
          className='w-fit'
        >
          <div>{children}</div>
          <div className='flex justify-end mt-2'>
            <CommonButton
              title='닫기'
              color='darkred'
              onClick={close}
            />
          </div>
        </ModalInner>
      </ModalWrapper>
    </>
  );
};

export default FileUploadModal;
