import {
  IAppendix,
  IChapter,
  IChapterKey,
  IChapterKeyValue,
  IRuleDescription,
  IRuleLayer,
  ISearchDocumentContent,
} from 'models/interfaces/documentInterface';
import { RowType } from 'models/types/commonTypes';

export const defaultSearchDocumentContent: ISearchDocumentContent = {
  DOC_NO: 0,
  DOC_TYPE: '',
  CHR_SNO: 0,
  CH_NO: '',
  RUL_NO: '',
  KEY: '',
};

export const defaultChapter: IChapter = {
  CHR_SNO: 0,
  CH_NM: '',
  CH_NO: '',
  DOC_NO: 0,
  DOC_TYPE: '',
  HRK_CH_NO: '',
  HRK_SNO: 0,
  LEVEL: 0,
  ROWTYPE: RowType.ADD,
};

export const defaultChapterKey: IChapterKey = {
  CHR_SNO: 0,
  DATASET: '',
  ESS_USE: '',
  FLAG: 0,
  KEY: '',
  KEY_TYPE: '',
  OLD_KEY: '',
  ROWTYPE: RowType.ADD,
};

export const defaultChapterKeyValue: IChapterKeyValue = {
  CHR_SNO: 0,
  DATASET: '',
  KEY: '',
  KEY_TYPE: '',
  VALUE: '',
  ROWTYPE: RowType.ADD,
};

export const defaultRuleDescription: IRuleDescription = {
  CHR_SNO: 0,
  DCT_FLAG: '',
  DCT_NM: '',
  LAYER: '',
  LYT_RUL: '',
  OLD_RUL_NO: '',
  RUL_NO: '',
  SYM: '',
  ROWTYPE: RowType.ADD,
};

export const defaultRuleLayer: IRuleLayer = {
  LYR_CD: '',
  ROWTYPE: RowType.ADD,
};

export const defaultAppendix: IAppendix = {
  DSG_ID: '',
  DVC_ID: '',
  FIG: '',
  SP: '',
  VOP_CD: '',
  ROWTYPE: RowType.ADD,
};
