import { atom, atomFamily, selector } from 'recoil';
import { defaultCurrentData, CommonArray, IOption, RowType } from 'models';
import {
  ISearchCode,
  ISearchMaskModule,
  ISearchSub,
} from 'models/interfaces/maskInterface';
import {
  defaultMaskFile,
  defaultSearchMask,
  defaultSearchSubsite,
} from 'models/defaults/maskDefaults';

const currentMaskData = atom({
  key: 'currentMaskData',
  default: defaultCurrentData,
});

const currentMaskFileData = atomFamily({
  key: 'currentMaskFileData',
  default: defaultMaskFile,
});

const maskList = atom<CommonArray>({
  key: 'maskList',
  default: [],
});

const searchMask = atom({
  key: 'searchMask',
  default: defaultSearchMask,
});

const currentCodeData = atom({
  key: 'currentCodeData',
  default: defaultCurrentData,
});

const codeList = atom<CommonArray>({
  key: 'codeList',
  default: [],
});

const searchCode = selector<ISearchCode>({
  key: 'searchCode',
  get: ({ get }): ISearchCode => {
    const code = get(currentMaskData);
    if (code && code.data !== undefined) {
      return {
        MSK_CD: code.data.MSK_CD,
      };
    }
    return {
      MSK_CD: 0,
    };
  },
});

const currentMaskModuleData = atom({
  key: 'currentMaskModuleData',
  default: defaultCurrentData,
});

const maskModuleList = atom<CommonArray>({
  key: 'maskModuleList',
  default: [],
});

const searchMaskModule = selector<ISearchMaskModule>({
  key: 'searchMaskModule',
  get: ({ get }): ISearchMaskModule => {
    const maskModule = get(currentCodeData);
    if (maskModule && maskModule.data !== undefined) {
      return {
        MSK_CD: maskModule.data.MSK_CD,
        CODE_SNO: maskModule.data.CODE_SNO,
        ROWTYPE: maskModule.data.ROWTYPE,
      };
    }
    return {
      MSK_CD: 0,
      CODE_SNO: 0,
      ROWTYPE: RowType.ADD,
    };
  },
});

const currentSubData = atom({
  key: 'subCurrentData',
  default: defaultCurrentData,
});

const subList = atom<CommonArray>({
  key: 'subList',
  default: [],
});

const searchSubsiteData = atom({
  key: 'searchSubsiteData',
  default: defaultSearchSubsite,
});

const searchSub = selector<ISearchSub>({
  key: 'searchSub',
  get: ({ get }): ISearchSub => {
    const code = get(currentCodeData);
    const search = get(searchSubsiteData);
    if (code && code.data !== undefined) {
      return {
        MSK_CD: code.data.MSK_CD,
        CODE_SNO: code.data.CODE_SNO,
        MSK_DVC_CD: search.MSK_DVC_CD,
      };
    }
    return {
      MSK_CD: 0,
      CODE_SNO: 0,
      MSK_DVC_CD: '',
    };
  },
});

const maskDeviceOptions = atom<IOption[]>({
  key: 'maskDeviceOptions',
  default: [],
});

export const maskState = {
  currentMaskData,
  maskList,
  searchMask,
  currentCodeData,
  codeList,
  searchCode,
  currentMaskModuleData,
  maskModuleList,
  searchMaskModule,
  currentSubData,
  subList,
  searchSub,
  currentMaskFileData,
  maskDeviceOptions,
  searchSubsiteData,
};
