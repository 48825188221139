import { IButtonProps } from 'models';
import { memo } from 'react';
import { Button } from 'styles/commonStyles';

const CommonButton = ({
  type = 'button',
  title,
  onClick,
  color,
  ...props
}: IButtonProps): JSX.Element => {
  return (
    <Button
      type={type}
      onClick={onClick}
      $color={color}
      {...props}
    >
      {title}
    </Button>
  );
};

export default memo(CommonButton);
