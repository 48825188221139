import {
  CommonArray,
  defaultCurrentData,
  defaultSearchDepartmentCode,
  defaultSearchUser,
} from 'models';
import { atom, atomFamily, selector } from 'recoil';

const searchDepartmentCode = atom({
  key: 'searchDepartmentCode',
  default: defaultSearchDepartmentCode,
});

const searchUser = atomFamily({
  key: 'searchUser',
  default: defaultSearchUser,
});

const departmentCodeList = atom<CommonArray>({
  key: 'departmentCodeList',
  default: [],
});

const currentDepartmentCode = atomFamily({
  key: 'currentDepartmentCode',
  default: defaultCurrentData,
});

const userList = atomFamily<CommonArray, string>({
  key: 'userList',
  default: [],
});

const currentUser = atomFamily({
  key: 'currentUser',
  default: defaultCurrentData,
});

const searchAuth = selector({
  key: 'searchAuth',
  get: ({ get }) => {
    const user = get(currentUser('COCM170M02Auth'));

    return user && user.data
      ? { USR_NM: user.data.USR_NM, DPT_CD: user.data.DPT_CD }
      : { USR_NM: '', DPT_CD: '' };
  },
});

const currentAssignAuth = atom({
  key: 'currentAssignAuth',
  default: defaultCurrentData,
});

const assignAuthList = atom<CommonArray>({
  key: 'assignAuthList',
  default: [],
});

const currentPossibleAuth = atom({
  key: 'currentPossibleAuth',
  default: defaultCurrentData,
});

const possibleAuthList = atom<CommonArray>({
  key: 'possibleAuthList',
  default: [],
});

export const systemManagementState = {
  searchDepartmentCode,
  searchUser,
  departmentCodeList,
  currentDepartmentCode,
  userList,
  currentUser,
  searchAuth,
  currentAssignAuth,
  assignAuthList,
  currentPossibleAuth,
  possibleAuthList,
};
