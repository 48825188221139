import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { ITrendPlot } from 'models';
import { client } from 'utils';

function trendPlotMutation(
  options: UseMutationOptions<unknown, Error, ITrendPlot, unknown>,
) {
  return useMutation({
    mutationFn: async (trendPlot: ITrendPlot) => {
      const response = await client.post('/api/teg/08/plot', trendPlot);
      return response;
    },
    ...options,
  });
}

export default function useTrendPlotMutation() {
  return trendPlotMutation({
    onSuccess: () => {
      const iframe = document.getElementById(
        'trendPlot',
      ) as HTMLIFrameElement;
      const iframeSrc = iframe.src;
      console.log(
        iframe.contentDocument?.querySelectorAll("input[type='hidden']"),
      );
      const input = iframe.contentDocument?.getElementById('Txaxis_min');
      const value = input?.getAttribute('value');
      console.log(value);
      iframe.src = '';
      iframe.src = iframeSrc;
    },
  });
}
