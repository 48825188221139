import { useQuery } from '@tanstack/react-query';
import { UseQueryOptionsWithoutQueryKey } from 'models';
import { client } from 'utils';

interface IProcessListParam {
  options: UseQueryOptionsWithoutQueryKey;
}

function parentProcess({ options }: IProcessListParam) {
  return useQuery({
    queryKey: ['parentProcess'],
    queryFn: async () => {
      const response = await client.get('/api/teg/01/modal/process/top');
      return response.data.topProcessList;
    },
    ...options,
  });
}

export default function useParentProcessList() {
  return parentProcess({
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
