import { useQuery } from '@tanstack/react-query';
import { UseQueryOptionsWithoutQueryKey } from 'models';
import { client } from 'utils';
import { ISearchCode } from 'models/interfaces/maskInterface';

interface ICodeListParam {
  searchData: ISearchCode;
  options: UseQueryOptionsWithoutQueryKey;
}

function code({ searchData, options }: ICodeListParam) {
  return useQuery({
    queryKey: ['mask', 'codeList', searchData],
    queryFn: async () => {
      const response = await client.get('/api/teg/02/code', {
        params: searchData,
      });
      return response.data.codeList;
    },
    ...options,
  });
}

export default function useCodeList(searchData: ISearchCode) {
  return code({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
