import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';
import { measurementFileState } from 'store';
import { client } from 'utils';

function measurementFileMutation(
  options: UseMutationOptions<unknown, Error, unknown>,
) {
  const setProgressBar = useSetRecoilState(
    measurementFileState.measurementFileProgress('progress'),
  );
  return useMutation({
    mutationFn: async (measurementFileWrapper: any) => {
      const formData = new FormData();
      const { fileList, DVC_NO } = measurementFileWrapper;
      fileList.forEach((file: any) => {
        formData.append('files', file);
      });
      formData.append(
        'DVC_NO',
        new Blob([JSON.stringify(DVC_NO)], {
          type: 'application/json',
        }),
      );
      const response = await client.post(
        '/api/teg/07/file/text/upload',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: progressEvent => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / (progressEvent.total ?? 1),
            );
            setProgressBar(progress);
          },
        },
      );
      return response.data;
    },
    ...options,
  });
}

export default function useMeasurementFileMutation() {
  const queryClient = useQueryClient();
  return measurementFileMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [''],
      });
    },
  });
}
