import { useAssignAuthorityMutation } from 'apis';
import { IAssignedAuthorityMenu, RowType } from 'models';
import { BiSolidChevronsLeft } from 'react-icons/bi';
import { useRecoilValue } from 'recoil';
import { systemManagementState } from 'store';
import { getAlertMessage } from 'utils';

const COCM170M02Switch = () => {
  const changeAssignAuthority = useAssignAuthorityMutation();
  const assignList = useRecoilValue(systemManagementState.assignAuthList);

  const possibleList = useRecoilValue(
    systemManagementState.possibleAuthList,
  );
  const currentAssign = useRecoilValue(
    systemManagementState.currentAssignAuth,
  );
  const currentPossible = useRecoilValue(
    systemManagementState.currentPossibleAuth,
  );
  const currentUser = useRecoilValue(
    systemManagementState.currentUser('COCM170M02Auth'),
  );

  const addAuthority = () => {
    if (possibleList.length === 0) {
      alert(getAlertMessage('INFO_00460', ['부여가능한 권한']));
      return;
    }

    const addAuthority = {
      ...currentPossible.data,
      ROWTYPE: RowType.ADD,
      USR_ID: currentUser.data.USR_ID,
    };
    const params = [...assignList, addAuthority];
    changeAssignAuthority.mutate(params as IAssignedAuthorityMenu[]);
  };

  const removeAuthority = () => {
    if (assignList.length === 0) {
      alert(getAlertMessage('INFO_00460', ['회수할 권한']));
      return;
    }
    const removedList = assignList.filter(
      assign => assign !== currentAssign.data,
    );
    changeAssignAuthority.mutate(removedList as IAssignedAuthorityMenu[]);
  };

  return (
    <div className='col-center'>
      <button className='switch-button'>
        <BiSolidChevronsLeft onClick={addAuthority} />
      </button>
      <button className='switch-button'>
        <BiSolidChevronsLeft
          className='rotate-180'
          onClick={removeAuthority}
        />
      </button>
    </div>
  );
};

export default COCM170M02Switch;
