import { FrameInfo, FrameInfoSelect } from 'components';
import { useHeight, useSearchComboList } from 'hooks';
import { CustomChangeEvent } from 'models';
import { useRecoilState, useRecoilValue } from 'recoil';
import { documentState, selectState } from 'store';
import { FrameContent } from 'styles';
import { editValues } from 'utils';
import TEG10ChapterTable from './TEG10ChapterTable';
import TEG10ChapterForm from './TEG10ChapterForm';
import TEG10KeyTable from './TEG10KeyTable';
import TEG10ValueTable from './TEG10ValueTable';
import TEG10Description from './TEG10Description';
import TEG10AppendixTable from './TEG10AppendixTable';
import { useEffect, useState } from 'react';

const defaultOption = [{ value: '', label: '선택' }];

const TEG10 = (): JSX.Element => {
  const getHeight = useHeight();
  const frameActive =
    useRecoilValue(selectState.selectSideMenu).PGM_ID === 'UIE009TEG10';
  const [searchDocumentContent, setSearchDocumentContent] = useRecoilState(
    documentState.searchDocumentContent,
  );
  const [values, setValues] = useState(searchDocumentContent);
  const currentChapter = useRecoilValue(
    documentState.currentDocumentContent,
  );
  const currentChapterKey = useRecoilValue(
    documentState.currentChapterKey,
  );
  const pdkOptions = useSearchComboList(
    { CMB_CD: frameActive ? 'CMB0010' : '' },
    true,
  );
  const documentOptions = useSearchComboList(
    {
      CMB_CD: frameActive ? 'CMB0011' : '',
      SRCH_PARAM: !values.DOC_NO ? String(0) : String(values.DOC_NO),
    },
    true,
  );
  const editValue = (e: CustomChangeEvent, name?: string) => {
    editValues(e, setValues);
    if (name === 'DOC_NO') {
      setValues(prev => ({
        ...prev,
        DOC_TYPE: '',
      }));
    }
  };
  const isDescription =
    currentChapter?.data?.RMK_CONT === 'D' &&
    currentChapterKey?.data?.KEY === 'dvc_id';

  const isAppendix = currentChapter?.data?.RMK_CONT === 'A';
  useEffect(() => {
    setSearchDocumentContent(values);
  }, [values]);
  useEffect(() => {
    if (frameActive) setValues(searchDocumentContent);
  }, [frameActive]);
  return (
    <>
      <FrameInfo>
        <div className='search-form'>
          <div className='input-block'>
            <FrameInfoSelect
              title='PDK'
              name='DOC_NO'
              value={values.DOC_NO}
              options={pdkOptions}
              onChange={e => editValue(e, 'DOC_NO')}
            />
            <FrameInfoSelect
              title='문서'
              name='DOC_TYPE'
              value={values.DOC_TYPE}
              options={values.DOC_NO > 0 ? documentOptions : defaultOption}
              onChange={e => editValue(e, 'DOC_TYPE')}
            />
          </div>
        </div>
      </FrameInfo>
      <FrameContent>
        <div className='w-[270px]'>
          <TEG10ChapterTable tableHeight={getHeight(670)} />
        </div>
        <div className='grow-col-between gap-y-2'>
          <div className='flex-between-2'>
            {isAppendix ? (
              <TEG10AppendixTable tableHeight={getHeight(600)} />
            ) : (
              <>
                <div className='w-[250px]'>
                  <TEG10KeyTable tableHeight={getHeight(600)} />
                </div>
                <div className='flex flex-grow'>
                  {isDescription ? (
                    <TEG10Description tableHeight={getHeight(600)} />
                  ) : (
                    <TEG10ValueTable tableHeight={getHeight(600)} />
                  )}
                </div>
              </>
            )}
          </div>
          <TEG10ChapterForm />
        </div>
      </FrameContent>
    </>
  );
};

export default TEG10;
