import {
  CommonArray,
  defaultCurrentData,
  defaultSearchDocumentContent,
} from 'models';
import { atom, selector } from 'recoil';

const searchDocumentContent = atom({
  key: 'searchDocumentContent',
  default: defaultSearchDocumentContent,
});

const currentDocumentContent = atom({
  key: 'currentDocumentContent',
  default: defaultCurrentData,
});

const chapterList = atom<CommonArray>({
  key: 'chapterList',
  default: [],
});

const searchChapterKey = selector({
  key: 'searchChapterKey',
  get: ({ get }) => {
    const searchContent = get(searchDocumentContent);
    const currentContent = get(currentDocumentContent);

    if (searchContent && currentContent.data) {
      const chapter = currentContent.data;
      return {
        ...searchContent,
        CHR_SNO: chapter.CHR_SNO,
        CH_NO: chapter.CH_NO,
      };
    }

    return defaultSearchDocumentContent;
  },
});

// const docContentSelector = selector({
//   key: 'docContentSelector',
//   get: ({ get }) => {
//     const currentContent = get(currentDocumentContent);
//     const currentKey = get(currentChapterKey);

//     return {
//       DOC_NO: currentContent.data.DOC_NO,
//       CH_NO: currentContent.data.CH_NO,
//       CHR_SNO: currentContent.data.CHR_SNO,
//       KEY: undefined,
//     };

//     return {
//       DOC_NO: currentContent.data.DOC_NO,
//       CH_NO: currentContent.data.CH_NO,
//       CHR_SNO: currentContent.data.CHR_SNO,
//       KEY: currentKey.data.KEY,
//     };
//   },
// });

const currentChapterKey = atom({
  key: 'currentChapterKey',
  default: defaultCurrentData,
});

const searchChapterKeyValue = atom({
  key: 'searchChapterKeyValue',
  default: defaultSearchDocumentContent,
});

const documentVopList = atom<CommonArray>({
  key: 'documentVopList',
  default: [],
});

const RGDevice = atom({
  key: 'RGDevice',
  default: defaultCurrentData,
});

const currentRuleDescription = atom({
  key: 'currentRuleDescription',
  default: defaultCurrentData,
});

const searchRuleLayer = atom({
  key: 'searchRuleLayer',
  default: defaultSearchDocumentContent,
});

export const documentState = {
  searchDocumentContent,
  currentDocumentContent,
  chapterList,
  searchChapterKey,
  // docContentSelector,
  currentChapterKey,
  searchChapterKeyValue,
  documentVopList,
  RGDevice,
  currentRuleDescription,
  searchRuleLayer,
};
