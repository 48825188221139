import { useGuideImageNameList, useImage } from 'apis';
import { CommonButton, Loading } from 'components';
import { CommonObject } from 'models';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const TEG10Guide = (): JSX.Element => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const qeuryObject = Object.fromEntries(queryParams.entries());
  const [requestUrl, setRequestUrl] = useState('');
  const [index, setIndex] = useState(1);
  const { CH_NO } = qeuryObject;
  const { data: imageNameList, isLoading } = useGuideImageNameList(
    CH_NO,
  ) as CommonObject;

  const imageCount = imageNameList?.length;
  const { data: imageUrl } = useImage(requestUrl) as CommonObject;

  const handleIndex = (type: string) => {
    if (type === 'next' && index < imageCount) {
      setIndex(old => old + 1);
    } else if (type === 'back' && index > 1) {
      setIndex(old => old - 1);
    }
  };

  useEffect(() => {
    if (imageCount > 0) {
      const url = `GUIDE/${imageNameList[index - 1]}`;
      console.log('teg10Guide - url :', url);
      setRequestUrl(url);
    }

    return () => setRequestUrl('');
  }, [imageNameList, index]);

  if (isLoading) {
    return <Loading />;
  }

  if (!imageCount) {
    return (
      <div className='h-screen flex items-center justify-center'>
        선택한 챕터는 타이틀 챕터로 가이드가 존재하지 않습니다.
      </div>
    );
  }

  return (
    <div className='w-screen h-screen flex flex-col gap-y-2 justify-between'>
      <div className='border flex-grow'>
        <img
          className='w-fit'
          src={imageUrl}
        />
      </div>
      <div className='border h-[100px] flex items-center justify-center gap-x-5'>
        <CommonButton
          title='<'
          onClick={() => handleIndex('back')}
          disabled={index === 1}
        />
        <span>{`${index}/${imageCount}`}</span>
        <CommonButton
          title='>'
          onClick={() => handleIndex('next')}
          disabled={index === imageCount}
        />
      </div>
    </div>
  );
};

export default TEG10Guide;
