import { CommonButton } from 'components';
import { CustomChangeEvent, IFileUploadProps } from 'models';
import { useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { maskState } from 'store';

const TEG02FileUploadBox = ({
  title,
  name,
  handleUpload,
  handleDownload,
  handleReset,
}: IFileUploadProps): JSX.Element => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [currentFileData, setCurrentFileData] = useRecoilState(
    maskState.currentMaskFileData('mask'),
  );
  const currentMaskData = useRecoilValue(maskState.currentMaskData);
  const currentCodeData = useRecoilValue(maskState.currentCodeData);
  const onchange = (e: CustomChangeEvent) => {
    const inputElement = e.target as HTMLInputElement;
    const selectedFile = inputElement.files?.[0];
    const selectedFileName = selectedFile ? selectedFile.name : '';
    const mskCd = currentMaskData.data.MSK_CD;
    const codeSno = currentCodeData.data.CODE_SNO;
    const fileType = selectedFileName?.split('.')[1];
    const mskNm = currentMaskData.data.MSK_NM;
    const codeNm = currentCodeData.data.CODE_NM;
    setCurrentFileData({
      MSK_CD: mskCd,
      TYPE: name,
      CODE_SNO: codeSno,
      FILE_TYPE: fileType,
      MSK_NM: mskNm,
      CODE_NM: codeNm,
      FILE: selectedFile,
      FILE_NM: selectedFileName,
    });
  };

  const onClick = () => {
    fileInputRef?.current?.click();
  };
  return (
    <>
      <div className='input-block'>
        <div>
          <span className='block-title'>{title}</span>
          <input
            type='text'
            value={currentFileData.FILE_NM || ''}
            readOnly={true}
          />
          <input
            type='file'
            ref={fileInputRef}
            className='hidden'
            onChange={e => onchange(e)}
          />
          <CommonButton
            title='...'
            onClick={onClick}
          />
          {handleUpload && (
            <CommonButton
              title='업로드'
              onClick={handleUpload}
            />
          )}
          {handleDownload && (
            <CommonButton
              title='다운로드'
              onClick={handleDownload}
            />
          )}
          {handleReset && (
            <CommonButton
              title='초기화'
              onClick={handleReset}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default TEG02FileUploadBox;
