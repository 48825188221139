/**
 * Date 객체를 매개변수로 전달받아 UTC를 기준으로 LocalTime과의 간격을 계산하여 반환하는 함수
 * @param {Date} date Date 객체
 * @returns {Date} 계산된 Date 객체
 */
export function convertOffsetTime(date) {
  const offsetInMinutes = date.getTimezoneOffset();
  const oneMinutes = 60000;
  const adjustedTime = date.getTime();

  return new Date(adjustedTime - offsetInMinutes * oneMinutes);
}

/**
 * 매개변수로 다양한 자료형의 날짜를 전달받아 'YYYY-MM-DD HH:MM:SS' 형태로 반환하는 함수
 * @param {any} time 'YYYY-MM-DD', milisecond, Date 객체 등 다양한 시간 데이터
 * @returns {string} 지정된 포맷으로 반환되는 문자열 날짜 데이터
 */
export function getDateTimeLocal(time) {
  const date = time ? new Date(time) : new Date();
  const newDate = convertOffsetTime(date);
  const replaceDate = newDate.toISOString().replace('T', ' ');
  const dateTimeLocal = replaceDate.split('.')[0];

  return dateTimeLocal;
}

/**
 * 그라파나의 문자열 시간 데이터를 매개변수로 전달받아 'YYYY-MM-DD HH:MM:SS' 형태의 포맷으로 반환하는 함수
 * @param {string} time 밀리세컨드 형태의 문자열 데이터
 * @returns {string} 변환된 시간 데이터
 */
export function getGrafanaTime(timeParam) {
  const time = String(timeParam).includes('-')
    ? timeParam
    : Number(timeParam);
  const grafanaDate = new Date(time);
  const newDate = convertOffsetTime(grafanaDate);
  const replaceDate = newDate.toISOString().replace('T', ' ');
  const dateTime = replaceDate.split('.')[0];

  return dateTime;
}

/**
 * 매개변수로 전달받은 시작일자와 종료일자의 일자 차이를 반환하는 함수
 * @param {string} startDate 시작일자
 * @param {string} endDate 종료일자
 * @returns {number} 날짜 차이
 */
export const getDateDifference = (startDate, endDate) => {
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);
  const differenceTime = date2.getTime() - date1.getTime();
  const calculatedTime = differenceTime / (24 * 60 * 60 * 1000);

  return calculatedTime;
};

/**
 * 현재 시간을 'yyyy-mm-dd'형식으로 반환
 * @returns {string} 'yyyy-mm-dd' 형식의 문자열
 */
export const getCurrentDateFormatted = dateString => {
  const today = dateString ? new Date(dateString) : new Date();

  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

/**
 * 'yyyymmdd' 형태의 날짜 데이터를 'yyyy-mm-dd' 포맷으로 변환하는 함수
 * @param {string} yyyymmdd
 * @returns {string} 'yyyy-mm-dd' 형식의 문자열
 */
export const convertDate = yyyymmdd => {
  const match = yyyymmdd.match(/(\d{4})(\d{2})(\d{2})/);

  if (match) {
    const [, year, month, day] = match;
    return `${year}-${month}-${day}`;
  } else {
    return yyyymmdd;
  }
};
