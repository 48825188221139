import { CustomMouseEvent } from 'models';
import { ModalInner, ModalOverlay, ModalWrapper } from 'styles';
import TEG05DeviceTable from './TEG05DeviceTable';
import { useHeight } from 'hooks';
import TEG05ModuleTable from './TEG05ModuleTable';
import TEG05DutTable from './TEG05DutTable';
import { CommonButton } from 'components';
import { useRecoilValue } from 'recoil';
import { descriptionState } from 'store';
import { useDutMoveMutation } from 'apis';
import { toast } from 'react-toastify';

const TEG05MoveModal = ({
  onClose,
}: {
  onClose: () => void;
}): JSX.Element => {
  const getHeight = useHeight();
  const close = (e: CustomMouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const dutMoveMutation = useDutMoveMutation();

  const searchDescription = useRecoilValue(
    descriptionState.searchDescription('teg05'),
  );
  const moduleMove = useRecoilValue(descriptionState.moduleMove);
  const dutIdMove = useRecoilValue(descriptionState.dutIdMove);
  const selectedDutId = useRecoilValue(descriptionState.selectedDutId);

  const handleSaveMove = () => {
    const MDL_ID = searchDescription.MDL_ID;
    const D_ID = selectedDutId.padStart(2, '0');
    const DUT_ID = parseInt(`${MDL_ID}${D_ID}`, 10);
    const MV_MDL_ID = moduleMove.MV_MDL_ID;
    if (
      !MDL_ID ||
      !D_ID ||
      !DUT_ID ||
      !MV_MDL_ID ||
      !searchDescription.PRJ_NO ||
      !dutIdMove
    ) {
      toast.warning('입력되지 않은 필드가 있습니다.');
      return;
    }
    const DutMoveSave = {
      DVC_NO: searchDescription.DVC_NO,
      DUT_ID: DUT_ID,
      MV_DUT_ID: parseInt(
        `${MV_MDL_ID}${dutIdMove.toString().padStart(2, '0')}`,
        10,
      ),
      MV_DVC_NO: moduleMove.MV_DVC_NO,
      FLAG: 'Y',
    };
    const DutMoveWrapper = {
      PRJ_NO: searchDescription.PRJ_NO,
      DutMoveSave: DutMoveSave,
    };
    dutMoveMutation.mutate(DutMoveWrapper);
    onClose();
  };

  const handleRemoveMove = () => {
    const MDL_ID = searchDescription.MDL_ID;
    const D_ID = selectedDutId.padStart(2, '0');
    const DUT_ID = parseInt(`${MDL_ID}${D_ID}`, 10);
    const DutMoveDelete = {
      DVC_NO: searchDescription.DVC_NO,
      DUT_ID: DUT_ID,
      MV_DUT_ID: 0,
      MV_DVC_NO: 0,
      FLAG: 'N',
    };
    const DutMoveWrapper = {
      PRJ_NO: searchDescription.PRJ_NO,
      DutMoveSave: DutMoveDelete,
    };
    dutMoveMutation.mutate(DutMoveWrapper);
    onClose();
  };

  return (
    <>
      <ModalOverlay />
      <ModalWrapper
        onClick={close}
        tabIndex={-1}
      >
        <ModalInner
          tabIndex={0}
          className='w-[700px] h-[420px] space-y-5'
        >
          <div className='flex justify-center'>
            <div className='w-[250px]'>
              <TEG05DeviceTable tableHeight={getHeight(300)} />
            </div>
            <div className='w-[200px] flex mx-5'>
              <TEG05ModuleTable tableHeight={getHeight(300)} />
            </div>
            <div className='w-[200px]'>
              <TEG05DutTable tableHeight={getHeight(300)} />
            </div>
          </div>
          <div className='w-full flex justify-end'>
            <CommonButton
              title='MOVE 해제'
              onClick={handleRemoveMove}
            ></CommonButton>
            <CommonButton
              title='선택'
              onClick={handleSaveMove}
            ></CommonButton>
          </div>
        </ModalInner>
      </ModalWrapper>
    </>
  );
};

export default TEG05MoveModal;
