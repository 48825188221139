import { CommonButton, FrameInfo, FrameInfoSelect } from 'components';
import { useHeight, useSearchComboList } from 'hooks';
import { CustomChangeEvent } from 'models';
import { useEffect, useState } from 'react';
import { FrameContent } from 'styles';
import TEG07FileUploadTable from './TEG07FileUploadTable';
import { useRecoilValue, useRecoilState } from 'recoil';
import { measurementFileState, selectState } from 'store';
import ProgressBar from 'components/common/ProgressBar';

const TEG07 = (): JSX.Element => {
  const getHeight = useHeight();
  const frameActive =
    useRecoilValue(selectState.selectSideMenu).PGM_ID === 'UIE007TEG07';
  const [searchData, setSearchData] = useRecoilState(
    measurementFileState.searchMeasureFile,
  );
  const [values, setValues] = useState(searchData);
  const projectOptions = useSearchComboList(
    { CMB_CD: frameActive ? 'CMB0029' : '' },
    true,
  );
  const processOptions = useSearchComboList(
    {
      CMB_CD: frameActive && values.PRJ_NO ? 'CMB0030' : '',
      SRCH_PARAM: values.PRJ_NO,
    },
    true,
  );
  const deviceOptions = useSearchComboList(
    {
      CMB_CD: frameActive && values.PRC_NO ? 'CMB0031' : '',
      SRCH_PARAM: values.PRC_NO,
    },
    true,
  );
  const editValue = (e: CustomChangeEvent, codeType?: string) => {
    if (codeType) {
      if (codeType === 'PRJ_NO') {
        setValues({
          [codeType]: e.target.value,
          PRC_NO: '',
          DVC_NO: '',
        });
      } else {
        setValues(old => ({
          ...old,
          [codeType]: e.target.value,
        }));
      }
    }
  };
  useEffect(() => {
    setSearchData(values);
  }, [values]);
  const handleSearch = () => {
    setSearchData(values);
  };
  const percentage = useRecoilValue(
    measurementFileState.measurementFileProgress('progress'),
  );
  useEffect(() => {
    if (frameActive) setValues(searchData);
  }, [frameActive]);
  return (
    <>
      <FrameInfo>
        <div className='search-form'>
          <div className='input-block'>
            <div className='gap-x-10'>
              <FrameInfoSelect
                title='프로젝트'
                name='PRJ_NO'
                options={projectOptions}
                value={values.PRJ_NO}
                onChange={e => editValue(e, 'PRJ_NO')}
              />
              <FrameInfoSelect
                title='공정'
                name='PRC_NO'
                options={processOptions}
                value={values.PRC_NO}
                onChange={e => editValue(e, 'PRC_NO')}
              />
              <FrameInfoSelect
                title='디바이스'
                name='DVC_NO'
                options={deviceOptions}
                value={values.DVC_NO}
                onChange={e => editValue(e, 'DVC_NO')}
              />
            </div>
          </div>
          <div className='flex gap-x-2'>
            <CommonButton
              title='조회'
              onClick={handleSearch}
            />
          </div>
        </div>
      </FrameInfo>
      <FrameContent>
        <div className='w-full'>
          <TEG07FileUploadTable
            tableHeight={getHeight(680)}
          ></TEG07FileUploadTable>
          <div className='flex justify-end'>
            <ProgressBar percentage={percentage}></ProgressBar>
          </div>
        </div>
      </FrameContent>
    </>
  );
};

export default TEG07;
