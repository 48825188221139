import { useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  CheckInputCell,
  ModalCell,
  NumberCell,
  ReadOnlyTextCell,
  SelectCell,
  StatusCell,
  StatusIcon,
  Table,
  TextCell,
} from 'components';
import {
  TableContext,
  addRowData,
  addRowNumber,
  exportJsonToSheet,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';
import {
  Columns,
  CommonArray,
  CommonObject,
  CurrentData,
  IMenu,
  defaultParentMenu,
} from 'models';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTableData } from 'hooks';
import { ITableContext, ITablePropsWithModal } from 'models';
import { DataTableContainer } from 'styles';
import { menuManagementState } from 'store';
import { useChildMenuList, useMenuMutation } from 'apis';

const columnHelper = createColumnHelper();
const connectionOptions = [{ label: 'WEB', value: 'WEB' }];

const COCM150M01Table = ({
  tableHeight,
  openModal,
}: ITablePropsWithModal): JSX.Element => {
  const currentParentMenu = useRecoilValue(
    menuManagementState.currentParentMenu,
  );

  const newRow = {
    ...defaultParentMenu,
    HRK_MNU_ID: currentParentMenu.data?.MNU_ID,
    HRK_MNU_NM: currentParentMenu.data?.MNU_NM,
    CNN_DTT_CD: connectionOptions[0]?.value,
    DMN_CD: currentParentMenu.data?.DMN_CD,
  };

  const searchChildMenu = useRecoilValue(
    menuManagementState.searchChildMenu,
  );

  const { data: childMenuData } = useChildMenuList(
    searchChildMenu,
  ) as CommonObject;
  const [backupData, setBackupData] = useState<CommonArray>([]);
  const [initialData, setInitialData] = useTableData({
    fetchData: childMenuData,
    atom: menuManagementState.menuList,
    effect: () => {
      const newData = addRowNumber(childMenuData);
      setInitialData(newData);
      setBackupData(newData);
      setCurrentData({ index: 0, data: newData[0] });
    },
  });
  const [currentData, setCurrentData] = useRecoilState(
    menuManagementState.currentChildMenu,
  );
  const menuMutation = useMenuMutation();

  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 20,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('no', {
      header: 'No',
      cell: ReadOnlyTextCell,
      size: 30,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('MNU_ID', {
      header: '메뉴ID',
      cell: TextCell,
      size: 80,
      filterFn: 'includesString',
      meta: {
        unmodifiable: true,
        required: true,
        justify: 'center',
      },
    }),
    columnHelper.accessor('HRK_MNU_NM', {
      header: '상위메뉴',
      size: 80,
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('MNU_NM', {
      header: '메뉴명',
      size: 100,
      cell: TextCell,
      filterFn: 'includesString',
      meta: {
        required: true,
      },
    }),
    columnHelper.accessor('PGM_NM', {
      header: '프로그램명',
      size: 100,
      cell: ModalCell,
    }),
    columnHelper.accessor('MNU_PARM_VAL', {
      size: 90,
      header: '파라미터',
      cell: TextCell,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('CNN_DTT_CD', {
      header: '접속구분',
      size: 60,
      cell: SelectCell,
      filterFn: 'includesString',
      meta: {
        options: connectionOptions,
      },
    }),
    columnHelper.accessor('SRT_SQN', {
      header: '표시순서',
      cell: NumberCell,
      filterFn: 'includesString',
      size: 60,
      meta: {
        required: true,
      },
    }),
    columnHelper.accessor('MNU_IDCT_YN', {
      header: '화면표시',
      size: 40,
      cell: CheckInputCell,
      enableColumnFilter: false,
    }),
    columnHelper.accessor('PRV_INF_ICD_YN', {
      header: '개인정보포함여부',
      cell: CheckInputCell,
      size: 70,
      enableColumnFilter: false,
    }),
    columnHelper.accessor('USE_YN', {
      header: '사용여부',
      size: 20,
      cell: CheckInputCell,
      enableColumnFilter: false,
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const handleAddData = () => {
    addRowData(setInitialData, setCurrentData, newRow, initialData.length);
  };

  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);

    setInitialData(removedData);
    selectNextDataByRemove(
      selectData,
      initialData.length - 1,
      removedData,
      setCurrentData,
    );
  };

  const handleSaveData = () => {
    const checkKeys = ['MNU_ID', 'MNU_NM', 'SRT_SQN'];
    const targetData = saveTableData(initialData, checkKeys);

    if (targetData.length > 0) {
      menuMutation.mutate(targetData as IMenu[]);
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue: ITableContext = {
    handleExport: () =>
      exportJsonToSheet(columns, initialData, '메뉴 관리'),
    currentData,
    setCurrentData,
    handleAddData,
    handleRemoveData,
    handleSaveData,
    openModal,
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        <Table
          title='하위메뉴'
          initialData={initialData}
          backupData={backupData}
          setData={setInitialData}
          columns={columns}
          editable={true}
          tableHeight={tableHeight}
        />
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default COCM150M01Table;
