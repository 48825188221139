import { IFrameEditTextAreaProps } from 'models';
import { BiCaretLeft } from 'react-icons/bi';
import { FrameTextArea, UserFieldTitle } from 'styles/frameStyles';

const FrameEditTextArea = ({
  title,
  height,
  required,
  ...props
}: IFrameEditTextAreaProps): JSX.Element => {
  return (
    <>
      <UserFieldTitle>
        {required ? <BiCaretLeft className='required-icon' /> : undefined}
        {title}
      </UserFieldTitle>
      <div
        className='p-[1px] flex-grow'
        style={{ height: height }}
      >
        <FrameTextArea
          {...props}
          className={required ? 'required' : undefined}
          spellCheck='false'
          autoComplete='off'
        />
      </div>
    </>
  );
};

export default FrameEditTextArea;
