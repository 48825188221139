import { useRecoilValue } from 'recoil';
import { menuState, selectState } from 'store';
import { FrameContainer, FrameWrapper } from 'styles';
import COCM01 from './COCM01/COCM01';
import COCM02 from './COCM02/COCM02';
import COCM110M01 from './COCM110M01/COCM110M01';
import TEG01 from './TEG01/TEG01';
import TEG02 from './TEG02/TEG02';
import TEG03 from './TEG03/TEG03';
import TEG04 from './TEG04/TEG04';
import TEG05 from './TEG05/TEG05';
import TEG06 from './TEG06/TEG06';
import TEG07 from './TEG07/TEG07';
import COCM120M01 from './COCM120M01/COCM120M01';
import AIBI010M01 from './AIBI010M01/AIBI010M01';
import COCM100M01 from './COCM100M01/COCM100M01';
import COCM130M01 from './COCM130M01/COCM130M01';
import COCM140M01 from './COCM140M01/COCM140M01';
import COCM150M01 from './COCM150M01/COCM150M01';
import COCM160M01 from './COCM160M01/COCM160M01';
import COCM160M02 from './COCM160M02/COCM160M02';
import COCM170M01 from './COCM170M01/COCM170M01';
import COCM170M02 from './COCM170M02/COCM170M02';
import COCM180M01 from './COCM180M01/COCM180M01';
import COCM190M01 from './COCM190M01/COCM190M01';
import COCM210M01 from './COCM210M01/COCM210M01';
import COCM220M01 from './COCM220M01/COCM220M01';
import COCM430M01 from './COCM430M01/COCM430M01';
import COCM800M01 from './COCM800M01/COCM800M01';
import COCM900M01 from './COCM900M01/COCM900M01';
import TEG08 from './TEG08/TEG08';
import TEG08SUB from './TEG08SUB/TEG08SUB';
import TEG09 from './TEG09/TEG09';
import TEG10 from './TEG10/TEG10';

const Frame = (): JSX.Element => {
  const selectSideMenu = useRecoilValue(selectState.selectSideMenu);
  const tabMenu = useRecoilValue(menuState.tabMenu);
  const tabFrame: Record<string, JSX.Element> = {
    COCM01: <COCM01 />,
    COCM02: <COCM02 />,
    COCM100M01: <COCM100M01 />,
    COCM110M01: <COCM110M01 />,
    COCM120M01: <COCM120M01 />,
    COCM130M01: <COCM130M01 />,
    COCM140M01: <COCM140M01 />,
    COCM150M01: <COCM150M01 />,
    COCM160M01: <COCM160M01 />,
    COCM160M02: <COCM160M02 />,
    COCM170M01: <COCM170M01 />,
    COCM170M02: <COCM170M02 />,
    COCM180M01: <COCM180M01 />,
    COCM190M01: <COCM190M01 />,
    COCM210M01: <COCM210M01 />,
    COCM220M01: <COCM220M01 />,
    COCM430M01: <COCM430M01 />,
    COCM800M01: <COCM800M01 />,
    COCM900M01: <COCM900M01 />,
    AIBI010M01: <AIBI010M01 />,
    UIE001TEG01: <TEG01 />,
    UIE002TEG02: <TEG02 />,
    UIE003TEG03: <TEG03 />,
    UIE004TEG04: <TEG04 />,
    UIE005TEG05: <TEG05 />,
    UIE006TEG06: <TEG06 />,
    UIE007TEG07: <TEG07 />,
    UIE008TEG08: <TEG08 />,
    UIE008TEG08_S: <TEG08SUB />,
    UIE009TEG09: <TEG09 />,
    UIE009TEG10: <TEG10 />,
  };
  console.log('현재 Frame :: ' + selectSideMenu.PGM_ID);
  return (
    <FrameContainer>
      {tabMenu.map(tab => (
        <FrameWrapper
          key={tab.PGM_ID}
          style={{
            display:
              tab.PGM_ID === selectSideMenu.PGM_ID ? 'block' : 'none',
          }}
        >
          {tabFrame[tab.PGM_ID]}
        </FrameWrapper>
      ))}
      {/* <FrameWrapper>{tabFrame[selectSideMenu.PGM_ID]}</FrameWrapper> */}
    </FrameContainer>
  );
};

export default Frame;
