import { useHeight, useSearchComboList } from 'hooks';
import { useState } from 'react';
import {
  CommonButton,
  FrameInfo,
  FrameInfoInput,
  FrameInfoSelect,
} from 'components';
import { useRecoilState } from 'recoil';
import { editValues } from 'utils';
import COCM190M01Table from './COCM190M01Table';
import { historyManagementState } from 'store';
import { CustomChangeEvent } from 'models';
import { FrameContent } from 'styles';

const COCM190M01 = (): JSX.Element => {
  const getHeight = useHeight();

  const [searchSystemLogHistory, setSearchSystemLogHistory] =
    useRecoilState(historyManagementState.searchSystemLog);
  const [values, setValues] = useState(searchSystemLogHistory);
  const domainOptions = useSearchComboList({ CMB_CD: 'CMB0027' });
  const editValue = (e: CustomChangeEvent) => editValues(e, setValues);

  const handleSearchLoginHistory = () => {
    const changeDateValues = {
      ...values,
      LOG_ST_DT: values.LOG_ST_DT.replaceAll('-', ''),
      LOG_END_DT: values.LOG_END_DT.replaceAll('-', ''),
    };
    setSearchSystemLogHistory(changeDateValues);
  };

  return (
    <>
      <FrameInfo>
        <div className='search-form flex-col gap-y-1'>
          <div className='input-block'>
            <FrameInfoSelect
              title='도메인'
              width={200}
              options={domainOptions}
              name='DMN_CD'
              onChange={editValue}
            />
            <FrameInfoInput
              title='실행일자'
              type='date'
              name='LOG_ST_DT'
              value={values?.LOG_ST_DT || ''}
              onChange={editValue}
            />
            <FrameInfoInput
              title='~'
              width={150}
              type='date'
              name='LOG_END_DT'
              value={values?.LOG_END_DT || ''}
              onChange={editValue}
            />
            <FrameInfoInput
              title='프로그램 ID'
              width={250}
              type='text'
              name='PGM_ID'
              value={values?.PGM_ID || ''}
              onChange={editValue}
            />
            <FrameInfoInput
              title='프로그램명'
              type='text'
              name='PGM_NM'
              value={values?.PGM_NM || ''}
              onChange={editValue}
            />
          </div>
          <div className='input-block'>
            <FrameInfoInput
              title='사용자'
              width={200}
              type='text'
              name='USR_ID'
              value={values?.USR_ID || ''}
              onChange={editValue}
            />
            <FrameInfoInput
              title='실행메소드명'
              width={250}
              type='text'
              name='CL_MTH_NM'
              value={values?.CL_MTH_NM || ''}
              onChange={editValue}
            />
            <FrameInfoInput
              title='전달인자'
              type='text'
              name='CL_VRB_CONT'
              value={values?.CL_VRB_CONT || ''}
              onChange={editValue}
            />
          </div>
        </div>
        <div>
          <CommonButton
            title='조회'
            onClick={handleSearchLoginHistory}
          />
        </div>
      </FrameInfo>
      <FrameContent>
        <div className='w-full'>
          <COCM190M01Table tableHeight={getHeight(650)} />
        </div>
      </FrameContent>
    </>
  );
};

export default COCM190M01;
