import { ModalButton } from 'components';
import { CommonObject, IDescriptionCheck } from 'models';
import { Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { useSetRecoilState } from 'recoil';
import { descriptionState } from 'store';
import { getAlertMessage } from 'utils';

const TEG05MoveHeader = ({
  headerGroups,
  move,
  setOpenModal,
  checkList,
}: {
  headerGroups: any[];
  move: CommonObject[];
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  checkList: IDescriptionCheck;
}): JSX.Element => {
  const dutRegex = /D\d{2}/;
  const setDutId = useSetRecoilState(descriptionState.selectedDutId);
  const flatHeaderGroups = headerGroups.flatMap(header => {
    if (header.subHeaders) {
      return [...header.subHeaders];
    }
    return header;
  });
  const handleOpenModal = (moveId: string) => {
    if (checkList === undefined) {
      toast.warning(getAlertMessage('ERRO_00370', ['디바이스, 모듈']));
      return;
    }
    const checkId = `CHECK${moveId}`;
    if (checkList[checkId] === 1) {
      toast.warning('릴리즈 해제 후 MOVE 가능합니다.');
      return;
    }
    setDutId(moveId);
    setOpenModal(true);
  };

  const renderHeader = (
    header: any,
    move: any[],
    handleOpenModal: any,
  ) => {
    const { id: columnId } = header;
    if (columnId === 'DSC_NM') {
      return (
        <th
          className='text-sm py-1'
          key={columnId}
        >
          Move
        </th>
      );
    }

    if (dutRegex.test(columnId)) {
      const moveId = columnId.substring(1);
      const moveItem = move.find(item => item.D_ID === moveId);
      const moveValue = moveItem ? moveItem.MOVE : '';

      return (
        <th
          className='text-xs font-semibold'
          key={columnId}
        >
          <div className='w-full flex justify-between items-center'>
            <span>{moveValue}</span>
            <ModalButton openModal={() => handleOpenModal(moveId)} />
          </div>
        </th>
      );
    }

    return <th key={columnId}>{''}</th>;
  };

  return (
    <thead style={{ position: 'sticky', top: '60px' }}>
      <tr>
        {flatHeaderGroups.map(header =>
          renderHeader(header, move, handleOpenModal),
        )}
      </tr>
    </thead>
  );
};

export default TEG05MoveHeader;
