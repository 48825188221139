import { useEffect, useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { ReadOnlyTextCell, Table } from 'components/';
import { TableContext, addRowNumber, exportJsonToSheet } from 'utils';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTableData } from 'hooks';
import { authorityManagementState } from 'store';
import {
  Columns,
  CommonArray,
  CommonObject,
  CurrentData,
  ITableContext,
  defaultCurrentData,
} from 'models';
import { DataTableContainer } from 'styles';
import { useAuthorityList } from 'apis';

const columnHelper = createColumnHelper();

const COCM160M02AuthorityTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchAuthority = useRecoilValue(
    authorityManagementState.searchAuthority('COCM160M02'),
  );
  const setSearchAuthorityMenu = useSetRecoilState(
    authorityManagementState.searchAuthorityMenu,
  );
  const { data: authorityData } = useAuthorityList(
    searchAuthority,
  ) as CommonObject;
  const [backupData, setBackupData] = useState<CommonArray>([]);
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: authorityData,
    effect: () => {
      const newData = addRowNumber(authorityData);
      setInitialData(newData);
      setBackupData(newData);
      setCurrentData({ index: 0, data: newData[0] });
    },
  });
  const [currentData, setCurrentData] =
    useState<CurrentData>(defaultCurrentData);

  useEffect(() => {
    if (currentData.data) {
      setSearchAuthorityMenu(old => ({
        ...old,
        ATH_ID: currentData.data.ATH_ID,
      }));
    }
  }, [currentData]);

  const defaultColumns = [
    columnHelper.accessor('no', {
      header: 'No',
      cell: ReadOnlyTextCell,
      size: 30,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('ATH_NM', {
      header: '권한명',
      cell: ReadOnlyTextCell,
      size: 180,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('ATH_ID', {
      header: '권한ID',
      cell: ReadOnlyTextCell,
      size: 180,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const contextValue: ITableContext = {
    handleExport: () =>
      exportJsonToSheet(columns, initialData, '권한정보'),
    currentData,
    setCurrentData,
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        {initialData && (
          <Table
            title='권한'
            initialData={initialData}
            backupData={backupData}
            setData={setInitialData}
            columns={columns}
            draggable={true}
            tableHeight={tableHeight}
          />
        )}
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default COCM160M02AuthorityTable;
