import { LoadingBox } from 'styles';
import { useEffect, useState } from 'react';
import { Spinner } from 'assets';

const Loading = () => {
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    const handleOpacity = () => {
      setOpacity(current => current + 0.05);
      if (opacity > 1) {
        setOpacity(0);
      }
    };
    setTimeout(() => handleOpacity(), 30);
  }, [opacity]);

  return (
    <LoadingBox>
      <img
        className='w-[100px] h-[100px]'
        src={Spinner}
        alt='loading'
        style={{ opacity: opacity }}
      />
    </LoadingBox>
  );
};

export default Loading;
