import FrameEditBox from './FrameEditBox';
import FrameEditField from './FrameEditField';
import FrameUserField from './FrameUserField';
import FrameEditTextArea from './FrameEditTextArea';
import { IFrameUserFormProps, IOption, RowType } from 'models';
import FrameEditInput from './FrameEditInput';
import FrameEditSelect from './FrameEditSelect';
import { EditFieldRow } from 'styles/frameStyles';
import { useSearchComboList } from 'hooks';

const useYnOptions: IOption[] = [
  { label: '사용', value: 'Y' },
  { label: '미사용', value: 'N' },
];

const FrameUserForm = ({
  values,
  setValues,
  editValues,
  fieldCount,
  saveForm,
  height,
}: IFrameUserFormProps): JSX.Element => {
  const fieldArray = new Array(fieldCount).fill(0);
  const inputTypeOptions = useSearchComboList({ CMB_CD: 'CMB0016' });
  const systemOptions = useSearchComboList({ CMB_CD: 'CMB0015' }, true);
  const codeUseOptions = useSearchComboList({ CMB_CD: 'CMB0017' }, true);

  return (
    <FrameEditBox
      title='코드유형 정보'
      onClick={saveForm}
      height={height}
    >
      <EditFieldRow className='border-t'>
        <FrameEditField title='코드유형'>
          <FrameEditInput
            type='text'
            name='TP_CD'
            value={values?.TP_CD || ''}
            onChange={editValues}
            required={true}
            readOnly={values?.ROWTYPE !== RowType.ADD}
          />
        </FrameEditField>
        <FrameEditField title='코드유형명'>
          <FrameEditInput
            type='text'
            name='TP_CD_NM'
            value={values?.TP_CD_NM || ''}
            onChange={editValues}
            required={true}
          />
        </FrameEditField>
      </EditFieldRow>
      <EditFieldRow>
        <FrameEditField title='시스템구분'>
          <FrameEditSelect
            name='SYS_DTT_CD'
            value={values?.SYS_DTT_CD || ''}
            required={true}
            onChange={editValues}
            options={systemOptions}
          />
        </FrameEditField>
        <FrameEditField title='업무구분코드'>
          <FrameEditInput
            type='text'
            readOnly
          />
        </FrameEditField>
        <FrameEditField title='공통코드길이'>
          <FrameEditInput
            type='text'
            name='CMN_CD_LEN_VAL'
            value={values?.CMN_CD_LEN_VAL || ''}
            onChange={editValues}
          />
        </FrameEditField>
      </EditFieldRow>
      <EditFieldRow>
        <FrameEditField title='코드사용분류'>
          <FrameEditSelect
            name='USE_CLSF_CD'
            value={values?.USE_CLSF_CD || ''}
            onChange={editValues}
            options={codeUseOptions}
          />
        </FrameEditField>
        <FrameEditField title='구 유형코드'>
          <FrameEditInput
            type='text'
            name='OD_TP_CD'
            value={values?.OD_TP_CD || ''}
            readOnly
          />
        </FrameEditField>
        <FrameEditField title='사용여부'>
          <FrameEditSelect
            name='USE_YN'
            value={values?.USE_YN || ''}
            onChange={editValues}
            required={true}
            options={useYnOptions}
          />
        </FrameEditField>
      </EditFieldRow>
      {fieldArray.map((_, index) => (
        <div key={index}>
          <FrameUserField
            values={values}
            setValues={setValues}
            count={index + 1}
            typeOptions={inputTypeOptions}
          />
        </div>
      ))}
      <EditFieldRow className='flex-grow'>
        <FrameEditTextArea
          title='유형코드 설명'
          name='TP_CD_CONT'
          value={values?.TP_CD_CONT || ''}
          onChange={editValues}
        />
      </EditFieldRow>
    </FrameEditBox>
  );
};

export default FrameUserForm;
