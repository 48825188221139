import { CommonButton } from 'components';
import TEG08Scale from './TEG08Scale';
import { useRecoilState, useRecoilValue } from 'recoil';
import { plotState } from 'store';
import { CustomChangeEvent, IPlotProps } from 'models';
import TEG08Axis from './TEG08Axis';
import { useTrendPlotMutation } from 'apis';
import { PlotBox } from 'styles';

const TEG08TrendPlot = ({
  selectParams,
  isSub,
  recoilKey,
}: IPlotProps): JSX.Element => {
  const [trendPlotValue, setTrendPlotValue] = useRecoilState(
    plotState.plotValue('trend'),
  );
  const searchPlot = useRecoilValue(plotState.searchPlot(recoilKey));
  const trendPlotMutation = useTrendPlotMutation();
  const [trendPlot, setTrendPlot] = useRecoilState(plotState.trendPlot);
  const handleChangeAxis = () => {
    trendPlotMutation.mutate(trendPlot);
  };

  const { DVC_NO, DSG_ID, MSR_TYPE, DUT_ID } = searchPlot;
  const { LOG_BTM, LOG_LEFT, LOG_RIGHT, LEGEND_FLAG } = trendPlotValue;
  const subPartParam = isSub ? `${DUT_ID},${LEGEND_FLAG}` : LEGEND_FLAG;
  const paramString = `${DVC_NO},${DSG_ID},${MSR_TYPE},${LOG_BTM ?? 0},${LOG_LEFT ?? 0},${LOG_RIGHT ?? 0},${subPartParam ?? 0}`;

  const handlePlotScale = (e: CustomChangeEvent) => {
    if ('checked' in e.target) {
      const name = e.target.name;
      const value = e.target.checked;

      setTrendPlotValue(old => ({ ...old, [name]: value ? '1' : '0' }));
    }
  };

  return (
    <PlotBox>
      <div className='plot-title'>Trend Plot</div>
      <div className='plot-area'>
        <div className='trend-control'>
          <div className='trend-select'>
            <TEG08Axis
              title='Y축(좌)'
              name='SPC_TRN_AXIS_YL'
              setPlotValue={setTrendPlot}
            />
            <TEG08Axis
              title='Y축(우)'
              name='SPC_TRN_AXIS_YR'
              setPlotValue={setTrendPlot}
            />
          </div>
          <div>
            <CommonButton
              title='적용'
              onClick={handleChangeAxis}
            />
          </div>
        </div>
        <div className='plot-content'>
          <iframe
            className='w-full h-full'
            id='trendPlot'
            src={`/py/plot-trend?${selectParams ? paramString : 'none'}`}
          />
        </div>
        <TEG08Scale
          type='trend'
          isDC={MSR_TYPE === 'DC'}
          plotValue={trendPlotValue}
          setPlotScale={handlePlotScale}
        />
      </div>
    </PlotBox>
  );
};

export default TEG08TrendPlot;
