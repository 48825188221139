import { createColumnHelper } from '@tanstack/react-table';
import { useDocumentDeviceList, useDocumentDeviceMutation } from 'apis';
import {
  ReadOnlyTextCell,
  SelectCell,
  StatusCell,
  StatusIcon,
  Table,
} from 'components';
import { useTableData } from 'hooks';
import {
  Columns,
  CommonArray,
  CommonObject,
  CurrentData,
  IDocumentDevice,
  ITableContext,
  RowType,
  defaultCurrentData,
  defaultDocumentDevice,
} from 'models';
import { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { pdkState } from 'store';
import { DataTableContainer } from 'styles';
import {
  TableContext,
  addRowData,
  addRowNumber,
  filteredOptions,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';

const columnHelper = createColumnHelper();
const newDocumentDevice = defaultDocumentDevice;

const TEG09DocumentDeviceTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const currentDocument = useRecoilValue(pdkState.currentDocument);
  const documentDeviceMutation = useDocumentDeviceMutation();
  const [backupData, setBackupData] = useState<CommonArray>([]);
  const { data: documentDeviceData } = useDocumentDeviceList({
    DOC_NO: currentDocument?.data?.DOC_NO,
  }) as CommonObject;
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: documentDeviceData,
    effect: () => {
      const { deviceList } = documentDeviceData;
      const addNumberData = addRowNumber(deviceList);
      setInitialData(addNumberData);
      setBackupData(addNumberData);
      setCurrentData({ index: 0, data: addNumberData[0] });
    },
  });
  const [currentData, setCurrentData] =
    useState<CurrentData>(defaultCurrentData);
  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      cell: StatusCell,
      size: 25,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('no', {
      header: 'No',
      cell: ReadOnlyTextCell,
      size: 25,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('PRJ_CD', {
      header: '프로젝트',
      cell: SelectCell,
      enableColumnFilter: false,
      meta: {
        optionFn: () =>
          filteredOptions(
            documentDeviceData?.prjCodes,
            'PRJ_CD',
            'PRJ_NM',
            '',
          ),
      },
    }),
    columnHelper.accessor('PRC_CD', {
      header: '공정',
      cell: SelectCell,
      enableColumnFilter: false,
      meta: {
        optionFn: (row: CommonObject) =>
          filteredOptions(
            documentDeviceData?.prcCodes,
            'PRC_CD',
            'PRC_NM',
            row.PRJ_CD,
          ),
      },
    }),
    columnHelper.accessor('DVC_CD', {
      header: '디바이스',
      cell: SelectCell,
      enableColumnFilter: false,
      meta: {
        optionFn: (row: CommonObject) =>
          filteredOptions(
            documentDeviceData?.dvcCodes,
            'DVC_CD',
            'DVC_NM',
            row.PRC_CD,
          ),
      },
    }),
    columnHelper.accessor('DSG_CD', {
      header: '디자인',
      cell: SelectCell,
      enableColumnFilter: false,
      meta: {
        optionFn: (row: CommonObject) =>
          filteredOptions(
            documentDeviceData?.dsgCodes,
            'DSG_CD',
            'DSG_NM',
            row.DVC_CD,
          ),
      },
    }),
  ];
  const columns = useMemo(
    () => defaultColumns,
    [documentDeviceData, initialData],
  ) as Columns;

  const handleAddData = () => {
    const updatedDevice = {
      ...newDocumentDevice,
      DOC_NO: currentDocument.data.DOC_NO,
    } as IDocumentDevice;
    addRowData(
      setInitialData,
      setCurrentData,
      updatedDevice,
      initialData.length,
    );
  };

  const handleRemoveData = (selectDocument: CurrentData) => {
    const removedData = removeRowData(selectDocument, initialData);

    if (removedData.length > 0) {
      setInitialData(removedData);
      selectNextDataByRemove(
        selectDocument,
        initialData.length - 1,
        removedData,
        setCurrentData,
      );
    }
  };

  const handleSaveData = () => {
    const updatedDate = initialData.map(data => {
      if (data.ROWTYPE === RowType.ADD) {
        return {
          ...data,
          DSG_ID: data.DSG_CD.split('_').pop(),
          DVC_NO: data.DVC_CD.split('_').pop(),
        };
      }
      return data;
    });
    const targetData = saveTableData(updatedDate);

    if (targetData.length > 0) {
      documentDeviceMutation.mutate(targetData as IDocumentDevice[]);
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue: ITableContext = {
    handleAddData,
    handleRemoveData,
    handleSaveData,
    currentData,
    setCurrentData,
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        <Table
          title='디바이스'
          initialData={initialData}
          backupData={backupData}
          setData={setInitialData}
          columns={columns}
          editable={true}
          tableHeight={tableHeight}
        />
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default TEG09DocumentDeviceTable;
