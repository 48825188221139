import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IPrivateFileUploadParam } from 'models/interfaces/privateBbsInterface';
import { client } from 'utils';

function privateUploadMutation(
  options: UseMutationOptions<
    unknown,
    Error,
    IPrivateFileUploadParam,
    unknown
  >,
) {
  return useMutation({
    mutationFn: async (uploadParam: IPrivateFileUploadParam) => {
      console.log('mutation start');
      uploadParam.FILES.append('DIR_NAME', uploadParam.DIR_NAME);
      const response = await client.post(
        '/api/co/cm/900m01/file/upload',
        uploadParam.FILES,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );
      return response;
    },
    ...options,
  });
}

export default function usePrivateUploadMutation() {
  const queryClient = useQueryClient();

  return privateUploadMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['dir_list', 'file_list'],
      });
    },
  });
}
