import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { client } from 'utils';
import { IFavoriteMenu } from 'models';

export function saveMyMenu(
  options: UseMutationOptions<unknown, AxiosError, IFavoriteMenu, unknown>,
) {
  return useMutation({
    mutationFn: async (targetMenu: IFavoriteMenu) => {
      const response = await client.post('/api/co/cm/my-menu', targetMenu);
      return response;
    },
    ...options,
  });
}

export default function useSaveMyMenuMutation() {
  const queryClient = useQueryClient();

  return saveMyMenu({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['menu', 'myMenu'] });
    },
  });
}
