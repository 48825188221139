import { memo, useEffect } from 'react';
import { isEqual } from 'utils';
import { ITableCellProps, ITableMeta, RowType } from 'models';
import { DataTableValueBox } from 'styles';

const ReadOnlyNumberCell = ({
  getValue,
  table,
  row,
  column,
  rowRef,
}: ITableCellProps): JSX.Element => {
  const value = Number(getValue());
  const tableMeta: ITableMeta | undefined = table.options.meta;
  const localeValue = value.toLocaleString('ko-KR', {
    maximumFractionDigits: 2,
  });

  useEffect(() => {
    if (rowRef) {
      if (row.original.ROWTYPE !== RowType.NORMAL) {
        tableMeta?.editValue?.(row.index, column.id, value, rowRef);
      }
    }
  }, [value]);

  return (
    <DataTableValueBox $justify='end'>{localeValue}</DataTableValueBox>
  );
};

export default memo(ReadOnlyNumberCell, isEqual);
