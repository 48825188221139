import Loading from 'components/common/Loading';
import { IModalTableColumn, IModalTableProps } from 'models';
import {
  DataTable,
  DataTableCell,
  DataTableHeader,
  DataTableScrollBox,
  ModalTableBox,
} from 'styles';

const ModalTable = ({
  data,
  isLoading,
  columns,
  selectData,
  setSelectData,
  height,
}: IModalTableProps): JSX.Element => {
  const renderColumns: IModalTableColumn[] = [
    { header: 'No', key: 'no' },
    ...columns,
  ];

  if (isLoading) {
    return (
      <ModalTableBox>
        <Loading />
      </ModalTableBox>
    );
  }
  return (
    <ModalTableBox>
      <div className='count-box'>
        <span>전체</span>
        <span className='count'>{data.length}</span>
        <span>건</span>
      </div>
      <DataTableScrollBox style={{ height: height }}>
        <DataTable>
          <thead>
            <tr>
              {renderColumns.map(col => (
                <DataTableHeader key={col.key}>
                  {col.header}
                </DataTableHeader>
              ))}
            </tr>
          </thead>
          {data.length > 0 ? (
            <tbody>
              {data &&
                data.map((row, index) => (
                  <tr
                    key={index}
                    className={`
                    ${
                      selectData === row
                        ? 'bg-blue-100'
                        : 'even:bg-slate-50'
                    }
                    `}
                    onClick={() => setSelectData(row)}
                  >
                    {renderColumns.map((col, i) => (
                      <DataTableCell
                        key={index + i}
                        className='text-center'
                      >
                        {col.key === 'no' ? index + 1 : row[col.key]}
                      </DataTableCell>
                    ))}
                  </tr>
                ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={renderColumns.length}>
                  <div className='empty-box'>
                    조회된 데이터가 없습니다.
                  </div>
                </td>
              </tr>
            </tbody>
          )}
        </DataTable>
      </DataTableScrollBox>
    </ModalTableBox>
  );
};

export default ModalTable;
