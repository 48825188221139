import { codeManagementState } from 'store';
import { FrameUserForm } from 'components';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { USER_FIELD_COUNT, editValues, saveFormData } from 'utils';
import { CustomChangeEvent, ICodeType } from 'models';

const COCM110M01Form = ({ height }: { height: number }) => {
  const currentCodeType = useRecoilValue(
    codeManagementState.currentCodeType,
  );
  const setCodeTypeList = useSetRecoilState(
    codeManagementState.codeTypeList,
  );
  const [values, setValues] = useState<ICodeType>(
    currentCodeType.data as ICodeType,
  );
  const currentIndex = currentCodeType.index;
  const editValue = (e: CustomChangeEvent) => editValues(e, setValues);

  const handleSaveForm = () => {
    saveFormData({
      formData: values,
      dataIndex: currentIndex,
      setTableData: setCodeTypeList,
      compareKey: 'TP_CD',
    });
  };

  useEffect(() => {
    if (currentCodeType) {
      setValues(currentCodeType.data as ICodeType);
    }
  }, [currentCodeType]);
  return (
    <>
      <FrameUserForm
        values={values}
        setValues={setValues}
        editValues={editValue}
        fieldCount={USER_FIELD_COUNT}
        saveForm={handleSaveForm}
        height={height}
      />
    </>
  );
};

export default COCM110M01Form;
