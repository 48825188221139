import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IRuleLayerParam } from 'models';
import { client } from 'utils';

function ruleLayerMutation(
  options: UseMutationOptions<unknown, Error, IRuleLayerParam, unknown>,
) {
  return useMutation({
    mutationFn: async (ruleLayerData: IRuleLayerParam) => {
      console.log(ruleLayerData);
      const response = await client.post(
        '/api/teg/09/doc/rule/layer',
        ruleLayerData,
      );
      return response;
    },
    ...options,
  });
}

export default function useRuleLayerMutation() {
  const queryClient = useQueryClient();

  return ruleLayerMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['document', 'design_rule'],
      });
      queryClient.invalidateQueries({
        queryKey: ['document', 'chapter', 'description'],
      });
    },
  });
}
