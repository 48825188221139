import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { IWordDocumentParam } from 'models';
import { toast } from 'react-toastify';
import { client } from 'utils';

function wordDocumentMutation(
  options: UseMutationOptions<unknown, Error, IWordDocumentParam, unknown>,
) {
  return useMutation({
    mutationFn: async (wordParam: IWordDocumentParam) => {
      const response = await client.get('/python/word_chapter', {
        params: wordParam,
      });
      console.log(response.data);
      return response.data.code;
    },
    ...options,
  });
}

export default function useWordDocumentMutation() {
  return wordDocumentMutation({
    onSuccess: response => {
      response === '200'
        ? toast.success('문서 생성 성공')
        : toast.error('문서 생성 실패. 입력되지 않은 값이 있습니다.');
    },
  });
}
