import { ITableHeadProps } from 'models';
import TableHeader from './TableHeader';

const TableHead = ({
  headerGroups,
  tableMeta,
}: ITableHeadProps): JSX.Element => {
  return (
    <thead>
      {headerGroups.map((headers, index) => (
        <tr key={index}>
          {headers.map(header =>
            header.column.getCanHide() ? (
              <TableHeader
                key={header.id}
                header={header}
                tableMeta={tableMeta}
              />
            ) : null,
          )}
        </tr>
      ))}
    </thead>
  );
};

export default TableHead;
