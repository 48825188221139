import {
  CommonButton,
  FrameInfo,
  FrameInfoInput,
  FrameInfoSelect,
} from 'components';
import { useSearchComboList } from 'hooks';
import { CustomChangeEvent, ISearchUserCode } from 'models';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { systemManagementState } from 'store';
import { editValues } from 'utils';

const COCM100M01Info = (): JSX.Element => {
  const userTypeOptions = useSearchComboList({ CMB_CD: 'CMB0022' });
  const hlfcDttCdOptions = useSearchComboList({ CMB_CD: 'CMB0023' });

  const [searchUser, setSearchUser] = useRecoilState(
    systemManagementState.searchUser('COCM100M01'),
  );
  const [values, setValues] = useState<ISearchUserCode>(searchUser);

  const setCustomValues = (value: string | number, codeType: string) => {
    const targetList =
      codeType === 'USR_TP_CD' ? userTypeOptions : hlfcDttCdOptions;
    const target = targetList.find(option => option.value === value);

    if (target) {
      setValues(old => ({
        ...old,
        [codeType]: target.value,
        [`${codeType}_NM`]: target.label,
      }));
    }
  };

  const editValue = (e: CustomChangeEvent, codeType?: string) => {
    editValues(e, setValues);
    if (codeType) {
      setCustomValues(e.target.value, codeType);
    }
  };

  const handleSearch = () => {
    setSearchUser(values);
  };

  useEffect(() => {
    if (
      userTypeOptions &&
      userTypeOptions.length > 0 &&
      hlfcDttCdOptions &&
      hlfcDttCdOptions.length > 0
    ) {
      setValues(old => ({
        ...old,
        USR_TP_CD: userTypeOptions[0].value,
        HLFC_DTT_CD: hlfcDttCdOptions[0].value,
      }));
      setSearchUser(old => ({
        ...old,
        USR_TP_CD: userTypeOptions[0].value,
        HLFC_DTT_CD: hlfcDttCdOptions[0].value,
      }));
    }
  }, [userTypeOptions, hlfcDttCdOptions]);

  useEffect(() => {
    if (values.USR_TP_CD) {
      handleSearch();
    }
  }, [values.USR_TP_CD]);

  return (
    <FrameInfo>
      <div className='search-form'>
        <div className='input-block'>
          <FrameInfoSelect
            title='사용자유형'
            options={userTypeOptions}
            onChange={e => editValue(e, 'USR_TP_CD')}
          />
          <FrameInfoInput
            title='사용자ID'
            type='text'
            onChange={editValue}
            name='USR_ID'
          />
          <FrameInfoInput
            title='사용자명'
            type='text'
            onChange={editValue}
            name='USR_NM'
          />
          <FrameInfoInput
            title='부서명'
            type='text'
            onChange={editValue}
            name='DPT_NM'
          />
          <FrameInfoSelect
            title='재직구분'
            options={hlfcDttCdOptions}
            onChange={e => editValue(e, 'HLFC_DTT_CD')}
          />
        </div>
        <div>
          <CommonButton
            title='조회'
            onClick={handleSearch}
          />
        </div>
      </div>
    </FrameInfo>
  );
};

export default COCM100M01Info;
