import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Table, ReadOnlyTextCell } from 'components';
import { TableContext } from 'utils';
import { Columns, CommonObject } from 'models';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTableData } from 'hooks';
import { ITableContext } from 'models';
import { deviceState } from 'store';
import useProjectListInDeviceModal from 'apis/queries/useProjectListInDeviceModal';

const columnHelper = createColumnHelper();

const TEG03ModalProjectTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const { data: project } = useProjectListInDeviceModal() as CommonObject;
  const [currentData, setCurrentData] = useRecoilState(
    deviceState.currentModalProjectData,
  );
  const prjNo = useRecoilValue(deviceState.searchDevice).PRJ_NO;
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: project,
    atom: deviceState.projectListInModal,
    effect: () => {
      setInitialData(project);
      let matchingIndex = -1;
      for (let i = 0; i < project.length; i++) {
        if (project[i].PRJ_NO == prjNo) {
          matchingIndex = i;
          break;
        }
      }

      if (matchingIndex !== -1) {
        setCurrentData({
          index: matchingIndex,
          data: project[matchingIndex],
        });
      }
    },
  });

  const defaultColumns = [
    columnHelper.accessor('PRJ_NM', {
      header: '프로젝트',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const contextValue: ITableContext = {
    currentData,
    setCurrentData,
  };

  return (
    <TableContext.Provider value={contextValue}>
      {initialData && (
        <Table
          title='프로젝트'
          initialData={initialData}
          setData={setInitialData}
          columns={columns}
          tableHeight={tableHeight}
        />
      )}
    </TableContext.Provider>
  );
};

export default TEG03ModalProjectTable;
