import { CommonObject, CustomChangeEvent, ITrendProps } from 'models';
import { useRecoilValue } from 'recoil';
import { plotState } from 'store';
import { convertComboToOptions } from 'utils';

const emptyOption = { value: '', label: '선택' };

const TEG08Axis = ({
  title,
  name,
  setPlotValue,
}: ITrendProps): JSX.Element => {
  const specCodes = useRecoilValue(plotState.specCodes);
  const trendPlot = useRecoilValue(plotState.trendPlot);
  const converCodes = specCodes.map(code => ({
    CMN_CD: code.CODE,
    CMN_CD_NM: code.VALUE,
  }));
  const axisOptions = [emptyOption, ...convertComboToOptions(converCodes)];
  const handleSelect = (e: CustomChangeEvent) => {
    const name = e.target.name;
    const value = e.target.value;

    setPlotValue(old => ({
      ...old,
      [name]: value,
    }));
  };

  return (
    <div className='select-item'>
      <span>{title}</span>
      <select
        name={name}
        value={trendPlot[name]}
        onChange={handleSelect}
      >
        {axisOptions &&
          axisOptions.map((option: CommonObject, index: number) => (
            <option
              key={option.label + index}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
      </select>
    </div>
  );
};

export default TEG08Axis;
