import { PlotContainer } from 'styles';
import TEG08TotalPlot from './TEG08TotalPlot';
import TEG08TrendPlot from './TEG08TrendPlot';
import { useRecoilValue } from 'recoil';
import { plotState } from 'store';

const TEG08Plot = ({
  isSub = false,
  recoilKey,
}: {
  isSub?: boolean;
  recoilKey: string;
}): JSX.Element => {
  const searchPlot = useRecoilValue(plotState.searchPlot(recoilKey));
  const notSubSelectParam =
    searchPlot.DSG_ID !== '' && searchPlot.MSR_TYPE !== '';
  const subSelectParam = Object.values(searchPlot).every(t => t !== '');
  const selectParams = isSub ? subSelectParam : notSubSelectParam;

  return (
    <PlotContainer>
      <div className='w-1/2'>
        <TEG08TotalPlot
          selectParams={selectParams}
          isSub={isSub}
          recoilKey={recoilKey}
        />
      </div>
      <div className='w-1/2'>
        <TEG08TrendPlot
          selectParams={selectParams}
          isSub={isSub}
          recoilKey={recoilKey}
        />
      </div>
    </PlotContainer>
  );
};

export default TEG08Plot;
