import { useQuery } from '@tanstack/react-query';
import { ISearchDocumentKey, IQueryParams } from 'models';
import { client } from 'utils';

function chatperKeyList({
  searchData,
  options,
}: IQueryParams<ISearchDocumentKey>) {
  return useQuery({
    queryKey: ['document', 'chapter_key', searchData],
    queryFn: async () => {
      const response = await client.get('/api/teg/09/doc/key', {
        params: searchData,
      });

      return response.data;
    },
    ...options,
  });
}

export default function useChapterKeyList(searchData: ISearchDocumentKey) {
  return chatperKeyList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled:
        searchData.CHR_SNO !== undefined && searchData.CH_NO !== undefined,
    },
  });
}
