import { BORDER_ALL, CELL_BG_COLOR } from './constants';
import XLSX from 'xlsx-js-style';
import { getDateTimeLocal } from './timeHelper';
import { getBytes } from './dataHelper';
import { toast } from 'react-toastify';

function getFileName(fileName) {
  const fileExtension = '.xlsx';
  const currentDate = getDateTimeLocal();

  return `${currentDate}_${fileName + fileExtension}`;
}

function convertSheetData(columnData, rowData) {
  const flatColumnData = columnData.flatMap(col => {
    if (!col.cell && col.columns.length > 0) {
      return [...col.columns];
    }

    return col;
  });

  const cols = flatColumnData.filter(col => 'accessorKey' in col);

  const names = cols.map(col => ({
    v: col.header,
    s: {
      ...BORDER_ALL,
      ...getCellBg(CELL_BG_COLOR),
      ...getAlignMent('center'),
      ...getFont(11, true),
    },
  }));

  const keys = cols.map(col => ({
    v: col.meta
      ? col.meta.renderKey
        ? col.meta.renderKey
        : col.accessorKey
      : col.accessorKey,
    s: BORDER_ALL,
  }));

  const rows = rowData.map(row =>
    keys.map(key => ({
      v: row[key.v] ? row[key.v] : typeof row[key.v] === 'number' ? 0 : '',
      s: {
        ...BORDER_ALL,
        ...getAlignMent(typeof row[key.v] === 'number' ? 'right' : 'left'),
      },
    })),
  );

  const sheetData = [names, ...rows];

  return { sheetData, keys };
}

function calculateColsWidth(sheetData) {
  const widths = [];

  for (let i = 0; i < sheetData[0].length; i++) {
    const maxWidth = sheetData.reduce(
      (prev, now) => Math.max(prev, getBytes(now[i].v) + 1),
      0,
    );
    widths.push({ wch: maxWidth });
  }
  return widths;
}

function getFont(size, bold) {
  return {
    font: {
      sz: size,
      bold: bold,
    },
  };
}
function getAlignMent(position) {
  return {
    alignment: { horizontal: position },
  };
}
function getCellBg(color) {
  return {
    fill: {
      fgColor: {
        rgb: color,
      },
    },
  };
}

function setSheetHeader(workSheet, keys, fileName) {
  const sheetHeader = XLSX.utils.sheet_add_aoa(
    workSheet,
    [[fileName], [`작성일 : ${getDateTimeLocal()}`]],
    {
      origin: 'A1',
    },
  );

  const headRowMerges = [
    {
      s: { c: 0, r: 0 },
      e: { c: keys.length - 1, r: 0 },
    },
    {
      s: { c: 0, r: 1 },
      e: { c: keys.length - 1, r: 1 },
    },
  ];

  sheetHeader['A1'].s = {
    ...getAlignMent('center'),
    ...getFont(12, true),
    ...getCellBg(CELL_BG_COLOR),
  };
  sheetHeader['A2'].s = {
    ...getAlignMent('right'),
    ...getFont(12, true),
    ...getCellBg(CELL_BG_COLOR),
  };

  sheetHeader['!merges'] = headRowMerges;
}

export function exportJsonToSheet(columnData, rowData, fileName) {
  if (columnData.length === 0 || rowData.length === 0) {
    toast.warning('저장할 수 있는 데이터가 존재하지 않습니다.');
    return;
  }

  if (!Array.isArray(columnData) || !Array.isArray(rowData)) {
    toast.error('파일로 저장 할 수 없는 데이터 형식입니다.');
    return;
  }

  const { sheetData, keys } = convertSheetData(columnData, rowData);

  const filFullname = getFileName(fileName);

  const workBook = XLSX.utils.book_new();
  const workSheet = XLSX.utils.aoa_to_sheet(sheetData, {
    origin: '3A',
  });

  setSheetHeader(workSheet, keys, fileName);

  const widths = calculateColsWidth(sheetData);
  workSheet['!cols'] = widths;
  console.log('workBook :: ', workBook);
  XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1');
  XLSX.writeFile(workBook, filFullname, {
    compression: true,
  });
}
