import { IFrameEditInputProps } from 'models';

const FrameEditInput = ({ required, ...props }: IFrameEditInputProps) => {
  return (
    <input
      {...props}
      className={required ? 'required' : undefined}
    />
  );
};

export default FrameEditInput;
