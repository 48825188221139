import { BiSolidFolder, BiSolidFolderOpen } from 'react-icons/bi';
import { isEqual } from 'utils';
import { memo } from 'react';
import { IColumnMeta, ITableCellProps } from 'models';
import { TreeCellBorderBox, TreeValueBox } from 'styles';

const initialPadding = 5;
const levelPadding = 15;

const TreeCell = ({
  getValue,
  row,
  column,
}: ITableCellProps): JSX.Element => {
  const value = getValue();
  const columnMeta: IColumnMeta | undefined = column.columnDef.meta;
  const hasChildren = columnMeta?.hasChildren?.(row.original);
  const level = row.original.LEVEL;

  return (
    <TreeValueBox
      style={{
        paddingLeft: initialPadding + level * levelPadding,
      }}
    >
      <TreeCellBorderBox>
        <div className='border-box' />
        <div />
      </TreeCellBorderBox>
      {hasChildren ? (
        <BiSolidFolderOpen className='text-blue-600' />
      ) : (
        <BiSolidFolder className='text-slate-400' />
      )}
      <span>{value}</span>
    </TreeValueBox>
  );
};

export default memo(TreeCell, isEqual);
