import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IDesignDutFileWrapper } from 'models';
import { toast } from 'react-toastify';
import { client, getAlertMessage } from 'utils';

function designDutFileMutation(
  options: UseMutationOptions<
    unknown,
    Error,
    IDesignDutFileWrapper,
    unknown
  >,
) {
  return useMutation({
    mutationFn: async (designDutFileWrapper: IDesignDutFileWrapper) => {
      const formData = new FormData();
      const { file, measureSave } = designDutFileWrapper;
      formData.append('file', file); // 파일 추가
      formData.append(
        'measureSave',
        new Blob([JSON.stringify(measureSave)], {
          type: 'application/json',
        }),
      );
      const response = await client.post(
        '/api/teg/06/file/text/upload',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );
      return response;
    },
    ...options,
  });
}

export default function useDesignDutFileMutation() {
  const queryClient = useQueryClient();

  return designDutFileMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['desingDescription'],
      });
      toast.success(getAlertMessage('INFO_04020'));
    },
  });
}
