import { useHeight, useSearchComboList } from 'hooks';
import { useState } from 'react';
import {
  CommonButton,
  FrameInfo,
  FrameInfoInput,
  FrameInfoSelect,
} from 'components';
import { useRecoilState } from 'recoil';
import { editValues } from 'utils';
import COCM180M01Table from './COCM180M01Table';
import { CustomChangeEvent, ISearchLoginHistory } from 'models';
import { FrameContent } from 'styles';
import { historyManagementState } from 'store';

const COCM180M01 = (): JSX.Element => {
  const getHeight = useHeight();

  const [searchLoginHistory, setSearchLoginHistory] = useRecoilState(
    historyManagementState.searchLoginHistory,
  );
  const [values, setValues] =
    useState<ISearchLoginHistory>(searchLoginHistory);
  const domainOptions = useSearchComboList({ CMB_CD: 'CMB0027' });
  const editValue = (e: CustomChangeEvent) => editValues(e, setValues);

  const handleSearchLoginHistory = () => {
    const changeDateValues = {
      ...values,
      LGN_ST_DATE: values.LGN_ST_DATE.replaceAll('-', ''),
      LGN_END_DATE: values.LGN_END_DATE.replaceAll('-', ''),
    };
    setSearchLoginHistory(changeDateValues);
  };

  return (
    <>
      <FrameInfo>
        <div className='search-form'>
          <div className='input-block'>
            <FrameInfoSelect
              title='도메인'
              options={domainOptions}
              name='DMN_CD'
              onChange={editValue}
            />
            <FrameInfoInput
              title='로그인일자'
              type='date'
              name='LGN_ST_DATE'
              value={values?.LGN_ST_DATE || ''}
              onChange={editValue}
            />
            <FrameInfoInput
              title='~'
              type='date'
              name='LGN_END_DATE'
              value={values?.LGN_END_DATE || ''}
              onChange={editValue}
            />
            <FrameInfoInput
              title='사용자 ID'
              type='text'
              name='USR_ID'
              value={values?.USR_ID || ''}
              onChange={editValue}
            />
            <FrameInfoInput
              title='IP주소'
              type='text'
              name='IP_ADDR'
              value={values?.IP_ADDR || ''}
              onChange={editValue}
            />
          </div>
        </div>
        <div>
          <CommonButton
            title='조회'
            onClick={handleSearchLoginHistory}
          />
        </div>
      </FrameInfo>
      <FrameContent>
        <div className='w-full'>
          <COCM180M01Table tableHeight={getHeight(650)} />
        </div>
      </FrameContent>
    </>
  );
};

export default COCM180M01;
