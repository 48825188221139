import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchDocumentValue } from 'models';
import { client } from 'utils';

function chapterKeyValueList({
  searchData,
  options,
}: IQueryParams<ISearchDocumentValue>) {
  return useQuery({
    queryKey: [
      'document',
      'chapter_key',
      'value',
      searchData.CHR_SNO,
      searchData.KEY,
    ],
    queryFn: async () => {
      const response = await client.get('/api/teg/09/doc/value', {
        params: searchData,
      });
      return response.data;
    },
    ...options,
  });
}

export default function useChapterKeyValueList(
  searchData: ISearchDocumentValue,
) {
  return chapterKeyValueList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled:
        searchData.DOC_NO !== undefined &&
        searchData.CHR_SNO !== undefined &&
        searchData.CH_NO !== undefined &&
        searchData.KEY !== undefined,
    },
  });
}
