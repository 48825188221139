import {
  IDscCode,
  ISearchDeviceType,
} from 'models/interfaces/dscCodeInterface';
import { RowType } from 'models/types/commonTypes';

export const defaultDscCode: IDscCode = {
  DSC_CD: '',
  DVC_TYPE: '',
  DSC_NM: '',
  VAL_TYPE: '',
  VAL_EXPR: '',
  SRT_SQN: 0,
  W_FLAG: '',
  RMK_CONT: '',
  ROWTYPE: RowType.ADD,
};

export const defaultSearchDeviceType: ISearchDeviceType = {
  DVC_TYPE: '',
};
