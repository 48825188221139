import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { MesuerValue } from 'models';
import { client } from 'utils';

function singlePlotMesureMutation(
  options: UseMutationOptions<unknown, Error, MesuerValue[], unknown>,
) {
  return useMutation({
    mutationFn: async (mesureList: MesuerValue[]) => {
      const response = await client.post(
        '/api/teg/08/plot/measurement',
        mesureList,
      );
      return response;
    },
    ...options,
  });
}

export default function useSinglePlotMesureMutation() {
  const queryClient = useQueryClient();

  return singlePlotMesureMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['plot', 'mesurement'],
      });
    },
  });
}
