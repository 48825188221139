import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IAppendixParam } from 'models';
import { client } from 'utils';

function appendixMutation(
  options: UseMutationOptions<unknown, Error, IAppendixParam, unknown>,
) {
  return useMutation({
    mutationFn: async appendixParam => {
      const response = await client.post(
        '/api/teg/09/doc/guide/appendix',
        appendixParam,
      );
      return response;
    },
    ...options,
  });
}

export default function useAppendixMutation() {
  const queryClient = useQueryClient();

  return appendixMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['document', 'chapter', 'appendix'],
      });
    },
  });
}
