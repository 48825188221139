import { CommonObject, CustomChangeEvent, ITableMeta } from 'models';
import { ChangeEvent, useEffect, useState } from 'react';

const useCellValue = (
  initialValue: any,
  tableMeta?: ITableMeta,
  rowRef?: CommonObject,
) => {
  const [inputValue, setInputValue] = useState(initialValue);
  const changeChecked = (
    rowIndex: number,
    key: string,
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    const checkValue = e.target.checked
      ? inputValue === '0'
        ? '1'
        : 'Y'
      : inputValue === '1'
        ? '0'
        : 'N';
    setInputValue(checkValue);
    tableMeta?.editValue?.(rowIndex, key, checkValue, rowRef);
  };

  const handleChangeValue = (
    rowIndex: number,
    e: CustomChangeEvent,
    selectType?: string,
  ) => {
    const name = e.target.name;
    const type = e.target.type;
    const covertInitialValue =
      type === 'number' ||
      (type === 'select-one' && selectType === 'number')
        ? Number(initialValue)
        : String(initialValue);
    const value =
      type === 'number' ||
      (type === 'select-one' && selectType === 'number')
        ? Number(e.target.value)
        : e.target.value;

    if (type === 'checkbox') {
      changeChecked(rowIndex, name, e as ChangeEvent<HTMLInputElement>);
      return;
    }

    setInputValue(value);

    if (covertInitialValue !== value) {
      tableMeta?.editValue?.(rowIndex, name, value, rowRef);
    }
  };

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  return [inputValue, handleChangeValue, setInputValue];
};

export default useCellValue;
