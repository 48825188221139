import { useQuery } from '@tanstack/react-query';
import { RowType, UseQueryOptionsWithoutQueryKey } from 'models';
import { client } from 'utils';
import { ISearchProject } from 'models/interfaces/projectInterface';

interface IProcessListParam {
  searchData: ISearchProject;
  options: UseQueryOptionsWithoutQueryKey;
}

function process({ searchData, options }: IProcessListParam) {
  return useQuery({
    queryKey: ['process', 'maskList', searchData],
    queryFn: async () => {
      const response = await client.get('/api/teg/01/process', {
        params: searchData,
      });
      const { processList, maskList } = response.data;
      return {
        processList,
        maskList,
      };
    },
    ...options,
  });
}

export default function useProcessList(searchData: ISearchProject) {
  return process({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: searchData.ROWTYPE !== RowType.ADD,
    },
  });
}
