import { IHeaderUserInfoBoxProps } from 'models';
import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { loginState } from 'store';
import { RenewDiv } from 'styles';
import SidePasswordForm from './SidePasswordForm';

const SideRenewBox = ({
  openInfo: openRenew,
  setOpenInfo: setOpenRenew,
}: IHeaderUserInfoBoxProps): JSX.Element => {
  const userInfoRef = useRef<HTMLDivElement>(null);
  const userInfo = useRecoilValue(loginState.userInfo);

  useEffect(() => {
    const handleInfoBoxClose = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (openRenew && !userInfoRef.current?.contains(target)) {
        setOpenRenew(false);
      }
    };

    document.addEventListener('click', handleInfoBoxClose);

    return () => {
      document.removeEventListener('click', handleInfoBoxClose);
    };
  }, [openRenew]);

  return (
    <RenewDiv
      ref={userInfoRef}
      $openInfo={openRenew}
    >
      <div className='renew'>
        <span className='renew-id'>{userInfo?.USR_ID}</span>
        <span className='renew-etc'>
          {userInfo?.USR_NM} / {userInfo?.EMP_NO} / {userInfo?.DPT_NM}
        </span>
      </div>
      <hr className='my-2' />
      <SidePasswordForm
        openInfo={openRenew}
        setOpenInfo={setOpenRenew}
      />
    </RenewDiv>
  );
};

export default SideRenewBox;
