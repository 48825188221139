import {
  IAuthority,
  ISearchAuthority,
  ISearchAuthorityMenu,
} from 'models/interfaces/authorityManagementInterface';
import {
  IPopup,
  ISearchComboData,
  ISearchPopup,
} from 'models/interfaces/formManagementInterface';
import {
  ISearchErrorLog,
  ISearchLoginHistory,
  ISearchSystemLog,
} from 'models/interfaces/historyManagementInterface';
import {
  IMenu,
  IProgram,
  ISearchChildMenu,
  ISearchParentMenu,
  ISearchProgram,
} from 'models/interfaces/menuManagementInterface';
import {
  ISearchMessage,
  ISystemMessage,
} from 'models/interfaces/standardInfomationInterface';
import {
  IDepartmentCode,
  ISearchDepartmentCode,
  ISearchUserCode,
  IUserCode,
} from 'models/interfaces/systemManagementInterface';
import { RowType } from 'models/types/commonTypes';

export const defaultSearchDepartmentCode: ISearchDepartmentCode = {
  DPT_NM: '',
  USE_YN: 'Y',
};

export const defaultSearchUser: ISearchUserCode = {
  USR_TP_CD: '0001',
  USR_ID: '',
  USR_NM: '',
  DPT_CD: '',
  DPT_NM: '',
  RSPOFC_CD: '',
  HLFC_DTT_CD: '',
};

export const defaultUser: IUserCode = {
  USR_TP_CD: '',
  USR_ID: '',
  EMP_NO: '',
  USR_NM: '',
  DPT_CD: '',
  PSCL_CD: '',
  RSPOFC_CD: '',
  HLFC_DTT_CD: '',
  BLC_YN: 'N',
  ROWTYPE: RowType.ADD,
};

export const defaultDepartmentCode: IDepartmentCode = {
  DPT_CD: '',
  DPT_NM: '',
  DPT_DTT_CD: '',
  DPT_DTT_CD_NM: '',
  HRK_DPT_CD: '',
  HRK_DPT_NM: '',
  REG_YMD: '',
  DUS_YMD: '',
  SRT_SQN: 0,
  USE_YN: 'Y',
  RMK_CONT: '',
  ROWTYPE: RowType.ADD,
};

export const defaultSearchMessage: ISearchMessage = {
  MSG_DTT_CD: '',
  MSG_CD: '',
  MSG_NM: '',
  USE_YN: '',
};

export const defaultSystemMessage: ISystemMessage = {
  MSG_CD: '',
  MSG_NM: '',
  MSG_DTT_CD: '',
  MSG_TLT: '',
  USE_YN: 'Y',
  MSG_CONT: '',
  ROWTYPE: RowType.ADD,
};

export const defaultSearchProgram: ISearchProgram = {
  PGM_ID: '',
  PGM_NM: '',
  SYS_DTT_CD: '',
};

export const defaultProgram: IProgram = {
  PGM_ID: '',
  PGM_NM: '',
  SYS_DTT_CD: '',
  PGM_URL_NM: '',
  PGM_DESC_CONT: '',
  USE_YN: 'Y',
  DESC_RMK: '',
  ROWTYPE: RowType.ADD,
};

export const defaultSearchParentMenu: ISearchParentMenu = {
  DMN_CD: 'AIBIZ',
  SYS_DTT_CD: '',
  USE_YN: '',
  MNU_NM: '',
};
export const defaultSearchChildtMenu: ISearchChildMenu = {
  DMN_CD: '',
  USE_YN: '',
  HRK_MNU_ID: '',
};

export const defaultParentMenu: IMenu = {
  MNU_ID: '',
  HRK_MNU_ID: '',
  HRK_MNU_NM: '',
  MNU_NM: '',
  PGM_ID: '',
  PGM_NM: '',
  MNU_PARM_VAL: '',
  CNN_DTT_CD: '',
  SRT_SQN: '',
  MNU_IDCT_YN: '1',
  PRV_INF_ICD_YN: '0',
  NTH_LGN_PMS_YN: '0',
  DMN_CD: '',
  USE_YN: '1',
  ROWTYPE: RowType.ADD,
};

export const defaultSearchAuthority: ISearchAuthority = {
  ATH_ID: '',
  ATH_NM: '',
};

export const defaultAuthority: IAuthority = {
  ATH_ID: '',
  ATH_NM: '',
  ATH_DESC_CONT: '',
  USE_YN: 'Y',
  SRT_SQN: 0,
  WHL_RLT_CMP_USE_YN: 'N',
  ROWTYPE: RowType.ADD,
};

export const defaultSearchAuthorityMenu: ISearchAuthorityMenu = {
  ATH_ID: '',
  SYS_DTT_MNU: '',
};

export const defaultSearchLoginHistory: ISearchLoginHistory = {
  LGN_ST_DATE: '',
  LGN_END_DATE: '',
  USR_ID: '',
  IP_ADDR: '',
};

export const defaultSearchSystemLog: ISearchSystemLog = {
  LOG_ST_DT: '',
  LOG_END_DT: '',
  PGM_ID: '',
  PGM_NM: '',
  USR_ID: '',
  CL_MTH_NM: '',
  CL_VRB_CONT: '',
};

export const defaultSearchErrorLog: ISearchErrorLog = {
  PGM_NM: '',
  ERR_TP_CD: '',
  USR_ID: '',
  OCC_FROM_DT: '',
  OCC_TO_DT: '',
  ERR_LOG_CONT: '',
};

export const defaultSearchPopup: ISearchPopup = {
  SRCH_PPU_CD: '',
  PPU_NM: '',
};

export const defaultPopup: IPopup = {
  SRCH_PPU_CD: '',
  PPU_NM: '',
  SYS_DTT_CD: '',
  PPU_CONT: '',
  AT_INQ_YN: '',
  SRCH_CND_ESN_YN: '',
  GRID_CONT: '',
  INQ_TGT_SNTX_CONT: '',
  INQ_TBL_SNTX_CONT: '',
  INQ_CND_SNTX_CONT: '',
  CMB_COLM_SNTX_CONT: '',
  USE_YN_SNTX_CONT: '',
  APN_CND_SNTX_CONT: '',
  SRT_SNTX_CONT: '',
  SRCH_CMB_CONT: '',
  PPU_WDT_VAL: '',
  PPU_HGH_VAL: '',
  MNGR_NM: '',
  REGR_ID: '',
  UPDR_ID: '',
  ROWTYPE: RowType.ADD,
};

export const defaultSearchComboData: ISearchComboData = {
  APN_CND_SNTX_CONT: ' ',
  CMB_CD: '',
  CMB_NM: '',
  INQ_CND_SNTX_CONT: '',
  INQ_TGT_SNTX_CONT: '',
  MNGR_NM: '',
  SRT_SNTX_CONT: '',
  USE_YN_SNTX_CONT: '',
  ROWTYPE: RowType.ADD,
};
