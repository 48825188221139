import { useQuery } from '@tanstack/react-query';
import { IQueryParams, IUserCode } from 'models';
import { client } from 'utils';

function userList({ searchData, options }: IQueryParams<IUserCode>) {
  return useQuery({
    queryKey: ['user_list', searchData],
    queryFn: async () => {
      const response = await client.get('/api/co/cm/100m01/user', {
        params: searchData,
      });
      return response.data.userList;
    },
    ...options,
  });
}

export default function useUserList(searchData: IUserCode) {
  return userList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
