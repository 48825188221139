import { Modal } from 'components';
import { useHeight } from 'hooks';
import { CommonObject, DEPARTMENT_PARAM, RowType } from 'models';
import { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { systemManagementState } from 'store';
import { FrameContent } from 'styles';
import COCM100M01Info from './COCM100M01Info';
import COCM100M01Table from './COCM100M01Table';

const COCM100M01 = (): JSX.Element => {
  const getHeight = useHeight();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const currentData = useRecoilValue(
    systemManagementState.currentUser('COCM100M01'),
  );
  const setUserData = useSetRecoilState(
    systemManagementState.userList('COCM100M01'),
  );

  const selectValueByModal = (selectValue: CommonObject) => {
    setUserData(oldData =>
      oldData.map((user, index) => {
        if (index === currentData.index) {
          const isChanged =
            user.DPT_CD !== selectValue.DPT_CD ||
            user.DPT_NM !== selectValue.DPT_NM;
          const isInserted = user.ROWTYPE === RowType.ADD;
          const isDeleted = user.ROWTYPE === RowType.DELETE;
          let rowType = RowType.NORMAL;
          if (isChanged && !isInserted && !isDeleted) {
            rowType = RowType.UPDATE;
          } else if (isInserted) {
            rowType = RowType.ADD;
          } else if (isDeleted) {
            rowType = RowType.DELETE;
          } else if (!isChanged && !isInserted && !isDeleted) {
            rowType = user.ROWTYPE;
          }
          return {
            ...user,
            DPT_CD: selectValue.DPT_CD,
            DPT_NM: selectValue.DPT_NM,
            ROWTYPE: rowType,
          };
        }
        return user;
      }),
    );
    setModalOpen(false);
  };

  return (
    <>
      <COCM100M01Info />
      <FrameContent>
        <div className='grow-col-between'>
          <COCM100M01Table
            tableHeight={getHeight(650)}
            openModal={setModalOpen}
          />
        </div>
      </FrameContent>
      {modalOpen && (
        <Modal
          onClose={() => setModalOpen(false)}
          param={DEPARTMENT_PARAM}
          handleSelect={selectValueByModal}
        />
      )}
    </>
  );
};

export default COCM100M01;
