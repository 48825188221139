import { useQuery } from '@tanstack/react-query';
import { IQueryParams } from 'models';
import { client } from 'utils';

function guideImageNameList({
  searchData,
  options,
}: IQueryParams<string>) {
  return useQuery({
    queryKey: ['document', 'guide', searchData],
    queryFn: async () => {
      const response = await client.get(
        `/api/teg/09/doc/guide/imageCount/${searchData}`,
      );
      return response.data.imageNameList;
    },
    ...options,
  });
}

export default function useGuideImageNameList(searchData: string) {
  return guideImageNameList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      gcTime: 0,
      staleTime: 0,
      enabled: searchData !== '' && searchData !== undefined,
    },
  });
}
