import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IDeviceDrawSaveWrapper } from 'models';
import { toast } from 'react-toastify';
import { client, getAlertMessage } from 'utils';

export default function useDeviceDrawMutation() {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, IDeviceDrawSaveWrapper, unknown>({
    mutationFn: async (wrapper: IDeviceDrawSaveWrapper) => {
      const response1 = await client.post(
        '/api/teg/04/modal/draw',
        wrapper.dataObject,
      );
      if (wrapper?.fileObject?.file) {
        if (response1.status === 200) {
          console.log('파일정보 : ', wrapper.fileObject);
          const formData = new FormData();
          formData.append('file', wrapper.fileObject.file);
          formData.append(
            'drawInfo',
            new Blob([JSON.stringify(wrapper.fileObject.drawInfo)], {
              type: 'application/json',
            }),
          );
          const response2 = await client.post(
            '/api/teg/04/modal/file/image/upload',
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } },
          );
          return response2;
        }
      } else {
        return response1;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['module', 'device', 'draw'],
      });
      toast.success(getAlertMessage('INFO_00010'));
    },
  });
}
