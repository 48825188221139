import { useQuery } from '@tanstack/react-query';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { ReadOnlyTextCell } from 'components';
import { CommonArray, IQueryParams, ISearchDescription } from 'models';
import { client } from 'utils';

const columnHelper = createColumnHelper();

function addVopColumns(vopData: CommonArray) {
  if (vopData.length === 0) {
    return [];
  }
  const vopEntries = Object.entries(vopData[0]).sort();
  const vopColumns = vopEntries
    .map(([key, value]) => {
      if (!value) {
        return null;
      }
      const column = key.replace('NM', 'CONT');
      return columnHelper.accessor(column, {
        header: value,
        cell: ReadOnlyTextCell,
        size: 25,
        enableColumnFilter: false,
        meta: {
          justify: 'center',
        },
      }) as ColumnDef<unknown, any>;
    })
    .filter(Boolean) as ColumnDef<unknown, any>[];

  const vopGroup = columnHelper.group({
    header: 'STD',
    columns: vopColumns,
  });
  return [vopGroup];
}

function moduleDescriptionList({
  searchData,
  options,
}: IQueryParams<ISearchDescription>) {
  return useQuery({
    queryKey: ['module', 'device', 'description', searchData],
    queryFn: async () => {
      const response = await client.get('/api/teg/05/description', {
        params: searchData,
      });
      const { descriptionList, vopCodes, moveList, checkList } =
        response.data;
      console.log('속성 데이터 : ', response.data.descriptionList);
      const vopColumns = addVopColumns(vopCodes);
      return {
        descriptionList,
        vopColumns,
        moveList,
        checkList,
      };
    },
    ...options,
  });
}

export default function useModuleDescriptionList(
  searchData: ISearchDescription,
) {
  return moduleDescriptionList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      gcTime: 0,
      enabled:
        searchData.DVC_NO !== '' &&
        searchData.MDL_ID !== '' &&
        searchData.MDL_ID !== '-1',
    },
  });
}
