import {
  IDevice,
  IDeviceDoc,
  IDeviceVop,
  ISearchDevice,
} from 'models/interfaces/deviceInterface';
import { RowType } from 'models/types/commonTypes';

export const defaultDevice: IDevice = {
  PRC_NO: 0,
  DVC_NM: '',
  VOP_NM: '',
  DVC_CD: '',
  MMO_CD: '',
  MMO_NM: '',
  MSK_DVC_CD: '',
  RMK_CNT: '',
  ROWTYPE: RowType.ADD,
};

export const defaultSearchDevice: ISearchDevice = {
  PRJ_NO: 0,
  PRC_NO: 0,
  DVC_NO: 0,
  PRS_CD2: '',
  PRJ_NM: '',
};

export const defaultDeviceVop: IDeviceVop = {
  VOP_CD: '',
  RLS_FLAG: 'N',
  ROWTYPE: RowType.ADD,
};

export const defaultDeviceTEGDescription: IDeviceDoc[] = [
  {
    DIV: '마스크',
    NAME: 'Shot Map 파일 등록',
    CHECK: '0',
    TYPE: 'SHOT',
    DVC_NO: 1,
  },
  {
    DIV: '마스크',
    NAME: 'Die Map 파일 등록',
    CHECK: '0',
    TYPE: 'DIE',
    DVC_NO: 1,
  },
  {
    DIV: '코드',
    NAME: 'Code Layout 파일 등록',
    CHECK: '0',
    TYPE: 'LAYOUT',
    DVC_NO: 1,
  },
  {
    DIV: '코드',
    NAME: 'Module Array 파일 등록',
    CHECK: '0',
    TYPE: 'MODULE',
    DVC_NO: 1,
  },
  {
    DIV: '코드',
    NAME: 'PAD Assign 파일 등록',
    CHECK: '0',
    TYPE: 'PAD',
    DVC_NO: 1,
  },
  {
    DIV: '코드',
    NAME: '좌표 파일 등록',
    CHECK: '0',
    TYPE: 'SUBSITE',
    DVC_NO: 1,
  },
];

export const defaultDeviceAnalysis: IDeviceDoc[] = [
  {
    DIV: '마스크',
    NAME: 'Shot Map 파일 등록',
    CHECK: '0',
    TYPE: 'SHOT',
    DVC_NO: 1,
  },
  {
    DIV: '마스크',
    NAME: 'Die Map 파일 등록',
    CHECK: '0',
    TYPE: 'DIE',
    DVC_NO: 1,
  },
  {
    DIV: '코드',
    NAME: 'Code Layout 파일 등록',
    CHECK: '0',
    TYPE: 'LAYOUT',
    DVC_NO: 1,
  },
  {
    DIV: '코드',
    NAME: 'Module Array 파일 등록',
    CHECK: '0',
    TYPE: 'MODULE',
    DVC_NO: 1,
  },
  {
    DIV: '코드',
    NAME: 'PAD Assign 파일 등록',
    CHECK: '0',
    TYPE: 'PAD',
    DVC_NO: 1,
  },
  {
    DIV: '코드',
    NAME: '모듈 좌표 파일 등록',
    CHECK: '0',
    TYPE: 'MSUBSITE',
    DVC_NO: 1,
  },
  {
    DIV: '코드',
    NAME: 'DUT 좌표 파일 등록',
    CHECK: '0',
    TYPE: 'DSUBSITE',
    DVC_NO: 1,
  },
];
