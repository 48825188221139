import tw from 'twin.macro';
import styled from 'styled-components';

export const MenuTabContainer = styled.div`
  ${tw`
    flex
    w-full
    h-[30px]
    bg-blue-950
  `}
`;

export const MenuBox = styled.div`
  ${tw`
    relative
    flex
    flex-shrink-0
    border-t
    border-slate-500
    bg-blue-950
    transform
    duration-300
  `}
`;

export const MenuItem = styled.div`
  ${tw`
    w-[50%]
    flex
    items-center
    justify-center
    text-sm
    text-slate-300
    cursor-pointer
  `}

  &.left-menu:hover,
  &.left-active {
    background:
      linear-gradient(-135deg, transparent 10px, #2563ff 0) top right,
      linear-gradient(-45deg, transparent 10px, #2563ff 0) bottom right;
    background-size: 100% 51%;
    background-repeat: no-repeat;
    color: white;
  }
  &.right-menu:hover,
  &.right-active {
    background:
      linear-gradient(135deg, transparent 10px, #2563ff 0) top left,
      linear-gradient(45deg, transparent 10px, #2563ff 0) bottom left;
    background-size: 100% 51%;
    background-repeat: no-repeat;
    color: white;
  }
`;

export const TabBox = styled.div`
  ${tw`
    flex-grow
    flex
    justify-between
  `}

  .close-icon {
    ${tw`
      w-[20px]
      h-full
      text-white
      mr-1
      cursor-pointer
    `}
  }

  .fold-icon {
    ${tw`
      flex
      justify-center
      items-center
      p-1
      w-[30px]
      h-full
      text-blue-950
      flex-shrink-0
      bg-white
      cursor-pointer
    `}
    svg {
      ${tw`
        w-full
        h-full
        transform
        duration-300
      `}
    }
  }

  .side {
    ${tw`
    rounded-r-2xl
    `}
  }

  .top {
    ${tw`
    rounded-t-2xl
    `}
  }

  .tab-div {
    ${tw`
      flex-grow
      flex
      h-full
      ml-1
    `}
  }
`;

export const TabItem = styled.div<{ $isSelected: boolean }>`
  ${tw`
    flex
    items-center
    whitespace-nowrap
    bg-blue-600
    text-white
    text-sm
    cursor-pointer
    rounded-t-lg
    px-[1rem]
    mx-[1px]
    opacity-90
  `}

  &:hover {
    opacity: 1;
  }
  ${({ $isSelected }) =>
    $isSelected ? tw`bg-white text-blue-950 opacity-100` : undefined}
  span {
    margin-right: 0.5rem;
  }

  svg {
    ${tw`
      rounded-[50%]
      bg-blue-950
      text-white
    `}
  }
`;
