import { useQuery } from '@tanstack/react-query';
import { UseQueryOptionsWithoutQueryKey } from 'models';
import { client } from 'utils';
import { ISearchProject } from 'models/interfaces/projectInterface';

interface IDeviceListParam {
  searchData: ISearchProject;
  options: UseQueryOptionsWithoutQueryKey;
}

function device({ searchData, options }: IDeviceListParam) {
  return useQuery({
    queryKey: ['device', searchData],
    queryFn: async () => {
      const response = await client.get(
        '/api/teg/01/modal/project/device',
        {
          params: searchData,
        },
      );
      return response.data.deviceList;
    },
    ...options,
  });
}

export default function useDeviceListByProject(
  searchData: ISearchProject,
) {
  return device({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
