import { createColumnHelper } from '@tanstack/react-table';
import { usePopupMutation, useSearchPopupList } from 'apis';
import { Modal } from 'components';
import {
  DisplayButton,
  ReadOnlyTextCell,
  StatusCell,
  StatusIcon,
  Table,
} from 'components';
import { useTableData } from 'hooks';
import {
  Columns,
  CommonObject,
  CurrentData,
  IPopup,
  ITableContext,
  RowType,
  defaultPopup,
} from 'models';
import { useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { formManagementState } from 'store';
import { DataTableContainer } from 'styles';
import {
  POPUP_PARAM,
  TableContext,
  addRowData,
  exportJsonToSheet,
  removeRowData,
  saveTableData,
  duplicateData,
  selectNextDataByRemove,
} from 'utils';

const columnHelper = createColumnHelper();
const newRow = defaultPopup;

const COCM220M01Table = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const searchPopup = useRecoilValue(formManagementState.searchPopup);
  const savePopupList = usePopupMutation();
  const { data: searchPopupData } = useSearchPopupList(
    searchPopup,
  ) as CommonObject;
  const [currentData, setCurrentData] = useRecoilState(
    formManagementState.currentPopup,
  );
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: searchPopupData,
    atom: formManagementState.searchPopupList,
    effect: () => {
      setInitialData(searchPopupData);
      setCurrentData({ index: 0, data: searchPopupData[0] });
    },
  });

  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 25,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('SRCH_PPU_CD', {
      header: '검색팝업코드',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('PPU_NM', {
      header: '팝업명',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.display({
      header: '미리보기',
      size: 80,
      cell: DisplayButton,
      meta: {
        text: '미리보기',
        onClick: () => setOpenModal(true),
        disabled: (row: CommonObject) => row.ROWTYPE === RowType.ADD,
      },
    }),
    columnHelper.display({
      header: '복사',
      size: 60,
      cell: DisplayButton,
      meta: {
        text: '복사',
        onClick: (row: CommonObject) => duplicateData(setInitialData, row),
        disabled: (row: CommonObject) => row.ROWTYPE === RowType.ADD,
      },
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);

    setInitialData(removedData);
    selectNextDataByRemove(
      selectData,
      initialData.length - 1,
      removedData,
      setCurrentData,
    );
  };

  const handleSaveData = () => {
    const checkKeys = [
      'SRCH_PPU_CD',
      'PPU_NM',
      'PPU_CONT',
      'AT_INQ_YN',
      'SRCH_CND_ESN_YN',
      'GRID_CONT',
      'INQ_TGT_SNTX_CONT',
      'INQ_CND_SNTX_CONT',
      'SRCH_CMB_CONT',
    ];
    const targetData = saveTableData(initialData, checkKeys);

    if (targetData.length > 0) {
      savePopupList.mutate(targetData as IPopup[]);
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue: ITableContext = {
    handleExport: () =>
      exportJsonToSheet(columns, initialData, '팝업코드'),
    handleAddData: () =>
      addRowData(
        setInitialData,
        setCurrentData,
        newRow,
        initialData.length,
      ),
    handleRemoveData,
    handleSaveData,
    currentData,
    setCurrentData,
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        {initialData && (
          <Table
            title='검색팝업'
            initialData={initialData}
            setData={setInitialData}
            backupData={searchPopupData}
            columns={columns}
            editable={true}
            tableHeight={tableHeight}
          />
        )}
      </TableContext.Provider>
      {openModal && (
        <Modal
          onClose={() => setOpenModal(false)}
          param={{
            ...POPUP_PARAM,
            SRCH_PPU_CD: currentData.data.SRCH_PPU_CD,
          }}
        />
      )}
    </DataTableContainer>
  );
};

export default COCM220M01Table;
