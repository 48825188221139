import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { RecoilState, useRecoilState } from 'recoil';

interface ITableDataProps<T> {
  fetchData: any;
  atom?: RecoilState<T[]> | null;
  effect?: (() => void) | null;
}

type ITableDataResult<T> = [T, Dispatch<SetStateAction<T>>];
/**
 * react-query의 비동기 데이터를 매개변수로 전달받아 Table에 사용될 데이터에 주입하고,
 * callback 함수를 선택적으로 전달하여, 추가적인 작업이 가능토록하는 커스텀 훅
 *
 * atom를 지정하여 recoil state와 일반 state를 선택하여 적용 가능
 * 해당 커스텀 훅을 호출한 컴포넌트 언마운트 시 tableData 초기화
 * @param {any} fetchData 비동기 데이터
 * @param {RecoilState<unknown>} atom 적용할 recoil atom
 * @param {(() => void) | null} effect 사용자 정의 함수(default: null)
 * @example effect: () => {
      const newData = errorLotData.map(lot => ({
        ...lot,
        TIME: getDateTimeLocal(lot.TIME),
      }));

      setInitialData(newData);
      setCurrentErrorLot({ index: 0, data: newData[0] });
    },
 * @returns {array} 비동기 데이터가 주입된 테이블 데이터
 */
export default function useTableData<T>({
  fetchData,
  atom = null,
  effect = null,
}: ITableDataProps<T>): ITableDataResult<T[]> {
  const [tableData, setTableData] = atom
    ? useRecoilState(atom)
    : useState<T[]>([]);

  if (effect && typeof effect !== 'function') {
    throw new Error('effect는 함수로 전달되어야 합니다.');
  }

  useEffect(() => {
    if (fetchData) {
      const newFetchData = Array.isArray(fetchData)
        ? fetchData
        : [fetchData];
      if (!effect) setTableData(newFetchData);
      else effect();
    } else {
      setTableData([]);
    }
  }, [fetchData]);

  useEffect(() => {
    return () => {
      setTableData([]);
    };
  }, []);

  return [tableData, setTableData];
}
