import { IColumnMeta, ITableCellProps } from 'models';
import { memo } from 'react';
import { BiCheck, BiX } from 'react-icons/bi';
import { isEqual } from 'utils';

type Icons = { [key: string]: JSX.Element };

const icons: Icons = {
  Y: <BiCheck className='text-green-600' />,
  N: <BiX className='text-red-600' />,
};

const FileCell = ({ column, getValue }: ITableCellProps): JSX.Element => {
  const value = getValue();
  const columnMeta: IColumnMeta | undefined = column.columnDef.meta;
  const type = columnMeta?.type;

  let Icon: JSX.Element = <></>;

  if (type === 'FILE_FLAG') {
    Icon = icons[value] || <></>;
  } else if (type === 'ANS_FLAG') {
    Icon = icons[value] || <></>;
  }
  return (
    <>
      <div className='m-auto'>{Icon}</div>
    </>
  );
};

export default memo(FileCell, isEqual);
