import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IProjectWrapper } from 'models/interfaces/projectInterface';
import { toast } from 'react-toastify';
import { client, getAlertMessage } from 'utils';

function projectMutation(
  options: UseMutationOptions<unknown, Error, IProjectWrapper, unknown>,
) {
  return useMutation({
    mutationFn: async (projectWrapper: IProjectWrapper) => {
      const response = await client.post(
        '/api/teg/01/project',
        projectWrapper,
      );
      return response;
    },
    ...options,
  });
}

export default function useProjectMutation() {
  const queryClient = useQueryClient();

  return projectMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['project'],
      });
      toast.success(getAlertMessage('INFO_00010'));
    },
  });
}
