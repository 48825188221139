import { FrameContent } from 'styles';
import { CommonButton, FrameInfo } from 'components';
import FrameInfoSelect from 'components/frame/FrameInfoSelect';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
} from 'recoil';
import { moduleState, selectState } from 'store';
import { editValues, getAlertMessage } from 'utils';
import { useEffect, useState } from 'react';
import { CustomChangeEvent } from 'models';
import { useHeight, useSearchComboList } from 'hooks';
import TEG04ModuleTable from './TEG04ModuleTable';
import TEG04DesignTable from './TEG04DesignTable';
import TEG04DutTable from './TEG04DutTable';
import TEG04DescriptionModal from './TEG04DescriptionModal';
import TEG04DrawModal from './TEG04DrawModal';
import TEG04CoordinateModal from './TEG04CoordinateModal';
import { toast } from 'react-toastify';

const TEG04 = (): JSX.Element => {
  const getHeight = useHeight();
  const frameActive =
    useRecoilValue(selectState.selectSideMenu).PGM_ID === 'UIE004TEG04';
  const resetCurrentDesign = useResetRecoilState(
    moduleState.currentDesign,
  );
  const resetCurrentModule = useResetRecoilState(
    moduleState.moduleList('teg04'),
  );
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [drawModal, setDrawModal] = useState(false);
  const [coordinateModal, setCoordinateModal] = useState(false);
  const [searchModule, setSearchModule] = useRecoilState(
    moduleState.searchModule('teg04'),
  );
  const [values, setValues] = useState(searchModule);
  const projectOptions = useSearchComboList(
    { CMB_CD: frameActive ? 'CMB0029' : '' },
    true,
  );
  const processOptions = useSearchComboList(
    {
      CMB_CD: frameActive && values.PRJ_NO ? 'CMB0030' : '',
      SRCH_PARAM: String(values.PRJ_NO),
    },
    true,
  );
  const deviceOptions = useSearchComboList(
    {
      CMB_CD: frameActive && values.PRC_NO ? 'CMB0031' : '',
      SRCH_PARAM: String(values.PRC_NO),
    },
    true,
  );
  const editValue = (e: CustomChangeEvent, name?: string) => {
    editValues(e, setValues);
    if (name === 'PRJ_NO')
      setValues(prev => ({
        ...prev,
        PRC_NO: '',
        DVC_NO: '',
      }));
    if (name === 'PRC_NO')
      setValues(prev => ({
        ...prev,
        DVC_NO: '',
      }));
  };

  const handleSearchModule = () => {
    if (values.DVC_NO === '') {
      toast.warning(getAlertMessage('ERRO_00370', ['디바이스']));
      return;
    }
    setSearchModule(values);
  };

  useEffect(() => {
    const empty = Object.values(values).some(value => value === '');
    if (empty) {
      resetCurrentDesign();
      resetCurrentModule();
    }
    setSearchModule(values);
  }, [values]);
  useEffect(() => {
    if (frameActive) setValues(searchModule);
  }, [frameActive]);
  return (
    <>
      <FrameInfo>
        <div className='search-form'>
          <div className='input-block'>
            <div className='space-x-2'>
              <FrameInfoSelect
                title='프로젝트'
                options={projectOptions}
                name='PRJ_NO'
                value={values.PRJ_NO}
                onChange={e => editValue(e, 'PRJ_NO')}
              />
              <FrameInfoSelect
                title='공정'
                options={processOptions}
                name='PRC_NO'
                value={values.PRC_NO}
                onChange={e => editValue(e, 'PRC_NO')}
              />
              <FrameInfoSelect
                title='디바이스'
                options={deviceOptions}
                name='DVC_NO'
                value={values.DVC_NO}
                onChange={editValue}
              />
            </div>
          </div>
          <div>
            <CommonButton
              title='조회'
              onClick={handleSearchModule}
            />
          </div>
        </div>
      </FrameInfo>
      <FrameContent>
        <div className='w-[870px]'>
          <TEG04ModuleTable
            tableHeight={getHeight(650)}
            setDescriptionModal={setDescriptionModal}
            setDrawModal={setDrawModal}
            setCoordinateModal={setCoordinateModal}
          />
        </div>
        <div className='w-[440px]'>
          <TEG04DesignTable tableHeight={getHeight(650)} />
        </div>
        <div className='w-[300px]'>
          <TEG04DutTable tableHeight={getHeight(650)} />
        </div>
        {descriptionModal && (
          <TEG04DescriptionModal
            onClose={() => setDescriptionModal(false)}
          />
        )}
        {drawModal && (
          <TEG04DrawModal onClose={() => setDrawModal(false)} />
        )}
        {coordinateModal && (
          <TEG04CoordinateModal
            onClose={() => setCoordinateModal(false)}
          />
        )}
      </FrameContent>
    </>
  );
};

export default TEG04;
