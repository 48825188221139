import { useMutation } from '@tanstack/react-query';
import { IBbsFileDownload } from 'models/interfaces/bbsInterface';
import { client } from 'utils';

function downloadBbsFile() {
  return useMutation({
    mutationFn: async (searchFile: IBbsFileDownload) => {
      const response = await client.get(
        `/api/co/cm/800m01/file/download`,
        {
          params: searchFile,
          responseType: 'blob',
        },
      );
      return [response.data, searchFile.FILE_NM];
    },
    onSuccess: fileInfo => {
      const targetFile = Array.isArray(fileInfo[0])
        ? fileInfo[0]
        : [fileInfo[0]];
      const fileName = fileInfo[1];

      // downloadFile(targetFile, fileName);
      const file = new Blob(targetFile);
      const url = window.URL.createObjectURL(file);
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    },
  });
}

export default function useBownloadBbsFile() {
  return downloadBbsFile();
}
