import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchDocumentContent } from 'models';
import { client } from 'utils';

function chapterList({
  searchData,
  options,
}: IQueryParams<ISearchDocumentContent>) {
  return useQuery({
    queryKey: ['document', 'chapter', searchData],
    queryFn: async () => {
      const response = await client.get('/api/teg/09/doc/chapter', {
        params: searchData,
      });

      return response.data;
    },
    ...options,
  });
}

export default function useChapterList(
  searchData: ISearchDocumentContent,
) {
  return chapterList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: searchData.DOC_NO !== 0 && searchData.DOC_TYPE !== '',
    },
  });
}
