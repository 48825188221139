import { useQuery } from '@tanstack/react-query';
import { IQueryParams } from 'models';
import { ISearchPrivateDirectory } from 'models/interfaces/privateBbsInterface';
import { client } from 'utils';

function privateDirectoryList({
  searchData,
  options,
}: IQueryParams<ISearchPrivateDirectory>) {
  return useQuery({
    queryKey: ['dir_list', searchData],
    queryFn: async () => {
      const response = await client.get('api/co/cm/900m01/directory', {
        params: searchData,
      });
      return response.data.directoryList;
    },
    ...options,
  });
}

export default function usePrivateDirectoryList(
  searchData: ISearchPrivateDirectory,
) {
  return privateDirectoryList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
