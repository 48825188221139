import { ITableCellProps } from 'models';
import { memo } from 'react';
import {
  BiSolidAddToQueue,
  BiSolidEditAlt,
  BiSolidMinusSquare,
} from 'react-icons/bi';
import { isEqual } from 'utils';

type Icons = { [key: number]: JSX.Element };

const icons: Icons = {
  1: <BiSolidAddToQueue className='text-green-600' />,
  2: <BiSolidEditAlt className='text-blue-600' />,
  3: <BiSolidMinusSquare className='text-red-600' />,
};

const StatusCell = ({ row }: ITableCellProps): JSX.Element => {
  const { original } = row;
  const Icon = () => icons[original.ROWTYPE];
  return (
    <>
      <div className='m-auto'>
        <Icon />
      </div>
    </>
  );
};

export default memo(StatusCell, isEqual);
