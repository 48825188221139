import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { throttle } from 'lodash';
import { CommonObject } from 'models';
import { useCallback, useMemo } from 'react';

export default function useInfiniteScroll<T>(
  infiniteQuery: (
    searchData: T,
  ) => UseInfiniteQueryResult<CommonObject, Error>,
  param: T,
  timer: number,
) {
  const { data, fetchNextPage, hasNextPage, isFetching } =
    infiniteQuery(param);

  const flatData = useMemo(
    () => data?.pages?.flatMap((page: CommonObject) => page) ?? [],
    [data],
  );

  const fetchMoreOnBottomReached = useCallback(
    throttle((containerRefElement: HTMLDivElement) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } =
          containerRefElement;
        if (
          scrollHeight - scrollTop - clientHeight < 300 &&
          !isFetching &&
          hasNextPage
        ) {
          fetchNextPage();
        }
      }
    }, timer),
    [fetchNextPage, isFetching],
  );

  return [flatData, fetchMoreOnBottomReached];
}
