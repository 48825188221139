import { useEffect, useState } from 'react';

import {
  CommonButton,
  FrameInfo,
  FrameInfoInput,
  FrameInfoSelect,
} from 'components';
import { CustomChangeEvent } from 'models';
import { useRecoilState } from 'recoil';
import { bbsState } from 'store/bbsAtoms';
import { FrameContent } from 'styles';
import COCM800M01BbsTable from './COCM800M01BbsTable';
import { useHeight } from 'hooks';
import { ISearchBbs } from 'models/interfaces/bbsInterface';

const COCM800M01 = (): JSX.Element => {
  const bbsSearchOptions = [
    { label: '제목', value: 'BBS_NM' },
    { label: '작성자', value: 'DWUR_ID' },
    { label: '작성일', value: 'DWUR_DATE' },
  ];
  const getHeight = useHeight();
  const [searchBbs, setSeearchBbs] = useRecoilState(
    bbsState.searchBbs('COCM800M01'),
  );
  const [values, setValues] = useState<ISearchBbs>(searchBbs);

  const setCustomValues = (tagName: string, value: string) => {
    if (tagName === 'SELECT') {
      setValues({
        BBS_GUBUN: value,
        BBS_SEARCH: '',
      });
    } else if (tagName === 'INPUT') {
      setValues(old => ({
        ...old,
        BBS_SEARCH: value,
      }));
    }
  };

  const editValue = (e: CustomChangeEvent) => {
    const target = e.target as HTMLInputElement | HTMLSelectElement; // HTMLInputElement와 HTMLSelectElement 타입 중 하나로 타입 단언
    setCustomValues(target.tagName, target.value);
  };
  const handleSearchType = () => {
    setSeearchBbs(values);
  };
  useEffect(() => {
    setCustomValues('SELECT', bbsSearchOptions[0].value);
  }, []);

  return (
    <>
      <FrameInfo>
        <div className='search-form'>
          <div className='input-block'>
            <FrameInfoSelect
              title='조회구분'
              options={bbsSearchOptions}
              onChange={editValue}
            />
            <FrameInfoInput
              title=''
              type={values.BBS_GUBUN === 'DWUR_DATE' ? 'date' : 'text'}
              name='BBS_SEARCH'
              value={values.BBS_SEARCH || ''}
              onChange={editValue}
            />
          </div>
        </div>
        <div>
          <CommonButton
            title='조회'
            onClick={handleSearchType}
          />
        </div>
      </FrameInfo>
      <FrameContent>
        <div className='grow-col-between'>
          <COCM800M01BbsTable tableHeight={getHeight(650)} />
        </div>
      </FrameContent>
    </>
  );
};

export default COCM800M01;
