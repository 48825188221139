import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { TableContext, addRowNumber, customEqual } from 'utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTableData } from 'hooks';
import {
  DisplayButton,
  ExpressionCell,
  ReadOnlyTextCell,
  StatusCell,
  StatusIcon,
  Table,
  TextCell,
} from 'components';
import { DataTableContainer } from 'styles';
import {
  Columns,
  ITableContext,
  CommonObject,
  defaultCurrentData,
  CommonArray,
  IDescriptionCheck,
  RowType,
  IModuleDescription,
} from 'models';
import { descriptionState } from 'store';
import {
  useCommonFileDownloadMutation,
  useDescriptionTeg,
  useFileCheck,
  useModuleDescriptionList,
  useModuleDescriptionMutation,
} from 'apis';
import TEG05MoveHeader from './TEG05MoveHeader';
import TEG05MoveModal from './TEG05MoveModal';
import { toast } from 'react-toastify';

const columnHelper = createColumnHelper();

const TEG05DescriptionTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchData = useRecoilValue(
    descriptionState.searchDescription('teg05'),
  );
  const { data: descriptionData } = useModuleDescriptionList(
    searchData,
  ) as CommonObject;
  const descriptionMutation = useModuleDescriptionMutation();
  const [openModal, setOpenModal] = useState(false);

  const CheckHeader = ({ name }: { name: string }) => {
    const id = name.substring(1);
    const value = String(checkList?.[0]?.[`CHECK${id}`]);
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (value === '2') {
        toast.warning('MOVE 취소 후 릴리즈 가능합니다.');
        return;
      }
      const checkValue = e.target.checked ? 1 : null;
      setCheckList((prev: IDescriptionCheck[]) => [
        {
          ...prev[0],
          [`CHECK${id}`]: checkValue,
        },
      ]);
    };

    return (
      <>
        <input
          type='checkbox'
          checked={value === '1'}
          onChange={onChange}
          name='USE_YN'
        />
        {name}
      </>
    );
  };

  const copyStandardValue = (data: CommonObject) => {
    setInitialData(currentData => {
      const index = currentData.findIndex(
        item => item.DSC_ID === data.DSC_ID,
      );
      if (index === -1) return currentData;

      const updatedItem = { ...currentData[index] };
      for (let i = 1; i <= 12; i++) {
        const columnNumber = i.toString().padStart(2, '0');
        if (checkList[0]?.[`CHECK${columnNumber}`] === 2) {
          continue;
        }
        const key = `D${columnNumber}`;
        updatedItem[key] = data.USR_FLD_1_CONT;
      }
      updatedItem.ROWTYPE = RowType.UPDATE;
      const newData = [...currentData];
      newData[index] = updatedItem;
      return newData;
    });
  };

  const [backupData, setBackupData] = useState<CommonArray>([]);
  const [vopColumns, setVopColumns] = useState([]);
  const [checkList, setCheckList] = useState<IDescriptionCheck[]>([]);
  useEffect(() => {
    const empty = Object.values(searchData).some(value => value === '');
    if (empty) setCheckList([]);
  }, [searchData]);
  const [moveList, setMoveList] = useRecoilState(
    descriptionState.descriptionMoveList,
  );
  useEffect(() => {
    if (!checkList || checkList.length === 0) {
      return;
    }
    const checkKeys = Object.keys(checkList[0]).filter(
      key => /^CHECK[0-9]{2}$/.test(key) && checkList[0][key] === 1,
    );
    const updatedData = initialData.map(row => {
      const newRow = { ...row };
      checkKeys.forEach(checkKey => {
        const columnId = `D${checkKey.substring(5)}`;
        newRow[columnId] = newRow['USR_FLD_1_CONT'];
        if (newRow[columnId] !== row[columnId]) {
          newRow['ROWTYPE'] = RowType.UPDATE;
        }
      });
      return newRow;
    });
    setInitialData(updatedData);
  }, [checkList]);

  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: descriptionData?.descriptionList,
    effect: () => {
      const newData = addRowNumber(descriptionData?.descriptionList);
      setInitialData(newData);
      setBackupData(newData);
      setCurrentData({ index: 0, data: newData[0] });
      setVopColumns(descriptionData.vopColumns);
      setCheckList(descriptionData.checkList);
      setMoveList(descriptionData.moveList);
    },
  });
  const [searchDescriptionTeg, setSearchDescriptionTeg] = useState({
    PRJ_NO: '',
    DVC_NO: '',
    MDL_ID: '',
    FLAG: true,
  });
  const [fileCheck, setFileCheck] = useState({ filePath: '', FLAG: true });

  const [currentData, setCurrentData] = useState(defaultCurrentData);

  const initialDataRef = useRef(initialData);
  useEffect(() => {
    if (initialDataRef.current.length === 0) {
      initialDataRef.current = initialData;
      return;
    }
    const foundRow = initialData.find((row, index) => {
      const prevRow = initialDataRef.current[index];
      if (
        row?.DVC_NO !== prevRow?.DVC_NO ||
        row?.MDL_ID !== prevRow?.MDL_ID
      )
        return false;
      if (row !== prevRow && row.VAL_EXPR === 'x') {
        const gdrMin = parseFloat(row.GDR_MIN);
        for (let i = 1; i <= 12; i++) {
          const key = `D${i.toString().padStart(2, '0')}`;
          if (row[key] === null) return false;
          if (row[key] !== prevRow[key] && parseFloat(row[key]) < gdrMin) {
            return true;
          }
        }
        return false;
      }
    });
    if (foundRow !== undefined) {
      toast.warning('최솟값 보다 작습니다!');
    }
    initialDataRef.current = initialData;
  }, [initialData]);

  const generateDutColumns = (count: number) => {
    const columns = [];
    for (let i = 1; i <= count; i++) {
      const paddedIndex = i.toString().padStart(2, '0');
      columns.push(
        columnHelper.accessor(`D${paddedIndex}`, {
          header: () => <CheckHeader name={`D${paddedIndex}`} />,
          cell:
            checkList[0]?.[`CHECK${paddedIndex}`] !== 1 &&
            checkList[0]?.[`CHECK${paddedIndex}`] !== 2
              ? ExpressionCell
              : ReadOnlyTextCell,
          filterFn: 'includesString',
          size: 36,
          meta: {
            justify: 'end',
          },
        }),
      );
    }
    return columns;
  };

  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 20,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('no', {
      header: 'No',
      cell: ReadOnlyTextCell,
      size: 20,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.group({
      header: 'GDR',
      columns: [
        columnHelper.accessor('GDR_MIN', {
          header: '최소',
          cell: ReadOnlyTextCell,
          enableColumnFilter: false,
          size: 25,
          meta: {
            justify: 'center',
          },
        }) as ColumnDef<unknown, any>,
        columnHelper.accessor('GDR_DFT', {
          header: '기본',
          cell: ReadOnlyTextCell,
          enableColumnFilter: false,
          size: 25,
          meta: {
            justify: 'center',
          },
        }) as ColumnDef<unknown, any>,
      ],
    }),
    ...vopColumns,
    columnHelper.display({
      header: '행 전체 STD',
      size: 35,
      cell: DisplayButton,
      meta: {
        text: '복사',
        onClick: (row: CommonObject) => copyStandardValue(row),
      },
    }),
    columnHelper.accessor('DSC_NM', {
      header: '속성명',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 150,
    }),
    columnHelper.accessor('SYM', {
      header: '심벌',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 25,
      meta: {
        justify: 'center',
      },
    }),
    ...generateDutColumns(12),
    columnHelper.accessor('RMK_CNT', {
      header: '비고',
      cell: TextCell,
      filterFn: 'includesString',
      size: 80,
    }),
  ];
  const columns = useMemo(
    () => defaultColumns,
    [vopColumns, checkList, moveList],
  ) as Columns;

  useEffect(() => {
    if (descriptionData?.moveList) setMoveList(descriptionData?.moveList);
  }, [descriptionData?.moveList]);

  const handleSaveData = () => {
    //전체 삭제후 다시 저장
    const updatedInitialData = initialData.map(item => ({
      ...item,
      MDL_ID: item.MDL_ID === null ? searchData.MDL_ID : item.MDL_ID,
    }));
    const saveData = {
      PRJ_NO: String(searchData.PRJ_NO),
      descriptionList: updatedInitialData as IModuleDescription[],
      dutCheck: checkList[0],
    };
    console.log('속성 저장 : ', saveData);
    if (initialData.length > 0) {
      descriptionMutation.mutate(saveData);
    } else {
      setInitialData(initialData);
    }
  };

  const getExprResult = (tableData: CommonObject[], row: CommonObject) => {
    const regex = new RegExp(row.DVC_TYPE + '\\d{2}', 'g');
    const expr = row.VAL_EXPR;
    const codes: string[] = expr.match(regex) || [];
    const newRow = { ...row };
    for (let i = 1; i <= 12; i++) {
      const key = `D${i.toString().padStart(2, '0')}`;
      const valuesMap: { [key: string]: number } = {};
      codes.forEach(code => {
        tableData.forEach(paramRow => {
          if (paramRow.DSC_CD === code)
            valuesMap[code] = Number(paramRow[key]);
        });
      });
      let calculatedExpr = expr;
      for (const code in valuesMap) {
        calculatedExpr = calculatedExpr.replace(
          new RegExp(code, 'g'),
          valuesMap[code].toString(),
        );
      }
      try {
        const func = new Function(`return ${calculatedExpr}`);
        newRow[key] = String(func());
      } catch (error) {
        newRow[key] = '';
      }
    }

    if (!customEqual(row, newRow))
      return {
        ...newRow,
        ROWTYPE: RowType.UPDATE,
      };
    return row;
  };

  const useCalculateNewData = (data: CommonObject[]) => {
    return useMemo(() => {
      return data.map(row => {
        if (row.VAL_EXPR && row.VAL_EXPR !== 'x') {
          return getExprResult(data, row);
        }
        return row;
      });
    }, [data]);
  };

  const newData = useCalculateNewData(initialData);

  useEffect(() => {
    const isDifferent =
      JSON.stringify(initialData) !== JSON.stringify(newData);
    if (isDifferent) {
      setInitialData(newData);
    }
  }, [newData]);

  const handleResetData = () => {
    const updatedData = initialData.map(row => {
      const newRow = { ...row };
      let changed = false;
      for (let i = 1; i <= 12; i++) {
        const columnNumber = i.toString().padStart(2, '0');
        const columnId = `D${columnNumber}`;
        if (checkList[0]?.[`CHECK${columnNumber}`] !== null) {
          continue;
        }
        if (changed === false && newRow[columnId] !== null) {
          changed = true;
        }
        newRow[columnId] = null;
      }
      if (changed) {
        newRow['ROWTYPE'] = RowType.UPDATE;
      }
      return newRow;
    });
    setInitialData(updatedData);
  };

  const handleTegDoc = () => {
    setSearchDescriptionTeg(prev => ({
      PRJ_NO: String(searchData.PRJ_NO),
      DVC_NO: String(searchData.DVC_NO),
      MDL_ID: String(searchData.MDL_ID),
      FLAG: !prev.FLAG,
    }));
  };
  const tegResponse = useDescriptionTeg(
    searchDescriptionTeg,
  ) as CommonObject;
  useEffect(() => {
    if (tegResponse?.data?.FLAG === undefined) return;
    if (tegResponse?.data?.status === 200) {
      const prjNo = searchDescriptionTeg.PRJ_NO.padStart(9, '0');
      const dvcNo = searchDescriptionTeg.DVC_NO.padStart(9, '0');
      const mdlId = searchDescriptionTeg.MDL_ID.padStart(2, '0');
      setFileCheck({
        filePath: `/PRJ/${prjNo}/${dvcNo}/M${mdlId}_TEGDescription.xlsx`,
        FLAG: tegResponse.data.FLAG,
      });
    }
  }, [tegResponse?.data?.FLAG]);
  const checkResponse = useFileCheck(fileCheck.filePath, fileCheck.FLAG);
  const downloadFile = useCommonFileDownloadMutation();
  const fileDownload = () => {
    downloadFile.mutate({ URL: fileCheck.filePath });
  };
  useEffect(() => {
    if (checkResponse?.data !== undefined) {
      fileDownload();
    }
  }, [checkResponse?.data]);

  const contextValue: ITableContext = {
    handleSaveData,
    handleResetData,
    handleTegDoc,
    handleCellColor: (row: CommonObject, columnId: string) => {
      if (!/^D[0-9]{2}$/.test(columnId)) {
        return '';
      }
      if (!row[columnId]) {
        return '';
      }
      const check = `CHECK${columnId.substring(1)}`;

      if (checkList?.[0]?.[check] === 1) {
        return 'bg-yellow-300';
      }
      return row[columnId] !== row?.['USR_FLD_1_CONT']
        ? 'bg-green-400'
        : '';
    },
    currentData,
    setCurrentData,
    customDisabled: {
      fetched:
        searchData.PRJ_NO === '' ||
        searchData.PRC_NO === '' ||
        searchData.DVC_NO === '' ||
        searchData.MDL_ID === '',
    },
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        {initialData && (
          <Table
            title='속성'
            initialData={initialData}
            backupData={backupData}
            setData={setInitialData}
            columns={columns}
            editable={true}
            showToggle={false}
            tableHeight={tableHeight}
            customHeader={(headerGroups: any[]) => (
              <TEG05MoveHeader
                headerGroups={headerGroups}
                move={moveList} // 셀렉트한 move데이터 매핑자리
                setOpenModal={setOpenModal}
                checkList={checkList[0]}
              />
            )}
          />
        )}
      </TableContext.Provider>
      {openModal && <TEG05MoveModal onClose={() => setOpenModal(false)} />}
    </DataTableContainer>
  );
};

export default TEG05DescriptionTable;
