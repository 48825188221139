import {
  FrameEditBox,
  FrameEditField,
  FrameEditInput,
  Modal,
  ModalButton,
} from 'components';
import { CHAPTER_PARAM, CommonObject, RowType } from 'models';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { documentState } from 'store';
import { EditFieldRow } from 'styles';
import { saveFormData } from 'utils';

const TEG10ChapterForm = (): JSX.Element => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const setChapterList = useSetRecoilState(documentState.chapterList);
  const currentChapter = useRecoilValue(
    documentState.currentDocumentContent,
  );
  const [values, setValues] = useState(currentChapter.data);
  const selectValueByModal = (selectValue: CommonObject) => {
    setValues(old => ({
      ...old,
      CH_NO: selectValue.CHR_ID,
      CH_NM: selectValue.CHR_NM,
    }));
    setOpenModal(false);
  };

  const handleSaveFormData = () =>
    saveFormData({
      formData: values,
      dataIndex: currentChapter.index,
      setTableData: setChapterList,
      compareKey: 'CH_NM',
    });

  useEffect(() => {
    if (currentChapter.data) {
      setValues(currentChapter.data);
    }
  }, [currentChapter]);

  return (
    <FrameEditBox
      title='챕터 정보'
      onClick={handleSaveFormData}
    >
      <EditFieldRow className='border-t'>
        <FrameEditField title='챕터코드'>
          <FrameEditInput
            type='text'
            name='CH_NO'
            value={values.CH_NO || ''}
            readOnly
            required
          />
          <ModalButton
            openModal={() => setOpenModal(true)}
            disabled={currentChapter?.data?.ROWTYPE !== RowType.ADD}
          />
        </FrameEditField>
        <FrameEditField title='챕터명'>
          <FrameEditInput
            type='text'
            name='CH_NM'
            value={values.CH_NM || ''}
            readOnly
            required
          />
        </FrameEditField>
        <FrameEditField title='상위챕터'>
          <FrameEditInput
            type='text'
            name='HRK_CH_NO'
            value={values.HRK_CH_NO || ''}
            readOnly
          />
        </FrameEditField>
      </EditFieldRow>
      {openModal && (
        <Modal
          onClose={() => setOpenModal(false)}
          param={{
            ...CHAPTER_PARAM,
            SRCH_PARAM: currentChapter.data.HRK_CH_NO,
          }}
          handleSelect={selectValueByModal}
        />
      )}
    </FrameEditBox>
  );
};

export default TEG10ChapterForm;
