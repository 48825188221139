import { useRecoilValue, useSetRecoilState } from 'recoil';
import { menuState, selectState } from 'store';

/**
 * 의존성을 가진 프레임으로 이동하기 위해 sessionStorage에 데이터를 세팅하는 훅
 * @returns {function} 프레임 아이디와 전달할 데이터를 세션스토리지와 recoil에 세팅
 */
const useMoveTeg = () => {
  const sideMenu = useRecoilValue(menuState.sideMenu);
  const setTargetMenu = useSetRecoilState(selectState.selectTargetMenu);

  const moveTeg = (frameId: string) => {
    const targetMenu = sideMenu.find(menu => menu.PGM_ID === frameId);

    if (targetMenu) {
      setTargetMenu({ value: targetMenu, type: 'button' });
    }
  };

  return moveTeg;
};

export default useMoveTeg;
