import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IRuleDescription } from 'models';
import { client } from 'utils';

function ruleDescriptionMutation(
  options: UseMutationOptions<unknown, Error, IRuleDescription[], unknown>,
) {
  return useMutation({
    mutationFn: async (descriptionList: IRuleDescription[]) => {
      console.log(descriptionList);
      const response = await client.post(
        '/api/teg/09/doc/rule/rule',
        descriptionList,
      );
      return response;
    },
    ...options,
  });
}

export default function useRuleDescriptionMutation() {
  const queryClient = useQueryClient();

  return ruleDescriptionMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['document', 'chapter', 'description'],
      });
      queryClient.invalidateQueries({
        queryKey: ['document', 'design_rule', 'layer'],
      });
    },
  });
}
