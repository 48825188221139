import { useQuery } from '@tanstack/react-query';
import { UseQueryOptionsWithoutQueryKey } from 'models';
import { client } from 'utils';

interface IPopupInfoParam {
  popupCode: { SRCH_PPU_CD: string };
  options: UseQueryOptionsWithoutQueryKey;
}

function popupInfo({ popupCode, options }: IPopupInfoParam) {
  return useQuery({
    queryKey: ['common', 'popupInfo', popupCode],
    queryFn: async () => {
      const response = await client.get('/api/co/cm/popup/info', {
        params: popupCode,
      });

      const popupInfo = response.data.popupInfo;
      const jsonComboArray = popupInfo.SRCH_CMB_CONT.split(
        '\n',
      ) as string[];
      const jsonColumnArray = popupInfo.GRID_CONT.split('\n') as string[];
      const columns = jsonColumnArray.map(json => JSON.parse(json));
      const comboList = jsonComboArray.map(json => JSON.parse(json));

      return { columns, comboList };
    },
    ...options,
  });
}

export default function usePopupInfo(popup: string) {
  const popupCode = {
    SRCH_PPU_CD: popup,
  };

  return popupInfo({
    popupCode: popupCode,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
