// layout size
export const TARGET_WIDTH = 220;
export const TARGET_HEIGHT = 60;
export const MENUTAB_HEIGHT = 30;
export const SIDEMENU_PADDING = 10;
export const FRAME_WIDTH = 1700;
export const SUBPLOT_HEIGHT = 750;
export const SUBPLOT_WIDTH = 1100;
export const GUIDE_WIDTH = 1200;
export const GUIDE_HEIGHT = 800;

// Table property
export const ROWHEIGHT = 35;
export const STS_FLAG = {
  C: '정상완료',
  E: '에러',
  R: '진행중',
};
export const TAB_RESULT = {
  OK: '#38761d',
  GOOD: '#dda700',
  BAD: '#ed0505',
};
export const TOOL_MATCHING = {
  OK: '#00ff0000',
  GOOD: '#f7df86',
  BAD: '#fc140c',
};

// query property
export const FETCHSIZE = 100;

// menu
export const HEADER_LEVEL = 1;
export const TITLE_LEVEL = 2;

// excel property
export const CELL_BG_COLOR = 'C8F0E1';
export const CELL_BORDER_COLOR = '080808';
export const BORDER_ALL = {
  border: {
    top: { style: 'thin', color: { rgb: CELL_BORDER_COLOR } },
    right: { style: 'thin', color: { rgb: CELL_BORDER_COLOR } },
    bottom: { style: 'thin', color: { rgb: CELL_BORDER_COLOR } },
    left: { style: 'thin', color: { rgb: CELL_BORDER_COLOR } },
  },
};

// grafana property
export const HOUR = 32400000;
export const PANEL_PERIOD = [
  { value: 'now-1h', label: '1시간' },
  { value: 'now-3h', label: '3시간' },
  { value: 'now-6h', label: '6시간' },
  { value: 'now-12h', label: '12시간' },
  { value: 'now-24h', label: '24시간' },
];
export const PANEL_INTERVAL = [
  { value: '5s', label: '5초' },
  { value: '10s', label: '10초' },
  { value: '30s', label: '30초' },
  { value: '1m', label: '1분' },
];

// edit property
export const USE_YN = [
  { label: 'Y', value: 'Y' },
  { label: 'N', value: 'N' },
];
export const USER_FIELD_COUNT = 4;

// popup property
export const POPUP_PARAM = {
  SRCH_VALUE: '',
  SRCH_PPU_CD: '',
  SRCH_PARAM: '',
  SRCH_COL_IDX: '0',
  SRCH_USE_YN: 'Y',
  ROWTYPE: '0',
};

export const DOCUMENT_TYPE = {
  REFERENCE_GUIDE: '02',
  DESIGN_ROLE: '01',
};
