import { IFrameEditFieldProps } from 'models';
import { BiCaretLeft } from 'react-icons/bi';
import { EditFieldItem } from 'styles/frameStyles';

const FrameEditField = ({
  title,
  width,
  required,
  children,
}: IFrameEditFieldProps) => {
  return (
    <EditFieldItem $width={width}>
      <div className='key-name'>
        {required ? <BiCaretLeft className='required-icon' /> : null}
        {title}
      </div>
      <div className='field-box'>{children ?? null}</div>
    </EditFieldItem>
  );
};

export default FrameEditField;
