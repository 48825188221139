import useCellValue from 'hooks/useCellValue';
import {
  CustomChangeEvent,
  IColumnMeta,
  ITableCellProps,
  ITableMeta,
} from 'models';
import { DataTableSelect } from 'styles';
import { memo } from 'react';

const isEqual = () => {
  return false;
};

const SelectCell = ({
  getValue,
  table,
  row,
  column,
  rowRef,
}: ITableCellProps): JSX.Element => {
  const value = getValue();
  const tableMeta: ITableMeta | undefined = table.options.meta;
  const [selectValue, setSelectValue] = useCellValue(
    value,
    tableMeta,
    rowRef,
  );
  const columnMeta: IColumnMeta | undefined = column.columnDef.meta;
  const getDisabled = () => {
    if (typeof columnMeta?.disabled === 'function') {
      return columnMeta.disabled(row.original);
    } else {
      return columnMeta?.disabled;
    }
  };
  const disabled = columnMeta?.disabled ? getDisabled() : false;
  const options = columnMeta?.options;
  const optionFn = columnMeta?.optionFn;
  const renderOptions = optionFn ? optionFn(row.original) : options;

  const onChange = (e: CustomChangeEvent) => {
    const selectedOption = renderOptions?.find(
      option => option.value.toString() === e.target.value,
    );
    setSelectValue(row.index, e, typeof selectedOption.value);
  };

  return (
    <DataTableSelect
      value={selectValue ?? ''}
      name={column.id}
      onChange={onChange}
      required={columnMeta?.required ?? false}
      disabled={disabled}
    >
      {renderOptions?.map((option, index) => (
        <option
          key={option.value + index}
          value={option.value}
        >
          {option.label}
        </option>
      ))}
    </DataTableSelect>
  );
};

export default memo(SelectCell, isEqual);
