import LoginError from 'features/login/LoginError';
import LoginField from 'features/login/LoginField';
import { IHeaderUserPasswordFieldProps } from 'models';
import { UserFieldBox } from 'styles';

const HeaderUserPasswordField = ({
  name,
  placeholder,
}: IHeaderUserPasswordFieldProps): JSX.Element => {
  return (
    <div>
      <UserFieldBox>
        <LoginField
          type='password'
          name={name}
          placeholder={placeholder}
        />
      </UserFieldBox>
      <LoginError
        name={name}
        nospace
      />
    </div>
  );
};

export default HeaderUserPasswordField;
