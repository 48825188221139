import { CommonArray, defaultCurrentData } from 'models';
import {
  defaultBbsDetail,
  defaultFileDownload,
  defaultSearchBbs,
  defaultSearchBbsDetail,
} from 'models/defaults/bbsDefaults';
import { atom, atomFamily } from 'recoil';

const searchBbs = atomFamily({
  key: 'searchBbs',
  default: defaultSearchBbs,
});

const bbsList = atom<CommonArray>({
  key: 'bbs',
  default: [],
});

const currentBbs = atomFamily({
  key: 'currentBbs',
  default: defaultCurrentData,
});

const searchBbsDetail = atomFamily({
  key: 'searchBbsDetail',
  default: defaultSearchBbsDetail,
});

const bbsFileList = atom<CommonArray>({
  key: 'bbsFileList',
  default: [],
});

const bbsDownloadFile = atom({
  key: 'fileDownload',
  default: defaultFileDownload,
});

const bbsDetail = atom({
  key: 'bbsDetail',
  default: defaultBbsDetail,
});

export const bbsState = {
  searchBbs,
  bbsList,
  currentBbs,
  searchBbsDetail,
  bbsFileList,
  bbsDownloadFile,
  bbsDetail,
};
