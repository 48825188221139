import { memo } from 'react';
import { isEqual } from 'utils';
import useCellValue from 'hooks/useCellValue';
import {
  CustomChangeEvent,
  IColumnMeta,
  ITableCellProps,
  ITableMeta,
} from 'models';
import { DataTableCheckbox } from 'styles';

const CheckInputCell = ({
  getValue,
  table,
  row,
  column,
  rowRef,
}: ITableCellProps): JSX.Element => {
  const value = getValue();
  const tableMeta: ITableMeta | undefined = table.options.meta;
  const [checkValue, setCheckValue] = useCellValue(
    value,
    tableMeta,
    rowRef,
  );
  const columnMeta: IColumnMeta | undefined = column.columnDef.meta;

  const onChange = (e: CustomChangeEvent) => {
    if (columnMeta?.onChange) {
      const flag = columnMeta.onChange(row.original);
      if (flag === false) {
        return;
      }
    }
    setCheckValue(row.index, e);
  };

  return (
    <DataTableCheckbox
      type='checkbox'
      name={column.id}
      checked={checkValue === 'Y' || checkValue === '1'}
      onChange={onChange}
      onMouseDown={e => e.stopPropagation()}
    />
  );
};

export default memo(CheckInputCell, isEqual);
