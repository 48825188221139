import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { IUserInfo } from 'models';
import { useSetRecoilState } from 'recoil';
import { loginState } from 'store';
import { toast } from 'react-toastify';
import { Dispatch, SetStateAction } from 'react';

interface LoginResponse {
  accessToken: string;
  userInfo: IUserInfo;
  expTime: number;
}

interface ErrorResponse {
  message: string | null | undefined;
}

export function renewMutation(
  options: UseMutationOptions<
    LoginResponse,
    AxiosError<ErrorResponse>,
    unknown,
    unknown
  >,
) {
  return useMutation({
    mutationFn: async user => {
      const response = await axios.post('/api/renew', user);
      return response.data;
    },
    ...options,
  });
}

export default function useRenewMutation(
  setOpenRenew: Dispatch<SetStateAction<boolean>>,
) {
  const setExpTime = useSetRecoilState(loginState.expTime);

  return renewMutation({
    onSuccess: (data: LoginResponse) => {
      const accessToken = data.accessToken;
      const expTime = Math.round(data.expTime / 60);

      setOpenRenew(false);
      sessionStorage.setItem('accessToken', accessToken);
      setExpTime(expTime);
      toast.success('갱신에 성공하였습니다.');
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      const message = error.response?.data.message;
      if (message) toast.error(message);
    },
  });
}
