import { useQuery } from '@tanstack/react-query';
import { IComboCode, IQueryParams } from 'models';
import { client } from 'utils';

function comboList({ searchData, options }: IQueryParams<IComboCode>) {
  return useQuery({
    queryKey: ['common', 'combo', searchData],
    queryFn: async () => {
      const response = await client.get('/api/co/cm/430m01/combo/option', {
        params: searchData,
      });
      return response.data.comboList || [];
    },
    ...options,
  });
}

export default function useComboList(combo: IComboCode) {
  return comboList({
    searchData: combo,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: combo.CMB_CD !== '',
    },
  });
}
