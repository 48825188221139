import { useQuery } from '@tanstack/react-query';
import { UseQueryOptionsWithoutQueryKey } from 'models';
import { client } from 'utils';
import { ISearchSub } from 'models/interfaces/maskInterface';

interface ISubListParam {
  searchData: ISearchSub;
  options: UseQueryOptionsWithoutQueryKey;
}

function sub({ searchData, options }: ISubListParam) {
  return useQuery({
    queryKey: ['sub', 'subList', 'maskSubsite', searchData],
    queryFn: async () => {
      const response = await client.get('/api/teg/02/dut/sub', {
        params: searchData,
      });
      const dutSubList = response.data.dutSubList;
      const xKeys = Array.from(
        { length: 12 },
        (_, i) => `CRD_X_${String(i + 1).padStart(2, '0')}`,
      );
      const yKeys = Array.from(
        { length: 12 },
        (_, i) => `CRD_Y_${String(i + 1).padStart(2, '0')}`,
      );
      const keys = [...xKeys, ...yKeys];

      dutSubList.map((dut: any) => {
        keys.forEach(key => {
          if (!(key in dut)) {
            dut[key] = 0;
          }
        });
        return dut;
      });

      const maskDeviceList = response.data.maskDeviceList;
      return {
        dutSubList,
        maskDeviceList,
      };
    },
    ...options,
  });
}

export default function useMaskModuleList(searchData: ISearchSub) {
  return sub({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
