import { memo } from 'react';
import { BiRefresh } from 'react-icons/bi';
import { SearchRefreshWrapper } from 'styles';

const RefreshSearch = memo(
  ({ onMouseDown }: { onMouseDown: () => void }): JSX.Element => {
    return (
      <SearchRefreshWrapper>
        <BiRefresh
          className='refresh-icon'
          onMouseDown={onMouseDown}
        />
      </SearchRefreshWrapper>
    );
  },
);

export default RefreshSearch;
