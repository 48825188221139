import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IBbsDelete } from 'models/interfaces/bbsInterface';
import { client } from 'utils';

function bbsFileDeleteMutation(
  options: UseMutationOptions<unknown, Error, IBbsDelete, unknown>,
) {
  return useMutation({
    mutationFn: async targetFile => {
      const response = await client.delete('/api/co/cm/800m01/file', {
        data: {
          BBS_ID: targetFile.BBS_ID,
          FILE_LIST: targetFile.FILE_LIST,
        },
      });
      return response;
    },
    ...options,
  });
}

export default function useBbsFileDeleteMutation() {
  const queryClient = useQueryClient();

  return bbsFileDeleteMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['bbs_id', 'file_list'],
      });
    },
  });
}
