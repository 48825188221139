import { useQuery } from '@tanstack/react-query';
import {
  IPopupParam,
  IPopupSearchParam,
  UseQueryOptionsWithoutQueryKey,
} from 'models';
import { client } from 'utils';
import { defaultPopupParam } from 'models';

interface IPopupListParam {
  popupCode: IPopupParam;
  options: UseQueryOptionsWithoutQueryKey;
}

function popupList({ popupCode, options }: IPopupListParam) {
  return useQuery({
    queryKey: ['common', 'popup', popupCode],
    queryFn: async () => {
      const response = await client.get('/api/co/cm/popup/list', {
        params: popupCode,
      });

      return response.data.popupList;
    },
    ...options,
  });
}

export default function usePopupList(searchParam: IPopupSearchParam) {
  const popupCode: IPopupParam = {
    ...defaultPopupParam,
    ...searchParam,
  };

  return popupList({
    popupCode: popupCode,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
