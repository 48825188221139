import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IDesign } from 'models';
import { toast } from 'react-toastify';
import { client, getAlertMessage } from 'utils';

function designMutation(
  options: UseMutationOptions<
    unknown,
    Error,
    { PRJ_NO: string; designList: IDesign[] },
    unknown
  >,
) {
  return useMutation({
    mutationFn: async design => {
      const response = await client.post('/api/teg/04/design', design);
      return response;
    },
    ...options,
  });
}

export default function useDesignMutation() {
  const queryClient = useQueryClient();

  return designMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['module'],
      });
      toast.success(getAlertMessage('INFO_00010'));
    },
  });
}
