import { ITableCellProps } from 'models';
import ReadOnlyTextCell from './ReadOnlyTextCell';
import TextCell from './TextCell';

const ExpressionCell = ({
  getValue,
  row,
  column,
  table,
  rowRef,
}: ITableCellProps): JSX.Element => {
  const expression =
    row.original.VAL_EXPR !== 'x' &&
    row.original.VAL_EXPR !== undefined &&
    row.original.VAL_EXPR !== null;

  return (
    <>
      {expression ? (
        <ReadOnlyTextCell
          column={column}
          row={row}
          table={table}
          getValue={getValue}
          rowRef={rowRef}
        />
      ) : (
        <TextCell
          column={column}
          row={row}
          table={table}
          getValue={getValue}
          rowRef={rowRef}
        />
      )}
    </>
  );
};

export default ExpressionCell;
