import { MenuTabContainer } from 'styles';
import TabMenu from './TabMenu';
import Tab from './Tab';

const TabMenuBox = (): JSX.Element => {
  return (
    <MenuTabContainer>
      <TabMenu />
      <Tab />
    </MenuTabContainer>
  );
};

export default TabMenuBox;
