import { RowType } from 'models';

/**
 * 탭의 닫기 버튼 클릭 시 해당 프레임에 변경된 데이터 여부를 확인하여 반환하는 함수
 * @param {object} wholeFrameData 전체 프레임 데이터를 key와 value로 보유한 객체
 * @param {string} FrameId 닫으려고하는 대상의 프레임 ID
 * @returns {boolean} 변경된 데이터 여부
 */
export const checkChangeFrameData = (wholeFrameData, FrameId) => {
  const targetData = wholeFrameData[FrameId];

  if (targetData) {
    const notNormalData = targetData.filter(
      menu => menu.ROWTYPE !== RowType.NORMAL,
    );
    const isChanged = notNormalData.length > 0;

    return isChanged;
  }
};

export const customEqual = (obj1, obj2) => {
  if (obj1 === obj2) return true;
  if (obj1 === null || obj1 === undefined) obj1 = '';
  if (obj2 === null || obj2 === undefined) obj2 = '';
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return obj1 === obj2;
  }
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) return false;
    for (let i = 0; i < obj1.length; i++) {
      if (!customEqual(obj1[i], obj2[i])) return false;
    }
    return true;
  }
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) return false;
  for (let key of keys1) {
    if (!keys2.includes(key)) return false;
    if (!customEqual(obj1[key], obj2[key])) return false;
  }
  return true;
};
