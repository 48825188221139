import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { IUserCode } from 'models';
import { client } from 'utils';

function passwordMutation(
  options: UseMutationOptions<unknown, Error, IUserCode, unknown>,
) {
  return useMutation({
    mutationFn: async userCode => {
      const response = await client.put('/api/co/cm/pwd/init', userCode);
      return response;
    },
    ...options,
  });
}

export default function usePasswordMutation() {
  return passwordMutation({
    onSuccess: () => {
      alert('비밀번호가 초기화 되었습니다.');
    },
  });
}
