import TEG10DescriptionTable from './TEG10DescriptionTable';
import TEG10DeviceTable from './TEG10DeviceTable';
import TEG10LayerTable from './TEG10LayerTable';

const TEG10Description = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  return (
    <div className='flex gap-x-2 w-full'>
      <div className='w-[250px]'>
        <TEG10DeviceTable tableHeight={tableHeight} />
      </div>
      <div className='grow-col-between'>
        <TEG10DescriptionTable tableHeight={350} />
        <TEG10LayerTable tableHeight={200} />
      </div>
    </div>
  );
};

export default TEG10Description;
