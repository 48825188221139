import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchDocumentKey } from 'models';
import { client } from 'utils';

function chapterDescriptionList({
  searchData,
  options,
}: IQueryParams<ISearchDocumentKey>) {
  return useQuery({
    queryKey: ['document', 'chapter', 'description', searchData.CHR_SNO],
    queryFn: async () => {
      const response = await client.get('/api/teg/09/doc/rule/rule', {
        params: searchData,
      });
      return response.data;
    },
    ...options,
  });
}

export default function useChapterDescriptionList(
  searchData: ISearchDocumentKey,
) {
  return chapterDescriptionList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled:
        searchData.CHR_SNO !== undefined && searchData.CH_NO !== undefined,
    },
  });
}
