import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IMaskSubsiteFileWrapper } from 'models/interfaces/maskInterface';
import { toast } from 'react-toastify';
import { client, getAlertMessage } from 'utils';

function maskMutation(
  options: UseMutationOptions<
    unknown,
    Error,
    IMaskSubsiteFileWrapper,
    unknown
  >,
) {
  return useMutation({
    mutationFn: async (maskFile: IMaskSubsiteFileWrapper) => {
      const formData = new FormData();
      const { file, search } = maskFile;
      formData.append('file', file); // 파일 추가
      formData.append(
        'search',
        new Blob([JSON.stringify(search)], {
          type: 'application/json',
        }),
      );
      const response = await client.post(
        '/api/teg/02/file/text/upload',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );
      return response;
    },
    ...options,
  });
}

export default function useMaskSubsiteUploadMutation() {
  const queryClient = useQueryClient();

  return maskMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['mask'],
      });
      toast.success(getAlertMessage('INFO_04040'));
    },
  });
}
