import useCellValue from 'hooks/useCellValue';
import { memo, useState } from 'react';
import {
  CustomChangeEvent,
  CustomFocusEvent,
  CustomSelectFocusEvent,
  IColumnMeta,
  ITableCellProps,
  ITableMeta,
} from 'models';
import { DataTableInput, DataTableSelect } from 'styles';
import { isEqual } from 'utils';

const DoubleCell = ({
  getValue,
  table,
  row,
  column,
  rowRef,
}: ITableCellProps): JSX.Element => {
  const value = getValue();
  const tableMeta: ITableMeta | undefined = table.options.meta;
  const [selectValue, setSelectValue] = useCellValue(
    value,
    tableMeta,
    rowRef,
  );

  const columnMeta: IColumnMeta | undefined = column.columnDef.meta;
  const getDisabled = () => {
    if (typeof columnMeta?.disabled === 'function') {
      return columnMeta.disabled(row.original);
    } else {
      return columnMeta?.disabled;
    }
  };
  const unmodifiedRow = selectValue !== 'X99';
  const disabled = columnMeta?.disabled ? getDisabled() : false;
  const options = columnMeta?.options;
  const renderKey = columnMeta?.renderKey;
  const renderValue =
    selectValue === 'X99' && renderKey
      ? row.original[renderKey as string]
      : '';
  const [textValue, changeTextValue, setTextValue] = useCellValue(
    renderValue,
    tableMeta,
    rowRef,
  );

  const [filteredOptions, setFilteredOptions] = useState(options);
  const optionFilter = columnMeta?.optionFilter;
  const optionFn = columnMeta?.optionFn;
  const renderOptions = optionFn
    ? optionFn(row.original)
    : filteredOptions;
  const selectOnFocus = (e: CustomSelectFocusEvent) => {
    if (!optionFilter) return;
    let newOptions = filteredOptions;
    for (let i = 0; i < table.getRowCount(); i++) {
      const original = table.getRow(i.toString()).original as any;
      if (i !== row.index) {
        newOptions = newOptions?.filter(
          option =>
            option.value.indexOf(original[e.target.name]) === -1 ||
            option.value === 'X99',
        );
      }
    }
    setFilteredOptions(newOptions);
  };

  const onChangeSelect = (e: CustomChangeEvent) => {
    const selectedOption = renderOptions?.find(
      option => option.value.toString() === e.target.value,
    );
    setSelectValue(row.index, e, typeof selectedOption.value);
  };
  const onChangeText = (e: CustomChangeEvent) => {
    setTextValue(e.target.value);
  };
  const onBlurText = (e: CustomFocusEvent) => {
    changeTextValue(row.index, e);
  };

  return (
    <div
      className='w-full max-w-full'
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <DataTableSelect
        value={selectValue ?? ''}
        name={column.id}
        onChange={onChangeSelect}
        // onMouseDown={e => e.stopPropagation()}
        required={columnMeta?.required ?? false}
        disabled={disabled}
        onFocus={selectOnFocus}
      >
        {renderOptions?.map((option, index) => (
          <option
            key={option.value + index}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </DataTableSelect>
      <DataTableInput
        type='text'
        className='text-start'
        readOnly={unmodifiedRow}
        name={renderKey}
        value={textValue}
        onChange={onChangeText}
        onBlur={onBlurText}
        autoComplete='off'
      />
    </div>
  );
};

export default memo(DoubleCell, isEqual);
