import { useHeight, useSearchComboList } from 'hooks';
import { useEffect, useState } from 'react';
import {
  CommonButton,
  FrameInfo,
  FrameInfoInput,
  FrameInfoSelect,
  Modal,
  Tree,
} from 'components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { convertDataToTree, editValues, preConvertData } from 'utils';
import COCM150M01Table from './COCM150M01Table';
import { FrameContent } from 'styles';
import { menuManagementState } from 'store';
import {
  CommonArray,
  CommonObject,
  CustomChangeEvent,
  ISearchParentMenu,
  PROGRAM_PARAM,
} from 'models';
import { useParentMenuList } from 'apis';

const targetKey = 'MNU_ID';
const parentKey = 'HRK_MNU_ID';
const renderKey = 'MNU_NM';

const COCM150M01 = (): JSX.Element => {
  const getHeight = useHeight();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const currentChildMenu = useRecoilValue(
    menuManagementState.currentChildMenu,
  );
  const setMenuData = useSetRecoilState(menuManagementState.menuList);
  const [searchParentMenu, setSearchParentMenu] = useRecoilState(
    menuManagementState.searchParentMenu,
  );
  const [values, setValues] =
    useState<ISearchParentMenu>(searchParentMenu);
  const domainOptions = useSearchComboList({ CMB_CD: 'CMB0027' });
  const systemOptions = useSearchComboList({ CMB_CD: 'CMB0015' });
  const combinedSystemOptions = [
    { label: '전체', value: '' },
    ...systemOptions,
  ];
  const menuUseOptions = [
    { label: '전체', value: '' },
    { label: '사용', value: '1' },
    { label: '사용안함', value: '0' },
  ];
  const { data: treeParentMenuData, isLoading } = useParentMenuList(
    searchParentMenu,
  ) as CommonObject;
  const [treeParentMenuList, setTreeParentMenuList] =
    useState<CommonArray>([]);
  const [currentTreeData, setCurrentTreeData] = useRecoilState(
    menuManagementState.currentParentMenu,
  );
  const setSearchChildMenu = useSetRecoilState(
    menuManagementState.searchChildMenu,
  );

  const selectValueByModal = (selectValue: CommonObject) => {
    setMenuData(oldMenu =>
      oldMenu.map((menu, index) => {
        if (index === currentChildMenu.index) {
          const isChanged = menu.PGM_ID !== selectValue.PGM_ID;
          return {
            ...menu,
            PGM_ID: selectValue.PGM_ID,
            PGM_NM: selectValue.PGM_NM,
            ROWTYPE: menu?.ROWTYPE === 1 ? 1 : isChanged ? 2 : 0,
          };
        }
        return menu;
      }),
    );
    setModalOpen(false);
  };

  const editValue = (e: CustomChangeEvent) => {
    editValues(e, setValues);
  };

  const handleSearchParentMenu = () => {
    setSearchParentMenu({
      ...values,
      DMN_CD:
        domainOptions.length === 1
          ? domainOptions[0].value
          : values.DMN_CD,
    });
  };

  useEffect(() => {
    if (currentTreeData.data) {
      setSearchChildMenu({
        DMN_CD: currentTreeData.data.DMN_CD,
        HRK_MNU_ID: currentTreeData.data.MNU_ID,
        USE_YN: searchParentMenu.USE_YN,
      });
    }
  }, [currentTreeData]);

  useEffect(() => {
    if (treeParentMenuData) {
      const convertData = preConvertData(treeParentMenuData, parentKey);
      const updatedFirstElement = {
        ...convertData[0],
        HRK_MNU_ID: undefined,
      };
      const updatedData = [updatedFirstElement, ...convertData.slice(1)];
      setTreeParentMenuList(
        convertDataToTree(updatedData, parentKey, targetKey),
      );
    }
  }, [treeParentMenuData]);

  return (
    <>
      <FrameInfo>
        <div className='search-form'>
          <div className='input-block'>
            <FrameInfoSelect
              title='도메인'
              options={domainOptions}
              name='DMN_CD'
              onChange={editValue}
            />
            <FrameInfoSelect
              title='시스템구분'
              options={combinedSystemOptions}
              name='SYS_DTT_CD'
              onChange={editValue}
            />
            <FrameInfoSelect
              title='사용여부'
              options={menuUseOptions}
              name='USE_YN'
              onChange={editValue}
            />
            <FrameInfoInput
              title='메뉴명'
              type='text'
              name='MNU_NM'
              value={values?.MNU_NM || ''}
              onChange={editValue}
            />
          </div>
        </div>
        <div>
          <CommonButton
            title='조회'
            onClick={handleSearchParentMenu}
          />
        </div>
      </FrameInfo>
      <FrameContent>
        <Tree
          title='메뉴'
          isLoading={isLoading}
          data={treeParentMenuList}
          currentData={currentTreeData}
          setCurrentData={setCurrentTreeData}
          nodeKey={targetKey}
          parentKey={parentKey}
          renderKey={renderKey}
          height={getHeight(650)}
        />
        <div className='grow-col-between'>
          <COCM150M01Table
            tableHeight={getHeight(650)}
            openModal={setModalOpen}
          />
        </div>
      </FrameContent>
      {modalOpen && (
        <Modal
          onClose={() => setModalOpen(false)}
          param={PROGRAM_PARAM}
          handleSelect={selectValueByModal}
        />
      )}
    </>
  );
};

export default COCM150M01;
