import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Table,
  StatusCell,
  ReadOnlyTextCell,
  TextCell,
  StatusIcon,
  DateCell,
} from 'components';
import {
  TableContext,
  saveTableData,
  addRowData,
  removeRowData,
  addRowNumber,
  selectNextDataByRemove,
} from 'utils';
import { Columns, CommonArray, CommonObject, RowType } from 'models';
import { useRecoilState, useRecoilValue } from 'recoil';
import { projectState } from 'store';
import { useImage, useProjectList } from 'apis';
import { useTableData } from 'hooks';
import { ITableContext, CurrentData } from 'models';
import useProjectMutation from 'apis/mutations/useProjectMutation';
import {
  defaultProject,
  defaultSearchProject,
} from 'models/defaults/projectDefaults';
import {
  IProject,
  IProjectCopy,
} from 'models/interfaces/projectInterface';
import { toast } from 'react-toastify';

const columnHelper = createColumnHelper();
const newRow = defaultProject;

const TEG01ProjectTable = ({
  tableHeight,
  openProjectCopyModal,
}: {
  tableHeight: number;
  openProjectCopyModal: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const [searchData, setSearchData] = useRecoilState(
    projectState.searchData('projectTable'),
  );
  const { data: project } = useProjectList(searchData) as CommonObject;
  const [currentData, setCurrentData] = useRecoilState(
    projectState.currentProjectData,
  );
  const [backupData, setBackupData] = useState<CommonArray>([]);
  const copyList = useRecoilValue(projectState.projectCopyList);
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: project,
    atom: projectState.projectList,
    effect: () => {
      const newData = addRowNumber(project);
      setBackupData(newData);
      setInitialData(newData);
      setCurrentData({ index: 0, data: newData[0] });
    },
  });
  const saveProjectList = useProjectMutation();

  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 40,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('no', {
      header: 'NO',
      cell: ReadOnlyTextCell,
      size: 40,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('PRJ_NM', {
      header: '프로젝트 명',
      cell: TextCell,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('STR_DATE', {
      header: '시작일자',
      cell: DateCell,
      size: 100,
    }),
    columnHelper.accessor('END_DATE', {
      header: '종료일자',
      cell: DateCell,
      size: 100,
    }),
    columnHelper.accessor('RMK_CNT', {
      header: '비고',
      cell: TextCell,
    }),
    columnHelper.accessor('UPD_DATE', {
      header: '수정일자',
      cell: ReadOnlyTextCell,
      meta: {
        justify: 'center',
      },
      size: 100,
    }),
    columnHelper.accessor('UPDR_ID', {
      header: '수정자',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
      size: 80,
    }),
    columnHelper.accessor('OWNER_ID', {
      header: '소유자',
      cell: TextCell,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
      size: 80,
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const handleAddData = () => {
    addRowData(setInitialData, setCurrentData, newRow, initialData.length);
  };

  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);

    if (removedData.length > 0) {
      setInitialData(removedData);
      selectNextDataByRemove(
        selectData,
        initialData.length - 1,
        removedData,
        setCurrentData,
      );
    }
  };
  const handleSaveData = () => {
    const checkKeys = ['PRJ_NM'];
    const projectList = saveTableData(initialData, checkKeys);
    const saveData = {
      projectList: projectList as IProject[],
      copyList: copyList as IProjectCopy[],
    };
    if (projectList.length > 0) {
      saveProjectList.mutate(saveData);
      setSearchData(defaultSearchProject);
    } else {
      setInitialData(initialData);
    }
  };
  const { data: imageUrl } = useImage(
    'GUIDE/project_guide.png',
  ) as CommonObject;
  const handleGuide = () => {
    if (imageUrl) {
      window.open(
        imageUrl,
        '_blank',
        'width=649, height=934, location=no',
      );
    }
  };

  const handleDuplicateData = () => {
    if (
      currentData?.data?.ROWTYPE === undefined ||
      currentData?.data?.ROWTYPE === RowType.ADD
    ) {
      toast.warning('프로젝트를 저장한 후 복사해주세요.');
      return;
    }
    openProjectCopyModal(true);
  };

  const contextValue: ITableContext = {
    handleGuide,
    handleDuplicateData,
    handleAddData,
    handleRemoveData,
    handleSaveData,
    currentData,
    setCurrentData,
  };

  return (
    <TableContext.Provider value={contextValue}>
      {initialData && (
        <Table
          title='프로젝트 목록'
          initialData={initialData}
          backupData={backupData}
          setData={setInitialData}
          columns={columns}
          editable={true}
          tableHeight={tableHeight}
        />
      )}
    </TableContext.Provider>
  );
};

export default TEG01ProjectTable;
