import { createColumnHelper } from '@tanstack/react-table';
import useDeviceMutation from 'apis/mutations/useDeviceMutation';
import useDeviceList from 'apis/queries/useDeviceList';
import {
  DisplayButton,
  ReadOnlyTextCell,
  SelectCell,
  StatusCell,
  StatusIcon,
  Table,
  TextCell,
} from 'components';
import { useMoveTeg, useSearchComboList, useTableData } from 'hooks';
import {
  Columns,
  CommonArray,
  CommonObject,
  CurrentData,
  ITableContext,
  RowType,
} from 'models';
import {
  defaultDevice,
  defaultDeviceAnalysis,
  defaultDeviceTEGDescription,
} from 'models/defaults/deviceDefaults';
import { IDevice } from 'models/interfaces/deviceInterface';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { deviceState, moduleState } from 'store';
import {
  TableContext,
  addRowData,
  addRowNumber,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';

const columnHelper = createColumnHelper();
const newRow = defaultDevice;

const defaultDescriptionTableData = defaultDeviceTEGDescription;
const defaultAnalysisTableData = defaultDeviceAnalysis;

const TEG03DeviceTable = ({
  tableHeight,
  openDeviceCopyModal,
  openDescriptionModal,
  openAnalysisModal,
}: {
  tableHeight: number;
  openDeviceCopyModal: Dispatch<SetStateAction<boolean>>;
  openDescriptionModal: Dispatch<SetStateAction<boolean>>;
  openAnalysisModal: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const searchData = useRecoilValue(deviceState.searchDevice);
  const setDeviceDescriptionList = useSetRecoilState(
    deviceState.searchDeviceDescriptionList,
  );
  const setDeviceAnalysisList = useSetRecoilState(
    deviceState.searchDeviceAnalysisList,
  );
  const setSearchModule = useSetRecoilState(
    moduleState.searchModule('teg04'),
  );
  const deviceOptions = useSearchComboList({ CMB_CD: 'CMB0034' });
  const moduleOptions = useSearchComboList({ CMB_CD: 'CMB0035' });
  const deviceResponse = useDeviceList({
    PRJ_NO: searchData.PRJ_NO,
    PRC_NO: searchData.PRC_NO,
  }) as CommonObject;
  const [currentData, setCurrentData] = useRecoilState(
    deviceState.currentDeviceData,
  );
  const [backupData, setBackupData] = useState<CommonArray>([]);
  const device = deviceResponse?.data?.deviceList;
  const maskDeviceList = deviceResponse?.data?.maskDeviceList;
  const defaultEmpty = {
    value: '',
    label: '선택',
  };

  const maskDeviceOptions = Array.isArray(maskDeviceList)
    ? maskDeviceList.map(data => ({
        label: data.MSK_DVC_CD,
        value: data.MSK_DVC_CD,
      }))
    : [];

  maskDeviceOptions.unshift(defaultEmpty);

  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: device,
    atom: deviceState.deviceList,
    effect: () => {
      const newData = addRowNumber(device);
      setInitialData(newData);
      setBackupData(newData);
      setCurrentData({ index: 0, data: device[0] });
      const defaultDescriptionList = defaultDescriptionTableData.map(
        item => ({
          ...item,
          NO: currentData?.data?.DVC_NO,
        }),
      );
      const defaultAnalysisList = defaultAnalysisTableData.map(item => ({
        ...item,
        NO: currentData?.data?.DVC_NO,
      }));
      for (let i = 0; i < device.length; i++) {
        const dev = {
          DIV: '디바이스',
          NAME: device[i].DVC_NM + ' / ' + device[i].VOP_NM,
          CHECK: '0',
          TYPE: 'DVC',
          NO: device[i].DVC_NO,
          DVC_NO: device[i].DVC_NO,
        };
        defaultDescriptionList.push(dev);
        defaultAnalysisList.push(dev);
      }
      setDeviceDescriptionList(defaultDescriptionList);
      setDeviceAnalysisList(defaultAnalysisList);
    },
  });
  const saveDeivceList = useDeviceMutation();
  const moveTeg = useMoveTeg();
  const handleMove = (row: CommonObject) => {
    const { DVC_NO } = row;
    const { PRJ_NO, PRC_NO } = searchData;
    setSearchModule(old => ({
      ...old,
      PRJ_NO: String(PRJ_NO),
      PRC_NO: String(PRC_NO),
      DVC_NO: String(DVC_NO),
    }));
    moveTeg('UIE004TEG04');
  };
  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 30,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('no', {
      header: 'NO',
      cell: ReadOnlyTextCell,
      size: 30,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('DVC_NM', {
      header: '디바이스 명',
      cell: TextCell,
      filterFn: 'includesString',
      size: 120,
    }),
    columnHelper.accessor('VOP_NM', {
      header: 'Vop 명',
      cell: TextCell,
      size: 80,
    }),
    columnHelper.accessor('DVC_CD', {
      header: '디바이스 구조',
      cell: SelectCell,
      size: 100,
      meta: {
        key: 'DVC_CD',
        options: deviceOptions,
        required: true,
      },
    }),
    columnHelper.accessor('MMO_CD', {
      header: '대표모듈',
      cell: SelectCell,
      size: 160,
      meta: {
        renderKey: 'MMO_CD',
        options: moduleOptions,
        required: true,
      },
    }),
    columnHelper.accessor('MMO_NM', {
      header: '대표모듈명',
      cell: TextCell,
      size: 160,
    }),
    columnHelper.accessor('MSK_DVC_CD', {
      header: '마스크 좌표',
      cell: SelectCell,
      size: 120,
      meta: {
        renderKey: 'MSK_DVC_CD',
        options: maskDeviceOptions,
      },
    }),
    columnHelper.accessor('RMK_CNT', {
      header: '별명',
      cell: TextCell,
      size: 160,
    }),
    columnHelper.display({
      header: '모듈',
      cell: DisplayButton,
      size: 80,
      meta: {
        text: '관리',
        onClick: (row: CommonObject) => handleMove(row),
        disabled: (row: CommonObject) => row.ROWTYPE === RowType.ADD,
      },
    }),
  ];
  const columns = useMemo(
    () => defaultColumns,
    [deviceOptions, moduleOptions, maskDeviceOptions],
  ) as Columns;

  const handleAddData = () => {
    const modifiedNewRow = {
      ...newRow,
      PRC_NO: searchData?.PRC_NO || '',
      DVC_CD: deviceOptions?.[0]?.value || '',
      MMO_CD: moduleOptions?.[0]?.value || '',
      MSK_DVC_CD: maskDeviceOptions?.[0]?.value || '',
    };
    addRowData(
      setInitialData,
      setCurrentData,
      modifiedNewRow,
      initialData.length,
    );
  };

  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);

    if (removedData.length > 0) {
      setInitialData(removedData);
      selectNextDataByRemove(
        selectData,
        initialData.length - 1,
        removedData,
        setCurrentData,
      );
    }
  };

  const handleSaveData = () => {
    const checkKeys = ['DVC_NM', 'VOP_NM', 'DVC_CD', 'MMO_CD', 'MMO_NM'];
    const deviceList = saveTableData(initialData, checkKeys);
    const saveData = {
      PRJ_NO: searchData.PRJ_NO as number,
      deviceList: deviceList as IDevice[],
    };
    if (deviceList.length > 0) {
      saveDeivceList.mutate(saveData);
    } else {
      setInitialData(initialData);
    }
  };

  const handleDuplicateData = () => {
    openDeviceCopyModal(true);
  };

  const handleTegDoc = () => {
    openDescriptionModal(true);
  };

  const handleAnalysisDoc = () => {
    openAnalysisModal(true);
  };

  const contextValue: ITableContext = {
    handleDuplicateData,
    handleAddData,
    handleRemoveData,
    handleSaveData,
    handleTegDoc,
    handleAnalysisDoc,
    currentData,
    setCurrentData,
    customDisabled: {
      fetched: searchData.PRJ_NO === 0 || searchData.PRC_NO === 0,
    },
  };
  return (
    <TableContext.Provider value={contextValue}>
      {initialData && (
        <Table
          title='디바이스'
          initialData={initialData}
          backupData={backupData}
          setData={setInitialData}
          columns={columns}
          editable={true}
          tableHeight={tableHeight}
        />
      )}
    </TableContext.Provider>
  );
};

export default TEG03DeviceTable;
