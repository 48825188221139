import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchModuleDoc } from 'models';
import { client } from 'utils';

function moduleAnalysis({
  searchData,
  options,
}: IQueryParams<ISearchModuleDoc>) {
  return useQuery({
    queryKey: ['module', 'analysis', searchData],
    queryFn: async () => {
      const response = await client.get(
        '/python/all_module_ppt_analysis',
        {
          params: searchData,
        },
      );
      const { data, status } = response;
      return {
        data,
        status,
        FLAG: searchData.FLAG,
      };
    },
    ...options,
  });
}

export default function useModuleAnalysis(searchData: ISearchModuleDoc) {
  return moduleAnalysis({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: searchData.PRJ_NO !== '' && searchData.DVC_NO !== '',
    },
  });
}
