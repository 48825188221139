import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchAuth } from 'models';
import { client } from 'utils';

function assignedAuthorityList({
  searchData,
  options,
}: IQueryParams<ISearchAuth>) {
  return useQuery({
    queryKey: ['system_setting', 'user_assigned', searchData],
    queryFn: async () => {
      const response = await client.get(
        '/api/co/cm/170m02/user/authority/assigned',
        { params: searchData },
      );
      return response.data.userAuthorityList || [];
    },
    ...options,
  });
}

export default function useAssignedAuthorityList(searchData: ISearchAuth) {
  return assignedAuthorityList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: searchData.USR_NM !== undefined && searchData.USR_NM !== '',
    },
  });
}
