import { usePopupInfo, usePopupList } from 'apis';
import {
  CommonArray,
  CommonObject,
  CustomMouseEvent,
  IModalProps,
  IPopupParam,
} from 'models';
import { useState } from 'react';
import { ModalInner, ModalOverlay, ModalWrapper } from 'styles';
import ModalSearch from './ModalSearch';
import CommonButton from 'components/common/CommonButton';
import ModalTable from './ModalTable';

const Modal = ({
  onClose,
  maskCloseable = true,
  param,
  handleSelect,
}: IModalProps): JSX.Element => {
  const [searchData, setSearchData] = useState<IPopupParam>(param);
  const [selectData, setSelectData] = useState<CommonObject>();
  const { data: popupInfo } = usePopupInfo(
    param.SRCH_PPU_CD,
  ) as CommonObject;

  const { data: popupList, isLoading } = usePopupList(searchData);

  const onMaskClick = (e: CustomMouseEvent) => {
    if (e.target === e.currentTarget) {
      setSearchData(param);
      onClose();
    }
  };

  const close = () => {
    if (onClose) {
      setSearchData(param);
      onClose();
    }
  };

  const handleConfirm = () => {
    if (!selectData) {
      alert('대상이 선택되지 않았습니다.');
      return;
    }

    if (handleSelect) {
      handleSelect(selectData);
    }
    onClose();
  };
  return (
    <>
      <ModalOverlay />
      <ModalWrapper
        onClick={maskCloseable ? onMaskClick : undefined}
        tabIndex={-1}
      >
        <ModalInner
          tabIndex={0}
          className='w-[650px] h-[500px]'
        >
          <ModalSearch
            options={popupInfo?.comboList || []}
            setSearchValue={setSearchData}
          />
          <ModalTable
            data={popupList as CommonArray}
            isLoading={isLoading}
            columns={popupInfo?.columns || []}
            selectData={selectData}
            setSelectData={setSelectData}
            height={350}
          />
          <div className='flex justify-center mt-2'>
            <CommonButton
              title='확인'
              onClick={handleConfirm}
            />
            <CommonButton
              title='닫기'
              color='darkred'
              onClick={close}
            />
          </div>
        </ModalInner>
      </ModalWrapper>
    </>
  );
};

export default Modal;
