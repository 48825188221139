import { IPopupParam } from 'models/interfaces/modalInterface';
import { PopupCode } from 'models/types/commonTypes';

export const defaultPopupParam: IPopupParam = {
  SRCH_VALUE: '',
  SRCH_PPU_CD: '',
  SRCH_PARAM: '',
  SRCH_COL_IDX: '0',
  SRCH_USE_YN: 'Y',
  ROWTYPE: '0',
};

export const EQUIPMENT_PARAM = {
  ...defaultPopupParam,
  SRCH_PPU_CD: PopupCode.EQUIPMENT,
};
export const CODE_TYPE_PARAM = {
  ...defaultPopupParam,
  SRCH_PPU_CD: PopupCode.CODE_TYPE,
};
export const COMMON_CODE_PARAM = {
  ...defaultPopupParam,
  SRCH_PPU_CD: PopupCode.COMMON_CODE,
};
export const DEPARTMENT_PARAM = {
  ...defaultPopupParam,
  SRCH_PPU_CD: PopupCode.DEPARMENT,
};
export const MANAGE_PROCESS_PARAM = {
  ...defaultPopupParam,
  SRCH_PPU_CD: PopupCode.MANAGE_PROCESS,
};
export const PROGRAM_PARAM = {
  ...defaultPopupParam,
  SRCH_PPU_CD: PopupCode.PROGRAM,
};
export const MENU_PARAM = {
  ...defaultPopupParam,
  SRCH_PPU_CD: PopupCode.MENU,
};
export const CHAMBER_PARAM = {
  ...defaultPopupParam,
  SRCH_PPU_CD: PopupCode.CAHMBER,
};
export const RECIPE_PARAM = {
  ...defaultPopupParam,
  SRCH_PPU_CD: PopupCode.RECIPE,
};
export const CHAPTER_PARAM = {
  ...defaultPopupParam,
  SRCH_PPU_CD: PopupCode.CHAPTER,
};
