import { memo } from 'react';
import { changeFocus, isEqual } from 'utils';
import useCellValue from 'hooks/useCellValue';
import {
  CustomChangeEvent,
  CustomFocusEvent,
  CustomKeyboardEvent,
  IColumnMeta,
  ITableCellProps,
  ITableMeta,
} from 'models';
import { DataTableInput } from 'styles';

const NumberCell = ({
  getValue,
  table,
  row,
  column,
  rowRef,
}: ITableCellProps): JSX.Element => {
  const tableMeta: ITableMeta | undefined = table.options.meta;
  const columnMeta: IColumnMeta | undefined = column.columnDef.meta;
  const value = columnMeta?.isFloat
    ? Number(getValue()).toFixed(2)
    : getValue();
  const [numberValue, changeNumberValue, setNumberValue] = useCellValue(
    value,
    tableMeta,
    rowRef,
  );

  const onBlur = (e: CustomFocusEvent) => {
    if (columnMeta?.isFloat) {
      e.target.value = Number(e.target.value).toFixed(2);
      changeNumberValue(row.index, e);
    } else {
      changeNumberValue(row.index, e);
    }
  };

  const onChange = (e: CustomChangeEvent) => {
    setNumberValue(e.target.value);
  };

  const handleOnKeyDown = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter') e.currentTarget.blur();
    if (e.key === 'ArrowDown') {
      if (row.index + 1 === table.options.data.length) return;
      e.currentTarget.blur();
      e.preventDefault();
      const nextIndex = row.index + 1;
      tableMeta?.moveCurrentData?.(nextIndex);
      if (!changeFocus(nextIndex, column.id)) {
        tableMeta?.moveCurrentData?.(nextIndex + 1);
        changeFocus(nextIndex + 1, column.id);
      }
    }
    if (e.key === 'ArrowUp') {
      if (row.index === 0) return;
      e.currentTarget.blur();
      e.preventDefault();
      const prevIndex = row.index - 1;
      tableMeta?.moveCurrentData?.(prevIndex);
      if (!changeFocus(prevIndex, column.id)) {
        tableMeta?.moveCurrentData?.(prevIndex - 1);
        changeFocus(prevIndex - 1, column.id);
      }
    }
    if (e.key === 'ArrowLeft') {
      let columnId = row.getAllCells()[column.getIndex() - 1]?.column.id;
      e.currentTarget.blur();
      e.preventDefault();
      if (!changeFocus(row.index, columnId)) {
        columnId = row.getAllCells()[column.getIndex() - 2]?.column.id;
        changeFocus(row.index, columnId);
      }
    }
    if (e.key === 'ArrowRight') {
      let columnId = row.getAllCells()[column.getIndex() + 1]?.column.id;
      e.currentTarget.blur();
      e.preventDefault();
      if (!changeFocus(row.index, columnId)) {
        columnId = row.getAllCells()[column.getIndex() + 2]?.column.id;
        changeFocus(row.index, columnId);
      }
    }
  };

  const handleOnPaste = (e: any) => {
    const clipboardData = e.clipboardData;
    if (!clipboardData) {
      return;
    }
    const types = clipboardData.types;
    if (types.includes('text/html')) e.preventDefault();
  };

  return (
    <DataTableInput
      type='number'
      className='text-end w-full'
      id={`${row.index}-${column.id}`}
      name={column.id}
      value={numberValue ?? 0}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={e => e.target.select()}
      onKeyDown={handleOnKeyDown}
      required={columnMeta?.required ?? false}
      autoComplete='off'
      onPaste={handleOnPaste}
    />
  );
};

export default memo(NumberCell, isEqual);
