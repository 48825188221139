import { flexRender } from '@tanstack/react-table';
import { useDrag, useDrop } from 'react-dnd';
import { CustomMouseEvent, IDraggableRowProps } from 'models';
import { useTableContext } from 'hooks';
import { DataTableCell } from 'styles';

const DraggableRow = ({
  row,
  reorderRow,
  // handleStartIndex,
  backupRow,
}: IDraggableRowProps): JSX.Element => {
  const {
    currentData,
    setCurrentData,
    handleDoubleClick,
    handleCellColor,
  } = useTableContext();

  const handleSelect = (e: CustomMouseEvent) => {
    if (e.button !== 0) return;

    if (setCurrentData)
      setCurrentData({ index: row.index, data: row.original });
  };

  const [, dropRef] = useDrop({
    accept: 'row',
    drop: (draggedRow: any) =>
      reorderRow && reorderRow(draggedRow.index, row.index),
  });

  const [{ isDragging }, dragRef] = useDrag({
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => row,
    type: 'row',
  });

  return (
    <tr
      ref={node => dropRef(dragRef(node))}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className={
        currentData?.index === row.index
          ? 'bg-blue-100'
          : 'even:bg-slate-50'
      }
      onMouseDown={handleSelect}
      onDoubleClick={handleDoubleClick}
    >
      {row.getVisibleCells().map(cell => (
        <DataTableCell
          key={cell.id}
          style={{ maxWidth: cell.column.columnDef.size }}
          // onClick={() =>
          //   handleStartIndex({ row: row.index, column: index })
          // }
        >
          <div
            className={`cell-box ${handleCellColor?.(row.original, cell.column.id)}`}
          >
            {flexRender(cell.column.columnDef.cell, {
              ...cell.getContext(),
              rowRef: backupRow,
            })}
          </div>
        </DataTableCell>
      ))}
    </tr>
  );
};

export default DraggableRow;
