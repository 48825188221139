import { CommonButton, FrameInfo, FrameInfoInput } from 'components';
import { CustomChangeEvent, defaultSearchDocument } from 'models';
import { FrameContent } from 'styles';
import { useHeight } from 'hooks';
import TEG09DocumentTable from './TEG09DocumentTable';
import { useRecoilState } from 'recoil';
import { pdkState } from 'store';
import { editValues } from 'utils';
import TEG09DocumentHistoryTable from './TEG09DocumentHistoryTable';
import TEG09DocumentDeviceTable from './TEG09DocumentDeviceTable';
import TEG09DocumentTypeTable from './TEG09DocumentTypeTable';
import { useEffect } from 'react';

const TEG09 = (): JSX.Element => {
  const getHeight = useHeight();
  const [searchDocument, setSearchDocument] = useRecoilState(
    pdkState.searchDocument,
  );
  useEffect(() => {
    setSearchDocument(defaultSearchDocument);
  }, []);

  const editValue = (e: CustomChangeEvent) =>
    editValues(e, setSearchDocument);
  return (
    <>
      <FrameInfo>
        <div className='search-form'>
          <div className='input-block'>
            <FrameInfoInput
              title='생성 일자'
              type='date'
              value={searchDocument.DATE || ''}
              name='DATE'
              onChange={editValue}
            />
          </div>
          <div>
            <CommonButton title='조회' />
          </div>
        </div>
      </FrameInfo>
      <FrameContent>
        <div className='w-[600px] col-between gap-y-2'>
          <TEG09DocumentTable tableHeight={getHeight(320)} />
          <TEG09DocumentHistoryTable tableHeight={getHeight(320)} />
        </div>
        <div className='grow-col-between gap-y-2'>
          <TEG09DocumentDeviceTable tableHeight={getHeight(420)} />
          <TEG09DocumentTypeTable tableHeight={getHeight(220)} />
        </div>
      </FrameContent>
    </>
  );
};

export default TEG09;
