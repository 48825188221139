import { CommonObject, ISearchModule } from 'models';
import { Dispatch, SetStateAction } from 'react';

export const handleFileCheck = (
  responseData: CommonObject,
  searchModuleDoc: CommonObject,
  fileType: string | undefined,
  setFileCheck: Dispatch<
    SetStateAction<{ filePath: string; FLAG: boolean }>
  >,
) => {
  if (responseData?.FLAG === undefined) return;
  if (responseData?.status === 200) {
    const prjNo = searchModuleDoc.PRJ_NO.padStart(9, '0');
    const dvcNo = searchModuleDoc.DVC_NO.padStart(9, '0');
    setFileCheck({
      filePath: `/PRJ/${prjNo}/${dvcNo}/${fileType}`,
      FLAG: responseData.FLAG,
    });
  }
};

export const handleSearchDescription = (
  row: CommonObject,
  searchModule: ISearchModule,
  setSearchDescription: Dispatch<
    SetStateAction<{
      PRJ_NO: string;
      PRC_NO: string;
      DVC_NO: string;
      MDL_ID: string;
    }>
  >,
) => {
  const { MDL_ID } = row;
  const { PRJ_NO, PRC_NO, DVC_NO } = searchModule;
  setSearchDescription(old => ({
    ...old,
    PRJ_NO,
    PRC_NO,
    DVC_NO,
    MDL_ID,
  }));
};
