import { useQuery } from '@tanstack/react-query';
import { IQueryParams, IUserCode } from 'models';
import { client } from 'utils';

function userAuthorityList({
  searchData,
  options,
}: IQueryParams<IUserCode>) {
  return useQuery({
    queryKey: ['system_setting', 'user_authority', searchData],
    queryFn: async () => {
      const response = await client.get(
        '/api/co/cm/170m01/user/authority',
        { params: searchData },
      );
      return response.data.userAuthorityList || [];
    },
    ...options,
  });
}

export default function useUserAuthorityList(searchData: IUserCode) {
  return userAuthorityList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
