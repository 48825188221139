import { useEffect, useState } from 'react';

import { CommonButton, FrameInfo, FrameInfoSelect } from 'components';
import { CustomChangeEvent } from 'models';
import { useRecoilState } from 'recoil';
import { editValues } from 'utils';
import { dscCodeState } from 'store/dscCodeAtoms';
import { FrameContent } from 'styles';
import COCM02DscTable from './COCM02DscTable';
import { useHeight, useSearchComboList } from 'hooks';

const COCM02 = (): JSX.Element => {
  const deviceTypeOptions = useSearchComboList({ CMB_CD: 'CMB0037' });
  const [searchOptions, setSearchOptions] = useState(deviceTypeOptions);
  const getHeight = useHeight();
  const [searchDeviceType, setSearchDeviceType] = useRecoilState(
    dscCodeState.searchDeviceType('COCM02'),
  );
  const [values, setValues] = useState(searchDeviceType);

  const editValue = (e: CustomChangeEvent) => {
    editValues(e, setValues);
  };
  const handleSearchType = () => {
    setSearchDeviceType(values);
  };
  useEffect(() => {
    const addRow = { label: 'ALL', value: '' };
    setSearchOptions([addRow, ...deviceTypeOptions]);
  }, [deviceTypeOptions]);

  return (
    <>
      <FrameInfo>
        <div className='search-form'>
          <div className='input-block'>
            <FrameInfoSelect
              title='디바이스 종류'
              options={searchOptions}
              name='DVC_TYPE'
              value={values.DVC_TYPE}
              onChange={editValue}
            />
          </div>
        </div>
        <div>
          <CommonButton
            title='조회'
            onClick={handleSearchType}
          />
        </div>
      </FrameInfo>
      <FrameContent>
        <div className='grow-col-between'>
          <COCM02DscTable tableHeight={getHeight(650)} />
        </div>
      </FrameContent>
    </>
  );
};

export default COCM02;
