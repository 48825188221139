import { useComboList } from 'apis';
import { CommonObject, IComboCode } from 'models';
import { useEffect, useState } from 'react';
import { convertComboToOptions } from 'utils';

const emptyOptions = {
  CMN_CD: '',
  CMN_CD_NM: '선택',
};

const defaultEmpty = {
  value: '',
  label: '선택',
};

/**
 * 검색, 수정, 테이블에서 사용되는 select 태그의 옵션을 DB에서 호출하여 반환하는 훅
 * 내부적으로 react-query를 호출하여 반환된 데이터를 상태로 반환
 * @param {IComboCode} comboCode 시스템 검색콤보관리에 등록된 코드 및 추가 파라미터
 * @param {boolean} addEmpty 옵션의 0번째에 '선택'을 추가하고싶은 경우 지정(default: false)
 * @param {(comboData: any) => void} convertFn 콤보에 대한 추가적인 변환작업이 필요한 경우 외부에서 정의한 함수를 전달
 * @returns {array} react-query가 수행한 데이터를 기반으로 label, value를 속성으로 보유한 배열 반환
 */
export default function useSearchComboList(
  comboCode: IComboCode,
  addEmpty?: boolean,
  convertFn?: (comboData: any) => void,
) {
  const { data: comboData } = useComboList(comboCode) as CommonObject;
  const defaultComboList = addEmpty ? [defaultEmpty] : [];
  const [comboList, setComboList] = useState<any[]>(defaultComboList);

  if (convertFn && typeof convertFn !== 'function') {
    throw new Error('convertFn은 함수만 전달가능합니다.');
  }

  useEffect(() => {
    if (comboData) {
      const newComboData = comboData as IComboCode[];
      const tempComboList = addEmpty
        ? [emptyOptions, ...newComboData]
        : newComboData;
      const targetComboList = convertFn
        ? convertFn?.(tempComboList)
        : tempComboList;
      setComboList(convertComboToOptions(targetComboList));
    } else {
      setComboList(defaultComboList);
    }
  }, [comboData]);

  return comboList;
}
