import { useEffect, useState } from 'react';
import { saveFormData, editValues } from 'utils';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useSearchComboList } from 'hooks';
import {
  FrameEditBox,
  FrameEditField,
  FrameEditInput,
  FrameEditSelect,
  Modal,
  ModalButton,
} from 'components';
import {
  CommonObject,
  CustomChangeEvent,
  DEPARTMENT_PARAM,
  RowType,
} from 'models';
import { systemManagementState } from 'store';
import { EditFieldRow } from 'styles';

const AIBI010M01Form = (): JSX.Element => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const currentData = useRecoilValue(
    systemManagementState.currentDepartmentCode('AIBI010M01'),
  );
  const [values, setValues] = useState(currentData.data);
  const { index: currentIndex } = currentData;
  const setDepartmentCodeTableData = useSetRecoilState(
    systemManagementState.departmentCodeList,
  );
  const options = useSearchComboList({ CMB_CD: 'CMB0019' });

  const setCustomValues = (value: CommonObject) => {
    const target = options.filter(option => option.value === value);

    setValues(old => ({
      ...old,
      DPT_DTT_CD: target[0].value,
      DPT_DTT_CD_NM: target[0].label,
    }));
  };

  const editValue = (e: CustomChangeEvent) => {
    editValues(e, setValues, setCustomValues);
  };

  const selectValueByModal = (selectValue: CommonObject) => {
    setValues(old => ({
      ...old,
      HRK_DPT_CD: selectValue.DPT_CD,
      HRK_DPT_NM: selectValue.DPT_NM,
    }));
    setModalOpen(false);
  };

  const removeUpper = (rowType: number) => {
    return {
      ...values,
      ROWTYPE: rowType,
      HRK_DPT_CD: null,
      HRK_DPT_NM: null,
    };
  };

  const handleSaveFormData = () =>
    saveFormData({
      formData: values,
      dataIndex: currentIndex,
      setTableData: setDepartmentCodeTableData,
      compareKey: 'DPT_CD',
      upperKey: 'HRK_DPT_NM',
      removeUpper,
    });

  useEffect(() => {
    setValues(currentData.data);
  }, [currentData]);

  return (
    <FrameEditBox
      title='부서현황'
      onClick={handleSaveFormData}
    >
      <EditFieldRow className='border-t'>
        <FrameEditField title='부서코드'>
          <FrameEditInput
            name='DPT_CD'
            value={values?.DPT_CD || ''}
            onChange={e => editValue(e)}
            type='text'
            readOnly={values?.ROWTYPE !== RowType.ADD}
          />
        </FrameEditField>
        <FrameEditField title='부서명'>
          <FrameEditInput
            name='DPT_NM'
            value={values?.DPT_NM || ''}
            onChange={e => editValue(e)}
            type='text'
            required={true}
            className='required'
          />
        </FrameEditField>
        <FrameEditField title='상위부서'>
          <FrameEditInput
            name='HRK_DPT_NM'
            value={values?.HRK_DPT_NM || ''}
            onChange={e => editValue(e)}
            type='text'
          />
          <ModalButton openModal={setModalOpen} />
        </FrameEditField>
        <FrameEditField title='정렬순서'>
          <FrameEditInput
            name='SRT_SQN'
            value={values?.SRT_SQN || ''}
            onChange={e => editValue(e)}
            type='text'
          />
        </FrameEditField>
      </EditFieldRow>
      <EditFieldRow>
        <FrameEditField title='부서구분'>
          <FrameEditSelect
            name='DPT_DTT_CD'
            options={options}
            value={values?.DPT_DTT_CD || ''}
            onChange={editValue}
            required={true}
            className='required'
          />
        </FrameEditField>
        <FrameEditField title='등록일자'>
          <FrameEditInput
            name='REG_YMD'
            value={values?.REG_YMD || ''}
            onChange={e => editValue(e)}
            type='date'
            required={true}
            className='required'
          />
        </FrameEditField>
        <FrameEditField title='폐기일자'>
          <FrameEditInput
            name='DUS_YMD'
            value={values?.DUS_YMD || ''}
            onChange={e => editValue(e)}
            type='date'
          />
        </FrameEditField>
        <FrameEditField title='사용여부'>
          <FrameEditInput
            name='USE_YN'
            type='checkbox'
            value={values?.USE_YN}
            checked={values?.USE_YN === 'Y'}
            onChange={editValue}
          />
        </FrameEditField>
      </EditFieldRow>
      <EditFieldRow>
        <FrameEditField title='비고'>
          <FrameEditInput
            name='RMK_CONT'
            value={values?.RMK_CONT || ''}
            onChange={e => editValue(e)}
            type='text'
            width={800}
          />
        </FrameEditField>
      </EditFieldRow>
      {modalOpen ? (
        <Modal
          onClose={() => setModalOpen(false)}
          param={DEPARTMENT_PARAM}
          handleSelect={selectValueByModal}
        />
      ) : null}
    </FrameEditBox>
  );
};

export default AIBI010M01Form;
