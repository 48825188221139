import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchChildMenu } from 'models';
import { client } from 'utils';

function childMenuList({
  searchData,
  options,
}: IQueryParams<ISearchChildMenu>) {
  return useQuery({
    queryKey: ['system_setting', 'child_menu', searchData],
    queryFn: async () => {
      const response = await client.get('/api/co/cm/150m01/menu', {
        params: searchData,
      });

      return response.data.menuList || [];
    },
    ...options,
  });
}

export default function useChildMenuList(searchData: ISearchChildMenu) {
  return childMenuList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: searchData.DMN_CD !== '' && searchData.HRK_MNU_ID !== '',
    },
  });
}
