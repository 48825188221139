import { FrameInfo, FrameInfoSelect } from 'components';
import { FrameContent } from 'styles';
import TEG08Table from './TEG08Table';
import { useHeight, useSearchComboList } from 'hooks';
import TEG08Plot from './TEG08Plot';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { plotState, selectState } from 'store';
import {
  CustomChangeEvent,
  defaultMesureType,
  defaultSearchPlot,
} from 'models';
import { editValues } from 'utils';

const searchRecoilKey = 'plotManagement';

const TEG08 = (): JSX.Element => {
  const getHeight = useHeight();
  const frameActive =
    useRecoilValue(selectState.selectSideMenu).PGM_ID === 'UIE008TEG08';
  const [searchPlot, setSearchPlot] = useRecoilState(
    plotState.searchPlot(searchRecoilKey),
  );
  const [values, setValues] = useState(searchPlot);
  const projectOptions = useSearchComboList(
    { CMB_CD: frameActive ? 'CMB0029' : '' },
    true,
  );
  const processOptions = useSearchComboList(
    {
      CMB_CD: frameActive && values.PRJ_NO ? 'CMB0030' : '',
      SRCH_PARAM: values.PRJ_NO,
    },
    true,
  );
  const deviceOptions = useSearchComboList(
    {
      CMB_CD: frameActive && values.PRC_NO ? 'CMB0031' : '',
      SRCH_PARAM: values.PRC_NO,
    },
    true,
  );
  const designOptions = useSearchComboList(
    {
      CMB_CD: frameActive && values.DVC_NO ? 'CMB0036' : '',
      SRCH_PARAM: values.DVC_NO,
    },
    true,
  );

  const editValue = (e: CustomChangeEvent, name?: string) => {
    editValues(e, setValues);
    if (name === 'PRJ_NO')
      setValues(prev => ({
        ...prev,
        PRC_NO: '',
        DVC_NO: '',
        DSG_ID: '',
      }));
    if (name === 'PRC_NO')
      setValues(prev => ({
        ...prev,
        DVC_NO: '',
        DSG_ID: '',
      }));
    if (name === 'DVC_NO')
      setValues(prev => ({
        ...prev,
        DSG_ID: '',
      }));
  };

  useEffect(() => {
    if (values.DSG_ID) setSearchPlot(values);
    else setSearchPlot(defaultSearchPlot);
  }, [values]);

  useEffect(() => {
    if (frameActive) setValues(searchPlot);
  }, [frameActive]);

  return (
    <>
      <FrameInfo>
        <div className='search-form'>
          <div className='input-block'>
            <FrameInfoSelect
              title='프로젝트'
              options={projectOptions}
              name='PRJ_NO'
              value={values.PRJ_NO}
              onChange={e => editValue(e, 'PRJ_NO')}
            />
            <FrameInfoSelect
              title='공정'
              options={processOptions}
              name='PRC_NO'
              value={values.PRC_NO}
              onChange={e => editValue(e, 'PRC_NO')}
            />
            <FrameInfoSelect
              title='디바이스'
              options={deviceOptions}
              name='DVC_NO'
              value={values.DVC_NO}
              onChange={e => editValue(e, 'DVC_NO')}
            />
            <FrameInfoSelect
              title='디자인'
              options={designOptions}
              name='DSG_ID'
              value={values.DSG_ID}
              onChange={editValue}
            />
            <FrameInfoSelect
              title='측정'
              options={defaultMesureType}
              name='MSR_TYPE'
              value={values.MSR_TYPE}
              onChange={editValue}
            />
          </div>
        </div>
      </FrameInfo>
      <FrameContent>
        <div className='flex flex-col gap-y-2 w-full'>
          <TEG08Table
            tableHeight={getHeight(400)}
            recoilKey={searchRecoilKey}
          />
          <TEG08Plot recoilKey={searchRecoilKey} />
        </div>
      </FrameContent>
    </>
  );
};

export default TEG08;
