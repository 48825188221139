import { CommonArray, defaultCurrentData } from 'models';
import {
  defaultSearchDirectory,
  defaultSearchFile,
} from 'models/defaults/privateBbsDefaults';
import { atom } from 'recoil';

const searchDirectory = atom({
  key: 'searchDirectory',
  default: defaultSearchDirectory,
});
const searchFile = atom({
  key: 'searchFile',
  default: defaultSearchFile,
});

const privateDirectory = atom<CommonArray>({
  key: 'privateDirectory',
  default: [],
});

const currentDir = atom({
  key: 'currentDir',
  default: defaultCurrentData,
});

const currentFile = atom({
  key: 'currentFile',
  default: defaultCurrentData,
});

const privateFile = atom<CommonArray>({
  key: 'privateFile',
  default: [],
});

export const privateBbsState = {
  searchDirectory,
  searchFile,
  privateDirectory,
  currentDir,
  currentFile,
  privateFile,
};
