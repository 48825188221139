import {
  FrameEditBox,
  FrameEditField,
  FrameEditInput,
  FrameEditSelect,
} from 'components';
import FrameEditTextArea from 'components/frame/FrameEditTextArea';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { editValues, saveFormData } from 'utils';
import { useSearchComboList } from 'hooks';
import { formManagementState } from 'store';
import { CustomChangeEvent, RowType } from 'models';
import { EditFieldRow } from 'styles';

const simpleTextHeight = 25;
const autoSearchOption = [
  { label: '사용', value: '1' },
  { label: '사용하지 않음', value: '0' },
];
const conditionOption = [
  { label: '사용', value: '1' },
  { label: '사용하지 않음', value: '0' },
];

const COCM220M01Form = ({ height }: { height: number }): JSX.Element => {
  const currentPopup = useRecoilValue(formManagementState.currentPopup);
  const setSearchPopupList = useSetRecoilState(
    formManagementState.searchPopupList,
  );
  const systemOptions = useSearchComboList({ CMB_CD: 'CMB0015' });
  const [values, setValues] = useState(currentPopup.data);
  const currentIndex = currentPopup.index;

  const editValue = (e: CustomChangeEvent) => editValues(e, setValues);
  const handleSaveForm = () => {
    saveFormData({
      formData: values,
      dataIndex: currentIndex,
      setTableData: setSearchPopupList,
      compareKey: 'SRCH_PPU_CD',
    });
  };

  useEffect(() => {
    if (currentPopup) {
      setValues(currentPopup.data);
    }
  }, [currentPopup]);

  return (
    <FrameEditBox
      title='팝업 정보'
      height={height}
      onClick={handleSaveForm}
    >
      <EditFieldRow className='border-t'>
        <FrameEditField title='검색팝업코드'>
          <FrameEditInput
            type='text'
            name='SRCH_PPU_CD'
            value={values?.SRCH_PPU_CD || ''}
            onChange={editValue}
            required={true}
            readOnly={values?.ROWTYPE !== RowType.ADD}
          />
        </FrameEditField>
        <FrameEditField title='팝업명'>
          <FrameEditInput
            type='text'
            name='PPU_NM'
            value={values?.PPU_NM || ''}
            onChange={editValue}
            required={true}
          />
        </FrameEditField>
        <FrameEditField title='시스템구분'>
          <FrameEditSelect
            name='SYS_DTT_CD'
            value={values?.SYS_DTT_CD || ''}
            onChange={editValue}
            options={systemOptions}
          />
        </FrameEditField>
      </EditFieldRow>
      <EditFieldRow>
        <FrameEditField title='검색팝업내용'>
          <FrameEditInput
            type='text'
            name='PPU_CONT'
            value={values?.PPU_CONT || ''}
            onChange={editValue}
            width={600}
            required={true}
          />
        </FrameEditField>
        <FrameEditField title='관리자명'>
          <FrameEditInput
            type='text'
            name='MNGR_NM'
            value={values?.MNGR_NM || ''}
            onChange={editValue}
          />
        </FrameEditField>
      </EditFieldRow>
      <EditFieldRow>
        <FrameEditField title='자동조회여부'>
          <FrameEditSelect
            name='AT_INQ_YN'
            value={values?.AT_INQ_YN || ''}
            onChange={editValue}
            required={true}
            options={autoSearchOption}
          />
        </FrameEditField>
        <FrameEditField title='조건 필수입력여부'>
          <FrameEditSelect
            name='SRCH_CND_ESN_YN'
            value={values?.SRCH_CND_ESN_YN || ''}
            onChange={editValue}
            required={true}
            options={conditionOption}
          />
        </FrameEditField>
      </EditFieldRow>
      <EditFieldRow>
        <FrameEditTextArea
          title='컬럼 정의'
          name='GRID_CONT'
          value={values?.GRID_CONT || ''}
          onChange={editValue}
          height={100}
          required={true}
        />
      </EditFieldRow>
      <EditFieldRow>
        <FrameEditTextArea
          title='선택(Select) 구문'
          name='INQ_TGT_SNTX_CONT'
          value={values?.INQ_TGT_SNTX_CONT || ''}
          onChange={editValue}
          height={100}
          required={true}
        />
      </EditFieldRow>
      <EditFieldRow>
        <FrameEditTextArea
          title='테이블 구문'
          name='INQ_TBL_SNTX_CONT'
          value={values?.INQ_TBL_SNTX_CONT || ''}
          onChange={editValue}
          height={simpleTextHeight}
        />
      </EditFieldRow>
      <EditFieldRow>
        <FrameEditTextArea
          title='조건(Where) 구문'
          name='INQ_CND_SNTX_CONT'
          value={values?.INQ_CND_SNTX_CONT || ''}
          onChange={editValue}
          height={100}
          required={true}
        />
      </EditFieldRow>
      <EditFieldRow>
        <FrameEditTextArea
          title='추가조건 구문'
          name='CMB_COLM_SNTX_CONT'
          value={values?.CMB_COLM_SNTX_CONT || ''}
          onChange={editValue}
          height={simpleTextHeight}
        />
      </EditFieldRow>
      <EditFieldRow>
        <FrameEditTextArea
          title='사용여부 구문'
          name='USE_YN_SNTX_CONT'
          value={values?.USE_YN_SNTX_CONT || ''}
          onChange={editValue}
          height={simpleTextHeight}
        />
      </EditFieldRow>
      <EditFieldRow>
        <FrameEditTextArea
          title='추가 구문'
          name='APN_CND_SNTX_CONT'
          value={values?.APN_CND_SNTX_CONT || ''}
          onChange={editValue}
          height={simpleTextHeight}
        />
      </EditFieldRow>
      <EditFieldRow>
        <FrameEditTextArea
          title='정렬구문'
          name='SRT_SNTX_CONT'
          value={values?.SRT_SNTX_CONT || ''}
          onChange={editValue}
          height={simpleTextHeight}
        />
      </EditFieldRow>
      <EditFieldRow className='flex-grow'>
        <FrameEditTextArea
          title='검색콤보 설정'
          name='SRCH_CMB_CONT'
          value={values?.SRCH_CMB_CONT || ''}
          onChange={editValue}
          required={true}
        />
      </EditFieldRow>
    </FrameEditBox>
  );
};

export default COCM220M01Form;
