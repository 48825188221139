import { MainLogo } from 'assets';
import { TARGET_WIDTH } from 'utils';

const HeaderLogo = (): JSX.Element => {
  return (
    <div
      className='logo-box'
      style={{ width: TARGET_WIDTH }}
    >
      <a href='/'>
        <img
          src={MainLogo}
          alt='main logo'
        />
      </a>
    </div>
  );
};

export default HeaderLogo;
