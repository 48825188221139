import { ITableCellProps } from 'models';
import { useRef, useState } from 'react';
import CheckInputCell from './CheckInputCell';
import { useTableContext } from 'hooks';

const CheckInputWithToolTipCell = ({
  getValue,
  table,
  row,
  column,
  rowRef,
}: ITableCellProps) => {
  const tableContext = useTableContext();
  const [showTooltip, setShowTooltip] = useState(false);
  const timerId = useRef<number | undefined>(undefined);

  const handleMouseEnter = () => {
    timerId.current = window.setTimeout(() => setShowTooltip(true), 1000);
  };

  const handleMouseLeave = () => {
    if (timerId !== undefined) {
      window.clearTimeout(timerId.current);
      timerId.current = undefined;
    }
    setShowTooltip(false);
  };
  const eventHandlers =
    tableContext.handleToolTipText?.(row.original, column.id) !== undefined
      ? {
          onMouseEnter: handleMouseEnter,
          onMouseLeave: handleMouseLeave,
        }
      : {};

  return (
    <>
      <div
        className='flex justify-center items-center w-full h-full'
        {...eventHandlers}
      >
        <CheckInputCell
          column={column}
          row={row}
          table={table}
          getValue={getValue}
          rowRef={rowRef}
        />
      </div>
      {showTooltip && (
        <div className='absolute border border-black bg-white translate-x-[30px] translate-y-[10px] z-10'>
          {tableContext.handleToolTipText?.(row.original, column.id)}
        </div>
      )}
    </>
  );
};

export default CheckInputWithToolTipCell;
