import {
  CommonArray,
  defaultCurrentData,
  defaultSearchCodeType,
  defaultSearchCommonCodeTable,
} from 'models';
import { atom, atomFamily } from 'recoil';

const searchCodeType = atomFamily({
  key: 'searchCodeType',
  default: defaultSearchCodeType,
});

const searchCommonCodeTable = atom({
  key: 'searchCommonCodeTable',
  default: defaultSearchCommonCodeTable,
});

const currentCodeType = atom({
  key: 'currentCodeType',
  default: defaultCurrentData,
});

const codeTypeList = atom<CommonArray>({
  key: 'codeTypeList',
  default: [],
});

const commonCodeList = atom<CommonArray>({
  key: 'commonCodeList',
  default: [],
});

const currentCommonCode = atom({
  key: 'currentCommonCode',
  default: defaultCurrentData,
});

export const codeManagementState = {
  searchCodeType,
  searchCommonCodeTable,
  currentCodeType,
  codeTypeList,
  commonCodeList,
  currentCommonCode,
};
