import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { CheckInputCell, ReadOnlyTextCell, Table } from 'components';
import { useTableData } from 'hooks';
import { Columns, CommonObject, ITableContext } from 'models';
import { useEffect, useMemo, useRef } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';
import { designState } from 'store';
import { TableContext, getAlertMessage } from 'utils';

const columnHelper = createColumnHelper();

const TEG06DesignPlotTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const [currentData, setCurrentData] = useRecoilState(
    designState.currentPlotInfoData,
  );
  const plotInfo = useRecoilValue(designState.plotInfo);
  const plotInfoRef = useRef(plotInfo);
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: plotInfo,
    atom: designState.plotInfo,
    effect: () => {
      if (plotInfo.length > 0) {
        setInitialData(plotInfo);
        setCurrentData({ index: 0, data: plotInfo[0] });
      }
    },
  });
  useEffect(() => {
    let flagCnt = 0;
    initialData.map(data => {
      if (data.TOTAL_R === 'Y') {
        flagCnt += 1;
      }
      if (data.TOTAL_F === 'Y') {
        flagCnt += 1;
      }
      if (data.TOTAL_D === 'Y') {
        flagCnt += 1;
      }
      if (data.TREND_R === 'Y') {
        flagCnt += 1;
      }
      if (data.TREND_F === 'Y') {
        flagCnt += 1;
      }
      if (data.TREND_D === 'Y') {
        flagCnt += 1;
      }
    });
    if (flagCnt > 4) {
      toast.warning(getAlertMessage('ERRO_00250', ['4건']));
      setInitialData(plotInfoRef.current);
    }
    plotInfoRef.current = initialData;
  }, [initialData]);

  const defaultColumns = [
    columnHelper.accessor('div', {
      cell: ReadOnlyTextCell,
      header: '구분',
      size: 50,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.group({
      header: 'Total 플롯',
      columns: [
        columnHelper.accessor('TOTAL_R', {
          header: 'Reverse',
          cell: CheckInputCell,
          size: 110,
        }) as ColumnDef<unknown, any>,
        columnHelper.accessor('TOTAL_F', {
          header: 'Forward',
          cell: CheckInputCell,
          size: 110,
        }) as ColumnDef<unknown, any>,
        columnHelper.accessor('TOTAL_D', {
          header: 'DC',
          cell: CheckInputCell,
          size: 110,
        }) as ColumnDef<unknown, any>,
      ],
    }),
    columnHelper.group({
      header: 'Trend 플롯',
      columns: [
        columnHelper.accessor('TREND_R', {
          header: 'Reverse',
          cell: CheckInputCell,
          size: 110,
        }) as ColumnDef<unknown, any>,
        columnHelper.accessor('TREND_F', {
          header: 'Forward',
          cell: CheckInputCell,
          size: 110,
        }) as ColumnDef<unknown, any>,
        columnHelper.accessor('TREND_D', {
          header: 'DC',
          cell: CheckInputCell,
          size: 110,
        }) as ColumnDef<unknown, any>,
      ],
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const contextValue: ITableContext = {
    currentData,
    setCurrentData,
  };
  return (
    <TableContext.Provider value={contextValue}>
      {initialData && (
        <Table
          title='분석문서 플롯'
          initialData={initialData}
          setData={setInitialData}
          columns={columns}
          tableHeight={tableHeight}
          showToggle={false}
        />
      )}
    </TableContext.Provider>
  );
};

export default TEG06DesignPlotTable;
