import {
  CustomChangeEvent,
  CustomMouseEvent,
  IHeaderUserInfoBoxProps,
} from 'models';
import { CommonButton } from 'components';
import SidePasswordField from './SidePasswordField';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { loginState } from 'store';
import useRenewMutation from 'apis/mutations/useRenewMutation';
import { toast } from 'react-toastify';

const SidePasswordForm = ({
  openInfo: openRenew,
  setOpenInfo: setOpenRenew,
}: IHeaderUserInfoBoxProps): JSX.Element => {
  const userInfo = useRecoilValue(loginState.userInfo);
  const renew = useRenewMutation(setOpenRenew);
  const [password, setPassword] = useState('');

  const handleRenew = () => {
    if (password.length === 0) {
      toast.warning('비밀번호를 입력해주세요.');
      return;
    }
    renew.mutate({ USR_ID: userInfo.USR_ID, PWD: password });
  };

  const handleOpenRenew = (e: CustomMouseEvent) => {
    e.stopPropagation();
    setOpenRenew(false);
  };

  const editValue = (e: CustomChangeEvent) => {
    setPassword(e.target.value);
  };

  useEffect(() => {
    setPassword('');
  }, [openRenew]);

  return (
    <form>
      <input
        type='text'
        name='USR_ID'
        autoComplete='username'
        value=''
        readOnly
        className='hidden'
        aria-hidden='true'
      />
      <SidePasswordField
        name='PWD'
        placeholder='비밀번호'
        autoComplete='new-password'
        onChange={editValue}
        value={password}
      />
      <div className='flex justify-end'>
        <CommonButton
          title='입력'
          onClick={handleRenew}
        />
        <CommonButton
          title='취소'
          type='button'
          color='#dc2626'
          onClick={handleOpenRenew}
        />
      </div>
    </form>
  );
};

export default SidePasswordForm;
