import { ISideMenuCategory } from 'models/interfaces/menuInterface';

export const sideMenuCategory: ISideMenuCategory[] = [
  {
    id: 'system',
    name: '시스템 메뉴',
    class: 'left-menu',
    active: 'left-active',
  },
  {
    id: 'my',
    name: '마이 메뉴',
    class: 'right-menu',
    active: 'right-active',
  },
];
