import { createColumnHelper } from '@tanstack/react-table';
import { ReadOnlyTextCell, Table } from 'components';
import { useTableData } from 'hooks';
import { Columns, CommonArray, CommonObject, ITableContext } from 'models';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DataTableContainer } from 'styles';
import { TableContext, addRowNumber } from 'utils';
import { privateBbsState } from 'store/privateBbsAtoms';
import usePrivateDirectoryList from 'apis/queries/usePrivateDirectoryList';
import COCM900M01Modal from './COCM900M01Modal';

const columnHelper = createColumnHelper();

export const formatDate = (dateString: string) => {
  const year = dateString.slice(0, 4);
  const month = dateString.slice(4, 6);
  const day = dateString.slice(6, 8);
  return `${year}-${month}-${day}`;
};
export const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const COCM900M01DirectoryTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const [openModal, setOpenModal] = useState(false);
  const searchDirectory = useRecoilValue(privateBbsState.searchDirectory);
  const [searchFile, setSearchFile] = useRecoilState(
    privateBbsState.searchFile,
  );
  const { data: directoryData } = usePrivateDirectoryList(
    searchDirectory,
  ) as CommonObject;
  const [currentData, setCurrentData] = useRecoilState(
    privateBbsState.currentDir,
  );

  const handleDoubleClick = () => {
    setOpenModal(true);
  };

  const dateTrim = (dataList: CommonArray): CommonArray => {
    return dataList.map(item => ({
      ...item,
      DIR_NAME: formatDate(item.DIR_NAME),
    }));
  };

  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: directoryData,
    atom: privateBbsState.privateDirectory,
    effect: () => {
      const newData = addRowNumber(directoryData, true);
      const sOutData = dateTrim(newData);
      setInitialData(sOutData);
      setCurrentData({ index: 0, data: sOutData[0] });
    },
  });
  useEffect(() => {
    setSearchFile({
      DIR_NAME: currentData.data?.DIR_NAME,
    });
  }, [currentData]);

  const defaultColumns = [
    columnHelper.accessor('no', {
      header: 'No',
      cell: ReadOnlyTextCell,
      size: 30,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('DIR_NAME', {
      header: '제목',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;
  const handleAddData = () => {
    setCurrentData(old => ({
      ...old,
      data: {
        DIR_NAME: getTodayDate(),
      },
    }));
    setOpenModal(true);
  };
  const contextValue: ITableContext = {
    handleAddData,
    handleDoubleClick,
    currentData,
    setCurrentData,
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        {initialData && (
          <Table
            title='질의응답'
            initialData={initialData}
            backupData={directoryData}
            setData={setInitialData}
            columns={columns}
            editable={true}
            tableHeight={tableHeight}
          />
        )}
      </TableContext.Provider>
      {openModal && (
        <COCM900M01Modal
          onClose={() => setOpenModal(false)}
          currentDirectory={searchFile.DIR_NAME}
        />
      )}
    </DataTableContainer>
  );
};

export default COCM900M01DirectoryTable;
