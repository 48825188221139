import { useEffect, useState } from 'react';

import { FrameInfo, FrameInfoInput } from 'components';
import { CustomChangeEvent } from 'models';
import { useRecoilState } from 'recoil';
import { privateBbsState } from 'store/privateBbsAtoms';
import { FrameContent } from 'styles';
import { useHeight } from 'hooks';
import { ISearchPrivateDirectory } from 'models/interfaces/privateBbsInterface';
import COCM900M01DirectoryTable from './COCM900M01DirectoryTable';

const COCM900M01 = (): JSX.Element => {
  const getHeight = useHeight();
  const [searchDirectory, setSearchDirectory] = useRecoilState(
    privateBbsState.searchDirectory,
  );
  const [values, setValues] =
    useState<ISearchPrivateDirectory>(searchDirectory);

  const editValue = (e: CustomChangeEvent) => {
    setValues(old => ({
      ...old,
      DIR_SEARCH: e.target.value,
    }));
  };
  useEffect(() => {
    setSearchDirectory(values);
  }, [values]);

  return (
    <>
      <FrameInfo>
        <div className='search-form'>
          <div className='input-block'>
            <div>
              <FrameInfoInput
                title='등록 일자'
                children=''
                name='DIR_SEARCH'
                type='date'
                value={values.DIR_SEARCH}
                onChange={editValue}
              />
            </div>
          </div>
        </div>
      </FrameInfo>
      <FrameContent>
        <div className='grow-col-between'>
          <COCM900M01DirectoryTable tableHeight={getHeight(650)} />
        </div>
      </FrameContent>
    </>
  );
};

export default COCM900M01;
