import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchModule } from 'models';
import { client } from 'utils';

function deviceDutList({
  searchData,
  options,
}: IQueryParams<ISearchModule>) {
  return useQuery({
    queryKey: ['module', 'device', 'dut', searchData],
    queryFn: async () => {
      const response = await client.get('/api/teg/04/modal/dut/sub', {
        params: searchData,
      });
      const dutList = response.data.dutList;
      console.log('좌표 정보', dutList);
      const xKeys = Array.from(
        { length: 12 },
        (_, i) => `CRD_X_${String(i + 1).padStart(2, '0')}`,
      );
      const yKeys = Array.from(
        { length: 12 },
        (_, i) => `CRD_Y_${String(i + 1).padStart(2, '0')}`,
      );
      const keys = [...xKeys, ...yKeys];

      dutList.map((dut: any) => {
        keys.forEach(key => {
          if (!(key in dut)) {
            dut[key] = 0;
          }
        });
        return dut;
      });
      return dutList;
    },
    ...options,
  });
}

export default function useDeviceDutList(searchData: ISearchModule) {
  return deviceDutList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: searchData.DVC_NO !== '',
    },
  });
}
