import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchDocument } from 'models';
import { client } from 'utils';

function documentList({
  searchData,
  options,
}: IQueryParams<ISearchDocument>) {
  return useQuery({
    queryKey: ['doc', 'list', searchData],
    queryFn: async () => {
      const response = await client.get('/api/teg/09/pdk', {
        params: searchData,
      });
      return response.data.docList;
    },
    ...options,
  });
}

export default function useDocumentList(searchData: ISearchDocument) {
  return documentList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
