import { createColumnHelper } from '@tanstack/react-table';
import {
  CommonButton,
  DisplayButton,
  ReadOnlyTextCell,
  StatusCell,
  StatusIcon,
  Table,
} from 'components';
import { useHeight, useTableData } from 'hooks';
import {
  Columns,
  CommonArray,
  CommonObject,
  CustomMouseEvent,
  ITableContext,
  RowType,
} from 'models';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ModalInner, ModalOverlay, ModalWrapper } from 'styles';
import { TableContext } from 'utils';
import usePrivateUploadMutation from 'apis/mutations/usePrivateUploadMutation';
import usePrivateFileDeleteMutation from 'apis/mutations/usePrivateFileDeleteMutation';
import usePrivateFileList from 'apis/queries/usePrivateFileList';
import { privateBbsState } from 'store/privateBbsAtoms';
import { useCommonFileDownloadMutation } from 'apis';

const formatFileSize = (size: number, depth: number): string => {
  if (size < 1024 || depth === 3) {
    const units = ['B', 'KB', 'MB', 'GB'];
    return `${parseFloat(size.toFixed(2))} ${units[depth]}`;
  } else {
    return formatFileSize(size / 1024, depth + 1);
  }
};
const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);

  return `${year}-${month}-${day}`;
};
const convertToYYYYMMDD = (dateString: string) => {
  // yyyyMMdd 포맷인지 확인
  const yyyyMMddRegex = /^\d{8}$/;

  if (yyyyMMddRegex.test(dateString)) {
    // yyyyMMdd 포맷인 경우 그대로 반환
    return dateString;
  } else {
    // yyyy-MM-dd 포맷인 경우 yyyyMMdd 포맷으로 변환
    const [year, month, day] = dateString.split('-');
    return `${year}${month.padStart(2, '0')}${day.padStart(2, '0')}`;
  }
};

const columnStdOut = (dataList: CommonArray): CommonArray => {
  return dataList.map(item => ({
    ...item,
    MDATE: formatDate(item.MDATE),
    FL_SZ_VAL: formatFileSize(item.FL_SZ_VAL, 0),
  }));
};

const columnHelper = createColumnHelper();
const COCM900M01Modal = ({
  onClose,
  currentDirectory,
}: {
  onClose: () => void;
  currentDirectory: string;
}): JSX.Element => {
  const onBbsClick = (e: CustomMouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  const getHeight = useHeight();

  // recoil
  const searchFile = useRecoilValue(privateBbsState.searchFile);
  const [currentData, setCurrentData] = useRecoilState(
    privateBbsState.currentFile,
  );
  // mutation
  const uploadMutation = usePrivateUploadMutation();
  const downloadMutation = useCommonFileDownloadMutation();
  const fileDeleteMutation = usePrivateFileDeleteMutation();

  // query
  const { data: fileData } = usePrivateFileList(
    searchFile,
  ) as CommonObject;
  // state
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: fileData,
    atom: privateBbsState.privateFile,
    effect: () => {
      const sOutData = columnStdOut(fileData);
      setInitialData(sOutData);
      setCurrentData({ index: 0, data: sOutData[0] });
    },
  });
  // function
  const handleFilesSelected = (files: File[]) => {
    setSelectedFiles(files);
    addItemsToList(files);
  };
  const addItemsToList = (newItems: File[]) => {
    const transformList = newItems.map(item => ({
      FL_NM: item.name,
      FL_SZ_VAL: item.size,
      MDATE: new Date(),
      STATUS: 'FILE',
      ROWTYPE: RowType.ADD,
    }));
    const sOutData = columnStdOut(transformList) as CommonObject[];
    const backUp = initialData.filter(
      file => file.ROWTYPE !== RowType.ADD,
    );
    setInitialData([...sOutData, ...backUp]);
  };
  const removeItemsToList = (itemName: string, rowType: number) => {
    if (rowType === RowType.NORMAL) {
      setInitialData(current => {
        return current.map(item => {
          if (item.FL_NM === itemName && item.ROWTYPE === rowType) {
            return { ...item, ROWTYPE: RowType.DELETE };
          }
          return item; // 조건에 맞지 않는 경우 원본 유지
        });
      });
    } else if (rowType === RowType.ADD) {
      setInitialData(prev =>
        prev.filter(
          file => !(file.FL_NM === itemName && file.ROWTYPE === rowType),
        ),
      );
      setSelectedFiles(prev =>
        prev.filter(file => file.name !== itemName),
      );
    }
  };
  const handleSaveData = () => {
    const fileFlag = selectedFiles.length > 0;
    const checkFile = (data: CommonObject[]) => {
      const deleteList = data.filter(
        file => file.ROWTYPE === RowType.DELETE,
      );
      return deleteList;
    };
    const targetFile = checkFile(initialData);
    formDataSave(fileFlag);
    fileDeleteMutation.mutate({
      DIR_NAME: currentDirectory,
      FILE_LIST: targetFile,
    });
  };
  const formDataSave = (flag: boolean) => {
    if (!flag) {
      return;
    }
    const form = new FormData();
    selectedFiles.forEach(file => {
      form.append('FILES', file);
    });
    const fileWrapper = {
      FILES: form,
      DIR_NAME: currentDirectory,
    };
    setUploadSuccess(true);
    uploadMutation.mutate(fileWrapper);
  };
  const downloadFile = (a: any) => {
    const dirName = convertToYYYYMMDD(currentDirectory);
    const downloadUrl = {
      URL: `/fileSample/${dirName}/${a.FL_NM}`,
    };
    downloadMutation.mutate(downloadUrl);
  };
  const deleteFile = (row: any) => {
    removeItemsToList(row.FL_NM, row.ROWTYPE);
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.target.files;
    if (!files) {
      return;
    }
    const newSelectedFiles = [...selectedFiles, ...Array.from(files)];
    setSelectedFiles(newSelectedFiles);
    handleFilesSelected(newSelectedFiles);
  };

  const resetFileInput = () => {
    setSelectedFiles([]);
  };
  useEffect(() => {
    if (uploadSuccess) {
      resetFileInput();
      setUploadSuccess(false);
    }
  }, [uploadSuccess]);

  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 25,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('FL_NM', {
      header: '제목',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('FL_SZ_VAL', {
      header: '크기',
      cell: ReadOnlyTextCell,
      size: 50,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('MDATE', {
      header: '작성일',
      cell: ReadOnlyTextCell,
      size: 50,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.display({
      header: '다운로드',
      size: 60,
      cell: DisplayButton,
      meta: {
        text: '다운로드',
        onClick: (row: CommonObject) => downloadFile(row),
      },
    }),
    columnHelper.display({
      header: '삭제',
      size: 60,
      cell: DisplayButton,
      meta: {
        text: '삭제',
        onClick: (row: CommonObject) => deleteFile(row),
      },
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const contextValue: ITableContext = {
    currentData,
    setCurrentData,
  };

  return (
    <>
      <ModalOverlay />
      <ModalWrapper
        onClick={onBbsClick}
        tabIndex={-1}
      >
        <ModalInner
          tabIndex={0}
          className='w-[1000px] h-[800px]'
        >
          <div className='flex justify-end mt-2'>
            <input
              type='file'
              multiple
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={handleFileChange}
            />
            <CommonButton
              title='추가'
              onClick={handleUploadButtonClick}
            />
          </div>
          <TableContext.Provider value={contextValue}>
            {initialData && (
              <Table
                title='첨부파일'
                initialData={initialData}
                backupData={fileData}
                setData={setInitialData}
                columns={columns}
                editable={false}
                showToggle={false}
                tableHeight={getHeight(560)}
              />
            )}
          </TableContext.Provider>
          <div className='flex justify-center mt-2'>
            <CommonButton
              title='저장'
              onClick={handleSaveData}
            />
            <CommonButton
              title='닫기'
              color='darkred'
              onClick={onBbsClick}
            />
          </div>
        </ModalInner>
      </ModalWrapper>
    </>
  );
};

export default COCM900M01Modal;
