import { useEffect, useState } from 'react';
import { BiCaretLeft, BiSearch } from 'react-icons/bi';
import { flexRender } from '@tanstack/react-table';
import HeaderFilter from '../utils/HeaderFilter';
import { DataTableHeader } from 'styles';
import { IColumnMeta, ITableHeaderProps } from 'models';

const headerHeight = 25;

const TableHeader = ({
  header,
  tableMeta,
}: ITableHeaderProps): JSX.Element => {
  const { column } = header;
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const canFilter = column.getCanFilter();
  const columnMeta = column.columnDef.meta as IColumnMeta;
  const isStatusCell = column.id === 'status';
  const isRequired = columnMeta?.required;
  const colSpan = columnMeta?.colSpan || 1;

  useEffect(() => {
    if (!canFilter) {
      setOpenFilter(false);
      column.setFilterValue(null);
    }
  }, [canFilter]);

  return (
    <>
      <DataTableHeader
        style={{ width: header.getSize() }}
        $height={isStatusCell ? headerHeight * header.rowSpan : undefined}
        rowSpan={header.rowSpan}
        colSpan={columnMeta?.colSpan ? colSpan : header.colSpan}
      >
        {isRequired && <BiCaretLeft className='required-icon' />}
        <div className='content-box'>
          {header.isPlaceholder
            ? null
            : flexRender(column.columnDef.header, header.getContext())}
          {canFilter ? (
            <div className='filter-box'>
              <BiSearch
                className='filter-icon'
                onClick={() => setOpenFilter(old => !old)}
              />
            </div>
          ) : null}
        </div>
        {canFilter && openFilter ? (
          <HeaderFilter
            header={header}
            tableMeta={tableMeta}
          />
        ) : null}
      </DataTableHeader>
    </>
  );
};

export default TableHeader;
