import { isEqual } from 'utils';
import { memo } from 'react';
import { CommonObject, IColumnMeta, ITableCellProps } from 'models';
import { DataTableValueBox } from 'styles';
import { useImage } from 'apis';

const ImageCell = ({ getValue, column }: ITableCellProps): JSX.Element => {
  const value = getValue();
  const columnMeta: IColumnMeta | undefined = column.columnDef.meta;
  const justify = columnMeta?.justify;

  const { data: imageUrl } = useImage(value) as CommonObject;

  return (
    <DataTableValueBox
      $justify={justify}
      onCopy={e => e.stopPropagation()}
      onMouseDown={e => e.preventDefault()}
    >
      <img
        src={imageUrl}
        className='h-[240px] object-contain'
      />
    </DataTableValueBox>
  );
};

export default memo(ImageCell, isEqual);
