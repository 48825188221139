import { createColumnHelper } from '@tanstack/react-table';
import {
  useChapterFileUploadMutation,
  useChapterKeyValueList,
  useChapterKeyValueMutation,
  useCommonFileDownloadMutation,
  useWordDocument,
} from 'apis';
import {
  FileUpload,
  FileUploadModal,
  SelectCell,
  StatusCell,
  StatusIcon,
  Table,
  TextCell,
  UploadCell,
} from 'components';
import { useSearchComboList, useTableData } from 'hooks';
import {
  Columns,
  CommonObject,
  CurrentData,
  IChapterFileUploadParam,
  IChapterKeyValue,
  ITableContext,
  RowType,
  defaultChapterKeyValue,
  defaultCurrentData,
} from 'models';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { documentState } from 'store';
import { DataTableContainer } from 'styles';
import {
  GUIDE_HEIGHT,
  GUIDE_WIDTH,
  TableContext,
  addRowData,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';

const colunmHelper = createColumnHelper();
const newChapterKeyValue = defaultChapterKeyValue;

const TEG10ValueTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const currentChapter = useRecoilValue(
    documentState.currentDocumentContent,
  );
  const currentChapterKey = useRecoilValue(
    documentState.currentChapterKey,
  );
  const searchChapterKeyValue = useRecoilValue(
    documentState.searchChapterKeyValue,
  );
  const searchChpaterKey = useRecoilValue(documentState.searchChapterKey);
  const [selectFile, setSelectFile] = useState<File>();
  const chapterKeyValueMutation = useChapterKeyValueMutation('value');
  const fileUploadMutation = useChapterFileUploadMutation('value');
  const fileDownloadMutation = useCommonFileDownloadMutation();
  const wordDocumentMutation = useWordDocument();
  const layerOptions = useSearchComboList({ CMB_CD: 'CMB0041' });
  const documentVopList = useRecoilValue(documentState.documentVopList);
  const [imageUrl, setImageUrl] = useState('');
  const { data: valueData } = useChapterKeyValueList({
    DOC_NO: currentChapter?.data?.DOC_NO,
    CH_NO: currentChapter?.data?.CH_NO,
    CHR_SNO: currentChapterKey?.data?.CHR_SNO,
    KEY: currentChapterKey?.data?.KEY,
  }) as CommonObject;

  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: valueData?.valueList,
  });
  const [currentData, setCurrentData] = useState(defaultCurrentData);
  const isKeyType = (type: string) =>
    valueData && currentChapterKey?.data?.KEY_TYPE.includes(type);
  const isKey = (key: string) =>
    valueData && currentChapterKey?.data?.KEY.includes(key);
  const isArray =
    valueData && currentChapterKey?.data?.KEY.includes('arr');
  const getValueOptions = () => {
    let options = [];

    if (!valueData) return [];

    if (isKey('dvc')) {
      options = valueData?.deviceList.map((device: CommonObject) => ({
        label: device.DVC_NM,
        value: device.DVC_NO,
      }));
    } else if (isKey('layer')) {
      options = layerOptions;
    } else if (isKey('dsg')) {
      options = valueData?.designList.map((message: CommonObject) => ({
        label: message.VALUE,
        value: message.CODE,
      }));
    } else if (isKey('vop')) {
      options = documentVopList.map(vop => ({
        label: vop.VALUE,
        value: vop.CODE,
      }));
    } else {
      options = valueData?.messageList.map((message: CommonObject) => ({
        label: message.VALUE,
        value: message.VALUE,
      }));
    }

    return [{ label: '선택', value: '' }, ...options];
  };

  const defaultColumns = [
    colunmHelper.display({
      id: 'status',
      header: () => <StatusIcon />,
      size: 30,
      cell: StatusCell,
    }),
    colunmHelper.accessor('VALUE', {
      header: '내용',
      size: 900,
      cell: isKeyType('text')
        ? TextCell
        : isKeyType('combo')
          ? SelectCell
          : isKeyType('image')
            ? UploadCell
            : undefined,
      meta: {
        options: getValueOptions(),
        onClick: () => setOpenModal(true),
      },
    }),
  ];
  const columns = useMemo(
    () => defaultColumns,
    [valueData, currentChapterKey],
  ) as Columns;

  const handleAddData = () => {
    const updatedData = {
      ...newChapterKeyValue,
      ...currentChapterKey.data,
      ROWTYPE: RowType.ADD,
    };
    addRowData(
      setInitialData,
      setCurrentData,
      updatedData,
      initialData.length,
    );
  };

  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);

    if (removeRowData.length > 0) {
      setInitialData(removedData);
      selectNextDataByRemove(
        selectData,
        initialData.length - 1,
        removedData,
        setCurrentData,
      );
    }
  };

  const handleSaveData = () => {
    const checkKeys = ['VALUE'];
    const targetData = saveTableData(initialData, checkKeys, false);

    if (targetData.length > 0) {
      if (isKey('text')) {
        const escapeData = targetData.map(data => {
          const value = data.VALUE.replace(/([,{}])/g, '\\$1');
          return { ...data, VALUE: value };
        });
        chapterKeyValueMutation.mutate(escapeData as IChapterKeyValue[]);
      } else {
        chapterKeyValueMutation.mutate(targetData as IChapterKeyValue[]);
      }
    } else {
      setInitialData(initialData);
    }
  };

  const handleGuide = () => {
    const { CH_NO } = searchChpaterKey;
    const url = window.location.origin + `/#/guide?CH_NO=${CH_NO}`;
    console.log('teg10ValueTable - CH_NO : ', CH_NO);
    console.log('teg10ValueTable - url : ', url);
    window.open(
      url,
      '_blank',
      `width=${GUIDE_WIDTH},height=${GUIDE_HEIGHT}`,
    );
  };

  const handleWordDocument = () => {
    const wordParam = {
      DOC_NO: searchChpaterKey.DOC_NO,
      DOC_TYPE: searchChpaterKey.DOC_TYPE,
      CHR_NO: searchChpaterKey.CH_NO,
    };
    wordDocumentMutation.mutate(wordParam);
  };

  const handleChage = (file: File) => {
    setSelectFile(file);
  };

  const handleUpload = () => {
    if (selectFile) {
      const imageInfo = {
        DOC_NO: searchChapterKeyValue.DOC_NO,
        DOC_TYPE: searchChapterKeyValue.DOC_TYPE,
        CHR_SNO: searchChapterKeyValue.CHR_SNO,
        KEY: searchChapterKeyValue.KEY,
        EXT: selectFile.name.split('.')[1],
        IMG_SNO: 0,
      };
      const uploadParam = {
        image: selectFile,
        imageInfo,
      };

      fileUploadMutation.mutate(uploadParam as IChapterFileUploadParam);
    }
  };

  const handleFileDownload = () => {
    fileDownloadMutation.mutate({ URL: imageUrl });
  };

  const handleWordDownload = () => {
    const { CH_NM } = currentChapter.data;
    const { DOC_TYPE, DOC_NO } = searchChpaterKey;
    const fileUrl = `/DOC/${DOC_TYPE}/${String(DOC_NO).padStart(9, '0')}/${CH_NM.replace(' ', '_')}.docx`;
    fileDownloadMutation.mutate({ URL: fileUrl });
  };

  useEffect(() => {
    if (isKeyType('image')) {
      const { DOC_TYPE, DOC_NO, CHR_SNO } = searchChapterKeyValue;
      const { KEY } = currentChapterKey.data;
      const newUrl = `/DOC/${DOC_TYPE}/${String(DOC_NO).padStart(9, '0')}/${String(CHR_SNO).padStart(9, '0')}/${KEY}.png`;
      setImageUrl(() => newUrl);
    }
  }, [currentChapterKey, fileUploadMutation]);

  const contextValue: ITableContext = {
    handleAddData,
    handleRemoveData,
    handleSaveData,
    handleWordDocument,
    handleWordDownload,
    handleGuide,
    currentData,
    setCurrentData,
    openModal: setOpenModal,
    customDisabled: {
      add: !isArray && initialData.length === 1,
      guide: searchChapterKeyValue.CH_NO === undefined,
      word: currentChapter?.data?.RMK_CONT !== 'W',
    },
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        <Table
          title='Value'
          initialData={initialData}
          backupData={valueData?.valueList}
          setData={setInitialData}
          columns={columns}
          tableHeight={tableHeight}
          showToggle={false}
        />
      </TableContext.Provider>
      {openModal && (
        <FileUploadModal
          onClose={() => setOpenModal(false)}
          maskCloseable={true}
        >
          <FileUpload
            title='figure'
            isEmpty={currentChapterKey.data.FLAG !== 1}
            handleChange={handleChage}
            handleUpload={handleUpload}
            handleDownload={handleFileDownload}
            imageUrl={imageUrl}
          />
        </FileUploadModal>
      )}
    </DataTableContainer>
  );
};

export default TEG10ValueTable;
