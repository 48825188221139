import { DefaultValue, atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { selectState } from './selectAtoms';
import { HEADER_LEVEL } from 'utils';
import { ICommonMenu, ISideMenu } from 'models';

const { persistAtom } = recoilPersist({
  key: 'recoil-persist',
  storage: sessionStorage,
});

const appMenu = atom<ICommonMenu[]>({
  key: 'appMenu',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

const headerMenu = atom<ICommonMenu[]>({
  key: 'headerMenu',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

const sideMenu = atom<ISideMenu[]>({
  key: 'sideMenu',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

const myMenu = atom<ICommonMenu[]>({
  key: 'myMenu',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

const menuSeparator = selector<ISideMenu[]>({
  key: 'appMenuSeparator',
  get: () => {
    return [];
  },
  set: ({ set }, menus) => {
    if (menus instanceof DefaultValue) return;

    const newHeaderMenu = menus.filter(
      menu => menu.LEVEL === HEADER_LEVEL,
    );
    const newSideMenu = menus.filter(
      menu => menu.LEVEL > HEADER_LEVEL && menu.MNU_IDCT_YN === '1',
    );

    set(appMenu, menus);
    set(headerMenu, newHeaderMenu);
    set(sideMenu, newSideMenu);
  },
});

const sideMenuByheader = selector({
  key: 'sideMenuByheader',
  get: ({ get }) => {
    const selectHeaderMenu = get(selectState.selectHeaderMenu);
    const sideMenuState = get(sideMenu);

    return (
      sideMenuState &&
      sideMenuState.filter(menu => menu.PATH.includes(selectHeaderMenu))
    );
  },
});

const sideMenuByMenu = selector({
  key: 'sideMenuByMenu',
  get: ({ get }): ISideMenu[] => {
    const selectMenu = get(selectState.selectMenu);
    const sideMenu =
      selectMenu === 'system' ? get(sideMenuByheader) : get(myMenu);
    return sideMenu as ISideMenu[];
  },
});

const tabMenu = atom<ISideMenu[]>({
  key: 'tabMenu',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

const wholeFrameData = selector({
  key: 'wholeFrameData',
  get: () => {
    const wholeData = {
      RCA01: null,
    };
    return wholeData;
  },
});

export const menuState = {
  headerMenu,
  sideMenu,
  sideMenuByheader,
  tabMenu,
  myMenu,
  sideMenuByMenu,
  appMenu,
  menuSeparator,
  wholeFrameData,
};
