import { useHeight, useSearchComboList } from 'hooks';
import { useEffect, useState } from 'react';
import {
  CommonButton,
  FrameInfo,
  FrameInfoInput,
  FrameInfoSelect,
} from 'components';
import { useRecoilState } from 'recoil';
import COCM120M01TypeTable from './COCM120M01TypeTable';
import COCM120M01CodeTable from './COCM120M01CodeTable';
import { editValues } from 'utils';
import { FrameContent } from 'styles';
import { codeManagementState } from 'store';
import { CustomChangeEvent } from 'models';

const COCM120M01 = (): JSX.Element => {
  const getHeight = useHeight();
  const [searchType, setSearchType] = useRecoilState(
    codeManagementState.searchCodeType('COCM120M01'),
  );
  const [values, setValues] = useState(searchType);
  const combinedOptions = useSearchComboList({ CMB_CD: 'CMB0015' }, true);
  const editValue = (e: CustomChangeEvent) => editValues(e, setValues);

  const handleSearchType = () => {
    setSearchType(values);
  };
  useEffect(() => {
    setSearchType(values);
  }, [values]);

  return (
    <>
      <FrameInfo>
        <div className='search-form'>
          <div className='input-block'>
            <FrameInfoSelect
              title='시스템구분'
              options={combinedOptions}
              name='SYS_DTT_CD'
              onChange={editValue}
            />
            <FrameInfoInput
              title='코드유형'
              type='text'
              name='TP_CD'
              value={values?.TP_CD || ''}
              onChange={editValue}
            />
            <FrameInfoInput
              title='코드유형명'
              type='text'
              name='TP_CD_NM'
              value={values?.TP_CD_NM || ''}
              onChange={editValue}
            />
          </div>
        </div>
        <div>
          <CommonButton
            title='조회'
            onClick={handleSearchType}
          />
        </div>
      </FrameInfo>
      <FrameContent>
        <div className='w-[400px]'>
          <COCM120M01TypeTable tableHeight={getHeight(650)} />
        </div>
        <div className='grow-col-between'>
          <COCM120M01CodeTable tableHeight={getHeight(650)} />
        </div>
      </FrameContent>
    </>
  );
};

export default COCM120M01;
