import { CustomMouseEvent, RowType } from 'models';
import { ModalInner, ModalOverlay, ModalWrapper } from 'styles';
import { useHeight } from 'hooks';
import { CommonButton } from 'components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { projectState } from 'store';
import TEG01ParentProcessTableInModal from './TEG01ParentProcessTableInModal';
import TEG01ProcessTableInModal from './TEG01ProcessTableInModal';
import { toast } from 'react-toastify';
import { getAlertMessage } from 'utils';

const TEG01ProcessModal = ({
  onClose,
}: {
  onClose: () => void;
}): JSX.Element => {
  const getHeight = useHeight();
  const currentProcessData = useRecoilValue(
    projectState.currentProcessData,
  );
  const [processInitialData, setProcessInitialData] = useRecoilState(
    projectState.processList,
  );
  const currentParentProcessData = useRecoilValue(
    projectState.currentParentProcessData,
  );
  const currentProcessDataInModal = useRecoilValue(
    projectState.currentProcessDataInModal,
  );
  const selectProcess = () => {
    const parentProcessCode = currentParentProcessData?.data?.PRS_CD_NM;
    const processCode = currentProcessDataInModal?.data?.PRS_CD_NM;
    if (parentProcessCode === undefined) {
      toast.warning(getAlertMessage('ERRO_00280', ['대공정 코드']));
      return;
    }
    if (processCode === undefined) {
      toast.warning(getAlertMessage('ERRO_00280', ['중공정 코드']));
      return;
    }
    const newData = processInitialData.map((row, index) => {
      if (currentProcessData.index === index) {
        const newPRS_CD12 = parentProcessCode + ' / ' + processCode;
        if (
          processInitialData[index].ROWTYPE !== RowType.ADD &&
          row.PRS_CD12 !== newPRS_CD12
        ) {
          return {
            ...row,
            PRS_CD12: newPRS_CD12,
            ROWTYPE: RowType.UPDATE,
          };
        }
        return {
          ...row,
          PRS_CD12: newPRS_CD12,
        };
      }
      return row;
    });
    setProcessInitialData(newData);
    onClose();
  };
  const close = (e: CustomMouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  return (
    <>
      <ModalOverlay />
      <ModalWrapper
        onClick={close}
        tabIndex={-1}
      >
        <ModalInner
          tabIndex={0}
          title='공정선택'
          className='w-[650px] h-[800px] flex justify-center'
        >
          <div className='w-[600px] space-y-2'>
            <div className='flex gap-x-5'>
              <TEG01ParentProcessTableInModal
                tableHeight={getHeight(680)}
              />
              <TEG01ProcessTableInModal tableHeight={getHeight(680)} />
            </div>
            <div className='w-full flex justify-end'>
              <CommonButton
                title='선택'
                onClick={selectProcess}
              ></CommonButton>
              <CommonButton
                title='닫기'
                onClick={onClose}
              ></CommonButton>
            </div>
          </div>
        </ModalInner>
      </ModalWrapper>
    </>
  );
};

export default TEG01ProcessModal;
