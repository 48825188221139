import {
  IModule,
  ISearchModule,
  ISearchModuleDoc,
  ISearchModuleDutWrapper,
} from 'models/interfaces/moduleInterface';
import { RowType } from 'models/types/commonTypes';

export const defaultSearchModule: ISearchModule = {
  PRJ_NO: '',
  PRC_NO: '',
  DVC_NO: '',
};

export const defaultSearchModuleDut: ISearchModuleDutWrapper = {
  dutSearch: { DVC_NO: '', DSG_ID: '' },
  moduleList: [],
};

export const defaultModule: IModule = {
  DVC_NO: 0,
  MDL_ID: 0,
  ID: 'M01',
  DUT1: null,
  DUT2: null,
  DUT3: null,
  DUT4: null,
  DUT5: null,
  DUT6: null,
  DUT7: null,
  DUT8: null,
  DUT9: null,
  DUT10: null,
  DUT11: null,
  DUT12: null,
  RMK_CNT: '',
  ROWTYPE: RowType.ADD,
};

export const defaultModuleDoc: ISearchModuleDoc = {
  PRJ_NO: '',
  DVC_NO: '',
  FLAG: true,
};
