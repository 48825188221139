import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IChapterKeyValue } from 'models';
import { client } from 'utils';

function chapterKeyValueMutation(
  options: UseMutationOptions<unknown, Error, IChapterKeyValue[], unknown>,
) {
  return useMutation({
    mutationFn: async (chapterKeyValueList: IChapterKeyValue[]) => {
      const response = await client.post(
        '/api/teg/09/doc/value',
        chapterKeyValueList,
      );
      return response;
    },
    ...options,
  });
}

export default function useChapterKeyValueMutation(type: string) {
  const queryClient = useQueryClient();

  return chapterKeyValueMutation({
    onSuccess: () => {
      if (type === 'device') {
        queryClient.invalidateQueries({
          queryKey: ['document', 'design_rule', 'device'],
        });
      } else {
        queryClient.invalidateQueries({
          queryKey: ['document', 'chapter_key'],
        });
      }
    },
  });
}
