import {
  ISearchPrivateDirectory,
  ISearchPrivateFile,
} from 'models/interfaces/privateBbsInterface';

export const defaultSearchDirectory: ISearchPrivateDirectory = {
  DIR_SEARCH: '',
};

export const defaultSearchFile: ISearchPrivateFile = {
  DIR_NAME: '',
};
