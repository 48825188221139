import tw from 'twin.macro';
import styled from 'styled-components';

export const DataTableContainer = styled.div`
  ${tw`
    w-full
  `}
`;

// Table.jsx Style start
export const DataTableTopBox = styled.div`
  ${tw`
    w-full
    bg-white
    flex
    items-center
  `}
`;

export const DataTableScrollBox = styled.div`
  ${tw`
    w-full
    overflow-y-scroll
    border
    border-slate-200
    transform
    duration-200
  `}

  .empty-box {
    ${tw`
      text-xs
      w-full
      h-full
      flex
      justify-center
      items-center
    `}
  }
`;

export const DataTable = styled.table`
  ${tw`
    w-full
    border-separate
    border-spacing-0
  `}

  & thead {
    ${tw`
      sticky
      top-0
      z-10
      border
      bg-slate-50
    `}
  }

  tbody {
    ${tw`
      text-xs
    `}
  }

  & th,
  td {
    ${tw`
    border-r
    border-b
    border-slate-200

    last:border-r-0
    `}
  }
`;

export const DataTableCell = styled.td`
  ${tw`
    h-[25px]
  `}

  .cell-box {
    ${tw`
      flex
      h-full
      overflow-hidden
      whitespace-nowrap
      items-center
      justify-start
    `}
  }
  & svg {
    ${tw`
      w-[15px]
      h-[15px]
    `}
  }
  &.draged-cell {
    ${tw`
      bg-blue-300
    `}
  }
  button::selection,
  div::selection {
    background-color: transparent;
  }
  input:not(:focus)::selection,
  textarea:not(:focus)::selection {
    background-color: transparent;
  }
`;
// Table.jsx Style end

// TableHeader.jsx Style start
export const DataTableHeader = styled.th<{ $height?: number }>`
  ${tw`
    relative
    font-semibold
    pl-1
    text-[0.8rem]
  `}
  height: ${({ $height }) => $height || 30}px;

  .content-box {
    ${tw`
      relative
      flex
      items-center
      justify-center
      text-blue-800
      gap-x-1
      m-auto
    `}
  }

  .required-icon {
    ${tw`
      absolute
      top-[-3px]
      left-[-3px]
      w-[15px]
      h-[15px]
      m-0
      p-0
      rotate-45
      text-red-600
    `}
  }

  .filter-icon {
    ${tw`
      p-0
      m-0
      w-[15px]
      h-[15px]
    `}
  }

  .filter-box {
    ${tw`
      rounded-[50%]
      bg-white
      p-[3px]
      hover:bg-blue-800
      hover:text-white
      cursor-pointer
    `}
  }
`;
// TableHeader.jsx Style end

// TableCell.jsx Style start
export const DataTableInput = styled.input`
  ${tw`
    w-[90%]
    py-0.5
    pl-1
    outline-none
    transform
    duration-150
    bg-transparent

    invalid:bg-red-50
    invalid:border-red-500
    invalid:border

    read-only:focus:border-none
    read-only:focus:bg-transparent

    focus:invalid:border-red-500
    focus:bg-white
    focus:border
    focus:border-slate-300
    `}
`;

export const DataTableSelect = styled.select`
  ${tw`
    bg-slate-50
    w-full
    border
    border-slate-300
    outline-none
    m-auto
  `}
`;

export const DataTableCheckbox = styled.input`
  ${tw`
    w-[0.8rem]
    h-[0.8rem]
    rounded-lg
    border
    border-slate-400
    outline-none
    cursor-pointer
    m-auto
  `}
`;

export const DataTableButton = styled.button`
  ${tw`
    border
    border-slate-300
    bg-white
    text-center
    text-[0.8rem]
    px-0.5
    cursor-pointer
    transform
    duration-100
    whitespace-nowrap

    hover:bg-blue-800
    hover:text-white
    
    disabled:bg-slate-200
    disabled:opacity-80
    disabled:text-black
  `}
`;

export const DataTableValueBox = styled.div<{ $justify?: string }>`
  ${tw`
    w-full
    h-full
    whitespace-nowrap
    overflow-hidden
    flex
    items-center
    px-1
    gap-x-1
  `}
  justify-content: ${({ $justify }) => $justify};
`;

export const TreeValueBox = styled.div`
  ${tw`
    h-full
    whitespace-nowrap
    flex
    items-center
    px-1
    gap-x-1
  `}
`;

export const ToolMatchingBox = styled.div`
  ${tw`
    rounded-[50%]
    w-[20px]
    h-[20px]
    shadow-md
    m-auto
  `}
`;
// TableCell.jsx Style end

export const TreeCellBorderBox = styled.div`
  ${tw`
    w-[10px]
    h-full
  `}

  div {
    height: 50%;
  }

  .border-box {
    ${tw`
      border-b
      border-l
      border-dotted
      border-slate-500
    `}
  }
`;
