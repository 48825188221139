import { useQuery } from '@tanstack/react-query';
import { CommonObject, IQueryParams } from 'models';
import { client } from 'utils';

function deviceDocumentsList({
  searchData,
  options,
}: IQueryParams<CommonObject>) {
  return useQuery({
    queryKey: ['tegDescription', 'check', searchData],
    queryFn: async () => {
      const response = await client.post(
        '/api/teg/03/doc/teg/check',
        searchData,
      );
      const { fileList, fileExist } = response.data;
      return { fileList, fileExist };
    },
    ...options,
  });
}

export default function useDeviceDescriptionCheckList(
  searchData: CommonObject,
) {
  return deviceDocumentsList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: true,
    },
  });
}
