import { useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { ReadOnlyTextCell, Table } from 'components';
import { TableContext, addRowNumber, exportJsonToSheet } from 'utils';
import { useRecoilValue } from 'recoil';
import { useInfiniteScroll, useTableData } from 'hooks';
import COCM210M01Modal from './COCM210M01Modal';
import { historyManagementState } from 'store';
import {
  Columns,
  CommonObject,
  CurrentData,
  ISearchErrorLog,
  ITableContext,
  defaultCurrentData,
} from 'models';
import { useErrorLogList } from 'apis';
import { DataTableContainer } from 'styles';

const columnHelper = createColumnHelper();

const COCM210M01Table = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const [openModal, setOpenModal] = useState(false);
  const searchErrorLog = useRecoilValue(
    historyManagementState.searchErrorLog,
  );
  const [errorLogData, fetchErrorLogData] =
    useInfiniteScroll<ISearchErrorLog>(
      useErrorLogList,
      searchErrorLog,
      300,
    );

  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: errorLogData,
    effect: () => {
      const newData = addRowNumber(errorLogData);
      setInitialData(newData);
    },
  });
  const [currentData, setCurrentData] =
    useState<CurrentData>(defaultCurrentData);

  const defaultColumns = [
    columnHelper.accessor('no', {
      header: 'No',
      cell: ReadOnlyTextCell,
      size: 30,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('PGM_ID', {
      header: '프로그램 ID',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 100,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('PGM_NM', {
      header: '프로그램명',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 150,
    }),
    columnHelper.accessor('ERR_TP_NM', {
      header: '오류유형',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 130,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('CL_MTH_NM', {
      header: '호출메서드명',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 150,
    }),
    columnHelper.accessor('CL_VRB_CONT', {
      header: '호출변수내용',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 200,
    }),
    columnHelper.accessor('USR_ID', {
      header: '사용자 ID',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 100,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('USR_USR_NM', {
      header: '사용자명',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 100,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('CL_IP_ADDR', {
      header: '호출 IP주소',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 100,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('LOG_OCC_DATE', {
      header: '발생일시',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 130,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('ERR_LOG_CONT', {
      header: '오류내용',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 350,
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const handleDoubleClick = () => {
    setOpenModal(true);
  };

  const contextValue: ITableContext = {
    handleExport: () =>
      exportJsonToSheet(columns, initialData, '오류로그 현황'),
    handleDoubleClick,
    currentData,
    setCurrentData,
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        <Table
          title='오류로그'
          initialData={initialData}
          setData={setInitialData}
          fetchScroll={fetchErrorLogData}
          columns={columns}
          tableHeight={tableHeight}
        />
      </TableContext.Provider>
      {openModal && (
        <COCM210M01Modal
          onClose={() => setOpenModal(false)}
          currentData={currentData?.data}
        />
      )}
    </DataTableContainer>
  );
};

export default COCM210M01Table;
