import { useRecoilValue } from 'recoil';
import { GoBookmark, GoBookmarkFill } from 'react-icons/go';
import { ReactNode } from 'react';
import { FrameInfoBox } from 'styles/frameStyles';
import { ICommonMenu, IFavoriteMenu, RowType } from 'models';
import { menuState, selectState } from 'store';
import { useSaveMyMenuMutation } from 'apis';

interface IFrameInfoProps {
  children: ReactNode;
}

const FrameInfo = ({ children }: IFrameInfoProps): JSX.Element => {
  const selectSideMenu = useRecoilValue(selectState.selectSideMenu);
  const appMenu = useRecoilValue(menuState.appMenu);
  const myMenu = useRecoilValue(menuState.myMenu);
  const saveMyMenu = useSaveMyMenuMutation();

  const namePath =
    selectSideMenu.PATH ??
    appMenu.find(
      (menu: ICommonMenu) => menu.MNU_ID === selectSideMenu.MNU_ID,
    )?.PATH;

  const isSameMenu = (targetMenu: ICommonMenu) => {
    return selectSideMenu.MNU_ID === targetMenu.MNU_ID;
  };

  const isMyMenu = () => {
    return myMenu.find(
      (menu: ICommonMenu) => menu.MNU_ID === selectSideMenu.MNU_ID,
    );
  };

  const handleMymenu = () => {
    const targetMenu: IFavoriteMenu = {
      MNU_ID: selectSideMenu.MNU_ID,
      FAVR_YN: isMyMenu() ? 'N' : 'Y',
      ROWTYPE: isMyMenu() ? RowType.DELETE : RowType.ADD,
    };

    saveMyMenu.mutate(targetMenu);
  };

  return (
    <FrameInfoBox>
      <div className='title-box'>
        <div className='title-box'>
          {isMyMenu() ? (
            <GoBookmarkFill onClick={handleMymenu} />
          ) : (
            <GoBookmark onClick={handleMymenu} />
          )}
          <span className='title'>{selectSideMenu.MNU_NM}</span>
        </div>
        <div>
          {appMenu &&
            appMenu.map((menu: ICommonMenu) =>
              namePath.includes(menu.MNU_ID) ? (
                <span
                  className={`text-[0.7rem] ${
                    isSameMenu(menu)
                      ? 'text-blue-400 font-semibold'
                      : 'text-slate-400'
                  }`}
                  key={`${menu.MNU_NM}_${menu.MNU_ID}`}
                >
                  {isSameMenu(menu) ? menu.MNU_NM : `${menu.MNU_NM} > `}
                </span>
              ) : null,
            )}
        </div>
      </div>
      <div className='search-box'>{children}</div>
    </FrameInfoBox>
  );
};

export default FrameInfo;
