import { FrameContent } from 'styles';
import { CommonButton, FrameInfo, RefreshSearch } from 'components';
import FrameInfoSelect from 'components/frame/FrameInfoSelect';
import TEG01ProjectTable from './TEG01ProjectTable';
import TEG01ProcessTable from './TEG01ProcessTable';
import FrameInfoInput from 'components/frame/FrameInfoInput';
import { useHeight } from 'hooks';
import { useSearchComboList } from 'hooks';
import { CustomChangeEvent } from 'models';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { projectState } from 'store';
import { editValues } from 'utils';
import TEG01ProjectCopyModal from './TEG01ProjectCopyModal';
import TEG01ProcessModal from './TEG01ProcessModal';
import { defaultSearchProject } from 'models/defaults/projectDefaults';

const TEG01 = (): JSX.Element => {
  const getHeight = useHeight();
  const [processModalOpen, setProcessModalOpen] = useState(false);
  const [projectCopyModalOpen, setProjectCopyModalOpen] = useState(false);
  const [searchData, setSearchData] = useRecoilState(
    projectState.searchData('projectTable'),
  );
  const [values, setValues] = useState(searchData);
  const parentPrsOptions = useSearchComboList({ CMB_CD: 'CMB0007' }, true);
  const maskOptions = useSearchComboList({ CMB_CD: 'CMB0004' });
  const [parentProcessCode, setParentProcessCode] = useState('');
  const processOptions = useSearchComboList(
    {
      CMB_CD: 'CMB0008',
      SRCH_PARAM: parentProcessCode,
    },
    true,
  );
  const editValue = (e: CustomChangeEvent, codeType?: string) => {
    if (codeType == 'PRS_CD_01') {
      setParentProcessCode(e.target.value);
      setValues(old => ({
        ...old,
        ['PRS_CD_02']: '',
      }));
    }
    editValues(e, setValues);
    if (codeType) {
      setValues(old => ({
        ...old,
        [codeType]: e.target.value,
      }));
    }
  };
  const handleSearch = () => {
    setSearchData(values);
  };
  const handleRefresh = async () => {
    setSearchData(defaultSearchProject);
    setValues(defaultSearchProject);
  };
  return (
    <>
      <FrameInfo>
        <div className='search-form'>
          <div className='input-block'>
            <div>
              <FrameInfoInput
                title='시작 일자'
                children=''
                name='STR_DATE'
                type='date'
                value={values.STR_DATE}
                onChange={e => editValue(e, 'STR_DATE')}
              />
              <FrameInfoInput
                title='~'
                children=''
                name='END_DATE'
                type='date'
              />
            </div>
          </div>
          <div className='input-block'>
            <div className='flex gap-x-2'>
              <FrameInfoSelect
                title='대공정'
                options={parentPrsOptions}
                onChange={e => editValue(e, 'PRS_CD_01')}
                value={values.PRS_CD_01}
              />
              <FrameInfoSelect
                title='중공정'
                options={processOptions}
                onChange={e => editValue(e, 'PRS_CD_02')}
                value={values.PRS_CD_02}
              />
              <FrameInfoInput
                title='디바이스 명'
                type='text'
                name='DVC_NM'
                value={values.DVC_NM}
                onChange={editValue}
              />
              <div>
                <RefreshSearch onMouseDown={handleRefresh} />
                <CommonButton
                  title='조회'
                  onClick={handleSearch}
                />
              </div>
            </div>
          </div>
        </div>
      </FrameInfo>
      <FrameContent>
        <div className='w-full'>
          <div className='flex-between-2'>
            <div className='w-[3000px]'>
              <TEG01ProjectTable
                tableHeight={getHeight(700)}
                openProjectCopyModal={setProjectCopyModalOpen}
              ></TEG01ProjectTable>
            </div>
            <TEG01ProcessTable
              tableHeight={getHeight(700)}
              maskOptions={maskOptions}
              openModal={setProcessModalOpen}
            ></TEG01ProcessTable>
          </div>
        </div>
      </FrameContent>
      {processModalOpen && (
        <TEG01ProcessModal onClose={() => setProcessModalOpen(false)} />
      )}
      {projectCopyModalOpen && (
        <TEG01ProjectCopyModal
          onClose={() => setProjectCopyModalOpen(false)}
        />
      )}
    </>
  );
};

export default TEG01;
