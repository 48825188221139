import { useRecoilState } from 'recoil';
import TEG08Scale from './TEG08Scale';
import { plotState } from 'store';
import {
  CommonObject,
  CustomChangeEvent,
  CustomKeyboardEvent,
  IPlotParam,
  ISinglePlotParam,
  defaultSinglePlotParam,
} from 'models';
import { PlotBox } from 'styles';
import { useEffect, useState } from 'react';
import { combinedObjectsByKeys, editValues } from 'utils';
import { useSinglePlotVopList } from 'apis';

const { LEGEND_FIRST, LEGEND_SECOND } = defaultSinglePlotParam;

const TEG08SinglePlot = ({
  queryParam,
}: {
  queryParam: IPlotParam;
}): JSX.Element => {
  const [singlePlotParam, setSinglePlotParam] = useRecoilState(
    plotState.singlePlotParam,
  );
  const [updatedSinglePlot, setUpdatedSinglePlot] =
    useState<ISinglePlotParam>(singlePlotParam);
  const [legendData, setLegendData] = useState({
    LEGEND_FIRST,
    LEGEND_SECOND,
  });
  const { data: vopData } = useSinglePlotVopList({
    DVC_NO: queryParam.DVC_NO,
  }) as CommonObject;
  const isDC = queryParam.MSR_TYPE === 'DC';
  const paramString = Object.values(updatedSinglePlot).join(',');

  const editValue = (e: CustomChangeEvent) => editValues(e, setLegendData);

  const changeLegend = (e: CustomKeyboardEvent) => {
    if (e.key !== 'Enter') return;
    setSinglePlotParam((old: ISinglePlotParam) => ({
      ...old,
      LEGEND_FIRST: legendData.LEGEND_FIRST,
      LEGEND_SECOND: legendData.LEGEND_SECOND,
    }));
  };

  const handleSelect = (e: CustomChangeEvent) => {
    setSinglePlotParam((old: ISinglePlotParam) => ({
      ...old,
      VOP: e.target.value,
    }));
  };

  const handlePlotScale = (e: CustomChangeEvent) => {
    if ('checked' in e.target) {
      const name = e.target.name;
      const value = e.target.checked;

      setUpdatedSinglePlot((old: ISinglePlotParam) => ({
        ...old,
        [name]: value ? '1' : '0',
      }));
    }
  };

  useEffect(() => {
    if (singlePlotParam) {
      const combinedSinglePlot = combinedObjectsByKeys(
        singlePlotParam,
        queryParam,
      ) as ISinglePlotParam;
      const editedSinglePlot = isDC
        ? { ...combinedSinglePlot, LOG_LEFT: '1' }
        : { ...combinedSinglePlot, LOG_TOP: '1' };
      setUpdatedSinglePlot(editedSinglePlot);
    }
  }, [singlePlotParam]);

  return (
    <PlotBox $type>
      <div className='plot-area'>
        <div className='single-control'>
          <div className='control-div'>
            {isDC ? null : (
              <>
                <span>범례명</span>
                <input
                  type='text'
                  name='LEGEND_FIRST'
                  value={legendData.LEGEND_FIRST}
                  onChange={editValue}
                  spellCheck='false'
                  onKeyDown={changeLegend}
                />
                <input
                  type='text'
                  name='LEGEND_SECOND'
                  value={legendData.LEGEND_SECOND}
                  onChange={editValue}
                  spellCheck='false'
                  onKeyDown={changeLegend}
                />
              </>
            )}
          </div>
          <div className='control-div'>
            <span>VOP</span>
            <select
              className='w-[100px]'
              name='VOP'
              onChange={handleSelect}
            >
              {vopData &&
                vopData.map((vop: CommonObject) => (
                  <option
                    key={vop.CODE}
                    value={Number(vop.CODE)}
                  >
                    {vop.VALUE}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className='plot-content'>
          <iframe
            className='w-full h-full'
            id='totalPlot'
            src={`/py/plot-single?${paramString}`}
          />
        </div>
        <TEG08Scale
          type='single'
          isSingle
          isDC={isDC}
          plotValue={updatedSinglePlot}
          setPlotScale={handlePlotScale}
        />
      </div>
    </PlotBox>
  );
};

export default TEG08SinglePlot;
