import { STS_FLAG } from './constants';

/**
 * 트리 데이터의 특정 Key 값이 null 또는 ''인 경우 undifined를 반환하는 함수
 * @param {array} treeData 트리로 변환해야 할 데이터
 * @param {string} parentKey 검증 Key 값
 * @returns {array} 트리 데이터
 */
export function preConvertData(treeData, parentKey) {
  const convertData = treeData.map(tree => ({
    ...tree,
    [parentKey]:
      tree[parentKey] === null || tree[parentKey] === ''
        ? undefined
        : tree[parentKey],
  }));
  return convertData;
}

/**
 * 객체 배열을 매개변수로 전달받아 소속관계가 있는 데이터를 트리 형태로 반환하는 함수
 * @param {array} dataList 대상 데이터 배열
 * @param {string} parentKey 부모 코드
 * @param {string} targetKey 대상 코드
 * @param {string} targetId  하위 요소의 부모코드로 비교될 대상 데이터
 * @param {boolean} title 최상단 요소 판단 데이터(최초 실행 후 false)
 * @returns {array} 트리 데이터
 */
export function convertDataToTree(
  dataList,
  parentKey,
  targetKey,
  targetId = undefined,
  title = true,
) {
  return dataList
    .filter(data => data[parentKey] === targetId)
    .map(data => ({
      ...data,
      title: title,
      children: convertDataToTree(
        dataList,
        parentKey,
        targetKey,
        data[targetKey],
        false,
      ),
    }));
}

/**
 * useComboList 훅을 통해 반환받은 comboList를 Options 형태에 맞도록 변환하는 함수
 * @param {array} dataList useComboList의 반환 데이터
 * @returns {array} 코드와 네임을 value와 label로 변경한 배열
 */
export function convertComboToOptions(dataList) {
  const convertData = dataList.map(data => ({
    label: data.CMN_CD_NM,
    value: data.CMN_CD,
  }));

  return convertData;
}

/**
 * 서버로 전달받은 STS_FLAG에 대한 상태 코드를 텍스트로 변환하는 함수
 * @param {array} dataList 비동기 데이터
 * @returns {array} STS_FLAG를 텍스트로 전환한 배열
 */
export function convertStatus(dataList) {
  const convertData = dataList.map(learn => ({
    ...learn,
    STS_FLAG: STS_FLAG[learn.STS_FLAG],
  }));

  return convertData;
}

/**
 * 'yyyyMMdd' 형태의 날짜 데이터를 'yyyy-MM-dd' 형태로 반환하는 함수
 * @param {string} date string 날짜 데이터
 * @returns 'yyyy-MM-dd' 형태 날짜 데이터
 */
export function formatDateWithHyphen(date) {
  if (!date) return;

  const year = date.substring(0, 4);
  const month = date.substring(4, 6);
  const day = date.substring(6, 8);

  return `${year}-${month}-${day}`;
}

/**
 * baseObject를 대상으로 targetObject에 중첩된 key의 값을 덮어쓰기하여 반환하는 함수
 * @param {object} baseObject 덮어씌워질 객체
 * @param {object} targetObject 덮어씌울 데이터를 가진 객체
 * @returns {object} 데이터가 합쳐진 객체
 */
export const combinedObjectsByKeys = (baseObject, targetObject) => {
  const targetKeys = Object.keys(targetObject);

  const combinedObject = targetKeys.reduce((prev, currentKey) => {
    if (currentKey in baseObject) {
      prev[currentKey] = targetObject[currentKey];
    }
    return prev;
  }, {});

  return { ...baseObject, ...combinedObject };
};

/**
 * 매개변수로 전달받은 알파벳을 기준으로 26진법을 적용하여 다음 알파벳 심벌을 반환하는 함수
 * ex) Z -> AA, ZZ -> AAA
 * @param {String} symbol 알파벳 심벌 문자열
 * @returns 다음 알파벳 심벌 문자열
 */
export function nextSymbol(symbol) {
  const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const BASE26 = 26; // 26진법

  let num = symbol
    .split('')
    .reduce((acc, char) => BASE26 * acc + ALPHABET.indexOf(char) + 1, 0);
  num++;
  let newSymbol = '';
  while (num > 0) {
    num--;
    newSymbol = ALPHABET[num % BASE26] + newSymbol;
    num = Math.floor(num / BASE26);
  }
  return newSymbol;
}

/**
 * 액셀저장 시 SelectCell에 해당하는 데이터를 실제 보여지는 값(label)으로 전환하는 함수
 * @param {array} options SelectCell의 options 데이터
 * @param {object} rowData 테이블 데이터의 객체
 * @param {string} targetKey rowData의 변경할 key 값
 * @returns {string | number} targetKey에 해당하는 label 데이터
 */
export function convertValueToLabel(options, rowData, targetKey) {
  const option = options.find(
    option => option.value === rowData[targetKey],
  );
  return option ? option.label : '';
}

/**
 * 테이블 데이터 중 SelectCell로 지정된 KEY에 option을 매핑하여 데이터를 변경하는 함수
 * @param {array} rowData 테이블 데이터(ex: initialData)
 * @param {object} optionsMap key와 options 형태의 객체
 * @returns label값으로 치환된 데이터
 */
export function mapDataWithOptions(rowData, optionsMap) {
  return rowData.map(row => {
    const newRow = { ...row };
    Object.keys(optionsMap).forEach(key => {
      return (newRow[key] = convertValueToLabel(
        optionsMap[key],
        row,
        key,
      ));
    });
    return newRow;
  });
}

/**
 * 문자를 Html로 변환하는 함수
 * @param {string} text 변경할 문자
 * @returns Html 데이터
 */
export function convertStringToHtml(text) {
  return (
    <div>
      {text.split('\\n').map((txt, index) => (
        <div key={index}>
          {txt}
          <br />
        </div>
      ))}
    </div>
  );
}
