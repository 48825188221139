import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchCodeType } from 'models';
import { client } from 'utils';

function codeTypeList({
  searchData,
  options,
}: IQueryParams<ISearchCodeType>) {
  return useQuery({
    queryKey: ['code', 'code_type', searchData],
    queryFn: async () => {
      const response = await client.get('/api/co/cm/110m01/code/type', {
        params: searchData,
      });

      return response.data.commonCodeTypeList || [];
    },
    ...options,
  });
}

export default function useCodeTypeList(searchData: ISearchCodeType) {
  return codeTypeList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
