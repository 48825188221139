import { useHeight } from 'hooks';
import COCM160M01Info from './COCM160M01Info';
import { FrameContent } from 'styles';
import COCM160M01Table from './COCM160M01Table';

const COCM160M01 = (): JSX.Element => {
  const getHeight = useHeight();

  return (
    <>
      <COCM160M01Info />
      <FrameContent>
        <div className='grow-col-between'>
          <COCM160M01Table tableHeight={getHeight(530)} />
        </div>
      </FrameContent>
    </>
  );
};

export default COCM160M01;
