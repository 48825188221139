import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchMainProcess } from 'models';
import { client } from 'utils';

function mainProcessList({
  searchData,
  options,
}: IQueryParams<ISearchMainProcess>) {
  return useQuery({
    queryKey: ['code', 'process_code', searchData],
    queryFn: async () => {
      const response = await client.get('/api/co/cm/01/code', {
        params: searchData,
      });

      return response.data.codeList;
    },
    ...options,
  });
}

export default function useMainProcessCodeList(
  searchData: ISearchMainProcess,
) {
  return mainProcessList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
