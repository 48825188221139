import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchModule } from 'models';
import { client } from 'utils';

function moduleList({ searchData, options }: IQueryParams<ISearchModule>) {
  return useQuery({
    queryKey: ['module', searchData],
    queryFn: async () => {
      const response = await client.get('/api/teg/04/module', {
        params: searchData,
      });
      const { moduleList, designList, vopCodes, dutMoveList } =
        response.data;
      return {
        moduleList,
        designList,
        vopCodes,
        dutMoveList,
      };
    },
    ...options,
  });
}

export default function useModuleList(searchData: ISearchModule) {
  return moduleList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: searchData.DVC_NO !== '',
    },
  });
}
