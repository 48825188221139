import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Table, ReadOnlyTextCell } from 'components';
import { TableContext } from 'utils';
import { Columns, CommonObject } from 'models';
import { useRecoilState } from 'recoil';
import { useTableData } from 'hooks';
import { ITableContext } from 'models';
import { projectState } from 'store';
import useParentProcessList from 'apis/queries/useParentProcessList';

const columnHelper = createColumnHelper();

const TEG01ParentProcessTableInModal = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const { data: parentProcess } = useParentProcessList() as CommonObject;
  const [currentData, setCurrentData] = useRecoilState(
    projectState.currentParentProcessData,
  );
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: parentProcess,
    atom: projectState.parentProcessList,
    effect: () => {
      setInitialData(parentProcess);
      setCurrentData({ index: 0, data: parentProcess[0] });
    },
  });

  const defaultColumns = [
    columnHelper.accessor('PRS_CD_NM', {
      header: '대공정 코드',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const contextValue: ITableContext = {
    currentData,
    setCurrentData,
  };

  return (
    <TableContext.Provider value={contextValue}>
      {initialData && (
        <Table
          title='대공정'
          initialData={initialData}
          // backupData={parentProcess}
          setData={setInitialData}
          columns={columns}
          tableHeight={tableHeight}
        />
      )}
    </TableContext.Provider>
  );
};

export default TEG01ParentProcessTableInModal;
