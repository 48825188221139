import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IChapterFileUploadParam } from 'models';
import { client } from 'utils';

function chapterFileUploadMutation(
  options: UseMutationOptions<
    unknown,
    Error,
    IChapterFileUploadParam,
    unknown
  >,
) {
  return useMutation({
    mutationFn: async (uploadParam: IChapterFileUploadParam) => {
      const formData = new FormData();
      formData.append('image', uploadParam.image);
      formData.append('imageInfo', JSON.stringify(uploadParam.imageInfo));
      const response = await client.post(
        '/api/teg/09/doc/file/image/upload',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      );
      return response;
    },
    ...options,
  });
}

export default function useChapterFileUploadMutation(type: string) {
  const queryClient = useQueryClient();

  return chapterFileUploadMutation({
    onSuccess: () => {
      const queryKey =
        type === 'value'
          ? ['document', 'chapter_key']
          : ['document', 'chapter', 'appendix'];
      queryClient.invalidateQueries({
        queryKey: queryKey,
      });
      queryClient.invalidateQueries({
        queryKey: ['image'],
      });
    },
  });
}
