import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IMaskCode } from 'models/interfaces/maskInterface';
import { toast } from 'react-toastify';
import { client, getAlertMessage } from 'utils';

function codeMutation(
  options: UseMutationOptions<unknown, Error, IMaskCode[], unknown>,
) {
  return useMutation({
    mutationFn: async (codeList: IMaskCode[]) => {
      const response = await client.post('/api/teg/02/code', codeList);
      return response;
    },
    ...options,
  });
}

export default function useCodeMutation() {
  const queryClient = useQueryClient();

  return codeMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['mask', 'codeList'],
      });
      toast.success(getAlertMessage('INFO_00010'));
    },
  });
}
