import { useComboList } from 'apis';
import {
  CommonButton,
  FrameEditBox,
  FrameEditField,
  FrameEditInput,
  FrameEditSelect,
} from 'components';
import FrameEditTextArea from 'components/frame/FrameEditTextArea';
import { CommonObject, CustomChangeEvent, RowType } from 'models';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { formManagementState } from 'store';
import { EditFieldRow } from 'styles';
import {
  convertComboToOptions,
  editValues,
  getAlertMessage,
  saveFormData,
} from 'utils';

const simpleTextHeight = 120;

const COCM430M01Form = ({ height }: { height: number }): JSX.Element => {
  const currentCombo = useRecoilValue(
    formManagementState.currentSearchCombo,
  );
  const setSearchComboList = useSetRecoilState(
    formManagementState.searchComboList,
  );
  const [values, setValues] = useState(currentCombo.data);
  const { data: testOptionData } = useComboList({
    CMB_CD: currentCombo.data?.CMB_CD,
  }) as CommonObject;
  const [testOptions, setTestOptions] = useState([]);
  const currentIndex = currentCombo.index;

  const editValue = (e: CustomChangeEvent) => editValues(e, setValues);
  const handleSaveForm = () => {
    saveFormData({
      formData: values,
      dataIndex: currentIndex,
      setTableData: setSearchComboList,
      compareKey: 'CMB_CD',
    });
  };

  const handleComboTest = () => {
    if (!testOptionData) {
      toast.warning(getAlertMessage('ERRO_00120'));
      return;
    }
    const newOptions = convertComboToOptions(testOptionData);
    setTestOptions(newOptions);
  };

  useEffect(() => {
    if (currentCombo) {
      setValues(currentCombo.data);
    }
  }, [currentCombo]);

  return (
    <FrameEditBox
      title='팝업 정보'
      height={height}
      onClick={handleSaveForm}
    >
      <EditFieldRow className='border-t'>
        <FrameEditField title='콤보코드'>
          <FrameEditInput
            type='text'
            name='CMB_CD'
            value={values?.CMB_CD || ''}
            onChange={editValue}
            required={true}
            readOnly={values?.ROWTYPE !== RowType.ADD}
          />
        </FrameEditField>
        <FrameEditField title='콤보명'>
          <FrameEditInput
            type='text'
            name='CMB_NM'
            value={values?.CMB_NM || ''}
            onChange={editValue}
            required={true}
          />
        </FrameEditField>
        <FrameEditField title='생성자'>
          <FrameEditInput
            type='text'
            name='MNGR_NM'
            value={values?.MNGR_NM || ''}
            onChange={editValue}
          />
        </FrameEditField>
      </EditFieldRow>
      <EditFieldRow>
        <FrameEditTextArea
          title='선택(Select) 구문'
          name='INQ_TGT_SNTX_CONT'
          value={values?.INQ_TGT_SNTX_CONT || ''}
          onChange={editValue}
          height={simpleTextHeight}
          required={true}
        />
      </EditFieldRow>
      <EditFieldRow>
        <FrameEditTextArea
          title='조건(Where) 구문'
          name='INQ_CND_SNTX_CONT'
          value={values?.INQ_CND_SNTX_CONT || ''}
          onChange={editValue}
          height={simpleTextHeight}
          required={true}
        />
      </EditFieldRow>
      <EditFieldRow>
        <FrameEditTextArea
          title='사용여부 구문'
          name='USE_YN_SNTX_CONT'
          value={values?.USE_YN_SNTX_CONT || ''}
          onChange={editValue}
          height={simpleTextHeight}
        />
      </EditFieldRow>
      <EditFieldRow>
        <FrameEditTextArea
          title='추가 조건구문'
          name='APN_CND_SNTX_CONT'
          value={values?.APN_CND_SNTX_CONT || ''}
          onChange={editValue}
          height={simpleTextHeight}
        />
      </EditFieldRow>
      <EditFieldRow>
        <FrameEditTextArea
          title='정렬구문'
          name='SRT_SNTX_CONT'
          value={values?.SRT_SNTX_CONT || ''}
          onChange={editValue}
          height={simpleTextHeight}
        />
      </EditFieldRow>
      <EditFieldRow className='flex-grow'>
        <FrameEditField
          title='콤보 테스트'
          width={600}
        >
          <FrameEditSelect options={testOptions} />
          <CommonButton
            title='테스트'
            onClick={handleComboTest}
          />
        </FrameEditField>
      </EditFieldRow>
    </FrameEditBox>
  );
};

export default COCM430M01Form;
