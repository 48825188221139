import { CommonButton, FrameInfo, FrameInfoSelect } from 'components';
import { useHeight, useSearchComboList } from 'hooks';
import { CustomChangeEvent } from 'models';
import { useEffect, useState } from 'react';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
} from 'recoil';
import { designState, selectState } from 'store';
import { FrameContent } from 'styles';
import TEG06DesignDescriptionTable from './TEG06DesignDescriptionTable';
import TEG06DesignPlotTable from './TEG06DesignPlotTable';
import TEG06DesignDutTable from './TEG06DesignDutTable';

const TEG06 = (): JSX.Element => {
  const getHeight = useHeight();
  const frameActive =
    useRecoilValue(selectState.selectSideMenu).PGM_ID === 'UIE006TEG06';
  const resetPlotInfo = useResetRecoilState(designState.plotInfo);
  const [searchData, setSearchData] = useRecoilState(
    designState.searchDesignDescription,
  );
  const [values, setValues] = useState(searchData);
  const projectOptions = useSearchComboList(
    { CMB_CD: frameActive ? 'CMB0029' : '' },
    true,
  );
  const processOptions = useSearchComboList(
    {
      CMB_CD: frameActive && values.PRJ_NO ? 'CMB0030' : '',
      SRCH_PARAM: values.PRJ_NO,
    },
    true,
  );
  const deviceOptions = useSearchComboList(
    {
      CMB_CD: frameActive && values.PRC_NO ? 'CMB0031' : '',
      SRCH_PARAM: values.PRC_NO,
    },
    true,
  );
  const designOptions = useSearchComboList(
    {
      CMB_CD: frameActive && values.DVC_NO ? 'CMB0036' : '',
      SRCH_PARAM: values.DVC_NO,
    },
    true,
  );
  const editValue = (e: CustomChangeEvent, codeType?: string) => {
    if (codeType) {
      if (codeType === 'PRJ_NO') {
        setValues({
          [codeType]: e.target.value,
          PRC_NO: '',
          DVC_NO: '',
          DSG_ID: '',
        });
      } else if (codeType === 'PRC_NO') {
        setValues(old => ({
          ...old,
          [codeType]: e.target.value,
          DVC_NO: '',
          DSG_ID: '',
        }));
      } else if (codeType === 'DVC_NO') {
        setValues(old => ({
          ...old,
          [codeType]: e.target.value,
          DSG_ID: '',
        }));
      } else {
        setValues(old => ({
          ...old,
          [codeType]: e.target.value,
        }));
      }
    }
  };

  useEffect(() => {
    const empty = Object.values(values).some(value => value === '');
    if (empty) {
      resetPlotInfo();
    }
    setSearchData(values);
  }, [values?.DSG_ID, values?.DVC_NO]);
  const handleSearch = () => {
    setSearchData(values);
  };
  useEffect(() => {
    if (frameActive) setValues(searchData);
  }, [frameActive]);
  return (
    <>
      <FrameInfo>
        <div className='search-form'>
          <div className='input-block'>
            <div className='gap-x-10'>
              <FrameInfoSelect
                title='프로젝트'
                name='PRJ_NO'
                options={projectOptions}
                value={values.PRJ_NO}
                onChange={e => editValue(e, 'PRJ_NO')}
              />
              <FrameInfoSelect
                title='공정'
                name='PRC_NO'
                options={processOptions}
                value={values.PRC_NO}
                onChange={e => editValue(e, 'PRC_NO')}
              />
              <FrameInfoSelect
                title='디바이스'
                name='DVC_NO'
                options={deviceOptions}
                value={values.DVC_NO}
                onChange={e => editValue(e, 'DVC_NO')}
              />
              <FrameInfoSelect
                title='디자인'
                name='DSG_ID'
                options={designOptions}
                value={values.DSG_ID}
                onChange={e => editValue(e, 'DSG_ID')}
              />
            </div>
          </div>
          <div className='flex gap-x-2'>
            <CommonButton
              title='조회'
              onClick={handleSearch}
            />
          </div>
        </div>
      </FrameInfo>
      <FrameContent>
        <div className='w-full'>
          <div className='flex-between-2'>
            <div className='w-[1500px]'>
              <TEG06DesignDescriptionTable
                tableHeight={getHeight(563)}
              ></TEG06DesignDescriptionTable>
              <TEG06DesignPlotTable
                tableHeight={87}
              ></TEG06DesignPlotTable>
            </div>
            <TEG06DesignDutTable
              tableHeight={getHeight(680)}
            ></TEG06DesignDutTable>
          </div>
        </div>
      </FrameContent>
    </>
  );
};

export default TEG06;
