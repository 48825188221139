import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IModuleWrapper } from 'models';
import { toast } from 'react-toastify';
import { client, getAlertMessage } from 'utils';

function moduleMutation(
  options: UseMutationOptions<unknown, Error, IModuleWrapper, unknown>,
) {
  return useMutation({
    mutationFn: async module => {
      const response = await client.post('/api/teg/04/module', module);
      return response;
    },
    ...options,
  });
}

export default function useModuleMutation() {
  const queryClient = useQueryClient();

  return moduleMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['module'],
      });
      toast.success(getAlertMessage('INFO_00010'));
    },
  });
}
