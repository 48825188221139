import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  TableContext,
  addRowData,
  getAlertMessage,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useMoveTeg, useTableData } from 'hooks';
import {
  CheckInputCell,
  DisplayButton,
  StatusCell,
  StatusIcon,
  Table,
  TextCell,
} from 'components';
import { DataTableContainer } from 'styles';
import {
  Columns,
  CommonObject as ModuleObject,
  CurrentData,
  ITableContext,
  IModule,
  CommonObject,
  RowType,
  IModuleWrapper,
  CommonArray,
} from 'models';
import { descriptionState, moduleState } from 'store';
import {
  useFileCheck,
  useModuleDutList,
  useModuleMutation,
  useModuleTeg,
} from 'apis';
import {
  defaultModule,
  defaultModuleDoc,
} from 'models/defaults/moduleDefaults';
import useCommonFileDownloadMutation from 'apis/mutations/useCommonFileDownloadMutation';
import CheckInputWithToolTipCell from 'components/datatable/cells/CheckWithToolTipCell';
import {
  handleFileCheck,
  handleSearchDescription,
} from './TEG04Functions';
import { toast } from 'react-toastify';

const columnHelper = createColumnHelper();
const newRow: IModule = defaultModule;

const TEG04ModuleTable = ({
  tableHeight,
  setDescriptionModal,
  setDrawModal,
  setCoordinateModal,
}: {
  tableHeight: number;
  setDescriptionModal: Dispatch<SetStateAction<boolean>>;
  setDrawModal: Dispatch<SetStateAction<boolean>>;
  setCoordinateModal: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const moveTeg = useMoveTeg();
  const searchModule = useRecoilValue(moduleState.searchModule('teg04'));
  const searchDut = useRecoilValue(moduleState.searchModuleDut('teg04'));
  const setSearchDescription = useSetRecoilState(
    descriptionState.searchDescription('teg05'),
  );
  const dutResponse = useModuleDutList(searchDut) as ModuleObject;
  const moduleData = dutResponse.data?.moduleList;
  const dutData = dutResponse.data?.dutList;
  const moduleList = useRecoilValue(moduleState.moduleList('teg04'));

  const setDutData = useSetRecoilState(moduleState.dutList('teg04'));

  const [initialData, setInitialData] = useTableData<ModuleObject>({
    fetchData: moduleData ? moduleData : moduleList,
    effect: () => {
      const newData = moduleData ? moduleData : moduleList;
      const containAll = newData.map((newRow: CommonObject) => ({
        ...newRow,
        all: '0',
      }));
      setInitialData(containAll);
      setBackupData(containAll);
      setCurrentData({ index: 0, data: containAll[0] });
    },
  });

  const [currentData, setCurrentData] = useRecoilState(
    moduleState.currentModule,
  );
  const [backupData, setBackupData] = useState<CommonArray>([]);
  const [searchModuleTeg, setSearchModuleTeg] = useState(defaultModuleDoc);
  const [fileCheck, setFileCheck] = useState({ filePath: '', FLAG: true });

  const verifyDesignAndRowType = (data: CommonObject): boolean => {
    if (currentDesign?.data?.DSG_ID === undefined) {
      toast.warning(getAlertMessage('ERRO_00370', ['디자인']));
      return false;
    }
    if (currentDesign?.data?.ROWTYPE === RowType.ADD) {
      toast.warning(getAlertMessage('ERRO_00380', ['디자인']));
      return false;
    }
    if (data.ROWTYPE === RowType.ADD) {
      toast.warning(getAlertMessage('ERRO_00380', ['모듈']));
      return false;
    }
    return true;
  };

  const handleWholeCheckValues = (data: CommonObject) => {
    if (!verifyDesignAndRowType(data)) return false;
    const all = data.all;
    const dutIdArray = Array.from(
      { length: 12 },
      (_, i) => data.MDL_ID * 100 + i + 1,
    );
    setInitialData(prev => {
      const index = prev.findIndex(item => item.MDL_ID === data.MDL_ID);
      if (index === -1) return prev;
      const updatedAll = all === '1' ? '0' : '1';
      const updatedItem = {
        ...prev[index],
        all: updatedAll,
        ...Array.from(
          { length: 12 },
          (_, i) => `DUT_CHECK${(i + 1).toString().padStart(2, '0')}`,
        ).reduce(
          (acc, key) => ({
            ...acc,
            [key]: updatedAll,
          }),
          {},
        ),
        ROWTYPE: RowType.UPDATE,
      };
      const newData = prev.map((item, idx) =>
        idx === index ? updatedItem : item,
      );
      if (newData.length > 0) {
        newData[newData.length - 1] = {
          ...newData[newData.length - 1],
          checkUpdated: true,
        };
      }
      return newData;
    });
    setDutData(prev => {
      const updatedDutData = dutIdArray //[101, 102, ..., 112]
        .map(dutId => {
          const existingDut = prev.find(dut => dut.DUT_ID === dutId);
          if (all === '0') {
            if (existingDut) {
              return existingDut.ROWTYPE === RowType.DELETE
                ? { ...existingDut, ROWTYPE: RowType.NORMAL }
                : existingDut;
            } else {
              return {
                DVC_NO: data.DVC_NO,
                DSG_ID: currentDesign?.data?.DSG_ID,
                DUT_ID: dutId,
                DUT_NM: `${data.ID} D${dutId.toString().slice(-2)}`,
                DUT_ALIAS: `D${dutId.toString().slice(-2)}`,
                ROWTYPE: RowType.ADD,
              };
            }
          } else {
            if (existingDut && existingDut.ROWTYPE === RowType.ADD) {
              return null;
            } else if (existingDut) {
              return { ...existingDut, ROWTYPE: RowType.DELETE };
            }
          }
          return null;
        })
        .filter((dut): dut is CommonObject => dut !== null); // null 제거
      const newDutData = prev.filter(
        dut => !dutIdArray.includes(dut.DUT_ID),
      );
      return [...newDutData, ...updatedDutData];
    });
  };

  const handleCheckValue = (data: CommonObject, columnId: string) => {
    if (!verifyDesignAndRowType(data)) return false;
    const updatedData =
      data?.[columnId] === '1'
        ? { ...data, [columnId]: '0', ROWTYPE: RowType.UPDATE }
        : { ...data, [columnId]: '1', ROWTYPE: RowType.UPDATE };
    const moveKey = `D${columnId.substring(9)}`;
    const dutId = parseInt(data.MDL_ID + columnId.substring(9));
    const dutNm = `${data.ID} ${moveKey}`;
    if (updatedData[columnId] === '1') {
      setDutData(prev => {
        const index = prev.findIndex(item => item.DUT_ID === dutId);
        if (index !== -1 && prev[index].ROWTYPE === RowType.DELETE) {
          return prev.map((item, i) =>
            i === index ? { ...item, ROWTYPE: RowType.NORMAL } : item,
          );
        }
        const dut = {
          DVC_NO: data.DVC_NO,
          DSG_ID: currentDesign?.data?.DSG_ID,
          DUT_ID: dutId,
          DUT_NM: dutNm,
          DUT_ALIAS: moveKey,
          ROWTYPE: RowType.ADD,
        };
        return [...prev, dut];
      });
    } else {
      setDutData(prev => {
        const index = prev.findIndex(item => item.DUT_ID === dutId);
        if (index === -1) return prev;
        if (prev[index].ROWTYPE === RowType.ADD) {
          return prev.filter((_, i) => i !== index);
        }
        const newData = prev.map((item, i) =>
          i === index ? { ...item, ROWTYPE: RowType.DELETE } : item,
        );
        return newData;
      });
    }
  };
  const currentDesign = useRecoilValue(moduleState.currentDesign);

  const handleMove = (row: CommonObject) => {
    handleSearchDescription(row, searchModule, setSearchDescription);
    moveTeg('UIE005TEG05');
  };

  const genereateDutColumns = (count: number) => {
    const columns = [];
    for (let i = 1; i <= count; i++) {
      const paddedIndex = i.toString().padStart(2, '0');
      columns.push(
        columnHelper.accessor(`DUT_CHECK${paddedIndex}`, {
          header: `D${paddedIndex}`,
          size: 35,
          cell: CheckInputWithToolTipCell,
          enableColumnFilter: false,
          meta: {
            onChange: (row: CommonObject) =>
              handleCheckValue(row, `DUT_CHECK${paddedIndex}`),
          },
        }),
      );
    }
    return columns;
  };

  const moduleMutation = useModuleMutation();
  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 30,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('all', {
      header: 'ALL',
      size: 40,
      cell: CheckInputCell,
      meta: {
        onChange: (row: CommonObject) => handleWholeCheckValues(row),
      },
    }),
    columnHelper.accessor('ID', {
      header: '구분',
      cell: TextCell,
      size: 50,
      filterFn: 'includesString',
      meta: {
        unmodifiable: true,
        justify: 'center',
      },
    }),
    ...genereateDutColumns(12),
    columnHelper.accessor('RMK_CNT', {
      header: '비고',
      cell: TextCell,
      enableColumnFilter: false,
    }),
    columnHelper.display({
      header: '속성',
      size: 60,
      cell: DisplayButton,
      meta: {
        text: '관리',
        onClick: (row: CommonObject) => handleMove(row),
        disabled: (row: CommonObject) => row.ROWTYPE === RowType.ADD,
      },
    }),
  ];
  const columns = useMemo(
    () => defaultColumns,
    [currentDesign, dutResponse, moduleList],
  ) as Columns;

  const handleAddData = () => {
    const numericId =
      initialData?.[0] === undefined
        ? '00'
        : initialData?.[initialData.length - 1]?.ID.substring(1);
    const newModuleId = parseInt(numericId) + 1;
    if (newModuleId === 100) {
      toast.warning('M99까지만 추가 가능합니다.');
      return;
    }
    const newId = `M${newModuleId.toString().padStart(2, '0')}`;
    const modifiedNewRow = {
      ...newRow,
      DVC_NO: searchModule.DVC_NO,
      ID: newId,
    };
    addRowData(
      setInitialData,
      setCurrentData,
      modifiedNewRow,
      initialData.length,
    );
  };

  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);

    if (removedData.length > 0) {
      setInitialData(removedData);
      selectNextDataByRemove(
        selectData,
        initialData.length - 1,
        removedData,
        setCurrentData,
      );
    }
  };

  const handleSaveData = () => {
    const checkKeys = ['ID'];
    const targetData = saveTableData(initialData, checkKeys, false);
    const idValues = new Set();
    let duplicatedId = '';
    for (const row of targetData) {
      if (idValues.has(row.ID)) {
        duplicatedId = row.ID;
        break;
      } else {
        idValues.add(row.ID);
      }
    }
    if (duplicatedId) {
      toast.warning(getAlertMessage('ERRO_00190', [duplicatedId]));
      return;
    }
    console.log('모듈 저장 목록 ', targetData);
    const saveData = {
      PRJ_NO: String(searchModule.PRJ_NO),
      moduleList: targetData,
    };

    if (targetData.length > 0) {
      moduleMutation.mutate(saveData as IModuleWrapper);
    } else {
      setInitialData(initialData);
    }
  };

  const handleTegDoc = () => {
    setSearchModuleTeg(prev => ({
      PRJ_NO: String(searchModule.PRJ_NO),
      DVC_NO: String(searchModule.DVC_NO),
      FLAG: !prev.FLAG,
    }));
  };
  const tegResponse = useModuleTeg(searchModuleTeg) as CommonObject;
  useEffect(() => {
    handleFileCheck(
      tegResponse?.data,
      searchModuleTeg,
      '_D_TEGDescription.xlsx',
      setFileCheck,
    );
  }, [tegResponse?.data?.FLAG]);
  const checkResponse = useFileCheck(fileCheck.filePath, fileCheck.FLAG);
  const downloadFile = useCommonFileDownloadMutation();
  const fileDownload = () => {
    downloadFile.mutate({ URL: fileCheck.filePath });
  };
  useEffect(() => {
    if (checkResponse?.data !== undefined) {
      fileDownload();
    }
  }, [checkResponse?.data]);

  useEffect(() => {
    if (dutData) setDutData(dutData);
    else setDutData([]);
  }, [dutData]);

  const prevCheckUpdatedRef = useRef(
    initialData[initialData.length - 1]?.checkUpdated,
  );
  useEffect(() => {
    const currentCheckUpdated =
      initialData[initialData.length - 1]?.checkUpdated;
    if (
      prevCheckUpdatedRef.current === true &&
      currentCheckUpdated === false
    ) {
      setDutData(dutData);
    }
    prevCheckUpdatedRef.current = currentCheckUpdated;
  }, [initialData[initialData.length - 1]?.checkUpdated]);

  const handleDescription = () => {
    setDescriptionModal(true);
  };
  const handleDraw = () => {
    setDrawModal(true);
  };
  const handleCoordinate = () => {
    setCoordinateModal(true);
  };

  const contextValue: ITableContext = {
    handleCellColor: (row: CommonObject, columnId: string) => {
      const dut = columnId.replace(
        /DUT_CHECK(\d+)/,
        (match, number) => `DUT${parseInt(number, 10)}`,
      );
      return row[dut] && row[dut] === 1
        ? 'bg-yellow-300'
        : row[dut] === 2
          ? 'bg-blue-300'
          : 'bg-white';
    },
    handleToolTipText: (row: CommonObject, columnId: string) => {
      const mvDut = columnId.replace(
        /DUT_CHECK(\d+)/,
        (match, number) => `MV_DUT${parseInt(number, 10)}`,
      );
      return row[mvDut] ? row[mvDut] : undefined;
    },
    handleTegDoc,
    handleDescription,
    handleDraw,
    handleCoordinate,
    handleAddData,
    handleRemoveData,
    handleSaveData,
    currentData,
    setCurrentData,
    customDisabled: {
      fetched:
        searchModule.PRJ_NO === '' ||
        searchModule.PRC_NO === '' ||
        searchModule.DVC_NO === '',
    },
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        {initialData && (
          <Table
            title='모듈'
            initialData={initialData}
            backupData={backupData}
            setData={setInitialData}
            columns={columns}
            editable={false}
            showToggle={false}
            tableHeight={tableHeight}
            draggable={true}
          />
        )}
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default TEG04ModuleTable;
