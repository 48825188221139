import { createColumnHelper } from '@tanstack/react-table';
import useBbsDetail from 'apis/queries/useBbsDetail';
import useBbsFileList from 'apis/queries/useBbsFileList';
import {
  CommonButton,
  DisplayButton,
  FrameEditBox,
  FrameEditField,
  FrameEditInput,
  ReadOnlyTextCell,
  StatusCell,
  StatusIcon,
  Table,
} from 'components';
import FrameEditTextArea from 'components/frame/FrameEditTextArea';
import { useHeight, useTableData } from 'hooks';
import {
  Columns,
  CommonArray,
  CommonObject,
  CustomChangeEvent,
  CustomMouseEvent,
  ITableContext,
  RowType,
} from 'models';
import { defaultBbsDetail } from 'models/defaults/bbsDefaults';
import { IBbsDetail } from 'models/interfaces/bbsInterface';
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { RecoilState, useRecoilState, useRecoilValue } from 'recoil';
import { bbsState } from 'store/bbsAtoms';
import {
  EditFieldRow,
  ModalInner,
  ModalOverlay,
  ModalWrapper,
} from 'styles';
import {
  TableContext,
  editValues,
  getAlertMessage,
  getCurrentDateFormatted,
} from 'utils';
import useBbsUploadMutation from 'apis/mutations/useBbsUploadMutation';
import useBownloadBbsFile from 'apis/mutations/useBbsDownloadMutation';
import { loginState } from 'store';
import useBbsDetailMutation from 'apis/mutations/useBbsDetailMutation';
import useBbsFileDeleteMutation from 'apis/mutations/useBbsFileDeleteMutation';
import { toast } from 'react-toastify';
import useBbsDeleteMutation from 'apis/mutations/useBbsDeleteMutation';

const simpleTextHeight = 25;
const formatFileSize = (size: number, depth: number): string => {
  if (size < 1024 || depth === 3) {
    const units = ['B', 'KB', 'MB', 'GB'];
    return `${parseFloat(size.toFixed(2))} ${units[depth]}`;
  } else {
    return formatFileSize(size / 1024, depth + 1);
  }
};

interface IModalDataProps<IBbsDetail> {
  fetchData: IBbsDetail;
  atom: RecoilState<IBbsDetail>;
  effect?: (() => void) | null;
}
type IModalDataResult = [IBbsDetail, Dispatch<SetStateAction<IBbsDetail>>];

function useModalData({
  fetchData,
  atom,
  effect = null,
}: IModalDataProps<IBbsDetail>): IModalDataResult {
  const [modalData, setModalData] = useRecoilState<IBbsDetail>(atom);
  if (effect && typeof effect !== 'function') {
    throw new Error('effect는 함수로 전달되어야 합니다.');
  }
  useEffect(() => {
    if (typeof effect === 'function') {
      effect();
    } else if (fetchData) {
      setModalData(fetchData);
    }
  }, [fetchData, effect]);

  useEffect(() => {
    return () => {
      setModalData(defaultBbsDetail); // Clean up state on unmount
    };
  }, []);
  return [modalData, setModalData];
}

const columnStdOut = (dataList: CommonArray): CommonArray => {
  return dataList.map(item => ({
    ...item,
    MDATE: getCurrentDateFormatted(item.MDATE),
    FL_SZ_VAL: formatFileSize(item.FL_SZ_VAL, 0),
  }));
};

const columnHelper = createColumnHelper();
const COCM800M01Modal = ({
  onClose,
  currentId,
}: {
  onClose: () => void;
  currentId: number;
}): JSX.Element => {
  const onBbsClick = (e: CustomMouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  const writeAuth = (
    loginUser: string,
    authUser: string,
    bbsId: number,
  ) => {
    if (bbsId === 0) {
      return false;
    }
    return loginUser !== authUser;
  };
  const getHeight = useHeight();
  const loginUser = useRecoilValue(loginState.userInfo);
  // new form
  const newBbs = defaultBbsDetail;

  // recoil
  const searchBbsDetail = useRecoilValue(
    bbsState.searchBbsDetail('COCM800M01'),
  );
  const [currentData, setCurrentData] = useRecoilState(
    bbsState.currentBbs('COCM800M01File'),
  );
  // mutation
  const bbsDetailMutation = useBbsDetailMutation();
  const uploadMutation = useBbsUploadMutation();
  const downloadMutation = useBownloadBbsFile();
  const fileDeleteMutation = useBbsFileDeleteMutation();
  const bbsDeleteMutation = useBbsDeleteMutation(onClose);

  // query
  const { data: bbsDetailData } = useBbsDetail(
    searchBbsDetail,
  ) as CommonObject;
  const { data: bbsFileList } = useBbsFileList(
    searchBbsDetail,
  ) as CommonObject;
  // state
  const [request, setRequest] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [modalData, setModalData] = useModalData({
    fetchData: bbsDetailData?.[0],
    atom: bbsState.bbsDetail,
  });
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: bbsFileList,
    atom: bbsState.bbsFileList,
    effect: () => {
      const sOutData = columnStdOut(bbsFileList);
      setInitialData(sOutData);
      setCurrentData({ index: 0, data: sOutData[0] });
    },
  });
  // function
  const editValue = (e: CustomChangeEvent) => {
    editValues(e, setModalData);
  };

  const handleFilesSelected = (files: File[]) => {
    setSelectedFiles(files);
    addItemsToList(files);
  };
  const addItemsToList = (newItems: File[]) => {
    const transformList = newItems.map(item => ({
      FL_NM: item.name,
      FL_SZ_VAL: item.size,
      MDATE: new Date(),
      STATUS: 'FILE',
      ROWTYPE: RowType.ADD,
    }));
    const sOutData = columnStdOut(transformList) as CommonObject[];
    const backUp = initialData.filter(
      file => file.ROWTYPE !== RowType.ADD,
    );
    setInitialData([...sOutData, ...backUp]);
  };
  const removeItemsToList = (itemName: string, rowType: number) => {
    if (rowType === RowType.ADD) {
      setInitialData(prev =>
        prev.filter(
          file => !(file.FL_NM === itemName && file.ROWTYPE === rowType),
        ),
      );
      setSelectedFiles(prev =>
        prev.filter(file => file.name !== itemName),
      );
    } else {
      setInitialData(current => {
        return current.map(item => {
          if (item.FL_NM === itemName) {
            if (item.ROWTYPE === RowType.NORMAL) {
              return { ...item, ROWTYPE: RowType.DELETE };
            } else if (item.ROWTYPE === RowType.DELETE) {
              return { ...item, ROWTYPE: RowType.NORMAL };
            }
          }
          return item; // 조건에 맞지 않는 경우 원본 유지
        });
      });
    }
  };
  const handleSaveData = () => {
    const fileFlag = selectedFiles.length > 0;
    const hasDeletable = initialData.some(
      file => file.ROWTYPE == RowType.DELETE,
    );

    if (modalData === bbsDetailData?.[0] && !fileFlag && !hasDeletable) {
      return;
    }
    const checkKeys = (keyList: string[], data: IBbsDetail) => {
      if (keyList) {
        for (const key of keyList) {
          const dataValue = data[key as keyof IBbsDetail];
          if (dataValue === '') {
            toast.warning('입력되지 않은 필드가 있습니다.');
            return false;
          }
        }
      }
      const fileYn = initialData.filter(
        file =>
          file.ROWTYPE === RowType.NORMAL || file.ROWTYPE === RowType.ADD,
      );
      if (fileYn.length === 0) {
        setModalData(prev => ({ ...prev, FILE_FLAG: 'N' }));
      } else if (fileYn.length !== 0) {
        setModalData(prev => ({ ...prev, FILE_FLAG: 'Y' }));
      }
      return true;
    };
    const checkFile = (data: CommonObject[]) => {
      const deleteList = data.filter(
        file => file.ROWTYPE === RowType.DELETE,
      );
      return deleteList;
    };
    const targetCheck = checkKeys(['BBS_NM'], modalData);

    const targetFile = checkFile(initialData);
    if (targetCheck !== false) {
      setRequest(true);
      formDataSave(fileFlag);
      fileDeleteMutation.mutate({
        BBS_ID: currentId,
        FILE_LIST: targetFile,
      });
      toast.success(getAlertMessage('INFO_00010'));
    } else {
      setModalData(modalData);
    }
  };
  const handleRemoveData = () => {
    if (!confirm('질문을 삭제하시겠습니까?')) {
      return;
    }
    bbsDeleteMutation.mutate(currentId);
  };
  const formDataSave = (flag: boolean) => {
    if (!flag) {
      return;
    }
    const form = new FormData();
    selectedFiles.forEach(file => {
      form.append('FILES', file);
    });
    const fileWrapper = {
      FILES: form,
      FILE_INFO: {
        BBS_ID: currentId,
      },
    };
    setUploadSuccess(true);
    uploadMutation.mutate(fileWrapper);
  };

  const downloadFile = (a: any) => {
    const newFile = { BBS_ID: currentId, FILE_NM: a.FL_NM };
    downloadMutation.mutate(newFile);
  };

  const deleteFile = (row: any, isAuthority: boolean) => {
    if (isAuthority) {
      toast.warning(getAlertMessage('INFO_00150'));
      return;
    }
    removeItemsToList(row.FL_NM, row.ROWTYPE);
  };

  useEffect(() => {
    if (currentId === 0) {
      setModalData(newBbs);
    }
  }, [currentId]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.target.files;
    if (!files) {
      return;
    }
    const newSelectedFiles = [...selectedFiles, ...Array.from(files)];
    setSelectedFiles(newSelectedFiles);
    handleFilesSelected(newSelectedFiles);
  };

  const resetFileInput = () => {
    setSelectedFiles([]);
  };
  useEffect(() => {
    if (uploadSuccess) {
      resetFileInput();
      setUploadSuccess(false);
    }
  }, [uploadSuccess]);
  useEffect(() => {
    if (request) {
      bbsDetailMutation.mutate(modalData);
      setRequest(false);
    }
  }, [modalData, request]);

  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 25,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('FL_NM', {
      header: '제목',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('FL_SZ_VAL', {
      header: '크기',
      cell: ReadOnlyTextCell,
      size: 50,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('MDATE', {
      header: '작성일',
      cell: ReadOnlyTextCell,
      size: 50,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.display({
      header: '다운로드',
      size: 60,
      cell: DisplayButton,
      meta: {
        text: '다운로드',
        onClick: (row: CommonObject) => downloadFile(row),
      },
    }),
    columnHelper.display({
      header: '삭제',
      size: 60,
      cell: DisplayButton,
      meta: {
        text: '삭제',
        onClick: (row: CommonObject) =>
          deleteFile(row, loginUser.USR_ID === modalData?.DWUR_ID),
      },
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const contextValue: ITableContext = {
    currentData,
    setCurrentData,
  };

  return (
    <>
      <ModalOverlay />
      <ModalWrapper
        onClick={onBbsClick}
        tabIndex={-1}
      >
        <ModalInner
          tabIndex={0}
          className='w-[1000px] h-[800px]'
        >
          <div>상세조회</div>
          <FrameEditBox>
            <EditFieldRow className={'border-t'}>
              <FrameEditTextArea
                title='제목'
                name='BBS_NM'
                value={modalData?.BBS_NM || ''}
                onChange={editValue}
                height={simpleTextHeight}
                required={true}
                readOnly={writeAuth(
                  loginUser?.USR_ID,
                  modalData?.DWUR_ID,
                  currentId,
                )}
              />
            </EditFieldRow>
            <EditFieldRow>
              <FrameEditField title='작성자'>
                <FrameEditInput
                  type='text'
                  name='DWUR_ID'
                  value={modalData?.DWUR_ID || loginUser?.USR_ID}
                  onChange={editValue}
                  required={true}
                  readOnly={true}
                />
              </FrameEditField>
            </EditFieldRow>
            <EditFieldRow>
              <FrameEditTextArea
                title='내용'
                name='BBS_CONT'
                value={modalData?.BBS_CONT || ''}
                onChange={editValue}
                height={200}
                readOnly={writeAuth(
                  loginUser?.USR_ID,
                  modalData?.DWUR_ID,
                  currentId,
                )}
              />
            </EditFieldRow>
            <EditFieldRow>
              <FrameEditTextArea
                title='답변'
                name='ANS_CONT'
                value={modalData?.ANS_CONT || ''}
                onChange={editValue}
                height={200}
                readOnly={loginUser?.USR_ID !== 'aibiz'}
              />
            </EditFieldRow>
          </FrameEditBox>
          <div className='flex justify-end mt-2'>
            <input
              type='file'
              multiple
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={handleFileChange}
            />
            {loginUser?.USR_ID === modalData?.DWUR_ID ||
            loginUser?.USR_ID === 'aibiz' ? (
              <CommonButton
                title='추가'
                onClick={handleUploadButtonClick}
              />
            ) : (
              <></>
            )}
          </div>
          <TableContext.Provider value={contextValue}>
            {initialData && (
              <Table
                title='첨부파일'
                initialData={initialData}
                backupData={bbsFileList}
                setData={setInitialData}
                columns={columns}
                editable={false}
                showToggle={false}
                tableHeight={getHeight(150)}
              />
            )}
          </TableContext.Provider>
          <div className='flex justify-center mt-2'>
            <CommonButton
              title='저장'
              onClick={handleSaveData}
            />
            <CommonButton
              title='삭제'
              color='darkred'
              onClick={handleRemoveData}
            />
            {/* <CommonButton
              title='닫기'
              color='darkred'
              onClick={onBbsClick}
            /> */}
          </div>
        </ModalInner>
      </ModalWrapper>
    </>
  );
};

export default COCM800M01Modal;
