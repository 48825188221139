import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchRuleDevice } from 'models';
import { client } from 'utils';

function designRuleDevice({
  searchData,
  options,
}: IQueryParams<ISearchRuleDevice>) {
  return useQuery({
    queryKey: ['document', 'design_rule', 'device', searchData.CHR_SNO],
    queryFn: async () => {
      const response = await client.get('/api/teg/09/doc/rule/device', {
        params: searchData,
      });

      return response.data;
    },
    ...options,
  });
}

export default function useDesignRuleDevice(
  searchData: ISearchRuleDevice,
) {
  return designRuleDevice({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled:
        searchData.DOC_NO !== undefined &&
        searchData.CHR_SNO !== undefined &&
        searchData.CH_NO !== undefined,
    },
  });
}
