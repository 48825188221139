import { useHeight } from 'hooks';
import { useState } from 'react';
import { CommonButton, FrameInfo, FrameInfoInput } from 'components';
import { useRecoilState } from 'recoil';
import { editValues } from 'utils';
import COCM170M01Table from './COCM170M01Table';
import { FrameContent } from 'styles';
import { systemManagementState } from 'store';
import { CustomChangeEvent, ISearchUserCode } from 'models';

const COCM170M01 = (): JSX.Element => {
  const getHeight = useHeight();

  const [searchUser, setSearchUser] = useRecoilState(
    systemManagementState.searchUser('COCM170M01'),
  );
  const [values, setValues] = useState<ISearchUserCode>(searchUser);
  const editValue = (e: CustomChangeEvent) => editValues(e, setValues);
  const handleSearchProgram = () => {
    setSearchUser(values);
  };

  return (
    <>
      <FrameInfo>
        <div className='search-form'>
          <div className='input-block'>
            <FrameInfoInput
              title='부서명'
              type='text'
              name='DPT_NM'
              value={values?.DPT_NM || ''}
              onChange={editValue}
            />
            <FrameInfoInput
              title='사용자명'
              type='text'
              name='USR_NM'
              value={values?.USR_NM || ''}
              onChange={editValue}
            />
          </div>
        </div>
        <div>
          <CommonButton
            title='조회'
            onClick={handleSearchProgram}
          />
        </div>
      </FrameInfo>
      <FrameContent>
        <div className='w-full'>
          <COCM170M01Table tableHeight={getHeight(650)} />
        </div>
      </FrameContent>
    </>
  );
};

export default COCM170M01;
