import tw from 'twin.macro';
import styled from 'styled-components';

export const PlotContainer = styled.div`
  ${tw`
    h-[550px]
    flex
    border
    mb-2
  `}
`;
export const PlotBox = styled.div<{ $type?: boolean }>`
  ${tw`
    flex
    flex-col
    items-center
    h-full
  `}
  ${({ $type }) => ($type ? undefined : tw`border-r`)}

  .plot-title {
    ${tw`
      py-0.5
      w-full
      bg-slate-100
      text-center
      text-[1rem]
      font-semibold
      border-b
    `}
  }

  .plot-area {
    ${tw`
      flex-grow
      w-[60%]
      flex
      flex-col
      gap-y-2
      items-center
      justify-end
      pb-5
    `}
    ${({ $type }) => ($type ? tw`w-[90%]` : tw`w-[60%]`)}
  }

  .plot-content {
    ${tw`
      border
      w-full
    `}
    ${({ $type }) => ($type ? tw`h-[90%]` : tw`h-[87%]`)}
  }

  .trend-control {
    ${tw`
      flex
      justify-between
      w-full
      items-center
    `}
  }

  .trend-select {
    ${tw`
      flex
      gap-x-3
      text-sm
    `}

    .select-item {
      ${tw`
        flex
        gap-x-2
        text-sm
      `}

      select {
        ${tw`
          border
          px-1
          text-[0.8rem]
        `}
      }
    }
  }

  .single-control {
    ${tw`
      flex
      justify-between
      w-full
      text-sm
    `}
  }

  .control-div {
    ${tw`
      flex
      items-center
      gap-x-2
    `}

    select,
    input {
      ${tw`
        border
        rounded-md
        outline-none
        pl-1
        py-[1px]
      `}
    }
  }
`;

export const LogscaleBox = styled.div`
  ${tw`
    flex
    gap-x-2
    text-sm
    w-full
    text-slate-500
    relative
  `}

  .logscale-item {
    ${tw`
      flex
      items-center
      gap-x-2
    `}
  }

  .legend-item {
    ${tw`
      absolute
      right-0
      font-semibold
      text-blue-950
    `}
  }
`;
