import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IMenu } from 'models';
import { client } from 'utils';

function menuMutation(
  options: UseMutationOptions<unknown, Error, IMenu[], unknown>,
) {
  return useMutation({
    mutationFn: async menu => {
      const response = await client.post('/api/co/cm/150m01/menu', menu);
      return response;
    },
    ...options,
  });
}

export default function useMenuMutation() {
  const queryClient = useQueryClient();

  return menuMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['system_setting', 'child_menu'],
      });
    },
  });
}
