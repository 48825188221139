import 'react-toastify/dist/ReactToastify.css';
import { Flip, ToastContainer } from 'react-toastify';

type ToastPosition =
  | 'top-left'
  | 'top-right'
  | 'top-center'
  | 'bottom-left'
  | 'bottom-right'
  | 'bottom-center';

type ToastTheme = 'light' | 'dark';

interface IToastProps {
  position?: ToastPosition;
  closeDuration?: number;
  closeOnClick?: boolean;
  theme?: ToastTheme;
}

const Toast = ({
  position = 'top-center',
  closeDuration = 3000,
  closeOnClick = false,
  theme = 'light',
}: IToastProps): JSX.Element => {
  return (
    <ToastContainer
      style={{
        fontSize: '0.9rem',
        width: 'fit-content',
      }}
      position={position}
      autoClose={closeDuration}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick={closeOnClick}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme={theme}
      transition={Flip}
    />
  );
};

export default Toast;
