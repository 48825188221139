import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchAuthority } from 'models';
import { client } from 'utils';

function authorityList({
  searchData,
  options,
}: IQueryParams<ISearchAuthority>) {
  return useQuery({
    queryKey: ['system_setting', 'authority', searchData],
    queryFn: async () => {
      const response = await client.get('/api/co/cm/160m01/authority', {
        params: searchData,
      });
      return response.data.authorityList || [];
    },
    ...options,
  });
}

export default function useAuthorityList(searchData: ISearchAuthority) {
  return authorityList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
