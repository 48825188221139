/**
 * 매개변수로 전달받은 데이터를 문자열로 변환하여 해당 데이터의 Byte코드 길이를 반환하는 함수
 * @param {any} data 다양한 자료형의 데이터
 * @returns {number} 매개변수로 전달받은 데이터의 Byte 길이
 */
export function getBytes(data) {
  const string = data !== typeof String ? String(data) : data;
  const encoder = new TextEncoder();
  return encoder.encode(string).length;
}

/**
 * 객체의 key와 매핑된 Input의 onChange 이벤트 핸들 함수
 * @param {Object} event 이벤트 객체
 * @param {function} setValues 각 프레임에서 사용되는 values setter
 * @param {function} setCustomValues select 등 사용자 정의가 필요한 value 필터링 시 사용(default: null)
 * @returns -
 */
export function editValues(event, setValues, setCustomValues = null) {
  const name = event.target.name;
  const value = event.target.value;
  const type = event.target.type;

  if (setCustomValues && type === 'select-one') {
    setCustomValues(value);
    return;
  }

  setValues(old => ({
    ...old,
    [name]:
      type === 'checkbox'
        ? event.target.checked
          ? value === '0'
            ? '1'
            : 'Y'
          : value === '1'
            ? '0'
            : 'N'
        : value,
  }));
}

/**
 * 매개변수로 전달된 messageCode를 이용해 세션스토리지에 저장된 alert 메세지를 반환하는 함수
 * messageParams가 있는 경우 배열의 순서에 맞게 message에 명시된 변수자릴('{0}')를 대체하여 반환
 * @param {string} messageCode
 * @param {array} messageParams
 * @returns
 */
export function getAlertMessage(messageCode, messageParams = null) {
  const alertMessageList = sessionStorage.getItem('alertMessage');
  const alertMessage = JSON.parse(alertMessageList);

  if (messageParams) {
    let replacedMessage = alertMessage[messageCode];

    messageParams.forEach((param, index) => {
      replacedMessage = replacedMessage.replace(`{${index}}`, param);
    });

    return replacedMessage;
  }

  if (alertMessage[messageCode]) return alertMessage[messageCode];
}

/**
 * 선택 또는 서버로부터 응답받은 파일을 다운로드하는 함수.
 * @param {array} targetFile 배열의 요소로 다운받을 파일을 전달
 * @param {string} fileName 저장될 파일 이름
 */
export function downloadFile(targetFile, fileName) {
  if (!Array.isArray(targetFile)) {
    console.error('첫번째 매개변수는 배열형태로 전달되어야합니다.');
  }

  const file = new Blob(targetFile);
  const url = window.URL.createObjectURL(file);
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}
