import { useQuery } from '@tanstack/react-query';
import { CommonObject, IQueryParams } from 'models';
import { client } from 'utils';

function moduleDutList({
  searchData,
  options,
}: IQueryParams<CommonObject>) {
  return useQuery({
    queryKey: ['module', 'dut', searchData],
    queryFn: async () => {
      const response = await client.post(
        '/api/teg/04/dut/search',
        searchData,
      );
      const { moduleList, dutList } = response.data;
      const modifiedModuleList = moduleList.map((item: any) => ({
        ...item,
        all: '0',
      }));
      const NotEdittedmodifiedModuleList =
        modifiedModuleList.length > 0
          ? [
              ...modifiedModuleList.slice(
                0,
                modifiedModuleList.length - 1,
              ),
              {
                ...modifiedModuleList[modifiedModuleList.length - 1],
                checkUpdated: false,
              },
            ]
          : [];
      return {
        moduleList: NotEdittedmodifiedModuleList,
        dutList,
      };
    },
    ...options,
  });
}

export default function useModuleDutList(searchData: CommonObject) {
  return moduleDutList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled:
        !!searchData.dutSearch.DVC_NO &&
        !!searchData.dutSearch.DSG_ID &&
        !!searchData.moduleList,
    },
  });
}
