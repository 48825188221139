import { memo, useCallback } from 'react';
import { BiSearchAlt } from 'react-icons/bi';
import { ToggleLabel, ToggleInput } from 'styles';
import { IToggleSwitchProps } from 'models';

type ToggleIcons = { [key: string]: JSX.Element };

const ToggleSwitch = ({
  title,
  onChange,
  flag,
}: IToggleSwitchProps): JSX.Element => {
  const icons: ToggleIcons = {
    search: <BiSearchAlt />,
  };
  const memoizedOnChange = useCallback(() => onChange(), [onChange]);

  return (
    <>
      <ToggleLabel>
        <span>{icons[title]}</span>
        <ToggleInput
          role='switch'
          type='checkbox'
          checked={flag}
          onChange={memoizedOnChange}
        />
      </ToggleLabel>
    </>
  );
};

export default memo(ToggleSwitch);
