import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IDesignDutWrapper } from 'models';
import { toast } from 'react-toastify';
import { client, getAlertMessage } from 'utils';

function designDutMutation(
  options: UseMutationOptions<unknown, Error, IDesignDutWrapper, unknown>,
) {
  return useMutation({
    mutationFn: async dut => {
      const response = await client.post('/api/teg/04/dut/save', dut);
      return response;
    },
    ...options,
  });
}

export default function useDesignDutMutation() {
  const queryClient = useQueryClient();

  return designDutMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['module', 'dut'],
      });
      toast.success(getAlertMessage('INFO_00010'));
    },
  });
}
