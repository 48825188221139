import { useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { TableContext, addRowNumber, saveTableData } from 'utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTableData } from 'hooks';
import {
  ReadOnlyTextCell,
  StatusCell,
  StatusIcon,
  Table,
  TextCell,
} from 'components';
import { DataTableContainer } from 'styles';
import {
  Columns,
  ITableContext,
  CommonObject,
  IDesignDutWrapper,
  CommonArray,
} from 'models';
import { moduleState } from 'store';
import { useDesignDutMutation } from 'apis';

const columnHelper = createColumnHelper();

const TEG04DutTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchModule = useRecoilValue(moduleState.searchModule('teg04'));
  const dutList = useRecoilValue(moduleState.dutList('teg04'));
  const [backupData, setBackupData] = useState<CommonArray>([]);
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: dutList,
    effect: () => {
      const newData = addRowNumber(dutList);
      setBackupData(newData);
      setInitialData(newData);
      setCurrentData({ index: 0, data: newData[0] });
    },
  });

  const [currentData, setCurrentData] = useRecoilState(
    moduleState.currentDut,
  );
  const desingDutMutation = useDesignDutMutation();
  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 30,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('no', {
      header: 'No',
      size: 40,
      cell: ReadOnlyTextCell,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('DUT_NM', {
      header: 'DUT',
      cell: ReadOnlyTextCell,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('DUT_ALIAS', {
      header: '별명',
      cell: TextCell,
      enableColumnFilter: false,
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const handleSaveData = () => {
    saveTableData(initialData, null, false);
    const saveData = { PRJ_NO: searchModule.PRJ_NO, dutList: initialData };

    if (initialData.length > 0) {
      desingDutMutation.mutate(saveData as IDesignDutWrapper);
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue: ITableContext = {
    handleSaveData,
    currentData,
    setCurrentData,
    customDisabled: {
      fetched:
        searchModule.PRJ_NO === '' ||
        searchModule.PRC_NO === '' ||
        searchModule.DVC_NO === '',
    },
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        {initialData && (
          <Table
            title='DUT 선택'
            initialData={initialData}
            backupData={backupData}
            setData={setInitialData}
            columns={columns}
            draggable={true}
            showToggle={false}
            tableHeight={tableHeight}
          />
        )}
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default TEG04DutTable;
