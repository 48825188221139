import CommonButton from 'components/common/CommonButton';
import FrameInfoSelect from 'components/frame/FrameInfoSelect';
import { useTableContext } from 'hooks';
import { toast } from 'react-toastify';
import { getAlertMessage } from 'utils';

const TableButtonBox = ({ count }: { count: number }): JSX.Element => {
  const {
    handleExport,
    handleGuide,
    handleTegDoc,
    handleAnalysisDoc,
    handleDescription,
    handleDraw,
    handleCoordinate,
    handleRegister,
    handleDuplicateData,
    handleResetData,
    handleAddData,
    handleRemoveData,
    handleSaveData,
    handleWordDocument,
    handleWordDownload,
    handleUpload,
    handleSelectInfo,
    currentData,
    customDisabled,
    selectInfoOptions,
  } = useTableContext();

  const handleAdd = () => {
    handleAddData?.();
  };

  const handleRemove = () => {
    if (count === 0) {
      toast.warning(getAlertMessage('INFO_00060'));
      return;
    }
    handleRemoveData?.(currentData);
  };

  const handleDuplicate = () => {
    if (count === 0) {
      toast.warning(getAlertMessage('INFO_00060'));
      return;
    }
    handleDuplicateData?.(currentData);
  };

  const handleSave = () => {
    if (!confirm('변경된 사항을 저장하시겠습니까?')) return;
    handleSaveData?.();
  };
  return (
    <div className='flex mb-1'>
      {handleExport && (
        <CommonButton
          title='액셀저장'
          onClick={handleExport}
          disabled={customDisabled?.fetched}
        />
      )}
      {handleGuide && (
        <CommonButton
          title='가이드'
          onClick={handleGuide}
          disabled={customDisabled?.guide}
        />
      )}
      {handleDuplicateData && (
        <CommonButton
          title='복사'
          onClick={handleDuplicate}
        />
      )}
      {handleTegDoc && (
        <CommonButton
          title='TEG문서'
          onClick={handleTegDoc}
          disabled={customDisabled?.fetched}
        />
      )}
      {handleWordDocument && (
        <CommonButton
          title='문서생성'
          onClick={handleWordDocument}
          disabled={customDisabled?.word}
        />
      )}
      {handleWordDownload && (
        <CommonButton
          title='다운로드'
          onClick={handleWordDownload}
          disabled={customDisabled?.word}
        />
      )}
      {handleAnalysisDoc && (
        <CommonButton
          title='분석문서'
          onClick={handleAnalysisDoc}
          disabled={customDisabled?.fetched}
        />
      )}
      {handleDescription && (
        <CommonButton
          title='속성정의'
          onClick={handleDescription}
          disabled={customDisabled?.fetched}
        />
      )}
      {handleDraw && (
        <CommonButton
          title='도면정보'
          onClick={handleDraw}
          disabled={customDisabled?.fetched}
        />
      )}
      {handleCoordinate && (
        <CommonButton
          title='좌표정보'
          onClick={handleCoordinate}
          disabled={customDisabled?.fetched}
        />
      )}
      {handleRegister && (
        <CommonButton
          title='등록'
          onClick={handleRegister}
          disabled={customDisabled?.fetched}
        />
      )}
      {handleResetData && (
        <CommonButton
          title='초기화'
          onClick={handleResetData}
          disabled={customDisabled?.fetched}
        />
      )}
      {handleAddData && (
        <CommonButton
          title='추가'
          onClick={handleAdd}
          disabled={customDisabled?.fetched}
        />
      )}
      {handleRemoveData && (
        <CommonButton
          title='삭제'
          onClick={handleRemove}
          disabled={customDisabled?.fetched}
        />
      )}
      {handleSaveData && (
        <CommonButton
          title='저장'
          onClick={handleSave}
          disabled={customDisabled?.fetched}
        />
      )}
      {handleUpload && (
        <CommonButton
          title='일괄 업로드'
          onClick={handleUpload}
          disabled={customDisabled?.fetched}
        />
      )}
      {handleSelectInfo && selectInfoOptions && (
        <FrameInfoSelect
          title=''
          className='ml-2 border border-slate-400 text-sm py-[1px] outline-none'
          options={selectInfoOptions}
          onChange={handleSelectInfo}
          disabled={customDisabled?.fetched}
        />
      )}
    </div>
  );
};

export default TableButtonBox;
