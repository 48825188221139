import { createColumnHelper } from '@tanstack/react-table';
import { useChapterList, useChapterMutation } from 'apis';
import { StatusCell, StatusIcon, Table, TreeCell } from 'components';
import { useTableData } from 'hooks';
import {
  Columns,
  CommonObject,
  CurrentData,
  FIXED_CHAPTER,
  IChapter,
  ITableContext,
  defaultChapter,
} from 'models';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { documentState } from 'store';
import { DataTableContainer } from 'styles';
import {
  TableContext,
  addRowData,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';

const columnHelper = createColumnHelper();
const newChapter = defaultChapter;

const TEG10ChapterTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchDocumentContent = useRecoilValue(
    documentState.searchDocumentContent,
  );
  const chapterMutation = useChapterMutation();
  const { data: chapterData } = useChapterList(
    searchDocumentContent,
  ) as CommonObject;
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: chapterData?.chapterList,
    atom: documentState.chapterList,
    effect: () => {
      setInitialData(chapterData?.chapterList);
      setdocumentVopList(chapterData?.vopList);
      setCurrentData({ index: 0, data: chapterData?.chapterList[0] });
    },
  });

  const setdocumentVopList = useSetRecoilState(
    documentState.documentVopList,
  );
  const [currentData, setCurrentData] = useRecoilState(
    documentState.currentDocumentContent,
  );

  const [emptyChildChapter, setEmptyChildChapter] =
    useState<boolean>(false);
  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      header: () => <StatusIcon />,
      cell: StatusCell,
      size: 25,
    }),
    columnHelper.accessor('CH_NM', {
      header: '챕터명',
      cell: TreeCell,
      filterFn: 'arrIncludes',
      meta: {
        hasChildren: (row: CommonObject) => hasChildren(row),
      },
    }),
  ];
  const columns = useMemo(() => defaultColumns, [initialData]) as Columns;

  const hasChildren = (row: CommonObject) => {
    const childList = initialData.filter(
      data => data.CHR_SNO !== row.CHR_SNO && data.HRK_SNO === row.CHR_SNO,
    );
    return childList.length > 0;
  };

  const isFixedChapter = Object.values(FIXED_CHAPTER).some(
    chapter => chapter === currentData?.data?.CH_NO,
  );

  const handleAddData = () => {
    const updatedChapter = {
      ...newChapter,
      HRK_CH_NO: currentData.data.CH_NO,
      DOC_NO: currentData.data.DOC_NO,
      DOC_TYPE: currentData.data.DOC_TYPE,
      HRK_SNO: currentData.data.CHR_SNO,
    } as IChapter;

    addRowData(
      setInitialData,
      setCurrentData,
      updatedChapter,
      initialData.length,
    );
    setCurrentData({ index: 0, data: updatedChapter });
  };

  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);

    setInitialData(removedData);
    selectNextDataByRemove(
      selectData,
      initialData.length - 1,
      removedData,
      setCurrentData,
    );
  };

  const handleSaveData = () => {
    const checkKeys = ['CH_NM'];
    const targetData = saveTableData(initialData, checkKeys);

    if (targetData.length > 0) {
      console.log(targetData);
      chapterMutation.mutate(targetData as IChapter[]);
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue: ITableContext = {
    customDisabled: {
      add: emptyChildChapter,
      remove: hasChildren(currentData.data) || isFixedChapter,
    },
    handleAddData,
    handleRemoveData,
    handleSaveData,
    currentData,
    setCurrentData,
  };

  useEffect(() => {
    if (currentData.data) {
      const emptyChild = currentData.data.CHILD_COUNT === 0;
      setEmptyChildChapter(emptyChild);
    }
  }, [currentData]);

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        <Table
          title='챕터'
          initialData={initialData}
          backupData={chapterData}
          setData={setInitialData}
          columns={columns}
          tableHeight={tableHeight}
          showToggle={false}
        />
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default TEG10ChapterTable;
