import { useQuery } from '@tanstack/react-query';
import { IQueryParams } from 'models';
import { ISearchBbsDetail } from 'models/interfaces/bbsInterface';
import { client } from 'utils';

function bbsFileList({
  searchData,
  options,
}: IQueryParams<ISearchBbsDetail>) {
  return useQuery({
    queryKey: ['bbs_id', 'file_list', searchData],
    queryFn: async () => {
      const response = await client.get('api/co/cm/800m01/file', {
        params: searchData,
      });
      return response.data.fileList;
    },
    ...options,
  });
}

export default function useBbsFileList(searchData: ISearchBbsDetail) {
  return bbsFileList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: searchData.BBS_ID !== 0,
    },
  });
}
