import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchDrawImages } from 'models';
import { client } from 'utils';

function descriptionDrawList({
  searchData,
  options,
}: IQueryParams<ISearchDrawImages>) {
  return useQuery({
    queryKey: ['device', 'description', 'image', searchData],
    queryFn: async () => {
      const response = await client.get('/api/teg/05/image', {
        params: searchData,
      });
      const { imageList } = response.data;
      return imageList;
    },
    ...options,
  });
}

export default function useDescriptionDrawList(
  searchData: ISearchDrawImages,
) {
  return descriptionDrawList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: searchData.DVC_NO !== 0,
    },
  });
}
