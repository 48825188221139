import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IPlotSaveData } from 'models';
import { client } from 'utils';

function reproducibilitySpecMutation(
  options: UseMutationOptions<unknown, Error, IPlotSaveData, unknown>,
) {
  return useMutation({
    mutationFn: async (plotData: IPlotSaveData) => {
      const response = await client.post(
        '/api/teg/08/reproducibility/spec',
        plotData,
      );
      return response;
    },
    ...options,
  });
}

export default function useReproducibilitySpecMutation() {
  const queryClient = useQueryClient();

  return reproducibilitySpecMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['plot', 'reproducibility_spec'],
      });
    },
  });
}
