import { createColumnHelper } from '@tanstack/react-table';
import useMaskMutation from 'apis/mutations/useMaskMutation';
import useMaskList from 'apis/queries/useMaskList';
import {
  DisplayButton,
  ReadOnlyCheckCell,
  ReadOnlyTextCell,
  StatusCell,
  StatusIcon,
  Table,
  TextCell,
} from 'components';
import { useTableData } from 'hooks';
import {
  Columns,
  CommonArray,
  CommonObject,
  CurrentData,
  ITableContext,
  RowType,
  defaultCurrentData,
} from 'models';
import {
  defaultMask,
  defaultSearchMask,
} from 'models/defaults/maskDefaults';
import { IMask } from 'models/interfaces/maskInterface';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { maskState } from 'store';
import {
  TableContext,
  addRowData,
  addRowNumber,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';

const newRow = defaultMask;

const columnHelper = createColumnHelper();

const TEG02MaskTable = ({
  tableHeight,
  openModal,
}: {
  tableHeight: number;
  openModal: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const [searchData, setSearchData] = useRecoilState(maskState.searchMask);
  const { data: mask } = useMaskList(searchData) as CommonObject;
  const [currentData, setCurrentData] = useRecoilState(
    maskState.currentMaskData,
  );
  const [backupData, setBackupData] = useState<CommonArray>([]);
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: mask,
    atom: maskState.maskList,
    effect: () => {
      const newData = addRowNumber(mask);
      setInitialData(newData);
      setBackupData(newData);
      if (currentData === defaultCurrentData) {
        setCurrentData({ index: 0, data: newData[0] });
      } else {
        setCurrentData(currentData);
      }
    },
  });

  const saveMaskList = useMaskMutation();
  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 10,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('no', {
      header: 'NO',
      cell: ReadOnlyTextCell,
      size: 10,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('MSK_NM', {
      header: '마스크 명',
      cell: TextCell,
      filterFn: 'includesString',
      size: 100,
    }),
    columnHelper.accessor('SHT_MAP_FLAG', {
      header: 'Shot\nMap',
      cell: ReadOnlyCheckCell,
      size: 25,
    }),
    columnHelper.accessor('DIE_MAP_FLAG', {
      header: 'Die\nMap',
      cell: ReadOnlyCheckCell,
      size: 25,
    }),
    columnHelper.display({
      id: 'LRN_YN',
      size: 60,
      header: '이미지',
      cell: DisplayButton,
      meta: {
        text: '관리',
        onClick: openModal,
        disabled: (row: CommonObject) => row.ROWTYPE === RowType.ADD,
      },
    }),
  ];

  const columns = useMemo(() => defaultColumns, []) as Columns;

  const handleAddData = () => {
    addRowData(setInitialData, setCurrentData, newRow, initialData.length);
  };

  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);

    if (removedData.length > 0) {
      setInitialData(removedData);
      selectNextDataByRemove(
        selectData,
        initialData.length - 1,
        removedData,
        setCurrentData,
      );
    }
  };

  const handleSaveData = () => {
    const checkKeys = ['MSK_NM'];
    const targetData = saveTableData(initialData, checkKeys);
    if (targetData.length > 0) {
      saveMaskList.mutate(targetData as IMask[]);
      setSearchData(defaultSearchMask);
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue: ITableContext = {
    handleAddData,
    handleRemoveData,
    handleSaveData,
    currentData,
    setCurrentData,
    openModal,
  };

  return (
    <TableContext.Provider value={contextValue}>
      {initialData && (
        <Table
          title='마스크'
          initialData={initialData}
          backupData={backupData}
          setData={setInitialData}
          columns={columns}
          editable={true}
          tableHeight={tableHeight}
          showToggle={false}
        />
      )}
    </TableContext.Provider>
  );
};

export default TEG02MaskTable;
