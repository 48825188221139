import { useInfiniteQuery } from '@tanstack/react-query';
import {
  ISearchErrorLog,
  UseInfiniteQueryOptionsWithoutQueryKey,
} from 'models';
import { FETCHSIZE, client } from 'utils';

interface IErrorLogListParam {
  searchData: ISearchErrorLog;
  options: UseInfiniteQueryOptionsWithoutQueryKey;
}

function errorLogList({ searchData, options }: IErrorLogListParam) {
  return useInfiniteQuery({
    queryKey: ['errorLog', searchData],
    queryFn: async ({ pageParam }) => {
      const fetchParam = {
        ...searchData,
        PAGE: (pageParam as number) * FETCHSIZE,
        FETCHSIZE: FETCHSIZE,
      };
      const response = await client.get('/api/co/cm/210m01/error/log', {
        params: fetchParam,
      });
      return response.data.errLogList || [];
    },
    ...options,
  });
}

export default function useErrorLogList(searchData: ISearchErrorLog) {
  return errorLogList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      initialPageParam: 0,
      getNextPageParam: (lastPage: any, pages: any) => {
        return lastPage.length < pages[0].length
          ? undefined
          : pages.length;
      },
    },
  });
}
