import { FrameContent } from 'styles';
import { CommonButton, FrameInfo } from 'components';
import FrameInfoSelect from 'components/frame/FrameInfoSelect';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import { descriptionState, selectState } from 'store';
import { editValues, getAlertMessage } from 'utils';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CommonObject, CustomChangeEvent } from 'models';
import { useHeight, useSearchComboList } from 'hooks';
import { useModuleOptions } from 'apis';
import TEG05DescriptionTable from './TEG05DescriptionTable';
import TEG05DrawTable from './TEG05DrawTable';
import { toast } from 'react-toastify';

const defaultEmpty = {
  value: '',
  label: '선택',
};

const TEG04 = (): JSX.Element => {
  const getHeight = useHeight();
  const frameActive =
    useRecoilValue(selectState.selectSideMenu).PGM_ID === 'UIE005TEG05';
  const resetMoveList = useResetRecoilState(
    descriptionState.descriptionMoveList,
  );
  const [searchDescription, setSearchDescription] = useRecoilState(
    descriptionState.searchDescription('teg05'),
  );
  const setSearchDrawImages = useSetRecoilState(
    descriptionState.searchDrawImages,
  );
  const [values, setValues] = useState(searchDescription);
  const projectOptions = useSearchComboList(
    { CMB_CD: frameActive ? 'CMB0029' : '' },
    true,
  );
  const processOptions = useSearchComboList(
    {
      CMB_CD: frameActive && values.PRJ_NO ? 'CMB0030' : '',
      SRCH_PARAM: String(values.PRJ_NO),
    },
    true,
  );
  const deviceOptions = useSearchComboList(
    {
      CMB_CD: frameActive && values.PRC_NO ? 'CMB0031' : '',
      SRCH_PARAM: String(values.PRC_NO),
    },
    true,
  );
  const { data: moduleOptions } = useModuleOptions({
    DVC_NO: frameActive ? String(values.DVC_NO) : '',
    PRJ_NO: '',
    PRC_NO: '',
  }) as CommonObject;

  const editValue = (e: CustomChangeEvent, name?: string) => {
    editValues(e, setValues);
    if (name === 'PRJ_NO')
      setValues(prev => ({
        ...prev,
        PRC_NO: '',
        DVC_NO: '',
        MDL_ID: '',
      }));
    if (name === 'PRC_NO')
      setValues(prev => ({
        ...prev,
        DVC_NO: '',
        MDL_ID: '',
      }));
    if (name === 'DVC_NO')
      setValues(prev => ({
        ...prev,
        MDL_ID: '',
      }));
  };

  useEffect(() => {
    const empty = Object.values(values).some(value => value === '');
    if (empty) {
      resetMoveList();
    }
    updateSearchData(values, setSearchDescription, setSearchDrawImages);
  }, [values]);
  useEffect(() => {
    if (frameActive) setValues(searchDescription);
  }, [frameActive]);

  const handleSearchDescription = () => {
    if (values.MDL_ID === '' || values.MDL_ID === '-1') {
      toast.warning(getAlertMessage('ERRO_00370', ['모듈']));
      return;
    }
    updateSearchData(values, setSearchDescription, setSearchDrawImages);
  };
  const updateSearchData = (
    values: { DVC_NO: string; PRJ_NO: string },
    setSearchDescription: Dispatch<SetStateAction<any>>,
    setSearchDrawImages: Dispatch<SetStateAction<any>>,
  ) => {
    setSearchDescription(values);
    setSearchDrawImages({
      DVC_NO: Number(values.DVC_NO),
      URL: `PRJ/${String(values.PRJ_NO).padStart(9, '0')}/${String(values.DVC_NO).padStart(9, '0')}/`,
      TIME: `?${new Date().getTime()}`,
    });
  };

  return (
    <>
      <FrameInfo>
        <div className='search-form'>
          <div className='input-block'>
            <div className='space-x-2'>
              <FrameInfoSelect
                title='프로젝트'
                options={projectOptions}
                name='PRJ_NO'
                value={values.PRJ_NO}
                onChange={e => editValue(e, 'PRJ_NO')}
              />
              <FrameInfoSelect
                title='공정'
                options={processOptions}
                name='PRC_NO'
                value={values.PRC_NO}
                onChange={e => editValue(e, 'PRC_NO')}
              />
              <FrameInfoSelect
                title='디바이스'
                options={deviceOptions}
                name='DVC_NO'
                value={values.DVC_NO}
                onChange={e => editValue(e, 'DVC_NO')}
              />
              <FrameInfoSelect
                title='모듈'
                options={moduleOptions ? moduleOptions : [defaultEmpty]}
                name='MDL_ID'
                value={values.MDL_ID}
                onChange={editValue}
              />
            </div>
          </div>
          <div>
            <CommonButton
              title='조회'
              onClick={handleSearchDescription}
            />
          </div>
        </div>
      </FrameInfo>
      <FrameContent>
        <div className='w-full space-y-5'>
          <TEG05DescriptionTable tableHeight={getHeight(650)} />
          <TEG05DrawTable tableHeight={getHeight(517)} />
        </div>
      </FrameContent>
    </>
  );
};

export default TEG04;
