import {
  CommonArray,
  IPlotValue,
  defaultSearchPlot,
  defaultSinglePlotParam,
  defaultTrendPlot,
} from 'models';
import { atom, atomFamily } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
  key: 'recoil-persist',
  storage: sessionStorage,
});

const searchPlot = atomFamily({
  key: 'searchPlot',
  default: defaultSearchPlot,
});

const specCodes = atom<CommonArray>({
  key: 'specCodes',
  default: [],
});

const trendPlot = atom({
  key: 'trendPlot',
  default: defaultTrendPlot,
});

const plotValue = atomFamily<IPlotValue, string>({
  key: 'plotValue',
  default: {} as IPlotValue,
  effects_UNSTABLE: [persistAtom],
});

const singlePlotParam = atom({
  key: 'singlePlotParam',
  default: defaultSinglePlotParam,
  effects_UNSTABLE: [persistAtom],
});

const subSpecCodes = atom<CommonArray>({
  key: 'subSpecCodes',
  default: [],
});

export const plotState = {
  searchPlot,
  specCodes,
  trendPlot,
  plotValue,
  singlePlotParam,
  subSpecCodes,
};
