import { SideContainer, SideMenuList } from 'styles';
import SideSearch from './SideSearch';
import { useRecoilValue } from 'recoil';
import { layoutState, menuState } from 'store';
import SideTitleItem from './SideTitleItem';
import SideChildItem from './SideChildItem';
import SideTime from './SideTime';

const Side = (): JSX.Element => {
  const sideMenu = useRecoilValue(menuState.sideMenuByMenu);
  const [sideStyle] = useRecoilValue(layoutState.sideOpenStyle);

  return (
    <SideContainer style={sideStyle}>
      <SideSearch />
      <SideMenuList>
        {sideMenu.map(menu => {
          return (
            <li key={menu.MNU_ID}>
              {menu.PGM_ID === null ? (
                <SideTitleItem menu={menu} />
              ) : (
                <SideChildItem menu={menu} />
              )}
            </li>
          );
        })}
      </SideMenuList>
      <SideTime />
    </SideContainer>
  );
};

export default Side;
