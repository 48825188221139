import { createColumnHelper } from '@tanstack/react-table';
import {
  useAppendixList,
  useAppendixMutation,
  useChapterFileUploadMutation,
  useCommonFileDownloadMutation,
  useWordDocument,
} from 'apis';
import {
  FileUpload,
  FileUploadModal,
  SelectCell,
  StatusCell,
  StatusIcon,
  Table,
  UploadCell,
} from 'components';
import { useTableData } from 'hooks';
import {
  Columns,
  CommonArray,
  CommonObject,
  CurrentData,
  IAppendixParam,
  IChapterFileUploadParam,
  ITableContext,
  defaultAppendix,
  defaultCurrentData,
} from 'models';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { documentState } from 'store';
import { DataTableContainer } from 'styles';
import {
  GUIDE_HEIGHT,
  GUIDE_WIDTH,
  TableContext,
  filteredOptions,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';

const columnHelper = createColumnHelper();
const newAppendix = defaultAppendix;

const TEG10AppendixTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const [openModal, setOpenModal] = useState(false);
  const currentChapter = useRecoilValue(
    documentState.currentDocumentContent,
  );
  const [file, setFile] = useState<File>();
  const [imageUrl, setImageUrl] = useState('');
  const appendixMutation = useAppendixMutation();
  const searchChapterKey = useRecoilValue(documentState.searchChapterKey);
  const fileUploadMutation = useChapterFileUploadMutation('appendix');
  const fileDownloadMutation = useCommonFileDownloadMutation();
  const wordDocumentMutation = useWordDocument();
  const { data: appendixData } = useAppendixList({
    DOC_NO: currentChapter?.data?.DOC_NO,
    DOC_TYPE: currentChapter?.data?.DOC_TYPE,
    CHR_SNO: currentChapter?.data?.CHR_SNO,
    CH_NO: '',
  }) as CommonObject;
  const vopList = appendixData?.vopList;
  const deviceList = appendixData?.deviceList;
  const designList = appendixData?.designList;
  const spList = appendixData?.spList;
  const [backupData, setBackupData] = useState<CommonArray>([]);
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: appendixData?.appendixList,
    effect: () => {
      setInitialData(appendixData?.appendixList);
      setBackupData(appendixData?.appendixList);
      setCurrentData({ index: 0, data: appendixData?.appendixList[0] });
    },
  });
  const [currentData, setCurrentData] = useState(defaultCurrentData);

  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      header: () => <StatusIcon />,
      cell: StatusCell,
      size: 20,
    }),
    columnHelper.accessor('DVC_ID', {
      header: '디바이스',
      cell: SelectCell,
      meta: {
        optionFn: () => filteredOptions(deviceList, 'DVC_NO', 'DVC_NM'),
      },
    }),
    columnHelper.accessor('VOP_CD', {
      header: 'VOP',
      cell: SelectCell,
      meta: {
        optionFn: (row: CommonObject) => {
          const filteredVopList = vopList.filter((vop: CommonObject) =>
            vop.DVC_ARR.includes(row.DVC_ID),
          );
          return filteredOptions(filteredVopList, 'CODE', 'VALUE');
        },
      },
    }),
    columnHelper.accessor('SP', {
      header: 'SP',
      cell: SelectCell,
      meta: {
        optionFn: (row: CommonObject) => {
          const filteredSpList = spList.filter((sp: CommonObject) =>
            sp.DVC_ARR.includes(row.DVC_ID),
          );
          return filteredOptions(filteredSpList, 'SP', 'SP');
        },
      },
    }),
    searchChapterKey.CH_NO === '710000'
      ? columnHelper.accessor('DSG_ID', {
          header: '디자인',
          cell: SelectCell,
          meta: {
            optionFn: (row: CommonObject) => {
              const filteredDesignList = designList.filter(
                (design: CommonObject) =>
                  design.DVC_NO === Number(row.DVC_ID),
              );
              return filteredOptions(filteredDesignList, 'CODE', 'VALUE');
            },
          },
        })
      : columnHelper.accessor('FIG', {
          header: '이미지',
          cell: UploadCell,
          meta: {
            onClick: () => setOpenModal(true),
          },
        }),
  ];
  const columns = useMemo(() => defaultColumns, [initialData]) as Columns;

  const handleAddData = () => {
    setInitialData(old => [...old, newAppendix]);
  };

  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);

    setInitialData(removedData);
    selectNextDataByRemove(
      selectData,
      initialData.length - 1,
      removedData,
      setCurrentData,
    );
  };

  const handleSaveData = () => {
    const targetData = saveTableData(initialData, [], false);

    if (targetData.length > 0) {
      const appendixData = {
        appendixList: targetData,
        CHR_SNO: searchChapterKey.CHR_SNO,
      };

      appendixMutation.mutate(appendixData as IAppendixParam);
    } else {
      setInitialData(initialData);
    }
  };

  const handleGuide = () => {
    const { CH_NO } = searchChapterKey;
    const url = window.location.origin + `/#/guide?CH_NO=${CH_NO}`;
    console.log('teg10AppendixTable - CH_NO : ', CH_NO);
    console.log('teg10AppendixTable - url : ', url);
    window.open(
      url,
      '_blank',
      `width=${GUIDE_WIDTH},height=${GUIDE_HEIGHT}`,
    );
  };

  const handleWordDocument = () => {
    const wordParam = {
      DOC_NO: searchChapterKey.DOC_NO,
      DOC_TYPE: searchChapterKey.DOC_TYPE,
      CHR_NO: searchChapterKey.CH_NO,
    };
    wordDocumentMutation.mutate(wordParam);
  };

  const handleChage = (file: File) => {
    setFile(file);
  };

  const handleUpload = () => {
    if (file) {
      const imageInfo = {
        DOC_NO: searchChapterKey.DOC_NO,
        DOC_TYPE: searchChapterKey.DOC_TYPE,
        CHR_SNO: searchChapterKey.CHR_SNO,
        KEY: 'figure_arr',
        EXT: file.name.split('.')[1],
        IMG_SNO: currentData.index + 1,
      };
      const uploadParam = {
        image: file,
        imageInfo,
      };

      fileUploadMutation.mutate(uploadParam as IChapterFileUploadParam);
    }
  };

  const handleDownload = () => {
    fileDownloadMutation.mutate({ URL: imageUrl });
  };

  const contextValue: ITableContext = {
    handleAddData,
    handleRemoveData,
    handleSaveData,
    handleGuide,
    handleWordDocument,
    currentData,
    setCurrentData,
    customDisabled: {
      guide: searchChapterKey.CH_NO === undefined,
      word: currentChapter?.data?.RMK_CONT !== 'W',
    },
  };

  useEffect(() => {
    if (searchChapterKey.CH_NO === '720000') {
      const { DOC_TYPE, DOC_NO, CHR_SNO } = searchChapterKey;
      const FIG = currentData?.data?.FIG;
      const newUrl = `/DOC/${DOC_TYPE}/${String(DOC_NO).padStart(9, '0')}/${String(CHR_SNO).padStart(9, '0')}/${FIG}`;

      setImageUrl(() => newUrl);
    }
  }, [currentData, fileUploadMutation]);

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        <Table
          title='Appendix Value'
          initialData={initialData}
          backupData={backupData}
          columns={columns}
          setData={setInitialData}
          showToggle={false}
          tableHeight={tableHeight}
        />
      </TableContext.Provider>
      {openModal && (
        <FileUploadModal
          onClose={() => setOpenModal(false)}
          maskCloseable={true}
        >
          <FileUpload
            title='figure'
            isEmpty={currentData?.data.FIG === ''}
            handleChange={handleChage}
            handleUpload={handleUpload}
            handleDownload={handleDownload}
            imageUrl={imageUrl}
          />
        </FileUploadModal>
      )}
    </DataTableContainer>
  );
};

export default TEG10AppendixTable;
