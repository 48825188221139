import { createColumnHelper } from '@tanstack/react-table';
import {
  useChapterDescriptionList,
  useRuleDescriptionMutation,
} from 'apis';
import {
  ReadOnlyTextCell,
  SelectOrTextCell,
  StatusCell,
  StatusIcon,
  Table,
  TextCell,
} from 'components';
import { useTableData } from 'hooks';
import {
  Columns,
  CommonObject,
  CurrentData,
  DESCRIPTION_ID,
  IRuleDescription,
  ITableContext,
  RowType,
  defaultRuleDescription,
} from 'models';
import { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { documentState } from 'store';
import { DataTableContainer } from 'styles';
import {
  TableContext,
  addRowData,
  nextSymbol,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';

const columnHelper = createColumnHelper();
const newDescription = defaultRuleDescription;

const TEG10DescriptionTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchChapterKeyValue = useRecoilValue(
    documentState.searchChapterKeyValue,
  );
  const setSearchRuleLayer = useSetRecoilState(
    documentState.searchRuleLayer,
  );
  const descriptionMutation = useRuleDescriptionMutation();
  const currentChapter = useRecoilValue(
    documentState.currentDocumentContent,
  );
  const currentKey = useRecoilValue(documentState.currentChapterKey);
  const { data: descriptionData } = useChapterDescriptionList({
    CH_NO: currentChapter?.data?.CH_NO,
    CHR_SNO: currentKey?.data.CHR_SNO,
  }) as CommonObject;
  const messageList = descriptionData?.messageList.map(
    (message: CommonObject) => ({
      label: message.VALUE,
      value: message.VALUE,
    }),
  );
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: descriptionData?.ruleList,
    effect: () => {
      setInitialData(descriptionData?.ruleList);
      setCurrentData({ index: 0, data: descriptionData?.ruleList[0] });
    },
  });
  const RGdevice = useRecoilValue(documentState.RGDevice);

  const [currentData, setCurrentData] = useRecoilState(
    documentState.currentRuleDescription,
  );
  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      header: () => <StatusIcon />,
      cell: StatusCell,
      size: 30,
    }),
    columnHelper.accessor('RUL_NO', {
      header: 'Rule No.',
      cell: TextCell,
      size: 70,
    }),
    columnHelper.accessor('DCT_NM', {
      header: 'Description',
      cell: SelectOrTextCell,
      size: 400,
      meta: {
        options: descriptionData && [
          { label: '선택', value: '' },
          ...messageList,
        ],
        optionFilter: true,
      },
    }),
    columnHelper.accessor('LYT_RUL', {
      header: 'Layout Rule(um)',
      cell: TextCell,
      size: 50,
    }),
    columnHelper.accessor('SYM', {
      header: 'Symbol',
      cell: TextCell,
      size: 30,
    }),
    columnHelper.accessor('LAYER', {
      header: 'Layer',
      cell: ReadOnlyTextCell,
      size: 50,
    }),
  ];
  const columns = useMemo(
    () => defaultColumns,
    [descriptionData],
  ) as Columns;

  const handleAddData = () => {
    const lastSymbol = initialData
      .map(data => data.SYM)
      .reduce((acc, current) => (acc > current ? acc : current));
    const symbol = nextSymbol(lastSymbol);
    const updatedDescription = {
      ...newDescription,
      CHR_SNO: currentKey?.data?.CHR_SNO,
      SYM: symbol,
      SRT_SQN: initialData.length + 1,
    };
    addRowData(
      setInitialData,
      setCurrentData,
      updatedDescription,
      initialData.length,
    );
  };

  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);

    if (removedData.length > 0) {
      setInitialData(removedData);
      selectNextDataByRemove(
        selectData,
        initialData.length - 1,
        removedData,
        setCurrentData,
      );
    }
  };

  const handleSaveData = () => {
    const checkKeys = ['DCT_NM', 'SYM'];
    const targetData = saveTableData(initialData, checkKeys);

    if (targetData.length > 0) {
      descriptionMutation.mutate(targetData as IRuleDescription[]);
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue: ITableContext = {
    handleAddData,
    handleRemoveData,
    handleSaveData,
    currentData,
    setCurrentData,
  };

  useEffect(() => {
    if (
      initialData.length > 0 &&
      initialData?.[currentData.index]?.DCT_NM &&
      initialData?.[currentData.index]?.ROWTYPE === RowType.ADD
    ) {
      const currentRow = initialData[currentData.index];
      const selectMessage = descriptionData?.messageList.find(
        (message: CommonObject) => message.VALUE === currentRow.DCT_NM,
      );

      if (selectMessage) {
        const { KEY_CD, FLAG } = selectMessage;
        const { NP_FLAG, VOP, CHR_ID, VOP_CNT } = RGdevice.data;
        const ISO_FLAG = RGdevice.data.ISO_FLAG === 'Y' ? 'I' : '';
        const NBL_FLAG = RGdevice.data.NBL_FLAG === 'Y' ? 'B' : '';

        const ruleNoCount = initialData.filter(
          (description: CommonObject, index: number) =>
            description.DCT_FLAG === FLAG && index !== currentData.index,
        ).length;

        let ruleName = '';
        const ruleNo = `.${FLAG}${ruleNoCount + 1}`;

        if (CHR_ID === DESCRIPTION_ID.DEVICE) {
          ruleName = `E${NP_FLAG}${KEY_CD}${ISO_FLAG}${NBL_FLAG}${VOP}${ruleNo}`;
        } else if (CHR_ID === DESCRIPTION_ID.DIODE) {
          ruleName = `EH${ISO_FLAG}${ruleNo}`;
        } else if (CHR_ID === DESCRIPTION_ID.BJT) {
          if (VOP_CNT > 1) {
            ruleName = `ELPVA${ruleNo}`;
          } else {
            ruleName = `ELP${VOP}${ruleNo}`;
          }
        }

        setInitialData(old =>
          old.map((description: CommonObject, index: number) => {
            if (index === currentData.index) {
              return { ...description, DCT_FLAG: FLAG, RUL_NO: ruleName };
            }
            return description;
          }),
        );
      }
    }
  }, [initialData?.[currentData.index]?.DCT_NM]);

  useEffect(() => {
    if (currentData.data) {
      setSearchRuleLayer(() => ({
        ...searchChapterKeyValue,
        RUL_NO: currentData.data.RUL_NO,
      }));
    }
  }, [currentData]);

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        <Table
          title='Description'
          initialData={initialData}
          backupData={descriptionData?.ruleList}
          setData={setInitialData}
          columns={columns}
          editable={true}
          showToggle={false}
          tableHeight={tableHeight}
        />
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default TEG10DescriptionTable;
