import { IScaleProps } from 'models';
import { LogscaleBox } from 'styles';

const totalScaleItems = [
  { title: 'Y축(좌)', name: 'LOG_LEFT' },
  { title: 'X축(하)', name: 'LOG_BTM' },
  { title: 'X축(상)', name: 'LOG_TOP' },
];

const trendScaleItems = [
  { title: 'X축(하)', name: 'LOG_BTM' },
  { title: 'Y축(좌)', name: 'LOG_LEFT' },
  { title: 'Y축(우)', name: 'LOG_RIGHT' },
];

const legendItems = [
  { title: '범례', name: 'LEGEND_FLAG' },
  { title: '안내선 표시', name: 'LINE' },
];

const TEG08Scale = ({
  type,
  isDC,
  isSingle = false,
  plotValue,
  setPlotScale,
}: IScaleProps): JSX.Element => {
  const scaleItems = type === 'trend' ? trendScaleItems : totalScaleItems;

  return (
    <LogscaleBox>
      <span>로그 스케일 : </span>
      {scaleItems.map((scale, index) => {
        if (isDC && index === 2) return null;
        return (
          <div
            className='logscale-item'
            key={index}
          >
            <input
              id={scale.title}
              type='checkbox'
              name={scale.name}
              checked={plotValue[scale.name] === '1'}
              onChange={setPlotScale}
            />
            <label htmlFor={scale.title}>{scale.title}</label>
          </div>
        );
      })}
      <div className='logscale-item legend-item'>
        {legendItems.map(legend => {
          if (!isSingle && legend.name === 'LINE') return null;
          return (
            <div
              key={legend.name}
              className='logscale-item'
            >
              <input
                id={legend.title}
                type='checkbox'
                name={legend.name}
                checked={plotValue[legend.name] === '1'}
                onChange={setPlotScale}
              />
              <label htmlFor={legend.title}>{legend.title}</label>
            </div>
          );
        })}
      </div>
    </LogscaleBox>
  );
};

export default TEG08Scale;
