import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchMessage } from 'models';
import { client } from 'utils';

function messageList({
  searchData,
  options,
}: IQueryParams<ISearchMessage>) {
  return useQuery({
    queryKey: ['system_reference', 'system_message', searchData],
    queryFn: async () => {
      const response = await client.get('/api/co/cm/130m01/message', {
        params: searchData,
      });
      return response.data.systemMessageList || [];
    },
    ...options,
  });
}

export default function useMessageList(searchData: ISearchMessage) {
  return messageList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
