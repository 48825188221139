import { useEffect, useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Table, ReadOnlyTextCell } from 'components';
import { TableContext } from 'utils';
import { Columns, CommonObject, defaultCurrentData } from 'models';
import { useTableData } from 'hooks';
import { ITableContext } from 'models';
import { descriptionState } from 'store';
import { useSetRecoilState } from 'recoil';

function createDutList(n: number) {
  return Array.from({ length: n }, (_, index) => ({
    DUT_ID: index + 1,
    DUT_NM: `D${(index + 1).toString().padStart(2, '0')}`,
  }));
}

const columnHelper = createColumnHelper();

const dutList = createDutList(12);

const TEG05DutTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const setDutMoveId = useSetRecoilState(descriptionState.dutIdMove);

  const [currentData, setCurrentData] = useState(defaultCurrentData);
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: dutList,
    effect: () => {
      setInitialData(dutList);
      setCurrentData({ index: 0, data: dutList[0] });
    },
  });

  const defaultColumns = [
    columnHelper.accessor('DUT_NM', {
      header: 'DUT',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  useEffect(() => {
    setDutMoveId(currentData?.data?.DUT_ID);
  }, [currentData]);

  const contextValue: ITableContext = {
    currentData,
    setCurrentData,
  };

  return (
    <TableContext.Provider value={contextValue}>
      {initialData && (
        <Table
          title='DUT'
          initialData={initialData}
          setData={setInitialData}
          columns={columns}
          tableHeight={tableHeight}
        />
      )}
    </TableContext.Provider>
  );
};

export default TEG05DutTable;
