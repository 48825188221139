import { useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  StatusIcon,
  Table,
  StatusCell,
  ReadOnlyTextCell,
  CheckInputCell,
  SelectCell,
  TreeCell,
} from 'components';
import {
  TableContext,
  addRowNumber,
  exportJsonToSheet,
  getAlertMessage,
  hasChildren,
  saveTableData,
} from 'utils';
import { useRecoilValue } from 'recoil';
import { useSearchComboList, useTableData } from 'hooks';
import { authorityManagementState } from 'store';
import {
  Columns,
  CommonArray,
  CommonObject,
  CurrentData,
  IAuthorityMenu,
  ITableContext,
  defaultCurrentData,
} from 'models';
import { DataTableContainer } from 'styles';
import { useAuthorityMenuList, useAuthorityMenuMutation } from 'apis';
import { toast } from 'react-toastify';

const columnHelper = createColumnHelper();

const COCM160M02MenuTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchAuthorityMenu = useRecoilValue(
    authorityManagementState.searchAuthorityMenu,
  );
  const [backupData, setBackupData] = useState<CommonArray>([]);
  const { data: authorityMenuData } = useAuthorityMenuList(
    searchAuthorityMenu,
  ) as CommonObject;
  const options = useSearchComboList({ CMB_CD: 'CMB0018' }, true);
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: authorityMenuData,
    atom: authorityManagementState.authorityMenuList,
    effect: () => {
      const newData = addRowNumber(authorityMenuData);
      setInitialData(newData);
      setBackupData(newData);
    },
  });

  const [currentData, setCurrentData] =
    useState<CurrentData>(defaultCurrentData);
  const authorityMenuMutation = useAuthorityMenuMutation();

  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 30,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('no', {
      header: 'No',
      cell: ReadOnlyTextCell,
      size: 30,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('MNU_NM', {
      header: '메뉴',
      cell: TreeCell,
      size: 400,
      filterFn: 'includesString',
      meta: {
        hasChildren: (row: CommonObject) => hasChildren(initialData, row),
      },
    }),
    columnHelper.accessor('INQ_ATH_YN', {
      header: '조회권한',
      cell: CheckInputCell,
      size: 150,
      enableColumnFilter: false,
    }),
    columnHelper.accessor('UPD_ATH_YN', {
      header: '수정권한',
      cell: CheckInputCell,
      size: 150,
      enableColumnFilter: false,
    }),
    columnHelper.accessor('PRNT_ATH_YN', {
      header: '출력권한',
      cell: CheckInputCell,
      size: 150,
      enableColumnFilter: false,
    }),
    columnHelper.accessor('INQ_RNG_DTT_CD', {
      header: '조회범위구분',
      cell: SelectCell,
      meta: {
        disabled: (row: CommonObject) => hasChildren(initialData, row),
        renderKey: 'INQ_RNG_DTT_NM',
        options: options,
      },
      size: 150,
      enableColumnFilter: false,
    }),
  ];
  const columns = useMemo(
    () => defaultColumns,
    [initialData, defaultColumns],
  ) as Columns;

  const handleSaveData = () => {
    const targetData = saveTableData(initialData);
    const invalidData = targetData.find(
      data => !data.PGM_ID && data.INQ_RNG_DTT_CD,
    );

    if (invalidData) {
      setInitialData(authorityMenuData);
      toast.warning(getAlertMessage('INFO_00140'));
      return;
    }

    if (targetData.length > 0) {
      const modifiedData = targetData.map(data => ({
        ...data,
        INQ_RNG_DTT_CD: data.INQ_RNG_DTT_CD ? data.INQ_RNG_DTT_CD : null,
      }));
      authorityMenuMutation.mutate(modifiedData as IAuthorityMenu[]);
    } else {
      setInitialData(authorityMenuData);
    }
  };

  const contextValue: ITableContext = {
    handleExport: () =>
      exportJsonToSheet(columns, initialData, '권한별 메뉴 정보'),
    handleSaveData,
    currentData,
    setCurrentData,
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        <Table
          title='권한별메뉴'
          initialData={initialData}
          backupData={backupData}
          setData={setInitialData}
          columns={columns}
          editable={true}
          tableHeight={tableHeight}
        />
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default COCM160M02MenuTable;
