import { atom, atomFamily, selector } from 'recoil';
import { defaultCurrentData, CommonArray } from 'models';
import { defaultSearchProject } from 'models/defaults/projectDefaults';
import {
  ISearchProcessInModal,
  ISearchProject,
} from 'models/interfaces/projectInterface';

const currentProjectData = atom({
  key: 'projectCurrentData',
  default: defaultCurrentData,
});

const currentProcessData = atom({
  key: 'processCurrentData',
  default: defaultCurrentData,
});

const currentDeviceDataByProject = atom({
  key: 'deviceCurrentDataByProject',
  default: defaultCurrentData,
});

const currentParentProcessData = atom({
  key: 'currentParentProcessData',
  default: defaultCurrentData,
});

const currentProcessDataInModal = atom({
  key: 'currentProcessDataInModal',
  default: defaultCurrentData,
});

const searchData = atomFamily({
  key: 'searchData',
  default: defaultSearchProject,
});

const projectList = atom<CommonArray>({
  key: 'projectList',
  default: [],
});

const processList = atom<CommonArray>({
  key: 'processList',
  default: [],
});

const deviceList = atom<CommonArray>({
  key: 'deviceListByProject',
  default: [],
});

const projectCopyList = atom<CommonArray>({
  key: 'projectCopyList',
  default: [],
});

const parentProcessList = atom<CommonArray>({
  key: 'parentProcessList',
  default: [],
});

const processListInModal = atom<CommonArray>({
  key: 'processListInProjectModal',
  default: [],
});

const searchProcess = selector<ISearchProject>({
  key: 'searchProcess',
  get: ({ get }): ISearchProject => {
    const project = get(currentProjectData);
    if (project && project.data !== undefined) {
      return {
        PRJ_NO: project.data.PRJ_NO,
        ROWTYPE: project.data.ROWTYPE,
        STR_DATE: '',
        PRS_CD_01: '',
        PRS_CD_02: '',
        DVC_NM: '',
      };
    }
    return {
      PRJ_NO: 0,
      ROWTYPE: 0,
      STR_DATE: '',
      PRS_CD_01: '',
      PRS_CD_02: '',
      DVC_NM: '',
    };
  },
});

const searchProcessInModal = selector<ISearchProcessInModal>({
  key: 'searchProcessInModal',
  get: ({ get }): ISearchProcessInModal => {
    const parentProcess = get(currentParentProcessData);
    if (parentProcess && parentProcess.data !== undefined) {
      return {
        PRS_HRK_CD: parentProcess.data.PRS_CD_NM,
      };
    }
    return {
      PRS_HRK_CD: '',
    };
  },
});

export const projectState = {
  searchData,
  projectList,
  processList,
  deviceList,
  projectCopyList,
  currentProjectData,
  currentProcessData,
  currentDeviceDataByProject,
  searchProcess,
  currentParentProcessData,
  parentProcessList,
  currentProcessDataInModal,
  processListInModal,
  searchProcessInModal,
};
