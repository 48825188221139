import { createColumnHelper } from '@tanstack/react-table';
import { useDocumentHistoryList, useDocumentHistoryMutation } from 'apis';
import {
  ReadOnlyTextCell,
  StatusCell,
  StatusIcon,
  Table,
  TextCell,
} from 'components';
import { useTableData } from 'hooks';
import {
  Columns,
  CommonObject,
  CurrentData,
  IDocumentHistory,
  ITableContext,
  defaultCurrentData,
  defaultDocumentHistory,
} from 'models';
import { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { pdkState } from 'store';
import { DataTableContainer } from 'styles';
import {
  TableContext,
  addRowData,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';

const columnHelper = createColumnHelper();
const newHistory = defaultDocumentHistory;

const TEG09DocumentHistoryTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const currentDocument = useRecoilValue(pdkState.currentDocument);
  const documentHistoryMutation = useDocumentHistoryMutation();
  const { data: documentData } = useDocumentHistoryList({
    DOC_NO: currentDocument?.data?.DOC_NO,
  }) as CommonObject;
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: documentData,
  });
  const [currentData, setCurrentData] =
    useState<CurrentData>(defaultCurrentData);
  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      cell: StatusCell,
      size: 25,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('HIS_OCC_DATE', {
      header: '시각',
      cell: ReadOnlyTextCell,
      size: 70,
      filterFn: 'arrIncludes',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('DOC_HIS_CONT', {
      header: '내용',
      cell: TextCell,
      enableColumnFilter: false,
    }),
    columnHelper.accessor('UPD_DATE', {
      header: '작성일자',
      cell: ReadOnlyTextCell,
      size: 80,
      filterFn: 'arrIncludes',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('UPDR_ID', {
      header: '작성자',
      cell: ReadOnlyTextCell,
      size: 80,
      filterFn: 'arrIncludes',
      meta: {
        justify: 'center',
      },
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const handleAddData = () => {
    const updatedHistory = {
      ...newHistory,
      DOC_NO: currentDocument.data.DOC_NO,
    } as IDocumentHistory;
    addRowData(
      setInitialData,
      setCurrentData,
      updatedHistory,
      initialData.length,
    );
  };

  const handleRemoveData = (selectDocument: CurrentData) => {
    const removedData = removeRowData(selectDocument, initialData);

    if (removedData.length > 0) {
      setInitialData(removedData);
      selectNextDataByRemove(
        selectDocument,
        initialData.length - 1,
        removedData,
        setCurrentData,
      );
    }
  };

  const handleSaveData = () => {
    const checkKeys = ['DOC_HIS_CONT'];
    const targetData = saveTableData(initialData, checkKeys);

    if (targetData.length > 0) {
      console.log(targetData);
      documentHistoryMutation.mutate(targetData as IDocumentHistory[]);
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue: ITableContext = {
    handleAddData,
    handleRemoveData,
    handleSaveData,
    currentData,
    setCurrentData,
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        <Table
          title='히스토리'
          initialData={initialData}
          setData={setInitialData}
          backupData={documentData}
          columns={columns}
          editable={true}
          tableHeight={tableHeight}
        />
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default TEG09DocumentHistoryTable;
