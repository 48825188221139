import { createColumnHelper } from '@tanstack/react-table';
import useProcessDetailCodeMutation from 'apis/mutations/useProcessDetailCodeMutation';
import useSubProcessList from 'apis/queries/useSubProcessCodeList';
import { StatusCell, StatusIcon, Table, TextCell } from 'components';
import { useTableData } from 'hooks';
import {
  Columns,
  CommonObject,
  CurrentData,
  ICommonCode,
  ISubProcess,
  ITableContext,
  defaultSubProcess,
} from 'models';
import { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { processState } from 'store/processAtoms';
import { DataTableContainer } from 'styles';
import {
  TableContext,
  addRowData,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';

const columnHelper = createColumnHelper();
const newRow: ISubProcess = defaultSubProcess;
const COCM01SubTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchSubProcessCode = useRecoilValue(
    processState.searchSubProcess,
  );
  const { data: subProcessData } = useSubProcessList(
    searchSubProcessCode,
  ) as CommonObject;
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: subProcessData,
    atom: processState.subProcessList,
    effect: () => {
      setInitialData(subProcessData);
      setCurrentData({ index: 0, data: subProcessData[0] });
    },
  });
  const [currentData, setCurrentData] = useRecoilState(
    processState.currentSubProcess,
  );
  const processDetailCodeMutation = useProcessDetailCodeMutation();
  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 29,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('PRS_CD', {
      header: '중공정 코드',
      size: 300,
      cell: TextCell,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('PRS_CD_NM', {
      header: '중공정 명',
      size: 300,
      cell: TextCell,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('RMK_CONT', {
      header: '비고',
      size: 300,
      cell: TextCell,
      filterFn: 'includesString',
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;
  const handleAddData = () => {
    const modifiedNewRow = {
      ...newRow,
      PRS_HRK_CD: searchSubProcessCode.PRS_HRK_CD,
    };
    addRowData(
      setInitialData,
      setCurrentData,
      modifiedNewRow,
      initialData.length,
    );
  };
  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);

    if (removedData.length > 0) {
      setInitialData(removedData);
      selectNextDataByRemove(
        selectData,
        initialData.length - 1,
        removedData,
        setCurrentData,
      );
    }
  };
  const handleSaveData = () => {
    const checkKeys = ['PRS_CD', 'PRS_CD_NM', 'PRS_HRK_CD'];
    const targetData = saveTableData(initialData, checkKeys, false);
    if (targetData.length > 0) {
      processDetailCodeMutation.mutate(targetData as ICommonCode[]);
    } else {
      setInitialData(initialData);
    }
  };
  const contextValue: ITableContext = {
    handleAddData,
    handleRemoveData,
    handleSaveData,
    currentData,
    setCurrentData,
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        {initialData && (
          <Table
            title='중공정 정보'
            initialData={initialData}
            backupData={subProcessData}
            setData={setInitialData}
            columns={columns}
            editable={true}
            tableHeight={tableHeight}
          />
        )}
      </TableContext.Provider>
    </DataTableContainer>
  );
};
export default COCM01SubTable;
