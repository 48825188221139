import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchAuth } from 'models';
import { client } from 'utils';

function possibleAuthorityList({
  searchData,
  options,
}: IQueryParams<ISearchAuth>) {
  return useQuery({
    queryKey: ['system_setting', 'user_possible', searchData],
    queryFn: async () => {
      const response = await client.get(
        '/api/co/cm/170m02/user/authority/unassigned',
        { params: searchData },
      );
      return response.data.userPossibleAuthorityList || [];
    },
    ...options,
  });
}

export default function usePossibleAuthorityList(searchData: ISearchAuth) {
  return possibleAuthorityList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: searchData.USR_NM !== undefined && searchData.USR_NM !== '',
    },
  });
}
