import { createColumnHelper } from '@tanstack/react-table';
import { useDesignRuleLayerList, useRuleLayerMutation } from 'apis';
import { SelectCell, StatusCell, StatusIcon, Table } from 'components';
import { useSearchComboList, useTableData } from 'hooks';
import {
  Columns,
  CommonObject,
  CurrentData,
  IRuleDescription,
  IRuleLayerParam,
  ITableContext,
  defaultCurrentData,
  defaultRuleLayer,
} from 'models';
import { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { documentState } from 'store';
import { DataTableContainer } from 'styles';
import {
  TableContext,
  addRowData,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';

const columnHelper = createColumnHelper();
const newLayer = defaultRuleLayer;

const TEG10LayerTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const currentRuleDescription = useRecoilValue(
    documentState.currentRuleDescription,
  );
  const layerMutation = useRuleLayerMutation();
  const { data: ruleLayerData } = useDesignRuleLayerList({
    CHR_SNO: currentRuleDescription?.data?.CHR_SNO,
    RUL_NO: currentRuleDescription?.data?.RUL_NO,
  }) as CommonObject;
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: ruleLayerData,
  });

  const [currentData, setCurrentData] = useState(defaultCurrentData);
  const layerOptions = useSearchComboList({ CMB_CD: 'CMB0041' }, true);

  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      header: () => <StatusIcon />,
      size: 25,
      cell: StatusCell,
    }),
    columnHelper.accessor('LYR_CD', {
      header: '레이어',
      size: 500,
      cell: SelectCell,
      meta: {
        options: layerOptions,
      },
    }),
  ];
  const columns = useMemo(() => defaultColumns, [layerOptions]) as Columns;

  const handleAddData = () => {
    addRowData(
      setInitialData,
      setCurrentData,
      newLayer,
      initialData.length,
    );
  };

  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);

    if (removedData.length > 0) {
      setInitialData(removedData);
      selectNextDataByRemove(
        selectData,
        initialData.length - 1,
        removedData,
        setCurrentData,
      );
    }
  };

  const handleSaveData = () => {
    const targetData = saveTableData(initialData, [], false);

    if (targetData.length > 0) {
      const layerData = {
        rule: currentRuleDescription.data as IRuleDescription,
        layerList: targetData,
      };
      layerMutation.mutate(layerData as IRuleLayerParam);
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue: ITableContext = {
    handleAddData,
    handleRemoveData,
    handleSaveData,
    currentData,
    setCurrentData,
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        <Table
          title='Layer'
          initialData={initialData}
          backupData={ruleLayerData}
          setData={setInitialData}
          columns={columns}
          showToggle={false}
          tableHeight={tableHeight}
        />
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default TEG10LayerTable;
