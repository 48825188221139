import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchCombo } from 'models';
import { client } from 'utils';

function searchComboList({
  searchData,
  options,
}: IQueryParams<ISearchCombo>) {
  return useQuery({
    queryKey: ['system_setting', 'comboList', searchData],
    queryFn: async () => {
      const response = await client.get('/api/co/cm/430m01/combo', {
        params: searchData,
      });
      return response.data.comboList;
    },
    ...options,
  });
}

export default function useSearchCombo(searchData: ISearchCombo) {
  return searchComboList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
