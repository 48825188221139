import { useQuery } from '@tanstack/react-query';
import { IQueryParams } from 'models';
import { ISearchPrivateFile } from 'models/interfaces/privateBbsInterface';
import { client } from 'utils';

function privateFileList({
  searchData,
  options,
}: IQueryParams<ISearchPrivateFile>) {
  return useQuery({
    queryKey: ['dir_list', 'file_list', searchData],
    queryFn: async () => {
      const response = await client.get('api/co/cm/900m01/file', {
        params: searchData,
      });
      return response.data.fileList;
    },
    ...options,
  });
}

export default function usePrivateFileList(
  searchData: ISearchPrivateFile,
) {
  return privateFileList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
