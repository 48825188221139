import { useQuery } from '@tanstack/react-query';
import { IQueryParams } from 'models';
import { client } from 'utils';

function fileCheck(
  { searchData, options }: IQueryParams<string>,
  flag?: boolean,
) {
  const queryKey = ['module', 'file', searchData];
  if (flag !== undefined) queryKey.push(`${flag}`);
  return useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      const response = await client.get(
        `/api/teg/04/file/check?filePath=${searchData}`,
      );
      const { fileCheckList } = response.data;
      return {
        fileCheckList,
        searchData,
        flag,
      };
    },
    ...options,
  });
}

export default function useFileCheck(filePath: string, flag?: boolean) {
  return fileCheck(
    {
      searchData: filePath,
      options: {
        retry: false,
        refetchOnMount: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
        enabled: filePath !== '',
      },
    },
    flag,
  );
}
