import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IUserAuthorityMenuWithFlag } from 'models';
import { client } from 'utils';

export function userAuthorityMutation(
  options: UseMutationOptions<
    unknown,
    Error,
    IUserAuthorityMenuWithFlag[],
    unknown
  >,
) {
  return useMutation({
    mutationFn: async userAuthority => {
      const response = await client.post(
        '/api/co/cm/170m01/user/authority',
        userAuthority,
      );
      return response;
    },
    ...options,
  });
}

export default function useUserAuthorityMutation() {
  const queryClient = useQueryClient();

  return userAuthorityMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['system_setting', 'user_authority'],
      });
    },
  });
}
