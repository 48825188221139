import {
  IMesureDC,
  IMesureTLP,
  IPlotParam,
  ISearchPlot,
  ISinglePlotParam,
  ITrendPlot,
  RowType,
} from 'models';

export const defaultSearchPlot: ISearchPlot = {
  PRJ_NO: '',
  PRC_NO: '',
  DVC_NO: '',
  DSG_ID: '',
  DUT_ID: '',
  MSR_TYPE: '',
};

export const defaultMesureType = [
  { label: '선택', value: '' },
  { label: 'TLP Reverse', value: 'TR' },
  { label: 'TLP Forword', value: 'TF' },
  { label: 'DC', value: 'DC' },
];

export const defaultPlotParam: IPlotParam = {
  DVC_NO: '',
  DSG_ID: '',
  MSR_TYPE: '',
};

export const defaultTrendPlot: ITrendPlot = {
  DVC_NO: '',
  DSG_ID: '',
  MSR_TYPE: '',
  PLOT_FLAG: 'Y',
  SPC_TRN_AXIS_YL: '',
  SPC_TRN_AXIS_YR: '',
};

export const defaultSinglePlotParam: ISinglePlotParam = {
  DVC_NO: '',
  DSG_ID: '',
  MSR_TYPE: '',
  DUT_ID: '',
  MSR_NO: '',
  LINE: 0,
  VOP: 0,
  LEGEND_FLAG: 0,
  LOG_BTM: '0',
  LOG_LEFT: '0',
  // LOG_RIGHT: '0',
  LOG_TOP: '0',
  LEGEND_FIRST: 'V_dut',
  LEGEND_SECOND: 'Leakage',
};

export const defaultMesureTLP: IMesureTLP = {
  VDUT: 0,
  Y: 0,
  LKG: 0,
  ROWTYPE: RowType.ADD,
};

export const defaultMesureDC: IMesureDC = {
  VJ: 0,
  IJ: 0,
  ROWTYPE: RowType.ADD,
};
