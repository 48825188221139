import tw from 'twin.macro';
import styled from 'styled-components';

export const Button = styled.button<{ $color?: string }>`
  ${tw`
      rounded-md
      py-[0.15rem]
      px-[0.6rem]
      ml-[5px]
      text-white
      text-sm
      disabled:bg-slate-400
      disabled:hover:bg-slate-400
    `}
  background-color: ${({ $color }) => $color || '#040e68'};
  opacity: 0.85;
  &:hover {
    transition: transform 150ms ease;
    background-color: ${({ $color }) => $color || '#040e68'};
    opacity: 1;
  }
`;
export const FileDialogButton = styled.button`
  ${tw`
    w-[30px]
    border-x
    border-b
    border-r
    border-t
    bg-white
  `}
`;

export const ModalWrapper = styled.div`
  ${tw`
    box-border
    fixed
    top-0
    bottom-0
    left-0
    right-0
    z-[1000]
    overflow-auto
    outline-none
  `}
`;

export const ModalOverlay = styled.div`
  ${tw`
    box-border
    fixed
    top-0
    bottom-0
    left-0
    right-0
    z-[999]
  `}
  background-color: rgba(0, 0, 0, 0.6);
`;

export const ModalInner = styled.div`
  ${tw`
    box-border
    relative
    bg-white
    rounded-lg
    m-auto
    py-[1.5rem]
    px-[0.8rem]
    overflow-hidden
  `}
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
`;

export const ModalSearchBox = styled.div`
  ${tw`
    flex
    justify-between
    items-center
    border-y
    border-slate-200
    p-2
    bg-slate-100
  `}

  .field-box {
    ${tw`
      flex
      items-center
      text-[0.8rem]
      text-slate-700
    `}

    select, input {
      ${tw`
        border
        rounded-md
        h-[25px]
        outline-none

        hover:border-slate-400
        focus:border-slate-400
      `}
    }

    input {
      ${tw`
        ml-1
        pl-1
        w-[300px]
      `}
    }

    select {
      ${tw`
        ml-2
        w-[100px]
        whitespace-nowrap
        overflow-hidden
      `}
    }
  }
`;

export const ModalTableBox = styled.div`
  ${tw`
    mt-2
  `}
  .count-box {
    ${tw`
      text-xs
      text-slate-600
      mb-1
   `}
  }
  .count {
    ${tw`
      text-blue-500
      font-bold
      ml-2
    `}
  }

  tbody > tr {
    ${tw`
      hover:bg-blue-50
    `}
  }
`;

export const ModalIcon = styled.button`
  ${tw`
    ml-1
    flex
    items-center
    justify-center
    w-[25px]
    h-full
    bg-white
    border
    cursor-pointer
    hover:border-slate-400
  `}
`;

export const LoadingBox = styled.div`
  ${tw`
    absolute
    flex
    items-center
    justify-center
  `}

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    width: 100px;
    height: 100px;
  }
`;

export const ContentTabContainer = styled.div`
  ${tw`
    flex
    gap-x-[2px]
    mb-1
  `}
`;

export const ContentTabTitle = styled.div`
  ${tw`
      bg-blue-950
      text-white
      py-[1px]
      rounded-t-md
      px-3
      opacity-60
      cursor-pointer
      
      hover:opacity-100 
    `}

  &.active {
    opacity: 1;
  }
`;

export const FileUploadTitle = styled.div`
  ${tw`
    w-fit
    text-sm
    text-blue-950
    border-b
    border-blue-950
    p-1
    mb-1
  `}
`;

export const FileUploadControlBox = styled.div`
  ${tw`
    flex
    justify-between
    items-center
  `}

  .file-name {
    ${tw`
      outline-none
      border
      rounded-md
      text-sm
      py-[1px]
      pl-1
    `}
  }
`;

export const FileUploadImage = styled.div`
  ${tw`
    w-full
    h-[350px]
    mt-1
    flex 
    justify-center
    items-center
  `}

  div {
    ${tw`
      w-full
      h-full
      border
      border-gray-400
      flex
      justify-center
      items-center
    `}
  }

  img {
    width: fit-content;
    height: fit-content;
    max-width: 100%;
    max-height: 100%;
  }
`;

export const ProgressBarBox = styled.div`
  ${tw`
    flex
    border
    rounded-lg
    py-[0.1rem]
    px-[0.1rem]
    items-center
  `}
  position: relative;
  width: 500px;
  height: 30px;
  background-color: white;
`;
export const Dealt = styled.div<{ $dealt: number }>`
  ${tw`
    flex  
    border
    rounded-lg
    bg-blue-400
  `}
  position: absolute;
  opacity: 0.45;
  width: ${({ $dealt }) => $dealt + '%' || '0%'};
  height: 100%;
`;

export const AdditionalDealt = styled.div<{ $dealt: number }>`
  position: absolute;
  width: ${({ $dealt }) => $dealt + '%'};
  height: 100%;
  border-right: 2px solid white;
  pointer-events: none;
`;
