import { LoginFormContext } from 'features/login/LoginForm';
import { useForm } from 'hooks';
import { CommonObject, CustomMouseEvent } from 'models';
import HeaderUserPasswordField from './HeaderUserPasswordField';
import { CommonButton } from 'components';

const formProps = {
  initialValues: {
    old: '',
    new: '',
    confirm: '',
  },

  validate: (password: CommonObject) => {
    const errors = {
      old: '',
      new: '',
      confirm: '',
    };

    if (password.new !== password.confirm) {
      errors.confirm = '비밀번호 일치하지 않습니다.';
    }
    if (password.old === password.new) {
      errors.new = '이전 비밀번호와 동일합니다.';
    }

    return errors;
  },
  onSubmit: (values: CommonObject) => {
    console.log(values + ' :: 수정완료');
  },
};

const HeaderUserPasswordForm = ({
  handleEditPassword,
}: {
  handleEditPassword: (e: CustomMouseEvent) => void;
}): JSX.Element => {
  const formValue = useForm(formProps);
  return (
    <div>
      <LoginFormContext.Provider value={formValue}>
        <form
          className='info-form'
          onSubmit={formValue.handleSubmit}
        >
          <HeaderUserPasswordField
            name='old'
            placeholder='현재 비밀번호'
          />
          <HeaderUserPasswordField
            name='new'
            placeholder='새 비밀번호'
          />
          <HeaderUserPasswordField
            name='confirm'
            placeholder='새 비밀번호 확인'
          />
          <div className='text-xs py-1'>최소 4자 이상</div>
          <div>
            <CommonButton
              title='변경'
              type='submit'
            />
            <CommonButton
              title='취소'
              type='button'
              color='#dc2626'
              onClick={handleEditPassword}
            />
          </div>
        </form>
      </LoginFormContext.Provider>
    </div>
  );
};

export default HeaderUserPasswordForm;
