import { createColumnHelper } from '@tanstack/react-table';
import { useMainProcessCodeList } from 'apis';
import useProcessCodeMutation from 'apis/mutations/useProcessCodeMutation';
import { StatusCell, StatusIcon, Table, TextCell } from 'components';
import { useTableData } from 'hooks';
import {
  Columns,
  CommonObject,
  CurrentData,
  ICommonCode,
  IMainProcess,
  ITableContext,
  defaultMainProcess,
  defaultSearchMainProcess,
} from 'models';
import { useEffect, useMemo } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { processState } from 'store/processAtoms';
import { DataTableContainer } from 'styles';
import {
  TableContext,
  addRowData,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';

const columnHelper = createColumnHelper();
const newRow: IMainProcess = defaultMainProcess;

const COCM01MainTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const [searchMainProcess, setSearchMainProcess] = useRecoilState(
    processState.searchMainProcess('COCM01'),
  );
  const { data: mainProcessData } = useMainProcessCodeList(
    searchMainProcess,
  ) as CommonObject;

  const [currentData, setCurrentData] = useRecoilState(
    processState.currentMainProcess,
  );
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: mainProcessData,
    atom: processState.mainProcessList,
    effect: () => {
      setInitialData(mainProcessData);
      setCurrentData({ index: 0, data: mainProcessData[0] });
    },
  });

  const setSearchSubProcess = useSetRecoilState(
    processState.searchSubProcess,
  );
  useEffect(() => {
    if (currentData.data) {
      setSearchSubProcess({ PRS_HRK_CD: currentData.data.PRS_CD });
    }
  }, [currentData]);

  const processCodeMutation = useProcessCodeMutation();

  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 29,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('PRS_CD', {
      header: '대공정 코드',
      cell: TextCell,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('PRS_CD_NM', {
      header: '대공정 명',
      cell: TextCell,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('RMK_CONT', {
      header: '비고',
      cell: TextCell,
      filterFn: 'includesString',
    }),
  ];

  const columns = useMemo(() => defaultColumns, []) as Columns;

  const handleAddData = () => {
    addRowData(setInitialData, setCurrentData, newRow, initialData.length);
  };
  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);

    if (removedData.length > 0) {
      setInitialData(removedData);
      selectNextDataByRemove(
        selectData,
        initialData.length - 1,
        removedData,
        setCurrentData,
      );
    }
  };
  const handleSaveData = () => {
    const checkKeys = ['PRS_CD', 'PRS_CD_NM'];
    const targetData = saveTableData(initialData, checkKeys, true);
    if (targetData.length > 0) {
      processCodeMutation.mutate(targetData as ICommonCode[]);
      setSearchMainProcess(defaultSearchMainProcess);
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue: ITableContext = {
    handleAddData,
    handleRemoveData,
    handleSaveData,
    currentData,
    setCurrentData,
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        {initialData && (
          <Table
            title='대공정 정보'
            initialData={initialData}
            backupData={mainProcessData}
            setData={setInitialData}
            columns={columns}
            editable={true}
            tableHeight={tableHeight}
          />
        )}
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default COCM01MainTable;
