import { memo } from 'react';
import { AdditionalDealt, Dealt, ProgressBarBox } from 'styles';

const ProgressBar = ({
  percentage,
}: {
  percentage: number;
}): JSX.Element => {
  const dealt = percentage;
  return (
    <div className='flex'>
      {dealt}%
      <ProgressBarBox>
        <Dealt $dealt={dealt}></Dealt>
        {Array.from({ length: 19 }).map((_, index) => (
          <AdditionalDealt
            key={index * 5}
            $dealt={(index + 1) * 5}
          />
        ))}
      </ProgressBarBox>
    </div>
  );
};

export default memo(ProgressBar);
