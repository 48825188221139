import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { ISearchComboData } from 'models';
import { client } from 'utils';

function comboMutation(
  options: UseMutationOptions<unknown, Error, ISearchComboData[], unknown>,
) {
  return useMutation({
    mutationFn: async comboList => {
      const response = await client.post(
        '/api/co/cm/430m01/combo',
        comboList,
      );
      return response;
    },
    ...options,
  });
}

export default function useSearchComboMutation() {
  const queryClient = useQueryClient();

  return comboMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['system_setting', 'comboList'],
      });
    },
  });
}
