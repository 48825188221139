import { useContext } from 'react';
import { TableContext } from 'utils';

export default function useTableContext() {
  const tableContext = useContext(TableContext);

  if (!tableContext) {
    throw new Error('Please define a Provider to use tableContext.');
  }

  const {
    editType,
    handleExport,
    handleGuide,
    handleDuplicateData,
    handleTegDoc,
    handleAnalysisDoc,
    handleDescription,
    handleDraw,
    handleCoordinate,
    handleRegister,
    handleResetData,
    handleAddData,
    handleSaveData,
    handleUpload,
    handleRemoveData,
    handleDoubleClick,
    handleWordDocument,
    handleWordDownload,
    handleCellColor,
    handleToolTipText,
    openModal,
    handleSelectInfo,
    currentData,
    setCurrentData,
    customDisabled,
    selectInfoOptions,
    selectedDataList,
    setSelectedDataList,
    dragStart,
    setDragStart,
  } = tableContext;

  return {
    editType,
    handleExport,
    handleGuide,
    handleDuplicateData,
    handleTegDoc,
    handleAnalysisDoc,
    handleDescription,
    handleDraw,
    handleCoordinate,
    handleRegister,
    handleResetData,
    handleAddData,
    handleSaveData,
    handleUpload,
    handleRemoveData,
    handleDoubleClick,
    handleWordDocument,
    handleWordDownload,
    handleCellColor,
    handleToolTipText,
    openModal,
    handleSelectInfo,
    currentData,
    setCurrentData,
    customDisabled,
    selectInfoOptions,
    selectedDataList,
    setSelectedDataList,
    dragStart,
    setDragStart,
  };
}
