import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Table,
  StatusCell,
  StatusIcon,
  TextCell,
  ReadOnlyCheckCell,
  DisplayButton,
  ReadOnlyTextCell,
} from 'components';
import {
  TableContext,
  addRowData,
  addRowNumber,
  getAlertMessage,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTableData } from 'hooks';
import { maskState } from 'store';
import {
  Columns,
  CommonArray,
  CommonObject,
  CurrentData,
  ITableContext,
  RowType,
  defaultCurrentData,
} from 'models';
import useCodeList from 'apis/queries/useMaskCodeList';
import useCodeMutation from 'apis/mutations/useMaskCodeMutation';
import { IMaskCode } from 'models/interfaces/maskInterface';
import { defaultMaskCode } from 'models/defaults/maskDefaults';
import { toast } from 'react-toastify';

const newRow = defaultMaskCode;

const columnHelper = createColumnHelper();

const TEG02CodeTable = ({
  tableHeight,
  openModal,
  openSubModal,
}: {
  tableHeight: number;
  openModal: Dispatch<SetStateAction<boolean>>;
  openSubModal: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const searchData = useRecoilValue(maskState.searchCode);
  const { data: code } = useCodeList(searchData) as CommonObject;
  const [currentData, setCurrentData] = useRecoilState(
    maskState.currentCodeData,
  );
  const [backupData, setBackupData] = useState<CommonArray>([]);
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: code,
    atom: maskState.codeList,
    effect: () => {
      const newData = addRowNumber(code);
      setInitialData(newData);
      setBackupData(newData);
      if (
        currentData === defaultCurrentData ||
        currentData?.data?.MSK_CD !== searchData.MSK_CD
      ) {
        setCurrentData({ index: 0, data: newData[0] });
      } else {
        setCurrentData(currentData);
      }
    },
  });
  const saveCodeList = useCodeMutation();
  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 20,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('no', {
      header: 'NO',
      cell: ReadOnlyTextCell,
      size: 20,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('CODE_NM', {
      header: '코드 명',
      cell: TextCell,
      filterFn: 'includesString',
      size: 120,
    }),
    columnHelper.accessor('CODE_LAYOUT_FLAG', {
      header: 'Code\nLayout',
      cell: ReadOnlyCheckCell,
      size: 40,
    }),
    columnHelper.accessor('MDL_ARRAY_FLAG', {
      header: 'Module\nArray',
      cell: ReadOnlyCheckCell,
      size: 40,
    }),
    columnHelper.accessor('PAD_ASSIGN_FLAG', {
      header: 'PAD\nAssign',
      cell: ReadOnlyCheckCell,
      size: 40,
    }),
    columnHelper.accessor('GDS_FLAG', {
      header: 'GDS\nFile',
      cell: ReadOnlyCheckCell,
      size: 40,
    }),
    columnHelper.accessor('DUT_SUB', {
      header: 'DUT\n좌표',
      cell: ReadOnlyCheckCell,
      size: 40,
    }),
    columnHelper.display({
      size: 60,
      header: '이미지',
      cell: DisplayButton,
      meta: {
        text: '관리',
        onClick: openModal,
        disabled: (row: CommonObject) => row.ROWTYPE === RowType.ADD,
      },
    }),
    columnHelper.display({
      size: 60,
      header: 'DUT좌표',
      cell: DisplayButton,
      meta: {
        text: '관리',
        onClick: openSubModal,
        disabled: (row: CommonObject) => row.ROWTYPE === RowType.ADD,
      },
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const handleAddData = () => {
    if (searchData.MSK_CD === undefined) {
      toast.warning(getAlertMessage('ERRO_00380', ['마스크']));
      return;
    }
    const copyRow = { ...newRow };
    copyRow.MSK_CD = searchData?.MSK_CD;
    const newCodeSno =
      initialData.reduce((max, item) => {
        return item.CODE_SNO > max ? item.CODE_SNO : max;
      }, 0) + 1;
    copyRow.CODE_SNO = newCodeSno;
    addRowData(
      setInitialData,
      setCurrentData,
      copyRow,
      initialData.length,
    );
  };

  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);

    if (removedData.length > 0) {
      setInitialData(removedData);
      selectNextDataByRemove(
        selectData,
        initialData.length - 1,
        removedData,
        setCurrentData,
      );
    }
  };

  const handleSaveData = () => {
    const checkKeys = ['CODE_NM'];
    const targetData = saveTableData(initialData, checkKeys);

    if (targetData.length > 0) {
      saveCodeList.mutate(targetData as IMaskCode[]);
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue: ITableContext = {
    handleAddData,
    handleRemoveData,
    handleSaveData,
    currentData,
    setCurrentData,
    customDisabled: {
      fetched: searchData.MSK_CD === undefined || searchData.MSK_CD === 0,
    },
    openModal,
  };

  return (
    <TableContext.Provider value={contextValue}>
      {initialData && (
        <Table
          title='코드정보'
          initialData={initialData}
          backupData={backupData}
          setData={setInitialData}
          columns={columns}
          editable={true}
          tableHeight={tableHeight}
          showToggle={false}
        />
      )}
    </TableContext.Provider>
  );
};

export default TEG02CodeTable;
