import { useHeight } from 'hooks';
import { useEffect, useState } from 'react';
import { CommonButton, FrameInfo, FrameInfoInput, Tree } from 'components';
import { useRecoilState } from 'recoil';
import { convertDataToTree, editValues, preConvertData } from 'utils';
import COCM170M02UserTable from './COCM170M02UserTable';
import COCM170M02AssignTable from './COCM170M02AssignTable';
import COCM170M02Switch from './COCM170M02Switch';
import COCM170M02PossibleTable from './COCM170M02PossibleTable';
import { FrameContent } from 'styles';
import { systemManagementState } from 'store';
import { useDepartmentCodeList } from 'apis';
import {
  CommonArray,
  CommonObject,
  CustomChangeEvent,
  defaultSearchDepartmentCode,
} from 'models';

const targetKey = 'DPT_CD';
const parentKey = 'HRK_DPT_CD';
const renderKey = 'DPT_NM';

const COCM170M02 = (): JSX.Element => {
  const getHeight = useHeight();
  const { data: treeCodeData, isLoading } = useDepartmentCodeList(
    defaultSearchDepartmentCode,
  ) as CommonObject;
  const [treeCodeList, setTreeCodeList] = useState<CommonArray>([]);
  const [currentDepartment, setCurrentDepartment] = useRecoilState(
    systemManagementState.currentDepartmentCode('COCM170M02'),
  );
  const [searchUser, setSearchUser] = useRecoilState(
    systemManagementState.searchUser('COCM170M02'),
  );
  const [values, setValues] = useState(searchUser);
  const editValue = (e: CustomChangeEvent) => editValues(e, setValues);

  const handleSearchUserAuth = () => {
    setSearchUser(values);
  };

  useEffect(() => {
    if (treeCodeData) {
      const treeList = preConvertData(treeCodeData, parentKey);
      setTreeCodeList(convertDataToTree(treeList, parentKey, targetKey));
    }
  }, [treeCodeData]);

  useEffect(() => {
    if (treeCodeList.length > 0) {
      const firstDepartment = treeCodeList[0];

      setCurrentDepartment({ index: 0, data: firstDepartment });
      setSearchUser(old => ({
        ...old,
        DPT_CD: firstDepartment.DPT_CD,
      }));
      setValues(old => ({
        ...old,
        DPT_CD: firstDepartment.DPT_CD,
      }));
    }
  }, [treeCodeList]);

  return (
    <>
      <FrameInfo>
        <div className='search-form'>
          <div className='input-block'>
            <FrameInfoInput
              title='사용자명'
              type='text'
              name='USR_NM'
              value={values?.USR_NM || ''}
              onChange={editValue}
            />
            <FrameInfoInput
              title='부서명'
              type='text'
              name='DPT_NM'
              value={values?.DPT_NM || ''}
              onChange={editValue}
            />
          </div>
        </div>
        <div>
          <CommonButton
            title='조회'
            onClick={handleSearchUserAuth}
          />
        </div>
      </FrameInfo>
      <FrameContent>
        <Tree
          title='조직도'
          isLoading={isLoading}
          data={treeCodeList}
          currentData={currentDepartment}
          setCurrentData={setCurrentDepartment}
          nodeKey={targetKey}
          parentKey={parentKey}
          renderKey={renderKey}
          height={getHeight(670)}
        />
        <div className='grow-col-between'>
          <COCM170M02UserTable tableHeight={getHeight(320)} />
          <div className='flex-between-2'>
            <COCM170M02AssignTable tableHeight={getHeight(320)} />
            <COCM170M02Switch />
            <COCM170M02PossibleTable tableHeight={getHeight(320)} />
          </div>
        </div>
      </FrameContent>
    </>
  );
};

export default COCM170M02;
