import { CustomMouseEvent, ISideMenu } from 'models';
import { KeyboardEvent, useEffect, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';
import { menuState, selectState } from 'store';
import { SearchList, SideSearchBox } from 'styles';

const SideSearch = (): JSX.Element => {
  const sideMenu = useRecoilValue(menuState.sideMenu);
  const selectMenuCategory = useRecoilValue(selectState.selectMenu);
  const [selectSideMenu, setSelectSideMenu] = useRecoilState(
    selectState.selectTargetMenu,
  );
  const tabMenu = useRecoilValue(menuState.tabMenu);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchList, setSearchList] = useState<ISideMenu[]>([]);
  const maxLength = 10;

  const handleSearch = (
    e: KeyboardEvent<HTMLInputElement> | CustomMouseEvent,
  ) => {
    if (searchValue.length === 0) return;

    if (('key' in e && e.key === 'Enter') || e.type === 'click') {
      const searchMenu = sideMenu.filter(
        menu => menu.PGM_ID !== null && menu.MNU_NM.includes(searchValue),
      );

      setSearchList(searchMenu);
    }
  };

  const resetSearchState = () => {
    setSearchList([]);
    setSearchValue('');
  };

  const handleClick = (e: CustomMouseEvent, targetMenu: ISideMenu) => {
    e.stopPropagation();

    const isSelected = selectSideMenu.MNU_ID === targetMenu.MNU_ID;
    const alreadySelect = tabMenu.find(
      menu => menu.MNU_ID === targetMenu.MNU_ID,
    );

    if (isSelected) {
      toast.warning('현재 선택된 메뉴와 동일한 메뉴입니다.');
      resetSearchState();
      return;
    }

    if (alreadySelect) {
      toast.warning('이미 선택되어 탭에 존재하는 메뉴입니다.');
      resetSearchState();
      return;
    }

    if (tabMenu.length === maxLength) {
      toast.warning('메뉴는 최대 10개까지만 선택할 수 있습니다.');
      resetSearchState();
      return;
    }

    resetSearchState();
    setSelectSideMenu({ value: targetMenu, type: 'search' });
  };

  useEffect(() => {
    setSearchValue('');
  }, [selectMenuCategory]);

  return (
    <SideSearchBox>
      <input
        type='text'
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        onKeyDown={handleSearch}
      />
      <BiSearch onClick={handleSearch} />
      <SearchList>
        {searchList.map(menu => (
          <div
            key={menu.MNU_ID}
            className='search-item'
            onClick={e => handleClick(e, menu)}
          >
            {menu.MNU_NM}
          </div>
        ))}
      </SearchList>
    </SideSearchBox>
  );
};

export default SideSearch;
