import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchModule } from 'models';
import { client } from 'utils';

function moduleOptions({
  searchData,
  options,
}: IQueryParams<ISearchModule>) {
  return useQuery({
    queryKey: ['description', 'module', 'option', searchData],
    queryFn: async () => {
      const response = await client.get('/api/teg/05/module', {
        params: searchData,
      });
      const moduleList = response.data.moduleList;
      const moduleOptions = moduleList.map(
        (item: { MDL_NM: string; MDL_ID: number; RMK_CNT: string }) => ({
          label: item.MDL_NM,
          value: item.MDL_ID,
        }),
      );
      return moduleOptions;
    },
    ...options,
  });
}

export default function useModuleOptions(searchData: ISearchModule) {
  return moduleOptions({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: searchData.DVC_NO !== '',
    },
  });
}
