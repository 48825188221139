import { createColumnHelper } from '@tanstack/react-table';
import { useDepartmentCodeList, useDepartmentCodeMutation } from 'apis';
import {
  ReadOnlyNumberCell,
  ReadOnlyTextCell,
  StatusCell,
  StatusIcon,
  Table,
} from 'components';
import { useSearchComboList, useTableData } from 'hooks';
import {
  Columns,
  CommonArray,
  CommonObject,
  CurrentData,
  IDepartmentCode,
  ITableContext,
  RowType,
  defaultDepartmentCode,
} from 'models';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';
import { systemManagementState } from 'store';
import {
  TableContext,
  addRowData,
  convertDate,
  exportJsonToSheet,
  getAlertMessage,
  getCurrentDateFormatted,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';

const columnHelper = createColumnHelper();
const newRow = defaultDepartmentCode;

const AIBI010M01Table = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const replaceDptDttCd = (cd: string, cdList: CommonArray) => {
    const matchingDepartment = cdList.find(
      department => department.value === cd,
    );
    return matchingDepartment ? matchingDepartment.label : null;
  };
  const searchDepartmentCode = useRecoilValue(
    systemManagementState.searchDepartmentCode,
  );
  const { data: tableCodeData } = useDepartmentCodeList(
    searchDepartmentCode,
  ) as CommonObject;
  const comboData = useSearchComboList({ CMB_CD: 'CMB0019' });
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: tableCodeData,
    atom: systemManagementState.departmentCodeList,
  });
  const [currentData, setCurrentData] = useRecoilState(
    systemManagementState.currentDepartmentCode('AIBI010M01'),
  );
  const departmentCodeMutation = useDepartmentCodeMutation();
  const [backupData, setBackupData] = useState([]);
  useEffect(() => {
    if (tableCodeData && comboData) {
      const updatedData = tableCodeData.map((data: CommonObject) => ({
        ...data,
        REG_YMD: convertDate(data.REG_YMD),
        DPT_DTT_CD_NM: replaceDptDttCd(data.DPT_DTT_CD, comboData),
      }));
      setInitialData(updatedData);
      setBackupData(updatedData);
      setCurrentData({ index: 0, data: updatedData[0] });
    }
  }, [comboData, tableCodeData]);
  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 25,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('DPT_CD', {
      header: '부서코드',
      cell: ReadOnlyTextCell,
      size: 70,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('DPT_NM', {
      header: '부서명',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      meta: {
        required: true,
      },
    }),
    columnHelper.accessor('HRK_DPT_NM', {
      header: '상위부서',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('DPT_DTT_CD_NM', {
      header: '부서구분',
      cell: ReadOnlyTextCell,
      size: 70,
      filterFn: 'includesString',
      meta: {
        required: true,
        justify: 'center',
      },
    }),
    columnHelper.accessor('REG_YMD', {
      header: '등록일자',
      cell: ReadOnlyTextCell,
      size: 100,
      filterFn: 'includesString',
      meta: {
        required: true,
        justify: 'center',
      },
    }),
    columnHelper.accessor('DUS_YMD', {
      header: '폐기일자',
      cell: ReadOnlyTextCell,
      size: 100,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('SRT_SQN', {
      header: '정렬순서',
      cell: ReadOnlyNumberCell,
      size: 70,
      enableColumnFilter: false,
    }),
    columnHelper.accessor('RMK_CONT', {
      header: '비고',
      cell: ReadOnlyTextCell,
      enableColumnFilter: false,
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const handleAddData = () => {
    const updateRow = {
      ...newRow,
      DPT_DTT_CD: comboData[0].value,
      DPT_DTT_CD_NM: comboData[0].label,
      REG_YMD: getCurrentDateFormatted(),
    };
    addRowData(
      setInitialData,
      setCurrentData,
      updateRow,
      initialData.length,
    );
    setCurrentData({ index: 0, data: updateRow });
  };

  const handleRemoveData = (selectData: CurrentData) => {
    let removeFlag = true;
    const currentRowType = selectData.data.ROWTYPE;
    if (
      currentRowType === RowType.NORMAL ||
      currentRowType === RowType.UPDATE
    ) {
      const removeLength = initialData.filter(
        data =>
          data.ROWTYPE === RowType.NORMAL &&
          data.HRK_DPT_CD === currentData.data.DPT_CD,
      ).length;
      if (removeLength > 0) {
        toast.warning(getAlertMessage('INFO_00050'));
        removeFlag = false;
      }
    } else if (currentRowType === RowType.ADD) {
      if (initialData.length > 1)
        setCurrentData({ index: 0, data: initialData[1] });
    }
    if (!removeFlag) {
      return false;
    }
    const removedData = removeRowData(selectData, initialData);

    setInitialData(removedData);
    selectNextDataByRemove(
      selectData,
      initialData.length - 1,
      removedData,
      setCurrentData,
    );
  };

  const handleSaveData = () => {
    const checkKeys = ['DPT_NM', 'DPT_DTT_CD', 'REG_YMD'];
    const targetData = saveTableData(initialData, checkKeys);

    if (targetData !== undefined && targetData.length > 0) {
      const newData = targetData.map(item => ({
        ...item,
        REG_YMD: item.REG_YMD.split('-').join(''),
      }));
      departmentCodeMutation.mutate(newData as IDepartmentCode[]);
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue: ITableContext = {
    handleExport: () =>
      exportJsonToSheet(columns, initialData, 'department-code'),
    handleAddData,
    handleRemoveData,
    handleSaveData,
    currentData,
    setCurrentData,
  };

  return (
    <TableContext.Provider value={contextValue}>
      {initialData && (
        <Table
          title='부서정보'
          initialData={initialData}
          backupData={backupData}
          setData={setInitialData}
          columns={columns}
          editable={true}
          tableHeight={tableHeight}
        />
      )}
    </TableContext.Provider>
  );
};

export default AIBI010M01Table;
