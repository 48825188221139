import { ISideMenu } from 'models';
import { useRef } from 'react';
import { BiChevronRight } from 'react-icons/bi';
import { useRecoilState, useRecoilValue } from 'recoil';
import { menuState, selectState } from 'store';
import { SideMenuItem } from 'styles';
import { SIDEMENU_PADDING, TITLE_LEVEL } from 'utils';

const SideTitleItem = ({ menu }: { menu: ISideMenu }): JSX.Element => {
  const sideMenu = useRecoilValue(menuState.sideMenu);
  const [toggleMenu, setToggleMenu] = useRecoilState(
    selectState.selectToggleMenu,
  );
  const prevToggles = useRef<string[]>([]);

  const isTitle = menu.LEVEL === TITLE_LEVEL;
  const isToggle = toggleMenu.includes(menu.MNU_ID);
  const isOpenToggle = !isTitle && !toggleMenu.includes(menu.HRK_MNU_ID);
  const isMenu = (targetId: string) => {
    return targetId === menu.MNU_ID;
  };

  const isChild = (targetId: string, menuGroups: ISideMenu[]) => {
    const childMenu = menuGroups.find(child => child.MNU_ID === targetId);
    return childMenu !== undefined;
  };

  const getChildMenus = () => {
    return sideMenu.filter(sideMenu => {
      if (sideMenu.HRK_MNU_ID === menu.MNU_ID) return sideMenu.MNU_ID;
    });
  };

  const addToggleMenu = () => {
    setToggleMenu([...toggleMenu, menu.MNU_ID]);
  };

  const restoreToggleMenu = () => {
    if (!prevToggles) return;
    setToggleMenu([...toggleMenu, ...prevToggles.current]);
    prevToggles.current = [];
  };

  const removeToggleMenu = (menuGroups: ISideMenu[]) => {
    const newToggleMenu = toggleMenu.filter(
      toggle => !isMenu(toggle) && !isChild(toggle, menuGroups),
    );
    prevToggles.current = toggleMenu.filter(
      toggle => isMenu(toggle) || isChild(toggle, menuGroups),
    );
    setToggleMenu(newToggleMenu);
  };

  const handleToggle = () => {
    const menuGroups = getChildMenus();
    const isIncludes = toggleMenu.includes(menu.MNU_ID);

    if (prevToggles.current.length === 0 && !isIncludes) {
      addToggleMenu();
    } else if (prevToggles.current.length > 0 && !isIncludes) {
      restoreToggleMenu();
    } else if (menuGroups.length > 0 && isIncludes) {
      removeToggleMenu(menuGroups);
    } else if (menuGroups.length === 0 && isIncludes) {
      setToggleMenu(toggleMenu.filter(toggle => toggle !== menu.MNU_ID));
    }
  };

  return (
    <SideMenuItem
      style={{
        paddingLeft: isTitle ? undefined : SIDEMENU_PADDING * menu.LEVEL,
        position: isOpenToggle ? 'absolute' : undefined,
        display: isOpenToggle ? 'none' : undefined,
      }}
      onClick={handleToggle}
    >
      <span>{menu.MNU_NM}</span>
      <BiChevronRight className={isToggle ? 'rotate-90' : undefined} />
    </SideMenuItem>
  );
};

export default SideTitleItem;
