import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IAssignedAuthorityMenu } from 'models';
import { client } from 'utils';

function assignAuthorityMutation(
  options: UseMutationOptions<
    unknown,
    Error,
    IAssignedAuthorityMenu[],
    unknown
  >,
) {
  return useMutation({
    mutationFn: async assignAuthority => {
      const response = await client.post(
        '/api/co/cm/170m02/user/authority/assigned',
        assignAuthority,
      );

      return response;
    },
    ...options,
  });
}

export default function useAssignAuthority() {
  const queryClient = useQueryClient();

  return assignAuthorityMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['system_setting', 'user_possible'],
      });
      queryClient.invalidateQueries({
        queryKey: ['system_setting', 'user_assigned'],
      });
    },
  });
}
