import { createColumnHelper } from '@tanstack/react-table';
import {
  useReproducibilitySpecList,
  useReproducibilitySpecMutation,
} from 'apis';
import {
  DisplayButton,
  ReadOnlyTextCell,
  StatusCell,
  StatusIcon,
  Table,
} from 'components';
import { useTableData } from 'hooks';
import {
  Columns,
  CommonArray,
  CommonObject,
  CurrentData,
  IMeasureSpec,
  IPlotSaveData,
  IPlotValue,
  ITableContext,
  defaultCurrentData,
} from 'models';
import { useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { plotState } from 'store';
import { DataTableContainer } from 'styles';
import {
  SUBPLOT_HEIGHT,
  SUBPLOT_WIDTH,
  TableContext,
  exportJsonToSheet,
  saveTableData,
} from 'utils';

const columnHelper = createColumnHelper();

const TEG08SUBTable = ({
  tableHeight,
  recoilKey,
}: {
  tableHeight: number;
  recoilKey: string;
}): JSX.Element => {
  const searchPlot = useRecoilValue(plotState.searchPlot(recoilKey));
  const setSpecCodes = useSetRecoilState(plotState.specCodes);
  const setTrendPlot = useSetRecoilState(plotState.trendPlot);
  const { data: specData } = useReproducibilitySpecList(
    searchPlot,
  ) as CommonObject;
  const [backupData, setBackupData] = useState<CommonArray>([]);
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: specData,
    effect: () => {
      setInitialData(specData.specList);
      setBackupData(specData.specList);
      setCurrentData({ index: 0, data: specData.specList[0] });
      setSpecColumns(specData.specColumns);
      setSpecCodes(specData.specCodes);
      setTrendPlot(old => ({
        ...old,
        DVC_NO: searchPlot.DVC_NO,
        DSG_ID: searchPlot.DSG_ID,
        MSR_TYPE: searchPlot.MSR_TYPE,
        SPC_TRN_AXIS_YL: specData.axis.YL,
        SPC_TRN_AXIS_YR: specData.axis.YR,
      }));
    },
  });
  const [specColumns, setSpecColumns] = useState<CommonObject[]>([]);
  const [currentData, setCurrentData] =
    useState<CurrentData>(defaultCurrentData);
  const specMutation = useReproducibilitySpecMutation();
  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      header: () => <StatusIcon />,
      cell: StatusCell,
      size: 30,
    }),
    columnHelper.accessor('MSR_NM', {
      header: '측정순번',
      cell: ReadOnlyTextCell,
      size: 70,
      filterFn: 'arrIncludes',
      meta: {
        justify: 'center',
      },
    }),
    ...specColumns,
    columnHelper.accessor('UPL_DT', {
      header: '등록일자',
      cell: ReadOnlyTextCell,
      size: 70,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.display({
      header: 'Plot',
      cell: DisplayButton,
      size: 50,
      meta: {
        text: '보기',
        onClick: (row: IPlotValue) => openSubWindow(row),
      },
    }),
  ];
  const columns = useMemo(() => defaultColumns, [specColumns]) as Columns;

  const openSubWindow = (data: IPlotValue) => {
    const { DVC_NO, DSG_ID, MSR_TYPE, MSR_NO, DUT_ID } = data;
    const queryString = `DVC_NO=${DVC_NO}&DSG_ID=${DSG_ID}&MSR_TYPE=${MSR_TYPE}&MSR_NO=${MSR_NO}&DUT_ID=${DUT_ID}`;

    const url = window.location.origin + `/#/subPlot?${queryString}`;
    window.open(
      url,
      '_blank',
      `width=${SUBPLOT_WIDTH},height=${SUBPLOT_HEIGHT}`,
    );
  };

  const handleSaveData = () => {
    const specSaveParam: IPlotSaveData = {
      PRJ_NO: Number(searchPlot.PRJ_NO),
      specList: saveTableData(initialData) as IMeasureSpec[],
    };

    if (specSaveParam.specList.length > 0) {
      specMutation.mutate(specSaveParam);
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue: ITableContext = {
    handleExport: () =>
      exportJsonToSheet(columns, initialData, 'DUT 스펙'),
    handleSaveData,
    currentData,
    setCurrentData,
    customDisabled: {
      fetched:
        searchPlot.PRJ_NO === '' ||
        searchPlot.PRC_NO === '' ||
        searchPlot.DVC_NO === '' ||
        searchPlot.DSG_ID === '' ||
        searchPlot.MSR_TYPE === '' ||
        searchPlot.DUT_ID === '',
    },
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        <Table
          title='DUT'
          initialData={initialData}
          backupData={backupData}
          setData={setInitialData}
          columns={initialData.length > 0 ? columns : []}
          editable={true}
          tableHeight={tableHeight}
        />
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default TEG08SUBTable;
