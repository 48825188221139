import { useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Table,
  StatusCell,
  StatusIcon,
  ReadOnlyTextCell,
  TextCell,
  SelectCell,
  CheckInputCell,
  NumberCell,
} from 'components';
import {
  TableContext,
  addRowData,
  addRowNumber,
  exportJsonToSheet,
  getAlertMessage,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTableData } from 'hooks';
import { authorityManagementState } from 'store';
import {
  Columns,
  CommonArray,
  CommonObject,
  CurrentData,
  IAuthority,
  ITableContext,
  RowType,
  defaultAuthority,
} from 'models';
import { useAuthorityList, useAuthorityMutation } from 'apis';
import { DataTableContainer } from 'styles';
import { toast } from 'react-toastify';

const columnHelper = createColumnHelper();
const newRow = defaultAuthority;
const useYnOptions = [
  { label: '사용', value: 'Y' },
  { label: '사용안함', value: 'N' },
];

const COCM160M01Table = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchAuthority = useRecoilValue(
    authorityManagementState.searchAuthority('COCM160M01'),
  );
  const [backupData, setBackupData] = useState<CommonArray>([]);
  const { data: tableCodeData } = useAuthorityList(
    searchAuthority,
  ) as CommonObject;
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: tableCodeData,
    atom: authorityManagementState.authorityData('COCM160M01'),
    effect: () => {
      const newData = addRowNumber(tableCodeData);
      setInitialData(newData);
      setBackupData(newData);
    },
  });
  const [currentData, setCurrentData] = useRecoilState(
    authorityManagementState.currentAuthority('COCM160M01'),
  );
  const authMutation = useAuthorityMutation();

  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 30,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('no', {
      header: 'No',
      cell: ReadOnlyTextCell,
      size: 30,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('ATH_ID', {
      header: '권한ID',
      cell: TextCell,
      filterFn: 'includesString',
      meta: {
        unmodifiable: true,
        required: true,
        justify: 'center',
      },
    }),
    columnHelper.accessor('ATH_NM', {
      header: '권한명',
      cell: TextCell,
      filterFn: 'includesString',
      meta: {
        required: true,
        justify: 'center',
      },
    }),
    columnHelper.accessor('USE_YN', {
      header: '사용여부',
      cell: SelectCell,
      filterFn: 'includesString',
      meta: {
        renderKey: 'USE_YN',
        options: useYnOptions,
      },
    }),
    columnHelper.accessor('ATH_DESC_CONT', {
      header: '권한설명',
      cell: TextCell,
      size: 800,
    }),
    columnHelper.accessor('SRT_SQN', {
      header: '정렬순서',
      cell: NumberCell,
      size: 100,
      enableColumnFilter: false,
    }),
    columnHelper.accessor('WHL_RLT_CMP_USE_YN', {
      header: '전체관계회사사용여부',
      cell: CheckInputCell,
      size: 80,
      enableColumnFilter: false,
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const handleAddData = () => {
    addRowData(setInitialData, setCurrentData, newRow, initialData.length);
  };

  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);

    setInitialData(removedData);
    selectNextDataByRemove(
      selectData,
      initialData.length - 1,
      removedData,
      setCurrentData,
    );
  };

  const handleSaveData = () => {
    const checkKeys = ['ATH_ID', 'ATH_NM', 'USE_YN', 'WHL_RLT_CMP_USE_YN'];
    const targetData = saveTableData(initialData, checkKeys);

    const count = initialData.filter(data => {
      if (data.ROWTYPE === RowType.ADD) {
        if (data.ATH_ID.length !== 7) {
          return data;
        }
      }
    });

    if (count.length > 0) {
      toast.warning(getAlertMessage('ERRO_00100', ['권한ID', '7']));
      return;
    }
    if (targetData && targetData.length > 0) {
      authMutation.mutate(targetData as IAuthority[]);
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue: ITableContext = {
    handleExport: () =>
      exportJsonToSheet(columns, initialData, 'auth-code'),
    handleAddData,
    handleRemoveData,
    handleSaveData,
    currentData,
    setCurrentData,
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        {initialData && (
          <Table
            title='사용자'
            initialData={initialData}
            backupData={backupData}
            setData={setInitialData}
            columns={columns}
            editable={true}
            tableHeight={tableHeight}
          />
        )}
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default COCM160M01Table;
