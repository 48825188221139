import { useQuery } from '@tanstack/react-query';
import { UseQueryOptionsWithoutQueryKey } from 'models';
import { client } from 'utils';
import { ISearchMaskModule } from 'models/interfaces/maskInterface';

interface IMaskModuleListParam {
  searchData: ISearchMaskModule;
  options: UseQueryOptionsWithoutQueryKey;
}

function maskModule({ searchData, options }: IMaskModuleListParam) {
  return useQuery({
    queryKey: ['maskModule', 'maskModuleList', searchData],
    queryFn: async () => {
      const response = await client.get('/api/teg/02/sub', {
        params: searchData,
      });
      return response.data.moduleSubList;
    },
    ...options,
  });
}

export default function useMaskModuleList(searchData: ISearchMaskModule) {
  return maskModule({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
