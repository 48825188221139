import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { client, getAlertMessage } from 'utils';

function bbsDeleteMutation(
  options: UseMutationOptions<unknown, Error, number, unknown>,
) {
  return useMutation({
    mutationFn: async bbsId => {
      const response = await client.delete('/api/co/cm/800m01/bbs', {
        data: {
          BBS_ID: bbsId,
        },
      });
      return response;
    },
    ...options,
  });
}

export default function useBbsDeleteMutation(onClose: () => void) {
  const queryClient = useQueryClient();

  return bbsDeleteMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['bbs_id', 'bbs_list'],
      });
      toast.success(getAlertMessage('INFO_00010'));
      onClose();
    },
  });
}
