import {
  FrameContent,
  ModalInner,
  ModalOverlay,
  ModalWrapper,
} from 'styles';
import { CommonButton, FrameInfo } from 'components';
import FrameInfoSelect from 'components/frame/FrameInfoSelect';
import { useHeight, useSearchComboList } from 'hooks';
import TEG03DeviceTable from './TEG03DeviceTable';
import TEG03DeviceVopTable from './TEG03DeviceVopTable';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import { useEffect, useState } from 'react';
import { deviceState, selectState } from 'store';
import {
  CommonArray,
  CommonObject,
  CustomChangeEvent,
  CustomMouseEvent,
  RowType,
} from 'models';
import TEG03ModalProjectTable from './TEG03ModalProjectTable';
import TEG03ModalProcessTable from './TEG03ModalProcessTable';
import TEG03ModalDeviceTable from './TEG03ModalDeviceTable';
import TEG03ModalDescriptionTable from './TEG03ModalDescriptionTable';
import TEG03ModalAnalysisTable from './TEG03ModalAnalysisTable';
import useDeviceTeg from 'apis/queries/useDeviceTeg';
import useCommonFileDownloadMutation from 'apis/mutations/useCommonFileDownloadMutation';
import useDeviceAnalysis from 'apis/queries/useDeviceAnalysis';
import { toast } from 'react-toastify';
import { getAlertMessage } from 'utils';

const TEG03 = (): JSX.Element => {
  const getHeight = useHeight();
  const frameActive =
    useRecoilValue(selectState.selectSideMenu).PGM_ID === 'UIE003TEG03';
  const resetCurrentDevice = useResetRecoilState(
    deviceState.currentDeviceData,
  );
  const [searchData, setSearchData] = useRecoilState(
    deviceState.searchDevice,
  );
  const [values, setValues] = useState(searchData);
  const [openDeviceCopyModal, setOpenDeviceCopyModal] = useState(false);
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const projectOptions = useSearchComboList(
    { CMB_CD: frameActive ? 'CMB0029' : '' },
    true,
  );
  const processOptions = useSearchComboList(
    {
      CMB_CD: values.PRJ_NO ? 'CMB0030' : '',
      SRCH_PARAM: values.PRJ_NO ? values.PRJ_NO.toString() : '',
    },
    true,
  );

  const findLabel = (options: CommonArray, value: number | string) => {
    for (let i = 0; i < options.length; i++) {
      if (value !== 0 && options[i].value == value) {
        return options[i].label;
      }
    }
    return '';
  };

  useEffect(() => {
    if (processOptions) {
      const prsCd2 =
        findLabel(processOptions, searchData.PRC_NO).split(' / ')[1] || '';
      const prjNm = findLabel(projectOptions, searchData.PRJ_NO);
      setValues(old => ({
        ...old,
        ['PRJ_NM']: prjNm,
        ['PRS_CD2']: prsCd2,
      }));
    }
  }, [processOptions]);
  const editValue = (e: CustomChangeEvent, codeType?: string) => {
    if (e.target.value === '') resetCurrentDevice();
    if (codeType) {
      const value = e.target.value;
      if (codeType === 'PRC_NO') {
        const prsCd2 =
          findLabel(processOptions, value).split(' / ')[1] || '';
        setValues(old => ({
          ...old,
          PRC_NO: e.target.value === '' ? 0 : parseInt(e.target.value),
          ['PRS_CD2']: prsCd2,
        }));
      } else if (codeType === 'PRJ_NO') {
        const prjNm = findLabel(projectOptions, value);
        setValues(old => ({
          ...old,
          PRJ_NO: e.target.value === '' ? 0 : parseInt(e.target.value),
          ['PRJ_NM']: prjNm,
          ['PRC_NO']: 0,
        }));
      }
    }
  };
  useEffect(() => {
    setSearchData(values);
  }, [values]);
  useEffect(() => {
    if (frameActive) {
      const prjNm = findLabel(projectOptions, searchData.PRJ_NO);
      const prsCd2 =
        findLabel(processOptions, searchData.PRC_NO).split(' / ')[1] || '';
      setValues({ ...searchData, PRJ_NM: prjNm, PRS_CD2: prsCd2 });
    }
  }, [frameActive]);
  const handleSearch = () => {
    setSearchData(values);
  };

  const closeModal = () => {
    setOpenDeviceCopyModal(false);
    setOpenDescriptionModal(false);
    setOpenAnalysisModal(false);
  };
  const currentModalDeviceData = useRecoilValue(
    deviceState.currentModalDeviceData,
  );
  const setCurrentDeviceData = useSetRecoilState(
    deviceState.currentDeviceData,
  );
  const [deviceInitialData, setDeviceInitialData] = useRecoilState(
    deviceState.deviceList,
  );
  const selectCopyDevice = () => {
    const dvcNM = currentModalDeviceData?.data?.DVC_NM;
    if (dvcNM === undefined) {
      toast.warning('디바이스를 선택해 주세요.');
      return;
    }
    const copyProcessNumber = searchData?.PRC_NO;
    const copyDevicetNm = dvcNM + '_copy';
    const newRow = {
      ...currentModalDeviceData?.data,
      PRC_NO: copyProcessNumber,
      DVC_NM: copyDevicetNm,
      ROWTYPE: RowType.ADD,
    };
    if (deviceInitialData) {
      setDeviceInitialData(old => [...old, newRow]);
      setCurrentDeviceData({
        index: deviceInitialData.length,
        data: newRow,
      });
    }
    closeModal();
  };
  const [filePath, setFilePath] = useRecoilState(
    deviceState.currentDeviceFileUrl,
  );
  const [searchDeviceTeg, setSearchDeviceTeg] = useState({
    PRC_NO: 0,
    PRS_CD2: '',
    PRJ_NO: 0,
  });
  const [searchDeviceAnalysis, setSearchDeviceAnalysis] = useState({
    PRC_NO: 0,
    PRS_CD2: '',
    PRJ_NO: 0,
    PRJ_NM: '',
  });
  const searchVop = useRecoilValue(deviceState.searchVop);
  const tegResponse = useDeviceTeg(searchDeviceTeg) as CommonObject;
  const analysisResponse = useDeviceAnalysis(
    searchDeviceAnalysis,
  ) as CommonObject;
  const createDescription = () => {
    setSearchDeviceTeg({
      PRC_NO: searchVop.PRC_NO,
      PRS_CD2: searchVop.PRS_CD2,
      PRJ_NO: searchVop.PRJ_NO,
    });
  };
  useEffect(() => {
    if (tegResponse?.data?.status === 200) {
      toast.success(getAlertMessage('INFO_04000', ['TEG']));
      const prjNo = searchVop.PRJ_NO.toString().padStart(9, '0');
      const prsCd2 = searchVop.PRS_CD2;
      setFilePath({
        URL: `PRJ/${prjNo}/${prsCd2}_TEGDescription.xlsx`,
        FLAG: '1',
      });
    }
  }, [tegResponse?.data, searchDeviceTeg]);
  useEffect(() => {
    if (analysisResponse?.data?.status === 200) {
      toast.success(getAlertMessage('INFO_04000', ['Analysis']));
      const prjNo = searchVop.PRJ_NO.toString().padStart(9, '0');
      const prsCd2 = searchVop.PRS_CD2;
      setFilePath({
        URL: `PRJ/${prjNo}/${prsCd2}_AnalysisReport.pptx`,
        FLAG: '1',
      });
    }
  }, [analysisResponse?.data, searchDeviceAnalysis]);
  const downloadFile = useCommonFileDownloadMutation();
  const fileDownload = () => {
    const fileUrl = filePath;
    downloadFile.mutate(fileUrl);
  };
  const createAnalysis = () => {
    setSearchDeviceAnalysis({
      PRC_NO: searchVop.PRC_NO,
      PRS_CD2: searchVop.PRS_CD2,
      PRJ_NO: searchVop.PRJ_NO,
      PRJ_NM: searchData.PRJ_NM,
    });
  };
  const close = (e: CustomMouseEvent) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };
  return (
    <>
      <FrameInfo>
        <div className='search-form'>
          <div className='input-block'>
            <div className='gap-x-10'>
              <FrameInfoSelect
                title='프로젝트'
                name='PRJ_NO'
                options={projectOptions}
                value={values.PRJ_NO}
                onChange={e => editValue(e, 'PRJ_NO')}
              />
              <FrameInfoSelect
                title='공정'
                name='PRC_NO'
                options={processOptions}
                value={values.PRC_NO}
                onChange={e => editValue(e, 'PRC_NO')}
              />
            </div>
          </div>
          <div className='flex gap-x-2'>
            <CommonButton
              title='조회'
              onClick={handleSearch}
            />
          </div>
        </div>
      </FrameInfo>
      <FrameContent>
        <div className='w-full'>
          <div className='flex-between-2'>
            <TEG03DeviceTable
              tableHeight={getHeight(700)}
              openDeviceCopyModal={setOpenDeviceCopyModal}
              openDescriptionModal={setOpenDescriptionModal}
              openAnalysisModal={setOpenAnalysisModal}
            ></TEG03DeviceTable>
            <div className='w-[500px]'>
              <TEG03DeviceVopTable
                tableHeight={getHeight(700)}
              ></TEG03DeviceVopTable>
            </div>
          </div>
        </div>
      </FrameContent>
      {openDeviceCopyModal && (
        <>
          <ModalOverlay />
          <ModalWrapper onClick={close}>
            <ModalInner
              className='w-[1200px] h-[800px]'
              title='디바이스 선택'
            >
              <>
                <div className='flex gap-x-5'>
                  <TEG03ModalProjectTable
                    tableHeight={getHeight(680)}
                  ></TEG03ModalProjectTable>
                  <TEG03ModalProcessTable
                    tableHeight={getHeight(680)}
                  ></TEG03ModalProcessTable>
                  <TEG03ModalDeviceTable
                    tableHeight={getHeight(680)}
                  ></TEG03ModalDeviceTable>
                </div>
                <CommonButton
                  title='선택'
                  onClick={selectCopyDevice}
                ></CommonButton>
                <CommonButton
                  title='닫기'
                  onClick={closeModal}
                ></CommonButton>
              </>
            </ModalInner>
          </ModalWrapper>
        </>
      )}
      {openDescriptionModal && (
        <>
          <ModalOverlay />
          <ModalWrapper onClick={close}>
            <ModalInner
              className='w-[800px] h-[800px]'
              title='디바이스 선택'
            >
              <>
                <div className='flex gap-x-5'>
                  <TEG03ModalDescriptionTable
                    tableHeight={getHeight(680)}
                  ></TEG03ModalDescriptionTable>
                </div>
                <CommonButton
                  title='생성'
                  onClick={createDescription}
                ></CommonButton>
                <CommonButton
                  title='다운로드'
                  onClick={fileDownload}
                  disabled={filePath.FLAG !== '1'}
                  color={filePath.FLAG !== '1' ? 'darkgray' : ''}
                ></CommonButton>
                <CommonButton
                  title='닫기'
                  onClick={closeModal}
                ></CommonButton>
              </>
            </ModalInner>
          </ModalWrapper>
        </>
      )}
      {openAnalysisModal && (
        <>
          <ModalOverlay />
          <ModalWrapper onClick={close}>
            <ModalInner
              className='w-[800px] h-[800px]'
              title='디바이스 선택'
            >
              <>
                <div className='flex gap-x-5'>
                  <TEG03ModalAnalysisTable
                    tableHeight={getHeight(680)}
                  ></TEG03ModalAnalysisTable>
                </div>
                <CommonButton
                  title='생성'
                  onClick={createAnalysis}
                ></CommonButton>
                <CommonButton
                  title='다운로드'
                  onClick={fileDownload}
                  disabled={filePath.FLAG !== '1'}
                  color={filePath.FLAG !== '1' ? 'darkgray' : ''}
                ></CommonButton>
                <CommonButton
                  title='닫기'
                  onClick={closeModal}
                ></CommonButton>
              </>
            </ModalInner>
          </ModalWrapper>
        </>
      )}
    </>
  );
};

export default TEG03;
