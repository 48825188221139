import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IProcess } from 'models/interfaces/projectInterface';
import { toast } from 'react-toastify';
import { client, getAlertMessage } from 'utils';

function processMutation(
  options: UseMutationOptions<unknown, Error, IProcess[], unknown>,
) {
  return useMutation({
    mutationFn: async (processList: IProcess[]) => {
      const response = await client.post(
        '/api/teg/01/process',
        processList,
      );
      return response;
    },
    ...options,
  });
}

export default function useProcessMutation() {
  const queryClient = useQueryClient();

  return processMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['process'],
      });
      toast.success(getAlertMessage('INFO_00010'));
    },
  });
}
