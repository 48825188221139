import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchAuthorityMenu } from 'models';
import { client } from 'utils';

function authorityMenuList({
  searchData,
  options,
}: IQueryParams<ISearchAuthorityMenu>) {
  return useQuery({
    queryKey: ['system_setting', 'authority_menu', searchData],
    queryFn: async () => {
      const response = await client.get(
        '/api/co/cm/160m02/authority/menu',
        { params: searchData },
      );
      return response.data.menuAuthorityList || [];
    },
    ...options,
  });
}

export default function useAuthorityMenuList(
  searchData: ISearchAuthorityMenu,
) {
  return authorityMenuList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: searchData.ATH_ID !== '',
    },
  });
}
