import ResetRecoilContext from 'ResetRecoilContext';
import { CustomMouseEvent, IHeaderUserInfoBoxProps } from 'models';
import { useContext, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { loginState } from 'store';
import { UserInfoDiv } from 'styles';
import HeaderUserInfoMenu from './HeaderUserInfoMenu';
import HeaderUserPasswordForm from './HeaderUserPasswordForm';

const HeaderUserInfoBox = ({
  openInfo,
  setOpenInfo,
}: IHeaderUserInfoBoxProps): JSX.Element => {
  const userInfoRef = useRef<HTMLDivElement>(null);
  const resetRecoil = useContext(ResetRecoilContext);
  const [editPassword, setEditPassword] = useState<boolean>(false);
  const userInfo = useRecoilValue(loginState.userInfo);

  const handleEditPassword = (e: CustomMouseEvent) => {
    e.stopPropagation();

    setEditPassword(!editPassword);
  };

  const handleLogout = () => {
    resetRecoil?.resetRecoil();
    sessionStorage.clear();
  };

  useEffect(() => {
    const handleInfoBoxClose = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (openInfo && !userInfoRef.current?.contains(target)) {
        setEditPassword(false);
        setOpenInfo(false);
      }
    };

    document.addEventListener('click', handleInfoBoxClose);

    return () => {
      document.removeEventListener('click', handleInfoBoxClose);
    };
  }, [openInfo]);

  return (
    <UserInfoDiv
      ref={userInfoRef}
      $openInfo={openInfo}
    >
      <div className='info'>
        <span className='info-id'>{userInfo?.USR_ID}</span>
        <span className='info-etc'>
          {userInfo?.USR_NM} / {userInfo?.EMP_NO} / {userInfo?.DPT_NM}
        </span>
      </div>
      <hr className='my-2' />
      {!editPassword ? (
        <HeaderUserInfoMenu
          handleEditPassword={handleEditPassword}
          handleLogout={handleLogout}
        />
      ) : (
        <HeaderUserPasswordForm handleEditPassword={handleEditPassword} />
      )}
    </UserInfoDiv>
  );
};

export default HeaderUserInfoBox;
