import { useEffect, useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  ReadOnlyTextCell,
  StatusCell,
  StatusIcon,
  Table,
} from 'components';
import { TableContext } from 'utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTableData } from 'hooks';
import { DataTableContainer } from 'styles';
import { useCommonCodeList, useUserList } from 'apis';
import { systemManagementState } from 'store';
import { Columns, CommonArray, CommonObject, ITableContext } from 'models';

const columnHelper = createColumnHelper();

const COCM170M01UserTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const [searchUser, setSearchUser] = useRecoilState(
    systemManagementState.searchUser('COCM170M02'),
  );
  const currentDepartment = useRecoilValue(
    systemManagementState.currentDepartmentCode('COCM170M02'),
  );
  const [currentUser, setCurrentUser] = useRecoilState(
    systemManagementState.currentUser('COCM170M02Auth'),
  );
  const { data: psclOptions } = useCommonCodeList({
    TP_CD: 'PSCL_CD',
  }) as CommonObject;
  const { data: rspofcOptions } = useCommonCodeList({
    TP_CD: 'RSPOFC_CD',
  }) as CommonObject;
  const { data: tableCodeData } = useUserList(searchUser) as CommonObject;
  const [initialData, setInitialData] = useTableData({
    fetchData: tableCodeData,
    atom: systemManagementState.userList('COCM170M02Auth'),
    effect: () => {
      const updatedData = tableCodeData.map(
        (data: CommonObject, index: number) => ({
          ...data,
          no: index + 1,
          PSCL_NM: replaceCodeToName(data.PSCL_CD, psclOptions),
          RSPOFC_NM: replaceCodeToName(data.RSPOFC_CD, rspofcOptions),
          HLFC_DTT_NM: data.HLFC_DTT_CD === '1' ? 'Y' : 'N',
        }),
      );

      setInitialData(updatedData);
      setCurrentUser({ index: 0, data: updatedData[0] });
    },
  });

  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 20,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('no', {
      header: 'No',
      cell: ReadOnlyTextCell,
      size: 30,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('USR_ID', {
      header: '사용자ID',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('EMP_NO', {
      header: '사원번호',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('USR_NM', {
      header: '사용자명',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('DPT_NM', {
      header: '부서',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('PSCL_NM', {
      header: '직급',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('RSPOFC_NM', {
      header: '직책',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('HLFC_DTT_NM', {
      header: '재직구분',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const replaceCodeToName = (
    targetCode: string,
    codeList: CommonArray,
  ) => {
    if (codeList) {
      const matchingCD = codeList.find(code => code.CMN_CD === targetCode);
      return matchingCD ? matchingCD.CMN_CD_NM : '';
    }
  };

  const contextValue: ITableContext = {
    currentData: currentUser,
    setCurrentData: setCurrentUser,
  };

  useEffect(() => {
    if (currentDepartment.data) {
      setSearchUser(old => ({
        ...old,
        DPT_CD: currentDepartment.data.DPT_CD,
      }));
    }
  }, [currentDepartment]);

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        {initialData && (
          <Table
            title='사용자'
            initialData={initialData}
            backupData={tableCodeData}
            setData={setInitialData}
            columns={columns}
            tableHeight={tableHeight}
          />
        )}
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default COCM170M01UserTable;
