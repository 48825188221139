import { useEffect, useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  ReadOnlyTextCell,
  StatusCell,
  StatusIcon,
  Table,
} from 'components';
import { TableContext } from 'utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTableData } from 'hooks';
import { systemManagementState } from 'store';
import { DataTableContainer } from 'styles';
import { usePossibleAuthorityList } from 'apis';
import { Columns, CommonObject, ITableContext } from 'models';

const columnHelper = createColumnHelper();

const COCM170M02PossibleTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchAuth = useRecoilValue(systemManagementState.searchAuth);
  const [currentData, setCurrentData] = useRecoilState(
    systemManagementState.currentPossibleAuth,
  );
  const { data: possibleData } = usePossibleAuthorityList(
    searchAuth,
  ) as CommonObject;
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: possibleData,
    atom: systemManagementState.possibleAuthList,
    effect: () => {
      setInitialData(possibleData);
      setCurrentData({ index: 0, data: possibleData[0] });
    },
  });

  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 20,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('ATH_ID', {
      header: '권한ID',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('ATH_NM', {
      header: '권한명',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const contextValue: ITableContext = {
    currentData,
    setCurrentData,
  };

  useEffect(() => {
    if (!searchAuth.USR_NM) {
      setInitialData([]);
    }
  }, [searchAuth]);

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        <Table
          title='부여가능 권한'
          initialData={initialData}
          setData={setInitialData}
          columns={columns}
          tableHeight={tableHeight}
        />
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default COCM170M02PossibleTable;
