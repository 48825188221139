import { createColumnHelper } from '@tanstack/react-table';
import { useDocumentTypeList, useDocumentTypeMutation } from 'apis';
import {
  DisplayButton,
  ReadOnlyTextCell,
  SelectCell,
  StatusCell,
  StatusIcon,
  Table,
} from 'components';
import { useMoveTeg, useSearchComboList, useTableData } from 'hooks';
import {
  Columns,
  CommonArray,
  CommonObject,
  CurrentData,
  IDocumentType,
  ITableContext,
  defaultCurrentData,
  defaultDocumentType,
} from 'models';
import { useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { documentState, pdkState } from 'store';
import { DataTableContainer } from 'styles';
import {
  TableContext,
  addRowData,
  addRowNumber,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';
import { toast } from 'react-toastify';

const columnHelper = createColumnHelper();
const newDocumentType = defaultDocumentType;

const TEG09DocumentTypeTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const setSearchDocumentContent = useSetRecoilState(
    documentState.searchDocumentContent,
  );
  const moveTeg = useMoveTeg();
  const currentDocument = useRecoilValue(pdkState.currentDocument);
  const documentTypeMutation = useDocumentTypeMutation();
  const [backupData, setBackupData] = useState<CommonArray>([]);
  const documentOptions = useSearchComboList(
    {
      CMB_CD: 'CMB0039',
    },
    true,
  );
  const { data: documentTypeData } = useDocumentTypeList({
    DOC_NO: currentDocument?.data?.DOC_NO,
  }) as CommonObject;
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: documentTypeData,
    effect: () => {
      const addNumberData = addRowNumber(documentTypeData);
      setInitialData(addNumberData);
      setBackupData(addNumberData);
      setCurrentData({ index: 0, data: addNumberData[0] });
    },
  });
  const [currentData, setCurrentData] =
    useState<CurrentData>(defaultCurrentData);

  const handleMove = (row: CommonObject) => {
    const { DOC_NO } = currentDocument.data;
    const { DOC_TYPE } = row;
    setSearchDocumentContent(old => ({ ...old, DOC_NO, DOC_TYPE }));
    moveTeg('UIE009TEG10');
  };

  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      cell: StatusCell,
      size: 25,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('no', {
      header: 'No',
      cell: ReadOnlyTextCell,
      size: 25,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('DOC_TYPE', {
      header: '문서종류',
      cell: SelectCell,
      enableColumnFilter: false,
      meta: {
        options: documentOptions,
      },
    }),
    columnHelper.accessor('UPD_DATE', {
      header: '작성일자',
      cell: ReadOnlyTextCell,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('UPDR_ID', {
      header: '작성자',
      cell: ReadOnlyTextCell,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.display({
      header: '문서관리',
      cell: DisplayButton,
      meta: {
        text: '관리',
        onClick: (row: CommonObject) => handleMove(row),
      },
    }),
  ];
  const columns = useMemo(
    () => defaultColumns,
    [documentOptions, currentDocument],
  ) as Columns;

  const handleAddData = () => {
    if (initialData.length >= 2) {
      toast.warning('동일한 문서를 2개 이상 생성할 수 없습니다.');
      return;
    }

    const updatedType = {
      ...newDocumentType,
      DOC_NO: currentDocument.data.DOC_NO,
    };
    addRowData(
      setInitialData,
      setCurrentData,
      updatedType,
      initialData.length,
    );
  };

  const handleRemoveData = (selectDocument: CurrentData) => {
    const removedData = removeRowData(selectDocument, initialData);

    if (removedData.length > 0) {
      setInitialData(removedData);
      selectNextDataByRemove(
        selectDocument,
        initialData.length - 1,
        removedData,
        setCurrentData,
      );
    }
  };

  const handleSaveData = () => {
    const updatedData = initialData.map(data => {
      const equalType = documentOptions.find(
        option => option.value === data.DOC_TYPE,
      );
      return { ...data, FILE_NM: `${equalType.label}.docx` };
    });
    const targetData = saveTableData(updatedData);

    if (targetData.length > 0) {
      documentTypeMutation.mutate(targetData as IDocumentType[]);
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue: ITableContext = {
    handleAddData,
    handleRemoveData,
    handleSaveData,
    currentData,
    setCurrentData,
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        <Table
          title='문서 종류'
          initialData={initialData}
          backupData={backupData}
          setData={setInitialData}
          columns={columns}
          editable={true}
          tableHeight={tableHeight}
        />
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default TEG09DocumentTypeTable;
