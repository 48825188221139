import { createColumnHelper } from '@tanstack/react-table';
import useDesignDutFileMutation from 'apis/mutations/useDesignDutFileMutation';
import useDesignDutMeasureDeleteMutation from 'apis/mutations/useDesignDutMeasureDeleteMutation';
import { CheckInputCell, ReadOnlyTextCell, Table } from 'components';
import { useMoveTeg, useTableData } from 'hooks';
import {
  Columns,
  CommonObject,
  CustomChangeEvent,
  IDesignDutFileData,
  IDesignDutFileWrapper,
  ITableContext,
} from 'models';
import { useEffect, useMemo, useRef } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { designState, measurementFileState, plotState } from 'store';
import { TableContext, getAlertMessage } from 'utils';

const columnHelper = createColumnHelper();

const TEG06DesignDutTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const moveTeg = useMoveTeg();
  const searchData = useRecoilValue(designState.searchDesignDescription);
  useEffect(() => {
    const empty = Object.values(searchData).some(value => value === '');
    if (empty) setInitialData([]);
  }, [searchData]);
  const [measureData, setMeasureData] = useRecoilState(
    designState.currentDesignDutFileData,
  );
  const [currentData, setCurrentData] = useRecoilState(
    designState.currentDesignDutData,
  );
  const setSearchMeasureFile = useSetRecoilState(
    measurementFileState.searchMeasureFile,
  );
  const setSearchPlot = useSetRecoilState(
    plotState.searchPlot('plotManagement'),
  );
  const designDutList = useRecoilValue(designState.designDutList);
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: designDutList,
    atom: designState.designDutList,
    effect: () => {
      if (designDutList.length > 0) {
        setInitialData(designDutList);
        setCurrentData({ index: 0, data: designDutList[0] });
      }
    },
  });

  const defaultColumns = [
    columnHelper.accessor('no', {
      header: 'NO',
      cell: ReadOnlyTextCell,
      size: 20,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('DUT_NM', {
      header: 'DUT',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 80,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('DUT_ALIAS', {
      header: '별명',
      cell: ReadOnlyTextCell,
      size: 80,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('LOT_NO', {
      header: 'LOT 번호',
      cell: ReadOnlyTextCell,
      size: 80,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('WFR_NO', {
      header: '웨이퍼 번호',
      cell: ReadOnlyTextCell,
      size: 80,
      filterFn: 'includesString',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('TR_FLAG', {
      header: '',
      size: 10,
      cell: CheckInputCell,
    }),
    columnHelper.accessor('TR_CNT', {
      header: 'TLP Reverse 측정',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 100,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('TF_FLAG', {
      header: '',
      size: 10,
      cell: CheckInputCell,
    }),
    columnHelper.accessor('TF_CNT', {
      header: 'TLP Forward 측정',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 100,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('DC_FLAG', {
      header: '',
      size: 10,
      cell: CheckInputCell,
    }),
    columnHelper.accessor('DC_CNT', {
      header: 'DC 측정',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 100,
      meta: {
        justify: 'center',
      },
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleRegister = () => {
    let cnt = 0;
    let msr_type = '';
    let dut_id = 0;
    initialData.map(data => {
      if (data.TR_FLAG === 'Y') {
        cnt += 1;
        msr_type = 'TR';
        dut_id = data.DUT_ID;
      }
      if (data.TF_FLAG === 'Y') {
        cnt += 1;
        msr_type = 'TF';
        dut_id = data.DUT_ID;
      }
      if (data.DC_FLAG === 'Y') {
        cnt += 1;
        msr_type = 'DC';
        dut_id = data.DUT_ID;
      }
    });
    if (cnt === 1) {
      //upload 진행
      setMeasureData({
        MSR_TYPE: msr_type,
        DUT_ID: dut_id.toString(),
      } as IDesignDutFileData);
      fileInputRef?.current?.click();
    } else if (cnt === 0) {
      toast.warning(getAlertMessage('ERRO_00260'));
    } else {
      toast.warning(getAlertMessage('ERRO_00270'));
    }
  };
  const deleteDesignDutMeasure = useDesignDutMeasureDeleteMutation();
  const handleRemoveData = () => {
    // 측정파일 삭제
    let cnt = 0;
    initialData.map(data => {
      if (
        data.TR_FLAG === 'Y' ||
        data.TF_FLAG === 'Y' ||
        data.DC_FLAG === 'Y'
      ) {
        cnt += 1;
      }
    });
    if (cnt === 0) {
      toast.warning(getAlertMessage('ERRO_00360'));
      return;
    }
    if (!confirm('삭제하시겠습니까?')) return;
    deleteDesignDutMeasure.mutate(designDutList);
  };
  const handleUpload = () => {
    // 측정파일 업로드 이동
    const { PRJ_NO, PRC_NO, DVC_NO } = searchData;
    setSearchMeasureFile(old => ({ ...old, PRJ_NO, PRC_NO, DVC_NO }));
    moveTeg('UIE007TEG07');
  };
  const handleSelectInfo = (e: CustomChangeEvent) => {
    const MSR_TYPE = e.target.value;
    const { PRJ_NO, PRC_NO, DVC_NO, DSG_ID } = searchData;
    setSearchPlot(old => ({
      ...old,
      PRJ_NO,
      PRC_NO,
      DVC_NO,
      DSG_ID,
      MSR_TYPE,
    }));
    moveTeg('UIE008TEG08');
  };
  const saveFile = useDesignDutFileMutation();
  const onchange = (e: CustomChangeEvent) => {
    const inputElement = e.target as HTMLInputElement;
    const selectedFile = inputElement.files?.[0];
    const designDutFileWrapper = {
      file: selectedFile,
      measureSave: {
        DVC_NO: Number(searchData.DVC_NO),
        MSR_TYPE: measureData.MSR_TYPE,
        DUT_ID: measureData.DUT_ID,
      },
    };
    saveFile.mutate(designDutFileWrapper as IDesignDutFileWrapper);
  };
  const selectInfoOptions = [
    { label: '플롯 관리 이동', value: '-1' },
    { label: 'Reverse 플롯', value: 'TR' },
    { label: 'Forward 플롯', value: 'TF' },
    { label: 'DC 플롯', value: 'DC' },
  ];
  const contextValue: ITableContext = {
    handleRegister,
    handleUpload,
    handleRemoveData,
    handleSelectInfo,
    currentData,
    setCurrentData,
    selectInfoOptions,
    customDisabled: {
      fetched:
        searchData.PRJ_NO === '' ||
        searchData.PRC_NO === '' ||
        searchData.DVC_NO === '' ||
        searchData.DSG_ID === '',
    },
  };

  return (
    <TableContext.Provider value={contextValue}>
      {initialData && (
        <>
          <input
            type='file'
            ref={fileInputRef}
            className='hidden'
            onChange={e => onchange(e)}
          />
          <Table
            title='DUT'
            initialData={initialData}
            setData={setInitialData}
            columns={columns}
            tableHeight={tableHeight}
          />
        </>
      )}
    </TableContext.Provider>
  );
};

export default TEG06DesignDutTable;
