import { useQuery } from '@tanstack/react-query';
import { IPlotParam, IQueryParams } from 'models';
import { client } from 'utils';

function singlePlotMesurementList({
  searchData,
  options,
}: IQueryParams<IPlotParam>) {
  return useQuery({
    queryKey: ['plot', 'mesurement', searchData],
    queryFn: async () => {
      const response = await client.get('/api/teg/08/plot/measurement', {
        params: searchData,
      });
      return response.data.measurementList;
    },
    ...options,
  });
}

export default function useSinglePlotMesurementList(
  searchData: IPlotParam,
) {
  return singlePlotMesurementList({
    searchData: {
      DVC_NO: searchData.DVC_NO,
      DSG_ID: searchData.DSG_ID,
      MSR_TYPE: searchData.MSR_TYPE,
      MSR_NO: searchData.MSR_NO,
      DUT_ID: searchData.DUT_ID,
    },
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
