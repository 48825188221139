import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IMask } from 'models/interfaces/maskInterface';
import { toast } from 'react-toastify';
import { client, getAlertMessage } from 'utils';

function maskMutation(
  options: UseMutationOptions<unknown, Error, IMask[], unknown>,
) {
  return useMutation({
    mutationFn: async (maskList: IMask[]) => {
      const response = await client.post('/api/teg/02/mask', maskList);
      return response;
    },
    ...options,
  });
}

export default function useMaskMutation() {
  const queryClient = useQueryClient();

  return maskMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['mask'],
      });
      toast.success(getAlertMessage('INFO_00010'));
    },
  });
}
