interface IEmptyBodyProps {
  tableHeight: number;
  colSpan: number;
}

const EmptyBody = ({
  tableHeight,
  colSpan,
}: IEmptyBodyProps): JSX.Element => {
  return (
    <tbody>
      <tr
        className='text-center'
        style={{ height: tableHeight - 35 }}
      >
        <td
          className='border-none'
          colSpan={colSpan}
        >
          조회된 결과가 없습니다.
        </td>
      </tr>
    </tbody>
  );
};

export default EmptyBody;
