import { useMutation } from '@tanstack/react-query';
import { IFileDownload } from 'models/interfaces/fileInteface';
import { client } from 'utils';

function commonFileDownloadMutation() {
  return useMutation({
    mutationFn: async (searchFile: IFileDownload) => {
      const response = await client.get(`/api/file/download`, {
        params: searchFile,
        responseType: 'blob',
      });
      const url = searchFile.URL;
      const fileName = url.substring(url.lastIndexOf('/') + 1);
      return [response.data, fileName];
    },
    onSuccess: fileInfo => {
      const targetFile = Array.isArray(fileInfo[0])
        ? fileInfo[0]
        : [fileInfo[0]];
      const fileName = fileInfo[1];

      const file = new Blob(targetFile);
      const url = window.URL.createObjectURL(file);
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    },
  });
}

export default function useCommonFileDownloadMutation() {
  return commonFileDownloadMutation();
}
