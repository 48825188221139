import { CommonObject, ITreeNodeProps } from 'models';
import { useEffect, useRef } from 'react';
import { BiSolidFolder, BiSolidFolderOpen } from 'react-icons/bi';

const TreeNode = ({
  toggleItems,
  setToggleItems,
  currentData,
  setCurrentData,
  nodeKey,
  parentKey,
  renderKey,
  node,
  depth,
  exceptDepth,
  isLastChild,
  treeIndex,
}: ITreeNodeProps) => {
  const prevToggleItems = useRef<string[]>([]);
  const isToggle = toggleItems.includes(node[nodeKey]);
  const isParent = (target: CommonObject) =>
    target.children && target.children.length > 0;
  const isOpen = !node.title && !toggleItems.includes(node[parentKey]);

  const getChildNode = (node: CommonObject): string[] => {
    const childNode = [];

    childNode.push(node[nodeKey]);

    if (isParent(node)) {
      for (const child of node.children) {
        if (!isParent(child)) continue;
        childNode.push(...getChildNode(child));
      }
    }

    return childNode;
  };

  const addToggleMenu = (target: CommonObject) => {
    setToggleItems([...toggleItems, target[nodeKey]]);
  };

  const restoreToggleMenu = () => {
    const prev = prevToggleItems.current || [];
    setToggleItems([...toggleItems, ...prev]);
    prevToggleItems.current = [];
  };

  const removeToggleMenu = (item: CommonObject, childNode: string[]) => {
    const newToggleMenu = toggleItems.filter(
      toggle => toggle !== item[nodeKey] && !childNode.includes(toggle),
    );
    prevToggleItems.current = toggleItems.filter(
      toggle => toggle === item[nodeKey] || childNode.includes(toggle),
    );
    setToggleItems(newToggleMenu);
  };

  const handleToggle = (item: CommonObject) => {
    const childNode = getChildNode(item).filter(
      node => node !== item[nodeKey],
    );
    const isIncludes = toggleItems.includes(item[nodeKey]);
    const currentToggleItems = prevToggleItems.current;

    if (currentToggleItems.length === 0 && !isIncludes) {
      addToggleMenu(item);
    } else if (currentToggleItems.length > 0 && !isIncludes) {
      restoreToggleMenu();
    } else if (childNode.length > 0 && isIncludes) {
      removeToggleMenu(item, childNode);
    } else if (childNode.length === 0 && isIncludes) {
      setToggleItems(
        toggleItems.filter(toggle => toggle !== item[nodeKey]),
      );
    }
  };

  useEffect(() => {
    if (isParent(node)) setToggleItems(old => [...old, node[nodeKey]]);
  }, []);

  if (isOpen) return null;

  return (
    <div
      className={`tree-item ${
        currentData && currentData?.data?.[nodeKey] === node[nodeKey]
          ? 'bg-blue-100'
          : 'even:bg-slate-100'
      }`}
      onClick={() => setCurrentData({ index: treeIndex, data: node })}
    >
      {Array(depth + 1)
        .fill(0)
        .map((_, nodeDepth) => (
          <div
            key={nodeDepth}
            className={`line-box ${
              nodeDepth === depth && isLastChild
                ? 'border-none'
                : exceptDepth.includes(nodeDepth)
                  ? 'border-none'
                  : 'border-l'
            }`}
          >
            {nodeDepth === depth ? (
              <div
                className={isLastChild ? 'border-l border-b' : 'border-b'}
              />
            ) : null}
          </div>
        ))}
      <div className='tree-content'>
        {isParent(node) ? (
          isToggle ? (
            <BiSolidFolderOpen
              className='text-blue-600 cursor-pointer'
              onClick={() => handleToggle(node)}
            />
          ) : (
            <BiSolidFolder
              className='text-yellow-500 cursor-pointer'
              onClick={() => handleToggle(node)}
            />
          )
        ) : (
          <BiSolidFolder className='text-slate-400' />
        )}
        {node[renderKey]}
      </div>
    </div>
  );
};

export default TreeNode;
