import { useHeight, useSearchComboList } from 'hooks';
import { useState } from 'react';
import {
  CommonButton,
  FrameInfo,
  FrameInfoInput,
  FrameInfoSelect,
} from 'components';
import { useRecoilState } from 'recoil';
import { editValues } from 'utils';
import COCM130M01Table from './COCM130M01Table';
import { FrameContent } from 'styles';
import { CustomChangeEvent } from 'models';
import { standardInfomationState } from 'store';

const COCM130M01 = (): JSX.Element => {
  const getHeight = useHeight();
  const [searchMessage, setSearchMessage] = useRecoilState(
    standardInfomationState.searchMessage,
  );
  const [values, setValues] = useState(searchMessage);
  const systemOptions = useSearchComboList({ CMB_CD: 'CMB0026' });
  const combinedSystemOptions = [
    { label: '전체', value: '' },
    ...systemOptions,
  ];
  const messageUseOptions = [
    { label: '전체', value: '' },
    { label: '사용', value: '1' },
    { label: '사용안함', value: '0' },
  ];

  const editValue = (e: CustomChangeEvent) => editValues(e, setValues);

  const handleSearchMessage = () => {
    setSearchMessage(values);
  };

  return (
    <>
      <FrameInfo>
        <div className='search-form'>
          <div className='input-block'>
            <FrameInfoSelect
              title='메시지구분'
              options={combinedSystemOptions}
              name='MSG_DTT_CD'
              onChange={editValue}
            />
            <FrameInfoInput
              title='메시지코드'
              type='text'
              name='MSG_CD'
              value={values?.MSG_CD || ''}
              onChange={editValue}
            />
            <FrameInfoInput
              title='메시지명'
              type='text'
              name='MSG_NM'
              value={values?.MSG_NM || ''}
              onChange={editValue}
            />
            <FrameInfoSelect
              title='사용여부'
              options={messageUseOptions}
              name='USE_YN'
              onChange={editValue}
            />
          </div>
        </div>
        <div>
          <CommonButton
            title='조회'
            onClick={handleSearchMessage}
          />
        </div>
      </FrameInfo>
      <FrameContent>
        <div className='w-full'>
          {systemOptions.length > 0 && (
            <COCM130M01Table
              tableHeight={getHeight(650)}
              options={systemOptions}
            />
          )}
        </div>
      </FrameContent>
    </>
  );
};

export default COCM130M01;
