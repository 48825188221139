import { useQuery } from '@tanstack/react-query';
import { IQueryParams } from 'models';
import { ISearchDeviceType } from 'models/interfaces/dscCodeInterface';
import { client } from 'utils';

function dscCodeList({
  searchData,
  options,
}: IQueryParams<ISearchDeviceType>) {
  return useQuery({
    queryKey: ['dsc_cd', searchData],
    queryFn: async () => {
      const response = await client.get('api/co/cm/02/description', {
        params: searchData,
      });
      return response.data.dscList;
    },
    ...options,
  });
}

export default function useDscCodeList(searchData: ISearchDeviceType) {
  return dscCodeList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
