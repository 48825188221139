import {
  UseInfiniteQueryOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { CommonObject } from 'models/interfaces/commonInterface';

export type AxiosErrorResponse = {
  status: number;
  message: string;
};

export type UseQueryOptionsWithoutQueryKey<T = unknown> = Omit<
  UseQueryOptions<unknown, AxiosError<AxiosErrorResponse>, T>,
  'queryKey'
>;

export type UseInfiniteQueryOptionsWithoutQueryKey = Omit<
  UseInfiniteQueryOptions<CommonObject, Error>,
  'queryKey'
>;

export const enum RowType {
  NORMAL = 0,
  ADD = 1,
  UPDATE = 2,
  DELETE = 3,
}

export const enum PopupCode {
  EQUIPMENT = 'TEST001',
  CODE_TYPE = 'TEST002',
  COMMON_CODE = 'TEST003',
  DEPARMENT = 'TEST004',
  MANAGE_PROCESS = 'TEST005',
  PROGRAM = 'TEST006',
  MENU = 'TEST010',
  CAHMBER = 'TEST102',
  RECIPE = 'TEST103',
  CHAPTER = 'TEST104',
}
