import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { ICommonCode } from 'models';
import { client } from 'utils';

function commonCodeMutation(
  options: UseMutationOptions<unknown, Error, ICommonCode[], unknown>,
) {
  return useMutation({
    mutationFn: async commonCode => {
      const response = await client.post(
        '/api/co/cm/120m01/code',
        commonCode,
      );
      return response;
    },
    ...options,
  });
}

export default function useCommonCodeMutation() {
  const queryClient = useQueryClient();

  return commonCodeMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['code', 'common_code'],
      });
    },
  });
}
