import { useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Table, ReadOnlyTextCell, ReadOnlyNumberCell } from 'components';
import { TableContext } from 'utils';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTableData } from 'hooks';
import { Columns, CommonObject, CurrentData, ITableContext } from 'models';
import { maskState } from 'store';
import useSubList from 'apis/queries/useDutSubList';

const columnHelper = createColumnHelper();

const TEG02SubTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchData = useRecoilValue(maskState.searchSub);
  const subResponse = useSubList(searchData) as CommonObject;
  const sub = subResponse?.data?.dutSubList;
  const maskDeviceList = subResponse?.data?.maskDeviceList;
  const [dragStart, setDragStart] = useState<number | null>(null);
  const [selectedDataList, setSelectedDataList] = useState<CurrentData[]>(
    [],
  );
  const setMaskDeviceOptions = useSetRecoilState(
    maskState.maskDeviceOptions,
  );

  const [currentData, setCurrentData] = useRecoilState(
    maskState.currentSubData,
  );
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: sub,
    atom: maskState.subList,
    effect: () => {
      setInitialData(sub);
      setCurrentData({ index: 0, data: sub[0] });
      setMaskDeviceOptions(
        maskDeviceList?.map(
          (data: { CODE: string; MSK_DVC_CD: string }) => ({
            label: data.MSK_DVC_CD,
            value: data.MSK_DVC_CD,
          }),
        ) || '',
      );
    },
  });
  const generateCoordinateColumns = (count: number) => {
    const columns = [];
    for (let i = 1; i <= count; i++) {
      const paddedIndex = i.toString().padStart(2, '0');
      columns.push(
        columnHelper.accessor(`CRD_X_${paddedIndex}`, {
          header: `D${paddedIndex}`,
          cell: ReadOnlyNumberCell,
          filterFn: 'includesString',
          size: 30,
          minSize: 30,
          maxSize: 30,
          meta: {
            colSpan: 2,
          },
        }),
        columnHelper.accessor(`CRD_Y_${paddedIndex}`, {
          header: `D${paddedIndex}`,
          cell: ReadOnlyNumberCell,
          filterFn: 'includesString',
          enableHiding: false,
          size: 30,
          minSize: 30,
          maxSize: 30,
        }),
      );
    }
    return columns;
  };
  const defaultColumns = [
    columnHelper.accessor('MSK_DVC_CD', {
      header: '디바이스',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 90,
    }),
    columnHelper.accessor('DUT_ID', {
      header: '모듈',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 50,
      meta: {
        justify: 'center',
      },
    }),
    ...generateCoordinateColumns(12),
  ];

  const columns = useMemo(() => defaultColumns, []) as Columns;

  const contextValue: ITableContext = {
    currentData,
    setCurrentData,
    selectedDataList,
    setSelectedDataList,
    dragStart,
    setDragStart,
  };

  return (
    <TableContext.Provider value={contextValue}>
      {initialData && (
        <Table
          title='좌표 정보'
          initialData={initialData}
          setData={setInitialData}
          columns={columns}
          editable={false}
          tableHeight={tableHeight}
        />
      )}
    </TableContext.Provider>
  );
};

export default TEG02SubTable;
