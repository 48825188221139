import { CustomMouseEvent, ISideMenu } from 'models';
import { BiArrowToLeft, BiArrowToTop, BiX } from 'react-icons/bi';
import { VscCloseAll } from 'react-icons/vsc';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { layoutState, menuState, selectState } from 'store';
import { TabBox, TabItem } from 'styles';
import { checkChangeFrameData } from 'utils';

const Tab = (): JSX.Element => {
  const [sideOpen, setSideOpen] = useRecoilState(layoutState.sideOpen);
  const [topOpen, setTopOpen] = useRecoilState(layoutState.topOpen);
  const selectSideMenu = useRecoilValue(selectState.selectSideMenu);
  const [tabMenu, setTabMenu] = useRecoilState(menuState.tabMenu);
  const setSelectTargetMenu = useSetRecoilState(
    selectState.selectTargetMenu,
  );
  const wholeFrameData = useRecoilValue(menuState.wholeFrameData);

  const handleSelectTab = (tab: ISideMenu) => {
    const targetMenu = { value: tab, type: 'tab' };
    setSelectTargetMenu(targetMenu);
  };

  const removeTab = (e: CustomMouseEvent, target: ISideMenu) => {
    e.stopPropagation();

    const isChanged = checkChangeFrameData(wholeFrameData, target.PGM_ID);
    // if (isChanged && !confirm(getAlertMessage('CONF_00030'))) return;
    if (isChanged && !confirm('변경된 데이터가 있습니다. 닫으시겠습니까?'))
      return;

    const newTabMenu = tabMenu.filter(tab => target !== tab);
    const isSameMenu = selectSideMenu.MNU_ID === target.MNU_ID;

    if (newTabMenu.length > 0 && isSameMenu) {
      setSelectTargetMenu({
        value: newTabMenu[newTabMenu.length - 1],
        type: 'tab',
      });
    }

    if (newTabMenu.length === 0) {
      setSelectTargetMenu({});
    }

    setTabMenu(newTabMenu);
  };

  const removeAllTab = () => {
    if (!confirm('모든 탭을 닫으시겠습니까?')) return;

    setTabMenu([]);
    setSelectTargetMenu({});
  };
  return (
    <TabBox>
      <div className='fold-icon side'>
        <BiArrowToLeft
          className={`${sideOpen ? undefined : 'rotate-180'}`}
          onClick={() => setSideOpen(old => !old)}
        />
      </div>
      <div className='tab-div'>
        {tabMenu &&
          tabMenu.map(tab => (
            <TabItem
              key={tab.MNU_ID}
              $isSelected={selectSideMenu.MNU_ID === tab.MNU_ID}
              onClick={() => handleSelectTab(tab)}
            >
              <span>{tab.MNU_NM}</span>
              <BiX onClick={e => removeTab(e, tab)} />
            </TabItem>
          ))}
      </div>
      <VscCloseAll
        className='close-icon'
        onClick={removeAllTab}
      />
      <div className='fold-icon top'>
        <BiArrowToTop
          className={`${topOpen ? undefined : 'rotate-180'}`}
          onClick={() => setTopOpen(old => !old)}
        />
      </div>
    </TabBox>
  );
};

export default Tab;
