import { useEffect, useMemo, useRef, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  TableContext,
  addRowData,
  addRowNumber,
  customEqual,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useMoveTeg, useTableData } from 'hooks';
import {
  CheckInputCell,
  DisplayButton,
  ReadOnlyTextCell,
  StatusCell,
  StatusIcon,
  Table,
  TextCell,
} from 'components';
import { DataTableContainer } from 'styles';
import {
  Columns,
  CommonObject as ModuleObject,
  CurrentData,
  ITableContext,
  IModule,
  IDesign,
  defaultDesign,
  CommonObject,
  RowType,
  CommonArray,
} from 'models';
import { designState, moduleState } from 'store';
import {
  useCommonFileDownloadMutation,
  useDesignMutation,
  useFileCheck,
  useModuleAnalysis,
  useModuleList,
} from 'apis';
import { defaultModuleDoc } from 'models/defaults/moduleDefaults';
import { handleFileCheck } from './TEG04Functions';

const columnHelper = createColumnHelper();
const newRow: IDesign = defaultDesign;

const TEG04DesignTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const moveTeg = useMoveTeg();
  const searchModule = useRecoilValue(moduleState.searchModule('teg04'));
  const searchModuleRef = useRef(searchModule);

  const [currentData, setCurrentData] = useRecoilState(
    moduleState.currentDesign,
  );
  const setSearchDut = useSetRecoilState(
    moduleState.searchModuleDut('teg04'),
  );
  const setSearchDesignDescription = useSetRecoilState(
    designState.searchDesignDescription,
  );
  const [moduleData, setModuleData] = useRecoilState(
    moduleState.moduleList('teg04'),
  );
  const moduleResponse = useModuleList(searchModule) as ModuleObject;

  const designData = moduleResponse?.data?.designList;
  const [backupData, setBackupData] = useState<CommonArray>([]);

  const [searchModuleAnalysis, setSearchModuleAnalysis] =
    useState(defaultModuleDoc);
  const [fileCheck, setFileCheck] = useState({ filePath: '', FLAG: true });

  useEffect(() => {
    if (customEqual(searchModule, searchModuleRef.current)) {
      return;
    }
    searchModuleRef.current = searchModule;
    setSearchDut({
      moduleList: [],
      dutSearch: {
        DVC_NO: currentData?.data?.DVC_NO,
        DSG_ID: '',
      },
    });
  }, [searchModule]);

  const [initialData, setInitialData] = useTableData<ModuleObject>({
    fetchData: designData,
    atom: moduleState.designList('teg04'),
    effect: () => {
      const newData = addRowNumber(designData);
      setInitialData(newData);
      setBackupData(newData);
      setCurrentData({ index: 0, data: newData[0] });
    },
  });

  useEffect(() => {
    if (moduleResponse?.data?.moduleList) {
      setModuleData(moduleResponse?.data?.moduleList); //모듈
    }
  }, [moduleResponse.data?.moduleList]);

  useEffect(() => {
    setSearchDut(prev => ({
      ...prev,
      moduleList: moduleData.map((module: any) => {
        const newModule = { ...module };
        for (let i = 1; i <= 12; i++) {
          const key = `DUT_CHECK${String(i).padStart(2, '0')}`;
          delete newModule[key];
        }
        return newModule;
      }) as IModule[],
    }));
  }, [moduleData]);

  useEffect(() => {
    if (currentData.data) {
      setSearchDut(prev => ({
        ...prev,
        dutSearch: {
          DVC_NO: currentData.data.DVC_NO,
          DSG_ID: currentData.data.DSG_ID,
        },
      }));
    }
  }, [currentData]);

  const designMutation = useDesignMutation();

  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 30,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('no', {
      header: 'No',
      size: 30,
      cell: ReadOnlyTextCell,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('DSG_NM', {
      header: '디자인명',
      cell: TextCell,
      size: 140,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('RLS_FLAG', {
      header: '릴리즈',
      cell: CheckInputCell,
      size: 40,
      enableColumnFilter: false,
    }),
    columnHelper.display({
      header: '디자인',
      cell: DisplayButton,
      size: 60,
      meta: {
        text: '관리',
        onClick: (row: CommonObject) => handleMove(row),
        disabled: (row: CommonObject) => row.ROWTYPE === RowType.ADD,
      },
    }),
  ];
  const columns = useMemo(
    () => defaultColumns,
    [moduleResponse],
  ) as Columns;

  const handleMove = (row: CommonObject) => {
    const { DSG_ID } = row;
    const { PRJ_NO, PRC_NO, DVC_NO } = searchModule;
    setSearchDesignDescription(old => ({
      ...old,
      PRJ_NO,
      PRC_NO,
      DVC_NO,
      DSG_ID,
    }));
    moveTeg('UIE006TEG06');
  };

  const handleAddData = () => {
    const newDsgId =
      initialData.reduce((max, item) => {
        return item.DSG_ID > max ? item.DSG_ID : max;
      }, 0) + 1;
    const modifiedNewRow = {
      ...newRow,
      DVC_NO: searchModule.DVC_NO,
      DSG_ID: newDsgId,
    };
    addRowData(
      setInitialData,
      setCurrentData,
      modifiedNewRow,
      initialData.length,
    );
  };

  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);
    if (removedData.length > 0) {
      setInitialData(removedData);
      selectNextDataByRemove(
        selectData,
        initialData.length - 1,
        removedData,
        setCurrentData,
      );
    }
  };

  const handleSaveData = () => {
    //전체 삭제후 다시 저장
    const checkKeys = ['DSG_NM'];
    const targetData = saveTableData(initialData, checkKeys, false);
    const saveData = {
      PRJ_NO: String(searchModule.PRJ_NO),
      designList: targetData,
    };
    if (targetData.length > 0) {
      designMutation.mutate(saveData);
    } else {
      setInitialData(initialData);
    }
  };

  const handleAnalysisDoc = () => {
    setSearchModuleAnalysis(prev => ({
      PRJ_NO: String(searchModule.PRJ_NO),
      DVC_NO: String(searchModule.DVC_NO),
      FLAG: !prev.FLAG,
    }));
  };
  const analysisResponse = useModuleAnalysis(
    searchModuleAnalysis,
  ) as CommonObject;
  useEffect(() => {
    handleFileCheck(
      analysisResponse?.data,
      searchModuleAnalysis,
      '_D_AnalysisReport.pptx',
      setFileCheck,
    );
  }, [analysisResponse?.data?.FLAG]);
  const checkResponse = useFileCheck(fileCheck.filePath, fileCheck.FLAG);
  const downloadFile = useCommonFileDownloadMutation();
  const fileDownload = () => {
    downloadFile.mutate({ URL: fileCheck.filePath });
  };
  useEffect(() => {
    if (checkResponse?.data !== undefined) {
      fileDownload();
    }
  }, [checkResponse?.data]);

  const contextValue: ITableContext = {
    handleAddData,
    handleRemoveData,
    handleSaveData,
    handleAnalysisDoc,
    currentData,
    setCurrentData,
    customDisabled: {
      fetched:
        searchModule.PRJ_NO === '' ||
        searchModule.PRC_NO === '' ||
        searchModule.DVC_NO === '',
    },
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        {initialData && (
          <Table
            title='디자인'
            initialData={initialData}
            backupData={backupData}
            setData={setInitialData}
            columns={columns}
            editable={true}
            draggable={true}
            tableHeight={tableHeight}
          />
        )}
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default TEG04DesignTable;
