import { HeaderUserInfoContainer } from 'styles';
import { BsFillPersonFill } from 'react-icons/bs';
import { useState } from 'react';
import HeaderUserInfoBox from './HeaderUserInfoBox';
import { CustomMouseEvent } from 'models';

const HeaderUserInfo = (): JSX.Element => {
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const handleOpen = (e: CustomMouseEvent) => {
    e.stopPropagation();
    setOpenInfo(!openInfo);
  };
  return (
    <HeaderUserInfoContainer>
      <div
        className='out-circle'
        onClick={handleOpen}
      >
        <div className='inner-circle'>
          <BsFillPersonFill />
        </div>
      </div>
      <HeaderUserInfoBox
        openInfo={openInfo}
        setOpenInfo={setOpenInfo}
      />
    </HeaderUserInfoContainer>
  );
};

export default HeaderUserInfo;
