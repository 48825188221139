import {
  IProcess,
  IProject,
  ISearchProject,
} from 'models/interfaces/projectInterface';
import { RowType } from 'models/types/commonTypes';

export const defaultProject: IProject = {
  PRJ_NM: '',
  STR_YMD: '',
  END_YMD: '',
  RMK_CONT: '',
  UPD_DATE: '',
  UPD_ID: '',
  OWR_ID: '',
  ROWTYPE: RowType.ADD,
};

export const defaultProcess: IProcess = {
  PRJ_NO: -1,
  PRS_CD1: '',
  PRS_CD2: '',
  PRS_CD12: '',
  MSK_CD: '',
  RMK_CNT: '',
  UPD_DATE: '',
  UPDR_ID: '',
  ROWTYPE: RowType.ADD,
};

export const defaultSearchProject: ISearchProject = {
  STR_DATE: '',
  PRS_CD_01: '',
  PRS_CD_02: '',
  DVC_NM: '',
  PRJ_NO: 0,
};
