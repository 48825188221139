import { CustomMouseEvent, IColumnMeta, ITableCellProps } from 'models';
import { DataTableButton } from 'styles';

const UploadCell = ({
  getValue,
  row,
  column,
}: ITableCellProps): JSX.Element => {
  const value = getValue();
  const columnMeta: IColumnMeta | undefined = column.columnDef.meta;
  const handleOnClick = (e: CustomMouseEvent) => {
    e.stopPropagation();
    columnMeta?.onClick?.(row.original);
  };

  return (
    <div className='flex justify-between w-full'>
      <span>{value}</span>
      <DataTableButton
        className='w-[150px]'
        onClick={handleOnClick}
      >
        업로드
      </DataTableButton>
    </div>
  );
};

export default UploadCell;
