import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IDocument } from 'models';
import { toast } from 'react-toastify';
import { client, getAlertMessage } from 'utils';

function documentMutation(
  options: UseMutationOptions<unknown, Error, IDocument[], unknown>,
) {
  return useMutation({
    mutationFn: async (documentData: IDocument[]) => {
      const response = await client.post('/api/teg/09/pdk', documentData);
      return response;
    },
    ...options,
  });
}

export default function useDocumentMutation() {
  const queryClient = useQueryClient();

  return documentMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['doc'] });
      toast.success(getAlertMessage('INFO_00010'));
    },
  });
}
