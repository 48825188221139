import { ChangeEvent, useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Table, CheckInputCell, ReadOnlyTextCell } from 'components';
import { TableContext } from 'utils';
import { Columns, CommonObject } from 'models';
import { useRecoilState, useRecoilValue } from 'recoil';
import { projectState } from 'store';
import { useTableData } from 'hooks';
import { ITableContext } from 'models';
import useDeviceListByProject from 'apis/queries/useDeviceListByProject';

const columnHelper = createColumnHelper();

const TEG01ProjectCopyTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchData = useRecoilValue(projectState.searchProcess);
  const { data: device } = useDeviceListByProject(
    searchData,
  ) as CommonObject;
  const [currentData, setCurrentData] = useRecoilState(
    projectState.currentDeviceDataByProject,
  );
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: device,
    atom: projectState.deviceList,
    effect: () => {
      setInitialData(device);
      setCurrentData({ index: 0, data: device[0] });
    },
  });
  const CheckHeader = () => {
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      const checkValue = e.target.checked;
      setInitialData(prevData => {
        const newData = prevData.map(data => ({
          ...data,
          CHK: checkValue ? 'Y' : 'N',
        }));
        return newData;
      });
    };

    return (
      <>
        <input
          type='checkbox'
          onChange={onChange}
          name='CHECK'
        />
      </>
    );
  };
  const defaultColumns = [
    columnHelper.accessor('CHK', {
      header: () => <CheckHeader />,
      cell: CheckInputCell,
      size: 30,
      enableColumnFilter: false,
    }),
    columnHelper.accessor('PRC_NM', {
      header: '공정',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('DVC_NM', {
      header: '디바이스',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const contextValue: ITableContext = {
    currentData,
    setCurrentData,
  };

  return (
    <TableContext.Provider value={contextValue}>
      {initialData && (
        <Table
          title='디바이스 목록'
          initialData={initialData}
          setData={setInitialData}
          columns={columns}
          tableHeight={tableHeight}
        />
      )}
    </TableContext.Provider>
  );
};

export default TEG01ProjectCopyTable;
