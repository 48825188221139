import {
  CommonArray,
  IDescriptionCheck,
  defaultModuleMove,
  defaultSearchDrawImages,
} from 'models';
import { defaultSearchDescription } from 'models/defaults/descriptionDefaults';
import { atom, atomFamily, selectorFamily } from 'recoil';

const searchDescription = atomFamily({
  key: 'searchDescription',
  default: defaultSearchDescription,
});

const deviceSelector = selectorFamily({
  key: 'deviceSelector',
  get:
    name =>
    ({ get }) => {
      const search = get(searchDescription(name));
      return {
        DVC_NO: search.DVC_NO,
      };
    },
});

const moduleMove = atom({
  key: 'moduleMove',
  default: defaultModuleMove,
});

const dutIdMove = atom({
  key: 'dutIdMove',
  default: 0,
});

const selectedDutId = atom({
  key: 'selectedDutId',
  default: '',
});

const searchDrawImages = atom({
  key: 'searchDrawImages',
  default: defaultSearchDrawImages,
});

const descriptionMoveList = atom<CommonArray>({
  key: 'descriptionMoveList',
  default: [],
});

const descriptionCheckList = atom<IDescriptionCheck[]>({
  key: 'descriptionCheckList',
  default: [],
});

export const descriptionState = {
  searchDescription,
  deviceSelector,
  moduleMove,
  selectedDutId,
  dutIdMove,
  searchDrawImages,
  descriptionMoveList,
  descriptionCheckList,
};
