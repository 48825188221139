import CommonButton from 'components/common/CommonButton';
import {
  CustomChangeEvent,
  CustomMouseEvent,
  IModalSearchProps,
  IPopupSearchParam,
} from 'models';
import { KeyboardEvent, useState } from 'react';
import { ModalSearchBox } from 'styles';

const defaultValue: IPopupSearchParam = {
  SRCH_COL_IDX: '0',
  SRCH_VALUE: '',
};

const ModalSearch = ({
  options,
  setSearchValue,
}: IModalSearchProps): JSX.Element => {
  const [values, setValues] = useState<IPopupSearchParam>(defaultValue);

  const handleOnChange = (e: CustomChangeEvent) => {
    const value = e.target.value;
    const type = e.target.type;

    if (type === 'select-one') {
      setValues(old => ({
        ...old,
        SRCH_COL_IDX: value,
      }));
      return;
    }

    setValues(old => ({
      ...old,
      SRCH_VALUE: value,
    }));
  };

  const handleSearch = (
    e: KeyboardEvent<HTMLInputElement> | CustomMouseEvent,
  ) => {
    if (e.type === 'keydown' && 'key' in e && e.key !== 'Enter') return;
    setSearchValue(old => ({ ...old, ...values }));
  };

  return (
    <ModalSearchBox>
      <div className='field-box'>
        <span>검색항목</span>
        <select onChange={handleOnChange}>
          {options.map(option => (
            <option
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </select>
        <input
          type='text'
          value={values.SRCH_VALUE}
          onKeyDown={handleSearch}
          onChange={handleOnChange}
        />
      </div>
      <div>
        <CommonButton
          title='조회'
          onClick={handleSearch}
        />
      </div>
    </ModalSearchBox>
  );
};

export default ModalSearch;
