import { useRef, useState } from 'react';
import CommonButton from './CommonButton';
import { CommonObject, CustomChangeEvent, IFileUploadProps } from 'models';
import {
  FileUploadControlBox,
  FileUploadImage,
  FileUploadTitle,
} from 'styles';
import { useImage } from 'apis';

const FileUpload = ({
  title,
  handleUpload,
  handleDownload,
  isEmpty,
  imageUrl = '',
  handleChange,
  showImage = true,
}: IFileUploadProps): JSX.Element => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState(
    isEmpty ? '' : imageUrl.split('/').pop(),
  );
  const { data: url } = useImage(imageUrl) as CommonObject;
  const selectFile = (e: CustomChangeEvent) => {
    const event = e.target as HTMLInputElement;
    const file = event.files?.[0];

    if (!file) {
      alert('파일을 선택하세요.');
      return;
    }
    setFileName(file.name);
    handleChange?.(file);
  };
  const openFileInput = () => {
    fileRef?.current?.click();
  };

  return (
    <div className='w-[400px]'>
      <FileUploadTitle>{title}</FileUploadTitle>
      <FileUploadControlBox>
        <div className='flex-grow'>
          <input
            className='file-name'
            type='text'
            readOnly
            value={fileName}
          />
          <input
            type='file'
            ref={fileRef}
            className='hidden'
            onChange={e => selectFile(e)}
          />
          <CommonButton
            title='...'
            onClick={openFileInput}
          />
        </div>
        <div className='flex w-fit'>
          {handleUpload && (
            <CommonButton
              title='업로드'
              onClick={handleUpload}
            />
          )}
          {handleDownload && (
            <CommonButton
              title='다운로드'
              onClick={handleDownload}
            />
          )}
        </div>
      </FileUploadControlBox>
      {showImage && (
        <FileUploadImage>
          <div>
            <img src={isEmpty ? null : url} />
          </div>
        </FileUploadImage>
      )}
    </div>
  );
};

export default FileUpload;
