import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchPopup } from 'models';
import { client } from 'utils';

function searchPopupList({
  searchData,
  options,
}: IQueryParams<ISearchPopup>) {
  return useQuery({
    queryKey: ['system_setting', 'popupList', searchData],
    queryFn: async () => {
      const response = await client.get('/api/co/cm/220m01/search/popup', {
        params: searchData,
      });

      return response.data.popupList || [];
    },
    ...options,
  });
}

export default function useSearchPopupList(searchData: ISearchPopup) {
  return searchPopupList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
