import { CustomMouseEvent, RowType } from 'models';
import { ModalInner, ModalOverlay, ModalWrapper } from 'styles';
import { useHeight } from 'hooks';
import TEG01ProjectCopyTable from './TEG01ProjectCopyTable';
import { CommonButton } from 'components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { projectState } from 'store';

const TEG01ProjectCopyModal = ({
  onClose,
}: {
  onClose: () => void;
}): JSX.Element => {
  const getHeight = useHeight();

  const [currentProjectData, setCurrentProjectData] = useRecoilState(
    projectState.currentProjectData,
  );
  const [projectInitialData, setProjectInitialData] = useRecoilState(
    projectState.projectList,
  );
  const deviceListByProject = useRecoilValue(projectState.deviceList);
  const setCopyList = useSetRecoilState(projectState.projectCopyList);

  const selectCopyDevice = () => {
    const copyProjectNumber = currentProjectData?.data?.PRJ_NO;
    const copyProjectNm = currentProjectData?.data?.PRJ_NM + '_copy';
    const newRow = {
      PRJ_NO: copyProjectNumber,
      PRJ_NM: copyProjectNm,
      ROWTYPE: RowType.ADD,
    };
    if (projectInitialData) {
      setProjectInitialData(old => [...old, newRow]);
      deviceListByProject.map(data => {
        if (data['CHK'] === 'Y') {
          setCopyList(old => [data, ...old]);
        }
      });
    }
    setCurrentProjectData({
      index: projectInitialData.length,
      data: newRow,
    });
    onClose();
  };
  const close = (e: CustomMouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  return (
    <>
      <ModalOverlay />
      <ModalWrapper
        onClick={close}
        tabIndex={-1}
      >
        <ModalInner
          tabIndex={0}
          title='프로젝트 복사'
          className='w-[600px] h-[700px] flex justify-center'
        >
          <div className='w-[600px] space-y-2'>
            <TEG01ProjectCopyTable tableHeight={getHeight(600)} />
            <div className='w-full flex justify-end'>
              <CommonButton
                title='선택'
                onClick={selectCopyDevice}
              ></CommonButton>
              <CommonButton
                title='닫기'
                onClick={onClose}
              ></CommonButton>
            </div>
          </div>
        </ModalInner>
      </ModalWrapper>
    </>
  );
};

export default TEG01ProjectCopyModal;
