import { useEffect, useState } from 'react';
import { BiAlignJustify, BiSortDown, BiSortUp } from 'react-icons/bi';
import { FilterInput, FilterWrapper, SortBox } from 'styles';
import {
  CustomChangeEvent,
  ITableColumnFilter,
  ITableHeaderProps,
} from 'models';

type IHeaderFilterProps = ITableHeaderProps;
interface ISortData {
  icon: JSX.Element;
  title: string;
  type: string;
}

const sortData: ISortData[] = [
  { icon: <BiSortUp />, title: 'A to Z', type: 'asc' },
  { icon: <BiSortDown />, title: 'Z to A', type: 'desc' },
  { icon: <BiAlignJustify />, title: 'Normal', type: 'normal' },
];

const HeaderFilter = ({
  header,
  tableMeta,
}: IHeaderFilterProps): JSX.Element => {
  const { column } = header;
  const [inputValue, setInputValue] = useState<string | string[]>('');
  const { filterFn, size } = column.columnDef;

  const handleInputValue = (e: CustomChangeEvent) => {
    const value = e.target.value;
    setInputValue(value);
  };

  const handleNumberInputValue = (e: CustomChangeEvent) => {
    const value = e.target.value;
    const name = e.target.name;

    setInputValue((old: string | string[]) => {
      if (name === 'min') {
        return [value, old[1]];
      } else {
        return [old[0], value];
      }
    });
  };

  const handleSortClick = (type: boolean, columnId: string) => {
    tableMeta?.handleSorting?.(type, columnId);
  };

  const handleSortReset = () => {
    tableMeta?.resetSorting?.();
  };

  useEffect(() => {
    if (inputValue) {
      tableMeta?.handleFilters?.((columnFilters: ITableColumnFilter[]) => {
        const currentFilter = columnFilters.find(
          filter => filter.id === column.id,
        );

        if (currentFilter) {
          return columnFilters.map(filter =>
            filter.id === column.id
              ? { ...filter, value: inputValue }
              : filter,
          );
        }

        return [...columnFilters, { id: column.id, value: inputValue }];
      });
    } else {
      const emptyFilters = [] as ITableColumnFilter[];
      tableMeta?.handleFilters?.(emptyFilters);
    }
  }, [inputValue]);

  return (
    <FilterWrapper $columnSize={size}>
      <div className='sort-wrapper'>
        {sortData.map((sort, index) => (
          <SortBox
            key={index}
            onClick={
              sort.type === 'desc'
                ? () => handleSortClick(true, column.id)
                : sort.type === 'asc'
                  ? () => handleSortClick(false, column.id)
                  : () => handleSortReset()
            }
          >
            {sort.icon}
            <span>{sort.title}</span>
          </SortBox>
        ))}
      </div>
      <div className='py-1'>
        {filterFn !== 'inNumberRange' ? (
          <FilterInput
            type={'text'}
            placeholder='Search'
            value={inputValue}
            onChange={handleInputValue}
          />
        ) : (
          <div
            className={size && size >= 200 ? 'flex gap-x-1' : undefined}
          >
            <FilterInput
              type='number'
              name='min'
              placeholder='최소 값'
              min={inputValue?.[0] || ''}
              max={inputValue?.[1] || ''}
              value={inputValue?.[0] || ''}
              onChange={handleNumberInputValue}
            />
            <FilterInput
              type='number'
              name='max'
              placeholder='최대 값'
              min={inputValue?.[0] || ''}
              max={inputValue?.[1] || ''}
              value={inputValue?.[1] || ''}
              onChange={handleNumberInputValue}
            />
          </div>
        )}
      </div>
    </FilterWrapper>
  );
};

export default HeaderFilter;
