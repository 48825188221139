import { createColumnHelper } from '@tanstack/react-table';
import {
  useSinglePlotMesureMutation,
  useSinglePlotMesurementList,
} from 'apis';
import { NumberCell, StatusCell, StatusIcon, Table } from 'components';
import { useTableData } from 'hooks';
import {
  Columns,
  CommonObject,
  CurrentData,
  IPlotParam,
  ITableContext,
  MesuerValue,
  defaultCurrentData,
  defaultMesureDC,
  defaultMesureTLP,
} from 'models';
import { useMemo, useState } from 'react';
import { DataTableContainer } from 'styles';
import {
  TableContext,
  addRowData,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';

const columnHelper = createColumnHelper();
const newTLP = defaultMesureTLP;
const newDC = defaultMesureDC;
const normalColumns = [
  columnHelper.display({
    id: 'status',
    cell: StatusCell,
    size: 40,
    header: () => <StatusIcon />,
  }),
  columnHelper.accessor('VDUT', {
    header: 'V_dut',
    cell: NumberCell,
    size: 110,
    filterFn: 'inNumberRange',
  }),
  columnHelper.accessor('Y', {
    header: 'I_dut',
    cell: NumberCell,
    size: 120,
    filterFn: 'inNumberRange',
  }),
  columnHelper.accessor('LKG', {
    header: 'Leakage',
    cell: NumberCell,
    size: 120,
    filterFn: 'inNumberRange',
  }),
];
const dcColumns = [
  columnHelper.display({
    id: 'status',
    cell: StatusCell,
    size: 40,
    header: () => <StatusIcon />,
  }),
  columnHelper.accessor('VJ', {
    header: 'Voltage',
    cell: NumberCell,
    size: 120,
    filterFn: 'inNumberRange',
  }),
  columnHelper.accessor('IJ', {
    header: 'Current',
    cell: NumberCell,
    size: 120,
    filterFn: 'inNumberRange',
  }),
];
const TEG08SingleTable = ({
  tableHeight,
  queryParam,
}: {
  tableHeight: number;
  queryParam: IPlotParam;
}): JSX.Element => {
  const { data: mesurementData } = useSinglePlotMesurementList(
    queryParam,
  ) as CommonObject;
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: mesurementData,
  });
  const singlePlotMesureMutation = useSinglePlotMesureMutation();
  const [dragStart, setDragStart] = useState<number | null>(null);
  const [selectedDataList, setSelectedDataList] = useState<CurrentData[]>(
    [],
  );
  const [currentData, setCurrentData] =
    useState<CurrentData>(defaultCurrentData);
  const isDC = queryParam.MSR_TYPE === 'DC';
  const defaultColumns =
    queryParam.MSR_TYPE === 'DC' ? dcColumns : normalColumns;
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const handleAddData = () => {
    const newMesure = isDC ? newDC : newTLP;

    addRowData(
      setInitialData,
      setCurrentData,
      newMesure,
      initialData.length,
    );
  };

  const handleRemoveData = () => {
    const removedData = removeRowData(selectedDataList, initialData);

    setInitialData(removedData);
    selectNextDataByRemove(
      selectedDataList[selectedDataList.length - 1],
      initialData.length - 1,
      removedData,
      setSelectedDataList,
      true,
    );
  };

  const handleSaveData = () => {
    const combinedData = initialData.map(data => ({
      ...data,
      ...queryParam,
    }));
    const targetData = saveTableData(combinedData, [], false);

    if (targetData.length > 0) {
      singlePlotMesureMutation.mutate(targetData as MesuerValue[]);
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue: ITableContext = {
    handleSaveData,
    handleAddData,
    handleRemoveData,
    currentData,
    setCurrentData,
    selectedDataList,
    setSelectedDataList,
    dragStart,
    setDragStart,
  };
  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        <Table
          title='측정 값'
          initialData={initialData}
          backupData={mesurementData}
          setData={setInitialData}
          columns={columns}
          editable={true}
          tableHeight={tableHeight}
        />
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default TEG08SingleTable;
