import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchDesignDescription } from 'models';
import { client } from 'utils';

function designTeg({
  searchData,
  options,
}: IQueryParams<ISearchDesignDescription>) {
  return useQuery({
    queryKey: ['design', 'teg', searchData],
    queryFn: async () => {
      const response = await client.get(
        '/python/one_module_ppt_analysis',
        {
          params: searchData,
        },
      );
      const { data, status } = response;
      return {
        data,
        status,
      };
    },
    ...options,
  });
}

export default function useDesignTeg(
  searchData: ISearchDesignDescription,
) {
  return designTeg({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled:
        searchData.PRJ_NO !== '' &&
        searchData.DVC_NO !== '' &&
        searchData.DSG_ID !== '',
    },
  });
}
