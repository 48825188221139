import { useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { TableContext } from 'utils';
import { useRecoilValue } from 'recoil';
import { useTableData } from 'hooks';
import {
  ReadOnlyNumberCell,
  ReadOnlyTextCell,
  StatusCell,
  StatusIcon,
  Table,
} from 'components';
import { DataTableContainer } from 'styles';
import {
  Columns,
  ITableContext,
  CommonObject,
  defaultCurrentData,
} from 'models';
import { moduleState } from 'store';
import { useDeviceDutList } from 'apis';

const columnHelper = createColumnHelper();

const TEG04CoordinateTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchModule = useRecoilValue(moduleState.searchModule('teg04'));
  const { data: dutData } = useDeviceDutList(searchModule) as CommonObject;

  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: dutData,
  });
  const [currentData, setCurrentData] = useState(defaultCurrentData);

  const generateCoordinateColumns = (count: number) => {
    const columns = [];
    for (let i = 1; i <= count; i++) {
      const paddedIndex = i.toString().padStart(2, '0');
      columns.push(
        columnHelper.accessor(`CRD_X_${paddedIndex}`, {
          header: `D${paddedIndex}`,
          cell: ReadOnlyNumberCell,
          filterFn: 'includesString',
          size: 30,
          meta: {
            colSpan: 2,
          },
        }),
        columnHelper.accessor(`CRD_Y_${paddedIndex}`, {
          header: `D${paddedIndex}`,
          cell: ReadOnlyNumberCell,
          filterFn: 'includesString',
          enableHiding: false,
          size: 30,
        }),
      );
    }
    return columns;
  };

  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 20,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('MSK_DVC_CD', {
      header: '디바이스',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 80,
    }),
    columnHelper.accessor('MDL_NM', {
      header: '모듈',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 50,
    }),
    ...generateCoordinateColumns(12),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const contextValue: ITableContext = {
    currentData,
    setCurrentData,
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        {initialData && (
          <Table
            title='좌표 정보'
            initialData={initialData}
            setData={setInitialData}
            columns={columns}
            editable={false}
            tableHeight={tableHeight}
          />
        )}
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default TEG04CoordinateTable;
