import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IDeviceDescriptionWrapper } from 'models';
import { toast } from 'react-toastify';
import { client, getAlertMessage } from 'utils';

function deviceDescriptionMutation(
  options: UseMutationOptions<
    unknown,
    Error,
    IDeviceDescriptionWrapper,
    unknown
  >,
) {
  return useMutation({
    mutationFn: async description => {
      const response = await client.post(
        '/api/teg/04/modal/description',
        description,
      );
      return response;
    },
    ...options,
  });
}

export default function useDeviceDescriptionMutation() {
  const queryClient = useQueryClient();

  return deviceDescriptionMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['module', 'device', 'description'],
      });
      toast.success(getAlertMessage('INFO_00010'));
    },
  });
}
