import { useQuery } from '@tanstack/react-query';
import { IQueryParams } from 'models';
import { client } from 'utils';

function singlePlotVopList({
  searchData,
  options,
}: IQueryParams<{ DVC_NO: string }>) {
  return useQuery({
    queryKey: ['plot', 'single', searchData],
    queryFn: async () => {
      const response = await client.get('/api/teg/08/plot/vop', {
        params: searchData,
      });
      return response.data.vopList;
    },
    ...options,
  });
}

export default function useSinglePlotVopList(searchData: {
  DVC_NO: string;
}) {
  return singlePlotVopList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
