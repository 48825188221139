import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchDocumentLayer } from 'models';
import { client } from 'utils';

function designRuleLayerList({
  searchData,
  options,
}: IQueryParams<ISearchDocumentLayer>) {
  return useQuery({
    queryKey: ['document', 'design_rule', 'layer', searchData],
    queryFn: async () => {
      const response = await client.get('/api/teg/09/doc/rule/layer', {
        params: searchData,
      });

      return response.data.layerList;
    },
    ...options,
  });
}

export default function useDesignRuleLayerList(
  searchData: ISearchDocumentLayer,
) {
  return designRuleLayerList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled:
        searchData?.CHR_SNO !== undefined &&
        searchData?.RUL_NO !== undefined,
    },
  });
}
