import { useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Table,
  StatusCell,
  StatusIcon,
  TextCell,
  SelectCell,
  NumberCell,
} from 'components';
import {
  TableContext,
  addRowData,
  removeRowData,
  exportJsonToSheet,
  saveTableData,
  duplicateData,
  convertValueToLabel,
  selectNextDataByRemove,
  getAlertMessage,
  convertStringToHtml,
} from 'utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useSearchComboList, useTableData } from 'hooks';
import {
  Columns,
  CommonArray,
  CommonObject,
  CurrentData,
  ITableContext,
  RowType,
} from 'models';
import { maskState } from 'store';
import useMaskModuleList from 'apis/queries/useMaskModuleList';
import useMaskModuleMutation from 'apis/mutations/useMaskModuleMutation';
import { IMaskModule } from 'models/interfaces/maskInterface';
import { toast } from 'react-toastify';

const newRow = {
  MSK_DVC_CD: '',
  MDL_NM: '',
  CRD_X: 0,
  CRD_Y: 0,
  VPAD_PTC_CD: '',
  LPAD_PTC_CD: '',
  ROWTYPE: RowType.ADD,
};

const columnHelper = createColumnHelper();

const TEG02ModuleTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchData = useRecoilValue(maskState.searchMaskModule);
  const { data: module } = useMaskModuleList(searchData) as CommonObject;
  const [backupData, setBackupData] = useState<CommonArray>([]);
  const [currentData, setCurrentData] = useRecoilState(
    maskState.currentMaskModuleData,
  );
  const verticalOptions = useSearchComboList({ CMB_CD: 'CMB0032' });
  const lateralOptions = useSearchComboList({ CMB_CD: 'CMB0033' });
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: module,
    atom: maskState.maskModuleList,
    effect: () => {
      setInitialData(module);
      setCurrentData({ index: 0, data: module[0] });
      setBackupData(module);
    },
  });
  const saveModuleList = useMaskModuleMutation();
  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 40,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('MSK_DVC_CD', {
      header: '디바이스',
      cell: TextCell,
      size: 150,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('MDL_NM', {
      header: '모듈',
      cell: TextCell,
      size: 80,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('CRD_X', {
      header: 'X좌표',
      cell: NumberCell,
      size: 80,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('CRD_Y', {
      header: 'Y좌표',
      cell: NumberCell,
      size: 80,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('VPAD_PTC_CD', {
      header: 'Vertical',
      cell: SelectCell,
      size: 100,
      meta: {
        renderKey: 'VPAD_PTC_CD',
        options: verticalOptions,
      },
    }),
    columnHelper.accessor('LPAD_PTC_CD', {
      header: 'Lateral',
      cell: SelectCell,
      size: 100,
      meta: {
        renderKey: 'LPAD_PTC_CD',
        options: lateralOptions,
      },
    }),
  ];
  const columns = useMemo(
    () => defaultColumns,
    [verticalOptions, lateralOptions],
  ) as Columns;

  const getNewModuleId = (moduleId: string) => {
    const newModuleId = (parseInt(moduleId.substring(1)) + 1)
      .toString()
      .padStart(2, '0');
    return newModuleId;
  };

  const handleAddData = () => {
    const lastData = initialData?.[initialData.length - 1];
    const newModuleId =
      initialData?.length === 0
        ? '01'
        : initialData?.[0].ROWTYPE === RowType.ADD
          ? getNewModuleId(initialData[0]?.MDL_NM)
          : getNewModuleId(lastData?.MDL_NM);
    const modifiedNewRow = {
      ...newRow,
      MSK_DVC_CD: lastData?.MSK_DVC_CD || '',
      MDL_NM: `M${newModuleId}` || '',
      VPAD_PTC_CD: verticalOptions?.[0]?.value || '',
      LPAD_PTC_CD: lateralOptions?.[0]?.value || '',
      CRD_X: lastData?.CRD_X || 0,
      CRD_Y: lastData?.CRD_Y || 0,
    };
    addRowData(
      setInitialData,
      setCurrentData,
      modifiedNewRow,
      initialData.length,
    );
  };

  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);

    if (removedData.length > 0) {
      setInitialData(removedData);
      selectNextDataByRemove(
        selectData,
        initialData.length - 1,
        removedData,
        setCurrentData,
      );
    }
  };

  const handleSaveData = () => {
    const checkKeys = ['MSK_DVC_CD', 'MDL_NM'];
    const targetData = saveTableData(initialData, checkKeys, false);
    const moduleNamePattern = /^M[0-9]{2}$/;
    const invalid = targetData.some(
      row => !moduleNamePattern.test(row.MDL_NM),
    );
    if (invalid) {
      toast.warning(convertStringToHtml(getAlertMessage('ERRO_00290')));
      return;
    }
    const newData = targetData.map(row => {
      const newRow = {
        ...row,
        MSK_CD: searchData.MSK_CD,
        CODE_SNO: searchData.CODE_SNO,
      };
      return newRow;
    });
    if (targetData.length > 0) {
      saveModuleList.mutate(newData as IMaskModule[]);
    } else {
      setInitialData(initialData);
    }
  };

  const handleDuplicateData = () => {
    duplicateData(setInitialData, currentData.data);
    setCurrentData({ index: initialData.length, data: currentData.data });
  };
  const getExcelRowData = () => {
    const newData = initialData.map(data => ({
      ...data,
      VPAD_PTC_CD: convertValueToLabel(
        verticalOptions,
        data,
        'VPAD_PTC_CD',
      ),
      LPAD_PTC_CD: convertValueToLabel(
        lateralOptions,
        data,
        'LPAD_PTC_CD',
      ),
    }));
    return newData;
  };
  const contextValue: ITableContext = {
    handleExport: () =>
      exportJsonToSheet(columns, getExcelRowData(), '모듈좌표'),
    handleDuplicateData,
    handleAddData,
    handleRemoveData,
    handleSaveData,
    currentData,
    setCurrentData,
    customDisabled: {
      fetched: searchData.ROWTYPE === RowType.ADD,
    },
  };

  return (
    <TableContext.Provider value={contextValue}>
      {initialData && (
        <Table
          title='모듈 좌표'
          initialData={initialData}
          backupData={backupData}
          setData={setInitialData}
          columns={columns}
          editable={true}
          tableHeight={tableHeight}
          draggable={true}
        />
      )}
    </TableContext.Provider>
  );
};

export default TEG02ModuleTable;
