import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IDocumentHistory } from 'models';
import { client } from 'utils';

function documentHistoryMutation(
  options: UseMutationOptions<unknown, Error, IDocumentHistory[], unknown>,
) {
  return useMutation({
    mutationFn: async (historyData: IDocumentHistory[]) => {
      const response = await client.post(
        '/api/teg/09/pdk/history',
        historyData,
      );
      return response;
    },
    ...options,
  });
}

export default function useDocumentHistoryMutation() {
  const queryClient = useQueryClient();

  return documentHistoryMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['doc', 'history'] });
    },
  });
}
