import { DataTableTopBox } from 'styles';
import ToggleSwitch from '../utils/ToggleSwitch';
import TableButtonBox from './TableButtonBox';
import InfoBox from '../utils/InfoBox';
import RefreshIcon from '../utils/RefreshIcon';
import { ITableTopProps } from 'models';

const TableTop = ({
  title,
  count,
  filterFlag,
  handleFilterFlag,
  refreshTable,
  editable,
  showToggle,
}: ITableTopProps): JSX.Element => {
  return (
    <DataTableTopBox>
      <InfoBox
        title={title}
        count={count}
      >
        {showToggle && (
          <ToggleSwitch
            title='search'
            flag={filterFlag}
            onChange={handleFilterFlag}
          />
        )}
        {editable && <RefreshIcon onMouseDown={refreshTable} />}
      </InfoBox>
      <TableButtonBox count={count} />
    </DataTableTopBox>
  );
};

export default TableTop;
