import { IDeviceDraw } from 'models/interfaces/deviceDrawInterface';
import { RowType } from 'models/types/commonTypes';

export const defaultDeviceDraw: IDeviceDraw = {
  DVC_NO: 0,
  DRW_SNO: 0,
  DRW_TYPE: '',
  DRW_MN: '',
  FILE_NM: '',
  ROWTYPE: RowType.ADD,
};
