import { useQuery } from '@tanstack/react-query';
import { IQueryParams } from 'models';
import { ISearchBbs } from 'models/interfaces/bbsInterface';
import { client } from 'utils';

function bbsList({ searchData, options }: IQueryParams<ISearchBbs>) {
  return useQuery({
    queryKey: ['bbs_id', 'bbs_list', searchData],
    queryFn: async () => {
      const response = await client.get('api/co/cm/800m01/bbs/list', {
        params: searchData,
      });
      return response.data.bbsList;
    },
    ...options,
  });
}

export default function useBbsList(searchData: ISearchBbs) {
  return bbsList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
