import { CommonArray, defaultSearchMessage } from 'models';
import { atom } from 'recoil';

const searchMessage = atom({
  key: 'searchMessage',
  default: defaultSearchMessage,
});

const messageList = atom<CommonArray>({
  key: 'messageList',
  default: [],
});

export const standardInfomationState = {
  searchMessage,
  messageList,
};
