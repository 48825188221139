import {
  IMask,
  IMaskCode,
  IMaskFile,
  IMaskModule,
  ISearchMask,
  ISearchSub,
} from 'models/interfaces/maskInterface';
import { RowType } from 'models/types/commonTypes';

export const defaultSearchMask: ISearchMask = {
  MASK_NM: '',
};

export const defaultMask: IMask = {
  MSK_NM: '',
  ROWTYPE: RowType.ADD,
};

export const defaultMaskCode: IMaskCode = {
  CODE_NM: '',
  ROWTYPE: RowType.ADD,
};

export const defaultMaskModule: IMaskModule = {
  MSK_DVC_CD: '',
  ROWTYPE: RowType.ADD,
  MDL_NM: '',
};

export const defaultMaskFile: IMaskFile = {
  MSK_NM: '',
};

export const defaultSearchSubsite: ISearchSub = {
  MSK_CD: 0,
  CODE_SNO: 0,
  MSK_DVC_CD: '',
};
