import { useRecoilState, useRecoilValue } from 'recoil';
import TEG08Scale from './TEG08Scale';
import { plotState } from 'store';
import { CustomChangeEvent, IPlotProps } from 'models';
import { PlotBox } from 'styles';

const TEG08TotalPlot = ({
  selectParams,
  isSub,
  recoilKey,
}: IPlotProps): JSX.Element => {
  const [totalPlotValue, setTrendPlotValue] = useRecoilState(
    plotState.plotValue('total'),
  );
  const searchPlot = useRecoilValue(plotState.searchPlot(recoilKey));
  const { DVC_NO, DSG_ID, MSR_TYPE, DUT_ID } = searchPlot;
  const { LOG_BTM, LOG_LEFT, LOG_TOP, LEGEND_FLAG } = totalPlotValue;
  const subPartParam = isSub
    ? `${DUT_ID},${LEGEND_FLAG ?? 0}`
    : LEGEND_FLAG ?? 0;
  const paramString = `${DVC_NO},${DSG_ID},${MSR_TYPE},${LOG_BTM ?? 0},${LOG_LEFT ?? 0},${LOG_TOP ?? 0},${subPartParam ?? 0}`;

  const handlePlotScale = (e: CustomChangeEvent) => {
    if ('checked' in e.target) {
      const name = e.target.name;
      const value = e.target.checked;

      setTrendPlotValue(old => ({ ...old, [name]: value ? '1' : '0' }));
    }
  };

  return (
    <PlotBox>
      <div className='plot-title'>Total Plot</div>
      <div className='plot-area'>
        <div className='plot-content'>
          <iframe
            className='w-full h-full'
            id='totalPlot'
            src={`/py/plot-total?${selectParams ? paramString : 'none'}`}
          />
        </div>
        <TEG08Scale
          type='total'
          isDC={MSR_TYPE === 'DC'}
          plotValue={totalPlotValue}
          setPlotScale={handlePlotScale}
        />
      </div>
    </PlotBox>
  );
};

export default TEG08TotalPlot;
