import axios from 'axios';
import eventBus from './eventBus';
// import { toast } from 'react-toastify';

export const client = axios.create({
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

client.interceptors.request.use(function (config) {
  const token = sessionStorage.getItem('accessToken');
  const recoilPersist = sessionStorage.getItem('recoil-persist');

  if (recoilPersist) {
    const recoil = JSON.parse(recoilPersist);
    const sideMenu = recoil.selectSideMenu;

    config.headers.Authorization = `Bearer ${token}`;
    if (sideMenu) {
      config.headers.MNU_ID = sideMenu.MNU_ID;
      config.headers.PGM_ID = sideMenu.PGM_ID;
    }
  }

  return config;
});

client.interceptors.response.use(
  function (response) {
    const newToken = response.headers['authorization'];
    if (newToken === undefined) return response;
    const expTime = Math.round(response.headers['exptime'] / 60);
    if (Number.isNaN(expTime)) return response;
    sessionStorage.setItem('accessToken', newToken);
    eventBus.emit('expTime', expTime);
    return response;
  },
  function (error) {
    // const message = error.response.data.message;
    const status = error.response.status;
    if (status === 401) {
      // toast.warning(message);
      sessionStorage.clear();
      window.location.href = '/#/login';
    }
    return Promise.reject(error);
  },
);
