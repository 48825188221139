import { useQuery } from '@tanstack/react-query';
import { UseQueryOptionsWithoutQueryKey } from 'models';
import { client } from 'utils';

function myMenu(options: UseQueryOptionsWithoutQueryKey) {
  return useQuery({
    queryKey: ['menu', 'myMenu'],
    queryFn: async () => {
      const response = await client.get('/api/co/cm/my-menu');
      return response.data.myMenu;
    },
    ...options,
  });
}

export default function useMyMenu() {
  return myMenu({
    retry: false,
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
}
