import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IDutMoveWrapper } from 'models';
import { toast } from 'react-toastify';
import { client, getAlertMessage } from 'utils';

function dutMoveMutation(
  options: UseMutationOptions<unknown, Error, IDutMoveWrapper, unknown>,
) {
  return useMutation({
    mutationFn: async dutMove => {
      const response = await client.post('/api/teg/05/move', dutMove);
      return response;
    },
    ...options,
  });
}

export default function useDutMoveMutation() {
  const queryClient = useQueryClient();

  return dutMoveMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['module', 'device', 'description'],
      });
      toast.success(getAlertMessage('INFO_00010'));
    },
  });
}
