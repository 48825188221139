import { CommonButton } from 'components';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { loginState } from 'store';
import { TimeBox } from 'styles';
import SideRenewBox from './SideRenewBox';
import { CustomMouseEvent } from 'models';
import { useRefresh } from 'apis';
import { toast } from 'react-toastify';
import eventBus from 'utils/eventBus';

const SideTime = (): JSX.Element => {
  const [tokenTime, setTokenTime] = useRecoilState(loginState.expTime);
  const [refresh, setRefresh] = useState(0);
  const [openRenew, setOpenRenew] = useState<boolean>(false);
  useRefresh(refresh);

  const handleRefresh = () => {
    setRefresh(prev => prev + 1);
  };

  const handleOpenRenew = (e: CustomMouseEvent) => {
    e.stopPropagation();
    setOpenRenew(!openRenew);
  };

  useEffect(() => {
    if (tokenTime === 5)
      toast.warning('5분 남았습니다. 토큰을 연장해주세요', {
        autoClose: 5000,
        closeOnClick: true,
      });
    else if (tokenTime === 0)
      toast.error('토큰이 만료되었습니다. 토큰을 갱신해주세요', {
        autoClose: false,
        closeOnClick: true,
      });
  }, [tokenTime]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTokenTime((prev: number) => (prev > 0 ? prev - 1 : prev));
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const handleMyEvent = (expTime: number) => {
      setTokenTime(expTime);
    };
    eventBus.on('expTime', handleMyEvent);
    return () => {
      eventBus.off('expTime', handleMyEvent);
    };
  }, []);

  return (
    <>
      <TimeBox>
        <span className='time-span'>{tokenTime}</span>
        <span className='form-span'>분 후 종료</span>
        <CommonButton
          title='연장'
          onClick={handleRefresh}
        />
        <CommonButton
          title='갱신'
          onClick={handleOpenRenew}
          color='green'
        />
      </TimeBox>
      <SideRenewBox
        openInfo={openRenew}
        setOpenInfo={setOpenRenew}
      />
    </>
  );
};

export default SideTime;
