import {
  CommonButton,
  FrameEditBox,
  FrameEditField,
  FrameEditInput,
  FrameEditSelect,
} from 'components';
import { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { editValues, saveFormData } from 'utils';
import { moduleState } from 'store';
import { CommonObject, CustomChangeEvent } from 'models';
import { EditFieldRow, FileDialogButton } from 'styles';
import { useCommonFileDownloadMutation, useImage } from 'apis';
import { toast } from 'react-toastify';

const TEG04DrawForm = ({ height }: { height: number }): JSX.Element => {
  const drawTypeOption = useRecoilValue(moduleState.drawTypeOption);
  const drawTypeCodesMap: { [key: string]: string } = {};
  drawTypeOption?.forEach((code: any) => {
    drawTypeCodesMap[code.value] = code.label;
  });
  const currentData = useRecoilValue(moduleState.currentDeviceDraw);
  const searchModule = useRecoilValue(moduleState.searchModule('teg04'));
  const [currentDrawFile, setCurrentDrawFile] = useRecoilState(
    moduleState.deviceDrawFile,
  );
  const setDeviceDrawList = useSetRecoilState(moduleState.deviceDrawList);
  const [values, setValues] = useState(currentData.data);
  const currentIndex = currentData.index;
  const fileInputRef = useRef<HTMLInputElement>(null);

  const editValue = (e: CustomChangeEvent) => editValues(e, setValues);
  const handleSaveForm = () => {
    saveFormData({
      formData: {
        ...values,
        DRW_TYPE_NM: drawTypeCodesMap[values.DRW_TYPE],
      },
      dataIndex: currentIndex,
      setTableData: setDeviceDrawList,
      compareKey: 'DRW_SNO',
    });
  };

  const downloadFile = useCommonFileDownloadMutation();
  const handleDownLoad = () => {
    if (imageUrl === '' || imageUrl === null || imageUrl === undefined) {
      toast.warning('해당 파일이 존재하지 않습니다.');
      return;
    }
    downloadFile.mutate({
      URL: `PRJ/${searchModule?.PRJ_NO.padStart(9, '0')}/${searchModule?.DVC_NO.padStart(9, '0')}/${currentData.data?.FILE_NM}`,
    });
  };

  const handleUpload = () => {
    fileInputRef?.current?.click();
  };

  useEffect(() => {
    if (currentData) {
      setCurrentDrawFile(null);
      setValues(currentData.data);
    }
  }, [currentData]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files !== null) {
      const file = e.currentTarget.files[0];
      setCurrentDrawFile(file);
    }
  };

  const { data: imageUrl } = useImage(
    `PRJ/${searchModule?.PRJ_NO.padStart(9, '0')}/${searchModule?.DVC_NO.padStart(9, '0')}/${currentData.data?.FILE_NM}`,
  ) as CommonObject;

  return (
    <>
      <FrameEditBox
        title='상세 정보'
        height={height}
      >
        <EditFieldRow className='border-t'>
          <FrameEditField title='순번'>
            <FrameEditInput
              type='text'
              name='DRW_SNO'
              value={values?.DRW_SNO || ''}
              onChange={editValue}
              readOnly={true}
            />
          </FrameEditField>
          <FrameEditField title='도면종류'>
            <FrameEditSelect
              name='DRW_TYPE'
              value={values?.DRW_TYPE || ''}
              onChange={editValue}
              options={drawTypeOption}
            />
          </FrameEditField>
        </EditFieldRow>
        <EditFieldRow>
          <FrameEditField title='도면명'>
            <FrameEditInput
              type='text'
              name='DRW_MN'
              value={values?.DRW_MN || ''}
              onChange={editValue}
            />
          </FrameEditField>
          <FrameEditField title='파일명'>
            <FrameEditInput
              type='text'
              name='FILE_NM'
              value={values?.FILE_NM || ''}
              onChange={editValue}
              readOnly={true}
            />
          </FrameEditField>
        </EditFieldRow>
        <EditFieldRow>
          <FrameEditField title='업로드 파일'>
            <input
              type='file'
              ref={fileInputRef}
              onChange={handleFileChange}
              className='hidden'
            />
            <FrameEditInput
              type='text'
              name='currentFile'
              value={currentDrawFile?.name || ''}
              onChange={editValue}
              readOnly={true}
            />
            <FileDialogButton
              className='filedialog'
              onClick={handleUpload}
            >
              …
            </FileDialogButton>
          </FrameEditField>
        </EditFieldRow>
        <div className='mt-2 border justify-center w-[600px] h-[275px] overflow-hidden'>
          <img
            src={imageUrl}
            className='object-contain w-[595px] h-[270px]'
          />
        </div>
        <div className='mt-2 flex justify-end w-[600px] h-[25px] overflow-hidden'>
          <CommonButton
            title='다운로드'
            onClick={handleDownLoad}
          />
          <CommonButton
            title='작성'
            onClick={handleSaveForm}
          />
        </div>
      </FrameEditBox>
    </>
  );
};

export default TEG04DrawForm;
