import { isEqual } from 'utils';
import { memo, useEffect } from 'react';
import { IColumnMeta, ITableCellProps, ITableMeta, RowType } from 'models';
import { DataTableValueBox } from 'styles';

const ReadOnlyTextCell = ({
  getValue,
  table,
  row,
  column,
  rowRef,
}: ITableCellProps): JSX.Element => {
  const value = getValue();
  const columnMeta: IColumnMeta | undefined = column.columnDef.meta;
  const justify = columnMeta?.justify;
  const tableMeta: ITableMeta | undefined = table.options.meta;

  useEffect(() => {
    if (rowRef) {
      if (row.original.ROWTYPE !== RowType.NORMAL) {
        tableMeta?.editValue?.(row.index, column.id, value, rowRef);
      }
    }
  }, [value]);

  return <DataTableValueBox $justify={justify}>{value}</DataTableValueBox>;
};

export default memo(ReadOnlyTextCell, isEqual);
