import { CommonArray, defaultCurrentData } from 'models';
import {
  defaultSearchMainProcess,
  defaultSearchSubProcess,
} from 'models/defaults/processDefaults';
import { atom, atomFamily } from 'recoil';

const searchMainProcess = atomFamily({
  key: 'searchProcess',
  default: defaultSearchMainProcess,
});

const searchSubProcess = atom({
  key: 'searchSubProcess',
  default: defaultSearchSubProcess,
});

const currentMainProcess = atom({
  key: 'currentMainProcess',
  default: defaultCurrentData,
});

const currentSubProcess = atom({
  key: 'currentSubProcess',
  default: defaultCurrentData,
});

const mainProcessList = atom<CommonArray>({
  key: 'mainProcessList',
  default: [],
});

const subProcessList = atom<CommonArray>({
  key: 'subProcessList',
  default: [],
});

export const processState = {
  searchMainProcess,
  searchSubProcess,
  currentMainProcess,
  currentSubProcess,
  mainProcessList,
  subProcessList,
};
