import {
  CommonArray,
  defaultCurrentData,
  defaultDesignDutFileData,
  defaultSearchDesignDescription,
} from 'models';
import { atom } from 'recoil';

const searchDesignDescription = atom({
  key: 'searchDesignDescription',
  default: defaultSearchDesignDescription,
});

const currentDesignDescriptionData = atom({
  key: 'currentDesignDescriptionData',
  default: defaultCurrentData,
});

const currentPlotInfoData = atom({
  key: 'currentPlotInfoData',
  default: defaultCurrentData,
});

const currentDesignDutData = atom({
  key: 'currentDesignDutData',
  default: defaultCurrentData,
});

const currentDesignDutFileData = atom({
  key: 'currentDesignDutFileData',
  default: defaultDesignDutFileData,
});

const designDescriptionList = atom<CommonArray>({
  key: 'designDescriptionList',
  default: [],
});

const designDutList = atom<CommonArray>({
  key: 'designDutList',
  default: [],
});

const plotInfo = atom<CommonArray>({
  key: 'designPlotInfo',
  default: [],
});

const plotFlag = atom<CommonArray>({
  key: 'designPlotFlag',
  default: [],
});

export const designState = {
  searchDesignDescription,
  currentDesignDescriptionData,
  currentPlotInfoData,
  currentDesignDutData,
  currentDesignDutFileData,
  designDescriptionList,
  designDutList,
  plotInfo,
  plotFlag,
};
