import { createColumnHelper } from '@tanstack/react-table';
import useCommonFileDownloadMutation from 'apis/mutations/useCommonFileDownloadMutation';
import useDesignDescriptionMutation from 'apis/mutations/useDesignDescriptionMutation';
import useDesignDescriptionList from 'apis/queries/useDesignDescriptionList';
import useDesignTeg from 'apis/queries/useDesignTeg';
import {
  CheckInputCell,
  ReadOnlyTextCell,
  SelectCell,
  StatusCell,
  StatusIcon,
  Table,
  TextCell,
} from 'components';
import { useTableData } from 'hooks';
import {
  Columns,
  CommonArray,
  CommonObject,
  CurrentData,
  ITableContext,
  defaultDesignDescription,
} from 'models';
import { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { designState } from 'store';
import {
  TableContext,
  addRowData,
  addRowNumber,
  getAlertMessage,
  removeRowData,
  selectNextDataByRemove,
} from 'utils';

const columnHelper = createColumnHelper();
const newRow = defaultDesignDescription;

const TEG06DesignDescriptionTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchData = useRecoilValue(designState.searchDesignDescription);
  const [currentData, setCurrentData] = useRecoilState(
    designState.currentDesignDescriptionData,
  );
  const [backupData, setBackupData] = useState<CommonArray>([]);

  const designDescriptionResponse = useDesignDescriptionList(
    searchData,
  ) as CommonObject;

  const designDescription =
    designDescriptionResponse?.data?.designDescriptionList;

  const deviceDescriptionList =
    designDescriptionResponse?.data?.deviceDescriptionList;

  const deviceDescriptionOptions = deviceDescriptionList?.map(
    (data: { DSC_NM: string; DSC_ID: number }) => ({
      label: data.DSC_NM,
      value: data.DSC_ID,
    }),
  );
  const designDescriptionRef = useRef(designDescription);

  const setDutList = useSetRecoilState(designState.designDutList);
  const [savePlotInfo, setPlotInfo] = useRecoilState(designState.plotInfo);
  useEffect(() => {
    const plotInfo = designDescriptionResponse?.data?.plotInfo;
    if (designDescriptionResponse?.data?.plotInfo) {
      setPlotInfo(plotInfo);
    }
    if (designDescriptionResponse?.data?.dutList) {
      setDutList(addRowNumber(designDescriptionResponse?.data?.dutList));
    }
  }, [designDescriptionResponse.data]);

  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: designDescription,
    atom: designState.designDescriptionList,
    effect: () => {
      const newData = addRowNumber(designDescription);
      setInitialData(newData);
      setBackupData(newData);
      setCurrentData({ index: 0, data: newData[0] });
    },
  });
  useEffect(() => {
    if (initialData) {
      let keyDscCnt = 0;
      let num = -1;
      initialData.map((data, index) => {
        if (data.KEY_DSC_FLAG === 'Y') {
          keyDscCnt += 1;
        }
        if (
          designDescriptionRef?.current[index]?.KEY_DSC_FLAG !==
          data.KEY_DSC_FLAG
        ) {
          num = index;
        }
      });
      designDescriptionRef.current = initialData;
      if (keyDscCnt > 1) {
        setInitialData(
          designDescriptionRef.current.map(
            (item: CommonObject, index: number) => {
              if (index !== num) {
                return {
                  ...item,
                  KEY_DSC_FLAG: 'N',
                };
              }
              return item;
            },
          ),
        );
      }
    }
  }, [initialData]);
  const saveDesignDescriptionList = useDesignDescriptionMutation();
  const [searchDesignAnalysis, setSearchDesignAnalysis] = useState({
    DVC_NO: '',
    PRJ_NO: '',
    DSG_ID: '',
    PRC_NO: '',
  });
  const downloadFile = useCommonFileDownloadMutation();
  const analysisResponse = useDesignTeg(
    searchDesignAnalysis,
  ) as CommonObject;
  useEffect(() => {
    if (analysisResponse?.data?.status === 200) {
      const prjNo = searchDesignAnalysis.PRJ_NO.toString().padStart(
        9,
        '0',
      );
      const dvcNo = searchDesignAnalysis.DVC_NO.toString().padStart(
        9,
        '0',
      );
      const dsgId = searchDesignAnalysis.DSG_ID.toString().padStart(
        2,
        '0',
      );
      toast.success(getAlertMessage('INFO_04000', ['Analysis']));
      const fileUrl = `PRJ/${prjNo}/${dvcNo}/G${dsgId}_AnalysisReport.pptx`;
      downloadFile.mutate({ URL: fileUrl });
      setSearchDesignAnalysis({
        PRJ_NO: '',
        DVC_NO: '',
        DSG_ID: '',
        PRC_NO: '',
      });
    }
  }, [analysisResponse]);
  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 20,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('no', {
      header: 'NO',
      cell: ReadOnlyTextCell,
      size: 20,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('KEY_DSC_FLAG', {
      header: '대표',
      cell: CheckInputCell,
      size: 40,
      filterFn: 'includesString',
      meta: {
        onChange: '',
      },
    }),
    columnHelper.accessor('DSC_ID', {
      header: '속성',
      cell: SelectCell,
      size: 180,
      meta: {
        key: 'DSC_ID',
        options: deviceDescriptionOptions,
      },
    }),
    columnHelper.accessor('DSC_ALIAS', {
      header: '별명',
      cell: TextCell,
      size: 180,
    }),
  ];
  const columns = useMemo(
    () => defaultColumns,
    [deviceDescriptionOptions],
  ) as Columns;

  const handleAddData = () => {
    if (initialData.length >= 4) {
      toast.warning(getAlertMessage('ERRO_00210', ['디자인 속성', '4개']));
      return;
    }
    const newDscSno =
      initialData.reduce((max, item) => {
        return item.DSC_SNO > max ? item.DSC_SNO : max;
      }, 0) + 1;
    const copyRow = { ...newRow };
    copyRow.DSG_ID = Number(searchData.DSG_ID);
    copyRow.DVC_NO = Number(searchData.DVC_NO);
    copyRow.DSC_SNO = newDscSno;
    copyRow.DSC_ID = deviceDescriptionOptions?.[0]?.value ?? 0;
    if (initialData.length < 1) {
      copyRow.KEY_DSC_FLAG = 'Y';
    }
    addRowData(
      setInitialData,
      setCurrentData,
      copyRow,
      initialData.length,
    );
  };

  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);

    if (removedData.length > 0) {
      setInitialData(removedData);
      selectNextDataByRemove(
        selectData,
        initialData.length - 1,
        removedData,
        setCurrentData,
      );
    }
  };

  const handleSaveData = () => {
    let keyDscCnt = 0;
    const invalidData = initialData.find(data => data.DSC_ID === 0);
    if (invalidData) {
      toast.warning('속성을 선택해주세요.');
      return;
    }
    initialData.map(data => {
      if (data.KEY_DSC_FLAG === 'Y') {
        keyDscCnt += 1;
      }
    });
    if (keyDscCnt === 0) {
      toast.warning(getAlertMessage('ERRO_00240', ['대표 속성']));
      return;
    }
    const saveData = {
      PRJ_NO: searchData.PRJ_NO,
      designDescriptionList: initialData,
      plotFlag: savePlotInfo[0],
    };
    if (initialData.length > 0) {
      saveDesignDescriptionList.mutate(saveData);
    } else {
      setInitialData(initialData);
    }
  };

  const handleAnalysisDoc = () => {
    setSearchDesignAnalysis({
      PRJ_NO: searchData.PRJ_NO,
      DVC_NO: searchData.DVC_NO,
      DSG_ID: searchData.DSG_ID,
      PRC_NO: '',
    });
  };
  const contextValue: ITableContext = {
    handleAddData,
    handleRemoveData,
    handleSaveData,
    handleAnalysisDoc,
    currentData,
    setCurrentData,
    customDisabled: {
      fetched:
        searchData.PRJ_NO === '' ||
        searchData.PRC_NO === '' ||
        searchData.DVC_NO === '' ||
        searchData.DSG_ID === '',
    },
  };
  return (
    <TableContext.Provider value={contextValue}>
      {initialData && (
        <Table
          title='디자인 속성'
          initialData={initialData}
          backupData={backupData}
          setData={setInitialData}
          columns={columns}
          editable={true}
          tableHeight={tableHeight}
        />
      )}
    </TableContext.Provider>
  );
};

export default TEG06DesignDescriptionTable;
