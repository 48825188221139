import { CommonButton, FrameInfo, FrameInfoInput } from 'components';
import { CustomChangeEvent } from 'models';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { authorityManagementState } from 'store';
import { editValues } from 'utils';

const COCM160M01Info = (): JSX.Element => {
  const [searchAuthority, setSearchAuthority] = useRecoilState(
    authorityManagementState.searchAuthority('COCM160M01'),
  );
  const [values, setValues] = useState(searchAuthority);
  const editValue = (e: CustomChangeEvent) => editValues(e, setValues);

  const handleSearch = () => {
    setSearchAuthority(values);
  };

  return (
    <FrameInfo>
      <div className='search-form'>
        <div className='input-block'>
          <FrameInfoInput
            title='권한ID'
            type='text'
            onChange={editValue}
            name='ATH_ID'
          />
          <FrameInfoInput
            title='권한명'
            type='text'
            onChange={editValue}
            name='ATH_NM'
          />
        </div>
        <div>
          <CommonButton
            title='조회'
            onClick={handleSearch}
          />
        </div>
      </div>
    </FrameInfo>
  );
};

export default COCM160M01Info;
