import { useQuery } from '@tanstack/react-query';
import { IQueryParams } from 'models';
import { ISearchDeviceFile } from 'models/interfaces/deviceInterface';
import { client } from 'utils';

function deviceAnalysis({
  searchData,
  options,
}: IQueryParams<ISearchDeviceFile>) {
  return useQuery({
    queryKey: ['device', 'analysis', searchData],
    queryFn: async () => {
      const response = await client.get('/py/all_ppt_analysis', {
        params: searchData,
      });
      const { data, status } = response;
      return {
        data,
        status,
      };
    },
    ...options,
  });
}

export default function useDeviceAnalysis(searchData: ISearchDeviceFile) {
  return deviceAnalysis({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled:
        searchData.PRJ_NO !== 0 &&
        searchData.PRC_NO !== 0 &&
        searchData.PRS_CD2 !== '' &&
        searchData.PRJ_NM !== '',
    },
  });
}
