import { useHeight, useSearchComboList } from 'hooks';
import { useState } from 'react';
import {
  CommonButton,
  FrameInfo,
  FrameInfoInput,
  FrameInfoSelect,
} from 'components';
import COCM160M02AuthorityTable from './COCM160M02AuthorityTable';
import COCM160M02MenuTable from './COCM160M02MenuTable';
import { useRecoilState } from 'recoil';
import { editValues } from 'utils';
import { FrameContent } from 'styles';
import { authorityManagementState } from 'store';
import { CommonArray, CustomChangeEvent } from 'models';

const COCM160M02 = (): JSX.Element => {
  const getHeight = useHeight();
  const [searchAuthority, setSearchAuthority] = useRecoilState(
    authorityManagementState.searchAuthority('COCM160M02'),
  );
  const [searchAuthorityMenu, setSearchAuthorityMenu] = useRecoilState(
    authorityManagementState.searchAuthorityMenu,
  );
  const [values, setValues] = useState({
    ...searchAuthority,
    ...searchAuthorityMenu,
  });
  const editValue = (e: CustomChangeEvent) => editValues(e, setValues);

  const modifiedSystemOptionData = (systemOptionData: CommonArray) => {
    return systemOptionData?.map(data => ({
      ...data,
      CMN_CD: data.USR_FLD_1_CONT,
    }));
  };
  const systemOptions = useSearchComboList(
    { CMB_CD: 'CMB0015' },
    false,
    modifiedSystemOptionData,
  );
  const combinedOptions = [{ label: '전체', value: '' }, ...systemOptions];

  const handleSearchAuthority = () => {
    setSearchAuthority(old => ({
      ...old,
      ATH_NM: values.ATH_NM,
    }));
    setSearchAuthorityMenu(old => ({
      ...old,
      SYS_DTT_MNU: values.SYS_DTT_MNU,
    }));
  };

  return (
    <>
      <FrameInfo>
        <div className='search-form'>
          <div className='input-block'>
            <FrameInfoInput
              title='권한명'
              type='text'
              name='ATH_NM'
              value={values.ATH_NM || ''}
              onChange={editValue}
            />
            <FrameInfoSelect
              title='시스템구분'
              options={combinedOptions}
              name='SYS_DTT_MNU'
              onChange={editValue}
            />
          </div>
        </div>
        <div>
          <CommonButton
            title='조회'
            onClick={handleSearchAuthority}
          />
        </div>
      </FrameInfo>
      <FrameContent>
        <div className='w-[450px]'>
          <COCM160M02AuthorityTable tableHeight={getHeight(600)} />
        </div>
        <div className='grow-col-between'>
          <COCM160M02MenuTable tableHeight={getHeight(600)} />
        </div>
      </FrameContent>
    </>
  );
};

export default COCM160M02;
