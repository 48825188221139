import { useHeight } from 'hooks';
import TEG08SinglePlot from './TEG08SinglePlot';
import TEG08SingleTable from './TEG08SingleTable';
import { useLocation } from 'react-router-dom';
import { IPlotParam } from 'models';

const TEG08SubPlot = (): JSX.Element => {
  const getHeight = useHeight();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryObject = Object.fromEntries(
    queryParams.entries(),
  ) as IPlotParam;
  return (
    <div className='w-screen h-screen flex justify-between'>
      <div className='flex-grow'>
        <TEG08SinglePlot queryParam={queryObject} />
      </div>
      <div className='w-[450px] py-4 pr-3'>
        <TEG08SingleTable
          tableHeight={getHeight(655)}
          queryParam={queryObject}
        />
      </div>
    </div>
  );
};

export default TEG08SubPlot;
