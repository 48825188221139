import { CommonButton, FrameInfo } from 'components';
import FrameInfoInput from 'components/frame/FrameInfoInput';
import FrameInfoSelect from 'components/frame/FrameInfoSelect';
import { useHeight, useSearchComboList } from 'hooks';
import { CustomChangeEvent } from 'models';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { codeManagementState } from 'store';
import { FrameContent } from 'styles';
import { editValues } from 'utils';
import COCM110M01Table from './COCM110M01Table';
import COCM110M01Form from './COCM110M01Form';

const COCM110M01 = (): JSX.Element => {
  const getHeight = useHeight();
  const systemOptions = useSearchComboList({ CMB_CD: 'CMB0015' }, true);
  const [searchCodeType, setSearchCodeType] = useRecoilState(
    codeManagementState.searchCodeType('COCM110M01'),
  );
  const [values, setValues] = useState(searchCodeType);
  const editValue = (e: CustomChangeEvent) => editValues(e, setValues);

  const handleSearch = () => {
    setSearchCodeType(values);
  };
  useEffect(() => {
    setSearchCodeType(values);
  }, [values]);

  return (
    <>
      <FrameInfo>
        <div className='search-form'>
          <div className='input-block'>
            <FrameInfoSelect
              title='시스템 구분'
              name='SYS_DTT_CD'
              options={systemOptions}
              onChange={editValue}
            />
            <FrameInfoInput
              title='코드유형'
              type='text'
              name='TP_CD'
              value={values?.TP_CD}
              onChange={editValue}
            />
            <FrameInfoInput
              title='코드유형명'
              type='text'
              name='TP_CD_NM'
              value={values?.TP_CD_NM}
              onChange={editValue}
            />
          </div>
          <div>
            <CommonButton
              title='조회'
              onClick={handleSearch}
            />
          </div>
        </div>
      </FrameInfo>
      <FrameContent>
        <div className='w-1/2'>
          <COCM110M01Table tableHeight={getHeight(670)} />
        </div>
        <div className='grow-row-between'>
          <COCM110M01Form height={getHeight(670)} />
        </div>
      </FrameContent>
    </>
  );
};

export default COCM110M01;
