import { ISideStyle, ITopStyle } from 'models';
import { atom, selector } from 'recoil';
import { TARGET_HEIGHT, TARGET_WIDTH } from 'utils';

const sideOpen = atom({
  key: 'sideOpen',
  default: true,
});

const topOpen = atom({
  key: 'topOpen',
  default: true,
});

const sideOpenStyle = selector({
  key: 'sideOpenStyle',
  get: ({ get }): [ISideStyle, boolean] => {
    const sideOpenState = get(sideOpen);
    const sideStyle = {
      width: sideOpenState ? TARGET_WIDTH : 0,
      left: sideOpenState ? 0 : -TARGET_WIDTH,
    };

    return [sideStyle, sideOpenState];
  },
});

const topOpenStyle = selector({
  key: 'topOpenStyle',
  get: ({ get }): [ITopStyle, boolean] => {
    const topOpenState = get(topOpen);
    const topStyle = {
      height: topOpenState ? TARGET_HEIGHT : 0,
      top: topOpenState ? 0 : -TARGET_HEIGHT,
    };
    return [topStyle, topOpenState];
  },
});

export const layoutState = {
  sideOpen,
  topOpen,
  sideOpenStyle,
  topOpenStyle,
};
