import {
  CommonButton,
  FileUpload,
  FileUploadModal,
  FrameInfo,
  FrameInfoInput,
  FrameInfoSelect,
} from 'components';
import { useHeight } from 'hooks';
import { useEffect, useState } from 'react';
import {
  FrameContent,
  ModalInner,
  ModalOverlay,
  ModalWrapper,
} from 'styles';
import TEG02MaskTable from './TEG02MaskTable';
import TEG02CodeTable from './TEG02CodeTable';
import TEG02ModuleTable from './TEG02ModuleTable';
import TEG02SubTable from './TEG02SubTable';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { maskState } from 'store';
import { CustomChangeEvent } from 'models';
import { editValues } from 'utils';
import TEG02FileUploadBox from 'features/frame/TEG02/TEG02FileUploadBox';
import {
  IMaskCode,
  IMaskFileWrapper,
  IMaskSubsiteFileWrapper,
} from 'models/interfaces/maskInterface';
import useMaskSubsiteDeleteMutation from 'apis/mutations/useMaskSubsiteDeleteMutation';
import useMaskSubsiteUploadMutation from 'apis/mutations/useMaskSubsiteUploadMutation';
import useMaskFileMutation from 'apis/mutations/useMaskFileMutation';
import { useCommonFileDownloadMutation } from 'apis';
import { defaultSearchMask } from 'models/defaults/maskDefaults';

const TEG02 = (): JSX.Element => {
  const getHeight = useHeight();
  const [searchData, setSearchData] = useRecoilState(maskState.searchMask);
  useEffect(() => {
    setSearchData(defaultSearchMask);
  }, []);
  const setSearchSubsiteData = useSetRecoilState(
    maskState.searchSubsiteData,
  );
  const maskDeviceOptions = useRecoilValue(maskState.maskDeviceOptions);
  const [values, setValues] = useState(searchData);
  const [modalOpen, setModalOpen] = useState(false);
  const [codeImageModalOpen, setCodeImageModalOpen] = useState(false);
  const [codeSubModalOpen, setCodeSubModalOpen] = useState(false);
  const editValue = (e: CustomChangeEvent, codeType?: string) => {
    editValues(e, setValues);
    if (codeType) {
      setValues(old => ({
        ...old,
        [codeType]: e.target.value,
      }));
      setSearchSubsiteData({
        MSK_CD: currentCodeData?.data?.MSK_CD,
        CODE_SNO: currentCodeData?.data?.CODE_SNO || 0,
        MSK_DVC_CD: e.target.value,
      });
    }
  };
  const currentMaskData = useRecoilValue(maskState.currentMaskData);
  const currentCodeData = useRecoilValue(maskState.currentCodeData);
  const [shotUrl, setShotUrl] = useState('');
  const [dieUrl, setDieUrl] = useState('');
  const [layoutUrl, setLayoutUrl] = useState('');
  const [mdlUrl, setMdlUrl] = useState('');
  const [padUrl, setPadUrl] = useState('');
  const [gdsUrl, setGDSUrl] = useState('');

  useEffect(() => {
    if (currentMaskData.data) {
      const formattedMaskNum = String(
        currentMaskData.data.MSK_CD,
      ).padStart(9, '0');
      if (currentMaskData.data.SHT_MAP_FILE != null) {
        const newShotUrl =
          'MSK/' +
          formattedMaskNum +
          '/' +
          currentMaskData.data.SHT_MAP_FILE;
        setShotUrl(() => newShotUrl);
      } else {
        setShotUrl('');
      }
      if (currentMaskData.data.DIE_MAP_FILE != null) {
        setDieUrl(
          () =>
            'MSK/' +
            formattedMaskNum +
            '/' +
            currentMaskData.data.DIE_MAP_FILE,
        );
      } else {
        setDieUrl('');
      }
    }
  }, [currentMaskData]);
  useEffect(() => {
    if (currentCodeData.data) {
      const formattedMaskNum = String(
        currentMaskData.data.MSK_CD,
      ).padStart(9, '0');

      if (currentCodeData.data.CODE_LAYOUT_FILE != null) {
        setLayoutUrl(
          () =>
            'MSK/' +
            formattedMaskNum +
            '/' +
            currentCodeData.data.CODE_LAYOUT_FILE,
        );
      } else {
        setLayoutUrl('');
      }
      if (currentCodeData.data.MDL_ARRAY_FILE != null) {
        setMdlUrl(
          () =>
            'MSK/' +
            formattedMaskNum +
            '/' +
            currentCodeData.data.MDL_ARRAY_FILE,
        );
      } else {
        setMdlUrl('');
      }
      if (currentCodeData.data.PAD_ASSIGN_FILE != null) {
        setPadUrl(
          () =>
            'MSK/' +
            formattedMaskNum +
            '/' +
            currentCodeData.data.PAD_ASSIGN_FILE,
        );
      } else {
        setPadUrl('');
      }
      if (currentCodeData.data.GDS_FILE != null) {
        setGDSUrl(
          () =>
            'MSK/' +
            formattedMaskNum +
            '/' +
            currentCodeData.data.GDS_FILE,
        );
      } else {
        setGDSUrl('');
      }
    }
  }, [currentCodeData]);
  const handleSearch = () => {
    setSearchData(values);
  };

  const [currentFileData, setCurrentFileData] = useRecoilState(
    maskState.currentMaskFileData('mask'),
  );
  const saveSubsite = useMaskSubsiteUploadMutation();

  const subUploadButtonClick = () => {
    const targetData = maskState.currentMaskFileData;
    const file = currentFileData.FILE;
    const maskFileWrapper = {
      file: file,
      search: currentFileData,
    };
    if (file && targetData.length > 0) {
      saveSubsite.mutate(maskFileWrapper as IMaskSubsiteFileWrapper);
    }
  };
  const deleteSubsiteMutation = useMaskSubsiteDeleteMutation();
  const resetButtonClick = () => {
    if (!confirm('초기화 하시겠습니까?')) {
      return;
    }
    deleteSubsiteMutation.mutate({
      MSK_CD: currentCodeData?.data?.MSK_CD,
      CODE_SNO: currentCodeData?.data?.CODE_SNO || 0,
    } as IMaskCode);
  };

  const [uploadFile, setUploadFile] = useState<File>();
  const fileChange = (file: File) => {
    setUploadFile(file);
    const fileType = (filename: string) => {
      const lastIndex = filename.lastIndexOf('.');
      if (lastIndex === -1 || lastIndex === filename.length - 1) {
        return '';
      }
      return filename.substring(lastIndex + 1);
    };
    setCurrentFileData(prev => ({
      ...prev,
      FILE_TYPE: fileType(file.name),
      MSK_CD: currentMaskData.data.MSK_CD,
      MSK_NM: currentMaskData.data.MSK_NM,
      CODE_SNO: currentCodeData?.data?.CODE_SNO || '',
      CODE_NM: currentCodeData?.data?.CODE_NM || '',
    }));
  };
  const saveFile = useMaskFileMutation();

  useEffect(() => {
    if (currentFileData?.TYPE === 'SHOT') {
      const formattedMaskNum = String(
        currentMaskData.data.MSK_CD,
      ).padStart(9, '0');
      if (currentMaskData.data.SHT_MAP_FILE != null) {
        const newShotUrl =
          'MSK/' +
          formattedMaskNum +
          '/' +
          currentMaskData.data.SHT_MAP_FILE;
        setShotUrl(() => newShotUrl);
      }
    }
  }, [currentFileData, saveFile]);

  const uploadButtonClick = (type: string) => {
    const newData = { ...currentFileData, TYPE: type };
    setCurrentFileData(newData);
    const maskFileWrapper = {
      file: uploadFile,
      maskFileInfo: newData,
    };
    if (uploadFile && currentFileData.MSK_CD) {
      saveFile.mutate(maskFileWrapper as IMaskFileWrapper);
    }
  };
  const downloadFile = useCommonFileDownloadMutation();
  const downloadButtonClick = (src: string) => {
    if (src === '' || src === null || src === undefined) {
      return;
    }
    downloadFile.mutate({ URL: src });
  };
  return (
    <>
      <FrameInfo>
        <div className='search-form'>
          <div className='input-block'>
            <FrameInfoInput
              title='마스크 코드'
              type='text'
              name='MASK_CD'
              onChange={e => editValue(e, 'MASK_NM')}
            >
              {/* <ModalButton openModal={setPrcModalOpen} /> */}
            </FrameInfoInput>
          </div>
        </div>
        <div>
          <CommonButton
            title='조회'
            onClick={handleSearch}
          />
        </div>
      </FrameInfo>
      <FrameContent>
        <div className='w-[500px]'>
          <TEG02MaskTable
            tableHeight={getHeight(700)}
            openModal={setModalOpen}
          ></TEG02MaskTable>
        </div>
        <div className='w-[600px]'>
          <TEG02CodeTable
            tableHeight={getHeight(700)}
            openModal={setCodeImageModalOpen}
            openSubModal={setCodeSubModalOpen}
          ></TEG02CodeTable>
        </div>
        <div>
          <TEG02ModuleTable
            tableHeight={getHeight(700)}
          ></TEG02ModuleTable>
        </div>
        <div className='grow-col-between'></div>
        {modalOpen ? (
          <FileUploadModal onClose={() => setModalOpen(false)}>
            <div className='flex gap-x-5'>
              <FileUpload
                title={'Shot Map'}
                name={'SHOT'}
                handleUpload={() => uploadButtonClick('SHOT')}
                handleDownload={() => downloadButtonClick(shotUrl)}
                handleChange={fileChange}
                imageUrl={shotUrl}
                showImage={true}
              />
              <FileUpload
                title={'Die Map'}
                name={'DIE'}
                handleUpload={() => uploadButtonClick('DIE')}
                handleDownload={() => downloadButtonClick(dieUrl)}
                handleChange={fileChange}
                imageUrl={dieUrl}
                showImage={true}
              />
            </div>
          </FileUploadModal>
        ) : null}
        {codeImageModalOpen ? (
          <FileUploadModal onClose={() => setCodeImageModalOpen(false)}>
            <div className='flex gap-x-5'>
              <FileUpload
                title={'Code Layout'}
                name={'LAYOUT'}
                handleUpload={() => uploadButtonClick('LAYOUT')}
                handleDownload={() => downloadButtonClick(layoutUrl)}
                handleChange={fileChange}
                imageUrl={layoutUrl}
                showImage={true}
              />
              <FileUpload
                title={'Module Array'}
                name={'MODULE'}
                handleUpload={() => uploadButtonClick('MODULE')}
                handleDownload={() => downloadButtonClick(mdlUrl)}
                handleChange={fileChange}
                imageUrl={mdlUrl}
                showImage={true}
              />
            </div>
            <div className='flex gap-x-5'>
              <FileUpload
                title={'PAD Assign'}
                name={'PAD'}
                handleUpload={() => uploadButtonClick('PAD')}
                handleDownload={() => downloadButtonClick(padUrl)}
                handleChange={fileChange}
                imageUrl={padUrl}
                showImage={true}
              />
              <FileUpload
                title={'GDS'}
                name={'GDS'}
                handleUpload={() => uploadButtonClick('GDS')}
                handleDownload={() => downloadButtonClick(gdsUrl)}
                handleChange={fileChange}
                imageUrl={gdsUrl}
                showImage={false}
              />
            </div>
          </FileUploadModal>
        ) : null}
        {codeSubModalOpen ? (
          <>
            <ModalOverlay />
            <ModalWrapper>
              <ModalInner className='w-[1300px] h-[900px]'>
                <FrameInfo>
                  <div className='search-form'>
                    <div>
                      <TEG02FileUploadBox
                        title='좌표파일'
                        name='SUB'
                        handleUpload={subUploadButtonClick}
                        handleReset={resetButtonClick}
                      ></TEG02FileUploadBox>
                    </div>
                    <div className='input-block'>
                      <div className='flex gap-x-2'>
                        <FrameInfoSelect
                          title='디바이스'
                          options={maskDeviceOptions}
                          onChange={e => editValue(e, 'MSK_DVC_CD')}
                        />
                      </div>
                    </div>
                  </div>
                </FrameInfo>
                <div>
                  <div className='w-[100%]'>
                    <TEG02SubTable
                      tableHeight={getHeight(700)}
                    ></TEG02SubTable>
                  </div>
                </div>
                <div className='flex justify-center mt-2'>
                  <CommonButton
                    title='닫기'
                    color='darkred'
                    onClick={() => setCodeSubModalOpen(false)}
                  />
                </div>
              </ModalInner>
            </ModalWrapper>
          </>
        ) : null}
      </FrameContent>
    </>
  );
};

export default TEG02;
