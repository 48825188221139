import { useInfiniteQuery } from '@tanstack/react-query';
import {
  ISearchSystemLog,
  UseInfiniteQueryOptionsWithoutQueryKey,
} from 'models';
import { FETCHSIZE, client } from 'utils';

interface ISystemLogList {
  searchData: ISearchSystemLog;
  options: UseInfiniteQueryOptionsWithoutQueryKey;
}

function systemLogList({ searchData, options }: ISystemLogList) {
  return useInfiniteQuery({
    queryKey: ['systemLog', searchData],
    queryFn: async ({ pageParam }) => {
      const fetchParam = {
        ...searchData,
        PAGE: (pageParam as number) * FETCHSIZE,
        FETCHSIZE: FETCHSIZE,
      };

      const response = await client.get('/api/co/cm/190m01/system/log', {
        params: fetchParam,
      });
      return response.data.systemLogList || [];
    },
    ...options,
  });
}

export default function useSystemLogList(searchData: ISearchSystemLog) {
  return systemLogList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      initialPageParam: 0,
      getNextPageParam: (lastPage: any, pages: any) => {
        return lastPage.length < pages[0].length
          ? undefined
          : pages.length;
      },
    },
  });
}
