import {
  CommonArray,
  defaultCurrentData,
  defaultSearchAuthority,
  defaultSearchAuthorityMenu,
} from 'models';
import { atom, atomFamily } from 'recoil';

const searchAuthority = atomFamily({
  key: 'searchAuthority',
  default: defaultSearchAuthority,
});

const authorityData = atomFamily<CommonArray, string>({
  key: 'authorityData',
  default: [],
});

const currentAuthority = atomFamily({
  key: 'currentAuthority',
  default: defaultCurrentData,
});

const searchAuthorityMenu = atom({
  key: 'searchAuthorityMenu',
  default: defaultSearchAuthorityMenu,
});

const authorityMenuList = atom<CommonArray>({
  key: 'authorityMenuList',
  default: [],
});

export const authorityManagementState = {
  searchAuthority,
  authorityData,
  currentAuthority,
  searchAuthorityMenu,
  authorityMenuList,
};
