import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IMaskFileWrapper } from 'models/interfaces/maskInterface';
import { toast } from 'react-toastify';
import { client, getAlertMessage } from 'utils';

function maskFileMutation(
  options: UseMutationOptions<unknown, Error, IMaskFileWrapper, unknown>,
) {
  return useMutation({
    mutationFn: async (maskFile: IMaskFileWrapper) => {
      console.log(maskFile);
      const formData = new FormData();
      const { file, maskFileInfo } = maskFile;
      formData.append('file', file); // 파일 추가
      formData.append(
        'maskFileInfo',
        new Blob([JSON.stringify(maskFileInfo)], {
          type: 'application/json',
        }),
      );
      const response = await client.post(
        '/api/teg/02/file/image/upload',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );
      return response;
    },
    ...options,
  });
}

export default function useMaskFileMutation() {
  const queryClient = useQueryClient();

  return maskFileMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['mask'],
      });
      toast.success(getAlertMessage('INFO_00010'));
    },
  });
}
