import { useHeight, useSearchComboList } from 'hooks';
import { useState } from 'react';
import {
  CommonButton,
  FrameInfo,
  FrameInfoInput,
  FrameInfoSelect,
} from 'components';
import { useRecoilState } from 'recoil';
import { editValues } from 'utils';
import COCM210M01Table from './COCM210M01Table';
import { CustomChangeEvent } from 'models';
import { FrameContent } from 'styles';
import { historyManagementState } from 'store';

const COCM210M01 = (): JSX.Element => {
  const getHeight = useHeight();

  const [searchErrorLog, setSearchErrorLog] = useRecoilState(
    historyManagementState.searchErrorLog,
  );
  const [values, setValues] = useState(searchErrorLog);
  const errorTypeOptions = useSearchComboList({ CMB_CD: 'CMB0028' });
  const combinedOptions = [
    { label: '전체', value: '' },
    ...errorTypeOptions,
  ];
  const editValue = (e: CustomChangeEvent) => editValues(e, setValues);

  const handleSearchLoginHistory = () => {
    setSearchErrorLog(values);
  };

  return (
    <>
      <FrameInfo>
        <div className='search-form'>
          <div className='input-block'>
            <FrameInfoInput
              title='프로그램명'
              type='text'
              name='PGM_NM'
              value={values?.PGM_NM || ''}
              onChange={editValue}
            />
            <FrameInfoSelect
              title='오류유형'
              options={combinedOptions}
              name='ERR_TP_CD'
              onChange={editValue}
            />
            <FrameInfoInput
              title='오류내용'
              type='text'
              name='ERR_LOG_CONT'
              value={values?.ERR_LOG_CONT || ''}
              onChange={editValue}
            />
            <FrameInfoInput
              title='사용자 ID'
              type='text'
              name='USR_ID'
              value={values?.USR_ID || ''}
              onChange={editValue}
            />
            <FrameInfoInput
              title='발생일자'
              type='date'
              name='OCC_FROM_DT'
              value={values?.OCC_FROM_DT || ''}
              onChange={editValue}
            />
            <FrameInfoInput
              title='~'
              type='date'
              name='OCC_TO_DT'
              value={values?.OCC_TO_DT || ''}
              onChange={editValue}
            />
          </div>
        </div>
        <div>
          <CommonButton
            title='조회'
            onClick={handleSearchLoginHistory}
          />
        </div>
      </FrameInfo>
      <FrameContent>
        <div className='w-full'>
          <COCM210M01Table tableHeight={getHeight(650)} />
        </div>
      </FrameContent>
    </>
  );
};

export default COCM210M01;
