import { useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { ReadOnlyTextCell, Table } from 'components';
import { TableContext, addRowNumber, exportJsonToSheet } from 'utils';
import { useRecoilValue } from 'recoil';
import { useInfiniteScroll, useTableData } from 'hooks';
import COCM190M01Modal from './COCM190M01Modal';
import { historyManagementState } from 'store';
import {
  Columns,
  CommonObject,
  CurrentData,
  ISearchSystemLog,
  ITableContext,
  defaultCurrentData,
} from 'models';
import { DataTableContainer } from 'styles';
import { useSystemLogList } from 'apis';

const columnHelper = createColumnHelper();

const COCM190M01Table = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const [openModal, setOpenModal] = useState(false);
  const searchSystemLog = useRecoilValue(
    historyManagementState.searchSystemLog,
  );
  const [systemLogData, fetchSystemLogData] =
    useInfiniteScroll<ISearchSystemLog>(
      useSystemLogList,
      searchSystemLog,
      300,
    );

  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: systemLogData,
    effect: () => {
      const newData = addRowNumber(systemLogData);
      setInitialData(newData);
    },
  });
  const [currentData, setCurrentData] =
    useState<CurrentData>(defaultCurrentData);

  const defaultColumns = [
    columnHelper.accessor('no', {
      header: 'No',
      cell: ReadOnlyTextCell,
      size: 30,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('USR_ID', {
      header: '사용자 ID',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 80,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('USR_NM', {
      header: '사용자명',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 80,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('CL_IP_ADDR', {
      header: '접속 IP',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 100,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('LOG_OCC_DATE', {
      header: '실행일시',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 120,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('MNU_ID', {
      header: '메뉴 ID',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 70,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('MNU_NM', {
      header: '메뉴명',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 120,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('PGM_ID', {
      header: '프로그램 ID',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 100,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('PGM_NM', {
      header: '프로그램명',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 120,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('CL_MTH_NM', {
      header: '실행메소드명',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 150,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('CL_VRB_CONT', {
      header: '전달인자',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
      size: 400,
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const handleDoubleClick = () => {
    setOpenModal(true);
  };

  const contextValue: ITableContext = {
    handleExport: () =>
      exportJsonToSheet(columns, initialData, '시스템사용이력'),
    handleDoubleClick,
    currentData,
    setCurrentData,
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        <Table
          title='사용이력'
          initialData={initialData}
          setData={setInitialData}
          fetchScroll={fetchSystemLogData}
          columns={columns}
          tableHeight={tableHeight}
        />
      </TableContext.Provider>
      {openModal && (
        <COCM190M01Modal
          onClose={() => setOpenModal(false)}
          currentData={currentData?.data}
        />
      )}
    </DataTableContainer>
  );
};

export default COCM190M01Table;
