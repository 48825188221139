import tw from 'twin.macro';
import styled from 'styled-components';
import { FRAME_WIDTH, TARGET_WIDTH } from 'utils';

export const FrameContainer = styled.div`
  ${tw`
    py-3
    px-5
    w-full
    h-full
    overflow-auto
  `}
`;

export const FrameWrapper = styled.div`
  height: 100%;
  min-width: ${FRAME_WIDTH - TARGET_WIDTH}px;
`;

export const FrameInfoBox = styled.div`
  ${tw`
    mb-2
  `}

  .title-box {
    ${tw`
      flex
      items-center
      justify-between
      text-blue-950
      mb-1
    `}

    svg {
      ${tw`
        w-[1rem]
        h-[1rem]
        transform
        duration-300
        cursor-pointer

        hover:rotate-[360deg]
      `}
    }

    .title {
      ${tw`
        text-[1rem]
        ml-1
      `}
    }
  }

  .search-box {
    ${tw`
      w-full
      mt-1
      py-1
      px-2
      bg-slate-100
      border-y
      border-slate-200
      flex
    `}

    .search-form {
      ${tw`
        flex
        justify-between
        flex-grow
        gap-x-4
        text-[0.8rem]
      `}

      .input-block {
        ${tw`
          flex
          gap-x-2
        `}
        div {
          ${tw`
          flex
          items-center
        `}
        }
      }

      .block-title {
        ${tw`
          text-slate-700
          mr-2
        `}
      }

      input,
      select,
      .date-span {
        ${tw`
          w-fit
          min-w-[100px]
          outline-none
          border
          border-slate-200
          pl-1
          py-[1px]

          hover:border-slate-400
          focus:border-slate-400
        `}
      }

      input[type='checkbox'] {
        ${tw`
          w-[0.8rem]
          h-[0.8rem]
        `}
      }
    }
  }
`;

export const FrameContent = styled.div`
  ${tw`
    flex
    justify-between
    gap-x-3
  `}

  .flex-between-2 {
    ${tw`
      flex
      justify-between
      gap-x-2
      `}
  }

  .col-center {
    ${tw`
      flex
      flex-col
      justify-center
      gap-y-1
    `}
  }

  .col-between {
    ${tw`
      flex
      flex-col
      justify-between
    `}
  }

  .grow-row-between {
    ${tw`
      flex
      flex-grow
      justify-between
    `}
  }

  .grow-col-between {
    ${tw`
      flex
      flex-col
      justify-between
      flex-grow
    `}
  }

  .switch-button {
    ${tw`
      p-1
      border
      rounded
      text-xl
      text-blue-950
      hover:bg-blue-50
    `}
  }
`;

export const EditBox = styled.div`
  ${tw`
    flex
    flex-col
    text-xs
  `}

  .multi-title {
    ${tw`
      border-b
      border-blue-950
      text-center
      py-0.5
      px-2
      cursor-pointer
    `}
  }

  .active {
    ${tw`
      bg-blue-950
      text-white
    `}
  }

  .title-box {
    ${tw`
    text-sm
    flex
    items-center
    justify-between
    mb-1
    font-semibold
    `}
  }
`;

export const EditFieldRow = styled.div`
  ${tw`
    flex
    border-x
    border-b
    border-slate-200
  `}
`;

export const EditFieldItem = styled.div<{ $width?: number }>`
  ${tw`
    flex
    last:border-none
    border-r
    border-slate-200
  `}
  width: ${({ $width }) => $width || 300}px;

  div {
    ${tw`
      flex
      items-center
    `}
  }

  .key-name {
    ${tw`
      relative
      flex-shrink-0
      bg-slate-100
      w-[100px]
      pl-1
      border-r
      border-slate-200
    `}
  }
  .field-box {
    ${tw`
      flex-grow
      p-1
      pl-1
    `}
  }

  .required-icon {
    ${tw`
      absolute
      top-[-3px]
      left-[-3px]
      w-[15px]
      h-[15px]
      m-0
      p-0
      rotate-45
      text-red-600
    `}
  }
  input {
    ${tw`
      w-full
      pl-1
      py-[1px]
      read-only:bg-slate-100
      `}
  }

  select {
    ${tw`
      w-fit
      px-2
      mr-1
      disabled:bg-slate-100
    `}
  }

  .field-button {
    ${tw`
      px-2
      py-[1px]
      bg-white
      border
      border-blue-950

      hover:bg-blue-950
      hover:text-slate-100
      disabled:hover:bg-slate-100
      disabled:hover:text-black
    `}
  }

  input:disabled,
  select:disabled,
  button:disabled {
    ${tw`
      bg-slate-100
    `}
  }

  input,
  select {
    ${tw`
      bg-white
      border
      border-slate-200
      outline-none

      focus:border
      focus:border-slate-400
    `}
    &.required {
      ${tw`
        border-red-300
        focus:border-red-400
      `}
    }
  }

  input[type='checkbox'] {
    ${tw`
      w-[15px]
      h-[15px]
    `}
  }

  input[type='radio'] {
    ${tw`
      mx-2
      w-[15px]
      h-[15px]
    `}
  }
`;

export const TreeBox = styled.div`
  ${tw`
    w-[250px]
    border
    border-slate-200
    transform
    duration-200
    overflow-auto
  `}

  .tree-item {
    ${tw`
      text-xs
      flex
      h-[25px]
      pl-[1rem]
      overflow-hidden
      whitespace-nowrap
    `}

    div {
      ${tw`
        border-slate-500
        border-dotted
      `}
    }

    .line-box {
      ${tw`
        flex-shrink-0
        w-[15px]
        mb-[1px]
      `}

      div {
        height: 50%;
      }
    }
  }

  .tree-content {
    ${tw`
      flex
      items-center
      gap-x-1
      pl-1
    `}

    svg {
      ${tw`
        w-[15px]
        h-[15px]
      `}
    }
  }
`;

export const UserFieldTitle = styled.div`
  ${tw`
    relative
    w-[100px]
    bg-slate-100
    flex
    items-center
    border-r
    pl-1
  `}

  .required-icon {
    ${tw`
      absolute
      top-[-3px]
      left-[-3px]
      w-[15px]
      h-[15px]
      m-0
      p-0
      rotate-45
      text-red-600
    `}
  }
`;

export const FrameTextArea = styled.textarea`
  ${tw`
    h-full
    w-full
    border
    outline-none
    focus:border-slate-400
    px-1
  `}

  &.required {
    ${tw`
        border-red-300
        focus:border-red-400
      `}
  }
`;

export const GrafanaContainer = styled.div`
  ${tw`
    flex
    justify-between
    gap-y-2
  `}
`;

export const GrafanaBox = styled.div`
  ${tw`
    border
    border-slate-200
    rounded-lg
    overflow-hidden
  `}

  iframe {
    ${tw`
      w-full
      h-full
    `}
  }
`;

export const ImageBox = styled.div`
  ${tw`
    border
    border-slate-200
    rounded-lg
    overflow-hidden
    w-[450px]
    h-[350px]
  `}

  img {
    ${tw`
      w-auto
      h-auto
      m-auto
    `}
  }
`;

export const SearchRefreshWrapper = styled.div`
  ${tw`
    rounded-[50%]
    bg-gray-500
    p-[1px]
    text-white
    transform
    duration-150
  `}

  &:hover {
    ${tw`
      bg-blue-800
    `}
    transform: scale(1.03);
  }

  .refresh-icon {
    width: 1.4em;
    height: 1.4em;
    cursor: pointer;
  }
`;
