import { useQuery } from '@tanstack/react-query';
import { UseQueryOptionsWithoutQueryKey } from 'models';
import { client } from 'utils';

interface IImageParam {
  url: string;
  options: UseQueryOptionsWithoutQueryKey;
}

function image({ url, options }: IImageParam) {
  return useQuery({
    queryKey: ['image', url],
    queryFn: async () => {
      if (url === '') return '';
      const response = await client.get(
        `/esd_file/${url}?${new Date().getTime()}`,
        {
          responseType: 'blob',
        },
      );
      const imageUrl = URL.createObjectURL(response.data);
      return imageUrl;
    },
    ...options,
  });
}

export default function useImage(url: string) {
  return image({
    url: url,
    options: {
      retry: false,
      refetchOnMount: true,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      gcTime: 0,
      staleTime: 0,
      enabled: url !== '' && url !== undefined,
    },
  });
}
