import { useQuery } from '@tanstack/react-query';
import { UseQueryOptionsWithoutQueryKey } from 'models';
import { client } from 'utils';

function allAuthorityList(options: UseQueryOptionsWithoutQueryKey) {
  return useQuery({
    queryKey: ['system_setting', 'all_authorities'],
    queryFn: async () => {
      const response = await client.get('/api/co/cm/170m01/authority');
      return response.data.userAuthorityList || [];
    },
    ...options,
  });
}

export default function useAllAuthorityList() {
  const options: UseQueryOptionsWithoutQueryKey = {
    retry: false,
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  };
  return allAuthorityList(options);
}
