import { useQuery } from '@tanstack/react-query';
import { UseQueryOptionsWithoutQueryKey } from 'models';
import { client } from 'utils';
import { ISearchProject } from 'models/interfaces/projectInterface';

interface IProjectListParam {
  searchData: ISearchProject;
  options: UseQueryOptionsWithoutQueryKey;
}

function project({ searchData, options }: IProjectListParam) {
  return useQuery({
    queryKey: ['project', searchData],
    queryFn: async () => {
      const response = await client.get('/api/teg/01/project', {
        params: searchData,
      });
      return response.data.projectList;
    },
    ...options,
  });
}

export default function useProjectList(searchData: ISearchProject) {
  return project({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
