import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { usePlotSpecMutation, useSpecList } from 'apis';
import {
  DisplayButton,
  ReadOnlyTextCell,
  StatusCell,
  StatusIcon,
  Table,
} from 'components';
import { useMoveTeg, useTableData } from 'hooks';
import {
  Columns,
  CommonArray,
  CommonObject,
  CurrentData,
  IMeasureSpec,
  IPlotSaveDataWithValueList,
  IPlotValue,
  ITableContext,
  defaultCurrentData,
} from 'models';
import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { plotState } from 'store';
import { DataTableContainer } from 'styles';
import {
  SUBPLOT_HEIGHT,
  SUBPLOT_WIDTH,
  TableContext,
  exportJsonToSheet,
  saveTableData,
} from 'utils';

const columnHelper = createColumnHelper();
const nextRecoilKey = 'reproducibility';

const TEG08Table = ({
  tableHeight,
  recoilKey,
}: {
  tableHeight: number;
  recoilKey: string;
}): JSX.Element => {
  const moveTeg = useMoveTeg();
  const searchPlot = useRecoilValue(plotState.searchPlot(recoilKey));
  const setSearchSubPlot = useSetRecoilState(
    plotState.searchPlot(nextRecoilKey),
  );
  const setSpecCodes = useSetRecoilState(plotState.specCodes);
  const setTrendPlot = useSetRecoilState(plotState.trendPlot);
  const [totalPlotValue, setTotalPlotValue] = useRecoilState(
    plotState.plotValue('total'),
  );
  const [trendPlotValue, setTrendPlotValue] = useRecoilState(
    plotState.plotValue('trend'),
  );
  const [backupData, setBackupData] = useState<CommonArray>([]);
  const { data: specData } = useSpecList(searchPlot) as CommonObject;
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: specData,
    effect: () => {
      const specList = specData.specList.map((spec: IMeasureSpec) => ({
        ...spec,
        MSR_NO: 1,
      }));
      setInitialData(specList);
      setBackupData(specList);
      setCurrentData({ index: 0, data: specList[0] });
      setSpecColumns(specData.specColumns);
      setSpecCodes(specData.specCodes);
      setTrendPlot(old => ({
        ...old,
        DVC_NO: searchPlot.DVC_NO,
        DSG_ID: searchPlot.DSG_ID,
        MSR_TYPE: searchPlot.MSR_TYPE,
        SPC_TRN_AXIS_YL: specData.axis.YL,
        SPC_TRN_AXIS_YR: specData.axis.YR,
      }));
      for (const plot of specData.plotValList) {
        if (plot.PLOT_TYPE === 'TO') setTotalPlotValue(plot);
        else setTrendPlotValue(plot);
      }
    },
  });

  const [specColumns, setSpecColumns] = useState<CommonObject[]>([]);
  const [currentData, setCurrentData] =
    useState<CurrentData>(defaultCurrentData);
  const plotSpecMutation = usePlotSpecMutation();

  const handleMove = (row: CommonObject) => {
    if (row.MSR_CNT < 2) {
      toast.warning('측정치가 2개 이상이어야 합니다.');
      return;
    }

    const { DUT_ID } = row;
    const tranferData = { ...searchPlot, DUT_ID };
    setSearchSubPlot(tranferData);
    moveTeg('UIE008TEG08_S');
  };

  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      header: () => <StatusIcon />,
      cell: StatusCell,
      size: 30,
    }),
    columnHelper.accessor('DUT_NM', {
      header: 'DUT',
      cell: ReadOnlyTextCell,
      size: 70,
      filterFn: 'arrIncludes',
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('DUT_ALIAS', {
      header: '별명',
      cell: ReadOnlyTextCell,
      size: 70,
      filterFn: 'arrIncludes',
      meta: {
        justify: 'center',
      },
    }),
    ...specColumns,
    columnHelper.accessor('UPL_DT', {
      header: '등록일자',
      cell: ReadOnlyTextCell,
      size: 70,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.display({
      header: 'Plot',
      cell: DisplayButton,
      size: 50,
      meta: {
        text: '보기',
        onClick: (row: IPlotValue) => openSubWindow(row),
      },
    }),
    columnHelper.group({
      header: '측정치',
      columns: [
        columnHelper.accessor('MSR_CNT', {
          header: '수량',
          cell: ReadOnlyTextCell,
          size: 30,
          enableColumnFilter: false,
          meta: {
            justify: 'center',
          },
        }) as ColumnDef<unknown, any>,
        columnHelper.display({
          header: '관리',
          cell: DisplayButton,
          size: 40,
          meta: {
            text: '관리',
            onClick: (row: CommonObject) => handleMove(row),
          },
        }),
      ],
    }),
  ];
  const columns = useMemo(() => defaultColumns, [specColumns]) as Columns;

  const openSubWindow = (data: IPlotValue) => {
    const { DVC_NO, DSG_ID, MSR_TYPE, MSR_NO, DUT_ID } = data;
    const queryString = `DVC_NO=${DVC_NO}&DSG_ID=${DSG_ID}&MSR_TYPE=${MSR_TYPE}&MSR_NO=${MSR_NO}&DUT_ID=${DUT_ID}`;
    const url = window.location.origin + `/#/subPlot?${queryString}`;
    window.open(
      url,
      '_blank',
      `width=${SUBPLOT_WIDTH},height=${SUBPLOT_HEIGHT}`,
    );
  };

  const handleSaveData = () => {
    const specSaveParam: IPlotSaveDataWithValueList = {
      PRJ_NO: Number(searchPlot.PRJ_NO),
      specList: saveTableData(initialData) as IMeasureSpec[],
      valueList: [totalPlotValue, trendPlotValue],
    };

    if (specSaveParam.specList.length > 0) {
      plotSpecMutation.mutate(specSaveParam);
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue: ITableContext = {
    handleExport: () =>
      exportJsonToSheet(columns, initialData, 'DUT 스펙'),
    handleSaveData,
    currentData,
    setCurrentData,
    customDisabled: {
      fetched:
        searchPlot.PRJ_NO === '' ||
        searchPlot.PRC_NO === '' ||
        searchPlot.DVC_NO === '' ||
        searchPlot.DSG_ID === '' ||
        searchPlot.MSR_TYPE === '',
    },
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        <Table
          title='DUT'
          initialData={initialData}
          backupData={backupData}
          setData={setInitialData}
          columns={initialData.length > 0 ? columns : []}
          editable={true}
          tableHeight={tableHeight}
        />
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default TEG08Table;
