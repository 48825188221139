import useCellValue from 'hooks/useCellValue';
import { memo, useState } from 'react';
import { changeFocus, isEqual } from 'utils';
import {
  CustomChangeEvent,
  CustomFocusEvent,
  CustomKeyboardEvent,
  CustomSelectFocusEvent,
  IColumnMeta,
  ITableCellProps,
  ITableMeta,
  RowType,
} from 'models';
import { DataTableInput, DataTableSelect } from 'styles';

const SelectOrTextCell = ({
  getValue,
  table,
  row,
  column,
  rowRef,
}: ITableCellProps): JSX.Element => {
  const value = getValue() ?? '';
  const [focus, setFocus] = useState(false);
  const tableMeta: ITableMeta | undefined = table.options.meta;
  const [textValue, changeTextValue, setTextValue] = useCellValue(
    value,
    tableMeta,
    rowRef,
  );
  const columnMeta: IColumnMeta | undefined = column.columnDef.meta;
  const unmodifiedRow = row.original.ROWTYPE !== RowType.ADD;
  const justify = columnMeta?.justify || 'start';

  const onBlur = (e: CustomFocusEvent) => {
    changeTextValue(row.index, e);
    setFocus(false);
  };

  const onChange = (e: CustomChangeEvent) => {
    setTextValue(e.target.value);
  };

  const onFocus = (e: CustomFocusEvent) => {
    if (columnMeta?.unmodifiable && unmodifiedRow) return;
    e.target.select();
    setFocus(true);
  };

  const handleOnKeyDown = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter') e.currentTarget.blur();
    if (e.key === 'ArrowDown') {
      if (row.index + 1 === table.options.data.length) return;
      e.currentTarget.blur();
      e.preventDefault();
      const nextIndex = row.index + 1;
      tableMeta?.moveCurrentData?.(nextIndex);
      if (!changeFocus(nextIndex, column.id)) {
        tableMeta?.moveCurrentData?.(nextIndex + 1);
        changeFocus(nextIndex + 1, column.id);
      }
    }
    if (e.key === 'ArrowUp') {
      if (row.index === 0) return;
      e.currentTarget.blur();
      e.preventDefault();
      const prevIndex = row.index - 1;
      tableMeta?.moveCurrentData?.(prevIndex);
      if (!changeFocus(prevIndex, column.id)) {
        tableMeta?.moveCurrentData?.(prevIndex - 1);
        changeFocus(prevIndex - 1, column.id);
      }
    }
    if (e.key === 'ArrowLeft') {
      let columnId = row.getAllCells()[column.getIndex() - 1]?.column.id;
      e.currentTarget.blur();
      e.preventDefault();
      if (!changeFocus(row.index, columnId)) {
        columnId = row.getAllCells()[column.getIndex() - 2]?.column.id;
        changeFocus(row.index, columnId);
      }
    }
    if (e.key === 'ArrowRight') {
      let columnId = row.getAllCells()[column.getIndex() + 1]?.column.id;
      e.currentTarget.blur();
      e.preventDefault();
      if (!changeFocus(row.index, columnId)) {
        columnId = row.getAllCells()[column.getIndex() + 2]?.column.id;
        changeFocus(row.index, columnId);
      }
    }
  };

  const [selectValue, setSelectValue] = useCellValue(
    value,
    tableMeta,
    rowRef,
  );
  const selectOnChange = (e: CustomChangeEvent) => {
    setSelectValue(row.index, e);
  };
  const getDisabled = () => {
    if (typeof columnMeta?.disabled === 'function') {
      return columnMeta.disabled(row.original);
    } else {
      return columnMeta?.disabled;
    }
  };
  const disabled = columnMeta?.disabled ? getDisabled() : false;
  const options = columnMeta?.options;
  const [filterOptions, setFilteredOptions] = useState(options);
  const optionFn = columnMeta?.optionFn;
  const renderOptions = optionFn ? optionFn(row.original) : filterOptions;
  const optionFilter = columnMeta?.optionFilter;
  const selectOnFocus = (e: CustomSelectFocusEvent) => {
    if (!optionFilter) return;
    let newOptions = filterOptions;
    for (let i = 0; i < table.getRowCount(); i++) {
      const original = table.getRow(i.toString()).original as any;
      if (i !== row.index) {
        newOptions = newOptions?.filter(
          option => option.label.indexOf(original[e.target.name]) === -1,
        );
      }
    }

    setFilteredOptions(newOptions);
  };
  return row.original.ROWTYPE !== RowType.ADD ? (
    <DataTableInput
      type='text'
      id={`${row.index}-${column.id}`}
      className={focus ? 'text-start' : `text-${justify}`}
      readOnly={columnMeta?.unmodifiable && unmodifiedRow}
      name={column.id}
      value={textValue || ''}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyDown={handleOnKeyDown}
      required={columnMeta?.required ?? false}
      autoComplete='off'
    />
  ) : (
    <DataTableSelect
      value={selectValue ?? ''}
      name={column.id}
      onChange={selectOnChange}
      required={columnMeta?.required ?? false}
      disabled={disabled}
      onFocus={selectOnFocus}
    >
      {renderOptions?.map(
        (option, index) =>
          option.label.indexOf('') !== -1 && (
            <option
              key={option.value + index}
              value={option.value}
            >
              {option.label}
            </option>
          ),
      )}
    </DataTableSelect>
  );
};

export default memo(SelectOrTextCell, isEqual);
