import { memo } from 'react';
import { isEqual } from 'utils';
import useCellValue from 'hooks/useCellValue';
import { CustomChangeEvent, IColumnMeta, ITableCellProps } from 'models';
import { DataTableInput } from 'styles';

const DateCell = ({
  getValue,
  table,
  row,
  column,
  rowRef,
}: ITableCellProps): JSX.Element => {
  const value = getValue();
  const tableMeta = table.options.meta;
  const [dateValue, setDateValue] = useCellValue(value, tableMeta, rowRef);
  const columnMeta: IColumnMeta | undefined = column.columnDef.meta;
  const columnSize = column.columnDef.size;
  const type = columnMeta?.type;

  const onChange = (e: CustomChangeEvent) => {
    setDateValue(row.index, e);
  };

  const handleOnPaste = (e: any) => {
    const clipboardData = e.clipboardData;
    if (!clipboardData) {
      return;
    }
    const types = clipboardData.types;
    if (types.includes('text/html')) e.preventDefault();
  };

  return (
    <DataTableInput
      type={type ? type : 'date'}
      style={{ width: columnSize }}
      name={column.id}
      value={dateValue || ''}
      autoComplete='off'
      onChange={onChange}
      onFocus={e => e.target.select()}
      required={columnMeta?.required ?? false}
      onPaste={handleOnPaste}
    />
  );
};

export default memo(DateCell, isEqual);
