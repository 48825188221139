import { useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  ReadOnlyTextCell,
  SelectCell,
  StatusCell,
  StatusIcon,
  Table,
  TextCell,
} from 'components';
import {
  TableContext,
  addRowData,
  addRowNumber,
  exportJsonToSheet,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';
import { useRecoilValue } from 'recoil';
import { useTableData } from 'hooks';
import { menuManagementState } from 'store';
import {
  Columns,
  CommonObject,
  CurrentData,
  IProgram,
  ITablePropsWithOptions,
  defaultCurrentData,
  defaultProgram,
} from 'models';
import { DataTableContainer } from 'styles';
import { useProgramList } from 'apis';
import useSaveProgram from 'apis/mutations/useProgramMutation';

const columnHelper = createColumnHelper();
const newRow = defaultProgram;

const COCM140M01Table = ({
  tableHeight,
  options,
}: ITablePropsWithOptions): JSX.Element => {
  const searchProgram = useRecoilValue(menuManagementState.searchProgram);
  const [backupData, setBackupData] = useState<CommonObject[]>([]);
  const { data: programData } = useProgramList(
    searchProgram,
  ) as CommonObject;
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: programData,
    atom: menuManagementState.programList,
    effect: () => {
      const newData = addRowNumber(programData);
      setInitialData(newData);
      setBackupData(newData);
      setCurrentData({ index: 0, data: newData[0] });
    },
  });
  const [currentData, setCurrentData] =
    useState<CurrentData>(defaultCurrentData);
  const useOptions = [
    { label: '사용', value: '1' },
    { label: '사용안함', value: '0' },
  ];
  const messageMutation = useSaveProgram();

  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 20,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('no', {
      header: 'No',
      cell: ReadOnlyTextCell,
      size: 30,
      enableColumnFilter: false,
      meta: {
        justify: 'center',
      },
    }),
    columnHelper.accessor('PGM_ID', {
      header: '프로그램ID',
      cell: TextCell,
      filterFn: 'includesString',
      meta: {
        unmodifiable: true,
        required: true,
      },
    }),
    columnHelper.accessor('PGM_NM', {
      header: '프로그램명',
      cell: TextCell,
      filterFn: 'includesString',
      meta: {
        required: true,
      },
    }),
    columnHelper.accessor('SYS_DTT_CD', {
      header: '시스템구분',
      cell: SelectCell,
      size: 80,
      filterFn: 'includesString',
      meta: {
        options: options,
      },
    }),
    columnHelper.accessor('PGM_DESC_CONT', {
      header: '프로그램 설명',
      cell: TextCell,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('DESC_RMK', {
      header: '비고',
      cell: TextCell,
      enableColumnFilter: false,
    }),
    columnHelper.accessor('USE_YN', {
      header: '사용여부',
      cell: SelectCell,
      size: 80,
      filterFn: 'includesString',
      meta: {
        options: useOptions,
        required: true,
      },
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const handleAddData = () => {
    const modifiedNewRow = {
      ...newRow,
      SYS_DTT_CD: options?.[0]?.value || '',
    };
    addRowData(
      setInitialData,
      setCurrentData,
      modifiedNewRow,
      initialData.length,
    );
  };

  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);

    setInitialData(removedData);
    selectNextDataByRemove(
      selectData,
      initialData.length - 1,
      removedData,
      setCurrentData,
    );
  };

  const handleSaveData = () => {
    const checkKeys = ['PGM_ID', 'PGM_NM', 'USE_YN'];
    const targetData = saveTableData(initialData, checkKeys);

    if (targetData.length > 0) {
      messageMutation.mutate(targetData as IProgram[]);
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue = {
    handleExport: () =>
      exportJsonToSheet(columns, initialData, '시스템메시지'),
    currentData,
    setCurrentData,
    handleAddData,
    handleRemoveData,
    handleSaveData,
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        <Table
          title='프로그램'
          initialData={initialData}
          backupData={backupData}
          setData={setInitialData}
          columns={columns}
          editable={true}
          tableHeight={tableHeight}
        />
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default COCM140M01Table;
