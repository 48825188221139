import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchAppendix } from 'models';
import { client } from 'utils';

function appendixList({
  searchData,
  options,
}: IQueryParams<ISearchAppendix>) {
  return useQuery({
    queryKey: ['document', 'chapter', 'appendix', searchData],
    queryFn: async () => {
      const response = await client.get('/api/teg/09/doc/guide/appendix', {
        params: searchData,
      });

      return response.data;
    },
    ...options,
  });
}

export default function useAppendixList(searchData: ISearchAppendix) {
  return appendixList({
    searchData: searchData,
    options: {
      retry: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled:
        searchData.CHR_SNO !== 0 && searchData.CHR_SNO !== undefined,
    },
  });
}
