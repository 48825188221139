import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Table, ReadOnlyTextCell } from 'components';
import { TableContext } from 'utils';
import { Columns, CommonObject } from 'models';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTableData } from 'hooks';
import { ITableContext } from 'models';
import useProcessListInModal from 'apis/queries/useProcessListInModal';
import { projectState } from 'store';

const columnHelper = createColumnHelper();

const TEG01ProcessTableInModal = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const searchData = useRecoilValue(projectState.searchProcessInModal);
  const { data: process } = useProcessListInModal(
    searchData,
  ) as CommonObject;
  const [currentData, setCurrentData] = useRecoilState(
    projectState.currentProcessDataInModal,
  );
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: process,
    atom: projectState.processListInModal,
    effect: () => {
      setInitialData(process);
      setCurrentData({ index: 0, data: process[0] });
    },
  });

  const defaultColumns = [
    columnHelper.accessor('PRS_CD_NM', {
      header: '중공정 코드',
      cell: ReadOnlyTextCell,
      filterFn: 'includesString',
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const contextValue: ITableContext = {
    currentData,
    setCurrentData,
  };

  return (
    <TableContext.Provider value={contextValue}>
      {initialData && (
        <Table
          title='중공정'
          initialData={initialData}
          setData={setInitialData}
          columns={columns}
          tableHeight={tableHeight}
        />
      )}
    </TableContext.Provider>
  );
};

export default TEG01ProcessTableInModal;
