import { useEffect, useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Table, ReadOnlyTextCell, ReadOnlyCheckCell } from 'components';
import { TableContext } from 'utils';
import { Columns, CommonObject } from 'models';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTableData } from 'hooks';
import { ITableContext } from 'models';
import { deviceState } from 'store';
import {
  IDeviceDoc,
  ISearchDevice,
} from 'models/interfaces/deviceInterface';
import useDeviceDescriptionCheckList from 'apis/queries/useDeviceDescriptionCheckList';

const columnHelper = createColumnHelper();

const TEG03ModalDescriptionTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const deviceDocList = useRecoilValue(
    deviceState.searchDeviceDescriptionList,
  );
  const deviceSearchData = useRecoilValue(deviceState.searchVop);
  const [currentData, setCurrentData] = useRecoilState(
    deviceState.currentDescriptionData,
  );
  const saveData = {
    deviceDocList: deviceDocList as IDeviceDoc[],
    deviceSearch: deviceSearchData as ISearchDevice,
  };
  const descriptionResponse = useDeviceDescriptionCheckList(
    saveData,
  ) as CommonObject;
  const descriptionList = descriptionResponse?.data?.fileList;
  const fileExist = descriptionResponse?.data?.fileExist;
  const setFileUrl = useSetRecoilState(deviceState.currentDeviceFileUrl);
  useEffect(() => {
    if (fileExist) {
      if (fileExist.FILE === '1') {
        const prjNo = deviceSearchData.PRJ_NO.toString().padStart(9, '0');
        const prsCd2 = deviceSearchData.PRS_CD2;
        setFileUrl({
          URL: `PRJ/${prjNo}/${prsCd2}_TEGDescription.xlsx`,
          FLAG: fileExist.FILE,
        });
      } else {
        setFileUrl({
          URL: ``,
          FLAG: '0',
        });
      }
    }
  }, [fileExist]);
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: descriptionList,
    atom: deviceState.descriptionListInDeviceModal,
    effect: () => {
      setInitialData(descriptionList);
    },
  });

  const defaultColumns = [
    columnHelper.accessor('DIV', {
      header: '구분',
      cell: ReadOnlyTextCell,
      size: 40,
    }),
    columnHelper.accessor('NAME', {
      header: '항목명',
      cell: ReadOnlyTextCell,
    }),
    columnHelper.accessor('CHECK', {
      header: '완료',
      cell: ReadOnlyCheckCell,
      size: 20,
    }),
  ];
  const columns = useMemo(() => defaultColumns, []) as Columns;

  const contextValue: ITableContext = {
    currentData,
    setCurrentData,
  };

  return (
    <TableContext.Provider value={contextValue}>
      {initialData && (
        <Table
          title='진행상태'
          initialData={initialData}
          backupData={descriptionList}
          setData={setInitialData}
          columns={columns}
          tableHeight={tableHeight}
          showToggle={false}
        />
      )}
    </TableContext.Provider>
  );
};

export default TEG03ModalDescriptionTable;
