import { CommonButton, FrameInfo, FrameInfoInput } from 'components';
import COCM220M01Table from './COCM220M01Table';
import COCM220M01Form from './COCM220M01Form';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { editValues } from 'utils';
import { formManagementState } from 'store';
import { CustomChangeEvent, ISearchPopup } from 'models';
import { FrameContent } from 'styles';

const COCM220M01 = (): JSX.Element => {
  const [searchPopup, setSearchPopup] = useRecoilState(
    formManagementState.searchPopup,
  );
  const [values, setValues] = useState<ISearchPopup>(searchPopup);

  const editValue = (e: CustomChangeEvent) => editValues(e, setValues);
  const handleSearch = () => {
    setSearchPopup(values);
  };
  return (
    <>
      <FrameInfo>
        <div className='search-form'>
          <div className='input-block'>
            <FrameInfoInput
              title='검색팝업코드'
              type='text'
              name='SRCH_PPU_CD'
              value={values?.SRCH_PPU_CD || ''}
              onChange={editValue}
            />
            <FrameInfoInput
              title='팝업명'
              type='text'
              name='PPU_NM'
              value={values?.PPU_NM || ''}
              onChange={editValue}
            />
          </div>
          <div>
            <CommonButton
              title='조회'
              onClick={handleSearch}
            />
          </div>
        </div>
      </FrameInfo>
      <FrameContent>
        <div className='flex-between-2 w-full'>
          <div className='w-[650px]'>
            <COCM220M01Table tableHeight={670} />
          </div>
          <div className='grow-col-between'>
            <COCM220M01Form height={670} />
          </div>
        </div>
      </FrameContent>
    </>
  );
};

export default COCM220M01;
