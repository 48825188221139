import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Table,
  StatusCell,
  StatusIcon,
  ModalCell,
  SelectCell,
  DisplayButton,
} from 'components';
import {
  TableContext,
  addRowData,
  getAlertMessage,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';
import {
  Columns,
  CommonArray,
  CommonObject,
  CurrentData,
  ITableContext,
  RowType,
} from 'models';
import { deviceState, projectState } from 'store';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useMoveTeg, useTableData } from 'hooks';
import useProcessList from 'apis/queries/useProcessList';
import useProcessMutation from 'apis/mutations/useProcessMutation';
import { IProcess } from 'models/interfaces/projectInterface';
import { defaultProcess } from 'models/defaults/projectDefaults';
import { toast } from 'react-toastify';

const columnHelper = createColumnHelper();
const newRow = defaultProcess;

const TEG01ProcessTable = ({
  tableHeight,
  maskOptions,
  openModal,
}: {
  tableHeight: number;
  maskOptions: CommonArray;
  openModal: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const moveTeg = useMoveTeg();
  const searchData = useRecoilValue(projectState.searchProcess);
  const setSearchDevice = useSetRecoilState(deviceState.searchDevice);
  const processResponse = useProcessList(searchData) as CommonObject;
  const process = processResponse?.data?.processList;
  const [backupData, setBackupData] = useState<CommonArray>([]);
  const [currentData, setCurrentData] = useRecoilState(
    projectState.currentProcessData,
  );
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: process,
    atom: projectState.processList,
    effect: () => {
      setInitialData(process);
      setBackupData(process);
      setCurrentData({ index: 0, data: process[0] });
    },
  });
  const saveProcessList = useProcessMutation();

  const handleMoveDevice = (row: CommonObject) => {
    const { PRC_NO } = row;
    const { PRJ_NO } = searchData;
    setSearchDevice({
      PRJ_NO: PRJ_NO,
      PRC_NO: PRC_NO,
      DVC_NO: 0,
      PRJ_NM: '',
      PRS_CD2: '',
    });
    moveTeg('UIE003TEG03');
  };

  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 20,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('PRS_CD12', {
      header: '공정',
      cell: ModalCell,
      size: 180,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('MSK_CD', {
      header: '마스크',
      cell: SelectCell,
      filterFn: 'includesString',
      size: 70,
      meta: {
        renderKey: 'MSK_CD',
        options: maskOptions,
      },
    }),
    columnHelper.display({
      header: '디바이스',
      cell: DisplayButton,
      size: 50,
      meta: {
        text: '관리',
        onClick: (row: CommonObject) => handleMoveDevice(row),
        disabled: (row: CommonObject) => row.ROWTYPE === RowType.ADD,
      },
    }),
  ];
  const columns = useMemo(
    () => defaultColumns,
    [maskOptions, processResponse],
  ) as Columns;

  const handleAddData = () => {
    if (searchData?.ROWTYPE === RowType.ADD) {
      toast.warning(getAlertMessage('ERRO_00380', ['프로젝트']));
      return;
    }
    addRowData(
      setInitialData,
      setCurrentData,
      {
        ...newRow,
        PRJ_NO: searchData.PRJ_NO,
        MSK_CD: maskOptions?.[0]?.value,
      },
      initialData.length,
    );
  };

  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);

    if (removedData.length > 0) {
      setInitialData(removedData);
      selectNextDataByRemove(
        selectData,
        initialData.length - 1,
        removedData,
        setCurrentData,
      );
    }
  };

  const handleSaveData = () => {
    const checkKeys = ['PRS_CD12', 'MSK_CD'];
    const targetData = saveTableData(initialData, checkKeys);
    if (targetData.length > 0) {
      saveProcessList.mutate(targetData as IProcess[]);
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue: ITableContext = {
    handleAddData,
    handleRemoveData,
    handleSaveData,
    currentData,
    setCurrentData,
    customDisabled: {
      fetched:
        searchData.PRJ_NO === undefined ||
        searchData.PRJ_NO === 0 ||
        searchData.ROWTYPE === RowType.ADD,
    },
    openModal,
  };

  return (
    <TableContext.Provider value={contextValue}>
      {initialData && (
        <Table
          title='공정'
          initialData={initialData}
          backupData={backupData}
          setData={setInitialData}
          columns={columns}
          editable={true}
          tableHeight={tableHeight}
        />
      )}
    </TableContext.Provider>
  );
};

export default TEG01ProcessTable;
