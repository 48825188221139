import { Loading } from 'components';
import { useState } from 'react';
import TreeNode from './TreeNode';
import { InfoContainer, TreeBox } from 'styles';
import { CommonObject, ITreeProps } from 'models';

const Tree = ({
  title,
  isLoading,
  data = [],
  currentData,
  setCurrentData,
  renderKey,
  nodeKey,
  parentKey,
  height,
}: ITreeProps) => {
  const [toggleItems, setToggleItems] = useState<string[]>([]);

  const hasChildren = (target: CommonObject) =>
    target.children && target.children.length > 0;
  const creatIndex = () => {
    let index = 0;
    return () => index++;
  };
  const counter = creatIndex();

  const renderTree = (
    tree: CommonObject,
    depth = 0,
    exceptDepth: number[] = [],
  ) => {
    return tree.map((node: CommonObject, index: number) => {
      const treeIndex = counter();
      const isLastChild = index === tree.length - 1;

      const currentNode = (
        <TreeNode
          key={node[nodeKey]}
          toggleItems={toggleItems}
          setToggleItems={setToggleItems}
          currentData={currentData}
          setCurrentData={setCurrentData}
          nodeKey={nodeKey}
          parentKey={parentKey}
          renderKey={renderKey}
          node={node}
          depth={depth}
          exceptDepth={exceptDepth}
          isLastChild={isLastChild}
          treeIndex={treeIndex}
        />
      );

      let children = [];
      if (hasChildren(node)) {
        children = renderTree(
          node.children,
          depth + 1,
          isLastChild ? [...exceptDepth, depth] : exceptDepth,
        );
      }

      return [currentNode, ...children];
    });
  };

  return (
    <div className='flex-col'>
      <InfoContainer>
        <div className='info-box'>
          <div className='title'>{title}</div>
        </div>
      </InfoContainer>
      <TreeBox style={{ height: height }}>
        {isLoading ? <Loading /> : renderTree(data)}
      </TreeBox>
    </div>
  );
};

export default Tree;
