import { useMenuList, useMessageList } from 'apis';
import { Frame, Header, Side, TabMenuBox } from 'features';
import { ISideMenu, defaultSearchMessage } from 'models';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { layoutState, menuState } from 'store';
import { MENUTAB_HEIGHT, TARGET_HEIGHT } from 'utils';

const Home = (): JSX.Element => {
  const separateMenuList = useSetRecoilState(menuState.menuSeparator);
  const [, topOpen] = useRecoilValue(layoutState.topOpenStyle);
  const { data: menuList } = useMenuList();
  const { data: systemMessage } = useMessageList(defaultSearchMessage);

  const headerHeight = topOpen ? TARGET_HEIGHT : 0;
  const contentHeight =
    window.innerHeight - (headerHeight + MENUTAB_HEIGHT);

  useEffect(() => {
    if (menuList) {
      separateMenuList(menuList as ISideMenu[]);
    }
  }, [menuList]);
  useEffect(() => {
    if (systemMessage) {
      let newData = {};
      if (Array.isArray(systemMessage)) {
        systemMessage.forEach((message: any) => {
          newData = { ...newData, [message.MSG_CD]: message.MSG_NM };
        });
      }
      sessionStorage.setItem('alertMessage', JSON.stringify(newData));
    }
  }, [systemMessage]);

  return (
    <div className='bg-blue-950'>
      <Header />
      <TabMenuBox />
      <div
        className='flex w-full bg-white'
        style={{ height: contentHeight }}
      >
        <Side />
        <Frame />
      </div>
    </div>
  );
};
export default Home;
