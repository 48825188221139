import { HeaderContainer } from 'styles';
import HeaderLogo from './HeaderLogo';
import HeaderMenu from './HeaderMenu';
import HeaderUserInfo from './HeaderUserInfo';
import { useRecoilValue } from 'recoil';
import { layoutState } from 'store';

const Header = (): JSX.Element => {
  const [topStyle] = useRecoilValue(layoutState.topOpenStyle);
  return (
    <HeaderContainer style={topStyle}>
      <HeaderLogo />
      <HeaderMenu />
      <HeaderUserInfo />
    </HeaderContainer>
  );
};

export default Header;
