import { FrameEditBox, FrameEditField, FrameEditInput } from 'components';
import FrameEditTextArea from 'components/frame/FrameEditTextArea';
import { CommonObject, CustomMouseEvent } from 'models';
import {
  EditFieldRow,
  ModalInner,
  ModalOverlay,
  ModalWrapper,
} from 'styles';

const frameData = [
  [
    { title: '실행일시', value: 'LOG_OCC_DATE' },
    { title: '사용자ID', value: 'USR_ID' },
    { title: '사용자명', value: 'USR_NM' },
  ],
  [
    { title: '접속자 IP', value: 'CL_IP_ADDR' },
    { title: '메뉴 ID', value: 'MNU_ID' },
    { title: '메뉴명', value: 'MNU_NM' },
  ],
  [
    { title: '프로그램ID', value: 'PGM_ID' },
    { title: '프로그램명', value: 'PGM_NM' },
    { title: '실행메소드', value: 'CL_MTH_NM' },
  ],
];

const COCM190M01Modal = ({
  onClose,
  currentData,
}: {
  onClose: () => void;
  currentData: CommonObject;
}): JSX.Element => {
  const onMaskClick = (e: CustomMouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      <ModalOverlay />
      <ModalWrapper
        onClick={onMaskClick}
        tabIndex={-1}
      >
        <ModalInner
          tabIndex={0}
          className='w-[1000px] h-[500px]'
        >
          <div>시스템 사용이력</div>
          <FrameEditBox>
            {frameData.map((frame, index) => (
              <EditFieldRow
                key={index}
                className={index === 0 ? 'border-t' : undefined}
              >
                {frame.map(data => (
                  <FrameEditField
                    key={data.title}
                    title={data.title}
                  >
                    <FrameEditInput
                      type='text'
                      value={currentData[data.value]}
                      readOnly
                    />
                  </FrameEditField>
                ))}
              </EditFieldRow>
            ))}
            <EditFieldRow>
              <FrameEditTextArea
                title='전달인자'
                value={currentData.CL_VRB_CONT}
                readOnly
                height={300}
              />
            </EditFieldRow>
          </FrameEditBox>
        </ModalInner>
      </ModalWrapper>
    </>
  );
};

export default COCM190M01Modal;
