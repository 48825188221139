import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    *{
        box-sizing: border-box;
    }
    body::-webkit-scrollbar {
        display: none;
    }

    body, ul, li {
        padding: 0px;
        margin: 0px;
    }
    
    body{
        overflow: hidden;
    }

    ul, li, a {
        list-style: none;
        text-decoration: none;
    }

    li, a {
        cursor: pointer;
    }
    
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    textarea {
        resize: none;
    }

`;
