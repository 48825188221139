import { IFrameEditSelectProps } from 'models';

const FrameEditSelect = ({
  required,
  options,
  ...props
}: IFrameEditSelectProps): JSX.Element => {
  return (
    <select
      {...props}
      className={`${required ? 'required' : undefined} max-w-[400px]`}
    >
      {options ? (
        options.map((option, index) => (
          <option
            key={index}
            value={option.value}
          >
            {option.label}
          </option>
        ))
      ) : (
        <option value=''>-</option>
      )}
    </select>
  );
};

export default FrameEditSelect;
