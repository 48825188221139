import { useQuery } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import {
  CheckInputCell,
  DateCell,
  NumberCell,
  SelectCell,
  TextCell,
} from 'components';
import {
  IQueryParams,
  ISearchCommonCode,
  ISearchCommonCodeTable,
} from 'models';
import { client } from 'utils';

const columnHelper = createColumnHelper();

function generateUserColumns(searchData: ISearchCommonCodeTable) {
  if (
    !Object.values(searchData).some(value => value && value !== 'TP_CD')
  ) {
    return [];
  }
  const namePattern = /^USR_FLD_(\d+)_NM$/;
  const inputFormCdPattern = /^USR_FLD_(\d+)_INP_FRM_CD$/;
  const fields: any = {};

  Object.entries(searchData)
    .sort()
    .forEach(([key, value]) => {
      let match;
      if ((match = key.match(inputFormCdPattern))) {
        const [, fieldNumber] = match;
        if (!fields[fieldNumber]) fields[fieldNumber] = {};
        fields[fieldNumber].inpFrmCd = value;
      } else if ((match = key.match(namePattern))) {
        const [, fieldNumber] = match;
        if (!fields[fieldNumber]) fields[fieldNumber] = {};
        fields[fieldNumber].name = value;
      }
    });
  return Object.entries(fields).reduce((columns, [fieldNumber, field]) => {
    const { name, inpFrmCd } = field as any;
    if (!name || !inpFrmCd) return columns;
    const accessor = `USR_FLD_${fieldNumber}_CONT`;

    const column = columnHelper.accessor(accessor, {
      header: name,
      size: 100,
      cell:
        inpFrmCd === 'CK'
          ? CheckInputCell
          : inpFrmCd === 'CD'
            ? SelectCell
            : inpFrmCd === 'NO'
              ? NumberCell
              : inpFrmCd === 'CA'
                ? DateCell
                : TextCell,
      filterFn: 'includesString',
    });

    columns.push(column);
    return columns;
  }, [] as any[]);
}

function commonCodeList({
  searchData,
  options,
}: IQueryParams<ISearchCommonCodeTable | ISearchCommonCode>) {
  return useQuery({
    queryKey: ['code', 'common_code', searchData],
    queryFn: async () => {
      if (searchData.TP_CD === '') {
        return [];
      }
      const response = await client.get('/api/co/cm/120m01/code', {
        params: { TP_CD: searchData.TP_CD },
      });

      if ('USR_FLD_1_INP_FRM_CD' in searchData) {
        const columns = generateUserColumns(searchData);
        return {
          commonCodeList: response.data.commonCodeList || [],
          columns,
        };
      }
      return response.data.commonCodeList || [];
    },
    ...options,
  });
}

export default function useCommonCodeList(
  searchData: ISearchCommonCodeTable | ISearchCommonCode,
) {
  return commonCodeList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: searchData.TP_CD !== '' && searchData.TP_CD !== undefined,
    },
  });
}
