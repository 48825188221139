import {
  IDeviceDescription,
  IModuleMove,
  ISearchDrawImages,
} from 'models/interfaces/deviceDescriptionInterface';
import { RowType } from 'models/types/commonTypes';

export const defaultDeviceDescription: IDeviceDescription = {
  DVC_NO: 0,
  DSC_ID: 0,
  DSC_CD: '',
  DSC_NM: '',
  SYM: '',
  GDR_MIN: '',
  GDR_DFT: '',
  USR_FLD_1_CONT: '',
  USR_FLD_2_CONT: '',
  USR_FLD_3_CONT: '',
  USR_FLD_4_CONT: '',
  S01: '',
  S02: '',
  S03: '',
  S04: '',
  S05: '',
  S06: '',
  S07: '',
  S08: '',
  S09: '',
  S10: '',
  S11: '',
  S12: '',
  RMK_CNT: '',
  ROWTYPE: RowType.ADD,
};

export const defaultModuleMove: IModuleMove = {
  MV_DVC_NO: 0,
  MV_MDL_ID: 0,
};

export const defaultSearchDrawImages: ISearchDrawImages = {
  DVC_NO: 0,
  URL: '',
  TIME: '',
};
