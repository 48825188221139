import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IMaskCode } from 'models/interfaces/maskInterface';
import { client } from 'utils';

function maskMutation(
  options: UseMutationOptions<unknown, Error, IMaskCode, unknown>,
) {
  return useMutation({
    mutationFn: async (maskCodeData: IMaskCode) => {
      const response = await client.delete('/api/teg/02/sub', {
        data: maskCodeData,
      });
      return response;
    },
    ...options,
  });
}

export default function useMaskSubsiteDeleteMutation() {
  const queryClient = useQueryClient();

  return maskMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['mask'],
      });
    },
  });
}
