import {
  CommonArray,
  defaultCurrentData,
  defaultSearchChildtMenu,
  defaultSearchParentMenu,
  defaultSearchProgram,
} from 'models';
import { atom } from 'recoil';

const searchProgram = atom({
  key: 'searchProgram',
  default: defaultSearchProgram,
});

const programList = atom<CommonArray>({
  key: 'programList',
  default: [],
});

const currentChildMenu = atom({
  key: 'currentChildMenu',
  default: defaultCurrentData,
});

const menuList = atom<CommonArray>({
  key: 'menuList',
  default: [],
});

const searchParentMenu = atom({
  key: 'searchParentMenu',
  default: defaultSearchParentMenu,
});

const currentParentMenu = atom({
  key: 'currentParentMenu',
  default: defaultCurrentData,
});

const searchChildMenu = atom({
  key: 'searchChildMenu',
  default: defaultSearchChildtMenu,
});

export const menuManagementState = {
  searchProgram,
  programList,
  currentChildMenu,
  menuList,
  searchParentMenu,
  currentParentMenu,
  searchChildMenu,
};
