import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IUserCode } from 'models';
import { client } from 'utils';

function userMutation(
  options: UseMutationOptions<unknown, Error, IUserCode[], unknown>,
) {
  return useMutation({
    mutationFn: async userCode => {
      const response = await client.post(
        '/api/co/cm/100m01/user',
        userCode,
      );
      return response;
    },
    ...options,
  });
}

export default function useUserMutation() {
  const queryClient = useQueryClient();

  return userMutation({
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['user_list'] });
    },
  });
}
