import { createColumnHelper } from '@tanstack/react-table';
import useDscCodeMutation from 'apis/mutations/useDscCodeMutation';
import useDscCodeList from 'apis/queries/useDscCodeList';
import {
  CheckInputCell,
  SelectCell,
  StatusCell,
  StatusIcon,
  Table,
  TextCell,
} from 'components';
import { useSearchComboList, useTableData } from 'hooks';
import {
  Columns,
  CommonObject,
  CurrentData,
  ICommonCode,
  ITableContext,
} from 'models';
import { defaultDscCode } from 'models/defaults/dscCodeDefaults';
import { IDscCode } from 'models/interfaces/dscCodeInterface';
import { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { dscCodeState } from 'store/dscCodeAtoms';
import { DataTableContainer } from 'styles';
import {
  TableContext,
  addRowData,
  removeRowData,
  saveTableData,
  selectNextDataByRemove,
} from 'utils';

const columnHelper = createColumnHelper();
const newRow: IDscCode = defaultDscCode;

const COCM02DscTable = ({
  tableHeight,
}: {
  tableHeight: number;
}): JSX.Element => {
  const deviceTypeOptions = useSearchComboList(
    { CMB_CD: 'CMB0037' },
    true,
  );
  const dataTypeOptions = useSearchComboList({ CMB_CD: 'CMB0038' }, true);
  const searchDeviceType = useRecoilValue(
    dscCodeState.searchDeviceType('COCM02'),
  );
  const { data: dscCodeData } = useDscCodeList(
    searchDeviceType,
  ) as CommonObject;

  const [currentData, setCurrentData] = useRecoilState(
    dscCodeState.currentDscCode,
  );
  const [initialData, setInitialData] = useTableData<CommonObject>({
    fetchData: dscCodeData,
    atom: dscCodeState.dscCodeList,
    effect: () => {
      setInitialData(dscCodeData);
      setCurrentData({ index: 0, data: dscCodeData[0] });
    },
  });
  const dscCodeMutation = useDscCodeMutation();

  const defaultColumns = [
    columnHelper.display({
      id: 'status',
      size: 20,
      cell: StatusCell,
      header: () => <StatusIcon />,
    }),
    columnHelper.accessor('DVC_TYPE', {
      header: '디바이스 종류',
      size: 100,
      cell: SelectCell,
      enableColumnFilter: false,
      meta: {
        options: deviceTypeOptions,
      },
    }),
    columnHelper.accessor('DSC_CD', {
      header: '속성 코드',
      cell: TextCell,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('DSC_NM', {
      header: '속성명',
      cell: TextCell,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('VAL_TYPE', {
      header: '데이터 유형',
      cell: SelectCell,
      enableColumnFilter: false,
      meta: {
        options: dataTypeOptions,
      },
    }),
    columnHelper.accessor('SRT_SQN', {
      header: '정렬순서',
      cell: TextCell,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('VAL_EXPR', {
      header: '수식',
      cell: TextCell,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('W_FLAG', {
      header: 'It2/W 계산용',
      cell: CheckInputCell,
      filterFn: 'includesString',
    }),
    columnHelper.accessor('RMK_CONT', {
      header: '비고',
      cell: TextCell,
      filterFn: 'includesString',
    }),
  ];
  const columns = useMemo(
    () => defaultColumns,
    [deviceTypeOptions, dataTypeOptions],
  ) as Columns;
  const extractNumber = (dscCd: string) => {
    const match = dscCd.match(/\d+$/);
    return match ? parseInt(match[0], 10) : 0;
  };
  const handleAddData = () => {
    let addRow = undefined;
    if (searchDeviceType.DVC_TYPE === '') {
      addRow = newRow;
    } else {
      const newNumber = initialData.length
        ? Math.max(...initialData.map(row => extractNumber(row.DSC_CD))) +
          1
        : 1;
      const newCd = `${searchDeviceType.DVC_TYPE}${newNumber.toString().padStart(2, '0')}`;
      addRow = {
        ...newRow,
        DVC_TYPE: searchDeviceType.DVC_TYPE,
        DSC_CD: newCd,
      };
    }

    addRowData(setInitialData, setCurrentData, addRow, initialData.length);
  };
  const handleRemoveData = (selectData: CurrentData) => {
    const removedData = removeRowData(selectData, initialData);

    if (removedData.length > 0) {
      setInitialData(removedData);
      selectNextDataByRemove(
        selectData,
        initialData.length - 1,
        removedData,
        setCurrentData,
      );
    }
  };
  const handleSaveData = () => {
    const checkKeys = ['DVC_TYPE', 'DSC_CD', 'DSC_NM'];
    const targetData = saveTableData(initialData, checkKeys);
    if (targetData.length > 0) {
      dscCodeMutation.mutate(targetData as ICommonCode[]);
    } else {
      setInitialData(initialData);
    }
  };

  const contextValue: ITableContext = {
    handleAddData,
    handleRemoveData,
    handleSaveData,
    currentData,
    setCurrentData,
  };

  return (
    <DataTableContainer>
      <TableContext.Provider value={contextValue}>
        {initialData && (
          <Table
            title='대공정 정보'
            initialData={initialData}
            backupData={dscCodeData}
            setData={setInitialData}
            columns={columns}
            editable={true}
            tableHeight={tableHeight}
          />
        )}
      </TableContext.Provider>
    </DataTableContainer>
  );
};

export default COCM02DscTable;
