import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { ICommonCode } from 'models';
import { client } from 'utils';

function dscCodeMutation(
  options: UseMutationOptions<unknown, Error, ICommonCode[], unknown>,
) {
  return useMutation({
    mutationFn: async dscCode => {
      const response = await client.post(
        '/api/co/cm/02/description',
        dscCode,
      );
      return response;
    },
    ...options,
  });
}

export default function useDscCodeMutation() {
  const queryClient = useQueryClient();

  return dscCodeMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['dsc_cd'],
      });
    },
  });
}
