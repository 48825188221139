import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IDepartmentCode, PopupCode } from 'models';
import { toast } from 'react-toastify';
import { client, getAlertMessage } from 'utils';

function departmentCodeMutation(
  options: UseMutationOptions<unknown, Error, IDepartmentCode[], unknown>,
) {
  return useMutation({
    mutationFn: async departmentCode => {
      const response = await client.post(
        '/api/ai/bi/010m01/department',
        departmentCode,
      );
      return response;
    },
    ...options,
  });
}

export default function useSaveDepartmentCode() {
  const queryClient = useQueryClient();

  return departmentCodeMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['department'],
      });
      queryClient.invalidateQueries({
        queryKey: ['common', 'popupInfo', PopupCode.DEPARMENT],
      });
      toast.success(getAlertMessage('INFO_00010'));
    },
  });
}
