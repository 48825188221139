import { flexRender, Cell } from '@tanstack/react-table';
import { DataTableCell } from 'styles';
import {
  /* CurrentData, */ CustomMouseEvent,
  ITableRowProps,
} from 'models';
import { useTableContext } from 'hooks';

const TableRow = ({
  row,
  handleStartIndex,
  handleEndIndex, // 추가
  handleMouseMove, // 추가
  isDragedCell, // 추가
  backupRow,
}: ITableRowProps): JSX.Element => {
  const {
    currentData,
    setCurrentData,
    handleDoubleClick,
    handleCellColor,
    selectedDataList,
  } = useTableContext();
  const currentRow = { index: row.index, data: row.original };
  const isCurrentData = currentData.index === row.index;
  const isRowSelected = () => {
    const selectedData = selectedDataList?.find(
      selectedData => selectedData.index === row.index,
    );

    return selectedData !== undefined;
  };
  const isSelected = selectedDataList ? isRowSelected() : isCurrentData;
  const handleSelected = (e: CustomMouseEvent) => {
    if (e.button !== 0) return;

    if (setCurrentData) setCurrentData(currentRow);
  };

  // useEffect(() => {
  //   if (backupRow === undefined) return;
  //   console.log('row.original', row.original);
  //   console.log('backupRow', backupRow);
  // }, [row.original]);

  return (
    <>
      <tr
        className={isSelected ? 'bg-blue-100' : 'even:bg-slate-50'}
        onMouseDown={handleSelected}
        onDoubleClick={handleDoubleClick}
      >
        {row
          .getVisibleCells()
          .map((cell: Cell<any, any>, index: number) => (
            <DataTableCell
              key={cell.id}
              style={{
                maxWidth: cell.column.columnDef.size,
                minWidth: cell.column.columnDef.size,
              }}
              className={`${isDragedCell?.({ row: row.index, column: index }) ? 'draged-cell' : undefined}`} // 추가
              onMouseDown={e =>
                handleStartIndex(e, { row: row.index, column: index })
              } // 변경
              onMouseUp={handleEndIndex} // 추가
              onMouseMove={() =>
                handleMouseMove?.({ row: row.index, column: index })
              } // 추가
              onDragStart={e => e.preventDefault()}
            >
              <div
                className={`cell-box ${handleCellColor?.(row.original, cell.column.id)}`}
              >
                {flexRender(cell.column.columnDef.cell, {
                  ...cell.getContext(),
                  rowRef: backupRow,
                })}
              </div>
            </DataTableCell>
          ))}
      </tr>
    </>
  );
};

export default TableRow;
