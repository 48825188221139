import { CustomMouseEvent } from 'models';
import { ModalInner, ModalOverlay, ModalWrapper } from 'styles';
import TEG04DescriptionTable from './TEG04DescriptionTable';
import { useHeight } from 'hooks';

const TEG04DescriptionModal = ({
  onClose,
}: {
  onClose: () => void;
}): JSX.Element => {
  const getHeight = useHeight();
  const onMaskClick = (e: CustomMouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      <ModalOverlay />
      <ModalWrapper
        onClick={onMaskClick}
        tabIndex={-1}
      >
        <ModalInner
          tabIndex={0}
          className='w-[1200px] h-[800px]'
        >
          <TEG04DescriptionTable tableHeight={getHeight(700)} />
        </ModalInner>
      </ModalWrapper>
    </>
  );
};

export default TEG04DescriptionModal;
