import TableRow from './TableRow';
import { ITableBodyProps } from 'models';
import DraggableRow from '../utils/DraggableRow';
import { Row } from '@tanstack/react-table';

const TableBody = ({
  padding,
  virtualRows,
  memoizedRows,
  draggable,
  reorderRow,
  handleStartIndex,
  handleEndIndex, // 추가
  handleMouseMove, // 추가
  isDragedCell, // 추가
  backupData,
}: ITableBodyProps): JSX.Element => {
  return (
    <tbody>
      {padding.top > 0 && (
        <tr>
          <td style={{ height: `${padding.top}px` }} />
        </tr>
      )}
      {virtualRows().map(virtualRow => {
        const row: Row<unknown> = memoizedRows[virtualRow.index];
        const backupRow = backupData[virtualRow.index];
        return draggable ? (
          <DraggableRow
            key={row.id}
            row={row}
            reorderRow={reorderRow}
            // handleStartIndex={handleStartIndex}
            backupRow={backupRow}
          />
        ) : (
          <TableRow
            key={row.id}
            row={row}
            handleStartIndex={handleStartIndex}
            handleEndIndex={handleEndIndex} // 추가
            handleMouseMove={handleMouseMove} // 추가
            isDragedCell={isDragedCell} // 추가
            backupRow={backupRow}
          />
        );
      })}
      {padding.bottom > 0 && (
        <tr>
          <td style={{ height: `${padding.bottom}px` }} />
        </tr>
      )}
    </tbody>
  );
};

export default TableBody;
