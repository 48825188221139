import {
  IDocument,
  IDocumentDevice,
  IDocumentHistory,
  IDocumentType,
  ISearchDocument,
} from 'models/interfaces/pdkInterface';
import { RowType } from 'models/types/commonTypes';

export const defaultSearchDocument: ISearchDocument = {
  DATE: '',
};

export const defaultDocument: IDocument = {
  DOC_NM: '',
  DOC_NO: 0,
  DOC_VER: '',
  OLD_FLAG: '',
  RLS_FLAG: 'N',
  UPDR_ID: '',
  UPD_DATE: '',
  ROWTYPE: RowType.ADD,
};

export const defaultDocumentHistory: IDocumentHistory = {
  DOC_HIS_CONT: '',
  DOC_HIS_SNO: 0,
  DOC_NO: 0,
  DOC_TYPE: '',
  HIS_OCC_DATE: '',
  UPDR_ID: '',
  UPD_DATE: '',
  USR_ID: '',
  ROWTYPE: RowType.ADD,
};

export const defaultDocumentDevice: IDocumentDevice = {
  DOC_NO: 0,
  DOC_SNO: 0,
  DVC_NO: '',
  DSG_ID: '',
  OLD_DVC_NO: '',
  OLD_DSG_ID: '',
  PRJ_CD: 0,
  PRC_CD: '',
  DVC_CD: '',
  DSG_CD: '',
  UPDR_ID: '',
  ROWTYPE: RowType.ADD,
};

export const defaultDocumentType: IDocumentType = {
  DOC_NO: 0,
  DOC_TYPE: '',
  FILE_NM: '',
  ROWTYPE: RowType.ADD,
  UPDR_ID: '',
};
