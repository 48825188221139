import { useQuery } from '@tanstack/react-query';
import { UseQueryOptionsWithoutQueryKey } from 'models';
import { client } from 'utils';
import { ISearchDevice } from 'models/interfaces/deviceInterface';

interface IDeviceListParam {
  searchData: ISearchDevice;
  options: UseQueryOptionsWithoutQueryKey;
}

function deviceVop({ searchData, options }: IDeviceListParam) {
  return useQuery({
    queryKey: ['deviceVop', searchData],
    queryFn: async () => {
      const response = await client.get('/api/teg/03/vop', {
        params: searchData,
      });
      const { vopList, vopCodes } = response.data;
      return {
        vopList,
        vopCodes,
      };
    },
    ...options,
  });
}

export default function useDeviceVopList(searchData: ISearchDevice) {
  return deviceVop({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  });
}
