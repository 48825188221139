import { useQuery } from '@tanstack/react-query';
import { IQueryParams, ISearchModule } from 'models';
import { client } from 'utils';

function getDrawTypeCodes(drawTypeCodes: any) {
  const drawTypeOptions = drawTypeCodes?.map(
    (code: { CMN_CD_NM: string; CMN_CD: string }) => ({
      label: code.CMN_CD_NM,
      value: code.CMN_CD,
    }),
  );
  return drawTypeOptions;
}

function deviceDrawList({
  searchData,
  options,
}: IQueryParams<ISearchModule>) {
  return useQuery({
    queryKey: ['module', 'device', 'draw', searchData],
    queryFn: async () => {
      const response = await client.get('/api/teg/04/modal/draw', {
        params: searchData,
      });
      const { drawList, drawTypeCodes } = response.data;
      const renderKey = new Date().getTime();
      const updatedDrawList = drawList.map((item: any) => ({
        ...item,
        renderKey: renderKey,
      }));
      const drawTypeOption = getDrawTypeCodes(drawTypeCodes);
      return {
        drawList: updatedDrawList,
        drawTypeOption,
      };
    },
    ...options,
  });
}

export default function useDeviceDrawList(searchData: ISearchModule) {
  return deviceDrawList({
    searchData: searchData,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: searchData.DVC_NO !== '',
    },
  });
}
