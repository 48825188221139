import { IInfoBoxProps } from 'models';
import { InfoContainer } from 'styles';

const InfoBox = ({
  title,
  count,
  children,
}: IInfoBoxProps): JSX.Element => {
  return (
    <InfoContainer>
      <div className='info-box'>
        <div className='title'>{title}</div>
        <div className='count-box'>
          <span>전체</span>
          <span className='count'>{count}</span>
          <span>건</span>
        </div>
      </div>
      <div className='toggle-box'>{children}</div>
    </InfoContainer>
  );
};

export default InfoBox;
