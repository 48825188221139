import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IBbsDetail } from 'models/interfaces/bbsInterface';
import { client } from 'utils';

function bbsDetailMutation(
  options: UseMutationOptions<unknown, Error, IBbsDetail, unknown>,
) {
  return useMutation({
    mutationFn: async bbsDetail => {
      const response = await client.post(
        '/api/co/cm/800m01/bbs',
        bbsDetail,
      );
      return response;
    },
    ...options,
  });
}

export default function useBbsDetailMutation() {
  const queryClient = useQueryClient();

  return bbsDetailMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['bbs_id'],
      });
    },
  });
}
