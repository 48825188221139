import tw from 'twin.macro';
import styled from 'styled-components';

export const HeaderContainer = styled.header`
  ${tw`
    relative
    z-20
    w-full
    bg-blue-950
    text-white
    flex
    transform
    duration-300
  `}

  .logo-box {
    ${tw`
      flex
      justify-center
      flex-shrink-0
    `}

    a {
      ${tw`
        flex
        items-center
        justify-center
      `}
    }
    img {
      ${tw`
        w-[50%]
      `}
    }
  }
`;

export const HeaderMenuList = styled.ul`
  ${tw`
    flex-grow
    h-full
    flex
    gap-x-4
    font-bold
    pl-[35px]
  `}

  li {
    ${tw`
      flex
      items-center
    `}
  }
  a {
    ${tw`
      pt-[2px]
      mr-4
      border-t-[2px]
      border-blue-500
      border-opacity-0
      transform
      duration-150
      
      hover:border-opacity-100
    `}
  }
  .active {
    ${tw`
      border-opacity-100
    `}
  }
`;

export const HeaderUserInfoContainer = styled.div`
  ${tw`
    relative
    w-[80px]
    flex
    items-center
    justify-center
  `}

  .out-circle {
    ${tw`
      cursor-pointer
      transform
      duration-300
      bg-slate-200
      bg-opacity-0
      hover:bg-opacity-20
      rounded-[50%]
      p-[0.3rem]
      `}
  }

  .inner-circle {
    ${tw`
      bg-orange-700
      w-[35px]
      h-[35px]
      rounded-[50%]
      text-xs
      `}

    svg {
      ${tw`
        w-[90%]
        h-[90%]
      `}
    }
  }

  .out-circle,
  .inner-circle {
    ${tw`
      flex
      items-center
      justify-center
    `}
  }
`;

export const UserInfoDiv = styled.div<{ $openInfo: boolean }>`
  ${tw`
    absolute
    w-[200px]
    top-[3.5rem]
    right-[1.5rem]
    rounded-lg
    bg-slate-100
    border
    border-slate-300
    p-1
    text-blue-950
    text-sm
    transform
    duration-200
  `}
  ${({ $openInfo }) =>
    $openInfo ? tw`opacity-100` : tw`opacity-0 scale-0`}

  .info {
    ${tw`
      flex
      flex-col
      items-center
      justify-center
      p-1
    `}
    .info-etc {
      ${tw`
        text-xs
      `}
    }
    .info-id {
      ${tw`
        text-base
        font-bold
      `}
    }
  }
  .info-menu {
    ${tw`
      flex
      items-center
      w-full
      gap-x-1
      p-1
      rounded-lg
      cursor-pointer

      hover:text-white
    `}
    svg {
      ${tw`
        w-[20px]
        h-[20px]
      `}
    }
  }
  .password {
    ${tw`
      hover:bg-blue-950
    `}
  }
  .logout {
    ${tw`
      mt-1
      text-red-600

      hover:bg-red-600
    `}
  }
  .info-form {
    ${tw`
      flex
      flex-col
      items-center
      justify-center
    `}
  }
`;

export const UserFieldBox = styled.div`
  ${tw`
    flex
    items-center
    gap-x-2
    py-1
  `}

  input {
    ${tw`
      w-full
      border
      rounded-lg
      py-0.5
      px-1.5
      outline-none
    `}
  }
`;
