import { RecoilRoot } from 'recoil';
import { HashRouter as Router } from 'react-router-dom';
import { AuthorizationRouter } from 'pages';
import { GlobalStyle } from 'GlobalStyle';
import 'react-toastify/dist/ReactToastify.css';
import { Toast } from 'components';

function App(): JSX.Element {
  return (
    <RecoilRoot>
      <div className='App'>
        <Toast />
        <GlobalStyle />
        <Router>
          <AuthorizationRouter />
        </Router>
      </div>
    </RecoilRoot>
  );
}

export default App;
